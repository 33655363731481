/*!
 * List listener
 */

import { DbListener } from "@/db"
import { COLLECTION_FILTER_GROUPS } from '@/db/db-collections.mjs'

export class FilterGroupDbListener extends DbListener {
  constructor({
    collectionName = COLLECTION_FILTER_GROUPS,
    listId,
    queryConditions = [
      ['listId', '==', listId],
      ['isDeleted', '==', false]
     ],
    addedFunction,
    modifiedFunction,
    removedFunction,
    handlerParams,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      handlerParams,
      ...props
    })
  }
}
