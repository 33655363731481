/*!
 * Team field schema listener
 */

import { DbListener } from "@/db"
import { COLLECTION_FIELD_SCHEMAS } from '@/db/db-collections.mjs'
import { required } from '@/utils/required.mjs'
import { getTeamFieldSchemasQuery } from '../db/field-schema-db-utils.mjs'
import { onFieldSchemaAdded, onFieldSchemaChanged, onFieldSchemaRemoved } from './team-field-schema-event-handlers.mjs'
import { teamFieldSchemas, fieldMapOptions } from '../../ui/ui-states.mjs'
import { getFieldSchemaEntitiesByTeamId } from '../../field-schema/db/field-schema-db.mjs'

export class TeamFieldScheamaListener extends DbListener {
  #teamId
  constructor({
    collectionName = COLLECTION_FIELD_SCHEMAS,
    teamId = required('teamId'),
    queryConditions = getTeamFieldSchemasQuery(teamId),
    addedFunction = onFieldSchemaAdded,
    modifiedFunction = onFieldSchemaChanged,
    removedFunction = onFieldSchemaRemoved,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      handlerParams: { teamFieldSchemas, fieldMapOptions },
      ...props
    })
    this.#teamId = teamId
  }

  async start() {
    // preload
    const fieldSchemaEntities = await getFieldSchemaEntitiesByTeamId(this.#teamId)
    teamFieldSchemas.value = fieldSchemaEntities.reduce((obj, fs) => ({ ...obj, [fs.id]: fs }), {})
    fieldMapOptions.value = Object.values(teamFieldSchemas.value)
      .filter(fs => fs.isEditable)
      .map(fs => ({ label: fs.label, value: fs.key, id: fs.id, fieldSchema : fs }))
      .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()) )
      /*
    libraryFieldSchemas.value = fieldSchemaEntities
      .filter(fs => !fs.isDeleted)
      .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()) )
    */
    super.start()
  }
}
