
// session
import { getTeamSession } from '../../session/team-session.mjs'

// 

import { ListEntity } from '../../entity/ListEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { PRICE_LIST } from '../constants/list-types.mjs'

import {
  TYPE_VIEW_SETTINGS
} from '../../column-set/constants/column-set-types.mjs'

export const createView = async ({
  name,
  libraryColumnSet,
  selectedColumns,
  canExport,
}) => {
  const teamId = libraryColumnSet.teamId
  const listEntity = new ListEntity({
    name,
    type: PRICE_LIST,
    teamId,
    isStatic: false,
    canExport
  })

  // use library column set

  const viewColumnSet = new ColumnSetEntity({
    type: TYPE_VIEW_SETTINGS,
    listId: listEntity.id,
    teamId: listEntity.teamId,
  })
  viewColumnSet.setCreated().setUpdated()

  listEntity.columnSetId = viewColumnSet.id
  const selectedColumnIds = selectedColumns.map(col => col.id) || []
  viewColumnSet.columns = libraryColumnSet.columns
    .filter(col => selectedColumnIds.includes(col.id))
    .map(col => col.cloneAsNew())
    .map(col => col.setCreated().setUpdated())

  await ListEntity.upsert(listEntity)
  await ColumnSetEntity.upsert(viewColumnSet)

  return { list: listEntity, columnSet: viewColumnSet }
}
