/**
 * Permission constants
 */

export const ADMIN_USER_ROLE  = 'Admin'
export const EDITOR_USER_ROLE = 'Editor'
export const VIEWER_USER_ROLE = 'Viewer'
export const DEFAULT_USER_ROLE = VIEWER_USER_ROLE

export const USER_ROLES = [
  ADMIN_USER_ROLE,
  EDITOR_USER_ROLE,
  VIEWER_USER_ROLE,
]
