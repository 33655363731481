import { getListCoreRpc } from '../../db/rpcs/db-rpcs.mjs'
import { ACTION_UPSERT_ITEM, ACTION_GET_ITEM } from '../../list-core/constants/action-types.mjs'

import { getLocalDb } from '../../db-local/local-db.mjs'
import { COLLECTION_ITEMS } from '../../db/db-collections.mjs'
import { saveItemsEntities } from '../db/item-db.mjs'


export const fetchItem = async (id) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_ITEM,
    payload: {
      id
    }
  })
  if (result.data?.success) {
    // save to local db
    const localDb = getLocalDb(COLLECTION_ITEMS)
    localDb.put(result.data.result)

    return result.data.result
  }
  console.error('fetchItem error:', result.data)
  return result?.data?.success
}
