/*!
 * Field editor ui 
 */
import { ref, shallowRef } from 'vue'


import { ColumnEntity, FieldSchemaEntity } from '../../entity'

import { isFieldEditorOpen, fieldEditorInputType } from '../ui-states.mjs'

import { parseBoolean, parseTaglist } from '../../utils/parse.mjs'
import { isCheckbox } from '../../utils/is.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

// Constants
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
} from '../../field-schema/field-schema-constants.mjs'

import {
  FIELD_INPUT_TYPE_TEXT,
  FIELD_INPUT_TYPE_NUMBER,
  FIELD_INPUT_TYPE_DATE,
  FIELD_INPUT_TYPE_CHECKBOX,
  FIELD_INPUT_TYPE_MULTI_LINE,
  FIELD_INPUT_TYPE_TAGLIST,
} from '../../field-schema/constants/field-schema-ui-constants.mjs'


const selectedFieldId = shallowRef(null)
const isNewColumn = shallowRef(false)
const fieldEditorColumn = ref(null)
const fieldEditorFieldSchema = ref(null)
const fieldEditorImportColumn = ref(null)

/**
 * Open field editor for new column
 */
function openFieldEditorNew(
  id,
) {
  isNewColumn.value = true
  selectedFieldId.value = id

  const team = getTeamSession()
  if (!team) {
    console.error('Cannot edit without a team')
  }
  fieldEditorColumn.value = (new ColumnEntity({ teamId: team.id })).toObject()
  fieldEditorFieldSchema.value = (new FieldSchemaEntity({ teamId: team.id })).toObject()
  fieldEditorColumn.value.fieldSchemaId = fieldEditorFieldSchema.value.id

  fieldEditorInputType.value = getInputTypeByFieldType(fieldEditorFieldSchema.value .type)
  fieldEditorInputType.value = fieldEditorFieldSchema.value.format.isMultiLine ? FIELD_INPUT_TYPE_MULTI_LINE : fieldEditorInputType.value

  // Open field editor
  isFieldEditorOpen.value = true
}

/**
 * Open field editor for update
 * @param {*} column 
 * @param {*} fieldSchema 
 * @param {*} importColumn 
 * @returns 
 */
function openFieldEditorUpdate(
  column,
  fieldSchema,
) {
  isNewColumn.value = false
  if (!column.isEditable) {
    fieldEditorFieldSchema.value = null
    return // Can't edit a non-editable field
  }
  fieldEditorColumn.value = { ...column.toObject(), fieldSchema: undefined }
  fieldEditorFieldSchema.value = fieldSchema.toObject()

  isFieldEditorOpen.value = true
}


/**
 * Open field editor for update
 * @param {*} column 
 * @param {*} fieldSchema 
 * @param {*} importColumn 
 * @returns 
 */
function openFieldEditorUpdateImport(
  importColumn,
  fieldSchema,
) {
  isNewColumn.value = false

  // set the display label and key from the source column
  // use the other properties of the mapped field schema in import column
  fieldEditorFieldSchema.value  = {
    ...importColumn.fieldSchema.toObject(),
    key: fieldSchema.key,
    label: importColumn.label || fieldSchema.label
  }

  fieldEditorImportColumn.value = {
    ...importColumn?.toObject(),
    fieldSchema: importColumn?.fieldSchema?.toObject()
  }


  isFieldEditorOpen.value = true
}

/**
 * Change current field type
 */
function changeCurrentFieldType(type) {
  const fs = new FieldSchemaEntity({ 
    ...fieldEditorFieldSchema.value,
    type
   })
  fs.type = type
  const parsedDefaultValue = fs.defaultValue
  switch (type) {
    case FIELD_TYPE_TAGLIST:
      fs.defaultValue = parseTaglist(parsedDefaultValue)
      break
    case FIELD_TYPE_CHECKBOX:
      fs.defaultValue = isCheckbox(parsedDefaultValue) ? parseBoolean(parsedDefaultValue) : parsedDefaultValue
      break
    default:
      fs.defaultValue = parsedDefaultValue?.toString()
  }
  fieldEditorFieldSchema.value = fs.toObject()

  return
}

/**
 * Get input type depending on field type
 */
function getInputTypeByFieldType(fieldType) {
  switch (fieldType) {
    case FIELD_TYPE_TAGLIST:
      return FIELD_INPUT_TYPE_TAGLIST
    case FIELD_TYPE_NUMBER:
    case FIELD_TYPE_CURRENCY:
    case FIELD_TYPE_PERCENTAGE:
      return FIELD_INPUT_TYPE_NUMBER
    case FIELD_TYPE_CHECKBOX:
      return FIELD_INPUT_TYPE_CHECKBOX
    case FIELD_TYPE_DATE:
      return FIELD_INPUT_TYPE_DATE
    case FIELD_TYPE_TEXT:
    default:
      return FIELD_INPUT_TYPE_TEXT
  }
}

function onCurrentFieldTypeMultiLine(isMultiLine) {
  fieldEditorInputType.value = isMultiLine ? FIELD_INPUT_TYPE_MULTI_LINE : FIELD_INPUT_TYPE_TEXT
}

export const openFieldEditor = () => { 
  fieldEditorInputType.value = getInputTypeByFieldType(fieldEditorFieldSchema.value.type)
  fieldEditorInputType.value = fieldEditorFieldSchema.value.format.isMultiLine ? FIELD_INPUT_TYPE_MULTI_LINE : fieldEditorInputType.value
}

export {
  isNewColumn, selectedFieldId, fieldEditorColumn, fieldEditorFieldSchema, fieldEditorImportColumn,
  openFieldEditorNew, openFieldEditorUpdate, openFieldEditorUpdateImport,
  changeCurrentFieldType, getInputTypeByFieldType, onCurrentFieldTypeMultiLine
}
