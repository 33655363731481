
//#region //--- Local Events ---//
// User Events
export const EVENT_USER_AUTHENTICATED = 'user-authenticated'
export const EVENT_USER_SIGNOUT = 'user-sign-out'
//#endregion

//#region //--- Integration Events ---//

export const EVENT_INTEGRATION_PUBLISH_LOCAL = 'integration-publish-local'

// session events
export const EVENT_INTEGRATION_UPDATED_LOCAL = 'integration-updated-local'
export const EVENT_INTEGRATION_DELETED_LOCAL = 'integration-delete-locald'
export const EVENT_INTEGRATION_UPDATED_REMOTE = 'integration-updated-remote'
export const EVENT_INTEGRATION_DELETED_REMOTE = 'integration-deleted-remote'
//#endregion

//#region //--- Item Events ---//

//Global or team events
export const EVENT_ITEMS_SAVED = 'items-saved'
export const EVENT_ITEMS_DELETED = 'items-deleted'
export const EVENT_ITEMS_REMOVED = 'items-removed'

export const EVENT_ITEM_NEW = 'item-new'
export const EVENT_ITEM_UPDATED = 'item-updated'


// session events
export const EVENT_ITEM_NEW_LOCAL = 'item-new-local'
export const EVENT_ITEM_NEW_REMOTE = 'item-new-remote'
export const EVENT_ITEM_UPDATED_LOCAL = 'item-updated-local'
export const EVENT_ITEM_UPDATED_REMOTE = 'item-updated-remote'
export const EVENT_ITEM_GRID_UPDATED_LOCAL = 'item-grid-updated-local'

// selected items
export const EVENT_SELECTED_ITEMS_UPDATE_LOCAL = 'selected-items-update-local'
export const EVENT_SELECTED_ITEMS_DELETE_LOCAL = 'selected-items-delete-local'
export const EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL = 'selected-items-duplicate-local'

export const EVENT_LIBRARY_ITEMS_UPDATED = 'library-items-updated'
export const EVENT_LIBRARY_ITEMS_UPDATED_REMOTE = 'library-items-updated-remote'

// imports
export const EVENT_ITEMS_IMPORT_LIBRARY_LOCAL = 'items-import-library-local'

// downloads
export const EVENT_ITEMS_DOWNLOAD_AS_CSV = 'items-download-csv'

//#endregion

//#region //--- publish Events ---//

//Global or team events
export const EVENT_PUBLISH_ITEM_ERROR = 'publish-item-error'

//#endregion

//#region //--- Progress Status Events ---//

export const EVENT_PROGRESS_START = 'progress-start'
export const EVENT_PROGRESS_SET_STATUS = 'progess-set-status'

//#endregion

//#region //--- Column Events ---//
//Global or team events
export const EVENT_COLUMN_FIELD_SCHEMA_NEW = 'column-field-schema-new'
export const EVENT_COLUMN_FIELD_SCHEMA_UPDATED = 'column-field-schema-updated'
export const EVENT_COLUMN_FIELD_SCHEMA_DELETED = 'column-field-schema-deleted'
export const EVENT_FIELD_SCHEMA_UPDATED = 'field-schema-updated'
export const EVENT_COLUMN_HIDE = 'column-hide'
export const EVENT_COLUMN_UNHIDE = 'column-unhide'
export const EVENT_COLUMN_PIN = 'column-pin'
export const EVENT_COLUMN_UNPIN = 'column-unpin'
export const EVENT_COLUMN_SORT_ASC = 'column-sort-asc'
export const EVENT_COLUMN_SORT_DESC = 'column-sort-desc'
export const EVENT_COLUMN_MOVE = 'column-move'
export const EVENT_COLUMN_FILTER_SAVE_GROUP = 'column-filter-save-group'
export const EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP = 'column-filter-delete-saved-group'
export const EVENT_COLUMN_DELETED = 'column-deleted'

// session events
export const EVENT_COLUMN_FIELD_SCHEMA_NEW_LOCAL = 'column-field-schema-new-local'
export const EVENT_COLUMN_FIELD_SCHEMA_UPDATED_LOCAL = 'column-field-schema-updated-local'
export const EVENT_COLUMN_FIELD_SCHEMA_DELETED_LOCAL = 'column-field-schema-delete-local'
export const EVENT_COLUMN_FIELD_SCHEMA_NEW_REMOTE = 'column-field-schema-new-remote'
export const EVENT_COLUMN_FIELD_SCHEMA_UPDATED_REMOTE = 'column-field-schema-updated-remote'
export const EVENT_COLUMN_FIELD_SCHEMA_DELETED_REMOTE = 'column-field-schema-delete-remote'
export const EVENT_FIELD_SCHEMA_UPDATED_LOCAL = 'field-schema-updated-local'
export const EVENT_FIELD_SCHEMA_UPDATED_REMOTE = 'field-schema-updated-remote'
export const EVENT_COLUMN_DELETED_LOCAL = 'column-deleted-local'
export const EVENT_COLUMN_DELETED_REMOTE = 'column-deleted-remote'

export const EVENT_COLUMN_HIDE_LOCAL = 'column-hide-local'
export const EVENT_COLUMN_HIDE_REMOTE = 'column-hide-remote'
export const EVENT_COLUMN_UNHIDE_LOCAL = 'column-unhide-local'
export const EVENT_COLUMN_UNHIDE_REMOTE = 'column-unhide-remote'
export const EVENT_COLUMN_PIN_LOCAL = 'column-pin-local'
export const EVENT_COLUMN_PIN_REMOTE = 'column-pin-remote'
export const EVENT_COLUMN_UNPIN_LOCAL = 'column-unpin-local'
export const EVENT_COLUMN_UNPIN_REMOTE = 'column-unpin-remote'
export const EVENT_COLUMN_SORT_ASC_LOCAL = 'column-local-sort-asc'
export const EVENT_COLUMN_SORT_DESC_LOCAL = 'column-local-sort-desc'


export const EVENT_COLUMN_MOVE_LOCAL = 'column-local-move'
export const EVENT_COLUMN_MOVE_REMOTE = 'column-remote-move'
export const EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED = 'column-updated-local-completed'

export const EVENT_COLUMN_AUTOSIZE = 'column-autosize'
export const EVENT_COLUMN_AUTOSIZE_LOCAL = 'column-autosize-local'
export const EVENT_COLUMN_AUTOSIZE_REMOTE = 'column-autosize-remote'

export const EVENT_COLUMN_RESIZE = 'column-resize'
export const EVENT_COLUMN_RESIZE_LOCAL = 'column-resize-local'
export const EVENT_COLUMN_RESIZE_REMOTE = 'column-resize-remote'


//#endregion


//#region //--- Column Search Events ---//
export const EVENT_COLUMN_FILTER_UPDATED_LOCAL = 'column-local-search-updated'
export const EVENT_COLUMN_FILTER_APPLY_TOGGLE_LOCAL = 'column-local-filter-apply-toogle'
export const EVENT_COLUMN_FILTER_ADD_LOCAL = 'column-local-filter-add'
export const EVENT_COLUMN_FILTER_SET_RULE_CONDITION_LOCAL = 'column-local-filter-set-rule-condition'
export const EVENT_COLUMN_FILTER_SET_RULE_VALUE_LOCAL = 'column-local-filter-set-rule-value'
export const EVENT_COLUMN_FILTER_SAVE_GROUP_LOCAL = 'column-local-filter-save-group'
export const EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE = 'column-remote-filter-save-group'
export const EVENT_COLUMN_FILTER_APPLY_SAVED_GROUP_LOCAL = 'column-local-filter-apply-saved-group'
export const EVENT_COLUMN_FILTER_CANCEL_SAVED_GROUP_LOCAL = 'column-local-filter-cancel-saved-group'
export const EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_LOCAL = 'column-local-filter-delete-saved-group'
export const EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_REMOTE = 'column-remote-filter-delete-saved-group'
export const EVENT_COLUMN_FILTER_REMOVE_LOCAL = 'column-local-filter-remove'
export const EVENT_COLUMN_FILTER_REMOVE_RULE_LOCAL = 'column-local-filter-remove-rule'
export const EVENT_COLUMN_FILTER_ADD_RULE_LOCAL = 'column-local-filter-add-rule'
export const EVENT_COLUMN_FILTER_QUICK_LOCAL = 'column-local-filter-quick'

//#endregion

//#region //--- View Action Events ---//
export const EVENT_OPEN_CREATE_VIEW = 'open-create-view'
export const EVENT_CREATE_VIEW = 'create-view'

export const EVENT_OPEN_ADD_TO_VIEW = 'open-add-to-view'
export const EVENT_ADD_TO_VIEW = 'create-add-to-view'

export const EVENT_OPEN_MODIFY_VIEW = 'open-modify-view'

export const EVENT_VIEW_MODIFY_LOCAL = 'view-modify-local'
export const EVENT_VIEW_MODIFIED_LOCAL = 'view-modified-local'
export const EVENT_VIEW_MODIFIED = 'view-modified'
export const EVENT_VIEW_MODIFIED_REMOTE = 'view-modified-remote'



//#endregion

//#region //--- Price List Action Events ---//

export const EVENT_OPEN_CREATE_PRICE_LIST = 'open-create-price-list'
export const EVENT_CREATE_PRICE_LIST = 'create-price-list'

export const EVENT_OPEN_ADD_TO_PRICE_LIST = 'open-add-to-price-list'
export const EVENT_ADD_TO_PRICE_LIST_LOCAL = 'add-to-price-list'

export const EVENT_PRICE_LIST_ITEMS_UPDATED = 'price-list-items-updated'
export const EVENT_PRICE_LIST_ITEMS_UPDATED_REMOTE = 'price-list-items-remote'


export const EVENT_OPEN_MODIFY_PRICE_LIST = 'open-modify-price-list'

export const EVENT_PRICE_LIST_MODIFY_LOCAL = 'price-list-modify-local'
export const EVENT_PRICE_LIST_MODIFIED_LOCAL = 'price-list-modified-local'
export const EVENT_PRICE_LIST_MODIFIED = 'price-list-modified'
export const EVENT_PRICE_LIST_MODIFIED_REMOTE = 'price-list-modified-remote'

export const EVENT_PRICE_LIST_DELETE_LOCAL = 'price-list-delete-local'



//#endregion

//#region //--- Gerneral List Action Events ---//
export const EVENT_LIST_DELETE = 'view-delete'
export const EVENT_LIST_DELETE_LOCAL = 'list-delete-local'
export const EVENT_LIST_DELETE_REMOTE = 'list-delete-remote'
export const EVENT_LIST_REMOVE_SHARING_USER = 'list-remove-sharing-user'
export const EVENT_LIST_REMOVE_SHARING_USER_LOCAL = 'list-remove-sharing-user-local'
export const EVENT_LIST_REMOVE_SHARING_USER_REMOTE = 'list-remove-sharing-user-remote'

//#region //--- File Events ---//
export const EVENT_UPLOAD_CSV_LOCAL = 'upload-csv-local'
export const EVENT_UPLOAD_CSV_LOCAL_COMPLETED = 'upload-csv-local-completed'
//#endregion


//#region //--- import Events ---//
export const EVENT_IMPORT_COLUMN_MAP_UPDATED = 'import-column-map-updated'
export const EVENT_IMPORT_COLUMN_MAP_UPDATED_LOCAL_COMPLETED = 'import-column-map-updated-local-completed'
export const EVENT_IMPORT_COLUMN_MAP_UPDATED_REMOTE = 'import-column-map-updated-remote'

//#endregion


//#region //--- UI Events ---//
export const EVENT_OPEN_CELL_VALUE_EDITOR = 'open-cell-value-editor'
//#endregion

//#region //--- session Events ---//
export const EVENT_SET_SESSION_UPDATED_AT_INTERVAL = 'set-session-updated-at-interval'
//#endregion

//#region //--- log file Events ---//
export const EVENT_LOG_FILE_ITEMS_ENABLE_LOCAL = 'log-file-items-enable-local'
export const EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL = 'log-file-items-disable-local'

//#endregion