/*!
 * Email
 */

import { required, createOne, getAllByConditions } from '../../utils'
import { EMAIL_COLLECTION_NAME, EMAIL_TEMPLATES_COLLECTION_NAME } from '../../constants/database.mjs'
import { SIGNUP_INVITE_TEMPLATE_NAME, TEAM_INVITE_TEMPLATE_NAME, LIST_INVITE_TEMPLATE_NAME } from '../../constants/email.mjs'

const COLLECTION_NAME = EMAIL_COLLECTION_NAME

function makeEmail({
  teamId,
  to = required('to'),
  cc = null,
  bcc = null,
  from,
  replyTo,
  template,
  message,
  inviteId,
}) {
  return {
    teamId,
    to,
    cc,
    bcc,
    replyTo,
    template,
    message,
    inviteId
  }
}

/**
 * Get one mail template by name
 * @param {*} templateName 
 * @returns 
 */
async function getOneEmailTemplateByName(templateName) {
  return getAllByConditions(
    EMAIL_TEMPLATES_COLLECTION_NAME,
    [
      ['templateName', '==', templateName],
      ['isDeleted', '==', false]
    ]
  )
}

/**
 * Create one email by template
 * @param {*} param0 
 * @returns 
 */
function createOneEmailByTemplate({
  to = required('receiverEmail'),
  templateName = required('templateName'),
  templateData,
  teamId,
  inviteId,
}) {
  return getOneEmailTemplateByName(templateName)
    .then(([template, ...rest]) => {
      if (!template || !template.id) {
        console.error(`Unable to find mail template ${templateName}`)
        return null
      }
      const email = makeEmail({
        to: [to],
        template: {
          name: template.id,
          data: { ...templateData }
        },
        teamId,
        inviteId
      })
      return createOne(COLLECTION_NAME, email)
    })
}

/**
 * Create one signup email 
 * @param {*} param0 
 * @returns 
 */
function createOneSignupInviteEmail({
  to = required('to'),
  templateName = SIGNUP_INVITE_TEMPLATE_NAME,
  templateData,
  teamId,
  inviteId
}) {
  return createOneEmailByTemplate({
    to,
    templateName,
    templateData,
    teamId,
    inviteId
  })
}

/**
 * Create one team invite email 
 * @param {*} param0 
 * @returns 
 */
function createOneTeamInviteEmail({
  to = required('to'),
  templateName = TEAM_INVITE_TEMPLATE_NAME,
  templateData = {},
  teamId,
  inviteId,
}) {
  return createOneEmailByTemplate({
    to,
    templateName,
    templateData,
    teamId,
    inviteId
  })
}

/**
 * Create one list invite mail 
 * @param {*} param0 
 * @returns 
 */
 function createOneListInviteMail({
  to = required('receiverEmail Required'),
  templateName = LIST_INVITE_TEMPLATE_NAME,
  templateData = {},
  teamId,
  inviteId,
}) {
  return createOneEmailByTemplate({
    to,
    templateName,
    templateData,
    teamId,
    inviteId
  })
}

/**
 * Create one email
 * @param {*} param0 
 * @returns 
 */
function createEmail({
  to = required('to'),
  cc = null,
  bcc = null,
  from,
  replyTo,
  subject = '',
  text = null,
  html = null,
}) {
  const message = { subject }
  if (text) {
    message.text = text
  }
  if (html) {
    message.html = html
  }
  const email = makeEmail({
    to,
    cc,
    bcc,
    from,
    replyTo,
    message,
  })
  return  createOne(COLLECTION_NAME, email)
}

export { createEmail, getOneEmailTemplateByName, createOneTeamInviteEmail, createOneSignupInviteEmail, createOneListInviteMail }
