
/**
 * Session Events
 * Keep track of event generated by this session. 
 * Main purpose: listeners can use this to skip updates orignated from this session
 */

// Dependencies
import { required } from '../utils/required.mjs'
import { setSessionItem, getSessionItem, removeSessionItem } from '../utils/session.mjs'
import { generateId } from '../utils/uuid.mjs'

// event
import { EVENT_USER_AUTHENTICATED } from './constants/event-types.mjs'

// Constants
const SESSION_KEY = 'event'
const DEFAULT_EXPIRY = 300000


/**
 * generate id
 * @returns uniqueId
 */
export const generateEventId = () => {
  return generateId()
}

export const createSessionEvent = (payload) => {
  return {
    id: generateEventId(),
    payload
  }
}

export const clearSessionEvents = () => {
  console.log('clear all session events')
  removeSessionItem(SESSION_KEY)
}

export const getCurrentSessionEvents = () => {
  const sessionEvents = getSessionItem(SESSION_KEY) || {}
  const currentTs = Date.now()
  for (const id in sessionEvents) {
    if (sessionEvents[id].expireAt < currentTs) {
      delete sessionEvents[id]
    }
  }
  setSessionItem(SESSION_KEY, sessionEvents)
  return sessionEvents
}

export const getSessionEvent = (
  eventId = required('eventId')
) => {
  const sessionEvents = getCurrentSessionEvents()
  return sessionEvents[eventId]
}

export const setSessionEvent = (
  event = required('event')
) => {
  const sessionEvents = getCurrentSessionEvents()
  event.id = event.id || generateEventId()
  sessionEvents[event.id] = {
    id: event.id,
    expireAt: event.expireAt || (Date.now() + DEFAULT_EXPIRY),
    payload: event.payload
  }
  setSessionItem(SESSION_KEY, sessionEvents)
  return event.id
}

export const removeSessionEvent = (
  event = required('event')
) => {
  const sessionEvents = getCurrentSessionEvents()
  delete sessionEvents[event.id]
  setSessionItem(SESSION_KEY, sessionEvents)
}


export const isSessionEvent = (eventId) => {
  return (eventId) ? !!getSessionEvent(eventId) : false
}


addEventListener(EVENT_USER_AUTHENTICATED, clearSessionEvents)
