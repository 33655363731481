/**
 * Team constants
 */

import { THIRTY_DAYS } from "./duration.mjs"

// Defaults
export const TEAM_PLAN_TRIAL = 'Trial'
export const TEAM_PLAN_STANDARD = 'Standard'
export const TEAM_PLAN_PRO = 'Pro'
export const TEAM_PLAN_ENTERPRISE = 'Enterprise'
export const TEAM_PLAN_FREE = 'Free'
export const DEFAULT_TEAM_PLAN = TEAM_PLAN_TRIAL
export const DEFAULT_TEAM_PLAN_TRIAL_DURATION = THIRTY_DAYS
