<template>
  <main-layout v-if="currentUser?.id">
    <q-toolbar class="row items-center q-px-md q-pt-lg q-pb-xs">
      <q-toolbar-title class="text-dark text-weight-bold text-center">{{ $route.meta.title }}</q-toolbar-title>
    </q-toolbar>
  
    <div class="row justify-center items-center mobile-wrapper q-mt-xl">
      <div style="width: 50rem;">
        <div class="column justify-center items-center">
          <q-avatar size="5.3rem" class="shadow-brand4">
            <img v-if="currentUser.photoURL" :src="currentUser.photoURL" alt="">
            <div v-else>{{ currentUser?.displayName?.[0]?.toUpperCase() }}</div>
          </q-avatar>
        </div>
        <div class="row justify-between no-wrap q-mt-lg full-width">
          <q-input v-model="firstNameRef" label="First Name" color="grey-7" bg-color="accent" outlined class="rounded-borders fit q-mr-md"  dense />
          <q-input v-model="lastNameRef" label="Last Name" color="grey-7" bg-color="accent" outlined class="rounded-borders fit"  dense />
        </div>
        <div class="row justify-between no-wrap q-mt-md full-width">
          <q-input disable v-model="currentUser.email" label="Work Email" color="grey-7" bg-color="accent" outlined class="rounded-borders fit q-mr-md"  dense />
          <q-input v-model="phoneNumberRef" label="Phone" color="grey-7" bg-color="accent" outlined class="rounded-borders fit"  dense />
        </div>
        <div class="row justify-center no-wrap q-mt-md full-width">
          <q-btn
            @click="onUpdateDetails"
            no-caps
            unelevated
            class="q-ml-sm rounded-borders"
            style="width: 16rem;"
            color="primary"
            text-color="white"
            label="Save"
          />
        </div>
        <q-separator color="grey-2" class="q-mb-md" style="margin-top: 2rem;" />
        <div class="text-subtitle1 text-weight-bold q-mb-sm">Teams</div>
        <q-item to="/team" class="row justify-start items-center full-width q-pa-xs rounded-borders" clickable>
          <q-avatar rounded class="q-mr-md" color="grey-1" style="border: 1px solid #EBEEF5;">{{ currentTeam.name?.[0]?.toUpperCase() || '' }}</q-avatar>
          <div>
            <div class="text-subtitle1 ">{{ currentTeam.name }}</div>
            <div class="text-primary text-body2 text-weight-medium">{{ memberCount }} Member{{ memberCount > 1 ? 's' : '' }}</div>
          </div>
        </q-item>
        <q-separator color="grey-2" class="q-mt-md" />
        <div class="row justify-center no-wrap q-mt-xl full-width">
          <q-btn
            @click="signOutUser"
            no-caps
            unelevated
            class="q-ml-sm rounded-borders"
            style="width: 16rem;"
            color="primary"
            text-color="white"
            label="Sign out"
          />

        </div>
        <div class="row justify-center no-wrap q-mt-xl full-width">
        <q-btn
            @click="clearCache"
            no-caps
            unelevated
            class="q-ml-sm rounded-borders"
            style="width: 16rem;"
            color="primary"
            text-color="white"
            label="Clear Cache"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<style scoped>
  @media only screen and (max-width: 768px) {
    .mobile-wrapper {
      justify-content: left;
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
</style>

<script setup>

// Styles
import '../styles/theme.css'

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'

// Layouts
import MainLayout from '../layouts/MainLayout.vue'

// Actions
import { currentTeam } from '../ui/ui-states.mjs'
import { currentUser, getCurrentUser, updateUserProfile, signOutUser } from '../actions/current-user.mjs'
import { getTeamMembers, setTeamMember } from '../team/actions/team-actions.mjs'
import { getUserById, updateUser } from '../user/actions/user-actions.mjs'
import { members } from '../team/actions/team-ui.mjs'

// Constants
import { CHAR_SPACE } from '../constants'

// local db
import { clearLocalDb } from '../db-local/local-db.mjs'

// Refs
const memberCount  = ref(null)
const firstNameRef = ref('')
const lastNameRef  = ref('')
const phoneNumberRef = ref('')

// Setup
onMounted(async () => {
  const user = await getCurrentUser()
  if (user?.teamId) {
    memberCount.value = members.value.length
  }
  const [ firstName, lastName ] = user?.displayName?.split(CHAR_SPACE, 2)
  firstNameRef.value = firstName
  lastNameRef.value  = lastName
  phoneNumberRef.value = user?.phoneNumber
})

/**
 * Profile update handler
 */
 function onUpdateDetails() {
  const displayName = `${firstNameRef.value} ${lastNameRef.value}`
  const phoneNumber = `${phoneNumberRef.value}`
  getUserById(currentUser.value.id).then(currentUserEntity => {
    currentUserEntity.displayName = displayName
    currentUserEntity.phoneNumber = phoneNumber
    updateUser(currentUserEntity)

    //update user's displayName in teams where user is a member of
    const teams = currentUserEntity.teams
    teams.forEach(team => {
      setTeamMember({ 
        userId: currentUser.value.id, 
        teamId: team,
        displayName: displayName,
      }) 
    })
  })

  return updateUserProfile({ displayName })
}

/**
 * clear cache
 */
function clearCache() {
  clearLocalDb()
}
</script>
