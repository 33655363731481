

import { unsubscribe, SCOPE_TEAM, SCOPE_GLOBAL } from '../../events/external-events.mjs'

import { addEventViewModify } from './view-modify-events.mjs'
import { addEventPriceListModify } from './price-list-modify-events.mjs'
import { addEventDeleteList } from './delete-list-events.mjs'
import { addEventListRemoveSharingUser } from './remove-user-from-list-sharing-events.mjs'
import { addEventTeamActiveInactive } from '../../team/events/team-active-inactive-events.mjs'
import { addEventUserModify } from '../../user/events/user-team-modify-events.mjs'

let eventController = null
let subscriptions = []

export const startMainMenuEvents = () => {
  eventController = new AbortController()
  addEventViewModify(eventController)
  addEventPriceListModify(eventController)
  addEventDeleteList(eventController, subscriptions)
  addEventListRemoveSharingUser(eventController, subscriptions)
  addEventTeamActiveInactive(eventController, subscriptions)
  addEventUserModify(eventController, subscriptions)
}

export const stopMainMenuEvents = () => {
  eventController?.abort()
  eventController = undefined
  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })
    unsubscribe(subscription, { scope: SCOPE_GLOBAL })
  }
  subscriptions = []

}