// local events
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import {
  EVENT_COLUMN_FILTER_UPDATED_LOCAL,
  EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED,
  EVENT_COLUMN_FILTER_APPLY_TOGGLE_LOCAL,
  EVENT_COLUMN_FILTER_ADD_LOCAL,
  EVENT_COLUMN_FILTER_REMOVE_LOCAL,
  EVENT_COLUMN_FILTER_SET_RULE_CONDITION_LOCAL,
  EVENT_COLUMN_FILTER_SET_RULE_VALUE_LOCAL,
  EVENT_COLUMN_FILTER_ADD_RULE_LOCAL,
  EVENT_COLUMN_FILTER_REMOVE_RULE_LOCAL,
  EVENT_COLUMN_FILTER_SAVE_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE,
  EVENT_COLUMN_FILTER_APPLY_SAVED_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_CANCEL_SAVED_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_REMOTE,
} from '../../events/constants/event-types.mjs'

// external events
import {
  EVENT_COLUMN_FILTER_SAVE_GROUP,
  EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP,
} from '../../events/constants/event-types.mjs'

// column event actions
import { 
  updateFilterColumnsQueryEvent, refreshFilteredColumnsPropsEvent,
  applyFilterToggleEvent, addColumnFilterEvent, removeColumnFilterEvent,
  setColumnFilterRuleConditionEvent, setColumnFilterRuleValueEvent,
  addColumnFilterRuleEvent, removeColumnFilterRuleEvent,
  saveColumnCurrentFilterGroupEvent, saveColumnCurrentFilterGroupRemoteHandler, saveColumnCurrentFilterGroupRemoteEvent,
  deleteColumnFilterGroupEvent, deleteColumnFilterGroupRemoteHandler, deleteColumnFilterGroupRemoteEvent,
  applyColumnFilterGroupEvent, cancelColumnFilterGroupEvent,
} from '../events/filter-column-events.mjs'

import { SCOPE_TEAM, SCOPE_GLOBAL } from '../../events/external-events.mjs'
import { subscribe, unsubscribe } from '../../events/external-events.mjs'

import { columnFilterValue, filteredColumns, isFilteringActive } from '../../ui/ui-states.mjs'

let subscriptions = []
let eventController = null

export const startRightHandMenuEvents = (columnManager, itemManager) => {
  eventController = new AbortController()
  addEventsColumnFilterQuery(eventController, columnManager)
  addEventsColumnFilteredProperties(eventController, columnManager)
  addEventsColumnApplyFilterToggle(eventController, itemManager)
  addEventsColumnAddFilter(eventController, columnManager, itemManager)
  addEventsColumnRemoveFilter(eventController, itemManager)
  addEventsColumnSetFilterRuleCondition(eventController, itemManager)
  addEventsColumnSetFilterRuleValue(eventController, itemManager)
  addEventsColumnAddFilterRule(eventController, itemManager)
  addEventsColumnRemoveFilterRule(eventController, itemManager)
  addEventsColumnSaveCurrentFilterGroup(eventController, subscriptions, itemManager)
  addEventsColumnDeleteFilterGroup(eventController, subscriptions, itemManager)
  addEventsColumnApplyFilterGroup(eventController, itemManager)
  addEventsColumnCancelFilterGroup(eventController, itemManager)
}

export const stopRightHandMenuEvents = () => {
  eventController?.abort()
  eventController = null
  columnFilterValue.value = CHAR_EMPTY
  filteredColumns.value = []
  isFilteringActive.value = false

  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })
  }
  subscriptions = []
}

const addEventsColumnFilterQuery = (controller, columnManager) => {
    addEventListener(
    EVENT_COLUMN_FILTER_UPDATED_LOCAL, 
    (event) => updateFilterColumnsQueryEvent(event, columnManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnFilteredProperties = (controller, columnManager) => {
  addEventListener(
    EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, 
    (event) => refreshFilteredColumnsPropsEvent(event, columnManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnApplyFilterToggle = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_APPLY_TOGGLE_LOCAL, 
    (event) => applyFilterToggleEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnAddFilter = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_ADD_LOCAL, 
    (event) => addColumnFilterEvent(event, columnManager, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnRemoveFilter = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_REMOVE_LOCAL, 
    (event) => removeColumnFilterEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnSetFilterRuleCondition = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_SET_RULE_CONDITION_LOCAL, 
    (event) => setColumnFilterRuleConditionEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnSetFilterRuleValue = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_SET_RULE_VALUE_LOCAL, 
    (event) => setColumnFilterRuleValueEvent(event, itemManager), 
    { signal: controller.signal }
  )
}
const addEventsColumnAddFilterRule = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_ADD_RULE_LOCAL, 
    (event) => addColumnFilterRuleEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnRemoveFilterRule = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_REMOVE_RULE_LOCAL, 
    (event) => removeColumnFilterRuleEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnSaveCurrentFilterGroup = (controller, subscriptions, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_SAVE_GROUP_LOCAL, 
    (event) => saveColumnCurrentFilterGroupEvent(event, itemManager, { scope: SCOPE_GLOBAL }), 
    { signal: controller.signal }
  )

  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_FILTER_SAVE_GROUP,
      saveColumnCurrentFilterGroupRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE,
    (event) => saveColumnCurrentFilterGroupRemoteEvent(event, itemManager),
    { signal: controller.signal }
  )
  return
}

const addEventsColumnDeleteFilterGroup = (controller, subscriptions, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_LOCAL, 
    (event) => deleteColumnFilterGroupEvent(event, itemManager, {scope: SCOPE_GLOBAL }), 
    { signal: controller.signal }
  )

  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP,
      deleteColumnFilterGroupRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_REMOTE,
    (event) => deleteColumnFilterGroupRemoteEvent(event, itemManager),
    { signal: controller.signal }
  )
  return
}

const addEventsColumnApplyFilterGroup = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_APPLY_SAVED_GROUP_LOCAL, 
    (event) => applyColumnFilterGroupEvent(event, itemManager), 
    { signal: controller.signal }
  )
}

const addEventsColumnCancelFilterGroup = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_CANCEL_SAVED_GROUP_LOCAL, 
    (event) => cancelColumnFilterGroupEvent(event, itemManager), 
    { signal: controller.signal }
  )
}