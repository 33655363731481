/*!
 * Team db functions
 */
import { throwError } from '@/utils/errors.mjs'
import { TeamEntity } from '../../entity'
import { TeamDb } from './TeamDb.mjs'

const db = new TeamDb()

/**
 * Get team by id
 * @param {string} id - team id 
 * @returns {Promise} TeamEntity
 */
async function fetchTeamEntity(id) {
  const team = await db.getById(id)
  return team ? (new TeamEntity(team)) : null
}

/**
 * Save team to database
 * @param {Object} teamEntity - team entity object
 * @returns {Promise} document
 */
function saveTeamEntity(teamEntity) {
  const saveObject = db.createDocObject(teamEntity)
  return db.upsert(saveObject)
}

/**
 * Mark team as deleted by teamEntity
 * @param {Object} teamEntity 
 * @returns {Object} TeamEntity
 */
 async function deleteTeamEntity(teamEntity) {
  if (!teamEntity.isDeletable) {
    throwError('Team is not deletable')
  }
  teamEntity.markAsDeleted()
  saveTeamEntity(teamEntity)
  return teamEntity
}

/**
 * DB team query by teamId
 * @param {string} teamId 
 * @returns {Array} An array of DB query condition
 */
function fetchTeamQuery(teamId) {
  return [
    ['id', '==', teamId],
    ['isDeleted', '==', false]
  ]
}

export {
  fetchTeamEntity, saveTeamEntity, deleteTeamEntity,
  fetchTeamQuery,
}
