



// sessions
import { getTeamSession } from '../../session/team-session.mjs'

import { INTEGRATION_SALESFORCE } from '../../integrations/constants/integration-types.mjs'

// salesforce
import { getIntegrationApp } from '../../integration-modules/utils/integration-apps-utils.mjs'

/**
 * Is integration valid to open
 * @param {*} integration 
 */
export const validateIntegration = (integration) => {

  const currentTeam = getTeamSession()
  const teamPlan = currentTeam?.plan
  if (!teamPlan) {
    return {
      valid: false,
      message: 'Missing Subscription Plan'
    }
  }
  // TODO: PL - move to make function when there is more integrations 
  const availPlans = getIntegrationApp(INTEGRATION_SALESFORCE, integration.subtype)?.plans || []
  if (availPlans.includes(teamPlan)) {
    return {
      valid: true
    }
  }
  const planStr = availPlans[0]
  return {
    valid: false,
    message: `This feature is not included in your subscription. Please upgrade to ${planStr}`
  }
}



