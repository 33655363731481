// Dependencies
import { createNotification } from './create-notification.mjs'

// Constants
const NOTIFY_DELAY = 2500

/**
 * Raise a notification
 */
function raiseNotification(
  message = 'Nothing to see here...',
  timeout = NOTIFY_DELAY
) {
  return createNotification({
    message,
    timeout
  })
}

// Exports
export { raiseNotification }
