
// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_IMPORT_BY_QUERY } from '../../../integration-apps/registered-apps.mjs'

// states
import { currentIntegrationId } from '../../../integrations/states/integrations-ui-states.mjs'
import { getActiveIntegration } from '../../../integrations/actions/active-integrations.mjs'

// events
import { EVENT_INTEGRATION_UPDATED_REMOTE } from '../../../events/constants/event-types.mjs'

import {
  clearImportByQueryConfigurationUiStates,
  setImportByQueryConfigurationUiStates,
  onRemoteSalesforceImportByQueryConfigUpdated,
  createImportByQueryConfigurationFromUi,
} from './salesforce-import-by-query-configuration-ui.mjs'

import { registerAppFunctions } from '../../../integration-modules/utils/integration-apps-utils.mjs' 
let eventController = null


/**
 * Import By Query
 * @param {*} id 
 * @returns 
 */
export const startSalesforceImportByQueryUi = async (id) => {
  registerAppFunctions(
    INTEGRATION_SALESFORCE,
    APP_SFDC_IMPORT_BY_QUERY,
    {
      createConfigurationFunc: createImportByQueryConfigurationFromUi,
      setConfigurationUiStatesFunc: setImportByQueryConfigurationUiStates
    }
  )
  if (!id) {
    clearImportByQueryConfigurationUiStates()
    return
  }
  const integration = await getActiveIntegration(id)

  // if no id, it's new
  if (integration) {
    setImportByQueryConfigurationUiStates(integration)
  }
  currentIntegrationId.value = id
  //init load
  let eventController = new AbortController()
  addEventListener(EVENT_INTEGRATION_UPDATED_REMOTE, onRemoteSalesforceImportByQueryConfigUpdated, { signal: eventController.signal })
}

export const stopSalesforceImportByQueryUi = () => {
  eventController?.abort()
  eventController = null
  clearImportByQueryConfigurationUiStates()
}
