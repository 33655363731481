<template>

  <!-- Cell Container -->
  <div>
    <q-btn style="margin-left: 11px;" v-if="showStatus" size="xs" flat round color="grey" icon="o_circle" />
    <q-tooltip>Item Status</q-tooltip>
  </div>
  <!--/ Cell Container -->

</template>

<script>

// TODO: JS allow sort by status

/**
 * Setup
 */
function setup() {
  const isLibrary = location.href.includes('/library')
  const isView = location.href.includes('/view')
  return { showStatus:  isLibrary || isView }
  // return { tooltip }
}

// Exports
export default {
  setup
}

</script>
