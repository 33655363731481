/*!
 * Grid manager entity
 */
import { ref, triggerRef } from 'vue'

// Utils
import { throwError } from '../../utils'
import { TRUE, FALSE } from '../../constants'

// col defs
import { libraryFirstColumn, createNewColumnColDef, defaultColDef } from '../../ui/components/sheet.mjs'
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
const DEFAULT_HEADER_HEIGHT = 48
import { GridColumnManager } from './GridColumnManager.mjs'

export class LibraryColumnManager extends GridColumnManager {
  constructor({
    ...props
  } = {}) {
    super({ ...props })
    this.firstColumnDef = libraryFirstColumn()
    this.lastColumnDef = createNewColumnColDef()
  }

  //#region //--- Property Getters ---//
  //#endregion

  //#region //--- Property Setters ---//
  //#endregion



  //#region //--- Public methods ---//
  //#endregion

  //#region //--- Private Event hander methods ---//
  //#endregion


    //#region //--- Private methods ---//
  //#endregion


  //#region //--- Grid methods ---//

  //#endregion


  //#endregion

}
