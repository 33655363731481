// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// local events
import { dispatch } from '../../events/dispatch.mjs'

// external events
import { subscribe, SCOPE_GLOBAL } from '../../events/external-events.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

//actions 
import { isTeamLoaderLoading } from '../../team/actions/team-ui.mjs'

const EVENT_USER_TEAMID_UPDATE = 'user-teamId-update'
const EVENT_USER_TEAMID_UPDATE_REMOTE = 'user-teamId-update-remote'

export const addEventUserModify = (controller, subscriptions) => {
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_USER_TEAMID_UPDATE,
      userTeamIdUpdateRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )

  // remote
  addEventListener(
    EVENT_USER_TEAMID_UPDATE_REMOTE,
    (event) => userTeamIdUpdateRemoteEvent(event),
    { signal: controller.signal }
  )
  return
}


//#region //--- User ---//
/**
 * Add external event handler - user teamId update
 * @param {Object} event
 * @param {string} event.eventId
 * @param {string} event.payload.userId
 * @param {string} event.payload.teamId
 * @param {string} event.payload.checkoutSessionId
 * @returns 
 */
const userTeamIdUpdateRemoteHandler = async (event) => {
  console.log('userTeamIdUpdateRemoteHandler :', event)
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { userId, teamId, checkoutSessionId } = payload //TODO: JL - userId and teamId
  dispatch(EVENT_USER_TEAMID_UPDATE_REMOTE, { userId, teamId, checkoutSessionId })
}

/**
 * Add event remote - user teamId update
 * @param {Object} event
 * @param {string} event.detail.userId
 * @param {string} event.detail.teamId
 * @param {string} event.detail.checkoutSessionId
 * @returns 
 */
const userTeamIdUpdateRemoteEvent = async (event) => {
  const { userId, teamId, checkoutSessionId } = event.detail
  isTeamLoaderLoading.value = false
  location.reload()
}



