/*!
 * Base database functions
 */

// Firestore
import {
  collection,
  doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc,
  where, query
} from 'firebase/firestore'

// Utils
import { required } from '@/utils'
import { getUserSession } from '@/session'

// DB
import {
  BATCH_SET, BATCH_UPDATE, BATCH_DELETE
} from '@/db/db-constants.mjs'

import {
  dbPool, createDoc, createDeleteDoc, createUndeleteDoc,
  createDbPoolBatches, commitBatches
} from '@/db/db-utils.mjs'


export class Db {
  collectionName
  constructor({
    collectionName
  } = {}) {
    this.collectionName = collectionName
  }

  getCollection() {
    const db = dbPool.getDb()
    return collection(db, this.collectionName)
  }

  /**
   * Get doucment by Id
   * @param {*} collectionName 
   * @param {*} id 
   * @returns 
   */
  async getById(id = required('id')) {
    const db = dbPool.getDb()
    const docRef = doc(db, this.collectionName, id)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
      && { ...docSnap.data() }
  }

  /**
   * Get doucments by Team Id
   * @param {*} collectionName 
   * @param {*} teamid 
   * @returns 
   */
   async getByTeamId(teamId = required('teamid')) {
    const conditionsArr = [
      ['teamId','==', teamId],
      ['isDeleted', '==', false],
    ]
   return this.getByConditions(conditionsArr)
  }

  /**
   * Get by ids
   * @param {*} ids (Array)
   * @returns 
   */
  async getByIds(ids = required('ids')) {
    if (ids.length === 0) {
      return []
    }
    const conditionsArr = [
      ['id', 'in', ids]
    ]
    return this.getByConditions(conditionsArr)
  }

  /**
   * Get documents by query
   * @param {*} collectionName 
   * @param {*} conditionsArr 
   * @returns {Object[]} an array of documents
   */
  async getByConditions(conditionsArr) {
    const db = dbPool.getDb()
    const c = this.getCollection(this.collectionName)
    const conditions = (conditionsArr)
      ? conditionsArr.map(i => where(i[0], i[1], i[2]))
      : []
    const q = query(c, ...conditions)
    const docSnap = await getDocs(q)
    const docs = []
    docSnap.forEach(doc => {
      docs.push({ id: doc.id, ...doc.data() })
    })
    return docs
  }

  /**
   * Create one document in collection
   */
  async upsert(
    {
      id,
      ...data
    } = required('data'),
  ) {
    const newDoc = createDoc({ ...data, id })
    const db = dbPool.getDb()
    const docRef = doc(db, this.collectionName, newDoc.id)
    return setDoc(docRef, newDoc, { merge: true })
  }

  /**
   * delete one document
   */
  async delete(
    {
      id = required('id'),
      ...data
    },
  ) {
    const newDoc = createDeleteDoc(data)
    if (!newDoc.isDeletable) {
      return
    }
    const db = dbPool.getDb()
    const docRef = doc(db, this.collectionName, id)
    return updateDoc(docRef, newDoc)
  }

  /**
   * delete one document
   */
  async undelete(
    {
      id = required('id'),
      ...data
    },
  ) {
    const newDoc = createUndeleteDoc(data)
    const db = dbPool.getDb()
    const docRef = doc(db, this.collectionName, id)
    return updateDoc(docRef, newDoc)
  }

  /**
   * Remove one document permenantly
   */
  async remove(
    {
      id = required('id'),
      ...data
    },
  ) {
    const db = dbPool.getDb()
    const docRef = doc(db, this.collectionName, id)
    return deleteDoc(docRef)
  }

  /**
   * upsert documents in batches
   */
  async upsertBatch(docList) {
    const user = getUserSession()
    if (docList.length === 0) {
      return
    }
    const batches = createDbPoolBatches(
      docList.map(data => createDoc(data))
    )
    return commitBatches(this.collectionName, batches, BATCH_SET)
  }

  async upsertBatchRaw(docList) {
    const user = getUserSession()
    if (docList.length === 0) {
      return
    }
    const batches = createDbPoolBatches(docList)
    return commitBatches(this.collectionName, batches, BATCH_SET)
  }



  /**
   * Delete documents in batches
   */
  async deleteBatch(docList) {
    const user = getUserSession()
    if (docList.length === 0) {
      return
    }
    const batches = createDbPoolBatches(
      docList.map(data => createDeleteDoc(data))
        .filter(data => data.isDeletable)
    )
    return commitBatches(this.collectionName, batches, BATCH_UPDATE)
  }


  /**
   * Undelete documents in batches
   */
  async undeleteBatch(docList) {
    const user = getUserSession()
    if (docList.length === 0) {
      return
    }
    const batches = createDbPoolBatches(
      docList.map(data => createUndeleteDoc(data))
    )
    return commitBatches(this.collectionName, batches, BATCH_UPDATE)
  }

  /**
   * Remove documents in batches
   */
  async removeBatch(docList) {
    const user = getUserSession()
    if (docList.length === 0) {
      return
    }
    const batches = createDbPoolBatches(docList)
    return commitBatches(this.collectionName, batches, BATCH_DELETE)
  }

  createDocObject(entity) {
    return {
      id: entity.id,
      isDeletable: entity.isDeletable,
      isDeleted: entity.isDeleted,
      isEditable: entity.isEditable,
      createdAt: entity.createdAt,
      createdBy: entity.createdBy,
      updatedAt: entity.updatedAt,
      updatedBy: entity.updatedBy,
      deletedAt: entity.deletedAt,
      deletedBy: entity.deletedBy,
      updatedAtInterval: entity.updatedAtInterval,
      updatedByEventId: entity.updatedByEventId
    }
  }
}

