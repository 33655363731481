import { libraryFilterGroups } from '../../ui/ui-states.mjs'

function onLibraryFiltersUpsert({ id, data }) {
  if (!libraryFilterGroups.value) {
    libraryFilterGroups.value = []
  }
  const idx = libraryFilterGroups.value.findIndex(fg => fg.id === id)
  if (idx >= 0) {
    libraryFilterGroups.value[idx] = data
  } else {
    libraryFilterGroups.value.push(data)
  }
  libraryFilterGroups.value = libraryFilterGroups.value.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
}

function onLibraryFiltersRemoved({ id, data }) {
  if (!libraryFilterGroups.value) {
    return
  }
  const idx = libraryFilterGroups.value.findIndex(fg => fg.id === id)
  if (idx >= 0) {
    libraryFilterGroups.value.splice(idx, 1)
  }
}

export { onLibraryFiltersUpsert, onLibraryFiltersRemoved }
