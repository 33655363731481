import { required } from './required.mjs'

/**
 * Copy value to clipboard
 */
function copyTextToClipboard(text = required('text to copy to clipboard')) {
  return navigator.clipboard.writeText(text)
}

// Exports
export { copyTextToClipboard }
