import { getRandomNumberUpTo } from './random.mjs'

const waitMessages = [
  "Reticulating splines...",
  "Generating witty dialog...",
  "Swapping time and space...",
  "Spinning violently around the y-axis...",
  "Tokenizing real life...",
  "Bending the spoon...",
  "Filtering morale...",
  "Don't think of purple hippos...",
  "We need a new fuse...",
  "Aww gee, we'll be a little longer...",
  "Have a happy day.",
  "Upgrading Windows, your PC will restart several times. Sit back and relax.",
  "The architects are still drafting",
  "The bits are breeding",
  "We're building the buildings as fast as we can",
  "Would you prefer chicken, steak, or tofu?",
  "(Pay no attention to the man behind the curtain)",
  "...and enjoy the elevator music...",
  "Please wait while the little elves draw your map",
  "Don't worry - a few bits tried to escape, but we caught them!",
  "Would you like fries with that?",
  "Checking the gravitational constant in your locale...",
  "Go ahead -- hold your breath!",
  "...at least you're not on hold...",
  "Hum something loud while others stare",
  "You're not in Kansas any more",
  "We're testing your patience",
  "As if you had any other choice",
  "Follow the white rabbit",
  "Why don't you order a sandwich?",
  "While the satellite moves into position",
  "The bits are flowing slowly today",
  "Dig on the 'X' for buried treasure... ARRR!",
  "Yeah, soo, we're going to need you to come in on Saturday...",
  "It's still faster than you could draw it",
  "Making sure your products are toasty can take time, grab a coffee.",
  "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
  "I should have had a coffee this morning...",
  "My other loading screen is much faster.",
  "Blame people, not systems, I might be biased though?",
  "Testing on Timmy... Ah, we're going to need another Timmy.",
  "Reconfoobling energymotron...",
  "Are we there yet?",
  "Have you lost weight?",
  "Just count to 10, if you still see this, just read this again.",
  "Why so serious?",
  "It's not you. It's me. Well... it could be you.",
  "Try counting backwards from Infinity, nah this will just take a little longer...",
  "Don't panic...",
  "Embiggening Prototypes",
  "Do not run! We are your friends!",
  "Do you come here often?",
  "Warning: Don't set yourself on fire.",
  "We're making you a cookie, not really, cookies are bad right?",
  "Creating time-loop inversion field",
  "Spinning the wheel of fortune...",
  "Loading the enchanted bunny...",
  "Computing chance of success",
  "Looking for exact change",
  "Where was I? ah that's right - loading...",
  "What do you call 8 Hobbits? A Hobbyte.",
  "Should we have used a compiled language...",
  "Is this Windows?",
  "Adjusting flux capacitor...",
  "Please wait until the sloth starts moving.",
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute...",
  "Unicorns are at the end of this road, I promise.",
  "Listening for the sound of one hand clapping...",
  "Keeping all the 1's and removing all the 0's...",
  "Putting the icing on the cake. The cake is not a lie...",
  "Cleaning off the cobwebs...",
  "Making sure all the i's have dots...",
  "Where did all the internets go?",
  "Connecting Neurotoxin Storage Tank...",
  "Granting wishes...",
  "Time flies when you’re having fun.",
  "Go grab a coffee and come back in a minute..",
  "Spinning the hamster…",
  "99 bottles of beer on the wall..",
  "Stay awhile and listen..",
  "Load it and they will come",
  "Convincing AI not to turn evil..",
  "There is no spoon. Because we are not done loading it",
  "Your left thumb points to the right and your right thumb points to the left.",
  "Wait, do you smell something burning?",
  "Computing the secret to life, the universe, and everything.",
  "When nothing is going right, go left!!...",
  "Why are they called apartments if they are all stuck together?",
  "Life is Short – Talk Fast!!!!",
  "Save water and shower together",
  "Whenever I find the key to success, someone changes the lock.",
  "I’ve got problem for your solution… won't be a moment",
  "Where there’s a will, there’s a relative.",
  "I think I am, therefore, I am. I think.",
  "Constructing additional pylons...",
  "Roping some seaturtles...",
  "We are not liable for any broken screens as a result of waiting.",
  "Hello IT, have you tried turning it off and on again? Or maybe just wait a little longer...",
  "If you type Google into Google do you break the internet?",
  "They just toss us away like yesterday's jam.",
  "Dividing by zero..., nah just joking, but i'm doing stuff, promise.",
  "If I’m not back in five minutes, just wait longer.",
  "Cracking military-grade encryption...",
  "Simulating traveling salesman...",
  "Entangling superstrings...",
  "Twiddling thumbs...",
  "Searching for plot device...",
  "Looking for sense of humour, please hold.",
  "Please hold on as we reheat our coffee",
  "Winter is coming...",
  "Switching to the latest JS framework...",
  "Distracted by cat gifs",
  "Finding someone to hold my beer",
  "@todo Insert witty loading message",
  "Let's hope it's worth the wait",
  "Aw, snap! Not..",
  "Ordering 1s and 0s...",
  "Whatever you do, don't look behind you...",
  "Please wait... Consulting the manual...",
  "It is dark. You're likely to be eaten by a grue.",
  "Loading funny message...",
  "Feel free to spin in your chair",
  "What's under there?",
  "Go ahead, hold your breath and do an ironman plank till loading complete",
  "Help, I'm trapped in a loader!",
  "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
  "Mining some bitcoins... I mean, loading...",
  "Downloading more RAM...",
  "Initializing the initializer thingy...",
  "When was the last time you dusted around here?",
  "Optimizing the optimizer...",
  "Last call for the data bus! All aboard!",
  "Running swag sticker detection...",
  "Never let a computer know you're in a hurry.",
  "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
  "Shovelling coal into the server",
  "Pushing pixels...",
  "How about this weather, eh?",
  "Patience! This is difficult, you know...",
  "Discovering new ways of making you wait...",
  "Your time is very important to us. Please wait a little longer...",
  "Time flies like an arrow; fruit flies like a banana",
  "Two men walked into a bar; the third ducked...",
  "Sooooo... Have you seen my vacation photos yet?",
  "Sorry we are busy catching em' all, we're done soon",
  "TODO: Insert elevator music",
  "Still faster than a Windows update",
  "Please wait while the minions do their work",
  "Grabbing extra minions",
  "Doing the heavy lifting",
  "We're working very Hard .... Really",
  "Waking up the minions",
  "You are number 2843684714 in the queue, nah we are processing and all that.",
  "Our premium plan is faster, we slowed this version down so we could get more money to afford new servers.",
  "Feeding unicorns...",
  "Rupturing the subspace barrier",
  "Creating an anti-time reaction",
  "Do you like my loading animation? I made it myself",
  "Whoah, look at it go!",
  "No, I'm awake. I was just resting my eyes.",
  "One mississippi, two mississippi...",
  "Ensuring Gnomes are still short.",
  "Baking ice cream...",
]

const waitMessageLength = waitMessages.length -1

export const getRandomWaitMessage = () => {
  const index = getRandomNumberUpTo(waitMessageLength)
  return waitMessages[index]
}
