// Utils
import { isNumber, isCurrency, isBoolean, isEmpty, isEmptyNullOrUndefined } from '../utils/is.mjs'

// Constants
import { CHAR_COMMA, CHAR_EMPTY, CHAR_PERCENT, CHAR_SPACE } from '../constants/characters.mjs'
import { CHECKBOX_TRUE_VALUES, CHECKBOX_FALSE_VALUES } from '../constants/checkbox.mjs'
import { ONE_THOUSAND } from '../constants/number.mjs'
import { DATE_FORMAT_ISO, DATE_FORMAT_TIMESTAMP_SECOND, DATE_FORMAT_TIMESTAMP_MILLI } from '../constants/date.mjs'
import { 
  REGEX_COMMA_AND_OR_SPACE, REGEX_NUMBER_FROM_CURRENCY, 
  REGEX_DATE_ISO_FORMAT, REGEX_DATE_TIMESTAMP_SECOND, REGEX_DATE_TIMESTAMP_MILLISECOND 
} from '../constants/regex.mjs'


/**
 * Parse currency
 */
function parseCurrency(value) {
  if (isEmptyNullOrUndefined(value)) {
    return null
  }
  if (!isCurrency(value)) {
    return value
  }
  if (value === 'Infinity') {
    return value
  }
  const cleanValue = String(value)
    .match(REGEX_NUMBER_FROM_CURRENCY)
    .join('')
  return Number(cleanValue)
}

/**
 * Parse number
 */
function parseNumber(value) {
  if (isEmptyNullOrUndefined(value)) {
    return null
  }
  if (!isNumber(value)) {
    return value
  }
  return Number(String(value).replaceAll(CHAR_COMMA, CHAR_EMPTY))
}

/**
 * Parse percentage
 */
function parsePercentage(value) {
  if (isEmptyNullOrUndefined(value)) {
    return null
  }
  const hasPercent = String(value)
    .includes(CHAR_PERCENT)
  const cleanValue = String(value)
    .split(CHAR_COMMA).join(CHAR_EMPTY)
    .split(CHAR_PERCENT).join(CHAR_EMPTY)
  if (isNaN(cleanValue)) {
    return NaN
  }
  return Number(cleanValue)
}

/**
 * Parse boolean
 */
function parseBoolean(value) {
  if (isEmptyNullOrUndefined(value)) {
    return null
  }
  if (isBoolean(value)) {
    return value
  }
  const cleanValue = String(value).trim().toUpperCase()
  // Truthy
  if (CHECKBOX_TRUE_VALUES.includes(cleanValue)) {
    return true
  }
  // Falsish
  if (CHECKBOX_FALSE_VALUES.includes(cleanValue)) {
    return false
  }
  // Indeterminate
  return null
}

/**
 * Parse date international format
 * @returns YYYY-MM-DD or null
 */
function parseDateInternational(value) {
  if (isEmptyNullOrUndefined(value)) {
    return null
  }
  const dateType = String(value).match(REGEX_DATE_ISO_FORMAT) ? DATE_FORMAT_ISO
                 : String(value).match(REGEX_DATE_TIMESTAMP_SECOND) ? DATE_FORMAT_TIMESTAMP_SECOND
                 : String(value).match(REGEX_DATE_TIMESTAMP_MILLISECOND) ? DATE_FORMAT_TIMESTAMP_MILLI
                 : null
  const isoString = []
  switch (dateType) {
    case DATE_FORMAT_ISO: 
      isoString.push(value)
      break
    case DATE_FORMAT_TIMESTAMP_MILLI:
      isoString.push(new Date(Number(value)).toISOString())
      break
    case DATE_FORMAT_TIMESTAMP_SECOND:
      isoString.push(new Date(Number(value)*ONE_THOUSAND).toISOString())
      break
    default: 
      return null
  }
  const [cleanValue] = isoString
  const isMatched = REGEX_DATE_ISO_FORMAT?.exec(cleanValue)
  const year = isMatched[1]
  const month = isMatched[2]
  const day = isMatched[3]
  return `${year}-${month}-${day}`
}

/**
 * Parse taglist
 */
function parseTaglist(value) {
  const parsedValue = isEmptyNullOrUndefined(value)
    ? null
    : String(value)
      .split(REGEX_COMMA_AND_OR_SPACE)
      .filter(tag => !isEmpty(tag))
  return parsedValue
}

// Exports
export { 
  parseCurrency, parseNumber, parsePercentage, 
  parseBoolean, parseDateInternational, parseTaglist
}
