<template>
  <pre-layout>
    <div class="fixed-center text-center">

      <q-card class="register-card text-dark q-pa-md">

        <q-card-section>
          <div class="text-h5 text-weight-bold">Get Started</div>
          <div class="text-body2 text-weight-medium text-grey-6 q-mt-sm">Let's get you some ProductEngine!!</div>
        </q-card-section>

        <q-card-section class="q-pt-md">
          <q-form ref="registrationForm">
            <q-input v-model="email" color="grey-7" bg-color="accent" outlined dense type="email" placeholder="Work email" class="q-mb-sm fit rounded-borders" :clearable="true" :autofocus="!password && !firstName && !lastName"></q-input>

            <template v-if="email">
              <q-card-section horizontal class="q-mb-sm">
                <q-input v-model="firstName" color="grey-7" bg-color="accent" outlined dense type="text" placeholder="First Name" class="q-mr-sm fit rounded-borders">
                </q-input>
                <q-input v-model="lastName" color="grey-7" bg-color="accent" outlined dense type="text" placeholder="Last Name" class="fit rounded-borders">
                </q-input>
              </q-card-section>

              <q-input
                :disable="!firstName || !lastName || !email"
                v-model="password" color="grey-7" bg-color="accent" outlined dense :type="showPassword ? 'password' : 'text'" placeholder="Password" class="fit q-mb-sm rounded-borders">
                <template v-slot:append>
                  <q-icon
                    :name="showPassword ? 'o_visibility_off' : 'o_visibility'"
                    class="cursor-pointer"
                    size="xs"
                    @click="showPassword = !showPassword"
                  />
                </template>
              </q-input>
            
              <q-input 
                v-show="firstName && lastName && email && password.length > 5" 
                v-model="confirmedPassword" color="grey-7" bg-color="accent" outlined dense :type="showPassword ? 'password' : 'text'" placeholder="Confirm Password" class="fit rounded-borders q-mb-sm">
                <template v-slot:append>
                  <q-icon
                    :name="showPassword ? 'o_visibility_off' : 'o_visibility'"
                    class="cursor-pointer"
                    size="xs"
                    @click="showPassword = !showPassword"
                  />
                </template>
              </q-input>
              <q-btn 
                unelevated
                size="md"
                no-caps
                @click="onRegister"
                class="bg-primary full-width q-pa-sm q-mb-md"
                text-color="white"
                style="border-radius: 5px;"
                label="Create Account"
                :disabled="!firstName || !lastName || !email || !password || (password !== confirmedPassword)"
              />
            </template>
          </q-form>
          <p class="text-body2 text-weight-medium q-pt-sm">or</p>
          <q-btn 
            no-caps
            unelevated
            size="md"
            @click="signInWithGoogle"
            class="bg-white text-grey-8 full-width q-pa-sm"
            style="border: 1px solid #E1E5F5; border-radius: 5px;"
            >

            <img class="q-pr-sm" side src="/img/google-logo.png" />
            <div>Continue with Google</div>
          </q-btn>
          <div class="text-grey-6 text-weight-medium text-body2" style="margin-top: 1.2em;">
            <router-link class="text-primary" to="/signin" style="text-decoration: none;">Already have an account?</router-link>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </pre-layout>
</template>

<style scoped>

.register-card {
  background: white;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  width: 25rem;
}

</style>

<script setup>
// Vue
import { useRouter } from 'vue-router'

// Layouts
import PreLayout from '../layouts/PreLayout.vue'

// Actions
import { 
  email, firstName, lastName, password, confirmedPassword, showPassword,
  signUpWithUsernameAndPassword, signInWithGoogle, signInWithMicrosoft
} from '../actions/current-user.mjs'

const router = useRouter()

function onRegister() {
  signUpWithUsernameAndPassword()
  router.replace('/register/verify')
}

</script>
