import { createRouter, createWebHistory } from 'vue-router'
import { signOutUser }    from '../actions/current-user.mjs'
import { getUserSession , getTeamSession } from '../session'
import { isEmulator } from '../db/emulators.mjs'
import { isListViewAllowed } from '../list-core/permissions/list-permissions.mjs'

// Old views
import SignInView          from '../views/SignInView.vue'
import GettingStartedView  from '../views/GettingStartedView.vue'
import ProductImportView   from '../views/ProductImportView.vue'
import ProfileView         from '../views/ProfileView.vue'
import RegistrationView    from '../views/RegistrationView.vue'
import ForgotPasswordView  from '../views/ForgotPasswordView.vue'
import TeamView            from '../views/TeamView.vue'
import SuperAdminView      from '../superadmin/SuperAdminView.vue'
import VerificationNeeded  from '../views/VerificationNeeded.vue'

// New views
import LibraryView         from '../list-library/views/LibraryView.vue'
import ViewListView        from '../list-view/views/ViewListView.vue'
import PriceListView       from '../list-price-list/views/PriceListView.vue'

  // TODO PL: - remove permanently - has not been migrated to event driven
//import PriceListIndexView  from '../list-price-list/views/PriceListIndexView.vue'

// Integrations
import IntegrationsView    from '../integrations/views/IntegrationsView.vue'
import SalesforceImportView    from '../integration-apps/salesforce-import-basic/views/salesforceImportView.vue'
import SalesforcePublishView    from '../integration-apps/salesforce-publish-basic/views/salesforcePublishView.vue'
import SalesforcePublishAdvancedView from '../integration-apps/salesforce-publish-advanced/views/salesforcePublishAdvancedView.vue'
import SalesforceImportByQueryView from '../integration-apps/salesforce-import-by-query/views/salesforceImportByQueryView.vue'

//shopify
//import ShopifyImportAdvancedView    from '../integration-modules/shopify/views/shopifyImportAdvancedView.vue'

import { isTeamLoaderLoading } from '../team/actions/team-ui.mjs'

const routes = [
  {
    path: '/signin',
    component: SignInView,
    meta: {
      title: 'Sign In',
      guestOnly: true,
    }
  },
  {
    path: '/register',
    component: RegistrationView,
    meta: {
      title: 'Register',
      guestOnly: true,
    }
  },
  {
    path: '/forgot',
    component: ForgotPasswordView,
    meta: {
      title: 'Forgot Password',
      guestOnly: true,
    }
  },
  {
    path: '/',
    component: GettingStartedView,
    meta: {
      title: 'Getting Started',
      requiresAuth: true,
    }
  },
  {
    path: '/library',
    component: LibraryView,
    meta: {
      title: 'Product Library',
      requiresAuth: true,
    }
  },
  {
    path: '/deleted',
    component: LibraryView,
    meta: {
      title: 'Recently Deleted',
      requiresAuth: true,
      isRecentlyDeleted: true
    }
  },
  {
    path: '/import',
    component: ProductImportView,
    meta: {
      title: 'Import Products',
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    component: ProfileView,
    meta: {
      title: 'My Profile',
      requiresAuth: true,
    }
  },
  // TODO PL: - remove permanently - has not been migrated to event driven
  /*
  {
    path: '/lists',
    component: PriceListIndexView,
    meta: {
      title: 'Price Lists',
      requiresAuth: true,
    }
  },
  */
  {
    path: '/list/:id',
    component: PriceListView,
    meta: {
      title: 'Price List',
      requiresAuth: true,
    }
  },
  {
    path: '/view/:id',
    component: ViewListView,
    meta: {
      title: 'View List',
      requiresAuth: true,
    }
  },
  {
    path: '/team',
    component: TeamView,
    meta: {
      title: 'My Team',
      requiresAuth: true,
    }
  },
  {
    path: '/billing',
    component: TeamView,
    meta: {
      title: 'My Team',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations',
    component: IntegrationsView,
    meta: {
      title: 'Integrations',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-import',
    component: SalesforceImportView,
    meta: {
      title: 'Salesforce Import Basic',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-import/:id',
    name: 'Salesforce Import Basic',
    component: SalesforceImportView,
    meta: {
      title: 'Salesforce',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-publish',
    component: SalesforcePublishView,
    meta: {
      title: 'Salesforce Publish Basic',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-publish/:id',
    name: 'Salesforce Publish',
    component: SalesforcePublishView,
    meta: {
      title: 'Salesforce Publish Basic',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-publish-advanced',
    component: SalesforcePublishAdvancedView,
    meta: {
      title: 'Salesforce Publish Advanced',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-publish-advanced/:id',
    name: 'Salesforce Publish Advanced',
    component: SalesforcePublishAdvancedView,
    meta: {
      title: 'Salesforce Publish Advanced',
      requiresAuth: true,
    }
  },
  
  {
    path: '/integrations/salesforce-import-by-query',
    component: SalesforceImportByQueryView,
    meta: {
      title: 'Salesforce Import By Query',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/salesforce-import-by-query/:id',
    name: 'Salesforce Import By Query',
    component: SalesforceImportByQueryView,
    meta: {
      title: 'Salesforce',
      requiresAuth: true,
    }
  },
  /*
  {
    path: '/integrations/shopify-import-advanced',
    component: ShopifyImportAdvancedView,
    meta: {
      title: 'Shopify',
      requiresAuth: true,
    }
  },
  {
    path: '/integrations/shopify-import-advanced/:id',
    name: 'Shopify Import Advanced',
    component: ShopifyImportAdvancedView,
    meta: {
      title: 'Shopify',
      requiresAuth: true,
    }
  },
  */
  {
    path: '/superadmin',
    component: SuperAdminView,
    meta: {
      title: 'Super Admin',
      requiresAuth: true,
    }
  },
  {
    path: '/register/verify',
    component: VerificationNeeded,
    meta: {
      title: 'Verification Needed',
      guestOnly: true,
    }
  },
  {
    path: '/team/checkoutsuccess',
    component: TeamView,
    meta: {
      title: 'My Team',
      requiresAuth: true,
    }
  },
]

const router = createRouter({
  linkExactActiveClass: 'is-active',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isGuestOnly  = to.matched.some(record => record.meta.guestOnly)
  const currentUser  = getUserSession()
  const currentTeam   = getTeamSession()

  if (requiresAuth && !currentUser?.id) {
    return next('/signin')
  }

  if (isGuestOnly && currentUser?.id) {
    return next('/')
  }

  if (to.path === '/superadmin' && !isEmulator() && !currentUser?.isSuperAdmin) {
    return next('/')
  }

  if (['/list/','/view/'].some(route => to.path.includes(route))) {
    const canViewList = await isListViewAllowed(currentUser, to.params?.id)
    if (!canViewList) {
      return next('/')
    }
  }

  if (to.path === '/library' && !currentTeam?.isActive) {
    return next('/')
  }
  
  if (to.path.includes('/team/checkoutsuccess')) {
    isTeamLoaderLoading.value = currentTeam?.id ? false : true
  }

  next()
  
})

export default router
