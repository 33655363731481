/*!
 * Productengine usage summary
 */
import { ref } from 'vue'
import { Db } from '@/db/Db.mjs'

export const usageStats = ref({})
export const statNotes = ref('All items were updated by system on 03/11/2022')


const teamDb = new Db({ collectionName: 'teams' })
const userDb = new Db({ collectionName: 'users' })
const itemsDb = new Db({ collectionName: 'items' })
const listsDb = new Db({ collectionName: 'lists' })



export async function getUsageStats() {
  let stats = {}
  const teams = await getActiveTeams()
  for(const team of teams ) {
    const members = await getActiveMembers(team.id)
    const lists = await getActiveLists(team.id)
    const items = await getActiveItems(team.id)
    const noOfPeopleShared = lists.reduce((cnt, l) => { 
      cnt += l.sharedWith.length
      return cnt
    }, 0 )
    const owner = members.find(m => m.id === team.createdBy)
    const sortedItems = items.filter(i => i.updatedAt).sort((a,b) => b.updatedAt-a.updatedAt)
    const lastUpdatedItem = sortedItems[0] || {}
    usageStats.value[team.id] = { 
      [team.name]: `${owner?.email}`,
      "Created Date": convertToDateTime(team.createdAt),
      Members: members.length,
      //activeMembers: members.map(m => m.email),
      "Active Price Lists": lists.length,
      "Shared With": noOfPeopleShared,
      "Active Items": items.length,
      "Last Item UpdatedAt": lastUpdatedItem.updatedAt ? convertToDateTime(lastUpdatedItem.updatedAt) : ''
    }
  }
  return  usageStats.value


}


function convertToDateTime(timestamp) {
  return new Date(timestamp).toLocaleDateString("en-AU")
  //+ ' ' + 
  //new Date(timestamp).toLocaleTimeString("en-AU")
}


async function getActiveTeams() {
 const teams = await teamDb.getByConditions(
    [
      ['environment', '==', 'Production'],
      ['isDeleted', '==', false ],
    ]
  )
  return teams.sort((a,b) => b.createdAt-a.createdAt)
}

async function getActiveMembers(teamId) {
  return userDb.getByConditions(
    [
      ['teamId', '==', teamId],
      ['isDeleted', '==', false]
    ]
  )
}

async function getActiveLists(teamId) {
  const lists = await listsDb.getByConditions(
     [
       ['teamId', '==', teamId ],
       ['type', '==', 'Price List'],
       ['isDeleted', '==', false ],
     ]
   )
  return lists.sort((a,b) => a.name.localeCompare(b.name))
 }
 
 async function getActiveItems(teamId) {
  const items = await itemsDb.getByConditions(
     [
       ['teamId', '==', teamId ],
       ['isDeleted', '==', false ],
     ]
   )
  return items
 }

