
import { FieldSchemaEntity } from '../../entity'
import { triggerRef } from 'vue'

import { FIELD_TYPES } from '../field-schema-constants.mjs'

function onFieldSchemaAdded({ id, data, handlerParams }) {
  const {teamFieldSchemas, fieldMapOptions } = handlerParams
  if (!FIELD_TYPES.includes(data.type)) {
    return
  }
  teamFieldSchemas.value[id] = new FieldSchemaEntity(data)
  fieldMapOptions.value = Object.values(teamFieldSchemas.value)
  .filter(fs => fs.isEditable && !fs.isDeleted)
  .map(fs => ({ label: fs.label, value: fs.key, id: fs.id, fieldSchema : fs }))
  .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()) )
  triggerRef(teamFieldSchemas)
  triggerRef(fieldMapOptions)
}

function onFieldSchemaChanged({ id, data, handlerParams }) {
  if (!FIELD_TYPES.includes(data.type)) {
    return
  }
  const fs = new FieldSchemaEntity(data)
  const {teamFieldSchemas, fieldMapOptions } = handlerParams
  teamFieldSchemas.value[id] = new FieldSchemaEntity(data)
  fieldMapOptions.value = Object.values(teamFieldSchemas.value)
  .filter(fs => fs.isEditable && !fs.isDeleted)
  .map(fs => ({ label: fs.label, value: fs.key, id: fs.id, fieldSchema : fs }))
  .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()) )
  triggerRef(teamFieldSchemas)
  triggerRef(fieldMapOptions)
}

function onFieldSchemaRemoved({ id, data, handlerParams }) {
  if (!FIELD_TYPES.includes(data.type)) {
    return
  }
  const {teamFieldSchemas, fieldMapOptions } = handlerParams
  delete  teamFieldSchemas.value[id]
  fieldMapOptions.value = Object.values(teamFieldSchemas.value)
  .filter(fs => fs.isEditable && !fs.isDeleted)
  .map(fs => ({ label: fs.label, value: fs.key, id: fs.id, fieldSchema : fs }))
  .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()) )
  triggerRef(teamFieldSchemas)
  triggerRef(fieldMapOptions)
}

export { onFieldSchemaAdded, onFieldSchemaChanged, onFieldSchemaRemoved }