/*!
 * Db Utils
 */

// Firestore
import {
  doc,  deleteField, writeBatch,
} from 'firebase/firestore'

// Utils
import { required, getTimestamp, getUpdateInterval, sleep } from '@/utils'
import { getUserSession } from '@/session'

// DB
import {
  COMMIT_BATCH_SIZE, COMMIT_BATCH_WAIT, 
  BATCH_SET, BATCH_UPDATE, BATCH_DELETE,
  DB_INSTANCE_POOL_SIZE
} from '@/db/db-constants.mjs'
import { DbInstance } from '@/db/DbInstance.mjs'

// Global connection pool
const dbPool = new DbInstance({ size: DB_INSTANCE_POOL_SIZE })


/**
 * Create a collection document
 */

function createDoc({
  user = getUserSession(),
  createdAt = getTimestamp(),
  createdBy = user?.uid,
  ...props
} = {}) {
  return {
    ...props,
    createdAt,
    createdBy,
    updatedAt: getTimestamp(),
    updatedBy: user?.uid,
    updatedAtInterval: getUpdateInterval(),
  }
}

/**
 * Mark a collection document as deleted
 */
function createDeleteDoc({
  user = getUserSession(),
  isDeletable = true,
  ...props
} = {}) {
  return {
    ...props,
    isDeletable,
    updatedAt: getTimestamp(),
    updatedBy: user?.uid,
    isDeleted: true,
    deletedAt: getTimestamp(),
    deletedBy: user?.uid,
    updatedAtInterval: getUpdateInterval(),
  }
}

/**
 * Mark a collection document as deleted
 */
function createUndeleteDoc({
  user = getUserSession(),
  ...props
} = {}) {
  return {
    ...props,
    updatedAt: getTimestamp(),
    updatedBy: user?.uid,
    isDeleted: false,
    deletedAt: deleteField(),
    deletedBy: deleteField(),
    updatedAtInterval: getUpdateInterval(),
  }
}

//----------//
// Special notes: if batch write is still not quick enough
//use parallel write with Cloud functions (admin SDK)
// 
//
function createDbPoolBatches(docList) {
  const poolBatches = []
  const size = dbPool.poolSize

  for (let i = 0; i < size; i++) {
    poolBatches[i] = []
  }

  let idx = 0
  for (const data of docList) {
    poolBatches[idx].push(data)
    idx = (++idx >= size) ? 0 : idx
  }
  return poolBatches.filter(batch => batch.length > 0)
}


async function commitBatchesDb(collectionName, batch, index, type) {
  if (!batch || batch.length === 0) {
    return
  }
  const batchSize = COMMIT_BATCH_SIZE
  const db = dbPool.getDbByIndex(index)
  while (batch.length > 0) {
    const commitBatch = batch.splice(0, batchSize)
    // create the batch
    const batchHandle = writeBatch(db)
    for (const data of commitBatch) {
      if (!data.id) {
        continue
      }
      const docRef = doc(db, collectionName, data.id)
      switch (type) {
        case BATCH_UPDATE:
          batchHandle.update(docRef, data)
          break
        case BATCH_DELETE:
          batchHandle.delete(docRef, data)
          break
        default:
          batchHandle.set(docRef, data)
      }
    }
    await batchHandle.commit()
    if (batch.length > 1) { 
      await sleep(COMMIT_BATCH_WAIT)
    }
  }
  return
}

/**
 * CommitBatch with progress status
 * @param {*} batches 
 * @param {*} param1 
 */
async function commitBatches(collectionName, batches, type) {
  if (!type) {
    return
  }
  for (let index = 0; index < batches.length; index++) {
    await commitBatchesDb(collectionName, batches[index], index, type)
  }
}


export { 
  dbPool, createDoc, createDeleteDoc, createUndeleteDoc, 
  createDbPoolBatches, commitBatches 
}

