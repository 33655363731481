

/**
 * Create db item
 * @param {*} item 
 */
export const createDbItem = (item) => {
  for (const key in item.fields) {
    const { inputValue, value, ...props } = item.fields[key]
    item.fields[key] = { inputValue }
  }
  return item
}

/**
 * Create db item
 * @param {*} item 
 */
export const createImportDbItem = (item) => {
  for (const key in item.fields) {
    const { inputValue, value, ...props } = item.fields[key]
    item.fields[key] = { inputValue }
  }
  return item
}