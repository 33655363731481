// utils
import { required } from '../../utils/required.mjs'

// constants
import { DEFAULT_FIELD_TYPE, FIELD_LABEL_NEW_COLUMN, FIELD_LABEL_NO_MAP_COLUMN } from '../../constants/field.mjs'
import { ITEM_TYPE_CSV } from '../../item/constants/item-types.mjs'

// entities
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { ItemEntity } from '../../entity/ItemEntity.mjs'


export const isColumnMapped = (mapColumn) => {
  return mapColumn.label !== FIELD_LABEL_NO_MAP_COLUMN
    && ( mapColumn.fieldSchema && mapColumn.fieldSchema.label !== FIELD_LABEL_NO_MAP_COLUMN)

}

export const isColumnNew = (mapColumn) => {
  return mapColumn.label === FIELD_LABEL_NEW_COLUMN
    || mapColumn.fieldSchema?.label === FIELD_LABEL_NEW_COLUMN
}


export const createNewColumnFieldSchema = (teamId, type = DEFAULT_FIELD_TYPE) => {
  return new FieldSchemaEntity({
    teamId,
    label: FIELD_LABEL_NEW_COLUMN,
    isDeleted: false,
    type
  })
}

export const createNoMapFieldSchema = (teamId) => {
  return new FieldSchemaEntity({
    teamId,
    label: FIELD_LABEL_NO_MAP_COLUMN,
    isDeleted: false
  })
}

export const isEmptyRow = (row) => {
  return !row.some(cell => cell?.length)
}

export const createCsvItem = (
  teamId = required('teamId'), 
  row = required('row'),
  headerRow = required('headerRow'), 
  fieldSchemas = required('fieldSchemas'),
) => {
  const columnLabelMap = new Map(fieldSchemas.map(col => [col.label.toLowerCase(), col]))
  let fields = {}
  headerRow.forEach((label, index) => {
    const fs = columnLabelMap.get(label.toLowerCase())
    if (!fs || label.length === 0) {
      return
    }
    fields[fs.key] = {
      inputValue: row[index],
      formattedValue: row[index],
      value: row[index]
    }
  })
  return new ItemEntity({
    id: fields.rowId?.value || undefined,
    teamId,
    isStatic: true,
    type: ITEM_TYPE_CSV,
    fields
  })
}


