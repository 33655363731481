/**
 * Timestamp util functions
 */

// Dependencies
import { SECONDS_IN_A_MINUTE, MILLIS_IN_ONE_SECOND } from '../constants/duration.mjs'
/**
 * Get timestamp
 */
function getTimestamp() {
  return Date.now()
}

/**
 * Get timezone offset
 * @returns local timezone offset in millisecond
 */
function getTimezoneOffset() {
  return new Date().getTimezoneOffset() * SECONDS_IN_A_MINUTE * MILLIS_IN_ONE_SECOND 
}

/**
 * Convert date to Unix time in millisecond
 * @param {date} date in YYYY-MM-DD format
 * @returns Unix time in millisecond
 */
function getTimestampByDate(date){
  return new Date(date).getTime()
}

// Exports
export { getTimestamp, getTimezoneOffset, getTimestampByDate }
