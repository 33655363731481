import { createNotification } from './create-notification.mjs'
import { openBillingPortal } from '../team/actions/team-ui.mjs'

function openManageSubscription() {
  openBillingPortal()
  return
}
/**
 * Raise delete confirmation
 */
export const raiseSubscriptionAlert = (message, {
  manageLabel = 'Manage Subscription',
  cancelLabel = 'Cancel',
} = {}) => {

  return createNotification({
    message,
    icon: 'warning',
    color: 'red-8',
    position: 'center',
    timeout: 0,
    textColor: 'white',
    actions: [
      { label: manageLabel, color: 'white', handler: openManageSubscription },
      { label: cancelLabel, color: 'white' },
    ]
  })
}

