// Dependencies
import { ref } from 'vue'

// Constants
const IS_MENU_OPEN = false

// Refs
const isSearchMenuOpen = ref(IS_MENU_OPEN)

/**
 * Open search menu
 */
function openSearchMenu() {
  isSearchMenuOpen.value = true
}

/**
 * Close search menu
 */
function closeSearchMenu() {
  isSearchMenuOpen.value = false
}

// Exports
export {
  isSearchMenuOpen,
  openSearchMenu, closeSearchMenu
}
