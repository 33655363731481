/*!
 * team actions
 */

import { required } from '@/utils/required.mjs'

// entities
import { TeamEntity } from '../../entity'

// database adapters
import { saveTeamEntity, fetchTeamEntity, deleteTeamEntity } from '../db/team-db.mjs'

// actions
import { getTeamRpc } from '../rpcs/team-rpcs.mjs'
import { getUserSession } from '../../session/user-session.mjs'

import { CHAR_EMPTY } from '../../constants/characters.mjs'

//#region team  actions

/**
 * Create team
 */
function createTeam({
  name = required('name'),
}) {
  const newTeam = new TeamEntity({ name })
  saveTeamEntity(newTeam)
  return newTeam
}

/**
 * Create team using cloud function
 * @param {string} [name] - name of the team 
 */
function createTeam_cloudfn(name = CHAR_EMPTY) {
  const teamCloudFn = getTeamRpc()
  const userSession = getUserSession()
  teamCloudFn({
    action: 'create-team',
    payload: {
      user: userSession,
      teamName: name
    }
  })
}

function createTeam_demo_cloudfn(name = CHAR_EMPTY) {
  const teamCloudFn = getTeamRpc()
  const userSession = getUserSession()
  return teamCloudFn({
    action: 'create-demo-team',
    payload: {
      user: userSession,
      teamName: name
    }
  })
}
async function createTeamLibrary_cloudfn(team) {
  const teamCloudFn = getTeamRpc()
  const newLibraryDoc = await teamCloudFn({
    action: 'create-team-library',
    payload: {
      team: team
    }
  })
  return newLibraryDoc
}

/**
 * Get team by teamId
 * @param {string} TeamId
 * @returns {Promise} TeamEntity
 */
async function getTeamById(teamId) {
  const teamEntity = await fetchTeamEntity(teamId)
  if (!teamEntity) {
    return
  }
  return teamEntity
}

/**
 * Update team by teamEntity
 * @param {Object} teamEntity 
 * @returns {Object} TeamEntity
 */
function updateTeam(teamEntity) {
  saveTeamEntity(teamEntity)
  return teamEntity
}

/**
 * Delete team by teamId
 * @param {string} teamId 
 * @returns {Object} TeamEntity
 */
async function deleteTeam(teamId) {
  const teamEntity = await getTeamById(teamId)
  return deleteTeamEntity(teamEntity)
}

/**
 * Get team entities from a user's team membership. 
 * @param {Object} user - user object required
 * @returns {Object[]} team entities
 */
async function getTeamsByUser(user) {
  const promise = Promise.all(user.teams.map(async teamId => {
    return getTeamById(teamId)
  }))
  return promise
}

/**
 * Get the user's current team. This is not getting current team session.
 * @param {Object} user - user object required
 * @returns {Object[]} team entity
 */
async function getCurrentTeamByUser(user) {
  if (!user.teamId) return CHAR_EMPTY
  return await getTeamById(user.teamId)
}

//#endregion

//#region team member actions

/**
 * Get Team Members
 * @param {string} teamId
 * @returns {Promise[]} member object array
 */
async function getTeamMembers(
  teamId = required('teamId')
) {
  return await getTeamById(teamId).then(team => team.members)
}

/**
 * set (add or update) a team member
 * @param {string} userId
 * @param {string} teamId
 * @returns {Promise} TeamEntity object
 */
async function setTeamMember({
  userId = required('userId'),
  teamId = required('teamId'),
  email,
  displayName,
  role,
  acceptedAt
}) {
  const teamEntity = await getTeamById(teamId).then(team => {
    const member = team.getMember(userId) || {}
    member.userId = userId || member.userId
    member.email = email || member.email
    member.displayName = displayName || member.displayName
    member.role = role || member.role
    member.acceptedAt = acceptedAt || member.acceptedAt
    team.addMember(member)
    updateTeam(team)
  })
  return teamEntity
}

/**
 * Remove a team member
 * @param {string} userId
 * @param {string} teamId
 * @returns {Promise} TeamEntity
 */
async function removeTeamMember(
  userId = required('userId'),
  teamId = required('teamId'),
) {
  const teamEntity = await getTeamById(teamId).then(team => {
    team.removeMember(userId)
    return updateTeam(team)
  })
  return teamEntity
}

/**
 * Modify teams and users collections  with new team membership structure using cloud function
 */
function migrateTeamsUsersMembership_cloudfn() {
  const teamCloudFn = getTeamRpc()
  const userSession = getUserSession()
  teamCloudFn({
    action: 'migrate-team',
    payload: {
      user: userSession,
    }
  })
}

//#endregion
export { 
  getTeamById, getTeamsByUser, getCurrentTeamByUser, 
  createTeam, updateTeam, deleteTeam,
  getTeamMembers, setTeamMember, removeTeamMember, 
  createTeam_cloudfn, migrateTeamsUsersMembership_cloudfn, createTeamLibrary_cloudfn, 
  createTeam_demo_cloudfn,
}
