<template>
  <q-dialog v-model="isCreateListOpen" @before-show="onCreateListOpen()">
    <q-card style="border-radius: 20px; width: 29rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Create {{ !isCreatePriceList ? 'View' : 'Price List' }}</div>
      </q-card-section>

      <form @submit.prevent="onListSave">
        <q-card-section class="q-pt-none">
          <q-select v-model="listType" color="black" bg-color="accent" outlined dense class="rounded-borders q-my-sm"
            menu-anchor="bottom left" :options="listTypes" v-show="!isListTypeSelectReadonly" />
          <q-input v-if="listType.value === 'new'" v-model="name" bg-color="accent" outlined dense
            placeholder="New name" />
          <q-select v-else v-model="existingList" color="black" bg-color="accent" outlined use-input input-debounce="0"
            class="rounded-borders" dense menu-anchor="bottom left" :options="allFilteredLists"
            :label="existingList.id ? void 0 : 'Select existing view / price list'"
             @filter="onFilterLists">
            <template v-slot:selected>{{ existingList.name }}</template>
            <template v-slot:option="scope">
              <q-item v-if="!scope.opt.group" v-bind="scope.itemProps" class="row items-center q-ma-xs rounded-borders"
                clickable>{{ scope.opt.name }}</q-item>
            </template>
          </q-select>

          <template v-if="listType.value === null">
            <!-- List Type Selection -->
            <div style="font-weight: 500; margin-top: 1rem; display: flex; gap: 1rem;">
              <q-radio v-show="isLibraryList" v-model="listUpdateType" :val="listUpdateTypes[TYPE_VIEW].value"
                :label="listUpdateTypes[TYPE_VIEW].label" />
              <q-radio v-model="listUpdateType" :val="listUpdateTypes[TYPE_PRICE_LIST].value"
                :label="listUpdateTypes[TYPE_PRICE_LIST].label" />
            </div>
            <p style="font-size: .85em; margin-left: 0.5rem;">
              <template v-if="listUpdateType === listUpdateTypes[TYPE_VIEW].value">
                Subset of product items and columns of the library.
              </template>
              <template v-else>
                Snapshot of product items and columns of the library.
              </template>
            </p>
          </template>

        </q-card-section>
        <q-card-section v-if="!isCreatePriceList || listType.value === 'new'" class="q-pt-none">
          <div class="text-weight-medium q-mt-md">Columns</div>
          <p style="font-size: .85em;">Select the library columns you wish to have available on the {{ !isCreatePriceList ?
            'view' : 'price list' }}.</p>
          <q-input color="grey-10" bg-color="accent" outlined class="full-width rounded-borders q-mt-sm" dense
            v-model="selectedColumnsSearchValue" placeholder="Filter columns" @keyup="onFilterColumn()"
            @clear="onFilterColumn()" clearable>
            <template v-slot:prepend>
              <q-icon color="dark" name="search" />
            </template>
          </q-input>

          <div class="q-py-xs" style="overflow: auto; max-height: 8rem;">
            <q-item v-for="column in filteredColumns" :key="column.id" dense
              class="row justify-between items-center q-px-xs q-py-none">
              <q-checkbox :model-value="selectedColumns.findIndex(x => x.key === column.key) !== -1"
                @click="onCheckboxSelected(column)" :label="column.label" color="primary" />
            </q-item>
          </div>
          <q-checkbox v-model="notifyMembers" label="Notify all team members" color="primary"
            class="hidden text-grey-8 q-mt-sm" />
        </q-card-section>
        <q-card-section v-show="isCreatePriceList" class="q-pt-none">
          <div class="text-weight-medium q-mt-md">Export Permission</div>
          <q-select v-model="listExportPermission" color="black" bg-color="accent" outlined
            class="rounded-borders q-my-sm" menu-anchor="bottom left"
            :label="`Who can export this ${!isCreatePriceList ? 'view' : 'price list'}?`" :options="listExportPermissions"
            dense>
            <template v-slot:prepend>
              <q-icon name="lock_open" />
            </template>
          </q-select>
        </q-card-section>
        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <!-- Cancel Button -->
          <q-btn no-caps unelevated class="rounded-borders" color="accent" text-color="dark" label="Cancel"
            style="width: 6.3rem;" v-close-popup @click="onCancelList()" />

          <!-- Done Button-->
          <q-btn
            :disable="(listType.value === 'new' && !name || listType.value === 'existing' && !existingList.id || listExportPermission === null || !selectedColumns.length)"
            no-caps unelevated type="submit" class="q-ml-sm rounded-borders" color="primary" text-color="white"
            label="Done" style="width: 6.3rem;" v-close-popup />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isShareListOpen" @before-show="onShareListOpen()">
    <q-card style="border-radius: 20px; width: 29rem;" class="q-pa-md">
      <q-card-section class="q-pb-none">
        <div class="text-h6">Share with...</div>
      </q-card-section>

      <form @submit.prevent="shareList">
        <q-card-section class="q-pt-none">
          <q-select :autofocus=true ref="shareWithEmails" v-model="sharedWithMeViewers" color="black" bg-color="accent"
            outlined class="rounded-borders q-mt-md" hide-dropdown-icon dense
            placeholder="Enter names or emails to share this price list with" use-input fill-input use-chips multiple
            input-debounce="0" @new-value="createValue" @input-value="setCurrentEmailValue"
            style="width: 25rem;" :options="filterTeamMembers"
            @filter="onTeamMemberfilterFn">
            <template v-slot:no-option>
              <q-item>
                <q-item-section>
                  No results
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected-item="scope">
              <q-chip removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex">
                <q-avatar color="primary" text-color="white">
                  <q-icon v-if="!scope.opt.label" name="email" />
                  <span v-else>{{ scope.opt.label?.[0]?.toUpperCase() }}</span>
                </q-avatar>
                {{ scope.opt.label || scope.opt.value }}
              </q-chip>
            </template>
          </q-select>
          <q-btn @click="showMessage = !showMessage" v-if="!showMessage" no-caps unelevated flat class="q-mt-sm q-pa-xs"
            color="primary" label="Add message" />
          <template v-if="showMessage || message.length">
            <div class="text-body1 text-weight-bold q-mt-md">Message</div>
            <q-input :autofocus=true v-model="message" placeholder="Message your audience" color="black" bg-color="accent"
              outlined class="rounded-borders q-mt-sm" type="textarea" />
          </template>
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <q-btn no-caps unelevated class="rounded-borders" color="accent" text-color="dark" label="Cancel"
            style="width: 6.3rem;" v-close-popup />
          <q-btn no-caps unelevated type="submit" class="q-ml-sm rounded-borders" color="primary" text-color="white"
            label="Done" style="width: 6.3rem;" v-close-popup />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isRemoveUserFromListConfirmModalOpen">
    <q-card style="border-radius: 20px; width: 25rem;">
      <q-card-section>
        <div class="text-h6">Remove {{ !isCreatePriceList ? 'View' : 'Price List' }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-justify text-body2">
          Are you sure you wish to remove yourself from this {{ !isCreatePriceList ? 'view' : 'price list' }}?
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-mb-sm q-mr-sm">
        <q-btn @click="initList" no-caps unelevated class="rounded-borders" color="accent" text-color="dark"
          label="Cancel" style="width: 6.3rem;" v-close-popup />
        <q-btn @click="removeUserFromList(selectedList, currentUser.email)" no-caps unelevated
          class="q-ml-sm rounded-borders" color="red-12" text-color="white" label="Remove" style="width: 6.3rem;"
          v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
textarea {
  resize: none !important;
}
</style>

<script setup>

// Dependencies
import { ref } from 'vue'

// Actions
import { currentUser } from '../../actions/current-user.mjs'
import { getCurrentTeam } from '../../actions/current-team.mjs'

/** NEW **/

import {
  isCreateListOpen, isShareListOpen,
  selectedList, isRemoveUserFromListConfirmModalOpen,
  isLibraryList, selectedItemsCount,
} from '../../ui/ui-states.mjs'

import { updateSharedWith } from '../../list-core/actions/update-shared-with.mjs'

import { getAllTeamMembers } from '../../team/actions/team-ui.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import { isEmail } from '../../utils/is.mjs'


// events
import { dispatch } from '../../events/dispatch.mjs'
import { EVENT_PROGRESS_START } from '../../events/constants/event-types.mjs'
import { 
  EVENT_OPEN_CREATE_VIEW,
  EVENT_CREATE_VIEW,
  EVENT_OPEN_CREATE_PRICE_LIST,
  EVENT_CREATE_PRICE_LIST,
  EVENT_LIST_REMOVE_SHARING_USER_LOCAL
} from '../../events/constants/event-types.mjs'

import { getListAndColumns } from '../../list-core/db/get-list-and-columns.mjs'

// Refs

const notifyMembers = ref(false)
const name = ref('')
const message = ref('')
const showMessage = ref(false)
const existingList = ref([])
const allLists = ref([])
const selectedColumns = ref([])
const columnOptions = ref([])
const allFilteredLists = ref([])
const sharedWithMeViewers = ref([])
const libraryColumns = ref([])
const filteredColumns = ref([])
const listUpdateType = ref('')
const currentEmailValue = ref('')
const teamMembers = ref([])
const filterTeamMembers = ref([])

const isListTypeSelectReadonly = ref(true)

const selectedColumnsSearchValue = ref('')

const isCreatePriceList = ref(true)
const listTypes = [
  {
    label: 'Create new',
    value: 'new'
  },
  {
    label: 'Add to existing',
    value: 'existing'
  }
]
const listType = ref(listTypes[0])

const listExportPermissions = [
  {
    label: 'Anyone',
    value: 'Anyone'
  },
  {
    label: 'Team',
    value: 'Team'
  }
]
const listExportPermission = ref(null)

const listUpdateTypes = [
  {
    label: 'View',
    value: 'dynamic'
  },
  {
    label: 'Price List',
    value: 'static'
  }
]

const TYPE_VIEW = 0
const TYPE_PRICE_LIST = 1


let sourceColumnSet = {}

let actionType = null
// events
addEventListener(EVENT_OPEN_CREATE_VIEW, onOpenCreateViewEvent)
addEventListener(EVENT_OPEN_CREATE_PRICE_LIST, onOpenCreatePriceListEvent)

const setUiColumns = (columnSet, fieldSchemas) => {
  libraryColumns.value = []
  filteredColumns.value = []
  selectedColumns.value = []
  sourceColumnSet = columnSet
  for (const col of columnSet.columns) {
    if (col.isDeleted) {
      continue
    }
    const fieldSchema = fieldSchemas.find(fs => fs.id === col.fieldSchemaId)
    if (!fieldSchema) {
      continue
    }
    const colObj = col.toObject()
    colObj.label = fieldSchema.label
    libraryColumns.value.push(colObj)
    filteredColumns.value.push(colObj)
    if (!col.isHidden) {
      selectedColumns.value.push(colObj)
    }
  }
}

function onOpenCreateViewEvent(event) {
  const { columnSet, fieldSchemas } = event.detail
  actionType = EVENT_OPEN_CREATE_VIEW
  listUpdateType.value = listUpdateTypes[TYPE_VIEW].value
  isCreatePriceList.value = false
  setUiColumns(columnSet, fieldSchemas)
  name.value = null
  existingList.value = []
  columnOptions.value = []
  listExportPermission.value = listExportPermissions[1]
  isCreateListOpen.value = true
}

function onOpenCreatePriceListEvent(event) {
  const { columnSet, fieldSchemas } = event.detail
  actionType = EVENT_OPEN_CREATE_PRICE_LIST
  listUpdateType.value = listUpdateTypes[TYPE_PRICE_LIST].value
  isCreatePriceList.value = true
  setUiColumns(columnSet, fieldSchemas)
  name.value = null
  existingList.value = []
  columnOptions.value = []
  listExportPermission.value = listExportPermissions[1]
  isCreateListOpen.value = true
}


async function initList() {
  const team = getCurrentTeam()
  const { list, columnSet, fieldSchemas } = await getListAndColumns(team.libraryListId)
  libraryColumns.value = []
  filteredColumns.value = []
  selectedColumns.value = []
  sourceColumnSet = columnSet
  for (const col of columnSet.columns) {
    if (col.isDeleted) {
      continue
    }
    const fieldSchema = fieldSchemas.find(fs => fs.id === col.fieldSchemaId)
    if (!fieldSchema) {
      continue
    }
    //col.fieldSchema = fieldSchema
    col.label = fieldSchema.label
    libraryColumns.value.push(col.toObject())
    filteredColumns.value.push(col.toObject())
    if (!col.isHidden) {
      selectedColumns.value.push(col.toObject())
    }
    name.value = null
    existingList.value = []
    columnOptions.value = []
    listExportPermission.value = listExportPermissions[0]
  }
}

async function onCreateListOpen() {
  //await initList()
}

function onShareListOpen() {
  const team = getCurrentTeam()
  getAllTeamMembers(team.id).then(tMembers => {
    sharedWithMeViewers.value = selectedList.value.sharedWith.map(listViewerEmail => {
      const teamListViewer = tMembers.find(teamMember => teamMember.email === listViewerEmail)
      return { value: listViewerEmail, label: teamListViewer ? teamListViewer.displayName : CHAR_EMPTY }
    })
    teamMembers.value = tMembers.filter(member => member.id !== currentUser.value.id)
      .map(member => {
        return { value: member.email, label: member.displayName }
      })
    filterTeamMembers.value = teamMembers.value
  })
}


/**
 * Add elements
 */
function onListSave() {
  dispatch(EVENT_PROGRESS_START, {
    label: 'Adding items...',
    maxValue: selectedItemsCount.value,
  })
  if (actionType === EVENT_OPEN_CREATE_VIEW) {
    dispatch(EVENT_CREATE_VIEW, {
      name: name.value,
      libraryColumnSet: sourceColumnSet,
      selectedColumns: selectedColumns.value,
      canExport: listExportPermission.value.value
    })
  }

  if (actionType === EVENT_OPEN_CREATE_PRICE_LIST) {
    dispatch(EVENT_CREATE_PRICE_LIST, {
      name: name.value,
      sourceColumnSet,
      selectedColumns: selectedColumns.value,
      canExport: listExportPermission.value.value
    })
  }
}

function onCancelList() {
  existingList.value = []
  listType.value = listTypes[0]
  isCreatePriceList.value = false
  listUpdateType.value = null
  name.value = null
  initList()
  return
}

function setCurrentEmailValue(val) {
  if (isEmail(val)) currentEmailValue.value = val
}


/**
 * Create value
 */
function createValue(val, done) {
  const newViewer = {
    label: CHAR_EMPTY,
    value: val,
  }
  return isEmail(newViewer.value) ? done(newViewer, 'add-unique') : done()
}

/**
 * Share price list
 */
function shareList() {
  if (selectedList.value) {
    updateSharedWith({
      list: selectedList.value,
      listId: selectedList.value.id,
      emails: sharedWithMeViewers.value.map(viewer => viewer.value),
      message: message.value
    })
  }
}

function onFilterLists(val, update) {
  update(() => {
    const needle = val.toLocaleLowerCase()
    if (val != '') {
      allFilteredLists.value = allLists.value.filter(v => v.name.toLocaleLowerCase().indexOf(needle) > -1)
    }
    else allFilteredLists.value = allLists.value
  })
}

// Filter columns for column selection
function onFilterColumn() {
  const query = selectedColumnsSearchValue.value.toLowerCase()
  filteredColumns.value = libraryColumns.value.filter(field => {
    if (field.isDeleted) {
      return false
    }
    if (!query?.length) {
      return true
    }
    return field.label
      ?.toLowerCase()
      .includes(query)
  })
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    })
}

// Switch fieldschema visibility
function onCheckboxSelected({ key }) {
  const libraryCol = filteredColumns.value.find(col => col.key === key)
  if (!libraryCol) {
    return
  }
  const isSelected = selectedColumns.value.findIndex(col => col.key === key)
  if (isSelected < 0) {
    return selectedColumns.value.push(libraryCol)
  }
  selectedColumns.value = [...selectedColumns.value.filter(col => col.key !== key)]
}

function onTeamMemberfilterFn(val, update) {
  setTimeout(() => {
    update(() => {
      const needle = val.toLocaleLowerCase()
      if (val !== '') {
        filterTeamMembers.value = teamMembers.value.filter(member => member.label.toLocaleLowerCase().indexOf(needle) > -1)
      }
      else filterTeamMembers.value = teamMembers.value
    },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      })
  }, 500)
}


function removeUserFromList(list, userEmail) {
  dispatch(EVENT_LIST_REMOVE_SHARING_USER_LOCAL, { list, userEmail })
}
</script>
