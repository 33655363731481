// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

//sessions
import { getTeamSession } from '../../session/team-session.mjs'
import { SCOPE_TEAM } from '../../events/external-events.mjs'

// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// local events
import {
  EVENT_COLUMN_MOVE_LOCAL,
  EVENT_COLUMN_MOVE_REMOTE,
} from '../../events/constants/event-types.mjs'

// external events
import {
  EVENT_LIBRARY_ITEMS_UPDATED,
  EVENT_PROGRESS_START,
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createTeamEvent, createExternalEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

import { ITEM_COMPILE_BATCH_SIZE } from '../../constants/batch-sizes.mjs'
import { compileItem } from '../../item/utils/compile-item.mjs'
import { saveItemsEntities } from '../../item/db/item-db.mjs'

//#region //--- Move ---//
export const selectedItemsDuplicateEvent = async (event, columnManager, itemManager, { 
  scope = SCOPE_TEAM
} = {}) => {
  const team = getTeamSession()

  const selectedItems = itemManager.getSelectedItems()
  const selectedItemsCount = selectedItems.length
  dispatch(EVENT_PROGRESS_START, {
    label: 'Duplicating items...',
    maxValue: selectedItemsCount,
  })

  const externalEvent = createExternalEvent({
    action: EVENT_LIBRARY_ITEMS_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list?.id
    }
  })

  let cnt = 0
  let batch = []
  for (const item of selectedItems ) {
    if (item.isStatic) {
      continue
    }
    const newItem = item.cloneAsNew()
    newItem.lists = [ columnManager.list?.id ]
    newItem.setCreated().setUpdated()
    if (event) {
      newItem.updatedByEventId = externalEvent.id
    }
    batch.push(compileItem({ item: newItem, fieldSchemas: columnManager.getFieldSchemas() }))
    if (cnt === ITEM_COMPILE_BATCH_SIZE) {
      itemManager.upsertGridItems(batch)
      saveItemsEntities(batch)
      cnt = 0
      batch = []
    }
  }

  if (batch.length > 0 ) {
    itemManager.upsertGridItems(batch)
    saveItemsEntities(batch)
  }

  setTimeout(() => emitEvent(externalEvent), 500)

}



//#endregion