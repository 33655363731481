

// session events
import { dispatch } from '../../events/dispatch.mjs'
import { EVENT_COLUMN_RESIZE_LOCAL, EVENT_COLUMN_MOVE_LOCAL, EVENT_COLUMN_FILTER_ADD_LOCAL } from '../../events/constants/event-types.mjs'

/**
 * Column resize handler
 */
export const onColumnResized = (params) => {
  if (params.type === 'columnResized' && !params.finished) {
    return
  }
  if (params.source !== 'uiColumnDragged') return
  const colWidthArr = {
    listId: params.column.colDef?.headerComponentParams?.data?.listId,
    userRole: params.column.colDef?.headerComponentParams?.data?.userRole,
    colDefs: [
      {
        colId: params.column.colId,
        width: params.column.actualWidth,
      }
    ]
  }
  dispatch(EVENT_COLUMN_RESIZE_LOCAL, colWidthArr)
}


/**
 * onColumnMoved ui action
 * @param {*} params 
 * @returns 
 */
export const onColumnMoved = (params) => {
  if (params.source !== 'uiColumnDragged') return
  dispatch(EVENT_COLUMN_MOVE_LOCAL, { 
    listId: params.column.colDef?.headerComponentParams?.data?.listId,
    userRole: params.column.colDef?.headerComponentParams?.data?.userRole,
    columnId: params.column.colId, 
    toIndex: params.toIndex,
  })
}

/**
 * onColumnAddFilter
 * @param {Object} column
 * @param {string} column.id
 */
export const onColumnAddFilter = (column) => {
  dispatch(EVENT_COLUMN_FILTER_ADD_LOCAL, {
    column: column 
  })
}