/*!
 * item db
 */

// Utils
import { required } from '../../utils'

// Db
import { Db } from '@/db/Db.mjs'
import { COLLECTION_ITEMS } from '../../db/db-collections.mjs'

export class ItemDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_ITEMS
    })
  }

  validateDocObject({
    id = required('id'),
    libraryListId,
    teamId = required('teamId'),
    fields = required('fields'),
    isStatic,
    
  }) {
    return
  }

  createDocObject(item) {
    const fieldDocs = {}
    for (const key in item.fields) {
      fieldDocs[key] = {
        inputValue : item.fields[key].inputValue
      }
    }
    const deletedFieldDocs = {}
    if (item.deletedFields) {
      for (const key in item.deletedFields) {
        deletedFieldDocs[key] = {
          inputValue : item.deletedFields[key].inputValue
        }
      }
    }
    this.validateDocObject(item.toObject())
    return {
      teamId: item.teamId,
      libraryListId: item.libraryListId,
      productCode: item.productCode,
      fields: fieldDocs,
      deletedFields: deletedFieldDocs,
      lists: [ ...item.lists ],
      isStatic: item.isStatic,
      sourceId: item.sourceId,
      ...super.createDocObject(item)
    }
  }
}
