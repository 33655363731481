import { IMPORT_COLUMN_SET_TYPE, DEFAULT_COLUMN_SET_TYPE } from '../column-set-constants.mjs'

function getActiveColumnSetsQuery(ids) {
  return [
    ['id', 'in', ids],
    ['isDeleted', '==', false]
  ]
}

function getImportColumnSetQuery(listId, teamId) {
  return [
    ['listId', '==', listId],
    ['teamId', '==', teamId],
    ['type', '==', IMPORT_COLUMN_SET_TYPE],
    ['isDeleted', '==', false]
  ]
}

function getDefaultColumnSetQuery(listId) {
  return [
    ['listId', '==', listId],
    ['type', '==', DEFAULT_COLUMN_SET_TYPE],
    ['isDeleted', '==', false]
  ]
}

export { getActiveColumnSetsQuery, getImportColumnSetQuery, getDefaultColumnSetQuery }
