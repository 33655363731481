/*!
* Entity
*/

import { DbDocument } from './DbDocument.mjs'
import { TRUE, FALSE, UNDEFINED } from '../constants/types.mjs'
import { CHAR_EMPTY } from '@/constants/characters.mjs'
import { deepClone } from '../utils'


export class Entity extends DbDocument {
  #error
  #warning
  #isDeleted
  #isDeletable
  #isEditable
  #updatedByEventId
  constructor({
    error = CHAR_EMPTY,
    warning = CHAR_EMPTY,
    isDeleted = FALSE,
    isDeletable = TRUE,
    isEditable = TRUE,
    updatedByEventId = UNDEFINED,
    ...props
  } = {}) {
    super({ ...props })
    this.error = error
    this.warning = warning
    this.isDeleted = isDeleted
    this.isDeletable = isDeletable
    this.isEditable = isEditable
    this.updatedByEventId = updatedByEventId
  }

  //--- getters ---//
  get error() { return this.#error }
  get warning() { return this.#warning }
  get isDeleted() { return this.#isDeleted }
  get isDeletable() { return this.#isDeletable }
  get isEditable() { return this.#isEditable }
  get updatedByEventId() { return this.#updatedByEventId }

  //--- setters ---//
  set error(str) { this.#error = String(str) }
  set warning(str) { this.#warning = String(str) }
  set isDeleted(bool) { this.#isDeleted = !!bool }
  set isDeletable(bool) { this.#isDeletable = !!bool }
  set isEditable(bool) { this.#isEditable = !!bool }
  set updatedByEventId(id) { this.#updatedByEventId = id }

  //--- methods ---//
  clearError() { this.#error = CHAR_EMPTY }
  clearWarning() { this.#warning = CHAR_EMPTY }
  markAsDeleted() { this.isDeleted = TRUE }
  markAsUndeleted() { this.isDeleted = FALSE }
  markAsDeletable() { this.isDeletable = TRUE }
  markAsUndeletable() { this.isDeletable = FALSE }
  markAsEditable() { this.isEditable = TRUE }
  markAsUnuneditable() { this.isEditable = FALSE }

  //--- object methods ---//
  #getMyProperties() {
    return {
      error: this.#error,
      warning: this.#warning,
      isDeleted: this.#isDeleted,
      isDeletable: this.#isDeletable,
      isEditable: this.#isEditable,
      updatedByEventId: this.#updatedByEventId
    }
  }

  cloneAsNew() {
    return new Entity(
      // do not clone DbDocument, we need a clean set
      deepClone({
        ...this.#getMyProperties(),
        error: CHAR_EMPTY,
        warning: CHAR_EMPTY,
        isDeleted: FALSE,
        updatedByEventId: UNDEFINED
      })
    )
  }

  clone() {
    return new Entity(
      deepClone({
        ...this.toObject()
      })
    )
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties()
    }
  }
}
