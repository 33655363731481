
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../db/db-constants.mjs'

const app = getApp()
const CALLABLE_INTEGRATIONS = 'integrations'

let inegrationRpc = null

/**
 * get the integration rpc function
 * @returns 
 */
function getIntegrationsRpc() {
  if (inegrationRpc) {
    return inegrationRpc
  }
  const functions = getFunctions(app, AU_REGION)
  inegrationRpc = httpsCallable(functions, CALLABLE_INTEGRATIONS, {timeout: 540000 } )
  return inegrationRpc
}

export { getIntegrationsRpc }