
import { required } from '@/utils'
import { getCurrentUser } from '../actions/current-user.mjs'
import { getCurrentTeam } from '../actions/current-team.mjs'


import { throwError } from '@/utils/errors.mjs'
import { ViewColumnManager } from './grid-managers/ViewColumnManager.mjs'
import { GridItemManager } from '../grid-manager/entities/GridItemManager.mjs'


// events
import {
  EVENT_USER_SIGNOUT,
  EVENT_OPEN_ADD_TO_PRICE_LIST,
  EVENT_OPEN_CREATE_PRICE_LIST,
  EVENT_SET_SESSION_UPDATED_AT_INTERVAL
} from '../events/constants/event-types.mjs'
import { dispatch } from '../events/dispatch.mjs'

// list
import { setListUiActionPermissionStates } from '../list-core/permissions/list-ui-action-permissions.mjs'
import { setLogFilesUi } from '../list-core/log-files/log-files-ui.mjs'
import {
  startViewColumnEvents, stopViewColumnEvents, startViewItemEvents, stopViewItemEvents
} from './events/view-column-event-handlers.mjs'

import { startRightHandMenuEvents, stopRightHandMenuEvents } from '../list-core/right-hand-menu-actions/right-hand-menu-event-actions.mjs'

// test
import { getListAndColumns } from '../list-core/db/get-list-and-columns.mjs'


// local db 
import { startLocalDb } from '../db-local/local-db.mjs'
import { loadDeltaLibraryItems, getLocalItems } from '../item/data/local-db-items.mjs'

// logs
import { clearLogFilesUi } from '../list-core/log-files/log-files-ui.mjs'

let gridItemsManager = null
let gridColumnManager = null
// grid

const onViewGridReady = async (grid, listId) => {
  gridItemsManager = null
  gridColumnManager = null

  await startLocalDb()
  addEventListener(EVENT_USER_SIGNOUT, onViewGridStop)

  const currentUser = getCurrentUser()
  if (!currentUser.teamId) {
    throwError('User is missing teamId')
  }
  const currentTeam = getCurrentTeam()
  if (!currentTeam.isActive) {
    throwError('Team is inactive')
  }
  const startTime = Date.now()

  const { list, columnSet, libraryColumnSet, fieldSchemas } = (await getListAndColumns(listId)) || {}

  if (!list) {
    throwError('Cannot not load View. Missing List', listId)
  }
  if (!columnSet) {
    throwError('Cannot not load View. Missing columnSet', listId)
  }

  if (!fieldSchemas || fieldSchemas.length === 0) {
    throwError('Cannot not load View. Missing fieldSchemas', listId)
  }

  setListUiActionPermissionStates(list)

  gridColumnManager = new ViewColumnManager({
    grid,
    list,
    columnSet: columnSet,
    fieldSchemaSet: { fieldSchemas },
    libraryColumnSet: libraryColumnSet
  })
  gridColumnManager.start()


  gridItemsManager = new GridItemManager({
    grid,
    list
  })
  gridItemsManager.start()

  // load items to grid
  await loadDeltaLibraryItems(currentTeam.libraryListId)
  const getLocalStartTime = Date.now()

  let itemCnt = 0
  await getLocalItems({
    listId: list.id,
    batchCallBack: (batch) => {
      itemCnt += batch.length
      gridItemsManager.upsertItemsGridOnly(batch, gridColumnManager.getFieldSchemas())
    }
  })
  console.log('Get local', itemCnt, 'items. Time:', Date.now() - getLocalStartTime, 'ms')

  startViewColumnEvents(gridColumnManager, gridItemsManager)
  startViewItemEvents(gridColumnManager, gridItemsManager)
  startRightHandMenuEvents(gridColumnManager, gridItemsManager)


  // The startup will use last session info to get delta
  // so always set the latest updatedAtInterval as the last action
  dispatch(EVENT_SET_SESSION_UPDATED_AT_INTERVAL, { id: currentTeam.libraryListId})
  console.log('View load Time:', Date.now() - startTime, 'ms')

  // show batch errors
  gridItemsManager.consoleLogBatchErrors()
  clearLogFilesUi()
  setLogFilesUi(list)

}



function onViewGridStop() {
  clearLogFilesUi()

  stopRightHandMenuEvents(gridColumnManager)
  stopViewColumnEvents(gridColumnManager, gridItemsManager)
  stopViewItemEvents(gridColumnManager, gridItemsManager)

  gridItemsManager?.terminate()
  gridColumnManager?.terminate()
  gridItemsManager = null
  gridColumnManager = null
  return
}



export const openCreatePriceList = () => {
  dispatch(EVENT_OPEN_CREATE_PRICE_LIST, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas()
  })
}


export const openAddToPriceList = () => {
  dispatch(EVENT_OPEN_ADD_TO_PRICE_LIST, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas(),
    selectedItems: gridItemsManager.getSelectedItems()
  })
}



export {
  onViewGridReady,
  onViewGridStop
}
