/*!
 * Filter group entity
 */

// Entities
import { Entity } from '../../entity/Entity.mjs'

// utils
import { deepClone } from '@/utils'

// field schema utils
import { required } from '@/utils/required.mjs'

// Constants
import { CHAR_EMPTY } from '../../constants'
import { DEFAULT_FILTERGROUP_NAME } from '../../constants/filter.mjs'

import { getTypeFromFieldType } from '../utils/filter-utils.mjs'

export class FilterGroupEntity extends Entity {
  #name
  #listId
  #filters

  constructor({
    name = DEFAULT_FILTERGROUP_NAME,
    listId,
    filters = [],
    ...props
  } = {}) {
    super({ ...props })
    this.name = name
    this.listId = listId
    this.filters = filters
  }

  //--- getters ---//
  get name() { return this.#name }
  get listId() { return this.#listId }
  get filters() { return this.#filters }

  //--- setters ---//
  set name(str) { this.#name = str }
  set listId(id) { this.#listId = id }
  set filters(arr) { this.#filters = arr }

  //--- methods ---//
  #createFilter({
    column,
    type = getTypeFromFieldType(column.fieldSchema.type),
    fieldId = column.id,
    fieldKey = column.fieldSchema.key,
    fieldType = column.fieldSchema.type,
    rules = []
  }) {
    return {
      fieldId, fieldKey, fieldType, type, rules
    }
  }

  #createRule({
    condition = null,
    value = CHAR_EMPTY,
  } = {}) {
    return { condition, value }
  }

  addFilter(column) {
    const newFilter = this.#createFilter({ column })
    const newRule = this.#createRule()
    newFilter.rules.push(newRule)
    this.#filters.push(newFilter)
  }

  addRule(filterIdx, data = {}) {
    const newRule = this.#createRule(data)
    this.#filters[filterIdx].rules.push(newRule)
  }

  setRuleCondition(filterIdx, ruleIdx, condition) {
    this.#filters[filterIdx].rules[ruleIdx].condition = condition
  }

  setRuleValue(filterIdx, ruleIdx, value) {
    this.#filters[filterIdx].rules[ruleIdx].value = value
  }

  removeFilter(filterIdx) {
    this.#filters.splice(filterIdx, 1)
  }

  removeRule(filterIdx, ruleIdx) {
    const filter = this.#filters[filterIdx]
    filter.rules.splice(ruleIdx, 1)
    if (filter.rules.length < 1) {
      this.removeFilter(filterIdx)
    }
  }

  //--- object methods ---//
  validate() {
    const msg = `[key: "${this.#name}"  id: ${this.id}]`
    if(!this.id) { required(`${msg} id`)}
    if(!this.name) { required(`${msg} name`)}
  }

  #getMyProperties() {
    return {
      name: this.#name,
      filters: this.#filters,
      listId: this.#listId,
    }
  }
  cloneAsNew() {
    return new FilterGroupEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
    })
  }

  clone() {
    return new FilterGroupEntity(
      deepClone({
        ...this.toObject()
      })
    )
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties(),
    }
  }
}

