

// utils
import { required } from '../../utils/required.mjs'
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// session
import { getTeamSession } from '../../session/team-session.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'
// local events
import {
  EVENT_CREATE_PRICE_LIST,
  EVENT_ADD_TO_PRICE_LIST_LOCAL,
  EVENT_PRICE_LIST_ITEMS_UPDATED,
  EVENT_PRICE_LIST_ITEMS_UPDATED_REMOTE,
  EVENT_SELECTED_ITEMS_DELETE_LOCAL,
  EVENT_PROGRESS_START,
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_GLOBAL } from '../../events/external-events.mjs'

// actions
import { getListAndColumns } from '../db/get-list-and-columns.mjs'
import { createPriceList } from '../menu-actions/create-price-list.mjs'
import { loadDeltaStaticListItems, getLocalItems } from '../../item/data/local-db-items.mjs'

//#region //--- Create price list ---//
/**
 * Create price list with items
 * @param {*} event 
 * @param {*} columnManager 
 */
export const createPriceListEvent = async (event, columnManager, itemManager) => {
  const { name, sourceColumnSet, selectedColumns, canExport } = event.detail
  const { list, columnSet } = await createPriceList({
    name,
    sourceColumnSet,
    selectedColumns,
    canExport,
    itemManager,
  })
  await itemManager.addSelectedToPriceList(list.id, columnSet, { event })

}


export const addEventsCreatePriceList = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_CREATE_PRICE_LIST,
    (event) => createPriceListEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

//#endregion

//#region //--- Add to price list ---//
/**
 * Add items to price list event listener
 * @param {*} controller 
 * @param {*} columnManager 
 * @param {*} itemManager 
 * @returns 
 */
export const addEventsAddToPriceList = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_ADD_TO_PRICE_LIST_LOCAL,
    (event) => addToPriceListEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

/**
 * Price list items updated event listener
 * @param {*} controller 
 * @param {*} subscriptions 
 * @param {*} columnManager 
 * @param {*} itemManager 
 * @returns 
 */
export const addEventsPriceListItemsUpdated = (controller, subscriptions, columnManager, itemManager) => {
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_PRICE_LIST_ITEMS_UPDATED,
      priceListItemsUpdatedRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )

  addEventListener(
    EVENT_PRICE_LIST_ITEMS_UPDATED_REMOTE,
    (event) => priceListItemsUpdatedRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

/**
 * add items to price list
 * @param {*} event 
 * @param {*} columnManager 
 */
export const addToPriceListEvent = async (event, columnManager, itemManager, {
  scope = SCOPE_GLOBAL
} = {}) => {
  const { listId } = event.detail
  const { list, columnSet } = await getListAndColumns(listId)
  const team = getTeamSession()
  const externalEvent = createExternalEvent({
    action: EVENT_PRICE_LIST_ITEMS_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: listId
    }
  })

  await itemManager.addSelectedToPriceList(listId, columnSet, { event: externalEvent })
  setTimeout(() => emitEvent(externalEvent), 500)
}

export const priceListItemsUpdatedRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { listId } = payload
  dispatch(EVENT_PRICE_LIST_ITEMS_UPDATED_REMOTE, { listId })

}


export const priceListItemsUpdatedRemoteEvent = async (event, columnManager, itemManager) => {
  const { listId } = event.detail
  if (columnManager.list.id !== listId) {
    return
  }
  await loadDeltaStaticListItems(listId)
  await getLocalItems({
    listId,
    batchCallBack: (batch) => {
      itemManager.upsertItemsGridOnly(batch, columnManager.getFieldSchemas())
    }
  })

}

//#endregion

//#region //--- Delete items from price list ---//

const selectedItemsDeleteEvent = async (event, columnManager, itemManager, { 
  scope = SCOPE_GLOBAL
} = {}) => {
  const { selectedItemsCount } = event.detail
  const team = getTeamSession()

  dispatch(EVENT_PROGRESS_START, {
    label: 'Deleting items...',
    maxValue: selectedItemsCount,
  })
  const externalEvent = createExternalEvent({
    action: EVENT_PRICE_LIST_ITEMS_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list?.id
    }
  })
  let removedItems = itemManager.deleteSelectedItems({ event: externalEvent })
  await itemManager.saveSelectedItems(removedItems)

  setTimeout(() => emitEvent(externalEvent), 500)

}

export const addEventsSeletedPriceListItemsDelete = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_SELECTED_ITEMS_DELETE_LOCAL,
    (event) => selectedItemsDeleteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
}

