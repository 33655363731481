import { getIntegrationAppsRpc } from '../integrations/rpcs/integration-apps-rpc.mjs'
import { ACTION_GET_INTEGRATION, ACTION_ADV_TEST } from '../integration-modules/salesforce/constants/action-types.mjs'
import { getTeamSession } from '../session/team-session.mjs'

export function testShopifyIntegration() {
  console.log('=== testShopifyIntegration called ===')
  const team = getTeamSession()
  const integrationRpc = getIntegrationAppsRpc()
  integrationRpc({
    action: ACTION_GET_INTEGRATION,
    payload: {
      teamId: team.id
    }
  }).then(response => {
    const entity = response.data.result
    console.log('getIntegration result',entity.name)
  })
}

export function lookupTest() {
  const team = getTeamSession()
  const integrationRpc = getIntegrationAppsRpc()
  integrationRpc({
    action: ACTION_ADV_TEST
  }).then(response => {
    //const result = response.data.result
    console.log('getIntegration result', response.data)
  })
}