
import {
  EVENT_ITEM_NEW,
} from '../../events/constants/event-types.mjs'
import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { updateItemRemoteEvent, updateItemRemoteHandler } from './update-item-events.mjs'


//#region //--- New Column ---//
/**
 * Add new column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const newItemEvent = async (event, columnManager, itemManager) => {
 const teamEvent = createTeamEvent(EVENT_ITEM_NEW)
  const fieldSchemas = columnManager.getFieldSchemas()
  const item = await itemManager.addItem(fieldSchemas)  

  // send external event
  teamEvent.payload = { itemId: item.id }
  setTimeout(() => emitEvent(teamEvent), 500)
}


/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const newItemRemoteEvent = async (event, columnManager, itemManager) => {
  updateItemRemoteEvent(event, columnManager, itemManager)
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const newItemRemoteHandler = async (event) => {
  updateItemRemoteHandler(event)
}
