
import {
  EVENT_FIELD_SCHEMA_UPDATED,
  EVENT_FIELD_SCHEMA_UPDATED_LOCAL,
  EVENT_FIELD_SCHEMA_UPDATED_REMOTE,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'
import { SCOPE_TEAM } from '../../events/external-events.mjs'
import { subscribe, unsubscribe } from '../../events/external-events.mjs'

import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// entities
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'






//#region //--- New Column ---//
/**
 * Update column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const updateImportFieldSchemaEvent = async (event, columnManager, itemManager) => {
  const { fieldSchema, importColumn } = event.detail
  const fieldSchemaEntity = !fieldSchema.isFieldSchemaEntity
    ? (new FieldSchemaEntity(fieldSchema))
    : fieldSchema

  const { key, label } = importColumn.fieldSchema
  const importColumnEntity = !importColumn.isColumnEntity
    ? (new ColumnEntity({ ...importColumn, fieldSchema: undefined }))
    : importColumn

  importColumnEntity.key = fieldSchema.key
  importColumnEntity.label = fieldSchema.label

  const teamEvent = createTeamEvent(EVENT_FIELD_SCHEMA_UPDATED)
  importColumnEntity.updatedByEventId = teamEvent.id
  importColumnEntity.fieldSchema = {
    ...fieldSchemaEntity.toObject(),
    key,
    label
  }
  await columnManager.updateImportColumn(importColumnEntity)
  columnManager.refreshColumns()

  // send external event
  setTimeout(() => emitEvent(teamEvent), 500)
  itemManager.clearAllItemsErrors()

}

export const upateImportColumnFieldSchemaRemoteHandler = async () => {

}

export const updateImportColumnFieldSchemaRemoteEvent = async () => {

}

export const addEventsFieldSchemaUpdate = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_FIELD_SCHEMA_UPDATED_LOCAL,
    (event) => updateImportFieldSchemaEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_FIELD_SCHEMA_UPDATED,
      upateImportColumnFieldSchemaRemoteHandler,
      { scope: SCOPE_TEAM }
    )
  )
  // remote
  addEventListener(
    EVENT_FIELD_SCHEMA_UPDATED_REMOTE,
    (event) => updateImportColumnFieldSchemaRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}