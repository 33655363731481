import { isNumber, getTimestampByDate, getTimezoneOffset } from '../../utils'

import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  DEFAULT_NUMBER_DP,
  DEFAULT_PERCENTAGE_DP,
  DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
  EMPTY_VALUE_TEXT,
  DEFAULT_IS_TEXT_MULTI_LINE
} from '@/field-schema/field-schema-constants.mjs'

export function makeFieldComparator(fieldType) {
  switch (fieldType) {
    case FIELD_TYPE_TEXT:
      return textFieldComparator
    case FIELD_TYPE_PERCENTAGE:
    case FIELD_TYPE_NUMBER:
    case FIELD_TYPE_CURRENCY:
      return numberFieldComparator
    case FIELD_TYPE_DATE:
      return dateFieldComparator
    case FIELD_TYPE_TAGLIST:
      return tagFieldComparator
    default:
      return textFieldComparator
  }
}

/**
 * Make text field comparator
 */
const textFieldComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  return (valueA.value?.toString().localeCompare(valueB.value?.toString()))
}

/**
 * Make number field compartor
 */
const numberFieldComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  if (valueA.value == valueB.value) return 0
  return (valueA.value - valueB.value)
}

/**
 * Make date field compartor
 */
const dateFieldComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  const a = (valueA.value.seconds) ? Number(valueA.value.seconds)
          : isNumber(valueA.value) ? Number(valueA.value)
          : getTimestampByDate(valueA.value) + getTimezoneOffset()
  const b = (valueB.value.seconds) ? Number(valueB.value.seconds)
          : isNumber(valueB.value) ? Number(valueB.value)
          : getTimestampByDate(valueB.value) + getTimezoneOffset()
  if (a == b) return 0
  return (a - b)
}

/**
 * Make tag field comparator
 */
const tagFieldComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  const a = valueA.value.join(', ')
  const b = valueB.value.join(', ')
  return (a.localeCompare(b))
}
