
import { required } from '../../utils/required.mjs'
import { getTeamSession } from '../../session/team-session.mjs'
import { getFieldSchemaEntitiesByTeamId } from '../db/field-schema-db.mjs'


/**
 * Get current team field schemas
 * @returns promise fieldSchemaList
 */
async function getCurrentTeamFieldSchemas() {
  const currentTeam = getTeamSession()
  if (!currentTeam || !currentTeam.id ) {
    return
  }
  return getFieldSchemaEntitiesByTeamId(currentTeam.id)
}

async function getTeamFieldSchemas(teamId = required('teamId')) {
  return getFieldSchemaEntitiesByTeamId(teamId)
}

export { getCurrentTeamFieldSchemas, getTeamFieldSchemas }