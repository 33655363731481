// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// local events
import { dispatch } from '../../events/dispatch.mjs'

// external events
import { subscribe, SCOPE_GLOBAL } from '../../events/external-events.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

//actions 
import { refreshSuppliers } from '../../list-price-list/events/price-list-ui-event-handlers.mjs'

const EVENT_TEAM_INACTIVE = 'team-inactive'
const EVENT_TEAM_INACTIVE_REMOTE = 'team-inactive-remote'
const EVENT_TEAM_ACTIVE = 'team-active'
const EVENT_TEAM_ACTIVE_REMOTE = 'team-active-remote'

export const addEventTeamActiveInactive = (controller, subscriptions) => {
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_TEAM_INACTIVE,
      teamInactiveRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )

  subscriptions.push(
    subscribe(
      EVENT_TEAM_ACTIVE,
      teamActiveRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )

  // remote
  addEventListener(
    EVENT_TEAM_INACTIVE_REMOTE,
    (event) => teamInactiveRemoteEvent(event),
    { signal: controller.signal }
  )

  addEventListener(
    EVENT_TEAM_ACTIVE_REMOTE,
    (event) => teamActiveRemoteEvent(event),
    { signal: controller.signal }
  )
  return

}


//#region //--- Team ---//
/**
 * Add event remote - team inactive
 * @param {Object} event
 * @param {string} event.detail.teamId
 * @returns 
 */
const teamInactiveRemoteEvent = async (event) => {
  const { teamId } = event.detail
  refreshSuppliers()   //user cannot see supplier priceLists where the team associated with that pricelist is inactive
}

/**
 * Add event remote - team active
 * @param {Object} event
 * @param {string} event.detail.teamId
 * @returns 
 */
const teamActiveRemoteEvent = async (event) => {
  const { teamId } = event.detail
  refreshSuppliers()   //update supplierPriceLists
}

/**
 * Add external event handler - team inactive
 * @param {Object} event
 * @param {string} event.eventId
 * @param {string} event.payload.teamId
 * @returns 
 */
const teamInactiveRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { teamId } = payload
  dispatch(EVENT_TEAM_INACTIVE_REMOTE, { teamId })
}

/**
 * Add external event handler - team active
 * @param {Object} event
 * @param {string} event.eventId
 * @param {string} event.payload.teamId
 * @returns 
 */
const teamActiveRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { teamId } = payload
  dispatch(EVENT_TEAM_INACTIVE_REMOTE, { teamId })
}
