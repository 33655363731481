/*!
 * Number field type
 */

import { FieldType } from './FieldType.mjs'

export class TextType extends FieldType {
  constructor() {
    super()
  }
}