// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

//sessions
import { getTeamSession } from '../../session/team-session.mjs'
import { SCOPE_TEAM } from '../../events/external-events.mjs'

// events
import {
  EVENT_LIBRARY_ITEMS_UPDATED,
  EVENT_PROGRESS_START,
}
  from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'


//#region //--- Move ---//
export const selectedItemsDeleteEvent = async (event, columnManager, itemManager, { 
  scope = SCOPE_TEAM
} = {}) => {
  const { selectedItemsCount } = event.detail
  const team = getTeamSession()

  dispatch(EVENT_PROGRESS_START, {
    label: 'Deleting items...',
    maxValue: selectedItemsCount,
  })
  const externalEvent = createExternalEvent({
    action: EVENT_LIBRARY_ITEMS_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list?.id
    }
  })
  let removedItems = columnManager.list?.id === team.libraryListId
    ? itemManager.deleteSelectedItems({ event: externalEvent })
    : itemManager.removeSelectedItems(columnManager.list.id, { event: externalEvent })

  await itemManager.saveSelectedItems(removedItems)

  setTimeout(() => emitEvent(externalEvent), 500)
}


//#endregion