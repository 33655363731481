/*!
 * Item listener
 */
import { DbListener } from "@/db"
import { COLLECTION_LISTS } from '@/db/db-collections.mjs'
import { required } from "../../utils/required.mjs"
import { getTeamPriceListsQuery } from '../db/price-list-db-utils.mjs'

export class TeamPriceListListener extends DbListener {
  constructor({
    teamId = required('teamId'),
    collectionName = COLLECTION_LISTS,
    queryConditions = getTeamPriceListsQuery(teamId),
    addedFunction,
    modifiedFunction,
    removedFunction,
    handlerParams,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      handlerParams,
      ...props
    })
  }
}
