// util
import { required } from '../../utils/required.mjs'


// salesforce integration rpc
import { getIntegrationsRpc } from '../rpcs/integrations-rpcs.mjs'
import { ACTION_COPY_INTEGRATION } from '../constants/action-types.mjs'

export const copyIntegration = async (id = required('id')) => {
  const integrationRpc = getIntegrationsRpc()

  const response = await integrationRpc({
    action: ACTION_COPY_INTEGRATION,
    payload: {
      id
    }
  })
  return response.data
}

