import { isNumber } from '@/utils'
import { DEFAULT_LOCALE } from '@/constants/locale.mjs'

import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
  EMPTY_VALUE_TEXT,
  DEFAULT_IS_TEXT_MULTI_LINE
} from '../field-schema-constants.mjs'

export function makeValidateFormat(type) {
  switch (type) {
    case FIELD_TYPE_PERCENTAGE:
      return validatePercentageFormat

    case FIELD_TYPE_NUMBER:
      return validateNumberFormat

    case FIELD_TYPE_CURRENCY:
      return validateCurrencyFormat

    case FIELD_TYPE_TAGLIST:
      return validateTagListFormat

    case FIELD_TYPE_CHECKBOX:
      return validateCheckboxFormat

    case FIELD_TYPE_DATE:
      return validateDateFormat

    default:
      return validateTextFormat
  }
}

/**
 * Make number format
 */
function validateNumberFormat({
  decimalPlaces
}) {
  return isNumber(decimalPlaces)
}

/**
 * Make currency format
 */
function validateCurrencyFormat({
  isoCode,
  noPriceValue,
  ...props
}) {
  return validateNumberFormat(props)
}

/**
 * Make percentge format
 */
function validatePercentageFormat({
  decimalPlaces,
  ...props
}) {
  return true // TODO:
}

/**
 * Make date time format
 */
function validateDateFormat({
  dateFormat,
  timeFormat,
}) {
  return true //TODO:
}

/**
 * Make taglist format
 */
function validateTagListFormat({
  dataType,
}) {
  return true //TODO:
}

/**
 * Make text format
 */
function validateTextFormat({
  isMultiLine,
  textType,
}) {
  return true //TODO:
}

/**
 * Make checkbox format
 */
function validateCheckboxFormat({
  trueValue,
  falseValue,
  emptyValue
}) {
  return true //TODO:
}

/**
 * Make file format
 */
function validateFileFormat() {
  return true //TODO:
}
