
/**
 * Check if user is a team member
 * @param {Object} user.teamId 
 * @param {Object} team.id
 * @returns {boolean}
 */
const isTeamMember = (user, team) => {
  return user.teamId === team.id
}

export { isTeamMember }
