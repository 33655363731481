// utils
import { required } from '../utils/required.mjs'

// local db
import { COLLECTION_SESSIONS } from '../db/db-collections.mjs'
import { getLocalDb } from '../db-local/local-db.mjs'


export const getObjectSession = async (
  id = required('id'),
  ) => {
  const sessionLocalDb = getLocalDb(COLLECTION_SESSIONS)
  let session = null
  try {
    session = await sessionLocalDb.get(id)
  } catch (e) {
    console.log('No object session found.', id)
  }
  return session || { id: id, data: {} }
}

export const setObjectSession = async ({
  id = required('id'),
  data = {} 
}) => {
  const sessionLocalDb = getLocalDb(COLLECTION_SESSIONS)
  if (!sessionLocalDb) {
    console.error('Cannot open sessionLocalDb')
    return
  }
  return (await sessionLocalDb.put({ id, data }))
}

export const deleteObjectSession = async ({
  id = required('id'),
  data = {} 
}) => {
  const sessionLocalDb = getLocalDb(COLLECTION_SESSIONS)
  if (!sessionLocalDb) {
    return
  }
  return (await sessionLocalDb.delete(id))
}