/*!
 * Number field type
 */

import { FieldType } from './FieldType.mjs'
import { isEmpty, isNumber, getTimestampByDate, getTimezoneOffset } from '../utils'

//Constants
import {
  KEY_IS_EMPTY, KEY_EQUAL_TO, KEY_NOT_EQUAL_TO, KEY_GREATER_THAN, 
  KEY_GREATER_THAN_EQUAL_TO, KEY_LESS_THAN, KEY_LESS_THAN_EQUAL_TO,
} from '../constants'

export class DateType extends FieldType {
  constructor() {
    super()
  }

  static compare(aValue, condition, bValue) {
    if (condition === KEY_IS_EMPTY) return isEmpty(aValue)

    const a = (aValue.seconds) ? Number(aValue.seconds)
            : isNumber(aValue) ? Number(aValue)
            : getTimestampByDate(aValue) + getTimezoneOffset()
    const b = (bValue.seconds) ? Number(bValue.seconds) 
            : isNumber(bValue) ? Number(bValue)
            : getTimestampByDate(bValue) + getTimezoneOffset() 

    return condition === KEY_EQUAL_TO              ? a === b
         : condition === KEY_NOT_EQUAL_TO          ? a !== b
         : condition === KEY_GREATER_THAN          ? a > b
         : condition === KEY_GREATER_THAN_EQUAL_TO ? a >= b
         : condition === KEY_LESS_THAN             ? a < b
         : condition === KEY_LESS_THAN_EQUAL_TO    ? a <= b
         : null 
  }
}