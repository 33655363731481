// Dependencies
import { throwError } from './errors.mjs'
import { required } from './required.mjs'
import { isBoolean } from './is.mjs'

/**
 * Validate a number
 */
function validateNumber(
  key = required('key'),
  value = required('value')
) {
  if (isNaN(value)) {
    return throwError(`${key} must be a number.`)
  }
  return Number(value)
}

function validateBoolean(
  key = required('key'),
  value = required('value')
) {
  if (!isBoolean(value)) {
    return throwError(`${key} must be a boolean.`)
  }
  return value
}

// Exports
export { validateBoolean, validateNumber }
