// utils
import { required } from '../../utils/required.mjs'


// external events
import { unsubscribe, SCOPE_TEAM, SCOPE_GLOBAL } from '../../events/external-events.mjs'

import { addEventsColumnAutosize, addEventsColumnResize } from '../../list-core/events/size-column-events.mjs'
import { addEventsColumnHide, addEventsColumnUnhide } from '../../list-core/events/hide-unhide-column-events.mjs'
import { addEventsColumnMove } from '../../list-core/events/move-column-events.mjs'
import { addEventsColumnPin, addEventsColumnUnpin } from '../../list-core/events/pin-unpin-column-events.mjs'
import { addEventsColumnSortAsc, addEventsColumnSortDesc } from '../../list-core/events/sort-column-events.mjs'

import { addEventsDownloadAsCsv } from '../../list-core/events/download-as-csv-events.mjs'

import { addEventsPriceListModified } from '../../list-core/events/price-list-modify-events.mjs'

import {
  addEventsPriceListItemsUpdated,
  addEventsSeletedPriceListItemsDelete
} from '../../list-core/actions/price-list-event-listener.mjs'

import {
  addEventsIntegrationPublish
} from '../../list-core/events/publish-to-integration.mjs'


import {
  addEventsLogFileOverlay
} from '../../list-core/events/overlay-log-file-events.mjs'


let subscriptions = []
let globalSubscriptions = []

let columnEventController = null
let itemEventController = null


/**
 * Start view column events
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const startTeamPriceListColumnEvents = (
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  columnEventController = new AbortController()

  addEventsColumnHide(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnUnhide(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnPin(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnUnpin(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnSortAsc(columnEventController, columnManager)
  addEventsColumnSortDesc(columnEventController, columnManager)

  addEventsColumnAutosize(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnResize(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnMove(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  // download event
  addEventsDownloadAsCsv(columnEventController, columnManager, itemManager)

  // price list modified
  addEventsPriceListModified(columnEventController, subscriptions, columnManager, itemManager, { scope: SCOPE_GLOBAL })
}

/**
 * Stop view column events
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const stopTeamPriceListColumnEvents = () => {
  columnEventController?.abort()
  columnEventController = undefined

  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })

  }
  subscriptions = []

  //external events
  for (const subscription of globalSubscriptions) {
    unsubscribe(subscription, { scope: SCOPE_GLOBAL })
  }
  globalSubscriptions = []
}


export const startTeamPriceListItemEvents = (
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  itemEventController = new AbortController()

  addEventsPriceListItemsUpdated(itemEventController, globalSubscriptions, columnManager, itemManager, { scope: SCOPE_GLOBAL })
  addEventsSeletedPriceListItemsDelete(itemEventController, columnManager, itemManager)

  // integrations
  addEventsIntegrationPublish(itemEventController, columnManager, itemManager)

  // log file overlay
  addEventsLogFileOverlay(itemEventController, itemManager)
}


export const stopTeamPriceListItemEvents = () => {
  itemEventController?.abort()
  itemEventController = null
}