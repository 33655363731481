// Dependencies
import { required } from '../utils'

//sessions
import { getTeamSession } from '../session/team-session.mjs'

// utils
import { getTimestamp } from '../utils/timestamp.mjs'
import { IS_DEBUG_EVENT_MODE } from '../constants/debug.mjs'

// firebase
import { query, onSnapshot, where, orderBy } from 'firebase/firestore'
import { Db } from '../db/Db.mjs'
import { COLLECTION_EVENTS } from '../db/db-collections.mjs'

// events
import { EVENT_USER_AUTHENTICATED, EVENT_USER_SIGNOUT } from './constants/event-types.mjs'

// Constants
export const SCOPE_TEAM = 'team'
export const SCOPE_GLOBAL = 'global'


const subscribers = {}
/**
 * Publish event
 */

/**
 * Subscribe to event
 */

export const subscribe = (
  eventName = required('eventName'),
  subscriber = required('subscriber'),
  {
    scope = SCOPE_TEAM
  }
) => {
  let scopeIndex = SCOPE_GLOBAL
  if (scope === SCOPE_TEAM) {
    const team = getTeamSession()
    if (!team) {
      return
    }
    scopeIndex = team.id
  }
  subscribers[scopeIndex] ||= {}
  subscribers[scopeIndex][eventName] ||= []
  const len = subscribers[scopeIndex][eventName]
    .push(subscriber)
  return {
    eventName, eventIdx: (len - 1)
  }
}



/**
 * Unsubscribe from event
 */

export const unsubscribe = ({
  eventName = required('eventName'),
  eventIdx = required('eventIdx'),
},
  {
    scope = SCOPE_TEAM
  }
) => {
  let scopeIndex = SCOPE_GLOBAL
  if (scope === SCOPE_TEAM) {
    const team = getTeamSession()
    if (!team) {
      return true
    }
    scopeIndex = team.id
    if (!subscribers[scopeIndex] || !subscribers[scopeIndex][eventName]) {
      return true
    }
  }
  subscribers[scopeIndex][eventName]?.splice(eventIdx, 1)

  return true
}


/**
 * Start team events listener when user authenticated
 */

let teamEventsHandle = null
let globalEventHandle = null

export const startGlobalEventsHandler = () => {
  globalEventHandle && globalEventHandle()
  setTimeout(() =>
    globalEventHandle = globalEventsListener(),
    100)
}

export const stopGlobalEventsHandler = () => {
  globalEventHandle && globalEventHandle()
  globalEventHandle = null
}

export const startTeamEventsHandler = () => {
  teamEventsHandle && teamEventsHandle()
  setTimeout(() =>
    teamEventsHandle = teamEventsListener(),
    100)
}

export const stopTeamEventsHandler = () => {
  teamEventsHandle && teamEventsHandle()
  teamEventsHandle = null
}

addEventListener(EVENT_USER_SIGNOUT, () => {
  stopTeamEventsHandler()
  stopGlobalEventsHandler()
})


/**
 * global events listener
 * @returns 
 */

function globalEventsListener() {
  const startTime = getTimestamp()
  const team = getTeamSession()
  const db = new Db({ collectionName: COLLECTION_EVENTS })
  const listenerQuery = query(
    db.getCollection(),
    where('updatedAt', '>', startTime),
    where('scope', '==', SCOPE_GLOBAL),
    orderBy('updatedAt', 'desc')
  )
  return onSnapshot(listenerQuery, (snapshot) => {
    const changes = snapshot.docChanges()
    for (const change of changes) {
      if (change.type !== 'added') {
        continue
      }
      const event = change.doc.data()
      if (!event.action) {
        console.error('Skip event without action:', event)
        continue
      }
      if (!event.scope) {
        console.error('Skip event without scope:', event)
        continue
      }
      const { action, scope, payload } = event
      IS_DEBUG_EVENT_MODE && console.log('RECEIVED EXTERNAL EVENT:', action)
      IS_DEBUG_EVENT_MODE && console.log('EVENT PAYLOAD:', payload)

      const subscriberIdx = scope

      if (!subscribers[subscriberIdx]) {
        console.log('no subscriber for team event. skip.', event)
        return
      }
      if (!subscribers[subscriberIdx][action]) {
        console.log('No subscriber for the event. skip.', event)
        continue
      }
      for (const subscriber of subscribers[subscriberIdx][action]) {
        if (typeof subscriber !== 'function') {
          continue
        }
        subscriber({ eventId: event.id, payload })
      }
    }
  })
}


/**
 * team events listener
 * @returns 
 */

function teamEventsListener() {
  const startTime = getTimestamp()
  const team = getTeamSession()
  const db = new Db({ collectionName: COLLECTION_EVENTS })
  const listenerQuery = query(
    db.getCollection(),
    where('updatedAt', '>', startTime),
    where('scope', '==', SCOPE_TEAM),
    where('teamId', '==', team.id),
    orderBy('updatedAt', 'desc')
  )
  return onSnapshot(listenerQuery, (snapshot) => {
    const changes = snapshot.docChanges()
    for (const change of changes) {
      if (change.type !== 'added') {
        continue
      }
      const event = change.doc.data()
      if (!event.action) {
        console.error('Received unknown event action:', event)
        continue
      }
      if (!event.scope) {
        console.error('Received unknown event scope:', event)
        continue
      }
      const { action, scope, payload } = event
      IS_DEBUG_EVENT_MODE && console.log('RECEIVED EXTERNAL EVENT:', action)
      IS_DEBUG_EVENT_MODE && console.log('EVENT PAYLOAD:', payload)

      const subscriberIdx = team.id

      if (!subscribers[subscriberIdx]) {
        console.log('no subscriber for team event. skip.', event)
        return
      }
      if (!subscribers[subscriberIdx][action]) {
        console.log('Unknown event action. skip.', event)
        continue
      }
      for (const subscriber of subscribers[subscriberIdx][action]) {
        if (typeof subscriber !== 'function') {
          continue
        }
        subscriber({ eventId: event.id, payload })
      }
    }
  })
}
