// Constants
import {
  DEFAULT_FALSE_VALUE, DEFAULT_IS_TEXT_MULTI_LINE,
  DEFAULT_NUMBER_DP, DEFAULT_PERCENTAGE_DP, DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE, EMPTY_VALUE_TEXT
} from '../../constants/field.mjs'
import { DEFAULT_CURRENCY_ISO_CODE, POA } from '../../constants/currency.mjs'
import { DEFAULT_LOCALE } from '../../constants/locale.mjs'


/**
 * Make text format
 * @param {*} param0 
 * @returns 
 */
export const makeTextFormat = ({
  isMultiLine = DEFAULT_IS_TEXT_MULTI_LINE,
  textType = DEFAULT_TEXT_TYPE,
}) => {
  return {
    isMultiLine, textType
  }
}

/**
 * @param {*} param0 
 * @returns 
 */
export const makeNumberFormat = ({
  decimalPlaces = DEFAULT_NUMBER_DP
}) => {
  return {
    decimalPlaces
  }
}

/**
 * Make currency format
 * @param {*} param0 
 * @returns 
 */
export const makeCurrencyFormat = ({
  isoCode = DEFAULT_CURRENCY_ISO_CODE,
  noPriceValue = POA, // TODO: get the actual default value from the settings
  ...props
}) => {
  return {
    isoCode,
    noPriceValue,
    ...makeNumberFormat(props)
  }
}

/**
 * Make percentage format
 * @param {*} param0 
 * @returns 
 */
export const makePercentageFormat = ({
  decimalPlaces = DEFAULT_PERCENTAGE_DP,
  ...props
}) => {
  return {
    ...makeNumberFormat({
      ...props,
      decimalPlaces
    })
  }
}

/**
 * Make taglist format
 * @param {*} param0 
 * @returns 
 */
export const makeTagListFormat = ({
  dataType = DEFAULT_TEXT_TYPE
}) => {
  return {
    dataType
  }
}

/**
 * Make checkbox format
 * @param {*} param0 
 * @returns 
 */
export const makeCheckboxFormat = ({
  trueValue = DEFAULT_TRUE_VALUE,
  falseValue = DEFAULT_FALSE_VALUE,
  emptyValue = EMPTY_VALUE_TEXT
}) => {
  return {
    trueValue, falseValue, emptyValue
  }
}

/**
 * Make date format
 * @param {*} param0 
 * @returns 
 */
export const makeDateFormat = ({
  dateFormat = DEFAULT_LOCALE,
  timeFormat = DEFAULT_LOCALE,
}) => {
  return {
    dateFormat, timeFormat
  }
}

/**
 * Make file format
 * @param {*} format 
 * @returns 
 */

export const makeFileFormat = (format) => {
  return {}

}

