
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../db/db-constants.mjs'

const app = getApp()
const CALLABLE_SAVE_ITEMS = 'saveItemsRpc'
const CALLABLE_IMPORT_ITEMS = 'importItemsRpc'
const CALLABLE_LIST = 'listCore'

let saveItemRpc = null
let importItemsRpc = null
let listCoreRpc = null

/**
 * get the salesforce rpc function
 * @returns 
 */
export const getSaveItemsRpc = () => {
  if (saveItemRpc) {
    return saveItemRpc
  }
  const functions = getFunctions(app, AU_REGION)
  saveItemRpc = httpsCallable(functions, CALLABLE_SAVE_ITEMS, {timeout: 540000 } )
  return saveItemRpc
}


export const getImportItemsRpc = () => {
  if (importItemsRpc) {
    return importItemsRpc
  }
  const functions = getFunctions(app, AU_REGION)
  importItemsRpc = httpsCallable(functions, CALLABLE_IMPORT_ITEMS, {timeout: 540000 } )
  return importItemsRpc
}


export const getListCoreRpc = () => {
  if (listCoreRpc) {
    return listCoreRpc
  }
  const functions = getFunctions(app, AU_REGION)
  listCoreRpc = httpsCallable(functions, CALLABLE_LIST)
  return listCoreRpc
}