// utils
import { required } from '../../utils/required.mjs'

// session
import { getTeamSession } from '../../session/team-session.mjs'

// constants
import { CSV_PRICE_LIST } from '../constants/list-types.mjs'

// events
import {
  EVENT_UPLOAD_CSV_LOCAL,
  EVENT_UPLOAD_CSV_LOCAL_COMPLETED,
  EVENT_PROGRESS_START
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

// actions
import { createStaticList } from '../actions/static-list-actions.mjs'
import { getListAndColumns } from '../db/get-list-and-columns.mjs'
//import { updateStaticListFile } from '../import/import-csv.mjs'
import { updateStaticListFile } from '../actions/static-list-actions.mjs'

import { addRowIds } from '../../storage/add-row-ids.mjs'

export const addEventsUploadCsv = (controller) => {
  addEventListener(
    EVENT_UPLOAD_CSV_LOCAL,
    (event) => uploadCsvEvent(event),
    { signal: controller.signal }
  )
  return
}


export const uploadCsvEvent = async (event) => {
  const { isNew, file, listId } = event.detail
  const peFile = await addRowIds(file)
  if (isNew) {
    await uploadCsvNew(peFile)
  } else {
    await uploadCsvReplace(peFile, listId)
  }
}


const uploadCsvNew = async (
  file = required('file'),
) => {
  const currentTeam = getTeamSession()

  dispatch(EVENT_PROGRESS_START, {
    label: 'Uploading file...',
    progressStyle: 'percentage'
  })

  //TODO: PL - this is a bit of a hack from old code. need to be re-written

  await createStaticList({
    name: file.name,
    type: CSV_PRICE_LIST,
    teamId: currentTeam.id,
    isStatic: true,
    file,
  })

  dispatch(EVENT_UPLOAD_CSV_LOCAL_COMPLETED)
  return
}


const uploadCsvReplace = async (
  file = required('file'),
  listId = required('listId')
) => {
  const currentTeam = getTeamSession()

  dispatch(EVENT_PROGRESS_START, {
    label: 'Uploading file...',
    progressStyle: 'percentage'
  })

  //TODO: PL - this is a bit of a hack from old code. need to be re-written
  console.log('uploadCsvExisting called')
  const { list, columnSet } = await getListAndColumns(listId)
  list.columnSet = columnSet
  await updateStaticListFile(list, file)

  dispatch(EVENT_UPLOAD_CSV_LOCAL_COMPLETED, { list })
  return
}


