/*!
 * Team Entity
 */

import { Entity } from '../entity'

import { TRUE } from '../constants'
import { required } from '../utils'
import { DEFAULT_TEAM_PLAN } from '../constants/team.mjs'
import { DEFAULT_LOCALE } from '../constants/locale.mjs'
import { DEFAULT_ENVIRONMENT } from '../constants/environments.mjs'
import { DEFAULT_CURRENCY_ISO_CODE } from '../constants/currency.mjs'
import { DEFAULT_TEAM_PLAN_TRIAL_DURATION } from '../constants/team.mjs'

import { getCurrentUser } from '../actions/current-user.mjs'

export class TeamEntity extends Entity {
  #defaultCurrencyIsoCode
  #defaultLocale
  #environment
  #expiresAt
  #isActive
  #libraryListId
  #members
  #name
  #billingCustomerId
  #ownerId
  #plan
  #planId
  #planQuantity
  #planStatus
  #integrations

  constructor({
    defaultCurrencyIsoCode = DEFAULT_CURRENCY_ISO_CODE,
    defaultLocale = DEFAULT_LOCALE,
    environment = DEFAULT_ENVIRONMENT,
    expiresAt = Date.now() + DEFAULT_TEAM_PLAN_TRIAL_DURATION,
    isActive = TRUE,
    libraryListId,
    members = [],
    billingCustomerId,
    name, 
    ownerId = getCurrentUser().id, //TODO: make it required.
    plan = DEFAULT_TEAM_PLAN,
    planId,
    planQuantity,
    planStatus,
    ...props
  }) {
    super({ ...props })
    this.defaultCurrencyIsoCode = defaultCurrencyIsoCode
    this.defaultLocale = defaultLocale
    this.environment = environment
    this.expiresAt = expiresAt
    this.billingCustomerId = billingCustomerId
    this.isActive = isActive
    this.libraryListId = libraryListId
    this.name = name
    this.members = members
    this.ownerId = ownerId
    this.plan = plan
    this.planId = planId
    this.planQuantity = planQuantity
    this.planStatus = planStatus
  }

  //#region getters
  get defaultCurrencyIsoCode() { return this.#defaultCurrencyIsoCode }
  get defaultLocale() { return this.#defaultLocale }
  get environment() { return this.#environment }
  get expiresAt() { return this.#expiresAt }
  get isActive() { return this.#isActive }
  get libraryListId() { return this.#libraryListId }
  get members() { return [...this.#members] }
  get name() { return this.#name }
  get billingCustomerId() { return this.#billingCustomerId }
  get ownerId() { return this.#ownerId }
  get plan() { return this.#plan }
  get planId() { return this.#planId }
  get planQuantity() { return this.#planQuantity }
  get planStatus() { return this.#planStatus }
  //#endregion

  //#region setters
  set defaultCurrencyIsoCode(str) { this.#defaultCurrencyIsoCode = str }
  set defaultLocale(str) { this.#defaultLocale = str }
  set isActive(bool) { this.#isActive = !!bool }
  set environment(str) { this.#environment = str }
  set expiresAt(num) { this.#expiresAt = num }
  set libraryListId(str) { this.#libraryListId = str }
  set members(arr) { this.#members = arr }
  set name(str) { this.#name = str }
  set billingCustomerId(billingCustomerId) { this.#billingCustomerId = billingCustomerId }
  set ownerId(str) { this.#ownerId = str }
  set plan(str) { this.#plan = str }
  set planId(str) { this.#planId = str }
  set planQuantity(num) { this.#planQuantity = num }
  set planStatus(str) { this.#planStatus = str }
  //#endregion

  //--- members methods ---//
  addMember({ 
    userId = required('userId'), 
    email, 
    role,
    ...props 
  }) {
    const index = this.#members.findIndex(member => member.userId === userId)
    if (index !== -1) {
      this.#members.splice(index, 1, { userId, email, role, ...props })
      return this.#members.length //TODO: JL - tested and pass
    }
    return this.#members.push({ userId, email, role, ...props }) //TODO: JL - tested and pass
  }
  removeMember(
    userId = required('userId')
  ) {
    const index = this.#members.findIndex(member => member.userId === userId)
    if (index !== -1) {
      this.#members.splice(index, 1)
    }
    return this.#members.length //TODO: JL - tested and pass
  }

  getMember(
    userId = required('userId')
  ) {
    return this.#members.find(member => member.userId === userId)
  }

  //#region object methods
  #getMyProperties() {
    return {
      defaultCurrencyIsoCode: this.#defaultCurrencyIsoCode,
      defaultLocale: this.#defaultLocale,
      isActive: this.#isActive,
      environment: this.#environment,
      expiresAt: this.#expiresAt,
      libraryListId: this.#libraryListId,
      members: this.#members,
      name: this.#name,
      billingCustomerId: this.#billingCustomerId,
      ownerId: this.#ownerId,
      plan: this.#plan,
      planId: this.#planId,
      planQuantity: this.#planQuantity,
      planStatus: this.#planStatus
    }
  }
  //#endregion

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties()
    }
  }
}