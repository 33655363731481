<template>

  <!-- Cell Container -->
  <div role="presentation">

    <!-- Button -->
    <q-btn
      flat
      round 
      size   = "xs"
      :color = "iconColor"
      :icon  = "iconName"
      @mousedown.stop = "onStatusMouseDown"
    />

    <!-- Tooltip -->
    <p11e-tooltip v-if="logStatus || hasBatchError || errorCount || warningCount">

      <!-- log status -->
      <div v-if="logStatus">
        <q-icon
          v-if  = "logStatus"
          name  = "circle"
          :color = "logStatus === 'ok' ? 'green' : 'red'"
        /> Open right hand panel to see more details
      </div>  

      <!-- Batch errors -->
      <div v-if="hasBatchError">
        <q-icon
          v-if  = "hasBatchError"
          name  = "error"
          color = "orange"
        /> Batch Errors. see Console Log.
      </div>  

      <!-- Errors -->
      <div v-if="errorCount">
        <q-icon
          v-if  = "errorCount"
          name  = "warning"
          color = "red"
        /> {{ errorCount }} error{{ errorCount !== 1 ? 's' : '' }}
      </div>

      <!-- Warnings -->
      <div v-if="warningCount">
        <q-icon
          name  = "warning"
          color = "orange"
        /> {{ warningCount }} warning{{ warningCount !== 1 ? 's' : '' }}
      </div>
  
    </p11e-tooltip>
  </div>
  <!--/ Cell Container -->

</template>

<script>
// Constants
import { FIELD_KEY_FIRST_COLUMN } from '../../constants'

import { setLogDisplayItem } from '../../list-core/log-files/log-files-ui.mjs'
/**
 * Setup
 */
function setup(props) {
  const { api, data, rowIndex, columnApi } = props.params
  //ItemEntityChanges
  const { fields, lists } = data
  const errors   = []
  const warnings = []
  const unDeletedFields = columnApi.columnModel.columnDefs.map(col => col.field)

  for (const key in fields) {
    if (!unDeletedFields.includes(key)) continue 
    const field = fields[key]
    if (field.error) {
      errors.push(key)
    }
    if (field.warning) {
      warnings.push(key)
    }
  }

  const logStatus         = data.logStatus
  const hasBatchError     = data.error === 'Batch Errors'
  const listCount         = lists?.length - 1 // exclude library list id
  const activeListCount   = listCount // TODO: need to actually check to see if it's in active lists
  const errorCount        = errors.length
  const warningCount      = warnings.length
  const hasWarningOrError = errorCount + warningCount

  // Set icon
  const iconName
    = hasBatchError ? 'error'
    : errorCount   ? 'error'
    : warningCount ? 'error'
    : logStatus === 'ok' ? 'circle'
    : logStatus === 'error' ? 'error'
    : 'o_circle'

  // Set icon color
  const iconColor
    = hasBatchError ? 'orange'
    : logStatus === 'ok' ? 'green'
    : logStatus === 'error' ? 'red'
    : errorCount   ? 'red'
    : warningCount ? 'orange'
    : activeListCount ? 'primary'
    : 'grey'

  /**
   * On click handler
   * Scrolls (if required) and highlights the next cell that has an error / warning
   */
  function onStatusMouseDown() {
    // If this has been called from another handler or the item doesn't have a warning or error, there is no point in continuing
    if (isNaN(rowIndex) || (!hasWarningOrError && !logStatus)) {
      return
    }

    if (logStatus) {
      data.id && setLogDisplayItem(data.id)
      return
    }
    
    api.tabToNextCell()
    
    const currentFocus = api.getFocusedCell()
    if (currentFocus?.rowIndex !== rowIndex) {
      api.setFocusedCell(rowIndex, FIELD_KEY_FIRST_COLUMN, null)
      return onStatusMouseDown()
    }

    const cell = api.getFocusedCell()
    const key = cell?.column.colDef.field
    const field = fields[key]
    if (!field.error && !field.warning) {
      return onStatusMouseDown()
    }
  }

  // Expose to component
  return {
    listCount, activeListCount,
    iconName, iconColor, errorCount, warningCount,
    hasBatchError,
    logStatus,
    onStatusMouseDown
  }
}

// Exports
export default {
  setup
}

</script>
