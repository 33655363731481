import { REGISTERED_APPS } from '../../integration-apps/registered-apps.mjs'

const registry = {}


const loadRegistry = () => {
  for (const app of REGISTERED_APPS) {
    registry[app.type] = registry[app.type] || {}
    registry[app.type][app.subtype] = app
  }
}

loadRegistry()

export const getAvailableIntegrationApps = async (teamId) => {
  if (!teamId) {
    return []
  }
  return REGISTERED_APPS
}

export const getIntegrationApp = (type, subtype) => {
  return registry[type][subtype]
}

export const registerAppFunctions = (type, subtype, {
  createConfigurationFunc,
  setConfigurationUiStatesFunc,
  ...props
}) => {
  registry[type][subtype].functions = {
    createConfigurationFunc,
    setConfigurationUiStatesFunc
  }
}