import script from "./RightHandMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./RightHandMenu.vue?vue&type=script&setup=true&lang=js"

import "./RightHandMenu.vue?vue&type=style&index=0&id=57a35cf8&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBadge,QTabs,QTab,QTabPanels,QTabPanel,QToggle,QScrollArea,QSelect,QInput,QSeparator,QMenu,QList,QIcon,QItem,QItemSection,QItemLabel,QCheckbox});qInstall(script, 'directives', {ClosePopup});
