import { createNotification } from './create-notification.mjs'

/**
 * Raise delete confirmation
 */
export const raiseConfirmDelete = (message, handler) => {
  return createNotification({
    message,
    icon: 'warning',
    color: 'red-8',
    position: 'center',
    timeout: 0,
    textColor: 'white',
    actions: [
      { label: 'Delete', color: 'white', handler },
      { label: 'Cancel', color: 'white' },
    ]
  })
}

