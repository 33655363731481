
//utils
import { generateFieldKeyFromLabel } from '../../field-schema/utils/field-schema-utils.mjs'
import { createDbItem, createImportDbItem } from '../../item/utils/create-db-item.mjs'

// constants
import { FIELD_LABEL_NEW_COLUMN, FIELD_LABEL_NO_MAP_COLUMN } from '../../constants/field.mjs'
import { IMPORT_BATCH_SIZE } from '../../constants/batch-sizes.mjs'

//events
import {
  EVENT_ITEMS_IMPORT_LIBRARY_LOCAL,
  EVENT_COLUMN_FIELD_SCHEMA_NEW,
  EVENT_PROGRESS_START,
  EVENT_PROGRESS_SET_STATUS,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// constants


// RPCs
import { getImportItemsRpc } from '../../db/rpcs/db-rpcs.mjs'

// session event
import { getTeamSession } from '../../session/index.mjs'

// entity
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'

// actions
import { getListAndColumns } from '../../list-core/db/get-list-and-columns.mjs'
import { isImportMode } from '../../ui/ui-states.mjs'
import { confirmImport } from '../../list-core/import/import-confirmations.mjs'



/**
 * Find new columns with existing key label
 * @param {*} libraryFieldSchemas 
 * @param {*} importColumnSet 
 * @returns 
 */
const newColumnWithExistingKeyLabel = (libraryFieldSchemas, importColumnSet) => {
  const matchedKeyLabelColumns = []
  for (const importColumn of importColumnSet.columns) {
    if (importColumn.fieldSchema.label !== FIELD_LABEL_NEW_COLUMN) {
      continue
    }

    const importKey = generateFieldKeyFromLabel(importColumn.label)
    const matched = libraryFieldSchemas
      .find(fs => fs.key === importKey || fs.label === importColumn.label)

    if (!matched) {
      continue
    }
    matchedKeyLabelColumns.push(importColumn)
  }
  return matchedKeyLabelColumns
}


/**
 * Upsert items to library
 * @param {*} libraryListId 
 * @param {*} importColumnSetId 
 * @param {*} importItems 
 */
const upsertItemsToLibrary = async (
  libraryListId,
  importColumnSetId,
  importItems
) => {
  const startTime = Date.now()
  const importItemsRpc = getImportItemsRpc()
  let items = []
  let cnt = 0
  const params = {
    libraryListId,
    importColumnSetId,
    updateByFields: ['productCode']
  }
  dispatch(EVENT_PROGRESS_START, {
    label: 'Importing items...',
    maxValue: importItems.length,
  })


  for (const item of importItems) {
    const newItem = item.cloneAsNew().toObject()
    newItem.isStatic = false
    items.push(createImportDbItem(newItem))
    cnt++
    if (cnt === IMPORT_BATCH_SIZE) {
      await importItemsRpc({ ...params, items })
      dispatch(EVENT_PROGRESS_SET_STATUS, {
        addValue: items.length,
      })
      cnt = 0
      items = []

    }
  }
  // send remainder
  if (cnt > 0) {
    await importItemsRpc({ ...params, items })
    dispatch(EVENT_PROGRESS_SET_STATUS, {
      addValue: items.length,
    })
  }
  items = undefined
  console.log('Import completed. items:', importItems.length, Date.now() - startTime, 'ms')
}

/**
 * Import list item
 * @param {*} libraryColumnSet 
 * @param {*} importColumnSet 
 * @param {*} items 
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
const importListItems = async (
  libraryColumnSet,
  importColumnSet,
  items,
  columnManager
) => {
  const teamEvent = createTeamEvent(EVENT_COLUMN_FIELD_SCHEMA_NEW)
  isImportMode.value = false

  //add new columns
  const importFieldSchemas = []
  for (const importColumn of importColumnSet.columns) {
    if (importColumn.fieldSchema.label === FIELD_LABEL_NO_MAP_COLUMN) {
      continue
    }
    if (importColumn.fieldSchema.label !== FIELD_LABEL_NEW_COLUMN) {
      importFieldSchemas.push(importColumn.fieldSchema)
      continue
    }
    const importKey = generateFieldKeyFromLabel(importColumn.label)
    importColumn.fieldSchema.key = importKey
    importColumn.fieldSchema.label = importColumn.label
    const newColumn = importColumn.cloneAsNew()
    newColumn.fieldSchema.setCreated().setUpdated()
    newColumn.fieldSchema.updatedByEventId = teamEvent.id
    newColumn.updatedByEventId = teamEvent.id
    await FieldSchemaEntity.upsert(newColumn.fieldSchema)
    newColumn.fieldSchema = undefined
    newColumn.setUpdated()
    libraryColumnSet.addColumn(newColumn)
    importFieldSchemas.push(newColumn.fieldSchema)
  }
  // save all the column sets
  importColumnSet.setUpdated()
  importColumnSet.updatedByEventId = teamEvent.id
  libraryColumnSet.setUpdated()
  libraryColumnSet.updatedByEventId = teamEvent.id

  await ColumnSetEntity.upsert(libraryColumnSet)
  await ColumnSetEntity.upsert(importColumnSet)

  columnManager.refreshColumns()

  teamEvent.payload = {
    columnSetId: libraryColumnSet.id,
    teamId: libraryColumnSet.teamId
  }
  setTimeout(() => emitEvent(teamEvent), 500)

  // import items
  await upsertItemsToLibrary(
    libraryColumnSet.listId,
    importColumnSet.id,
    items
  )

  // import items
  // call rpc to import filepath
  isImportMode.value = true
}

/**
 * Update column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const importItemsLibraryEvent = async (event, columnManager, itemManager) => {
  const { items } = event.detail
  const importItemsRpc = getImportItemsRpc()
  const team = getTeamSession()

  const library = await getListAndColumns(team.libraryListId)

  const importColumnSet = columnManager.importColumnSet
  const colWithExistingKeyLabel = newColumnWithExistingKeyLabel(library.fieldSchemas, importColumnSet)
  let messageType = 'info'
  let message = `Import ${items.length} item${items.length > 1 ? 's' : ''} to Library.`
  if (colWithExistingKeyLabel.length > 0) {
    const labels = colWithExistingKeyLabel.map(col => col.label).join(', ')
    const s = (colWithExistingKeyLabel.length > 1) ? 's' : ''
    message = `Error: New column${s} [ ${labels} ] will create identical key${s} that clash with existing column${s}. Please map to existing column or change the label.`
    messageType = 'error'
  }

  confirmImport(
    message,
    async () => await importListItems(
      library.columnSet,
      importColumnSet,
      items,
      columnManager,
      itemManager
    ),
    messageType
  )
}


/**
 * Add events import items to library
 * @param {*} controller 
 * @param {*} subscriptions 
 * @param {*} columnManager 
 * @returns 
 */
export const addEventsImportItemsLibrary = (controller, subscriptions, columnManager) => {
  addEventListener(
    EVENT_ITEMS_IMPORT_LIBRARY_LOCAL,
    (event) => importItemsLibraryEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}
