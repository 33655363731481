/**
 * Field Mapping utils
 */
// utils
import { required } from '../../utils/required.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import {
  MAP_TYPE_KEY, MAP_TYPE_VALUE, MAP_TYPE_COLUMN_NAME,
  MAP_TYPE_LOOKUP, MAP_TYPE_PARENT_RESULT
} from '../constants/field-map-types.mjs'

export const newFieldMap = ({
  type,
  peField,
  sfField,
  value = CHAR_EMPTY,
}) => {
  const newMap = {
    type: type,
    sfField: {
      key: sfField?.key || null,
      label: sfField?.label || null,
      required: sfField?.required || false
    }
  }

  switch (type) {
    case MAP_TYPE_KEY:
      newMap.peField = {
        key: peField?.key || null,
        label: peField?.label || null,
      }
      break
    case MAP_TYPE_VALUE:
      newMap.value = value
      break
      case MAP_TYPE_COLUMN_NAME:
        newMap.value = value
        break
  }
  return newMap
}

/**
 * Create Salesforce Publish Advanced Product Mapping Configuration
 * @param {string} destinationKey - map to Salesforce field
 * @param {string} type - type = 'key'|'value'|'lookupValue'|'lookupKey'|'parentResult' 
 * @param {boolean} [isRequired=false] - optional. Default is false
 * @param {string} [key] - key is required when type is 'key'|'lookupKey'|'parentResult'
 * @param {string} [value] - value is required when type is 'value'|'lookupValue'
 * @param {Object} [lookup] - lookup object is required when type is 'lookupKey'|'lookupValue'
 * @param {string} [lookup.name]
 * @param {string} [lookup.key]
 * @param {string} [lookup.resultKey]
 * @param {string} [columnName]
 * @returns {Object} fieldMapping
 */
export const newAdvancedFieldMap = ({
  destinationKey = CHAR_EMPTY,
  type,
  isRequired = false,
  key = CHAR_EMPTY,
  value = CHAR_EMPTY,
  lookupName = CHAR_EMPTY,
  lookupResultKey = CHAR_EMPTY,
  columnName = CHAR_EMPTY,
  lookupConditions
}) => {
  const newMap = {
    destinationKey: destinationKey,
    type: type,
    isRequired: isRequired
  }
  switch (type) {
    case MAP_TYPE_KEY:
      newMap.key = key
      break
    case MAP_TYPE_VALUE:
      newMap.value = value
      break
    case MAP_TYPE_PARENT_RESULT:
      newMap.key = key || 'id'
      break
    case MAP_TYPE_LOOKUP:
      newMap.lookupName = lookupName
      newMap.lookupResultKey = lookupResultKey
      newMap.lookupConditions = lookupConditions || [newCondition()]
      break
    case MAP_TYPE_COLUMN_NAME:
      newMap.columnName = columnName
      break
  }
  return newMap
}

export const newCondition = () => {
  return {
    lookupKey: CHAR_EMPTY,
    matchBy: 'key',
    matchOp: '==',
    matchValue: CHAR_EMPTY,
  }
}