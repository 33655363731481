/**
 * integration event listeners
 */

// event
import { 
  EVENT_USER_AUTHENTICATED,
  EVENT_USER_SIGNOUT
} from '../../events/constants/event-types.mjs'

//event actions
import { startIntegrationsListener, stopIntegrationsListener } from './integrations-listener.mjs'


// TODO: PL - change this only start based on role
export const startIntegrationsEventListeners = () => {
  addEventListener(EVENT_USER_AUTHENTICATED, startIntegrationsListener)
  addEventListener(EVENT_USER_SIGNOUT, stopIntegrationsListener)
}


