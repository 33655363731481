import { createNotification } from './create-notification.mjs'

/**
 * Raise delete confirmation
 */
export const raiseConfirmAlert = (message, handler, {
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel'
} = {}) => {
  return createNotification({
    message,
    icon: 'warning',
    color: 'red-8',
    position: 'center',
    timeout: 0,
    textColor: 'white',
    actions: [
      { label: confirmLabel, color: 'white', handler },
      { label: cancelLabel, color: 'white' },
    ]
  })
}

