/**
 * UUID utils
 */

/**
 * Generate Unique ID
 */
function generateId() {
  return Date.now() + '-' + crypto.randomUUID()
}

// Exports
export { generateId }
