import { triggerRef } from "vue"
import {
  isShareListOpen, selectedList,
  isRemoveUserFromListConfirmModalOpen, isStaticList,
} from '../../ui/ui-states.mjs'

/**
 * Open shared list
 */
function openShareList(list) {
  selectedList.value = list
  isShareListOpen.value = true
}

/**
 * Open remove user from list
 */
function openRemoveUserFromList(list) {
  selectedList.value = list
  isStaticList.value = selectedList.value.isStatic
  isRemoveUserFromListConfirmModalOpen.value = true
}

export {
  openShareList, openRemoveUserFromList
}
