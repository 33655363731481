<template>
  <div id="currentCellInput" ref="cell" style="min-width: 400px; height: 100%; max-height: 400px; background-color: white;" :style="isFormulaMode ? 'min-height: 300px;' : 'min-height: 39px;'">
    <!-- Formula Editor -->
    <template v-if="isFormulaMode">
      <i style="color: #888; position: absolute; top: 10px; left: 10px;">fx</i>
      <div
        style="
          margin-left: 18px;
          min-height: 38px;
          font-size: 14px;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: 0.00937em;
          padding: 0 10px;
          white-space: nowrap;
          overflow: hidden;
          width: calc(100% - 62px);
        "
        ref="formulaDisplay"
        v-html="formulaValue"
      ></div>
      <q-input
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        "
        @scroll="onFormulaInputScroll($refs)"
        type="text"
        :autofocus="true"
        :clearable="true"
        dense
        square
        outlined
        ref="input"
        input-style="
          margin-left: 8px;
          color: transparent;
          caret-color: black;
        "
        v-model="inputValue"
        @keydown.capture="onFormulaKeyDown"
      />
    </template>

    <!-- Taglist Field -->
    <template v-if="field.type === FIELD_TYPE_TAGLIST">
      <q-select
        :model-value="getTaglistInputValue()"
        @update:model-value="value => inputValue = value"
        ref="input"
        use-input
        use-chips
        multiple
        color="primary"
        bg-color="accent"
        outlined
        square
        hide-dropdown-icon
        input-debounce="0"
        new-value-mode="add"
        dense
      />
    </template>

    <!-- Date Field -->
    <template v-else-if="field.type === FIELD_TYPE_DATE">
      <q-input 
        type="date"
        ref="input"
        :clearable="true"
        :placeholder="defaultValue"
        bg-color="white"
        dense
        square
        outlined
        v-model="inputValue"
      />
    </template>

    <!-- Checkbox Field -->
    <template v-else-if="field.type === FIELD_TYPE_CHECKBOX">
      <p11e-checkbox 
        @checkboxUpdated="onCheckboxUpdated"
        :value="inputValue" 
        :label="inputValue   === true  ? data?.format?.trueValue  || DEFAULT_TRUE_VALUE
              : inputValue === false ? data?.format?.falseValue   || DEFAULT_FALSE_VALUE
              : data?.format?.emptyValue                          || EMPTY_VALUE_TEXT" />
    </template>

    <!-- Text Field -->
    <template v-else>

      <!-- Multi-line -->
      <q-input v-if="isMultiLine"
        type="textarea"
        :placeholder="defaultValue"
        :clearable="true"
        dense
        square
        outlined
        bg-color="white"
        ref="input"
        input-style="resize: none;"
        v-model="inputValue"
        @keydown.capture="onTextareaKeyDown"
      />

      <!-- Single Line -->
      <q-input
        v-else-if="!isFormulaMode"
        type="text"
        :placeholder="defaultValue"
        :clearable="true"
        dense
        square
        outlined
        bg-color="white"
        ref="input"
        :model-value="getTextInputValue()"
        @update:model-value="value => inputValue = value"
      />
    </template>

    <!-- Formula Mode Helper -->
    <div v-if="isFormulaMode" style="border-top: 3px solid #efefef; display: flex; flex-direction: column; padding: 1em 0;">
      <strong style="margin-left: 1em; color: #aeaeae;">Functions</strong>
      <div style="min-height: 105px; max-height: 120px; overflow: auto; margin: .5em 0;">
        <div class="menu-item" v-for="name of (currentFormulaMatches.length ? currentFormulaMatches : formulaNames)" :key="name" @click="addToFormula(name)">{{ name }} <span style="color: #cecece; margin-left: 1rem;">({{ getFormulaParams(name) }})</span></div>
      </div>
      <strong style="margin-left: 1em; color: #aeaeae; margin-top: 1rem;">Columns</strong>
      <div style="min-height: 105px; max-height: 120px; overflow: auto; margin: .5em 0;">
        <div class="menu-item" v-for="name of (currentColumnMatches.length ? currentColumnMatches : colKeys)" :key="name" @click="addToFormula(name)">{{ name }}</div>
      </div>
    </div>

  </div>
</template>

<style>
.menu-item {
  cursor: pointer;
  padding: .2em 1rem;
  white-space: nowrap;
}

.menu-item:hover {
  background-color: #efefef;
}
</style>

<script>

// Dependencies
import { ref, nextTick, watchEffect } from 'vue'
import { formulas } from '../../item/utils/compile-item.mjs'

// Utils
import { parseTaglist } from '../../utils/parse.mjs'
import { isArray, isFormula } from '../../utils/is.mjs'

// Actions
import { uploadFile, deleteFileById, getFileUrlByRef } from '@/storage'
 
// Constants
import {
  KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_DOWN, KEY_UP, KEY_RIGHT,
  EMPTY_VALUE_TEXT, DEFAULT_FALSE_VALUE, DEFAULT_TRUE_VALUE,
  FIELD_TYPE_TEXT, FIELD_TYPE_DATE, FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST,
} from '../../constants'
import { FIELD_TYPE_CURRENCY, FIELD_TYPE_NUMBER, FIELD_TYPE_PERCENTAGE } from '../../constants/field.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'


const formulaNames  = Object.keys(formulas).sort()
const formulaRegExp = new RegExp(`(${formulaNames.join('|')})(\\()`, 'gm')

/**
 * Setup
 */
function setup({ params }) {

  const data              = params.value
  const inputValue        = ref(data?.inputValue)
  const colId             = params.colDef.colId
  const columnData        = params.colDef.headerComponentParams.data
  const { teamId, listType, column, fieldSchema, fieldSchemas } = columnData

  //const field             = getListColumnById(colId).fieldSchema
  const field             = fieldSchema
  const isUploading       = ref(false)
  const isMultiLine       = field.format?.isMultiLine
  const isFormulaMode     = ref(false)
  const defaultValue      = field.defaultValue
  const formulaValue      = ref(null)
  const colKeys           = fieldSchemas.filter(fs => !fs.isDeleted).map(fs => fs.key)
  const colKeysRegExp     = new RegExp(`(\\${colKeys.join('|\\')})`, 'gm')
  const currentFormulaMatches = ref([])
  const currentColumnMatches  = ref([])

  let currentFormulaIndex = 0

  /**
   * Watch the input value to turn on/off the formula editor
   */
  watchEffect(() => {
    isFormulaMode.value = [
      FIELD_TYPE_TEXT, FIELD_TYPE_NUMBER, FIELD_TYPE_CURRENCY, FIELD_TYPE_PERCENTAGE
    ].includes(field.type)
    && isFormula(inputValue.value)
    // exit if not in formula mode
    if (!isFormulaMode.value) {
      return
    }
    // format the formula input
    formulaValue.value = formatFormulaHtmlValue()
  })

  /**
   * Change input value based on child components' value
   */
  function onCheckboxUpdated(newValue) {
    inputValue.value = newValue
  }

  function getSyntaxFormattedHTML(input) {
    let html = input
      .replace(/(\+|-|\*|\/)/gm, `<span style="color: grey;">$1</span>`)
      .replace(colKeysRegExp, `<span style="color: #009aff;">$1</span>`)
      .replace(formulaRegExp, `<span style="color: blue;">$1</span>$2`)
      .replace(/(\(|\))/gm, `<span style="color: grey;">$1</span>`)
    return html
  }

  /**
   * Format the formula HTML value
   */
  function formatFormulaHtmlValue(completeScope = false) {
    const input = inputValue.value || CHAR_EMPTY

    const currentScope = input.substring(1).split(/[^a-z0-9$_]/gi).pop()
    let html = getSyntaxFormattedHTML(input.trim().replace('=', ' '))

    // Dont run the autocomplete if there isn't any current scope
    if (!currentScope.length) {
      return html
    }
    const matchValue = currentScope
    currentFormulaMatches.value = formulaNames.filter(name => name.startsWith(matchValue))
    currentColumnMatches.value  = colKeys.filter(key => {
      return key.startsWith(matchValue)
    })
    const matchingFormulas = [
       ...currentFormulaMatches.value,
       ...currentColumnMatches.value
    ]

    // dont continue if we don't have any matching formulas to match on
    if (!matchingFormulas.length) {
      currentFormulaIndex = 0
      currentFormulaMatches.value = []
      currentColumnMatches.value = []
      return html
    }

    const match = matchingFormulas[currentFormulaIndex]

    if (completeScope !== false) {
      inputValue.value
        = inputValue.value.substring(0, inputValue.value.length - currentScope.length)
        + (completeScope === true ? match : completeScope)
      currentFormulaIndex = 0
      currentFormulaMatches.value = []
      currentColumnMatches.value = []
      return html
    }

    if (currentFormulaIndex > matchingFormulas.length -1) {
      currentFormulaIndex = 0
    }

    if (currentFormulaIndex < 0) {
      currentFormulaIndex = matchingFormulas.length -1
    }

    if (!match) {
      currentFormulaMatches.value = []
      currentColumnMatches.value = []
      return html
    }

    html += `<span style="color: #aaa;">${match.substring(currentScope.length)}</span>`

    return html
  }

  /**
   * Formula keydown handler
   */
  function onFormulaKeyDown(event) {
    switch (event.key) {
      case KEY_DOWN:
        currentFormulaIndex++
        event.preventDefault()
        break
      case KEY_UP:
        currentFormulaIndex--
        event.preventDefault()
        break
      case KEY_RIGHT:
        return formulaValue.value = formatFormulaHtmlValue(true)
    }
    formulaValue.value = formatFormulaHtmlValue()
  }

  /**
   * Get Value
   * this is used by ag-grid to grab the value from our ref
   */
  function getValue() {
    return inputValue.value
  }

  /**
   * Upload file handler
   */
  async function onUploadFile(input) {
    const file = input.files[0]
    isUploading.value = true

    const reader = new FileReader()
    reader.onload = (event) => {
      inputValue.value = {
        ...inputValue.value,
        name: file.name,
        type: file.type,
        size: file.size,
        url:  event.target.result
      }
    }
    reader.readAsDataURL(file)

    const uploadedFile = await uploadFile({ file })
    const fileRef = uploadedFile.ref
    inputValue.value.id  = uploadedFile.metadata.name
    inputValue.value.url = await getFileUrlByRef(fileRef)
    isUploading.value = false
    return file
  }

  /**
   * Open file handler
   */
  function onOpenFile() {
    const el = document.createElement('a')
    el.href = inputValue.value.url
    el.target = '_blank'
    document.body.appendChild(el)
    el.click()
    el.remove()
  }

  /**
   * Clear file handler
   */
  function onClearFile() {
    if (inputValue.value.id) {
      deleteFileById(inputValue.value.id)
    }
    inputValue.value = null
  }

  /**
   * On multiline text key down
   */
  function onTextareaKeyDown(event) {
    // If Shift + Enter is pressed, then stop the enter event from closing the cell
    if (event.key === KEY_ENTER && event.shiftKey) {
      return event.stopPropagation()
    }
  }

  function onFileNameKeyDown(event) {
    // We are looking for the backspace
    if (event.key !== KEY_BACKSPACE) {
      return
    }
    // We don't want any input (this will mean a delete of image)
    if (inputValue.value.name.length) {
      return
    }
    return onClearFile()
  }

  /**
   * On formula input scroll
   */
  function onFormulaInputScroll({ formulaDisplay }) {
    formulaDisplay?.scroll?.(event.target.scrollLeft, 0)
  }

  /**
   * Add selected menu item to formula
   */
  function addToFormula(key) {
    const $el = this.$refs.input.getNativeElement()
    formulaValue.value = formatFormulaHtmlValue(key)
    $el.focus()
  }

  function getFormulaParams(key) {
    let params = formulas[key].toString().split('(', 2)[1]?.split(')')[0]
    if (!params || params?.trim() === '') {
      params = 'input'
    }
    return params?.replace(/,(\s)?/g, ', ')
  }

  function getTaglistInputValue() {
    return parseTaglist(inputValue.value)
  }

  function getTextInputValue() {
    return inputValue.value?.toString()
  }

   // Expose to component
  return {
    addToFormula,
    inputValue,
    formulaValue,
    formulas,
    data,
    field,
    isMultiLine,
    isFormulaMode,
    isUploading,
    defaultValue,
    formulaNames,
    currentFormulaMatches,
    currentColumnMatches,
    colKeys,
    onCheckboxUpdated,
    getValue,
    getTaglistInputValue,
    getTextInputValue,
    getFormulaParams,
    onClearFile,
    onOpenFile,
    onUploadFile,
    onTextareaKeyDown,
    onFormulaInputScroll,
    onFormulaKeyDown,
    onFileNameKeyDown,
    KEY_ENTER,
    EMPTY_VALUE_TEXT,
    DEFAULT_FALSE_VALUE,
    DEFAULT_TRUE_VALUE,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_DATE,
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_TAGLIST
  }
}

/**
 * Mounted handler
 */
function mounted() {
  nextTick(() => {
    const input      = this.$refs.input
    const inputChar  = this.params.charPress

    // dynamically size the input field
    const currentWidth = this.params.colDef.width
    const cellEditorWidth = currentWidth < 200 ? 200 : currentWidth
    const domInput = document.getElementById('currentCellInput')

    if (domInput) {
      domInput.style.width = cellEditorWidth + 'px'
    }

    // if backspace or delete, clear the value
    if ([ KEY_BACKSPACE, KEY_DELETE ].includes(event.key)) {
      // if it's a file/image, make sure we remove it
      const fileId = this.inputValue?.url && this.inputValue?.id
      if (fileId) {
        deleteFileById(fileId)
      }
      this.inputValue = ''
    }

    // if input character detected
    else if (inputChar) {

      // Value is an array
      if (isArray(this.inputValue)) {
        this.inputValue = []
        input.updateInputValue(inputChar)
      }

      // Default behaviour
      else {
        this.inputValue = inputChar
      }
    }

    // focus on the input field once editing starts
    input?.focus()
  })
}

// Exports
export default {
  setup, mounted
}

</script>
