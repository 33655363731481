/*!
* DBDocument
*/

import { UNDEFINED } from '@/constants/types.mjs'
import { generateId } from '@/utils/uuid.mjs'

import { getTimestamp } from '../utils/timestamp.mjs'
import { getUserSession } from '../session/user-session.mjs'
import { getUpdateInterval } from '../utils/update-interval.mjs'
export class DbDocument {
  #id
  #createdAt
  #createdBy
  #updatedAt
  #updatedBy
  #deletedAt
  #deletedBy
  #updatedAtInterval
  constructor({
    id = generateId(),
    createdAt = UNDEFINED,
    createdBy = UNDEFINED,
    updatedAt = UNDEFINED,
    updatedBy = UNDEFINED,
    deletedAt = UNDEFINED,
    deletedBy = UNDEFINED,
    updatedAtInterval = UNDEFINED,
  } = {}) {
    this.#id = id
    this.#createdAt = createdAt
    this.#createdBy = createdBy
    this.#updatedAt = updatedAt
    this.#updatedBy = updatedBy
    this.#deletedAt = deletedAt
    this.#deletedBy = deletedBy
    this.#updatedAtInterval = updatedAtInterval
  }

  //--- getters ----//
  get id() { return this.#id }
  get createdAt() { return this.#createdAt }
  get createdBy() { return this.#createdBy }
  get updatedAt() { return this.#updatedAt }
  get updatedBy() { return this.#updatedBy }
  get deletedAt() { return this.#deletedAt }
  get deletedBy() { return this.#deletedBy }
  get updatedAtInterval() { return this.#updatedAtInterval }

  //--- methods ---//
  setCreated() {
    this.#createdAt = this.#createdAt || getTimestamp()
    this.#createdBy = this.#createdBy || getUserSession()?.id
    this.#updatedAtInterval = getUpdateInterval()
    return this
  }

  setUpdated() {
    this.#updatedAt = getTimestamp()
    this.#updatedBy = getUserSession()?.id
    this.#updatedAtInterval = getUpdateInterval()
    return this
  }

  setDeleted() {
    this.#deletedAt = getTimestamp()
    this.#deletedBy = getUserSession()?.id
    this.#updatedAtInterval = getUpdateInterval()
    return this
  }

  toObject() {
    return {
      id: this.#id,
      createdAt: this.#createdAt,
      createdBy: this.#createdBy,
      updatedAt: this.#updatedAt,
      updatedBy: this.#updatedBy,
      deletedAt: this.#deletedAt,
      deletedBy: this.#deletedBy,
      updatedAtInterval: this.#updatedAtInterval
    }
  }


  //---static methods ---//

}