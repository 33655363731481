// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { getTeamSession } from '../../session/team-session.mjs'

// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// events
import {
  EVENT_COLUMN_PIN,
  EVENT_COLUMN_PIN_LOCAL,
  EVENT_COLUMN_PIN_REMOTE,
  EVENT_COLUMN_UNPIN,
  EVENT_COLUMN_UNPIN_LOCAL,
  EVENT_COLUMN_UNPIN_REMOTE,
  EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'


// session event
import { getSessionEvent } from '../../events/session-events.mjs'

export const addEventsColumnPin = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_PIN_LOCAL,
    (event) => pinColumnsEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_PIN,
      pinColumnsRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_PIN_REMOTE,
    (event) => pinColumnsRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}


export const addEventsColumnUnpin = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_UNPIN_LOCAL,
    (event) => unpinColumnsEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_UNPIN,
      unpinColumnsRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_UNPIN_REMOTE,
    (event) => unpinColumnsRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}


export const pinColumnsEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { columnIds } = event.detail
  const team = getTeamSession()

  // send external event
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_PIN,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnIds
    }
  })
  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.pin(columnIds, { gridOnly: false })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.pin(columnIds, { gridOnly: true })
  }
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })
}

export const unpinColumnsEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { columnIds } = event.detail
  const team = getTeamSession()

  // send external event
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_UNPIN,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnIds
    }
  })
  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.unpin(columnIds, { gridOnly: false })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.unpin(columnIds, { gridOnly: true })
  }
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })
}

/**
 * Pin columns external event handler
 * @param {*} event 
 * @returns 
 */
export const pinColumnsRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_PIN_REMOTE, payload)
}

/**
 * Unpin columns external event handler
 * @param {*} event 
 * @returns 
 */
export const unpinColumnsRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_UNPIN_REMOTE, payload)
}

/**
 * Pin columns event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const pinColumnsRemoteEvent = async (event, columnManager) => {
  const { listId, columnIds } = event.detail
  if (listId !== columnManager.list?.id) {
    IS_DEBUG_EVENT_MODE && console.log('Not for this list. skip.')
    return
  }
  await columnManager.pin(columnIds, { gridOnly: true })
  columnManager.refreshFilteredColumns()
}

/**
 * Unpin columns event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const unpinColumnsRemoteEvent = async (event, columnManager) => {
  const { listId, columnIds } = event.detail
  if (listId !== columnManager.list?.id) {
    IS_DEBUG_EVENT_MODE && console.log('Not for this list. skip.')
    return
  }
  await columnManager.unpin(columnIds, { gridOnly: true })
  columnManager.refreshFilteredColumns()
}
