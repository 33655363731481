/*!
 * Import items actions
 */

// Dependencies
import { ref } from 'vue'

// Utils
import { required } from '../../utils'

// Constants
import {
  TRUE, DEFAULT_FIELD_TYPE,
  FIELD_LABEL_NEW_COLUMN,
  FIELD_LABEL_NO_MAP_COLUMN,
} from '../../constants'
import { IMPORT_BATCH_SIZE } from '../../constants/batch-sizes.mjs'



import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { IMPORT_COLUMN_SET_TYPE } from '../../column-set/column-set-constants.mjs'

import { getTeamFieldSchemas } from '../../field-schema/actions/field-schema-actions.mjs'

import { fetchColumnSet, fetchImportColumnSetEntity, fetchDefaultColumnSetEntity, saveColumnSetEntity } from '@/column-set/db/column-set-db.mjs'
import { fetchListById, saveList } from '../../list/db/list-db.mjs'
import { setColumnWidthToLabel } from '../../column/column-ui-utils.mjs'

// RPCs
import { getImportItemsRpc } from '../../db/rpcs/db-rpcs.mjs'


// events
import { dispatch } from '../../events/dispatch.mjs'
import { EVENT_PROGRESS_START, EVENT_PROGRESS_SET_STATUS } from '../../events/constants/event-types.mjs'

import { isColumnMapped, isColumnNew, createNewColumnFieldSchema, createNoMapFieldSchema } from './import-utils.mjs'

import { isImportMode } from '../../ui/ui-states.mjs'

//#region //--- Import Columns and Field Schemas ---//

/**
 * Create an importColumnSet for a team
 * note that listEntity might be shared and might not be of the same team.
 * @param {Object} list
 * @param {string} teamId
 * @param {string} list.Id
 */

async function createImportColumnSet(listImportId, teamId) {
  const importList = await fetchListById(listImportId)
  if (!importList) {
    return
  }

  const newColumnSetEntity = new ColumnSetEntity({
    listId: listImportId,
    teamId,
    isStatic: TRUE,
    type: IMPORT_COLUMN_SET_TYPE
  })

  // load columns
  const teamFieldSchemas = await getTeamFieldSchemas(teamId)
  const importListColumnSet = await fetchColumnSet(importList.columnSetId)
  importListColumnSet.columns.forEach(col => {
    const fieldSchemaType = col.fieldSchema.type
    const fieldSchema = teamFieldSchemas.find(fs =>
      fs.label.toLowerCase() === col.label.toLowerCase()
      && !fs.isDeleted
    ) || createNewColumnFieldSchema(teamId, fieldSchemaType)
    addImportColumn(newColumnSetEntity, col, fieldSchema)
  })

  return newColumnSetEntity
}


async function getImportColumnSet(listImportId, teamId) {
  let importColumnSet = await fetchImportColumnSetEntity(listImportId, teamId)
  if (importColumnSet) {
    // refresh with latest field schemas
    const teamFieldSchemas = await getTeamFieldSchemas(teamId) //field schemas from the library
    importColumnSet.columns.forEach(col => {
      col.fieldSchema = (!isColumnMapped(col) || isColumnNew(col)) ? col.fieldSchema
        : teamFieldSchemas.find(fs => fs.id === col.fieldSchemaId && !fs.isDeleted) ? col.fieldSchema
          : createNewColumnFieldSchema(teamId) // mapped field schema no longer exist. make a new one
    })
  } else {
    // create a new set
    importColumnSet = await createImportColumnSet(listImportId, teamId)
  }

  const listColumnSet = await fetchDefaultColumnSetEntity(listImportId)
  // add any missing columns
  listColumnSet.columns.forEach(srcCol => {
    if (importColumnSet.columns.findIndex(importCol => importCol.importColumnId === srcCol.id && !srcCol.isDeleted) < 0) {
      newImportColumn(importColumnSet, srcCol)
    }
  })

  saveColumnSetEntity(importColumnSet)
  return importColumnSet
}

function addImportColumn(columnSet, columnEntity, fieldSchemaEntity) {
  fieldSchemaEntity = fieldSchemaEntity || createNewColumnFieldSchema(columnSet.teamId)
  const newColumn = new ColumnEntity({
    teamId: columnSet.teamId,
    key: columnEntity.key,
    label: columnEntity.label,
    fieldSchema: fieldSchemaEntity,
    fieldSchemaId: fieldSchemaEntity.id,
    importColumnId: columnEntity.id
  })
  newColumn.key = columnEntity.key
  newColumn.label = columnEntity.label
  columnSet.addColumn(newColumn)
  return columnSet
}

function updateImportColumn(columnSet, columnEntity, fieldSchemaEntity) {
  const mappedColumn = columnSet.columns.find(col => col.importColumnId === columnEntity.id)
  if (!mappedColumn) {
    return addImportColumn(columnSet, columnEntity, fieldSchemaEntity)
  }
  const existingKey = mappedColumn.key
  mappedColumn.fieldSchema = fieldSchemaEntity
  saveColumnSetEntity(columnSet)
  return columnSet
}

function newImportColumn(columnSet, columnEntity) {
  const fieldSchemaEntity = createNewColumnFieldSchema(columnSet.teamId, columnEntity.fieldSchema.type)
  const mappedColumn = columnSet.columns.find(col => col.importColumnId === columnEntity.id)
  if (!mappedColumn) {
    return addImportColumn(columnSet, columnEntity, fieldSchemaEntity)
  }
  mappedColumn.fieldSchema = fieldSchemaEntity
  saveColumnSetEntity(columnSet)
  return columnSet
}

function removeImportColumn(columnSet, columnEntity) {
  const mappedColumn = columnSet.columns.find(col => col.importColumnId === columnEntity.id)
  if (!mappedColumn) {
    return
  }
  mappedColumn.fieldSchema = createNoMapFieldSchema(mappedColumn.teamId)
  saveColumnSetEntity(columnSet)
  return columnSet
}

function updateImportFieldSchema(importColumnSet, sourceColumn) {
  const importColumn = importColumnSet.columns.find(col => col.id === sourceColumn.id)
    || importColumnSet.columns.find(col => col.importColumnId === sourceColumn.id)

  if (!importColumn) {
    return importColumnSet
  }
  // Do not the column key. It's used as the mapping source
  importColumn.label = sourceColumn.label
  //importColumn.resetKey() // reset the key using the label
  importColumn.fieldSchema.type = sourceColumn.fieldSchema.type
  importColumn.fieldSchema.format = sourceColumn.fieldSchema.format
  importColumn.fieldSchema.defaultValue = sourceColumn.fieldSchema.defaultValue
  importColumn.fieldSchema.info = sourceColumn.fieldSchema.info
  importColumn.fieldSchema.placeholderText = sourceColumn.fieldSchema.placeholderText

  saveColumnSetEntity(importColumnSet)
  return importColumnSet
}

/**
 * Update the import columnSet column states with the list columns
 * @param {*} listColumnSet 
 * @param {*} importColumnSet 
 */
function updateImportColumnSet(listColumnSet, importColumnSet) {
  let removeImportColumns = []
  importColumnSet.columns.forEach(importCol => {
    if (isColumnNew(importCol)) {
      return
    }
    const listColumn = listColumnSet.columns.find(listCol => importCol.importColumnId === listCol.id)
    if (!listColumn) {
      removeImportColumns.push(importCol.id)
      return
    }
    importCol.isDeleted
      = importCol.fieldSchema.isDeleted
      = listColumn.isDeleted
  })
  importColumnSet.columns = importColumnSet.columns.filter(importCol => !removeImportColumns.includes(importCol.id))
  saveColumnSetEntity(importColumnSet)

}

//#endregion

//#region //--- Import Items ---//

/**
 * Import items to library
 * @param {*} param0 
 * @returns 
 */
async function importItemsToLibrary({
  libraryId = required('libraryId'),
  importListEntity,
  columnSet,
  importColumnSet,
  importItems,
}) {
  isImportMode.value =false
  updateImportColumnSet(columnSet, importColumnSet)

  // create new field schemas
  // get all team field schemas
  const teamFieldSchemas = await getTeamFieldSchemas(importColumnSet.teamId)

  const teamFieldSchemasMap = new Map(teamFieldSchemas.filter(fs => !fs.isDeleted).map(fs => [fs.id, fs]))

  const libraryList = await fetchListById(libraryId)
  libraryList.columnSet = await fetchColumnSet(libraryList.columnSetId)
  importColumnSet.columns.forEach(column => {
    if (column.isDeleted || column.fieldSchema.isDeleted) {
      return
    }
    if (teamFieldSchemasMap.get(column.fieldSchemaId)) {
      return
    }
    if (isColumnNew(column)) {
      const newColumn = column.cloneAsNew()
      if (!newColumn?.width) newColumn.width = setColumnWidthToLabel(newColumn)
      newColumn.fieldSchema.label = column.label
      newColumn.fieldSchema.resetKey()
      newColumn.fieldSchema.setCreated().setUpdated()
      libraryList.columnSet.addColumn(newColumn)
      FieldSchemaEntity.upsert(newColumn.fieldSchema)
    }

  })
  libraryList.columnSet.setUpdated()
  importColumnSet.setUpdated()
  await ColumnSetEntity.upsert(libraryList.columnSet)
  await ColumnSetEntity.upsert(importColumnSet)

  //await saveList(libraryList)
  //await saveColumnSetEntity(importColumnSet)

  // import items
  await upsertItemsToLibrary(libraryList, importListEntity, importColumnSet, importItems)
  isImportMode.value =true
  return importColumnSet
}

async function upsertItemsToLibrary(libraryList, importListEntity, importColumnSet, importItems) {
  const startTime = Date.now()
  const importItemsRpc = getImportItemsRpc()
  let items = []
  let cnt = 0
  const params = {
    libraryListId: libraryList.id,
    importListId: importListEntity.id,
    importColumnSetId: importColumnSet.id,
    updateByFields: ['productCode']
  }
  dispatch(EVENT_PROGRESS_START, {
    label: 'Importing items...',
    maxValue: importItems.length,
  })


  for (const item of importItems) {
    items.push(item.cloneAsNew().toObject())
    cnt++
    if (cnt === IMPORT_BATCH_SIZE) {
      await importItemsRpc({ ...params, items })
      dispatch(EVENT_PROGRESS_SET_STATUS, {
        addValue: items.length,
      })
      cnt = 0
      items = []

    }
  }
  // send remainder
  if (cnt > 0) {
    await importItemsRpc({ ...params, items })
    dispatch(EVENT_PROGRESS_SET_STATUS, {
      addValue: items.length,
    })  
  }
  items = undefined
  console.log('Import completed. items:', importItems.length, Date.now() - startTime, 'ms')
}

export {
  getImportColumnSet,
  importItemsToLibrary
}

/*
export {
  createImportColumnSet, updateImportColumn, removeImportColumn, newImportColumn,
  updateImportFieldSchema,
  importItemsToLibrary
}
*/
