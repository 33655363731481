/*!
 * FieldSchema Entity
 */
import { Entity } from '././Entity.mjs'

import { CHAR_EMPTY } from '../constants/characters.mjs'
import { TRUE } from '../constants/types.mjs'
import { required } from '../utils/required'
import { throwError } from '../utils/errors.mjs'
import { isEmpty } from '../utils/is.mjs'
import { deepClone } from '../utils/deep-clone.mjs'

import {
  DEFAULT_FIELD_TYPE, DEFAULT_LABEL, DEFAULT_VALUE,
} from '../field-schema/field-schema-constants.mjs'

import {
  generateFieldKeyFromLabel, isValidFieldType,
} from '../field-schema/utils/field-schema-utils.mjs'

import {
  makeCreateFormat
} from '../field-schema/utils/make-create-format.mjs'

import {
  makeValidateFormat
} from '../field-schema/utils/make-validate-format.mjs'

import { upsertFieldSchema, fetchFieldSchema } from '../field-schema/data/field-schema-data.mjs'
 
export class FieldSchemaEntity extends Entity {
  #type
  #label
  #teamId
  #key
  #format
  #defaultValue
  #isHidden
  #info
  #placeholderText
  constructor({
    type = DEFAULT_FIELD_TYPE,
    label = DEFAULT_LABEL,
    teamId = required('teamId'),
    key = generateFieldKeyFromLabel(label),
    format = {},
    defaultValue = DEFAULT_VALUE,
    isHidden = false,
    info = CHAR_EMPTY,
    placeholderText = CHAR_EMPTY,
    ...props
  }) {
    super({ ...props })
    this.teamId = teamId
    this.key = key
    this.defaultValue = defaultValue
    this.isHidden = isHidden
    this.info = info
    this.label = label
    this.type = type
    this.format = format
    this.placeholderText = placeholderText
  }

  //--- getters ---//
  get isFieldSchemaEntity() { return TRUE }
  get type() { return this.#type }
  get label() { return this.#label }
  get teamId() { return this.#teamId }
  get key() { return this.#key }
  get format() { return this.#format }
  get defaultValue() { return this.#defaultValue }
  get isHidden() { return this.#isHidden }
  get info() { return this.#info }
  get placeholderText() { return this.#placeholderText }

  //--- setters ---//
  set teamId(id) { this.#teamId = id }
  set key(str) { this.#key = str }
  set defaultValue(val) { this.#defaultValue = val }
  set isHidden(bool) { this.#isHidden = !!bool }
  set info(str) { this.#info = str }
  set placeholderText(str) { this.#placeholderText = str }

  set label(str) {
    this.#label = str
    if (this.#label?.length && isEmpty(this.#key)) {
      this.#key = generateFieldKeyFromLabel(this.#label)
    }
  }

  set type(str) {
    if (!isValidFieldType(str)) {
      throwError('Invalid field type')
    }
    this.#type = str
    this.#setFormat()
  }

  set format(obj) {
    const createFormat = makeCreateFormat(this.#type)
    this.#format = createFormat(obj)
  }

  //--- methods ---//
  #setFormat() {
    const createFormat = makeCreateFormat(this.#type)
    const validateFormat = makeValidateFormat(this.#type)
    this.#format = (this.#format && validateFormat(this.#format))
      ? createFormat(this.#format)
      : createFormat()
  }

  resetKey() {
    this.#key = generateFieldKeyFromLabel(this.#label)
  }

  //--- object methods ---//
  validate() {
    const msg = `[key: "${this.#key}"  id: ${this.id}]`
    if (!this.id) { required(`${msg} id`) }
    if (!this.key) { required(`${msg} key`) }
    if (!this.teamId) { required(`${msg} teamId`) }
    if (!this.type) { required(`${msg} type`) }
    if (!this.label) { required(`${msg} label`) }
    if (!this.format) { required(`${msg} format`) }
  }

  #getMyProperties() {
    return {
      type: this.#type,
      label: this.#label,
      teamId: this.#teamId,
      key: this.#key,
      format: this.#format,
      defaultValue: this.#defaultValue,
      isHidden: this.#isHidden,
      info: this.#info,
      placeholderText: this.#placeholderText,
    }
  }

  cloneAsNew({ teamId } = {}) {
    return new FieldSchemaEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
      teamId: teamId || this.#teamId
    })
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties()
    }
  }


  //--- static methods ---//
  static async upsert(fieldSchemaEntity) {
    return upsertFieldSchema(fieldSchemaEntity.toObject())
  }

  static async fetch(id) {
    const fieldSchema = await fetchFieldSchema(id)
    return fieldSchema ? new FieldSchemaEntity(fieldSchema) : undefined
  }
}

