/**
 * Is utils
 */

// Constants
const TYPE_STRING   = 'string'
const TYPE_OBJECT   = 'object'
const TYPE_BOOLEAN  = 'boolean'
const CHAR_FORMULA  = '='

import { CHECKBOX_FALSE_VALUES, CHECKBOX_TRUE_VALUES } from '../constants/checkbox.mjs' 
import { CHAR_STOP, CHAR_AT, CHAR_EMPTY } from '../constants/characters.mjs' 
import { 
  REGEX_DATE_MM_DD_YYYY, REGEX_DATE_DD_MM_YYYY, REGEX_DATE_ISO_FORMAT, 
  REGEX_DATE_TIMESTAMP_SECOND, REGEX_DATE_TIMESTAMP_MILLISECOND 
} from '../constants/regex.mjs'

/**
 * Is an object
 */
function isObject(input) {
  return typeof(input)
    === TYPE_OBJECT
}

/**
 * Is an array
 */
function isArray(input) {
  return Array.isArray(input)
}

/**
 * Is a boolean
 */
function isBoolean(input) {
  return typeof(input)
    === TYPE_BOOLEAN
}

/**
 * Is a string
 */
 function isString(input) {
  return typeof(input)
    === TYPE_STRING
}

/**
 * Is a number
 */
 function isNumber(input) {
  const cleanValue = String(input)
  ?.split(',').join(CHAR_EMPTY)
  .trim()
  return !isNaN(cleanValue)
}

/**
 * Is a formula
 */
function isFormula(input) {
  if (isEmpty(input)) {
    return false
  }
  return String(input)
    ?.trim()[0] === CHAR_FORMULA
}

/**
 * Is a currency
 */
function isCurrency(input = CHAR_EMPTY) {
  if (isNumber(input)) {
    return true
  }
  const cleanValue = String(input)
    ?.split(',').join(CHAR_EMPTY)
    .split('$').join(CHAR_EMPTY)
    .trim()
  return isNumber(cleanValue)
}

/**
 * Is a percentage
 */
 function isPercentage(input = CHAR_EMPTY) {
  if (isNumber(input)) {
    return true
  }
  const cleanValue = String(input)
    ?.split(',').join(CHAR_EMPTY)
    .split('%').join(CHAR_EMPTY)
    .trim()
  return isNumber(cleanValue)
}

/**
 * Is equal
 */
function isEqual(a, b) {
  if (!isObject(a)) {
    return a === b
  }
  // TOOD: do this equality comparison properly, this is dirty
  return JSON.stringify(a) === JSON.stringify(b)
}

/**
 * Is empty string
 */
function isEmptyString(value) {
  return value === CHAR_EMPTY
}

/**
 * Is null
 */
function isNull(value) {
  return value === null
}

/**
 * Is undefined
 */
function isUndefined(value) {
  return value === undefined
}

/**
 * Is true
 */
function isTrue(value) {
  return value === true
}

/**
 * Is false
 */
function isFalse(value) {
  return value === false
}

/**
 * Is empty
 * undefined / null / no value / empty array
 */
function isEmpty(value) {
  return isArray(value)
    ? value?.length === 0
    : isEmptyNullOrUndefined(value)
}

/**
 * Is an error
 */
function isError(err) {
  return err instanceof Error
}

/**
 * Is HTML
 * Performant check to see if a string is actually html
 */
function isHtml(value = CHAR_EMPTY) {
  if (!isString(value)) {
    return false
  }
  const hasHtmlTags
    =  value.includes('<')
    && value.includes('>')
  const hasHtmlEntities
    =  value.includes('&')
    && value.includes(';')
  // It's html if it has either tags or entities
  return hasHtmlTags || hasHtmlEntities
}

/**
 * Is empty, null or undefined
 */
function isEmptyNullOrUndefined(value) {
  return isEmptyString(value) || isNull(value) || isUndefined(value)
}

/**
 * Is a file
 */
function isFile(value) {
  return value instanceof File
}

/**
 * Is checkbox
 * check to see if a string is a checkbox value
 */
function isCheckbox(value) {
  if (isEmptyNullOrUndefined(value)) return true
  if (isBoolean(value)) return true
  if (isObject(value)) return false
  const cleanValue = String(value).trim().toUpperCase()
  return [ ...CHECKBOX_TRUE_VALUES, ...CHECKBOX_FALSE_VALUES ].includes(cleanValue)
}

/**
 * Is taglist
 * check to see if a input value is a taglist value
 */
function isTaglist(value) {
  return (
    isEmpty(value) 
    || isArray(value) 
    || isString(value) 
    || isNumber(value)
  )
}

/**
 * Is email
 */
function isEmail(value) {
  return String(value).includes(CHAR_AT)
    && String(value).includes(CHAR_STOP)
}

/**
 * Is promise
 */
function isPromise(value) {
  return value instanceof Promise
}

/**
 * Is date
 */
function isDate(input) {
  return REGEX_DATE_ISO_FORMAT.test(input) 
  || REGEX_DATE_MM_DD_YYYY.test(input)
  || REGEX_DATE_DD_MM_YYYY.test(input)
  || REGEX_DATE_TIMESTAMP_SECOND.test(input)
  || REGEX_DATE_TIMESTAMP_MILLISECOND.test(input)
}

// Exports
export {
  isString, isObject, isArray, isNumber, isBoolean, isCurrency, isPercentage, isEqual, isHtml,
  isFormula, isEmptyString, isError, isFile, isCheckbox, isTaglist,
  isEmpty, isNull, isUndefined, isTrue, isFalse, isEmptyNullOrUndefined, 
  isEmail, isPromise, isDate
}
