<template>
  <main-layout>
    <iframe style="border: none; width: 100%; height: 100vh;" width="100%" height="100%"
      :src="isAdminOrEditorUser(currentUser)
        ? 'https://productengine.app/getting-started-in-app'
        : 'https://productengine.app/getting-started-in-app-viewer'"
    ></iframe>
  </main-layout>
</template>

<script setup>

// Utils
import { currentUser } from '@/actions/current-user.mjs';
import { isAdminOrEditorUser } from '../permissions/utils/is-admin-or-editor-user.mjs'

// Layouts
import MainLayout from '../layouts/MainLayout.vue'

</script>
