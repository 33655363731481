// Dependencies
import { throwError } from './errors.mjs'

/**
 * Throw if no parameter
 */
function required(paramName = 'parameter') {
  return throwError(`${paramName} is required.`)
}

// Exports
export { required }
