/*!
 * Super admin actions
 */

// Dependencies
import { ref, triggerRef } from 'vue'

// Utils
import {
  required,
  getAll, removeBatch, updateBatch
} from '../utils/'

// Actions
import { setTeamMember, removeTeamMember, getTeamById, updateTeam, 
  getTeamsByUser, getCurrentTeamByUser, createTeamLibrary_cloudfn, createTeam_cloudfn, createTeam_demo_cloudfn,
} from '../team/actions/team-actions.mjs'
import { setUserTeam, removeUserTeam, addUserTeam } from '../user/actions/user-actions.mjs'
import { createSignupInvite, getExistingSignupInvites } from '../email-invites/actions/invites.mjs'
import { TeamEntity } from '../entity/TeamEntity.mjs'

// Constants
import {
  COLLECTION_USERS,
  COLLECTION_TEAMS,
  COLLECTION_ITEMS,
  COLLECTION_FIELD_SCHEMAS,
  COLLECTION_LISTS,
  COLLECTION_COLUMN_SETS,
  COLLECTION_FILTER_GROUPS,
  COLLECTION_INVITES
} from '../db/db-collections.mjs'

// notify
import { raiseNotification } from '../notify/raise-notification.mjs'
import { raiseConfirmAlert } from '../notify/raise-confirm-alert.mjs'

// Local constants
const TEAMS_MANAGEMENT_SECTION = 'Teams Management'
const USERS_MANAGEMENT_SECTION = 'Users Management'
const LIBRARY_MANAGEMENT_SECTION = 'Library Management'
const INVITES_MANAGEMENT_SECTION = 'Invites Management'
const DEVELOPER_SECTION = 'Developer Area'
const DEMO_SECTION = 'Demo Management'

// Refs
const showSection = ref(TEAMS_MANAGEMENT_SECTION)
const teams = ref([])
const users = ref([])

// Team Management refs
const selectedUserTm = ref(null)
const selectedTeamTm = ref(null)
const selectedUserTeamsTm = ref(null)
const selectedUserTeamTm = ref(null)
const selectedTeamMembersTm = ref(null)
const demoTeamName = ref(null)

// Library Management Refs
const selectedTeamLm = ref(null)
const selectedEnvironment = ref('None')
const selectedFieldLm = ref(null)

const teamFields = ref([])
const newTeamName = ref(null)
const newUserEmail = ref(null)

/**
 * init users and teams ref
 */
async function loadAdminRefs() {

  users.value = (await getAll(COLLECTION_USERS))
    .filter(user => user.email)
    .sort((a, b) => {
      return a.email.localeCompare(b.email)
    })

  teams.value = (await getAll(COLLECTION_TEAMS))
    .filter(team => team.id)
    .sort((a, b) => {
      return a.id.localeCompare(b.id)
    })
}


/**
 * Set selected user team
 */
async function setSelectedUserTeam() {
  if (!selectedUserTm.value) {
    selectedUserTeamsTm.value = null
    selectedUserTeamTm.value = null
    triggerRef(selectedUserTeamTm)
    return triggerRef(selectedUserTeamsTm)
  }
  selectedUserTeamsTm.value = await getTeamsByUser(selectedUserTm.value)
                             .then(teamEntities => teamEntities.map(teamEntity => teamEntity.toObject()))
  if (!selectedUserTm.value.teamId) return null 
  selectedUserTeamTm.value = await getCurrentTeamByUser(selectedUserTm.value)
                        .then(teamEntity => teamEntity.toObject())                             
}

/**
 * Set selected team
 */
async function setSelectedTeamMembers(team) {
  if (!team) {
    selectedTeamMembersTm.value = null
    return triggerRef(selectedTeamMembersTm)
  }
  selectedTeamMembersTm.value = team.members                       
}

function addUserToTeam({
  userId = required('userId'),
  teamId = required('teamId'),
  email,
  displayName,
  role,
  acceptedAt=Date.now()
}) {
  const newMember = { 
    userId: userId,
    teamId: teamId,
    email: email,
    displayName: displayName,
    role: role,
    acceptedAt: acceptedAt
  }
  const team = teams.value.find(team => team.id === teamId)
  team.members.push(newMember)
  setTeamMember(newMember)
  const user = users.value.find(user => user.id === userId)
  user.teams.push(teamId)
  addUserTeam(userId, teamId)
}

/**
 * Set team environment
 */
function setTeamEnvironment() {
  console.log('setTeamEnvironment called')
  const teamId = selectedTeamLm.value.id
  if (teamId) {
    const team = teams.value.find(team => team.id === teamId)
    selectedEnvironment.value = team?.environment || 'None'
  } else {
    selectedEnvironment.value = 'None'
  }
}

/**
 * change user's team member role
 * @param {Object} member 
 * @param {string} teamId 
 */
function changeMemberRole(member, teamId) {
  member.teamId = teamId
  setTeamMember(member)
}
 
/**
 * Change user team
 */
async function changeUserTeam(user, team) {
  if (!user || !team ) return null
  setUserTeam(user.id, team.id)
  const idx = users.value.findIndex(u => u.id === user.id)
  const { role } = team.members
                      .find(member => member.userId === user.id)
  users.value[idx].teamId = team.id
  users.value[idx].role = role
}

/** remove user from team
 * 
 */
function removeUserMembership(userId, teamId) {
  removeUserTeam(userId, teamId)
  removeTeamMember(userId, teamId)

  //remove member from team in SuperAdmin page
  const teamIdx = teams.value.findIndex(team => team.id === teamId)
  const teamMemberIdx = teams.value[teamIdx].members.findIndex(m => m.userId === userId)
  teams.value[teamIdx].members.splice(teamMemberIdx, 1)
  triggerRef(teams)

  //remove team from user in SuperAdmin page
  const userIdx = users.value.findIndex(user => user.id === userId)
  const userTeamIdx = users.value[userIdx].teams.findIndex(team => team === teamId)
  users.value[userIdx].teams.splice(userTeamIdx, 1)
  if (teamId === users.value[userIdx].teamId) {
    users.value[userIdx].teamId = null
    users.value[userIdx].role = null
  }
  triggerRef(users)
  if (selectedUserTm.value.id === userId) {
    selectedUserTm.value = null
    selectedUserTeamsTm.value = null
    selectedUserTeamTm.value = null
  }
}


/**
 * Create a team
 */
/*
function addTeam() {
  if (!newTeamName.value) {
    return
  }
  const name = newTeamName.value
  newTeamName.value = null
  return createTeam({ name })
}
*/

/**
 * Remove all items - only allow for sandbox
 */
function removeAllTeamItems() {
  if (!selectedTeamLm.value.id) return
  const team = teams.value.find(team => team.id === selectedTeamLm.value.id)
  if (team.environment !== 'Sandbox') {
    console.log('Environment is not a Sandbox. No items removed')
    throw new Error('Not A Sandbox!')
  }
  console.log('Environment is a Sandbox. Remove all items for', selectedTeamLm.value.id)

  getAll(COLLECTION_ITEMS, selectedTeamLm.value.id)
    .then(items => {
      const itemIds = items.map(i => i.id)
      removeBatch(COLLECTION_ITEMS, itemIds)
    })

}

/**
 *  Set team feids
 */
async function setTeamFields() {
  console.log('setTeamFields called.')
  const teamId = selectedTeamLm.value.id
  if (!teamId) {
    teamFields.value = []
    selectedFieldLm.value = null
    return
  }
  teamFields.value = (await getAll(COLLECTION_FIELD_SCHEMAS, teamId))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    .map(f => {
      if (f.isDeleted) f.label = f.label + ' (Deleted)'
      return f
    })
}

/**
 * Remove field and data
 */
async function removeFieldAndData() {
  const teamId = selectedTeamLm.value.id
  const removeField = selectedFieldLm.value
  if (!teamId) {
    teamFields.value = []
    selectedFieldLm.value = null
    return
  }
  // remove field data from team items
  const items = await getAll(COLLECTION_ITEMS, teamId)
  const field = teamFields.value.find(f => f.id === selectedFieldLm.value)
  if (!field || !field.key) return
  items.forEach(item => {
    delete item.fields[field.key]
  })
  await updateBatch(COLLECTION_ITEMS, items)
  // remove the field
  await removeBatch(COLLECTION_FIELD_SCHEMAS, [field.id])
  teamFields.value = teamFields.value.filter(f => f.id !== field.id)
}

/**
 * Remove all items, field schemas, column-sets, invites, lists by team
 */
async function removeAllDataByTeam(team) {
  const teamId = team.id
  const items = await getAll(COLLECTION_ITEMS, teamId)
  const itemIds = items.map(i => i.id)
  const fieldIds = teamFields.value.map(f => f.id)
  teamFields.value = []
  const listIds = (await getAll(COLLECTION_LISTS, teamId)).map(f => f.id)
  const columnSetIds = (await getAll(COLLECTION_COLUMN_SETS, teamId)).map(f => f.id)
  const filterGroupIds = (await getAll(COLLECTION_FILTER_GROUPS)).filter(filterGroup => listIds.includes(filterGroup.listId)).map(f => f.id)
  const invitesIds = (await getAll(COLLECTION_INVITES)).filter(invite => listIds.includes(invite.listId)).map(f => f.id)
  team.libraryListId = null
  team.ownerId = team.createdBy
  
  return Promise.all([
    removeBatch(COLLECTION_ITEMS, itemIds),
    removeBatch(COLLECTION_FIELD_SCHEMAS, fieldIds),
    removeBatch(COLLECTION_COLUMN_SETS, columnSetIds),
    removeBatch(COLLECTION_LISTS, listIds),
    removeBatch(COLLECTION_FILTER_GROUPS, filterGroupIds),
    removeBatch(COLLECTION_INVITES, invitesIds),
    updateTeam(new TeamEntity(team))
    //TODO: JL -still need to remove files from Firebase storage
  ]) 
}

/**
 * create a team
 */

async function createDemoTeam() {
  if (!demoTeamName.value) {
    raiseConfirmAlert('Team Name Required')
    return
  }
  const result = await createTeam_demo_cloudfn(demoTeamName.value)
  console.log('result ===>', result)
  if (!result.data?.success) {
    return raiseConfirmAlert('Create Failed')
  }
  raiseNotification('Team Created')

}

/**
 * create a team library
 */
async function createTeamLibrary(selectedTeam) {
  if (selectedTeam?.libraryListId) return 
  const result = await createTeamLibrary_cloudfn(selectedTeam)
  selectedTeam.libraryListId = result.data.result?.id
  const team = teams.value.find(team => team.id === selectedTeam.id)
  team.libraryListId = result.data.result?.id
  triggerRef(teams)
  triggerRef(selectedTeam)
}

/**
 * Recreate default fields
 */
function recreateDefaultFieldSchemas() {
  const teamId = selectedTeamLm.value.id
  //createDefaultFieldSchemas(teamId)
}


/**
 * remove list ids that no longer exist from items
 */
export async function cleanupItemLists() {
  const lists = await getAll(COLLECTION_LISTS)
  const items = await getAll(COLLECTION_ITEMS)
  console.log('total items => ', items.length)
  const listIds = lists.map(l => l.id)
  const updateItems = []
  for (const item of items) {
    let hasChanged = false
    let newItemLists = []
    for (const itemListId of item.lists) {
      if (!listIds.includes(itemListId)) {
        hasChanged = true
      } else {
        newItemLists.push(itemListId)
      }
    }
    if (hasChanged) {
      item.lists = newItemLists
      updateItems.push(item)
    }
  }
  console.log('update items', updateItems.length)
  await updateBatch(COLLECTION_ITEMS, updateItems)
}

// Module exports
export {
  showSection,
  TEAMS_MANAGEMENT_SECTION, USERS_MANAGEMENT_SECTION, LIBRARY_MANAGEMENT_SECTION,
  INVITES_MANAGEMENT_SECTION, DEVELOPER_SECTION, DEMO_SECTION,
  users, teams, teamFields,
  loadAdminRefs,
  selectedUserTm, selectedUserTeamTm, selectedUserTeamsTm, selectedTeamLm, selectedEnvironment, selectedFieldLm, newTeamName, newUserEmail,
  selectedTeamTm, selectedTeamMembersTm, setSelectedTeamMembers, addUserToTeam, changeMemberRole, demoTeamName,
  setSelectedUserTeam,
  changeUserTeam,
  removeUserMembership, 
  createDemoTeam,
  setTeamEnvironment,
  setTeamFields,
  removeAllTeamItems,
  removeFieldAndData,
  removeAllDataByTeam,
  recreateDefaultFieldSchemas,
  createTeamLibrary,
}
