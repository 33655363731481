/*!
 * File util functions
 */

import { required } from './required.mjs'

/**
 * Download a file with text contents in the browser
 */
function downloadStringAsFile({
  fileName = required('name of download file'),
  contents = required('string contents of file for download'),
  mimeType = 'text/plain', // The type of file
  charset  = 'utf-8'
}) {
  const anchor = document.createElement('a')
  anchor.setAttribute('href', `data:${mimeType};${charset},${contents}`)
  anchor.setAttribute('download', fileName)
  anchor.style.display = 'none'
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}

function convertStringToFile({
  fileName = required('name of download file'),
  contents = required('string contents of file for download'),
  mimeType = 'text/csv', // The type of file
}) {
  const file = new File([contents], fileName, {
    type: mimeType,
  })
  return file
}

function downloadFile(file= required('file'), name) {
  const url = window.URL.createObjectURL(file)
  const anchor = document.createElement('a')
  anchor.download = name
  anchor.href = url
  anchor.click()
  anchor.remove()
  window.URL.revokeObjectURL(url)
}

// Exports
export { downloadStringAsFile, convertStringToFile, downloadFile }
