
import { required } from '@/utils'
import { getCurrentUser } from '../actions/current-user.mjs'
import { getCurrentTeam } from '../actions/current-team.mjs'



/***NEW***/
import { throwError } from '@/utils/errors.mjs'
import { PriceListColumnManager } from './grid-managers/PriceListColumnManager.mjs'
import { GridItemManager } from '../grid-manager/entities/GridItemManager.mjs'
import { importColumnManager } from './grid-managers/importColumnManager.mjs'


// events
import {
  EVENT_USER_SIGNOUT,
} from '../events/constants/event-types.mjs'

// list
import { setListUiActionPermissionStates } from '../list-core/permissions/list-ui-action-permissions.mjs'
import {
  startTeamPriceListColumnEvents,
  stopTeamPriceListColumnEvents,
  startTeamPriceListItemEvents,
  stopTeamPriceListItemEvents
} from './events/team-price-list-event-handlers.mjs'


import {
  startImportColumnEvents,
  stopImportColumnEvents
} from './events/import-price-list-event-handlers.mjs'

import {
  startRightHandMenuEvents,
  stopRightHandMenuEvents
} from '../list-core/right-hand-menu-actions/right-hand-menu-event-actions.mjs'

// 
import { getListAndColumns } from '../list-core/db/get-list-and-columns.mjs'

import { getImportColumnSet } from '../list-core/import/list-import-actions.mjs'


// local db 
import { startLocalDb } from '../db-local/local-db.mjs'
import { isImportMode } from '../ui/ui-states.mjs'
import { loadDeltaStaticListItems, getLocalItems } from '../item/data/local-db-items.mjs'

// actions
import { setCsvGridItems } from '../list-core/actions/csv-item-actions.mjs'
import { isTeamPriceList, isExternalPriceList, isTeamCsvPriceList } from '../list-core/utils/is-list-type.mjs'

// logs
import { setLogFilesUi, clearLogFilesUi } from '../list-core/log-files/log-files-ui.mjs'

let gridItemsManager = null
let gridColumnManager = null
// grid


const startImportGrid = async (
  list,
  team,
  grid,
  columnSet,
  fieldSchemas
) => {

  const importColumnSet = await getImportColumnSet(list.id, team.id)

  gridColumnManager = new importColumnManager({
    grid,
    list,
    columnSet: columnSet,
    fieldSchemaSet: { fieldSchemas },
    importColumnSet,
    isImportMode: true
  })
  gridColumnManager.start()


  gridItemsManager = new GridItemManager({
    grid,
    list,
  })
  gridItemsManager.start()

}

const startPriceListGrid = async (
  list,
  grid,
  columnSet,
  fieldSchemas
) => {
  gridColumnManager = new PriceListColumnManager({
    grid,
    list,
    columnSet: columnSet,
    fieldSchemaSet: { fieldSchemas },
  })
  gridColumnManager.start()


  gridItemsManager = new GridItemManager({
    grid,
    list,
  })
  gridItemsManager.start()

}


export const onPriceListGridReady = async (grid, listId) => {
  gridItemsManager = null
  gridColumnManager = null
  await startLocalDb()
  addEventListener(EVENT_USER_SIGNOUT, onPriceListGridStop)

  const currentUser = getCurrentUser()
  const currentTeam = getCurrentTeam()
  const startTime = Date.now()

  const { list, columnSet, fieldSchemas, ...params } = await getListAndColumns(listId) || {}
  if (!list) {
    throwError('Cannot not load Pricelist:', listId)
  }
  setListUiActionPermissionStates(list)

  // team price list
  if (isTeamPriceList(list)) {
    isImportMode.value = false
    await startPriceListGrid(
      list,
      grid,
      columnSet,
      fieldSchemas)

    // load items to grid
    await loadDeltaStaticListItems(list.id)
    await getLocalItems({
      listId: list.id,
      batchCallBack: (batch) => {
        gridItemsManager.upsertItemsGridOnly(batch, gridColumnManager.getFieldSchemas())
      }
    })
    startTeamPriceListColumnEvents(gridColumnManager, gridItemsManager)
    startTeamPriceListItemEvents(gridColumnManager, gridItemsManager)

    // show batch errors
    gridItemsManager.consoleLogBatchErrors()
    clearLogFilesUi()
    setLogFilesUi(list)
  }

  // CSV list
  if (isTeamCsvPriceList(list)) {
    isImportMode.value = true
    await startImportGrid(
      list,
      currentTeam,
      grid,
      columnSet,
      fieldSchemas)
    await setCsvGridItems(list, gridColumnManager, gridItemsManager)
    startImportColumnEvents(gridColumnManager, gridItemsManager)
    startTeamPriceListItemEvents(gridColumnManager, gridItemsManager)

    // show batch errors
    gridItemsManager.consoleLogBatchErrors()
    clearLogFilesUi()
    setLogFilesUi(list)
  }


  // External price list
  if (isExternalPriceList(list) && currentTeam?.id) {
    isImportMode.value = true
    await startImportGrid(
      list,
      currentTeam,
      grid,
      columnSet,
      fieldSchemas)
    await loadDeltaStaticListItems(list.id)
    await getLocalItems({
      listId: list.id,
      batchCallBack: (batch) => {
        gridItemsManager.upsertItemsGridOnly(batch, gridColumnManager.getFieldSchemas())
      }
    })
    startImportColumnEvents(gridColumnManager, gridItemsManager)
    startTeamPriceListItemEvents(gridColumnManager, gridItemsManager)

    // show batch errors
    gridItemsManager.consoleLogBatchErrors()
    clearLogFilesUi()
    setLogFilesUi(list)
  }


  // External price list and viewer
  if (isExternalPriceList(list) && !currentTeam?.id) {
    console.log('load externa price list')
    isImportMode.value = false
    await startPriceListGrid(
      list,
      grid,
      columnSet,
      fieldSchemas)
    await loadDeltaStaticListItems(list.id)
    await getLocalItems({
      listId: list.id,
      batchCallBack: (batch) => {
        gridItemsManager.upsertItemsGridOnly(batch, gridColumnManager.getFieldSchemas())
      }
    })
    startImportColumnEvents(gridColumnManager, gridItemsManager)
    startTeamPriceListItemEvents(gridColumnManager, gridItemsManager)

      // show batch errors
      gridItemsManager.consoleLogBatchErrors()
      clearLogFilesUi()
      setLogFilesUi(list)
  }

  startRightHandMenuEvents(gridColumnManager, gridItemsManager)
  console.log('Price List load Time:', Date.now() - startTime, 'ms')
}


export const onPriceListGridStop = () => {
  clearLogFilesUi()
  isImportMode.value = false
  stopTeamPriceListItemEvents()
  stopRightHandMenuEvents()
  stopImportColumnEvents()
  stopTeamPriceListColumnEvents()
  gridItemsManager?.terminate()
  gridColumnManager?.terminate()
  gridItemsManager = null
  gridColumnManager = null
  return
}


