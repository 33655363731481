// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { getTeamSession } from '../../session/team-session.mjs'

// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// events
import {
  EVENT_COLUMN_AUTOSIZE,
  EVENT_COLUMN_AUTOSIZE_LOCAL,
  EVENT_COLUMN_AUTOSIZE_REMOTE,
  EVENT_COLUMN_RESIZE,
  EVENT_COLUMN_RESIZE_LOCAL,
  EVENT_COLUMN_RESIZE_REMOTE
}
  from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

//#region //--- Autosize ---//
export const addEventsColumnAutosize = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_AUTOSIZE_LOCAL,
    (event) => autosizeColumnEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_AUTOSIZE,
      autosizeColumnRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_AUTOSIZE_REMOTE,
    (event) => autosizeColumnRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}

export const autosizeColumnEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const colWidthArr = event.detail
  const team = getTeamSession()

  // send external event
  const skipHeader = true
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_AUTOSIZE,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      colWidthArr,
      skipHeader,
    }
  })
  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.autosize(colWidthArr, skipHeader, { gridOnly: false })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.autosize(colWidthArr, skipHeader, { gridOnly: true })
  }
}

/**
 * Autosize columns external event handler
 * @param {*} event 
 * @returns 
 */
export const autosizeColumnRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_AUTOSIZE_REMOTE, payload)
}

/**
 * Autosize column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const autosizeColumnRemoteEvent = async (event, columnManager) => {
  const { listId, colWidthArr, skipHeader } = event.detail
  if (columnManager.list.id !== listId) {
    return
  }

  await columnManager.autosize(colWidthArr, skipHeader, { gridOnly: true })
}

//#endregion

//#region //--- Resize ---//

export const addEventsColumnResize = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_RESIZE_LOCAL,
    (event) => resizeColumnEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_RESIZE,
      resizeColumnRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_RESIZE_REMOTE,
    (event) => resizeColumnRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}
export const resizeColumnEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const colWidthArr = event.detail
  const team = getTeamSession()

  // send external event
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_RESIZE,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      colWidthArr: event.detail
    }
  })

  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.resize(colWidthArr, { gridOnly: false })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.resize(colWidthArr, { gridOnly: true })
  }
}

/**
 * Resize columns external event handler
 * @param {*} event 
 * @returns 
 */
export const resizeColumnRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_RESIZE_REMOTE, payload)
}

/**
 * Resize column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const resizeColumnRemoteEvent = async (event, columnManager) => {
  const { listId, colWidthArr } = event.detail
  if (columnManager.list.id !== listId) {
    return
  }

  await columnManager.resize(colWidthArr, { gridOnly: true })
}

//#endregion