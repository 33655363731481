/*!
 * Team db functions
 */

import { required } from '@/utils/required.mjs'
import { throwError } from '@/utils/errors.mjs'

// entities
import { UserEntity } from '../../entity'

// database adapters
import { fetchUserEntity, saveUserEntity, fetchUserByEmail, fetchUsersByTeamId, deleteUserEntity } from '../db/user-db.mjs'

// Actions
import { currentUser } from '../../actions/current-user.mjs'
import { getTeamById } from '@/team/actions/team-actions.mjs'

//#region user actions
/**
 * Create a user 
 * @param {Object} userDetails - user details
 * @param {string} userDetails.email - user email
 * @param {string} [userDetails.displayName] - user display name
 * @returns {Object} UserEntitiy
 */
function createUser({
  email = required('email'),
  displayName = email
}) {
  const newUser = new UserEntity({ email, displayName })
  saveUserEntity(newUser)
  return newUser
}

/**
 * Update user by userEntity
 * @param {Object} userEntity 
 * @returns {Object} UserEntity
 */
function updateUser(userEntity) {
  saveUserEntity(userEntity)
  return userEntity
}
//#endregion

/**
 * Get user email address
 * @param {string} id - user id 
 * @returns {string} emaill address
 */
async function getUserEmail(
  id = required('id')
) {
  const userEntity = await getUserById(id)
  return userEntity.email
}

/**
 * Get user by id
 * @param {string} id - user id 
 * @returns {Promise} UserEntity
 */
async function getUserById(
  id = required('id')
) {
  const userEntity = await fetchUserEntity(id)
  return userEntity
}

/**
 * Delete user by userId
 * @param {string} id - user id
 * @returns {Object} UserEntity
 */
async function deleteUser(id) {
  // Don't allow a user to delete themselves
  if (id === currentUser?.id) {
    return throwError(`You can't delete yourself`)
  }
  const userEntity = await getUserById(id)
  return deleteUserEntity(userEntity)
}

/**
 * Switch user's team and role. Add team membership to user if it is 1st team. 
 * @param {string} userId - user id - required
 * @param {string} teamId - team id - required
 * @param {string} [role] - user role - optional
 * @returns {Promise} UserEntity
 */
async function setUserTeam(
  userId = required('userId'),
  teamId = required('teamId'),
  role
) {
  role = role || await getTeamById(teamId)
    .then(team => {
      const member = team.members.find(member => member.userId === userId)
      return member.role
    })
  return await getUserById(userId).then(user => {
    user.switchTeam(teamId, role)
    return updateUser(user)
  })
}

/**
 * Add team membership to user record 
 * @param {string} userId - user id - required
 * @param {string} teamId - team id - required
 * @returns {Promise} UserEntity
 */
async function addUserTeam(
  userId = required('userId'),
  teamId = required('teamId'),
) {
  return await getUserById(userId).then(user => {
    user.addTeam(teamId)
    return updateUser(user)
  })
}

/**
 * Remove team membership from user record. Remove team and role if they are current.
 * @param {string} userId - user id - required
 * @param {string} teamId - team id - required
 * @returns {Promise} UserEntity
 */
async function removeUserTeam(
  userId = required('userId'),
  teamId = required('teamId'),
) {
  return await getUserById(userId).then(user => {
    user.removeTeam(teamId)
    return updateUser(user)
  })
}

/**
 * Get team membership from user record. 
 * @param {string} userId - user id - required
 * @returns {string[]} teamIds
 */
async function getUserTeams(
  userId = required('userId')
) {
  return await getUserById(userId).then(user => {
    user.teams
  })
}

export {
  createUser, updateUser, deleteUser, setUserTeam, removeUserTeam, addUserTeam, getUserTeams,
  getUserEmail, fetchUserByEmail as getUserByEmail, getUserById, fetchUsersByTeamId as getUsersByTeamId, 
}
