export const MAP_TYPE_VALUE = 'value'
export const MAP_TYPE_KEY = 'key'
export const MAP_TYPE_LOOKUP = 'lookup'
export const MAP_TYPE_PARENT_RESULT = 'parentResult'
export const MAP_TYPE_COLUMN_NAME = 'columnName'

//PL : to be phased out??
export const MAP_TYPE_REFERENCE = 'reference'


export const FIELD_MAP_TYPE_OPTIONS = [
  {
    value: MAP_TYPE_KEY,
    label: 'Key'
  },
  {
    value: MAP_TYPE_VALUE,
    label: 'Value'
  },  
  {
    value: MAP_TYPE_LOOKUP,
    label: 'Lookup'
  },
  {
    value: MAP_TYPE_PARENT_RESULT,
    label: 'Parent Result'
  },
  {
    value: MAP_TYPE_COLUMN_NAME,
    label: 'Column Name'
  }
]
