
// utils
import { required } from '../../utils/required.mjs'

// entities
import { createDbItem } from './create-db-item.mjs'

/**
 * Clone as static
 * @param {*} columnSetEntity 
 * @param {*} selectedColumnIds 
 */
export const createStaticItem = (
  itemEntity = required('itemEntity'),
  listId = required('listId'),
  staticColumnSet = required('staticColumnSet'),
) => {
  const newItem = itemEntity.cloneAsNew({ isStatic: true })
  newItem.listId = listId
  newItem.lists = [listId]
  newItem.deletedFields = undefined
  newItem.setCreated().setUpdated()
  // strip field attributes
  for (const key in newItem.fields) {
    newItem.fields[key] = {
      inputValue : newItem.fields[key].value
    }
  }

  // remove fields that not in the list
  const newFields = {}
  for (const col of staticColumnSet.columns) {
    if (col.isDeleted || col.fieldSchema.isDeleted || !col.fieldSchema.key ) {
      continue
    }
    newFields[col.fieldSchema.key] = newItem.fields[col.fieldSchema.key]
  }
  newItem.fields = newFields
  return newItem
}