// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// events
import {
  EVENT_COLUMN_HIDE,
  EVENT_COLUMN_HIDE_LOCAL,
  EVENT_COLUMN_HIDE_REMOTE,
  EVENT_COLUMN_UNHIDE,
  EVENT_COLUMN_UNHIDE_LOCAL,
  EVENT_COLUMN_UNHIDE_REMOTE,
  EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'


// session event
import { getSessionEvent } from '../../events/session-events.mjs'

export const addEventsColumnHide = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM,
} = {}) => {
  addEventListener(
    EVENT_COLUMN_HIDE_LOCAL,
    (event) => hideColumnsEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_HIDE,
      hideColumnsRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_HIDE_REMOTE,
    (event) => hideColumnsRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}

export const addEventsColumnUnhide = (controller, subscriptions, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_UNHIDE_LOCAL,
    (event) => unhideColumnsEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_UNHIDE,
      unhideColumnsRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_UNHIDE_REMOTE,
    (event) => unhideColumnsRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}

export const hideColumnsEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { columnIds } = event.detail
  const team = getTeamSession()

  // send external event
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_HIDE,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnIds
    }
  })
  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.hide(columnIds, { event: externalEvent })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.hide(columnIds, { gridOnly: true })
  }
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })

}

export const unhideColumnsEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { columnIds } = event.detail
  const team = getTeamSession()

  // send external event
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_UNHIDE,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnIds
    }
  })
  if ([ADMIN_USER_ROLE, EDITOR_USER_ROLE].includes(columnManager.userRole)) {
    await columnManager.unhide(columnIds, { event: externalEvent })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.unhide(columnIds, { gridOnly: true })
  }
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })

}

/**
 * Hide column external event handler
 * @param {*} event 
 * @returns 
 */
export const hideColumnsRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_HIDE_REMOTE, payload)
}



/**
 * Unhide column external event handler
 * @param {*} event 
 * @returns 
 */
export const unhideColumnsRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_UNHIDE_REMOTE, payload)
}

/**
 * Hide column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const hideColumnsRemoteEvent = async (event, columnManager) => {
  const { listId, columnIds } = event.detail
  if (listId !== columnManager.list?.id) {
    IS_DEBUG_EVENT_MODE && console.log('Not for this list. skip.')
    return
  }
  await columnManager.hide(columnIds, { gridOnly: true })
  columnManager.refreshFilteredColumns()
}

/**
 * Unhide column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const unhideColumnsRemoteEvent = async (event, columnManager) => {
  const { listId, columnIds } = event.detail
  if (listId !== columnManager.list?.id) {
    IS_DEBUG_EVENT_MODE && console.log('Not for this list. skip.')
    return
  }
  await columnManager.unhide(columnIds, { gridOnly: true })
  columnManager.refreshFilteredColumns()
}
