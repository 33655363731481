/*!
 * Number constants
 */

// Numbers
export const ONE            = 1
export const THREE          = 3
export const FIVE           = 5
export const TEN            = 10
export const TWENTY_FOUR    = 24
export const THIRTY         = 30
export const FIFTY          = 50
export const SIXTY          = 60
export const ONE_HUNDRED    = 100
export const TWO_HUNDRED    = 200
export const THREE_HUNDRED  = 300
export const ONE_THOUSAND   = 1000
export const INFINITY       = Infinity
