/*!
 * Field types and field defaults
 */

// Dependencies
import { CHAR_EMPTY }   from '@/constants/characters.mjs'

// Field types
export const FIELD_TYPE_TEXT       = 'Text'
export const FIELD_TYPE_CHECKBOX   = 'Checkbox'
export const FIELD_TYPE_DATE       = 'Date'
export const FIELD_TYPE_NUMBER     = 'Number'
export const FIELD_TYPE_TAGLIST    = 'TagList'
export const FIELD_TYPE_CURRENCY   = 'Currency'
export const FIELD_TYPE_PERCENTAGE = 'Percentage'

export const EMPTY_VALUE_TEXT       = 'Empty'
export const FIELD_TYPE_TEXT_PLAIN  = 'Plain'

export const DEFAULT_TRUE_VALUE         = 'Yes'
export const DEFAULT_FALSE_VALUE        = 'No'
export const DEFAULT_FIELD_TYPE         = FIELD_TYPE_TEXT
export const DEFAULT_VALUE              = CHAR_EMPTY
export const DEFAULT_TEXT_TYPE          = FIELD_TYPE_TEXT_PLAIN
export const DEFAULT_NUMBER_DP          = 2
export const DEFAULT_PERCENTAGE_DP      = 2
export const DEFAULT_IS_TEXT_MULTI_LINE = false
export const DEFAULT_LABEL              = CHAR_EMPTY

// Field types
export const FIELD_TYPES = [
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
]