

import { raiseConfirmImport, raiseConfirmImportWarning, raiseConfirmImportError } from '../../notify/raise-confirm-import.mjs'

/**
 * Confirm import
 */
export const confirmImport = (message, callback, messageType = 'info') => {
  return messageType === 'error' ? raiseConfirmImportError(message)
    : messageType === 'warning' ? raiseConfirmImportWarning(`${message} Do you want to continue?`, callback)
    : raiseConfirmImport(`${message} Do you want to continue?`, callback)
}