/*!
 * Invite constants
 */

// Dependencies
import {
  ONE_YEAR_IN_SECONDS
} from '../constants'

// Invite types
export const TEAM_INVITE_TYPE   = 'team-invite'
export const LIST_INVITE_TYPE   = 'list-invite'
export const SIGNUP_INVITE_TYPE = 'signup-invite'
export const DEFAULT_INVITE_EXPIRY_DURATION = ONE_YEAR_IN_SECONDS
