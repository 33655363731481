/**
 * Field schema constants
 */

// Dependencies
import { CHAR_EMPTY }   from './characters.mjs'
import { TWO_HUNDRED }  from './number.mjs'

// Utils
import { getTimestamp } from '../utils/timestamp.mjs'

// Field types
export const FIELD_TYPE_TEXT       = 'Text'
export const FIELD_TYPE_CHECKBOX   = 'Checkbox'
export const FIELD_TYPE_DATE       = 'Date'
export const FIELD_TYPE_NUMBER     = 'Number'
export const FIELD_TYPE_TAGLIST    = 'TagList'
export const FIELD_TYPE_CURRENCY   = 'Currency'
export const FIELD_TYPE_PERCENTAGE = 'Percentage'

export const EMPTY_VALUE_TEXT       = 'Empty'
export const FIELD_TYPE_TEXT_PLAIN  = 'Plain'

export const DEFAULT_TRUE_VALUE         = 'Yes'
export const DEFAULT_FALSE_VALUE        = 'No'
export const DEFAULT_LABEL              = CHAR_EMPTY
export const DEFAULT_FIELD_TYPE         = FIELD_TYPE_TEXT
export const DEFAULT_VALUE              = CHAR_EMPTY
export const DEFAULT_TEXT_TYPE          = FIELD_TYPE_TEXT_PLAIN
export const DEFAULT_NUMBER_DP          = 2
export const DEFAULT_PERCENTAGE_DP      = 0
export const DEFAULT_IS_TEXT_MULTI_LINE = false
export const DEFAULT_COLUMN_WIDTH       = TWO_HUNDRED

export const SAVE_COLUMN_NS             = 'columns'

// Field Keys
export const FIELD_KEY_ID            = 'id'
export const FIELD_KEY_DESCRIPTION   = 'description'
export const FIELD_KEY_PRODUCT_CODE  = 'productCode'
export const FIELD_KEY_VENDOR_NAME   = 'vendorName'
export const FIELD_KEY_SUPPLIER_NAME = 'supplierName'
export const FIELD_KEY_COST_PRICE    = 'costPrice'
export const FIELD_KEY_MARGIN        = 'margin'
export const FIELD_KEY_MARKUP        = 'markup'
export const FIELD_KEY_LIST_PRICE    = 'listPrice'
export const FIELD_KEY_CREATED_AT    = 'createdAt'
export const FIELD_KEY_UPDATED_AT    = 'updatedAt'
// Special
export const FIELD_KEY_FIRST_COLUMN = '__FIRST_COLUMN__'
export const FIELD_KEY_NEW_COLUMN   = '__NEW_COLUMN__'
export const FIELD_KEY_NO_MAP_COLUMN = '__NONE__'

// Field Labels
export const FIELD_LABEL_ID            = 'ID'
export const FIELD_LABEL_DESCRIPTION   = 'Description'
export const FIELD_LABEL_PRODUCT_CODE  = 'Product Code'
export const FIELD_LABEL_VENDOR_NAME   = 'Vendor Name'
export const FIELD_LABEL_SUPPLIER_NAME = 'Supplier Name'
export const FIELD_LABEL_COST_PRICE    = 'Cost Price'
export const FIELD_LABEL_MARGIN        = 'Margin'
export const FIELD_LABEL_MARKUP        = 'Markup'
export const FIELD_LABEL_LIST_PRICE    = 'List Price'
export const FIELD_LABEL_CREATED_AT    = 'Created At'
export const FIELD_LABEL_UPDATED_AT    = 'Updated At'
// Special
export const FIELD_LABEL_NEW_COLUMN    = '+ New Column'
export const FIELD_LABEL_NO_MAP_COLUMN = '- Nothing -'

// Default field values
export const DEFAULT_LIST_PRICE_VALUE = '=ADDMARGIN($costPrice, $margin)'

// Default field schemas
export const DEFAULT_FIELD_SCHEMAS = [
  {
    key:          FIELD_KEY_ID,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_ID,
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }, {
    key:          FIELD_KEY_DESCRIPTION,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_DESCRIPTION,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_PRODUCT_CODE,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_PRODUCT_CODE,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  false,
    isEditable :  true
  }, {
    key:          FIELD_KEY_VENDOR_NAME,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_VENDOR_NAME,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_SUPPLIER_NAME,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_SUPPLIER_NAME,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_COST_PRICE,
    type:         FIELD_TYPE_CURRENCY,
    label:        FIELD_LABEL_COST_PRICE,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_MARGIN,
    type:         FIELD_TYPE_PERCENTAGE,
    label:        FIELD_LABEL_MARGIN,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_MARKUP,
    type:         FIELD_TYPE_PERCENTAGE,
    label:        FIELD_LABEL_MARKUP,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_LIST_PRICE,
    type:         FIELD_TYPE_CURRENCY,
    label:        FIELD_LABEL_LIST_PRICE,
    defaultValue: DEFAULT_LIST_PRICE_VALUE,
    isHidden:     false,
    isDeletable:  true,
    isEditable :  true
  }, {
    key:          FIELD_KEY_CREATED_AT,
    type:         FIELD_TYPE_DATE,
    label:        FIELD_LABEL_CREATED_AT,
    defaultValue: getTimestamp(),
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }, {
    key:          FIELD_KEY_UPDATED_AT,
    type:         FIELD_TYPE_DATE,
    label:        FIELD_LABEL_UPDATED_AT,
    defaultValue: getTimestamp(),
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }
]

export const NON_EDITABLE_FIELDS = DEFAULT_FIELD_SCHEMAS
  .filter(field => !field.isEditable)
  .map(field => field.key)

export const NON_DELETABLE_FIELDS = DEFAULT_FIELD_SCHEMAS
  .filter(field => !field.isDeletable)
  .map(field => field.key)
