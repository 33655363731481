
/**
 * Get list field schemas for list columns
 * @param {*} list 
 * @returns 
 */

export const getListFieldSchemas = (list) => {
  const fieldSchemas = []
  for (const col of list.columnSet?.columns) {
    fieldSchemas.push(col.fieldSchema)
  }
  return fieldSchemas
}
