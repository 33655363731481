import { FieldSchemaEntity, ColumnEntity } from '../entity'

function createColumnEntityWithFieldSchema(column) {
  //TODO: validation
  if (column.isColumnEntity && column.fieldSchema) {
    console.warn('Already a column entity with fieldSchema')
    return column
  }

  if (column.isColumnEntity) {
    column.createFieldSchema()
    return column
  }

  if (!column.fieldSchema) {
    return new ColumnEntity({
      ...column,
    })
  }

  const newFieldSchemaEntity = column.fieldSchema.isFieldSchemaEntity
    ? column.fieldSchema
    : new FieldSchemaEntity(column.fieldSchema)
  const newColumnEntity = new ColumnEntity({
    ...column,
    fieldSchema: newFieldSchemaEntity,
    fieldSchemaId: newFieldSchemaEntity.id
  })

  return newColumnEntity
}

export { createColumnEntityWithFieldSchema }
