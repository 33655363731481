import { getCurrentUser } from "@/actions/current-user.mjs"
import { UserListener } from '../events/UserListener.mjs'

// event handlers
let userEventListener = null

function startUserListener() {
  console.log('startUserListener called :')
  const userEntity = getCurrentUser()
  if (userEventListener) {
    console.warn('User listener has already started')
  } else {
    userEventListener = new UserListener({ userEntity })
    userEventListener.start()
  }
}
function stopUserListener() {
  if (!userEventListener) return
  userEventListener.stop()
  userEventListener = null  
}


export {
  startUserListener, stopUserListener,
}