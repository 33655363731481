/*!
 * Column ui utils
 */

import { DEFAULT_COLUMN_WIDTH_CHAR, DEFAULT_COLUMN_WIDTH_SPACING } from '../column-set/column-set-constants.mjs'

function createColDefFromColumn(column) {
  return {
    colId: column.id,
    headerName: column.label,
    field: column.key,
    width: column.width,
    editable: column.isEditable,
    hide: column.isHidden,
    pinned: (column.isPinned) ? 'left' : undefined,
  }
}

function createColumnFromUi(uiColumn) {
  return {
    id: uiColumn.colId,
    width: uiColumn.actualWidth,
    isHidden: !uiColumn.visible,
    isPinned: uiColumn.pinned ? true : false
  }
}

/**
 * Determine the width of the column based on column label length
 * @param { Object } column
 * @param { string } column.label
 * @param { string } [column.width] 
 * @returns { number } column width
 */
function setColumnWidthToLabel(column) {
  const columnWidth = (column.label.length * DEFAULT_COLUMN_WIDTH_CHAR) + DEFAULT_COLUMN_WIDTH_SPACING
  return column?.width ? Number(column.width) : Number(Math.round(columnWidth))
}

export { createColDefFromColumn, createColumnFromUi, setColumnWidthToLabel }
