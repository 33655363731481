import { isEmptyNullOrUndefined } from '../../utils/is.mjs'
import { CHAR_EMPTY, CHAR_PERCENT } from '../../constants/characters.mjs'
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  DEFAULT_NUMBER_DP,
  DEFAULT_PERCENTAGE_DP,
  DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
  EMPTY_VALUE_TEXT,
  DEFAULT_IS_TEXT_MULTI_LINE
} from '../field-schema-constants.mjs'

export function makeFormatInput(type) {
  switch (type) {
    case FIELD_TYPE_PERCENTAGE:
      return formatPercentageInput

    case FIELD_TYPE_NUMBER:
      return formatNumberInput

    case FIELD_TYPE_CURRENCY:
      return formatCurrencyInput

    case FIELD_TYPE_TAGLIST:
      return formatTagListInput

    case FIELD_TYPE_CHECKBOX:
      return formatCheckboxInput

    case FIELD_TYPE_DATE:
      return formatDateInput

    default:
      return formatTextInput
  }
}

/**
 * Make number format
 */
function formatNumberInput(input) {
  if (isEmptyNullOrUndefined(input)) {
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make currency format
 */
function formatCurrencyInput(input) {
  if (isEmptyNullOrUndefined(input)) {
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make percentge format
 */
function formatPercentageInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  const regex = /%$/i
  let cleanValue = String(input).trim()
  if (!cleanValue.match(regex)) {
    cleanValue = cleanValue + CHAR_PERCENT
  }
  return cleanValue
}

/**
 * Make date time format
 */
function formatDateInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make taglist format
 */
function formatTagListInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make text format
 */
function formatTextInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make checkbox format
 */
function formatCheckboxInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

