import { CHAR_EMPTY } from '../constants/characters.mjs'

/**
 * Normalize string - used for string comparison
 */
function normalizeString(str) {
  return String(str || CHAR_EMPTY).toLowerCase().trim()
}

// Exports
export {
  normalizeString
}
