// Dependencies
import { deleteObject } from 'firebase/storage'
import { required } from '../utils/required.mjs'
import { storage, ref } from './storage.mjs'

/**
 * Delete a file / blob by id
 */
function deleteFileById(
  id = required('file id')
) {
  const storageRef = ref(storage, id)
  return deleteObject(storageRef)
}

// Exports
export { deleteFileById }
