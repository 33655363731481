// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

//sessions
import { getTeamSession } from '../../session/team-session.mjs'

// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// events
import {
  EVENT_COLUMN_MOVE,
  EVENT_COLUMN_MOVE_LOCAL,
  EVENT_COLUMN_MOVE_REMOTE,
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'


//#region //--- Move ---//

export const addEventsColumnMove = (controller, subscriptions, columnManager,
  {
    scope = SCOPE_TEAM
  } = {}
) => {
  addEventListener(
    EVENT_COLUMN_MOVE_LOCAL,
    (event) => moveColumnEvent(event, columnManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_MOVE,
      moveColumnRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_MOVE_REMOTE,
    (event) => moveColumnRemoteEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}


export const moveColumnEvent = async (event, columnManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { listId, columnId, toIndex } = event.detail
  const userRole = columnManager.userRole
  const team = getTeamSession()
  if (![VIEWER_USER_ROLE, EDITOR_USER_ROLE, ADMIN_USER_ROLE]
    .includes(userRole)) {
    console.error('user is not permitted to move')
    return
  }
  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_MOVE,
    scope,
    teamId: team.id,
    payload: {
      ...event.detail,
    }
  })
  // send external event
  if ([EDITOR_USER_ROLE, ADMIN_USER_ROLE].includes(userRole)) {
    await columnManager.move(listId, columnId, toIndex, { gridOnly: false, event: externalEvent })
    setTimeout(() => emitEvent(externalEvent), 500)
  } else {
    await columnManager.move(listId, columnId, toIndex, { gridOnly: true })

  }
}


/**
 * Move columns external event handler
 * @param {*} event 
 * @returns 
 */
export const moveColumnRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_COLUMN_MOVE_REMOTE, { ...payload })
}

/**
 * Move column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const moveColumnRemoteEvent = async (event, columnManager) => {
  const { listId, columnId, toIndex } = event.detail
  if (listId != columnManager.list.id) {
    return
  }
  await columnManager.move(listId, columnId, toIndex, { gridOnly: true })
}

//#endregion