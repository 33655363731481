import { ItemEntity } from '../../entity/ItemEntity.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'

const createUiItemField = ({
  fieldSchemaId,
  inputValue = null,
  value = null,
  formattedValue = CHAR_EMPTY,
  warning = null,
  error = null,
}) => {
  return {
    fieldSchemaId,
    inputValue,
    value,
    formattedValue,
    warning,
    error,
  }
}


/**
 * Create ItemEntity with the column fields
 * @param {*} param0 
 * @returns 
 */
function createItemWithFields({
  teamId,
  libraryListId,
  listId,
  columns
}) {
  const newItem = new ItemEntity({ 
    teamId, 
    libraryListId, 
    lists: listId ? [listId] : [] 
  })
  
  for (const column of columns) {
    const key = column.fieldSchema.key
    newItem.addField({
      key,
      ...createUiItemField({ fieldSchema: column.fieldSchema.toObject() })
    })
  
  }
  return newItem
}

export { createItemWithFields }
