<template>
  <q-dialog v-model="isModifyViewOpen">
    <q-card style="border-radius: 20px; width: 27rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Edit View</div>
      </q-card-section>

      <form @submit.prevent="onListSave">
        <q-card-section class="q-pt-none">
          <q-input v-model="name"
            bg-color="accent" outlined dense>
            <template v-slot:append>
            <q-icon name="close" @click="name = ''" class="cursor-pointer"></q-icon>
            </template>
          </q-input>
        </q-card-section>
        
        <q-card-section class="q-pt-none">
          <div class="text-weight-medium q-mt-md">Columns</div>
          <p style="font-size: .85em;">Select the library columns you wish to have available on the view.</p>
          <q-input color="grey-10" bg-color="accent" outlined class="full-width rounded-borders q-mt-sm" dense
            v-model="selectedColumnsSearchValue" placeholder="Filter columns" @keyup="onFilterColumn()"
            @clear="onFilterColumn()" clearable>
            <template v-slot:prepend>
              <q-icon color="dark" name="search" />
            </template>
          </q-input>

          <div class="q-py-xs" style="overflow: auto; min-height: 12rem; max-height: 12rem;">
            <q-item v-for="column in filteredColumns" :key="column.id" dense
              class="row justify-between items-center q-px-xs q-py-none">
              <q-checkbox :model-value="selectedFieldSchemas.findIndex(x => x.key === column.key) !== -1"
                @click="onCheckboxSelected(column)" :label="column.label" color="primary" />
            </q-item>
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-checkbox
            v-model="notifyMembers" label="Notify all team members" color="primary"
            class="hidden text-grey-8 q-mt-sm"
          />
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <!-- Cancel Button -->
          <q-btn
            no-caps unelevated
            class="rounded-borders"
            color="accent"
            text-color="dark"
            label="Cancel"
            style="width: 6.3rem;" v-close-popup @click="onCancelList()"
          />

          <!-- Done Button-->
          <q-btn
            :disable="(name === '')"
            no-caps unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Done"
            style="width: 6.3rem;" v-close-popup
          />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>
</template>

<style scoped>
textarea {
  resize: none !important;
}
</style>

<script setup>

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'


// events
import { dispatch } from '../../events/dispatch.mjs'

import { 
  EVENT_OPEN_MODIFY_VIEW,
  EVENT_VIEW_MODIFY_LOCAL
} from '../../events/constants/event-types.mjs'

// actions
import { getListAndColumns } from '../../list-core/db/get-list-and-columns.mjs'

// Refs
const notifyMembers = ref(false)
const name = ref('')
const selectedFieldSchemas = ref([])
const libraryFieldSchemas = ref([])
const filteredColumns = ref([])
const selectedColumnsSearchValue = ref('')
const isModifyViewOpen = ref(false)

let libraryColumnSet = null
let listColumnSet = null
let currentList = null
let eventController = null
let currentListId = null


onMounted(() => {
  if (!eventController) {
    eventController = new AbortController()
    addEventListener(EVENT_OPEN_MODIFY_VIEW, onOpenModifyViewEvent, { signal: eventController.signal })
  }
})

onUnmounted(() => {
  isModifyViewOpen.value = false
  eventController?.abort()
})

async function onOpenModifyViewEvent(event) {
  const { listId } = event.detail
  currentListId = listId
  await initList()
  isModifyViewOpen.value = true
}



async function initList() {
  const { list, columnSet, fieldSchemas, ...props } = await getListAndColumns(currentListId)
  libraryColumnSet = props.libraryColumnSet
  listColumnSet = columnSet
  currentList = list
  libraryFieldSchemas.value = fieldSchemas
    .filter(fs => !fs.isDeleted)
    .map(col => { return col.toObject() })
    .sort((a, b) => { return a.label.localeCompare(b.label) })

  const listFieldShemasIds = columnSet.columns
    .filter(col => !col.isDeleted)
    .map(col => col.fieldSchemaId)

  const listFieldSchemas = fieldSchemas
    .filter(fs => listFieldShemasIds.includes(fs.id) && !fs.isDeleted)
    .map(fs => fs.toObject() )

  filteredColumns.value = libraryFieldSchemas.value
  selectedFieldSchemas.value = listFieldSchemas
  name.value = list.name
  return
}

/**
 * Save list name handler
 */
async function onListSave() {
  const selectedFieldIds = selectedFieldSchemas.value.map(fs => fs.id)
  // remove unselected columns
  listColumnSet.columns = listColumnSet.columns.filter(col => selectedFieldIds.includes(col.fieldSchemaId))
   
  //add selected columns 
  const existingIds = listColumnSet.columns.map(col => col.fieldSchemaId)
  const newSelected = selectedFieldSchemas.value.filter(selected => !existingIds.includes(selected.id))
  for (const field of newSelected) {
    const libraryCol = libraryColumnSet.columns.find(col => col.fieldSchemaId === field.id)
    const col = libraryCol.cloneAsNew()
    col.setCreated().setUpdated()
    listColumnSet.addColumn(col)
  }
  //listColumnSet.save()
  currentList.name = name.value
  return dispatch(EVENT_VIEW_MODIFY_LOCAL, { list: currentList, columnSet: listColumnSet })
}

/**
 * Cancel handler
 */
function onCancelList() {
  initList()
  isModifyViewOpen.value = false
  return
}

/**
 * Filter columns for column selection handler
 */
function onFilterColumn() {
  const query = selectedColumnsSearchValue.value.toLowerCase()
  filteredColumns.value = libraryFieldSchemas.value.filter(field => {
    if (field.isDeleted) {
      return false
    }
    if (!query?.length) {
      return true
    }
    return field.label
      ?.toLowerCase()
      .includes(query)
  })
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    })
}
/**
 * Switch fieldschema visibility handler
 */
function onCheckboxSelected(field) {
  // checbox select or deselect
  const existingCol = selectedFieldSchemas.value.find(col => col.id === field.id)
  if (existingCol) {
    selectedFieldSchemas.value = [...selectedFieldSchemas.value.filter(col => col.id !== field.id)]
  } else {
    selectedFieldSchemas.value.push(field)
  }
}

</script>
