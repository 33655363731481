<template>

  <!-- Cell container -->
  <div class="ag-cell-label-container" role="presentation"
    @click="onNew"
  >
    <div style="min-height: 39px; width: 100%;"></div>
  </div>
</template>

<script setup>

// Actions
import { openFieldEditor } from '../../ui/components/field-editor-ui.mjs'

// Constants
const IS_NEW = true

/**
 * Add new field handler
 */
function onNew() {
  return openFieldEditor(null, IS_NEW)
}

</script>
