import './styles/quasar.sass'
import { Notify } from 'quasar'

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify
  },
  config: {
    extras: []
  }
}
