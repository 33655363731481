
// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_IMPORT_BASIC } from '../../../integration-apps/registered-apps.mjs'


// states
import { currentIntegrationId } from '../../../integrations/states/integrations-ui-states.mjs'
import { getActiveIntegration } from '../../../integrations/actions/active-integrations.mjs'

// events
import { EVENT_INTEGRATION_UPDATED_REMOTE } from '../../../events/constants/event-types.mjs'

import {
  clearImportConfigurationUiStates,
  setImportConfigurationUiStates,
  onLocalSalesforceImportConfigUpdated,
  onRemoteSalesforceImportConfigUpdated,
  createImportConfigurationFromUi,
  reloadImportOptions
} from './salesforce-import-configuration-ui.mjs'

import { registerAppFunctions } from '../../../integration-modules/utils/integration-apps-utils.mjs' 

let eventController = null


/**
 * 
 * @param {*} id 
 * @returns 
 */
export const startSalesforceImportUi = async (id) => {
  registerAppFunctions(
    INTEGRATION_SALESFORCE,
    APP_SFDC_IMPORT_BASIC,
    {
      createConfigurationFunc: createImportConfigurationFromUi,
      setConfigurationUiStatesFunc: setImportConfigurationUiStates
    }
  )
  if (!id) {
    clearImportConfigurationUiStates()
    return
  }
  const integration = await getActiveIntegration(id)

  // if no id, it's new
  if (integration) {
    setImportConfigurationUiStates(integration)
  }
  currentIntegrationId.value = id
  //init load
  let eventController = new AbortController()
  addEventListener(EVENT_INTEGRATION_UPDATED_REMOTE, onRemoteSalesforceImportConfigUpdated, { signal: eventController.signal })
}

export const stopSalesforceImportUi = () => {
  eventController?.abort()
  eventController = null
  clearImportConfigurationUiStates()
}

export const reloadSalesforceImportUi = async (id) => {
  if (!id) {
    clearImportConfigurationUiStates()
    return
  }
  const integration = await getActiveIntegration(id)
  // if no id, it's new
  if (integration) {
    reloadImportOptions(integration)
  }
}
