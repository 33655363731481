import { isArray } from '../utils/is.mjs'

import { EVENT_USER_AUTHENTICATED } from "../events/constants/event-types.mjs"
import {
  COLLECTION_ITEMS,
  COLLECTION_SESSIONS
} from '../db/db-collections.mjs'

let localDb = null

export const getLocalDb = (collectionName) => {
  if (!localDb) {
    console.error('LocalDb has not started')
  }
  return localDb?.(collectionName)
}

const createDb = ({
  name = 'test',
  version = 1,
  schema = {},
  keyPath = 'id'
}) => new Promise((resolve, reject) => {
  const req = window.indexedDB.open(name, version)

  req.onerror = e => reject(e.target.error)

  req.onupgradeneeded = e => {
    const db = req.result
    // delete object store and start again
    for(const store of db.objectStoreNames) {
      console.log('delete objectstore:', store)
      db.deleteObjectStore(store)
    }

    Object.entries(schema).forEach(([name, indexes = []]) => {
      const store = db.createObjectStore(name, { keyPath })
      for (const index of indexes) {
        const { indexName, keyPath, options } = index
        store.createIndex(indexName, keyPath, options)
      }
    })
  }

  req.onsuccess = e => {
    // check for issues
    Object.entries(schema).forEach(([name]) => {
      const db = req.result
      if (!db.objectStoreNames.contains(name)) {
        console.error('local-db is missing objectStore:', name)
        console.error('Delete database to fix the problem')

      }
    })
    return resolve(name => Object.fromEntries([
      'get', 'put', 'getAll', 'clear', 'count', 'delete',
      'getAllKeys', 'getKey', 'openCursor', 'transaction',
      'index'
    ].map(key => [key, (...args) => new Promise((resolve, reject) => {
      if (key === 'transaction') {
        resolve(req.result
          .transaction(name, 'readwrite')
        )
      }
      if (key === 'index') {
        resolve(req.result
          .transaction(name, 'readwrite')
          .objectStore(name)
          .index(args[0])
        )
      }
      if (key === 'openCursor') {
        resolve(req.result
          .transaction(name, 'readwrite')
          .objectStore(name)[key](...args))
      }
      const tx = req.result
        .transaction(name, 'readwrite')
        .objectStore(name)[key](...args)
      tx.onsuccess = e => resolve(e.target.result)
      tx.onerror = e => reject(e.target.error)
    })])))
  }
})

export const startLocalDb = async () => {
  if (localDb) {
    return
  }
  console.log('startLocalDb called')
  localDb = await createDb({
    name: 'product-engine',
    version: 2,
    schema: {
      [COLLECTION_ITEMS]: [
        {
          indexName: 'productCode',
          keyPath: 'productCode',
          options: {
            unique: false
          }
        },
        {
          indexName: 'libraryListId',
          keyPath: 'libraryListId',
          options: {
            unique: false
          }
        },
        {
          indexName: 'lists',
          keyPath: 'lists',
          options: {
            unique: false,
            multiEntry: true
          }
        }
      ],
      [COLLECTION_SESSIONS]: [],
    }
  }).catch(console.error)
}

export const clearLocalDb = async () => {
  localDb(COLLECTION_ITEMS).clear()
  localDb(COLLECTION_SESSIONS).clear()
}

export const bulkPut = async (collectionName, dataArr) => {
  if (!localDb || !localDb(collectionName)) {
    return
  }
  const startTime = Date.now()
  const tx = await localDb(collectionName).transaction(collectionName)
  const store = tx.objectStore(collectionName)
  for (const data of dataArr) {
    store.put(data)
  }
  return tx.complete
}



// On EVENT_USER_AUTHENTICATED has a delay caused sequence issue
//addEventListener(EVENT_USER_AUTHENTICATED, startLocalDb)
addEventListener('load', startLocalDb)
