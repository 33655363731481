// Dependencies
import { getStorage, getDownloadURL as getDownloadUrl, ref, uploadBytes, getBlob } from 'firebase/storage'
import { DbInstance } from '../db/DbInstance.mjs'
import { isEmulator, useStorageEmulator } from '../db/emulators.mjs'

const dbInstance = new DbInstance({ poolSize: 1 })
const storage = getStorage(dbInstance.app)
if (isEmulator()) {
  useStorageEmulator(storage)
}

// Exports
export { storage, ref, uploadBytes, getDownloadUrl, getBlob }
