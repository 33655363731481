import { getListCoreRpc } from '../../db/rpcs/db-rpcs.mjs'
import { ACTION_UPSERT_LIST, ACTION_GET_LIST } from '../../list-core/constants/action-types.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'
export const upsertList = async (list) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_UPSERT_LIST,
    payload: list
  })
  return result?.data?.success
}

export const fetchList = async (id) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_LIST,
    payload: {
      id
    }
  })
  if (result.data?.success) {
    return result.data.result
  }
  console.error ('fetchList error:', result.data)
  return result?.data?.success
}

export const fetchListEntity = async (id) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_LIST,
    payload: {
      id
    }
  })
  if (result.data?.success) {
    return result.data.result ? new ListEntity(result.data.result) : result.data.result
  }
  console.error ('fetchList error:', result.data)
  return result?.data?.success
}
