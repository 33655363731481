/**
 * save integration
 */

import { generateId } from '../../../utils/uuid.mjs'

// session events
import { EVENT_INTEGRATION_UPDATED_LOCAL  } from '../../../events/constants/event-types.mjs'
import { dispatch } from '../../../events/dispatch.mjs'

// integrations
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'

// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../rpcs/salesforce-rpcs.mjs'
import {
  ACTION_UPDATE_INTEGRATION,
} from '../constants/action-types.mjs'

// active integrations
import { getActiveIntegration, upsertActiveIntegrations } from '../../../integrations/actions/active-integrations.mjs'


// salesforce ui
import { createIntegrationSettingsFromUi } from './salesforce-connection-ui.mjs'
import { makeCreateConfigurationFromUi } from '../../utils/make.mjs'
/**
 * create integration from ui states
 * @param {*} subtype 
 * @returns 
 */
export const createIntegratonFromUi = async (subtype) => {
  let updateIntegration = createIntegrationSettingsFromUi(subtype)
  updateIntegration.id = updateIntegration.id || generateId()
  let activeIntegration = await getActiveIntegration(updateIntegration.id)
  // update activeIntegration
  if (activeIntegration) {
    updateIntegration = {
      ...activeIntegration,
      ...updateIntegration
    }
  }
  // TODO: PL - change to event
  const createConfigurationFromUi = makeCreateConfigurationFromUi(updateIntegration.type, updateIntegration.subtype)
  if (!createConfigurationFromUi) {
    console.error('No createConfigurationFromUi found. (type/subtype)',updateIntegration)
    return
  }
  updateIntegration.configuration = createConfigurationFromUi(updateIntegration.subtype)
  return updateIntegration
}

/**
 * save integration
 * @param {*} integration 
 * @returns 
 */
export const saveIntegration = async (integration) => {
  // save update
  integration = upsertActiveIntegrations(integration)
  const sfdcIntegration = getSalesforceIntegrationRpc()
  const response = await sfdcIntegration({
    action: ACTION_UPDATE_INTEGRATION,
    payload: {
      ...integration
    }
  })
  if (response.data && response.data.id) {
    upsertActiveIntegrations(response.data, false)
    dispatch(EVENT_INTEGRATION_UPDATED_LOCAL , response.data)
    return {
      success: true,
      data: response.data
    }
  }
  return {
    success: false,
    error: 'Failed to save integration'
  }

}
