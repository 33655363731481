import { INTEGRATION_SALESFORCE } from '../integrations/constants/integration-types.mjs'

import {
  STRIPE_PRODUCT_NAME_STANDARD,
  STRIPE_PRODUCT_NAME_PRO,
  STRIPE_PRODUCT_NAME_ENTERPRISE
} from '../constants/stripe.mjs'

export const APP_SFDC_IMPORT_BASIC = 'salesforce-import'
export const APP_SFDC_IMPORT_BY_QUERY = 'salesforce-import-by-query'
export const APP_SFDC_PUBLISH_BASIC = 'salesforce-publish'
export const APP_SFDC_PUBLISH_ADVANCED = 'salesforce-publish-advanced'
export const APP_SHOPIFY_IMPORT_ADVANCED = 'shopify-import-advanced'


const allPlans = [
  STRIPE_PRODUCT_NAME_STANDARD,
  STRIPE_PRODUCT_NAME_PRO,
  STRIPE_PRODUCT_NAME_ENTERPRISE
]

const proPlans = [
  STRIPE_PRODUCT_NAME_PRO,
  STRIPE_PRODUCT_NAME_ENTERPRISE
]

// TODO: PL - move to DB
export const REGISTERED_APPS = [
  {
    name: 'Salesforce Import Basic',
    type: INTEGRATION_SALESFORCE,
    subtype: APP_SFDC_IMPORT_BASIC,
    path: APP_SFDC_IMPORT_BASIC,
    description: 'Import Product and PricebookEntry data',
    defaultName: 'My Salesforce Import',
    plans: allPlans
  },
  {
    name: 'Salesforce Import By Query',
    type: INTEGRATION_SALESFORCE,
    subtype: APP_SFDC_IMPORT_BY_QUERY,
    path: APP_SFDC_IMPORT_BY_QUERY,
    description: 'Import data by SOQL Query',
    defaultName: 'My Salesforce Import By Query',
    plans: allPlans
  },
  {
    name: 'Salesforce Publish Basic',
    type: INTEGRATION_SALESFORCE,
    subtype: APP_SFDC_PUBLISH_BASIC,
    path: APP_SFDC_PUBLISH_BASIC,
    description: 'Publish Product and PricebookEntry list price',
    defaultName: 'My Salesforce Basic',
    plans: allPlans
  },
  {
    name: 'Salesforce Publish Advanced',
    type: INTEGRATION_SALESFORCE,
    subtype: APP_SFDC_PUBLISH_ADVANCED,
    path: APP_SFDC_PUBLISH_ADVANCED,
    description: 'Publish to any standard and/or custom objects',
    defaultName: 'My Salesforce Publish Advanced',
    plans: proPlans
  }
]

