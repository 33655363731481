<template>
  <q-dialog v-model="isAddToViewOpen" @before-show="onAddToViewOpen()">
    <q-card style="border-radius: 20px; width: 27rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Add {{ selectedItemsLength }} Item{{ selectedItemsLength !== 1 ? 's' : '' }} To View</div>
      </q-card-section>

      <form @submit.prevent="onListSave">
        <q-card-section class="q-pt-none">
          <q-select v-model="existingList" color="black" bg-color="accent" outlined use-input input-debounce="0"
            class="rounded-borders" dense menu-anchor="bottom left" :options="allFilteredLists"
            :label="existingList.id ? void 0 : 'Select existing view'" @update:model-value="onHandleListChange"
            @filter="onFilterLists">
            <template v-slot:selected>{{ existingList.name }}</template>
            <template v-slot:option="scope">
              <q-item v-if="!scope.opt.group" v-bind="scope.itemProps" class="row items-center q-ma-xs rounded-borders"
                clickable>{{ scope.opt.name }}</q-item>
            </template>
          </q-select>
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <!-- Cancel Button -->
          <q-btn
            no-caps unelevated
            class="rounded-borders"
            color="accent"
            text-color="dark"
            label="Cancel"
            style="width: 6.3rem;" v-close-popup @click="onCancelList()"
          />

          <!-- Done Button-->
          <q-btn
            :disable="(!existingList.id)"
            no-caps unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Done"
            style="width: 6.3rem;" v-close-popup
          />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>
</template>

<style scoped>
textarea {
  resize: none !important;
}
</style>

<script setup>

// Dependencies
import { ref } from 'vue'

// Actions
import { 
  isAddToViewOpen, 
  teamPriceLists,
  selectedItemsCount
} from '../../ui/ui-states.mjs'

 // events
import { dispatch } from '../../events/dispatch.mjs'
import { 
  EVENT_PROGRESS_START, 
  EVENT_OPEN_ADD_TO_VIEW,
  EVENT_ADD_TO_VIEW
} from '../../events/constants/event-types.mjs'

// Refs
let selectedItems = []
const selectedItemsLength = ref(0)
const existingList = ref({})
const allLists = ref([])
const allFilteredLists = ref([])


addEventListener(EVENT_OPEN_ADD_TO_VIEW, onOpenAddToViewEvent)


function onOpenAddToViewEvent(event) {
  const { columnSet, fieldSchemas, selectedItems } = event.detail
  selectedItemsLength.value = selectedItems.length 
  allLists.value = teamPriceLists.value
  isAddToViewOpen.value = true
}

async function onAddToViewOpen() {

  //await initList()
}

function onHandleListChange() {
}

/**
 * Add elements to existing list
 */
function onListSave() {
  isAddToViewOpen.value = false

  dispatch(EVENT_PROGRESS_START, {
    label: 'Adding items...',
    maxValue: selectedItemsCount.value,
  })
  dispatch(EVENT_ADD_TO_VIEW, { listId: existingList.value.id })
}

function onCancelList() {
  allLists.value = []
  isAddToViewOpen.value = false
  return
}

function onFilterLists(val, update) {
  update(() => {
    const needle = val.toLocaleLowerCase()
    const allStaticLists = allLists.value.filter(v => !v.isStatic)
    if (val != '') {
      allFilteredLists.value = allStaticLists.filter(v=> v.name.toLocaleLowerCase().indexOf(needle) > -1)
    }
    else {
      allFilteredLists.value = allStaticLists
    }
  })
}

</script>
