
import { CHAR_EMPTY, CHAR_PERCENT, CHAR_AT, CHAR_AMPERSAND, CHAR_PLUS, CHAR_MINUS, CHAR_DOLLAR } from '../../constants/characters.mjs'
import { REGEX_ALPHA_AND_WHITESPACE } from '../../constants/regex.mjs'
import { FIELD_TYPES } from '../field-schema-constants.mjs'


/**
 * Make field key from label
 */
function generateFieldKeyFromLabel(
  label = CHAR_EMPTY
) {
  label = String(label).trim()
  if (!label.length) {
    return
  }
  // splits and joins are faster than a regex
  return label
    // replace special chars with text
    .split(CHAR_PERCENT).join('Percent')
    .split(CHAR_AT).join('At')
    .split(CHAR_AMPERSAND).join('And')
    .split(CHAR_PLUS).join('Plus')
    .split(CHAR_MINUS).join('Minus')
    .split(CHAR_DOLLAR).join('Dollar')
    // remove any other non alphanumeric char
    .match(REGEX_ALPHA_AND_WHITESPACE)
    .join(CHAR_EMPTY)
    // remove any remaining whitespace
    .split(/\s/g)
    // format the parts
    .map((part, idx) => {
      if (!part.length) {
        return part
      }
      // TODO: turn this into a util function for PascalCase or camelCase
      return part[0][idx > 0
        ? 'toUpperCase'
        : 'toLowerCase']()
        + part.substring(1).toLowerCase()
    })
    .join(CHAR_EMPTY)
}

function isValidFieldType(type) {
  return FIELD_TYPES.includes(type)
}


export { 
  generateFieldKeyFromLabel, isValidFieldType, 
}