

// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../rpcs/salesforce-rpcs.mjs'
import { ACTION_CREATE_IMPORT_CSV, ACTION_UPDATE_IMPORT_CSV } from '../constants/action-types.mjs'
import { saveIntegration } from './save-integration.mjs'

// event
import { dispatch } from '../../../events/dispatch.mjs'
import { EVENT_SALESFORCE_CREATE_IMPORT_CSV } from '../constants/event-types.mjs'

// session
import { getTeamSession } from '../../../session/team-session.mjs'

// active integration
import { getActiveIntegration } from '../../../integrations/actions/active-integrations.mjs'

// list management
import { saveList, saveListEntity } from '../../../list/db/list-db.mjs'
import { getListAndItems } from '../../../list/actions/list-actions.mjs'
import { createStaticList, updateStaticListFilePath } from '../../../list-core/actions/static-list-actions.mjs'
import { CSV_PRICE_LIST } from '../../../list-core/constants/list-constants.mjs'
import { getListById } from '../../../list/db/list-db.mjs'

//notification
import { raiseNotification } from '../../../notify/raise-notification.mjs'


addEventListener(EVENT_SALESFORCE_CREATE_IMPORT_CSV, event => {
  processImportCsvResult(event)
})


async function createImportCsv(integration) {
  // TODO: get the connection Id
  if (!integration.id) {
    console.error('Missing integration Id')
    return
  }
  await saveIntegration(integration)
  const currentIntegration = await getActiveIntegration(integration.id)
  const listName = currentIntegration.name
  const team = getTeamSession()

  // Use existing list
  if (currentIntegration.importListId) {
    const importList = await getListById(currentIntegration.importListId)
    if (importList && !importList.isDeleted) {
      importList.name = 'Reloading...' + listName
      saveListEntity(importList)
      const sfdcIntegration = getSalesforceIntegrationRpc()
      sfdcIntegration({
        action: ACTION_UPDATE_IMPORT_CSV,
        payload: {
          integrationId: currentIntegration.id,
          listName,
          filePath: importList.csvFilePath
        }
      }).then(result => {
        dispatch(EVENT_SALESFORCE_CREATE_IMPORT_CSV, result)
      })
      return
    }
    // otherwise, continue as a new list
  }

  createStaticList({
    name: 'Loading...' + listName,
    teamId: team.id,
    type: CSV_PRICE_LIST,
    sourceIntegrationId: currentIntegration.id,
  }).then(importList => {
    const sfdcIntegration = getSalesforceIntegrationRpc()
    sfdcIntegration({
      action: ACTION_CREATE_IMPORT_CSV,
      payload: {
        integrationId: currentIntegration.id,
        listId: importList.id,
        listName
      }
    }).then(result => {
      dispatch(EVENT_SALESFORCE_CREATE_IMPORT_CSV, result)
    })
  })
}

function processImportCsvResult(event) {
  const result = event.detail.data
  if (!result) {
    return
  }
  const notifyMsg = result.success ? 'Salesforce Load Completed'
    : (result.error || 'Salesforce Load Failed')
  raiseNotification(notifyMsg)

  const team = getTeamSession()
  const listId = result.listId
  if (!team || !listId) {
    return
  }
  getListAndItems(listId).then(list => {
    if (result.success) {
      list.name = result.listName || 'Salesforce Products Import'
      updateStaticListFilePath(list, result.filePath)
    } else {
      list.name = 'Failed...' + result.listName
      saveList(list)
    }
  })
}

export { createImportCsv }