// utils
import { required } from '../../utils/required.mjs'
//sessions
import { getTeamSession } from '../../session/team-session.mjs'

import { SCOPE_TEAM } from '../../events/external-events.mjs'
// local events
import {
  EVENT_CREATE_VIEW,
  EVENT_ADD_TO_VIEW,
  EVENT_LIBRARY_ITEMS_UPDATED,
} from '../../events/constants/event-types.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'


// actions
import { createView } from '../menu-actions/create-view.mjs'

//#region //--- create view ---//
/**
 * create view with items
 * @param {*} event 
 * @param {*} columnManager 
 */
export const createViewEvent = async (event, columnManager, itemManager) => {
  const { name, libraryColumnSet, selectedColumns, canExport } = event.detail
  const team = getTeamSession()

  const { list, columnSet } = await createView({
    name,
    libraryColumnSet,
    selectedColumns,
    canExport,
    itemManager,
  })
  const externalEvent = createExternalEvent({
    action: EVENT_LIBRARY_ITEMS_UPDATED,
    scope: SCOPE_TEAM,
    teamId: team.id,
    payload: {
      listId: list.id
    }
  }) 
  await itemManager.addSelectedToView(list.id)

  setTimeout(() => emitEvent(externalEvent), 500)

}


export const addEventsCreateView = async (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_CREATE_VIEW,
    async (event) => await createViewEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

//#endregion


//#region //--- add to view ---//
/**
 * Add to view
 * @param {*} event 
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const addToViewEvent = async (event, columnManager, itemManager) => {
  const { listId } = event.detail
  const team = getTeamSession()

  await itemManager.addSelectedToView(listId)
  const externalEvent = createExternalEvent({
    action: EVENT_LIBRARY_ITEMS_UPDATED,
    scope: SCOPE_TEAM,
    teamId: team.id,
    payload: {
      listId
    }
  }) 
  await itemManager.addSelectedToView(listId)

  setTimeout(() => emitEvent(externalEvent), 500)

}

export const addEventsAddToView = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_ADD_TO_VIEW,
    (event) => addToViewEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}