
// utils
import { required } from '../../utils/required.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

import { LIBRARY_LIST, VIEW_LIST, PRICE_LIST, CSV_PRICE_LIST } from '../constants/list-types.mjs'

export const isLibraryList = (list = required('list')) => {
  const team = getTeamSession()
  return (!list.type || !team?.id) ? false
    : (list.type === LIBRARY_LIST
      && list.teamId === team?.id)
}

export const isViewList = (list = required('list')) => {
  const team = getTeamSession()
  return (!list.type || !team?.id) ? false
    : ([VIEW_LIST, PRICE_LIST].includes(list.type)
      && list.teamId === team.id
      && !list.isStatic)
}

export const isTeamPriceList = (list = required('list')) => {
  const team = getTeamSession()
  return (!list.type || !team?.id) ? false
    : (list.type === PRICE_LIST
      && list.teamId === team.id
      && list.isStatic)
}

export const isExternalPriceList = (list = required('list')) => {
  const team = getTeamSession()
  return (list.type === PRICE_LIST) && (!team || !team.id) ? true
    : !list.type ? false
    : (list.type === PRICE_LIST && list.teamId !== team.id)
}

export const isTeamCsvPriceList = (list = required('list')) => {
  const team = getTeamSession()
  return (!list.type || !team?.id) ? false
    : (list.type === CSV_PRICE_LIST
      && list.teamId === team.id)
}




