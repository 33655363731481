<template>
  <!-- Cell container -->
  <div 
    style="text-overflow: ellipsis; width: 100%; height: 100%;"
    @dblclick="isLibraryColumn(params) ? onDoubleClick(params) : null">
    <!-- Column Default Icon -->
    <template v-if="hasDefaultValue(params) && isLibraryColumn(params)">
      <q-icon name="circle" color="primary" style="position: absolute; left: -6px; top: -6px; cursor: pointer;"
        @click="onEdit(params)">
        <p11e-tooltip>This column has a default value</p11e-tooltip>
      </q-icon>
    </template>

    <div>
      <!-- Label -->
      <div style="padding: 1rem; text-overflow: ellipsis; width: 100%; overflow: hidden;">
        <span style="position: absolute; left: 1rem; top: .2em; color: #cecece; font-size: .75em;">{{ getFieldType(params) }}</span>
        <span>{{ params.data.fieldSchema?.label }}</span>
        <q-icon v-if="getFieldInfoText(params)?.length" name="info" color="grey-5" class="q-pl-xs"
          style="padding-right: 1em;" />
        <p11e-tooltip anchor="bottom middle" self="top middle" :offset="[5, 5]">
          <div class="text-weight-bold">{{ params.data.fieldSchema?.label }}</div>
          <div>{{ getFieldInfoText(params) }}</div>
        </p11e-tooltip>
      </div>

      <!-- More button -->
      <span class="ag-header-icon ag-header-cell-menu-button" @click.capture="isContextMenuOpen = true"
        style="position: absolute; top: .6rem; right: .4em;">
        <q-btn icon="more_vert" size="sm" unelevated round ripple
          style="color: #111; background-color: rgba(255, 255, 255, .8)" />
      </span>

      <!-- Context Menu -->
      <q-menu v-model="isContextMenuOpen" style="min-width: 200px;" anchor="top right" self="top middle"
        :context-menu="true">

        <q-list class="q-pa-sm text-weight-medium">

          <!-- Edit column -->
          <q-item
            v-if="(!isImportMode && isColumnEditable(params) && canEditColumns) || ( isImportMode && canEditColumns && (mapColumn.label === MAP_NEW.label))"
            clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onEdit(params)">Edit Column Options</q-item-section>
          </q-item>

          <!-- Edit selected -->
          <template v-if="selectedItemsCount && isColumnEditable(params) && isLibraryColumn(params)">
            <q-item clickable dense v-close-popup class="rounded-borders">
              <q-item-section @click="onEditSelected(params)">Bulk update selected</q-item-section>
            </q-item>
          </template>

          <!-- Add column -->
          <q-item v-if="canCreateColumns" clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onNew(params)">{{ FIELD_LABEL_NEW_COLUMN }}</q-item-section>
          </q-item>

          <!-- Show / Hide -->
          <q-item v-if="canHideColumns" clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onHide(params)">Hide</q-item-section>
          </q-item>

          <!-- Pin / Unpin -->
          <q-item v-if="canPinColumns" clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onUpdatePin(params)">{{ params.data.column.isPinned ? 'Unpin' : 'Pin' }}
            </q-item-section>
          </q-item>

          <!-- Sort column -->
          <q-item clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onSort(params)">Sort {{
              (!params.column.sort || params.column.sort === COLUMN_SORT_DESC)  ? 'A&RightArrow;Z': 'Z&RightArrow;A' 
            }}</q-item-section>
          </q-item>

          <!-- Fit column -->
          <q-item clickable dense v-close-popup class="rounded-borders">
            <q-item-section @click="onFit(params)">Autosize</q-item-section>
          </q-item>

          <!-- Add filter -->
          <q-item v-if="isColumnEditable(params) && canFilterColumns" clickable dense v-close-popup
            class="rounded-borders">
            <q-item-section @click="onAddFilter(params)">Add filter</q-item-section>
          </q-item>

          <!-- Delete column -->
          <q-item v-if="isFieldDeletable(params) && isColumnEditable(params) && canDeleteColumns" clickable dense
            v-close-popup class="rounded-borders text-red-12">
            <q-item-section @click="onConfirmDelete(params)">Delete Column</q-item-section>
          </q-item>

        </q-list>
      </q-menu>
      <!-- END: Context menu -->

    </div>

    <div v-if="isImportMode" style="font-size: .7rem; font-weight: bold; margin-bottom: 4px; padding-left: 1rem;">Maps to:
    </div>

    <div v-if="isImportMode">
      <q-select size="sm" dense square options-dense hide-bottom-space v-model="mapColumn" hide-dropdown-icon
        style="margin: 0 1rem; margin-top: -8px;" :color="getMapColumnValueColor()"
        :options="[MAP_NULL, MAP_NEW, ...fieldMapOptions]" :default="getMapColumDefault(params)"
        @update:model-value="onMapColumnChanged(params)">
        <template v-slot:prepend>
          <q-icon size="xs" name="expand_more" />
        </template>
      </q-select>
    </div>
  </div><!-- END: Cell container -->
</template>

<script setup>

// Styles
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/styles/sheet.css'

// Dependencies
import { ref, shallowRef } from 'vue'

// Actions
import { openSearchMenu } from '@/actions/search-menu.mjs'
import { onColumnAddFilter } from '../../list-core/uis/column-ui-actions.mjs'

// utils 
import { isEmptyNullOrUndefined } from '../../utils/is.mjs'
//***NEW***/
import { openFieldEditorNew, openFieldEditorUpdate, openFieldEditorUpdateImport } from '../../ui/components/field-editor-ui.mjs'
import { selectedItemsCount, isImportMode, fieldMapOptions } from '../../ui/ui-states.mjs'

// Constants
import { FIELD_LABEL_NEW_COLUMN } from '../../constants'
import { MAP_NEW, MAP_NULL } from '../../list-core/constants/list-import-constants.mjs'

// UI action permissions
import {
  canEditColumns,
  canHideColumns,
  canPinColumns,
  canFilterColumns,
  canDeleteColumns,
  canCreateColumns,
} from '../../ui/ui-states.mjs'
import { LIBRARY_LIST, VIEW_LIST, COLUMN_SORT_DESC } from '../../list-core/constants/list-constants.mjs'

// events
import { dispatch } from '../../events/dispatch.mjs'
import {
  EVENT_COLUMN_FIELD_SCHEMA_DELETED_LOCAL,
  EVENT_COLUMN_DELETED_LOCAL,
  EVENT_COLUMN_HIDE_LOCAL,
  EVENT_COLUMN_PIN_LOCAL,
  EVENT_COLUMN_UNPIN_LOCAL,
  EVENT_COLUMN_SORT_ASC_LOCAL,
  EVENT_COLUMN_SORT_DESC_LOCAL,
  EVENT_COLUMN_AUTOSIZE_LOCAL,
  EVENT_IMPORT_COLUMN_MAP_UPDATED_LOCAL_COMPLETED,
  EVENT_OPEN_CELL_VALUE_EDITOR,
} from '../../events/constants/event-types.mjs'

import {
  isColumnMapped, isColumnNew,
  createNewColumnFieldSchema, createNoMapFieldSchema
} from '../../list-core/import/import-utils.mjs'

import { raiseConfirmDelete } from '../../notify/raise-confirm-delete.mjs'

const IS_NEW = true
const IS_HIDDEN = true

// Refs
const isContextMenuOpen = ref(false)                                      // controls the context menu state
const mapColumn = shallowRef({})                                                 // the column to map to
let showStatusInfo = ref(false)
let showNewImportStatusInfo = ref(false)
let showErrorStatusInfo = ref(false) // TODO: future


function getMapColumDefault(params) {
  const { teamId, importColumn, importColumnSet, isGridStarted} = params.data
  if (!mapColumn.value.label) {
    mapColumn.value = importColumn?.fieldSchema || createNewColumnFieldSchema(teamId)
  }
  setStatusInfo()
}

function getMapColumnValueColor() {
  return showErrorStatusInfo.value ? 'red'
    : mapColumn.value?.label === MAP_NULL.label ? 'grey-5'
      : showNewImportStatusInfo.value ? 'orange'
        : 'black'
}

function setStatusInfo() {
  //showErrorStatusInfo.value = hasColumnError(mapColumn.value) // TODO: future
  showStatusInfo.value = showErrorStatusInfo.value || isColumnMapped(mapColumn.value)
  showNewImportStatusInfo.value = !showErrorStatusInfo.value && isColumnNew(mapColumn.value)
}


async function onMapColumnChanged(params) {
  const { teamId, importColumn, importColumnSet } = params.data

  const mappedFieldSchema = isColumnNew(mapColumn.value) ? createNewColumnFieldSchema(teamId)
    : !isColumnMapped(mapColumn.value) ? createNoMapFieldSchema(teamId)
      : mapColumn.value.fieldSchema

  importColumn.fieldSchemaId = mappedFieldSchema.id
  importColumn.fieldSchema = mappedFieldSchema
  await importColumnSet.save()
  setStatusInfo()
  dispatch(EVENT_IMPORT_COLUMN_MAP_UPDATED_LOCAL_COMPLETED, { key: mappedFieldSchema.key })
}

function isLibraryColumn(params) {
  return params.data.isLibraryList || params.data.isViewList
}

function hasDefaultValue(params) {
  return !isEmptyNullOrUndefined(params.data.fieldSchema?.defaultValue)
}

/**
 * Get column info text
 */
function getFieldInfoText(params) {
  return params.data.fieldSchema?.info
}

/**
 * Get column type
 */
function getFieldType(params) {
  const fieldSchema = params.data?.importColumn?.fieldSchema || params.data.fieldSchema
  const { type, format } = fieldSchema
  if (type === 'Currency') {
    return `${type} (${format.isoCode})`
  }
  return type
}

/**
 * Is column editable
 */
function isColumnEditable({ data }) {
  return data.column.isEditable && data.fieldSchema.isEditable
}

/**
 * Is field deletable
 */
function isFieldDeletable({ data }) {
  return data.column.isDeletable && data.fieldSchema.isDeletable
}

/**
 * Sort handler
 */
function onSort(params) {
  const { data } = params
  const currentSortOrder = params.column.sort
  if (!currentSortOrder || currentSortOrder === COLUMN_SORT_DESC) {
    return dispatch(EVENT_COLUMN_SORT_ASC_LOCAL, { 
    columnId: data.column.id,
  })
  }
  return dispatch(EVENT_COLUMN_SORT_DESC_LOCAL, { 
    columnId: data.column.id, 
  })
}

/**
 * Double click handler
 */
function onDoubleClick({ data }) {
  return openFieldEditorUpdate(data.column, data.fieldSchema)

}

/**
 * Hide handler
 */
function onHide({ data }) {
  dispatch(EVENT_COLUMN_HIDE_LOCAL, {
    listId: data.listId,
    columnIds: [data.column.id],
  })
}

/**
 * Update pin handler
 */
function onUpdatePin({ data }) {
  const pinField = !data.column.isPinned
  const columnIds = [data.column.id]
  const colIds = {
    columnIds: columnIds
  }
  if (pinField) {
    return dispatch(EVENT_COLUMN_PIN_LOCAL, colIds)
  }
  return dispatch(EVENT_COLUMN_UNPIN_LOCAL, colIds)
}

/**
 * Fit column to content handler
 */
function onFit({ data }) {
  const colWidthArr = {
    listId: data.listId,
    userRole: data.userRole,
    columnIds: [data.column.id]
  }
  dispatch(EVENT_COLUMN_AUTOSIZE_LOCAL, colWidthArr)
}

/**
 * Add filter handler
 */
function onAddFilter(param) {
  const column = param.column.userProvidedColDef.headerComponentParams.data.column
  openSearchMenu()
  onColumnAddFilter(column)
}

/**
 * Add new field handler
 */
function onNew({ column }) {
  return openFieldEditorNew(column.colId)
}

/**
 * Field edit handler
 */
function onEdit({ data }) {
  return isImportMode.value
    ? openFieldEditorUpdateImport(data.importColumn, data.fieldSchema)
    : openFieldEditorUpdate(data.column, data.fieldSchema)

}

/**
 * Edit selected edit handler
 */
function onEditSelected({ data}) {
  dispatch(EVENT_OPEN_CELL_VALUE_EDITOR, data )
}

/**
 * Delete confirmation handler
 */
function onConfirmDelete({ data }) {
  if (!data.column.isDeletable || !data.fieldSchema.isDeletable) {
    console.error('Column is not deletable. Skip.')
    return
  }
  const eventAction = data.isLibraryList 
    ? EVENT_COLUMN_FIELD_SCHEMA_DELETED_LOCAL
    : EVENT_COLUMN_DELETED_LOCAL

  const message = `Are you sure you wish to delete column "${data.fieldSchema.label}"?`
  raiseConfirmDelete(message, () => {
    dispatch(eventAction, {
      column: data.column,
      fieldSchema: data.fieldSchema
    })
})
}

</script>
