<template>
  <q-checkbox
    color="primary"
    v-model="value"
    @update:model-value="(val) => $emit('checkboxUpdated', val)"
    :toggle-indeterminate=true
    :label="props.label"
  />
</template>

<script setup>

// Dependenceis
import { ref, defineProps, defineEmits } from 'vue'

// Props
const props = defineProps(['value', 'label'])

// Refs
const value = ref(props.value)

// Emits
const emit = defineEmits(['checkboxUpdated'])
</script>
