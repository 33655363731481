import script from "./PriceListView.vue?vue&type=script&setup=true&lang=js"
export * from "./PriceListView.vue?vue&type=script&setup=true&lang=js"

import "./PriceListView.vue?vue&type=style&index=0&id=22439f2b&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip,QBadge,QInput,QMenu,QList,QItem,QItemSection,QIcon});qInstall(script, 'directives', {ClosePopup});
