
// utils
import { required } from '../../utils/required.mjs'

// constants
import {
  TYPE_DEFAULT
} from '../constants/column-set-types.mjs'


// entities
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'

// actions
import { getTeamFieldSchemas } from '../../field-schema/actions/field-schema-actions.mjs'

/**
 * Clone as static
 * @param {*} columnSetEntity 
 * @param {*} selectedColumnIds 
 */
export const createStaticColumnSet = async (
  listId = required('listId'),
  sourceColumnSet = required('sourceColumnSet'),
  selectedColumns = []
) => {

  const fieldSchemas = await getTeamFieldSchemas(sourceColumnSet.teamId)

  const columnSet = new ColumnSetEntity({
    type: TYPE_DEFAULT,
    listId: listId,
    teamId: sourceColumnSet.teamId,
    isStatic: true,
    columns: []
  })
  columnSet.setCreated().setUpdated()
  const selectedColumnIds = selectedColumns.map(col => col.id) || []

  for (const col of sourceColumnSet.columns ) {
    const fieldSchema = fieldSchemas.find(fs => fs.id === col.fieldSchemaId && !fs.isDeleted)
    if (!fieldSchema || !selectedColumnIds.includes(col.id)) {
      continue
    }
    const newCol = col.cloneAsNew()
    newCol.fieldSchema = fieldSchema

    columnSet.columns.push(newCol.setCreated().setUpdated())
  }
  return columnSet
}