
import { TRUE, FALSE } from '../../constants/types.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import { ADMIN_USER_ROLE, EDITOR_USER_ROLE, VIEWER_USER_ROLE } from '../../permissions/constants/roles.mjs'
import {
  canViewTeamSettings,
  canViewTeamMemberList,
  canChangeTeamName,
  canInviteTeamMember,
  canRemoveTeamInvite,
  canRemoveTeamMember,
  canSetTeamMemberPermission,
  canCreateTeam,
} from '../../ui/ui-states.mjs'


const TEAM_ACTION_SETTINGS = {
  admin : {
    canViewTeamSettings: TRUE,
    canViewTeamMemberList: TRUE,
    canChangeTeamName: TRUE,
    canInviteTeamMember: TRUE,
    canRemoveTeamInvite: TRUE,
    canRemoveTeamMember: TRUE,
    canSetTeamMemberPermission: TRUE,
    canCreateTeam: FALSE,
   },
   editor : {
    canViewTeamSettings: TRUE,
    canViewTeamMemberList: TRUE,
    canChangeTeamName: FALSE,
    canInviteTeamMember: FALSE,
    canRemoveTeamInvite: FALSE,
    canRemoveTeamMember: FALSE,
    canSetTeamMemberPermission: FALSE,
    canCreateTeam: FALSE,
   },
   viewer : {
    canViewTeamSettings: TRUE,
    canViewTeamMemberList: FALSE,
    canChangeTeamName: FALSE,
    canInviteTeamMember: FALSE,
    canRemoveTeamInvite: FALSE,
    canRemoveTeamMember: FALSE,
    canSetTeamMemberPermission: FALSE,
    canCreateTeam: TRUE,
   },
   default: {
    canViewTeamSettings: TRUE,
    canViewTeamMemberList: FALSE,
    canChangeTeamName: FALSE,
    canInviteTeamMember: FALSE,
    canRemoveTeamInvite: FALSE,
    canRemoveTeamMember: FALSE,
    canSetTeamMemberPermission: FALSE,
    canCreateTeam: TRUE,
   }
}

function getTeamUiActionPermissions(userRole) {
  switch (userRole?.toLowerCase()) {
    case ADMIN_USER_ROLE.toLowerCase():
      return TEAM_ACTION_SETTINGS.admin
    case EDITOR_USER_ROLE.toLowerCase():
      return TEAM_ACTION_SETTINGS.editor
    case VIEWER_USER_ROLE.toLowerCase():
      return TEAM_ACTION_SETTINGS.viewer
    default:
      return TEAM_ACTION_SETTINGS.default
  }
}

/**
 * Set Team UI action permission by user role
 * @param {string} [userRole] - specify Admin, Editor, Viewer. Default is None
 * @returns {Object} permssions
 */
function setTeamUiActionPermissionStates(userRole = CHAR_EMPTY) {
  const permissions = getTeamUiActionPermissions(userRole)
  canViewTeamSettings.value = permissions.canViewTeamSettings
  canViewTeamMemberList.value = permissions.canViewTeamMemberList
  canChangeTeamName.value = permissions.canChangeTeamName
  canInviteTeamMember.value = permissions.canInviteTeamMember
  canRemoveTeamInvite.value = permissions.canRemoveTeamInvite
  canRemoveTeamMember.value = permissions.canRemoveTeamMember
  canSetTeamMemberPermission.value = permissions.canSetTeamMemberPermission
  canCreateTeam.value = permissions.canCreateTeam
  return permissions
}

export { setTeamUiActionPermissionStates }
