import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import { isEmulator, useAuthEmulator } from './emulators.mjs'

export const firebaseConfig = {
  apiKey: "AIzaSyA59cBfB3BS9wAM-Wy1xmj8WeeA15iUVtw",
  authDomain: "product-engine-0.firebaseapp.com",
  projectId: "product-engine-0",
  storageBucket: "product-engine-0.appspot.com",
  messagingSenderId: "935190759722",
  appId: "1:935190759722:web:14b7b6363ade7466f63653",
  measurementId: "G-NN7XLHPB5G"
}

function getFirebaseAuth(app) {
  app = app || getApp()
  const auth = getAuth(app)
  if (isEmulator()) {
    useAuthEmulator(auth)
  }
  return auth
}

export { getFirebaseAuth }