<template>
  <q-dialog v-model="isFieldEditorOpen" @before-show="onFieldEditorOpen">
    <q-card style="border-radius: 20px; width: 24rem;">
      <q-form @submit.prevent="onSaveCurrentColumn" ref="editorForm">

        <!-- Modal title -->
        <q-card-section style="padding: 2rem 2rem 0;">
          <q-input v-if="isEditingLabel || !fieldEditorFieldSchema.label.length" 
            v-model="fieldEditorFieldSchema.label" color="black" bg-color="accent" outlined class="rounded-borders q-mx-none text-h6" 
            :autofocus="true" dense 
            @keypress="isEditingLabel = true"
            @blur="isEditingLabel = !isEditingLabel" 
            placeholder="New Column Label" />
          <span v-else class="text-h6" @click="isEditingLabel = !isEditingLabel">{{ fieldEditorFieldSchema.label }}</span>
          <q-btn v-if="!isEditingLabel && fieldEditorFieldSchema.label?.length" 
            @click="isEditingLabel = !isEditingLabel" 
            style="margin-top: -6px;" size="sm" flat round icon="edit">
            <q-tooltip>Edit column label</q-tooltip>
          </q-btn>
        </q-card-section>
        <!--/ Modal title -->

        <q-card-section style="max-height: 60vh; overflow: auto; min-height: 200px; padding: 1rem 2rem;">
          <div class="text-weight-medium q-mt-sm">Column Key</div>
            <div>
              ${{ fieldEditorFieldSchema?.key }} 
              <q-btn v-if="fieldEditorFieldSchema?.key?.length" size="xs" @click="onCopyText('$' + fieldEditorFieldSchema?.key)" flat round icon="o_copy" class="text-grey-7">
              <q-tooltip>Copy to clipboard</q-tooltip>
            </q-btn>
            <small class="text-grey-6" v-if="isCopied">Copied!</small>
            <b v-if="errorMsg" class="text-weight-medium q-ml-sm text-red">&#9888; {{ errorMsg }}</b>

          </div>

          <div class="text-weight-medium q-mt-sm">Column Type</div>
          <q-select @update:model-value="changeCurrentFieldType" v-model="fieldEditorFieldSchema.type" dense :options-dense="true" color="black" bg-color="accent" outlined class="rounded-borders q-mt-sm" :options="FIELD_TYPES" />

          <!-- Currency selector -->
          <template v-if="fieldEditorFieldSchema?.type === FIELD_TYPE_CURRENCY">
            <div class="text-weight-medium q-mt-sm">Currency</div>
            <q-select v-model="fieldEditorFieldSchema.format.isoCode" emit-value dense color="black" bg-color="accent" outlined class="rounded-borders q-mt-sm" :options="CURRENCY_ISO_CODES" />
          </template>

          <!-- Information value input-->
          <div class="text-weight-medium q-mt-sm">Information</div>
          <q-input v-model="fieldEditorFieldSchema.info" color="black" bg-color="accent" maxlength="255" outlined class="rounded-borders q-mt-sm" placeholder="Information" dense />
          <div v-if="fieldEditorFieldSchema.info.length && fieldEditorFieldSchema.info.length < 255" class="text-negative text-weight-medium q-mt-xs">{{255 - fieldEditorFieldSchema.info.length}} characters left</div>

          <!-- Default Value input -->
          <div class="text-weight-medium q-mt-sm">Default Value</div>
          <template v-if="!isFormulaMode">
            <!-- Text, multi-line, number, currency, percentage, date field -->
            <q-input 
              v-if="![FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST].includes(fieldEditorFieldSchema.type)" 
              v-model="fieldEditorFieldSchema.defaultValue" 
              :type="inputType" 
              color="black" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              placeholder="[None]" 
              dense
            />
            <!-- Taglist field -->
            <q-select 
              v-if="[FIELD_TYPE_TAGLIST].includes(fieldEditorFieldSchema.type)" 
              v-model="fieldEditorFieldSchema.defaultValue" 
              color="primary" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              use-input 
              use-chips 
              multiple 
              hide-dropdown-icon 
              input-debounce="0" 
              new-value-mode="add-unique" 
              dense
            />
            <!-- Checkbox Field -->
            <p11e-checkbox 
              v-if="[FIELD_TYPE_CHECKBOX].includes(fieldEditorFieldSchema.type)"
              v-model="fieldEditorFieldSchema.defaultValue"
              :value="fieldEditorFieldSchema.defaultValue"
              :label="fieldEditorFieldSchema.defaultValue === true ? DEFAULT_TRUE_VALUE
                    : fieldEditorFieldSchema.defaultValue === false ? DEFAULT_FALSE_VALUE
                    : EMPTY_VALUE_TEXT" 
            />
          </template>
          <!-- Formula mode -->
          <template v-if="isFormulaMode">
            <q-input 
              v-if="![FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST].includes(fieldEditorFieldSchema.type)" 
              v-model="fieldEditorFieldSchema.defaultValue" 
              color="black" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              placeholder="[Enter Formula]" 
              dense
            />
          </template>
          <q-checkbox @update:model-value="onFormulaModeChecked" v-model="isFormulaMode" class="q-mt-sm" label="Formula Mode" color="primary" />

          <!-- Empty Placeholder input -->
          <div class="text-weight-medium q-mt-sm">Empty Placeholder</div>
          <q-input v-model="fieldEditorFieldSchema.placeholderText" color="black" bg-color="accent" outlined class="rounded-borders q-mt-sm"  placeholder="[Empty]" dense />

          <!-- Number, Currency, Percentage decimal -->
          <template v-if="fieldEditorFieldSchema.type === FIELD_TYPE_NUMBER || fieldEditorFieldSchema.type === FIELD_TYPE_CURRENCY || fieldEditorFieldSchema.type === FIELD_TYPE_PERCENTAGE">
            <div class="text-weight-medium q-mt-sm">Decimal places</div>
            <div class="row items-center justify-between no-wrap q-pr-xs" style="margin-top: .8rem; width: 8rem;">
              <q-btn
                @click="fieldEditorFieldSchema.format.decimalPlaces--"
                :disable="fieldEditorFieldSchema.format.decimalPlaces <= 0"
                icon="remove"
                class="bg-primary text-white"
                size="sm"
                flat round 
              />
              {{ fieldEditorFieldSchema.format.decimalPlaces }}
              <q-btn
                @click="fieldEditorFieldSchema.format.decimalPlaces++"
                :disable="fieldEditorFieldSchema.format.decimalPlaces >= 10"
                icon="add"
                class="bg-primary text-white"
                size="sm"
                flat round 
              />
            </div>
          </template>

          <!-- Multi-line -->
          <template v-if="fieldEditorFieldSchema.type === FIELD_TYPE_TEXT">
            <q-checkbox @update:model-value="onCurrentFieldTypeMultiLine" v-model="fieldEditorFieldSchema.format.isMultiLine" class="q-mt-sm" label="Allow multiline" color="primary" />
          </template>

        </q-card-section>

        <q-separator />

        <q-card-actions align="right" style="padding: 1rem;">
          <q-btn
            no-caps
            unelevated
            class="rounded-borders"
            color="accent"
            text-color="dark"
            label="Cancel"
            style="width: 6.3rem;"
            v-close-popup
          />
          <q-btn
            :disable="!validateFieldKey() || !fieldEditorFieldSchema.label?.length"
            no-caps
            unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Done"
            style="width: 6.3rem;"
            v-close-popup
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script setup>

// Dependencies
import { ref } from 'vue'
import { copyTextToClipboard } from '../../utils/index.mjs';

// utils
import { isFormula } from '../../utils/is.mjs'
import { generateFieldKeyFromLabel } from '../../field-schema/utils/field-schema-utils.mjs'

// Constants
import {
  FIELD_TYPES,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_PERCENTAGE,  
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  EMPTY_VALUE_TEXT,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
} from '../../field-schema/field-schema-constants.mjs'

import { CURRENCY_ISO_CODES } from '../../constants/currency.mjs';

// Actions
import { isFieldEditorOpen, fieldEditorInputType, teamFieldSchemas } from '../../ui/ui-states.mjs'
import { 
  isNewColumn, selectedFieldId, fieldEditorColumn, fieldEditorFieldSchema, fieldEditorImportColumn,
  changeCurrentFieldType, onCurrentFieldTypeMultiLine 
} from '../../ui/components/field-editor-ui.mjs'

// events
import { dispatch } from '../../events/dispatch.mjs'
import { 
  EVENT_COLUMN_FIELD_SCHEMA_NEW_LOCAL, 
  EVENT_FIELD_SCHEMA_UPDATED_LOCAL 
} from '../../events/constants/event-types.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'


// Refs
const isCopied       = ref(false)
const editorForm     = ref(null)
const isEditingLabel = ref(false)
const inputType      = fieldEditorInputType
const isFormulaMode    = ref(false)
const errorMsg       = ref('')

function setIsCopied(value = false) {
  isCopied.value = value
}

function validateFieldKey() {
  if (!isNewColumn.value) {
    errorMsg.value = CHAR_EMPTY
    return true
  }
  const fieldSchemaKeys = Object.values(teamFieldSchemas.value)
    .filter(fs => !fs.isDeleted)
    .map(fs => fs.key)
  const key = generateFieldKeyFromLabel(fieldEditorFieldSchema.value.label)
  fieldEditorFieldSchema.value.key = key
  errorMsg.value = fieldSchemaKeys.includes(key) ? 'Key already exist!'
    : CHAR_EMPTY

  return !errorMsg.value
}

/**
 * Copy text to clipboard handler
 */
function onCopyText(text) {
  setIsCopied(true)
  setTimeout(setIsCopied, 2000)
  return copyTextToClipboard(text)
}

/**
 * Save current field handler
 */
function onSaveCurrentColumn() {
  if (isNewColumn.value) {
    dispatch(EVENT_COLUMN_FIELD_SCHEMA_NEW_LOCAL, {
      column: fieldEditorColumn.value,
      fieldSchema: fieldEditorFieldSchema.value,
      importColumn: fieldEditorImportColumn.value,
      afterId: selectedFieldId.value,
    })
  } else {
    dispatch(EVENT_FIELD_SCHEMA_UPDATED_LOCAL, {
      column: { ...fieldEditorColumn.value, fieldSchema: undefined },
      fieldSchema: fieldEditorFieldSchema.value,
      importColumn: fieldEditorImportColumn.value
    })  
  }
}

function onFieldEditorOpen() {
  isFormulaMode.value = isFormula(fieldEditorFieldSchema.value.defaultValue)
}

function onFormulaModeChecked () {
  return isFormulaMode.value = true
}

</script>
