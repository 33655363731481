/**
 * Salesforce publish configuration UI states
 */
import { triggerRef } from 'vue'

import { getTeamSession } from '../../../session/team-session.mjs'

// states
import {
  integrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import {
  currentIntegrationId,
  lookupJsonConfig,
  integrationDescription,
  peFields,
  lookupJsonConfigDataTable,
  lookupJsonEditor,
  newJsonEditorState,
} from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'

// sesssion events
import { isSessionEvent } from '../../../events/session-events.mjs'

// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_IMPORT_BY_QUERY } from '../../registered-apps.mjs'
import { FIELD_TYPES } from '../../../field-schema/field-schema-constants.mjs'

//actions
import { getCurrentTeamFieldSchemas } from '../../../field-schema/actions/field-schema-actions.mjs'

import { getIntegrationApp } from '../../../integration-modules/utils/integration-apps-utils.mjs'


/**
 * 
 */
export const clearImportByQueryConfigurationUiStates = async () => {
  const team = getTeamSession()
  const integrationDetails = await getIntegrationApp(INTEGRATION_SALESFORCE, APP_SFDC_IMPORT_BY_QUERY)
  integrationName.value = integrationDetails?.defaultName
  integrationDescription.value = integrationDetails?.name
  lookupJsonConfig.value = []
}

/**
 * Set import configuraiton ui states
 * @param {*} integration 
 */
export const setImportByQueryConfigurationUiStates = async (integration) => {
  integrationDescription.value = integration.description
  const lookupJC = integration.configuration.lookupJson
    ? JSON.parse(integration.configuration.lookupJson)
    : []
  peFields.value = await setPeFields(true)

  lookupJsonConfigDataTable.value = lookupJC.map(jsonData => {
    if (!jsonData.rows) return null
    const result = jsonData.rows.map(row => {
      const obj = {}
      jsonData.columns.forEach((column, idx) => {
        obj[column] = row[idx]
      })
      return obj
    })
    return result
  })
  lookupJsonEditor.value = createJsonEditorUiState(lookupJC)
  lookupJsonConfig.value = lookupJC
}

/**
 * Create the JSON editor/UI toggle settings. To toggle between JSON editor or UI editor.
 * @param {Object[]} jsonConfig - Mapping or Lookup JSON configuration
 * @returns {Object[]} jsonEditorUi setting
 */
const createJsonEditorUiState = (jsonConfig) => {
  return jsonConfig.map((obj) => {
    const { name } = obj
    const jsonEditorUiSetting = {}
    if (name) {
      jsonEditorUiSetting.jsonEditor = newJsonEditorState()
    }
    return jsonEditorUiSetting
  })
}

/**
 * On Integration Update
 * @param {*} event 
 * @returns 
 */
export const onLocalSalesforceImportByQueryConfigUpdated = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setImportByQueryConfigurationUiStates(integration)
}

export const onRemoteSalesforceImportByQueryConfigUpdated = (event) => {
  const integration = event.detail
  if (isSessionEvent(integration.updatedByEventId)) {
    return
  }
  if (currentIntegrationId.value !== integration.id) {
    return
  }
  setImportByQueryConfigurationUiStates(integration)
}

export const onLocalSalesforceImportByQueryConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setImportByQueryConfigurationUiStates(integration)
}

export const onRemoteSalesforceImportByQueryConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setImportByQueryConfigurationUiStates(integration)
}


export const createImportByQueryConfigurationFromUi = () => {
  return {
    "lookupJson": JSON.stringify(lookupJsonConfig.value || []),
  }
}

/**
 * Set PE product fields
 * @param {boolean} force - force set
 */
const setPeFields = async (force = false) => {
  if (force || !peFields.value || peFields.value.length === 0) {
    const teamFields = await getCurrentTeamFieldSchemas()
    return teamFields
      .filter(fs => !fs.isDeleted || FIELD_TYPES.includes(fs.type))
      .map(field => { return { key: field.key, label: field.label } })
      .sort((a, b) => a.label.localeCompare(b.label))
  }  
}

