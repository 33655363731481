<template>

  <!-- Cell container -->
  <div class="ag-cell-label-container" @click="onNew">
    <!-- Label -->
    <div class="ag-header-cell-label q-pl-md ag-new-cell">
      <span class="ag-header-cell-text">{{ FIELD_LABEL_NEW_COLUMN }}</span>
    </div>
  </div>
  <!-- End Cell container `-->

</template>

<script setup>
// Styles
import '@/styles/sheet.css'

import { FIELD_LABEL_NEW_COLUMN } from '@/constants'
import { openFieldEditorNew } from '../../ui/components/field-editor-ui.mjs'

const IS_NEW = true

/**
 * Add new field handler
 */
function onNew() {
  return openFieldEditorNew(null)
}

</script>
