/**
 * Character constants
 */

export const CHAR_EMPTY     = ''
export const CHAR_STOP      = '.'
export const CHAR_COMMA     = ','
export const CHAR_DOLLAR    = '$'
export const CHAR_PERCENT   = '%'
export const CHAR_AT        = '@'
export const CHAR_AMPERSAND = '&'
export const CHAR_PLUS      = '+'
export const CHAR_MINUS     = '-'
export const CHAR_SPACE     = ' '
