/*!
 *  list db functions
 */

import { ListEntity } from '../../entity/ListEntity.mjs'
import { ListDb } from './ListDb.mjs'
import { saveItemsEntities } from '../../item/db/item-db.mjs'
import { saveFieldSchemaEntities } from '../../field-schema/db/field-schema-db.mjs'
import { saveColumnSetEntity } from '../../column-set/db/column-set-db.mjs'
import { getCreatedByMePriceListQuery, getSharedWithMePriceListQuery } from './list-db-utils.mjs'
import { getUserEmail } from '../../user/actions/user-actions.mjs'
import { getTeamSession } from '../../session/team-session.mjs'
import { saveListCsvItems } from '../../storage/list-file-storage.mjs'

import { CSV_PRICE_LIST, LIBRARY_LIST } from '../../list-core/constants/list-constants.mjs'

// events
import { EVENT_ITEMS_SAVED, EVENT_ITEMS_DELETED, EVENT_ITEMS_REMOVED } from '../../events/constants/event-types.mjs'
import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

const db = new ListDb()

/**
 * Get list by id
 * @param {*} id 
 * @returns ListEntity
 */

// TODO: rename all getListById to fetechListById
async function getListById(id) {
  return fetchListById(id)
}

async function fetchListById(id) {
  const list = await db.getById(id)
  if (!list) {
    return null
  }
  const createdByUserEmail = await getUserEmail(list.createdBy)
  const updatedByUserEmail = await getUserEmail(list.updatedBy)

  const newListEntity = new ListEntity(list)
  newListEntity.setCreatedByUserEmail(createdByUserEmail)
  newListEntity.setUpdatedByUserEmail(updatedByUserEmail)
  return newListEntity
}

async function saveList(listEntity) {
  // save list, columnSet and field schemas
  // TODO: put list, columns and field schemas in 1 batch

  //validate before save
  listEntity.validate()
  return Promise.all(
    [
      saveListEntity(listEntity),
      saveColumnSetEntity(listEntity.columnSet),
      (!listEntity.isStatic
        ? saveFieldSchemaEntities(listEntity.columnSet.getFieldSchemas())
        : undefined),
      (listEntity.importColumnSet
        ? saveColumnSetEntity(listEntity.importColumnSet)
        : undefined
      )

    ]
  )
}

async function getCreatedByMePriceList() {
  return await db.getByConditions(getCreatedByMePriceListQuery())
}

async function getSharedWithMePriceLists() {
  return await db.getByConditions(getSharedWithMePriceListQuery())
}

async function saveListAndItems(listEntity) {
  // TODO: put list, columns and field schemas in 1 batch
  console.log('saveListAndItems called - no of items', listEntity.items.length, ' isStatic =', listEntity.isStatic)
  //validate before save
  listEntity.validate()
  const event = createTeamEvent(EVENT_ITEMS_SAVED)
  await (listEntity.type === CSV_PRICE_LIST)
    ? saveListCsvItems(listEntity)
    : saveItemsEntities(listEntity.items, {
      event,
      onCompleted: () => { emitEvent(event) }
    })

  return Promise.all(
    [
      saveListEntity(listEntity),
      saveColumnSetEntity(listEntity.columnSet),
      (!listEntity.columnSet.isStatic
        ? saveFieldSchemaEntities(listEntity.columnSet.getFieldSchemas())
        : undefined),

      (listEntity.importColumnSet
        ? saveColumnSetEntity(listEntity.importColumnSet)
        : undefined
      )
    ]
  )
}

function saveListEntity(listEntity) {
  const saveObject = db.createDocObject(listEntity)
  return db.upsert(saveObject)
}

async function getCurrentLibraryList() {
  const currentTeam = getTeamSession()
  if (!currentTeam || !currentTeam.libraryListId) {
    console.error('No current team or missing libraryListId')
    return
  }
  return getListById(currentTeam.libraryListId)
}

function saveListColumn(listEntity, columnEntity) {
  //validate before save
  listEntity.validate()
  return Promise.all(
    [
      (!listEntity.isStatic
        ? saveFieldSchemaEntities([columnEntity.fieldSchema])
        : undefined),
      (listEntity.columnSet
        ? saveColumnSetEntity(listEntity.columnSet)
        : undefined
      )
    ]
  )
}

async function fetchTeamLibraryLists(teamId) {
  return db.getByType({
    type: LIBRARY_LIST,
    teamId,
    isDeleted: false
  })
}

export {
  fetchListById,
  getListById, getCreatedByMePriceList, getSharedWithMePriceLists,
  saveList, saveListAndItems, saveListEntity, saveListColumn,
  getCurrentLibraryList, fetchTeamLibraryLists,
}
