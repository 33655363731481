import { DEFAULT_CURRENCY_ISO_CODE, POA } from '@/constants/currency.mjs'
import { DEFAULT_LOCALE } from '@/constants/locale.mjs'

import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  DEFAULT_NUMBER_DP,
  DEFAULT_PERCENTAGE_DP,
  DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
  EMPTY_VALUE_TEXT,
  DEFAULT_IS_TEXT_MULTI_LINE
} from '@/field-schema/field-schema-constants.mjs'

export function makeCreateFormat(type) {
  switch (type) {
    case FIELD_TYPE_PERCENTAGE:
      return createPercentageFormat

    case FIELD_TYPE_NUMBER:
      return createNumberFormat

    case FIELD_TYPE_CURRENCY:
      return createCurrencyFormat

    case FIELD_TYPE_TAGLIST:
      return createTagListFormat

    case FIELD_TYPE_CHECKBOX:
      return createCheckboxFormat

    case FIELD_TYPE_DATE:
      return createDateFormat

    default:
      return createTextFormat
  }
}

/**
 * Make number format
 */
function createNumberFormat({
  decimalPlaces = DEFAULT_NUMBER_DP
} = {}) {
  return {
    decimalPlaces
  }
}

/**
 * Make currency format
 */
function createCurrencyFormat({
  isoCode = DEFAULT_CURRENCY_ISO_CODE,
  noPriceValue = POA, // TODO: get the actual default value from the settings
  ...props
} = {}) {
  return {
    isoCode,
    noPriceValue,
    ...createNumberFormat(props)
  }
}

/**
 * Make percentge format
 */
function createPercentageFormat({
  decimalPlaces = DEFAULT_PERCENTAGE_DP,
  ...props
} = {}) {
  return {
    ...createNumberFormat({
      ...props,
      decimalPlaces
    })
  }
}

/**
 * Make date time format
 */
function createDateFormat({
  dateFormat = DEFAULT_LOCALE,
  timeFormat = DEFAULT_LOCALE,
} = {}) {
  return {
    dateFormat, timeFormat
  }
}

/**
 * Make taglist format
 */
function createTagListFormat({
  dataType = DEFAULT_TEXT_TYPE,
} = {}) {
  return {
    dataType
  }
}

/**
 * Make text format
 */
function createTextFormat({
  isMultiLine = DEFAULT_IS_TEXT_MULTI_LINE,
  textType = DEFAULT_TEXT_TYPE,
} = {}) {
  return {
    isMultiLine, textType
  }
}

/**
 * Make checkbox format
 */
function createCheckboxFormat({
  trueValue = DEFAULT_TRUE_VALUE,
  falseValue = DEFAULT_FALSE_VALUE,
  emptyValue = EMPTY_VALUE_TEXT
} = {}) {
  return {
    trueValue, falseValue, emptyValue
  }
}

/**
 * Make file format
 */
function createFileFormat() {
  return {}
}
