/*!
 * User listener
 */

import { DbListener } from '@/db'
import { COLLECTION_USERS } from '@/db/db-collections.mjs'
import { required } from '@/utils/required.mjs'

export class UserDbListener extends DbListener {
  constructor({
    collectionName = COLLECTION_USERS,
    queryConditions = required('queryConditions'),
    addedFunction,
    modifiedFunction,
    removedFunction,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      ...props
    })
  }
}
