/*!
 * Item compilation
 */
// utils
//import functions from 'firebase-functions'

// Dependencies
import * as formulajs from '@formulajs/formulajs'

// utils
import { required } from '../../utils/required.mjs'
import {
  isEmptyNullOrUndefined, isEmpty, isNumber, isCurrency, isPercentage, isCheckbox, isTaglist, isFormula, isDate,
} from '../../utils/is.mjs'
import {
  parseNumber, parseCurrency, parseBoolean, parseTaglist, parseDateInternational,
} from '../../utils/parse.mjs'

import {
  ADDMARGIN, PRICEMARGIN,
  ADDMARKUP, PRICEMARKUP,
  PROFIT, MARGIN, MARKUP, CONTAINS,
  AI, FETCHTEXT, FETCHJSON, FETCHDAILYFXRATE,
  GENERATEPRODUCTDESC
} from '../../formulas/formulas.mjs'

// Constants
import { MAX_COMPILE_LOOPS } from '../constants/compile-constants.mjs'

// Field Types
import {
  FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST, FIELD_TYPE_TEXT, FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER, FIELD_TYPE_CURRENCY, FIELD_TYPE_PERCENTAGE, FIELD_TYPES
} from '../../field-schema/field-schema-constants.mjs'


// formatting
import { formatItemValues } from './format-item-values.mjs'
import { makeFormatCompileInput } from '../../field-schema/utils/make-field-format-compile-input.mjs'
//const logger = functions.logger


/**
 * Setup available formulas
 */
export const formulas = {
  ...formulajs, // includes the formulajs library

  // includes the custom formulas
  ADDMARGIN,
  PRICEMARGIN,
  ADDMARKUP,
  PRICEMARKUP,
  PROFIT,
  MARGIN,
  MARKUP,
  CONTAINS,
  //  FETCHTEXT, FETCHJSON,
  FETCHDAILYFXRATE,
  GENERATEPRODUCTDESC
}

/**
 * Calculate value
 */
const calculate = (
  key = required('key'),
  fields = required('fields'),
  field = fields[key],
  value = field?.compileInput,
  formula = value?.trim().substring(1),
) => {
  const fieldValues = {}
  for (const key in fields) {
    fieldValues['$' + key] = fields[key]?.value || null // TODO: drop the $ in favor of being created on the key
  }
  // TODO: This needs to have a secure context and should be run inside a worker
  // TODO: drop the $ in favor of being created on the key
  const out = (Function(`
    const alert      = undefined;
    const confirm    = undefined;
    const window     = undefined;
    const document   = undefined;
    const globalThis = undefined;
    const AI         = this.AI;
    const { ${Object.keys(fields).map(f => '$' + f).join(', ')} } = this.fieldValues;
    const { ${Object.keys(formulas).join(', ')} } = this.formulas;
    return ${formula};
  `)).call({ formulas, fieldValues })
  return out
}

/**
 * Set formula variables
 */
const setFormulaVars = (field) => {
  if (!field.compileInput || !isFormula(field.compileInput)) return field
  field.formulaVars = (field.compileInput
    .match(/\$(\w+)/g) || [])
    .map(function (v) {
      return v.trim().substring(1)
    })
  return field
}

/**
 * Validate formula variables
 */
function validateFormulaVariables(fields, key) {
  const field = fields[key]
  if (!field || !field.formulaVars) {
    return true
  }
  // self reference detection
  if (field.formulaVars.includes(key)) {
    field.error = 'Self Reference Detected'
  }
  // field reference doesn't exist
  for (const key of field.formulaVars) {
    if (!fields[key] || fields[key].error) {
      field.error = field.error || 'Invalid Reference ($' + key + ')'
    }
  }
  return !field.error
}


/**
 * Optimise compile order
 */
const optimiseCompileOrder = (compileOrder) => {
  const keys = [...compileOrder] || []
  let optimisedOrder = []
  const len = keys.length
  for (let i = 0; i < len; i++) {
    const key = keys.shift()
    if (keys.find(k => k === key)) continue
    optimisedOrder = [...optimisedOrder, key]
  }
  return optimisedOrder
}

/**
 * Generate compile order
 */
const generateCompileOrder = (
  fields = required('fields'),
  keys = [],
  sequence = [],
  depth = 0,
) => {
  //TODO: remove recursive call for better performance
  if (depth > MAX_COMPILE_LOOPS) {
    fields[sequence[sequence.length - 1]].error = 'Max compile loop reached. Possible circular formula references.'
    console.error('Max compile loop (' + MAX_COMPILE_LOOPS + ') reached. Possible circular references.')
    return optimiseCompileOrder(sequence)
  }
  for (const key of keys) {
    sequence = [...sequence, key]
    if (!fields[key] || fields[key].error || fields[key].warning) {
      continue
    }
    const children = fields[key].children || []
    if (children.length > 0) {
      sequence = [...sequence, ...generateCompileOrder(fields, children, sequence, ++depth)]
    }
  }
  return optimiseCompileOrder(sequence)
}

/**
 * set compile input
 */
const setCompileInput = (field, fieldSchema) => {
  if (!field) return null
  const formatCompileInput = makeFormatCompileInput(fieldSchema.type)
  field.compileInput = !isEmpty(field.inputValue) ? field.inputValue : field.defaultValue
  if (isEmpty(field.compileInput)) {
    return
  }

  // parse value to the correct format for compilation
  switch (fieldSchema.type) {
    case FIELD_TYPE_CHECKBOX:
      if (isCheckbox(field.compileInput)) {
        field.compileInput = parseBoolean(field.compileInput)
      }
      break
    case FIELD_TYPE_TAGLIST:
      if (isTaglist(field.compileInput)) {
        field.compileInput = parseTaglist(field.compileInput)
      }
      break
    case FIELD_TYPE_PERCENTAGE:
      if (isPercentage(field.compileInput)) {
        field.compileInput = formatCompileInput(field.compileInput)
      }
      break
    case FIELD_TYPE_CURRENCY:
      if (isCurrency(field.compileInput)) {
        field.compileInput = parseCurrency(field.compileInput)
      }
      break
    case FIELD_TYPE_NUMBER:
      if (isNumber(field.compileInput)) {
        field.compileInput = parseNumber(field.compileInput)
      }
      break
    case FIELD_TYPE_DATE:
      if (isDate(field.compileInput)) {
        field.compileInput = parseDateInternational(field.compileInput)
      }
      break
    case FIELD_TYPE_TEXT:
      field.compileInput = field.compileInput.toString()
      break
  }
}


const createCompileFieldFromSchema = (schema) => {
  return {
    defaultValue: schema.defaultValue,
    inputValue: schema.inputValue,
    format: { ...schema.format },
    placeholderText: schema.placeholderText,
    fieldType: schema.type,
    error: null,
    warning: null,
  }
}

const createCompiledFields = (fields) => {
  const compiledFields = {}
  for (const key in fields) {
    const {
      inputValue,
      value,
      formattedValue,
      error,
      warning
    } = fields[key]
    compiledFields[key] = {
      inputValue,
      value,
      formattedValue,
      error,
      warning
    }
  }
  return compiledFields
}

/**
 * Compile one item
 */
export const compileItem = ({
  item = required('item'),
  keys,
  fieldSchemas
}) => {
  const isCompileAll = !keys || (keys.length === 0) || !!Object.values(item.fields).find(f => f.error || f.warning)
  let compileOrder = []
  let fields = {}
  const activeKeys = []
  let changedKeys = keys ? [...keys] : []

  //  new fields based on current columns and schemas
  for (const fieldSchema of fieldSchemas) {
    if (fieldSchema.isDeleted) {
      continue
    }
    // exclude invalid types
    if (!FIELD_TYPES.includes(fieldSchema.type)) {
      continue
    }
    const key = fieldSchema.key
    fields[key] = createCompileFieldFromSchema(fieldSchema)
    if (item.fields[key]) {
      fields[key].fieldSchemaId = fieldSchema.id
      fields[key].inputValue = item.fields[key].inputValue
      fields[key].value = item.fields[key].value
    }
    // add fields that has no value to the compile key list
    if (
      !isCompileAll
      && fields[key].defaultValue
      && isEmptyNullOrUndefined(fields[key].value)
      && !changedKeys.includes(key)
    ) {
      changedKeys.push(key)
    }
    activeKeys.push(key)
  }

  const schemaMap = new Map()
  // make sure the item fields is deleted if the key is no longer used
  for (const fieldSchema of fieldSchemas) {
    if (fieldSchema.isDeleted) {
      if (!activeKeys.includes(fieldSchema.key)) {
        item.deleteField(fieldSchema.key)
      } item
      continue
    }
    schemaMap.set(fieldSchema.key, fieldSchema)
  }

  // pre compile 
  // set reset warnings and errors
  let compileKeys = isCompileAll ? Object.keys(fields)
    : (changedKeys.length > 0) ? changedKeys
      : Object.keys(fields)

  for (const key in fields) {
    if (!fields[key]) continue
    setCompileInput(fields[key], schemaMap.get(key))
    fields[key] = setFormulaVars(fields[key])
    if (fields[key].formulaVars) {
      for (const parentKey of fields[key].formulaVars) {
        if (!fields[parentKey]) {
          continue
        }
        fields[parentKey].children = [...fields[parentKey].children || [], key]
      }
    }
    // validate field formula reference relationships
    validateFormulaVariables(fields, key)

  }

  compileOrder = generateCompileOrder(fields, compileKeys)
  // compile
  for (const key of compileOrder) {
    if (!fields[key]) {
      continue
    }
    if (fields[key].error || fields[key].warning) {
      continue
    }
    if (item.isStatic) {
      fields[key].value = fields[key].compileInput
    } else {
      try {
        fields[key].value = isFormula(fields[key].compileInput)
          ? calculate(key, fields)
          : fields[key].compileInput
      } catch (e) {
        fields[key].error = 'Invalid Formula: ' + e.message
      }
    }
  }
  return setItemCompiledFields(item, fields)

  // helper
  function setItemCompiledFields(item, fields) {
    const compiledFields = createCompiledFields(fields)
    // - important to set content of each fields instead of replacing the field properties
    // otherwise it will trigger vue update for the whole item if vue. 
    // ie, DO NOT do itemEntity.fields = compiledFields

    for (const key in compiledFields) {
      item.fields[key] = {
        ...compiledFields[key]
      }
    }
    item.setInternalFieldValues()
    return formatItemValues(item, schemaMap)
  }
}
