/**
 * Currency constants
 */

// POA
export const POA = 'POA'

// Currency codes
export const CURRENCY_CODE_AUD = 'AUD'
export const CURRENCY_CODE_EUR = 'EUR'
export const CURRENCY_CODE_USD = 'USD'

// Currency labels
export const STR_CURRENCY_AUD = 'Australian Dollar'
export const STR_CURRENCY_EUR = 'Euro'
export const STR_CURRENCY_USD = 'US Dollar'

// Defaults
export const DEFAULT_CURRENCY_ISO_CODE = CURRENCY_CODE_AUD

// Currency list
export const CURRENCY_ISO_CODES = [
  { value: CURRENCY_CODE_AUD, label: STR_CURRENCY_AUD },
  { value: CURRENCY_CODE_EUR, label: STR_CURRENCY_EUR },
  { value: CURRENCY_CODE_USD, label: STR_CURRENCY_USD }
]
