
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../../db/db-constants.mjs'

const app = getApp()
const CALLABLE_SALESFORCE_INTEGRATION = 'salesforceIntegration'

let salesforceInegrationRpc = null

/**
 * get the salesforce rpc function
 * @returns 
 */
function getSalesforceIntegrationRpc() {
  if (salesforceInegrationRpc) {
    return salesforceInegrationRpc
  }
  const functions = getFunctions(app, AU_REGION)
  salesforceInegrationRpc = httpsCallable(functions, CALLABLE_SALESFORCE_INTEGRATION, {timeout: 540000 } )
  return salesforceInegrationRpc
}

export { getSalesforceIntegrationRpc }