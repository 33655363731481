import script from "./ProgressOverlay.vue?vue&type=script&setup=true&lang=js"
export * from "./ProgressOverlay.vue?vue&type=script&setup=true&lang=js"

import "./ProgressOverlay.vue?vue&type=style&index=0&id=414a34b4&lang=css"

const __exports__ = script;

export default __exports__
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress});
