/**
 * Team session
 */

// Dependencies
import { required, setSessionItem, getSessionItem } from '../utils'

// Constants
const SESSION_KEY = 'team'

/**
 * Set team session
 */
function getTeamSession() {
  return getSessionItem(SESSION_KEY)
}

/**
 * Get team session
 */
function setTeamSession(team = required(SESSION_KEY)) {
  setSessionItem(SESSION_KEY, team)
}

// Exports
export { getTeamSession, setTeamSession }
