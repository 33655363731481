import { ref } from 'vue'
import { sleep } from '../utils'
import { SHOW_WHEN_ABOVE_DEFAULT, CLOSE_DELAY } from '../constants/progress-status.mjs'

import {
  isProgressOpen, progressLabel, progressBody, progressValue,
  progressMaxValue, progressShowWhenAboveValue,
  progressPercentage
} from './ui-states.mjs'

// events
import { EVENT_PROGRESS_START, EVENT_PROGRESS_SET_STATUS } from '../events/constants/event-types.mjs'



// progess style
const PROGRESS_STYLE_TOTAL = 'total'
const PROGRESS_STYLE_PERCENTAGE = 'percentage'
const DEFAULT_SHOW_WHEN_ABOVE = 150

let eventController = null
let progessStyle = PROGRESS_STYLE_TOTAL

const setProgessBody = () => {
  if (progessStyle === PROGRESS_STYLE_TOTAL) {
    progressBody.value = `${progressValue.value} of ${progressMaxValue.value}`
  }
  if (progessStyle === PROGRESS_STYLE_PERCENTAGE) {
    progressBody.value = progressPercentage.value + '%'
  }
  return
}

const startStatus = (event) => {
  const { label = '', style = PROGRESS_STYLE_TOTAL, value = 0, maxValue = 0, showWhenAboveValue = DEFAULT_SHOW_WHEN_ABOVE } = event.detail
  progressLabel.value = label
  progressMaxValue.value = maxValue
  progressShowWhenAboveValue.value = showWhenAboveValue
  progressValue.value = value
  progessStyle = style
  progressPercentage.value = Number(((progressValue.value / progressMaxValue.value) * 100).toFixed(0))
  setProgessBody()
  if ( progressMaxValue.value >  progressShowWhenAboveValue.value) {
    isProgressOpen.value = true
  }
  if (progressValue.value === progressMaxValue.value) {
    stopProgressEvents()
  }
}


const setStatus = (event) => {
  if (!isProgressOpen.value) {
    return
  }
  const { label, value, addValue, maxValue, showWhenAboveValue } = event.detail
  progressLabel.value = label || progressLabel.value
  progressMaxValue.value = maxValue || progressMaxValue.value
  progressShowWhenAboveValue.value = showWhenAboveValue || progressShowWhenAboveValue.value

  progressValue.value = addValue ? (progressValue.value + addValue)
    : value ? value
      : progressValue.value
  if (progressValue.value > progressMaxValue.value) {
    progressValue.value = progressMaxValue.value
  }
  progressPercentage.value = Number(((progressValue.value / progressMaxValue.value) * 100).toFixed(0))

  setProgessBody()
  if ( progressMaxValue.value > progressShowWhenAboveValue.value) {
    isProgressOpen.value = true
  }
  if (progressValue.value === progressMaxValue.value) {
    stopProgressEvents()
}
}

export const startProgressEvents = () => {
  eventController = new AbortController()

  addEventListener(EVENT_PROGRESS_START, startStatus, { signal: eventController.singal })
  addEventListener(EVENT_PROGRESS_SET_STATUS, setStatus, { signal: eventController.singal })
}

export const stopProgressEvents = () => {
  isProgressOpen.value = false
  eventController?.abort()
}

