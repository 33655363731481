import { getListCoreRpc } from '../../db/rpcs/db-rpcs.mjs'
import { ACTION_UPSERT_FIELD_SCHEMA, ACTION_GET_FIELD_SCHEMA } from '../../list-core/constants/action-types.mjs'

export const upsertFieldSchema = async (fieldSchema) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_UPSERT_FIELD_SCHEMA,
    payload: fieldSchema
  })
  return result?.data?.success
}

export const fetchFieldSchema = async (id) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_FIELD_SCHEMA,
    payload: {
      id
    }
  })
  if (result.data?.success) {
    return result.data.result
  }
  console.error ('fetchFieldSchema error:', result.data)
  return result?.data?.success
}
