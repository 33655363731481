import { ref, shallowRef } from 'vue'
import { CHAR_EMPTY } from '../../../constants/characters.mjs'


export const integrationsTab = ref('integrations')
export const testResponse = ref(CHAR_EMPTY)

// connection UI states
export const currentIntegrationId = ref(CHAR_EMPTY)
//export const integrationName = ref(CHAR_EMPTY)
export const username = ref(CHAR_EMPTY)
export const userPassword = ref(CHAR_EMPTY)
export const securityToken = ref(CHAR_EMPTY)
export const instanceUrl = ref(CHAR_EMPTY)
export const integrationDescription = ref(CHAR_EMPTY)


// configuration UI states
export const productFields = ref([])
export const productFieldsMapping = ref([])

//
export const sfProductFields = ref([])
export const customPricebooks = ref([])
export const selectedCustomPricebooks = ref([])

export const stdPricebooks = ref([])
export const selectedStdPricebook = ref('')

export const peFields = ref([])

//Import 
export const selectedSfProductFields = ref([])

// SF Advanced UI states
export const mappingJsonConfig = ref('')
export const lookupJsonConfig = ref('')
export const lookupJsonConfigDataTable = ref([])
export const lookupJsonEditor = ref([])
export const mappingJsonEditor = ref([])

export const newJsonEditorState = () => {
  return { isActive: true, isJsonMode: false }
}