// util
import { required } from '../../utils/required.mjs'

// salesforce integration rpc
import { getAvailableIntegrationApps } from '../../integration-modules/utils/integration-apps-utils.mjs'

// db event
import { DbListener } from '../../db/DbListener.mjs'
import { COLLECTION_INTEGRATIONS } from '../../db/db-collections.mjs'

// session event
import { 
  EVENT_USER_AUTHENTICATED,
  EVENT_INTEGRATION_UPDATED_LOCAL ,
  EVENT_INTEGRATION_UPDATED_REMOTE,
  EVENT_INTEGRATION_DELETED_REMOTE,
  EVENT_USER_SIGNOUT,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

// states
import { availableIntegrations } from '../states/integrations-ui-states.mjs'

// active integrations
import {
  loadActiveIntegrations, clearActiveIntegrations,
  upsertActiveIntegrations, removeActiveIntegrations,
} from '../actions/active-integrations.mjs'

let listener = null




/**
 * upsert integration from db added/changed events
 * @param {*} param0 
 * @returns 
 */
const upsertIntergration = ({ id, data }) => {
  upsertActiveIntegrations(data, false)
  dispatch(EVENT_INTEGRATION_UPDATED_REMOTE, data)
}

/**
 * remove integration from db removed event
 * @param {*} param0 
 */
const removeIntegration = ({ id }) => {
  removeActiveIntegrations(id)
  dispatch(EVENT_INTEGRATION_DELETED_REMOTE, id)
}

/**
 * reset integrations
 */
const resetIntegrations = () => {
  clearActiveIntegrations()
  availableIntegrations.value = []
}




export const startIntegrationsListener = (event) => {
  // TODO: PL - start based on role
  console.log('startIntegrationsListener ===> startIntegrationsListener')

  const { user, team } = event.detail 
  if (!team.id) {
    console.log('User team does not exist.')
    resetIntegrations()
    return
  }
  return startListener(team.id)
}

/**
 * Start integrations listener
 * @param {*} teamId 
 * @returns 
 */
const startListener = async (
  teamId = required('teamId')
  ) => {
  if (isIntegrationsListenerStarted()) {
    console.log('already started. skipped')
    return
  }
  // initialise settings
  resetIntegrations()
  await loadActiveIntegrations(teamId)

  // move this to rpc / firebase db
  availableIntegrations.value = await getAvailableIntegrationApps(teamId)
  
  // start listening for mapping changes
  listener = new DbListener({
    collectionName: COLLECTION_INTEGRATIONS,
    queryConditions: [
      ['teamId', '==', teamId],
      ['isDeleted', '==', false]
    ],
    addedFunction: upsertIntergration,
    modifiedFunction: upsertIntergration,
    removedFunction: removeIntegration,
  })

  listener.start()
  console.log('integrations listener started')
  return
}

/**
 * stop Integration listener
 */
export const stopIntegrationsListener = () => {
  console.log('stopIntegrationsListener called')
  resetIntegrations()
  listener?.stop()
  listener = null
}

/**
 * 
 * @returns 
 */
export const isIntegrationsListenerStarted = () => {
  return !!listener
}

