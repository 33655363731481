/*!
 * Field schema db
 */

// Db
import { Db } from '@/db/Db.mjs'
import { COLLECTION_FIELD_SCHEMAS } from '@/db/db-collections.mjs'
import { required } from '../../utils'


export class FieldSchemaDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_FIELD_SCHEMAS
    })
  }

  /**
   * Get all field schemas by Team Id
   * @param {*} collectionName 
   * @param {*} teamid 
   * @returns 
   */
  async getAllByTeamId(teamId = required('teamid')) {
    const conditionsArr = [
      ['teamId', '==', teamId],
    ]
    return this.getByConditions(conditionsArr)
  }

  validateDocObject({
    id = required('id'),
    teamId = required('teamId'),
    key = required('key'),
    type = required('type'),
    label = required('label'),
    format = required('format'),
  }) {
    return
  }

  createDocObject(fieldSchema) {
    this.validateDocObject(fieldSchema.toObject())
    return {
      type: fieldSchema.type,
      label: fieldSchema.label,
      teamId: fieldSchema.teamId,
      key: fieldSchema.key,
      info: fieldSchema.info,
      format: fieldSchema.format,
      defaultValue: fieldSchema.defaultValue,
      isHidden: fieldSchema.isHidden,
      placeholderText: fieldSchema.placeholderText,
      ...super.createDocObject(fieldSchema)
    }
  }

}


