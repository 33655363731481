<template>
  <pre-layout>
    <div class="fixed-center text-center">
      <q-card class="forgot-card text-dark q-pa-md">
        <q-card-section>
          <div class="text-h5 text-weight-bold">Password Reset</div>
          <div class="text-body2 text-weight-medium text-grey-6 q-mt-sm">Enter the email you used to sign up</div>
        </q-card-section>

        <q-card-section>
          <q-form>
            <q-input v-model="email" color="grey-7" bg-color="accent" outlined dense type="email" placeholder="Email" class="q-mb-sm" />
          </q-form>
          <q-btn 
            unelevated
            size="md"
            no-caps
            @click="onSendResetPasswordEmail"
            class="bg-primary full-width q-pa-sm text-capitalize"
            text-color="white"
            style="border-radius: 5px;"
            label="Reset Password"
          />
          <div class="column justify-center text-weight-medium text-body2 q-mt-md">
            <div class="text-grey-6">
              &larr; Back to <router-link class="text-primary" to="/signin" style="text-decoration: none;">Sign In</router-link>
            </div>
            <router-link class="q-pt-sm text-grey-6" to="/register" style="text-decoration: none;">Create an account</router-link>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </pre-layout>
</template>

<style scoped>

.forgot-card {
  background: white;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  width: 25rem;
}

@media only screen and (max-width: 767px) {
  .forgot-card {
    width: 17rem;
  }
}

</style>

<script setup>

// Layouts
import PreLayout from '../layouts/PreLayout.vue'

// Actions
import { email, sendResetPasswordEmail } from '../actions/current-user.mjs';
import { raiseNotification } from '../notify/index.mjs';

function onSendResetPasswordEmail() {
  sendResetPasswordEmail(email.value)
  raiseNotification(`An email has been sent to ${email.value}`)
}

</script>
