/**
 * Custom Formulas
 */

import { throwError } from '../utils/errors.mjs'
import { validateNumber } from '../utils/validate.mjs'
import { isEmpty } from '../utils/is.mjs'

import { INFINITY } from '../constants/number.mjs'

// calculate price with cost + margin
function ADDMARGIN(cost, rate) {
  if (isEmpty(cost)) {
    return null
  }
  const validCost = validateNumber('Cost', cost)
  const validRate = validateNumber('Rate', rate)
  const calculatedValue = validCost / (1 - validRate)
  return calculatedValue === INFINITY
    ? throwError('A margin of 100% or more is not possible')
    : calculatedValue
}

// calculate price with cost + margin - stub function for the old version
function PRICEMARGIN (cost, rate) {
  return ADDMARGIN(cost, rate)
}

// calculate price with cost + markup
function ADDMARKUP(cost, rate) {
  if (isEmpty(cost)) return null
  const validCost = validateNumber('Cost', cost)
  const validRate = validateNumber('Rate', rate)
  return validCost * (1 + validRate)
}

// calculate price with cost + markup - stub function for the old version
function PRICEMARKUP(cost, rate) {
  return ADDMARKUP(cost, rate)
}

// calculate profit
function PROFIT(cost, revenue, expense) {
  if (isEmpty(cost) || isEmpty(revenue)) return null
  const validCost = validateNumber('Cost', cost)
  const validRevenue = validateNumber('Revenue', revenue)
  if (expense) {
    const validExpense = validateNumber('Expense', expense)
    return validRevenue - validCost - validExpense
  }
  return validRevenue - validCost
}

// calculation margin
function MARGIN(cost, revenue, expense) {
  if (isEmpty(cost) || isEmpty(revenue)) return null
  const validCost = validateNumber('Cost', cost)
  const validRevenue = validateNumber('Revenue', revenue)
  if (validRevenue === 0) return 0
  if (expense) {
    const validExpense = validateNumber('Expense', expense)
    return (validRevenue - validCost - validExpense) / validRevenue
  }
  return (validRevenue - validCost) / validRevenue
}

// calculation markup
function MARKUP(cost, revenue) {
  if (isEmpty(cost) || isEmpty(revenue)) return null
  const validCost = validateNumber('Cost', cost)
  const validRevenue = validateNumber('Revenue', revenue)
  return (validRevenue - validCost) / validCost
}

// check to see if a string or taglist contains a value
function CONTAINS(input, value) {
  if (isEmpty(input) || isEmpty(value)) {
    return null
  }
  return input.includes(value)
}

//TODO move to another place, doesn't belong here
function fetchOpenAi(prompt, temperature, maxTokens = 400) {
  return fetch('https://api.openai.com/v1/completions', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer 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`
    },
    body: JSON.stringify({
      model: "text-davinci-003",
      prompt,
      max_tokens: maxTokens,
      n: 1,
      stop: null,
      temperature
    })
  })
}

function fetchOpenAiJson(prompt, temperature, maxTokens) {
  return fetchOpenAi(prompt, temperature, maxTokens)
    .then(res => res.json())
    .then(data => data?.choices?.[0]?.text)
}

const AI = fields => function AI(prompt, temperature = 0) {
  const input = `With no preamble, keeping the answer short - ${JSON.stringify(prompt)}, The current product in JSON data for context:\n\n' + ${JSON.stringify(fields)}`
  return fetchOpenAiJson(input, temperature)
}

function GENERATEPRODUCTDESC(prompt, temperature = 0) {
  const input = `Using the following data (excluding any price): ${JSON.stringify(prompt)}, Create a very short one-line product description for price sheet use.`
  return fetchOpenAiJson(input, temperature)
}

function FETCHTEXT(url) {
  return fetch(url, { mode: 'no-cors' })
    .then(res => res.text())
}

function FETCHJSON(url) {
  return fetch(url, { mode: 'no-cors' })
    .then(res => res.json())
}

function FETCHDAILYFXRATE(fromCurrencyCode, toCurrencyCode) {
  if (!fromCurrencyCode?.length === 3) {
    throw Error('Invalid from currency code')
  }
  if (!toCurrencyCode?.length === 3) {
    throw Error('Invalid to currency code')
  }
  return fetch(`http://www.floatrates.com/daily/${fromCurrencyCode?.toLowerCase()}.json`)
    .then(res => res.json())
    .then(json => json[toCurrencyCode?.toLowerCase()].rate)
}

// Exports
export {
  ADDMARGIN, PRICEMARGIN, 
  ADDMARKUP, PRICEMARKUP, 
  PROFIT, MARGIN, MARKUP, CONTAINS,
  AI, FETCHTEXT, FETCHJSON, FETCHDAILYFXRATE, GENERATEPRODUCTDESC
}
