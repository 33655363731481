/*!
 * Column set db
 */

// Db
import { Db } from '@/db/Db.mjs'
import { COLLECTION_COLUMN_SETS } from '@/db/db-collections.mjs'
import { ColumnDb } from '../../column'
import { required } from '../../utils'

const columnDb = new ColumnDb()

export class ColumnSetDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_COLUMN_SETS
    })
  }

  validateDocObject({
    id = required('id'),
    teamId = required('teamId'),
    type = required('type'),
    listId = required('listId')
  }) {
    return
  }

  createDocObject(columnSet) {
    this.validateDocObject(columnSet.toObject())
    const retainSchema = columnSet.isStatic
    return {
      type: columnSet.type,
      listId: columnSet.listId,
      teamId: columnSet.teamId,
      sortBy: columnSet.sortBy,
      isStatic: columnSet.isStatic,
      
      // TODO: need to improve column management

      columns: columnSet.columns?.length
        ? columnSet.columns.map(col => columnDb.createDocObject(
          col, 
          retainSchema
          ))
        : [],

      ...super.createDocObject(columnSet)
    }
  }
}
