
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../db/db-constants.mjs'

const app = getApp()
const CALLABLE_SALESFORCE_INTEGRATION = 'salesforceIntegration'

let salesforceInegrationRpc = null

/**
 * get the salesforce rpc function
 * @returns 
 */
function startTaskTest() {
  console.log('startTaskTest started', Date.now())
  const functions = getFunctions(app, AU_REGION)
  const rpc = httpsCallable(functions, 'startTaskTest', {timeout: 540000 } )
  const response = rpc({ a:'1', b:'2' })
  console.log('startTaskTest ended', Date.now())

  return
}

export { startTaskTest }