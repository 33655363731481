
import { getTeamSession } from '../../session/team-session.mjs'
import { TRUE, FALSE } from '../../constants/types.mjs'
import { LIBRARY_LIST, PRICE_LIST, CSV_PRICE_LIST } from '../../list-core/constants/list-constants.mjs'

import {
  // items
  canAddItems,
  canDeleteItems,
  canExportItems,
  canDuplicateItems,
  // columns
  canCreateColumns,
  canEditColumns,
  canDeleteColumns,
  canFilterColumns,
  canSaveFilteredColumns,
  canHideColumns,
  canPinColumns,
  // view
  canCreateView,
  canAddToView,
  // price list
  canCreatePriceList,
  canModifyPriceList,
  canAddToPriceList,
  canSharePriceList, 
  // csvPriceList
  canUploadCsv,
  canImportToLibrary,
  canModifyView,
  // integration
  canPublishToIntegration,
} from '../../ui/ui-states.mjs'
import { VIEWER_USER_ROLE, ADMIN_USER_ROLE, EDITOR_USER_ROLE } from '../../permissions/constants/roles.mjs'
import { getUserSession } from '../../session/user-session.mjs'
import { isExportActionAllowed } from './list-permissions.mjs'

const LIST_TYPE_ACTION_SETTINGS = {
  library: [
    { // admin or editor role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE],
      //item
      canAddItems: TRUE,
      canDeleteItems: TRUE,
      canExportItems: TRUE,
      canDuplicateItems: TRUE,
      //column
      canCreateColumns: TRUE,
      canDeleteColumns: TRUE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: TRUE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: TRUE,
      canAddToView: TRUE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: TRUE,
      canAddToPriceList: TRUE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: TRUE,
      canImportToLibrary: FALSE,
    },
    { // viewer role
      role: [VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ],
  view: [
    { // admin or editor role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE],
      //item
      canAddItems: TRUE,
      canDeleteItems: TRUE,
      canExportItems: TRUE,
      canDuplicateItems: TRUE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: TRUE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: TRUE,
      //priceList
      canCreatePriceList: TRUE,
      canAddToPriceList: TRUE,
      canModifyPriceList: TRUE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: TRUE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    },
    { // user role
      role: [VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ],
  priceList: [
    { // admin or editor role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: TRUE,
      canExportItems: TRUE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: TRUE,
      canSharePriceList: TRUE,
      //integration
      canPublishToIntegration: TRUE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    },
    { // viewer role
      role: [VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ],
  csvPriceList: [
    { // admin or editor role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: TRUE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: TRUE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: TRUE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: TRUE,
      //csvPriceList
      canUploadCsv: TRUE,
      canImportToLibrary: TRUE,
    },
    { // viewer role
      role: [VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: TRUE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ],
  sharedWithMePriceList: [
    { // admin or editor role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: FALSE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: TRUE,
    },
    { // viewer role
      role: [VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: FALSE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ],
  default: [
    { // admin, editor, viewer role
      role: [ADMIN_USER_ROLE, EDITOR_USER_ROLE, VIEWER_USER_ROLE],
      //item
      canAddItems: FALSE,
      canDeleteItems: FALSE,
      canExportItems: FALSE,
      canDuplicateItems: FALSE,
      //column
      canCreateColumns: FALSE,
      canDeleteColumns: FALSE,
      canFilterColumns: TRUE,
      canSaveFilteredColumns: FALSE,
      canEditColumns: FALSE,
      canHideColumns: TRUE,
      canPinColumns: TRUE,
      //view
      canCreateView: FALSE,
      canAddToView: FALSE,
      canModifyView: FALSE,
      //priceList
      canCreatePriceList: FALSE,
      canAddToPriceList: FALSE,
      canModifyPriceList: FALSE,
      canSharePriceList: FALSE,
      //integration
      canPublishToIntegration: FALSE,
      //csvPriceList
      canUploadCsv: FALSE,
      canImportToLibrary: FALSE,
    }
  ]
}

function setConditionalPermissions(listTypeActionSettings, user, listEntity) {
  listTypeActionSettings.canExportItems = isExportActionAllowed(user, listEntity)
  return listTypeActionSettings
}

function getListUiActionPermissions(listEntity) {
  const team = getTeamSession()
  const isTeamList = team.id === listEntity.teamId
  const user = getUserSession()
  const userRole = user?.role

  // when team is not active or user has no team
  if (!team.isActive || !team) {
    const listTypeActionSettings = LIST_TYPE_ACTION_SETTINGS.default[0]
    return setConditionalPermissions(listTypeActionSettings, user, listEntity)
  }
  // SharedWithMe Price List to users not in the same team
  if (listEntity.type === PRICE_LIST && listEntity.isStatic && !isTeamList) {
    const listTypeActionSettings = LIST_TYPE_ACTION_SETTINGS.sharedWithMePriceList.find(permission => permission.role.includes(userRole))
    return setConditionalPermissions(listTypeActionSettings, user, listEntity) 
  }
  // Library
  if (listEntity.type === LIBRARY_LIST) {
    const listTypeActionSettings =  LIST_TYPE_ACTION_SETTINGS.library.find(permission => permission.role.includes(userRole))
    return setConditionalPermissions(listTypeActionSettings, user, listEntity)
  }
  // View
  if (listEntity.type === PRICE_LIST && !listEntity.isStatic) {
    const listTypeActionSettings =  LIST_TYPE_ACTION_SETTINGS.view.find(permission => permission.role.includes(userRole))
    return setConditionalPermissions(listTypeActionSettings, user, listEntity)
  }
  // Price List 
  if (listEntity.type === PRICE_LIST && listEntity.isStatic && isTeamList) {
    const listTypeActionSettings =  LIST_TYPE_ACTION_SETTINGS.priceList.find(permission => permission.role.includes(userRole))
    return setConditionalPermissions(listTypeActionSettings, user, listEntity)
  }
   // CSV Price List
  if (listEntity.type === CSV_PRICE_LIST) {
    const listTypeActionSettings = LIST_TYPE_ACTION_SETTINGS.csvPriceList.find(permission => permission.role.includes(userRole))
    return setConditionalPermissions(listTypeActionSettings, user, listEntity)
  }
  // none of the above
  const listTypeActionSettings = LIST_TYPE_ACTION_SETTINGS.default[0]
  return setConditionalPermissions(listTypeActionSettings, user, listEntity)
}

function setListUiActionPermissionStates(listEntity) {
  const permissions = getListUiActionPermissions(listEntity)
  canAddItems.value = permissions.canAddItems
  canDeleteItems.value = permissions.canDeleteItems
  canExportItems.value = permissions.canExportItems
  canDuplicateItems.value = permissions.canDuplicateItems
  canCreateColumns.value = permissions.canCreateColumns
  canDeleteColumns.value = permissions.canDeleteColumns
  canFilterColumns.value = permissions.canFilterColumns
  canSaveFilteredColumns.value = permissions.canSaveFilteredColumns
  canEditColumns.value = permissions.canEditColumns
  canHideColumns.value = permissions.canHideColumns
  canPinColumns.value = permissions.canPinColumns
  canCreateView.value = permissions.canCreateView
  canAddToView.value = permissions.canAddToView
  canModifyView.value = permissions.canModifyView
  canCreatePriceList.value = permissions.canCreatePriceList
  canAddToPriceList.value = permissions.canAddToPriceList
  canModifyPriceList.value = permissions.canModifyPriceList
  canSharePriceList.value = permissions.canSharePriceList
  canUploadCsv.value = permissions.canUploadCsv
  canImportToLibrary.value = permissions.canImportToLibrary
  canUploadCsv.value = permissions.canUploadCsv
  canImportToLibrary.value = permissions.canImportToLibrary
  canPublishToIntegration.value = permissions.canPublishToIntegration
}

export { getListUiActionPermissions, setListUiActionPermissionStates }
