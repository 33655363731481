/*!
 * FieldSchemaEntity
 */

import { Entity } from './Entity.mjs'
import { ColumnEntity } from './ColumnEntity.mjs'
import { FieldSchemaEntity } from './FieldSchemaEntity.mjs'

import { createColumnEntityWithFieldSchema } from '../column'
import { required, isEmpty, isNumber, deepClone } from '../utils'
import { TRUE, FALSE } from '../constants/types.mjs'

import { DEFAULT_COLUMN_SET_TYPE } from '@/column-set/column-set-constants.mjs'

import { upsertColumnSet, fetchColumnSet } from '../column-set/data/column-set-data.mjs'
export class ColumnSetEntity extends Entity {
  #type
  #listId
  #teamId
  #columns
  #sortBy
  #isStatic
  constructor({
    type = DEFAULT_COLUMN_SET_TYPE,
    listId = required('listId'),
    teamId = required('teamId'),
    columns = [],
    sortBy = {},
    isStatic = FALSE,
    ...props
  } = {}) {
    super({ ...props })
    this.type = type
    this.listId = listId
    this.teamId = teamId
    this.sortBy = sortBy
    this.columns = columns
    this.isStatic = isStatic
  }

  //--- getters ---//
  get isColumnSetEntity() { return TRUE }
  get type() { return this.#type }
  get listId() { return this.#listId }
  get teamId() { return this.#teamId }
  get sortBy() { return this.#sortBy }
  get columns() { return this.#columns }
  get isStatic() { return this.#isStatic }

  //--- setters ---//
  set type(str) { this.#type = str }
  set listId(id) { this.#listId = id }
  set teamId(id) { this.#teamId = id }
  set sortBy(obj) { this.#sortBy = obj }
  //set sortBy(obj) { this.#sortBy = { [Object.keys(obj)[0]]: Object.values(obj)[0] } }
  set columns(arr) {
    if (!arr?.length) {
      this.#columns = []
      return
    }
    this.#columns = arr[0].isColumnEntity ? arr
      : arr.map(col => createColumnEntityWithFieldSchema(col))
  }
  set isStatic(bool) { this.#isStatic = !!bool }

  //--- private methods ---//
  #parseColumnIndex(index) {
    if (isNumber(index)) {
      return index
    }
    if (index === 'first') {
      return 0
    }
    if (index === 'last') {
      return this.#columns.length
    }
    return
  }

  //--- field schema functions ---//
  getFieldSchemas() {
    return this.#columns.map(column => column.fieldSchema)
  }

  //--- column functions ---//
  getColumnById(id) {
    return this.#columns.find(column => column.id === id)
  }

  getColumnByIndex(index) {
    index = this.#parseColumnIndex(index)
    return this.#columns[index]
  }

  addColumn(columnEntity, index) {
    if (!columnEntity.isColumnEntity) {
      throw('column is not ColumnEntity.')
    }
    const existingIdx = this.#columns.findIndex(col => col.id === columnEntity.id)
    if (existingIdx >= 0) {
      console.error('Column already exist. skipped.', columnEntity.key, columnEntity.id)
      return
    }

    index = isEmpty(index) ? 'last' : index
    index = this.#parseColumnIndex(index)
    if (isEmpty(index) || index >= this.#columns.length) {
      this.#columns.push(columnEntity)
    } else {
      this.#columns.splice(index, 0, columnEntity)
    }
  }

  addColumnAfterId(columnEntity, afterId) {
    let index = this.#columns.findIndex(col => col.id === afterId)
    index = index < 0 ? this.#columns.length : index
    this.addColumn(columnEntity, index + 1)
  }

  updateColumn(columnEntity) {
    if (!columnEntity.isColumnEntity) {
      throw('column is not ColumnEntity.')
    }
    const index = this.#columns.findIndex(col => col.id === columnEntity.id)
    if (index < 0) {
      console.error('column index not found! No Update.')
    }
    if (index >= 0) {
      this.#columns[index] = columnEntity
    }
  }

  removeColumnByIndex(index) {
    index = this.#parseColumnIndex(index)
    if (index >= 0) {
      this.#columns.splice(index, 1)
    }
  }

  removeColumn(columnEntity) {
    const index = this.#columns.findIndex(col => col.id === columnEntity.id)
    this.removeColumnByIndex(index)
  }

  getColumnsByKeys = (keys) => {
    return this.#columns.filter(column => keys.includes(column.key))
  }

  setColumnOrder(ids) {
    if (!ids || ids.length === 0) {
      return this
    }
    const newColumns = []
    for (let i = 0; i < ids.length; i++) {
      const column = this.#columns.find(col => col.id === ids[i])
      if (column) {
        newColumns.push(column)
      }
    }
    this.#columns = newColumns
  }

  /**
   * Update sortBy property
   * @param {string} key - the name of the column key. 
   * @param {number} dir - the sort direction. 
   */
  setSortBy(key, dir) {
    this.#sortBy = { [key]: dir }
  }

  moveColumnByIndex(index, toIndex) {
    index = this.#parseColumnIndex(index)
    toIndex = this.#parseColumnIndex(toIndex)

    if (index < 0 || index === toIndex) {
      return this
    }
    const column = this.getColumnByIndex(index)
    if (index < toIndex) {
      this.addColumn(column, toIndex + 1)
      this.#columns.splice(index, 1)
    }
    if (index > toIndex) {
      this.addColumn(column, toIndex)
      this.#columns.splice(index + 1, 1)
    }
  }

  moveColumn(column, toIndex) {
    toIndex = this.#parseColumnIndex(toIndex)
    const index = this.#columns.findIndex(col => col.id === column.id)
    this.moveColumnByIndex(index, toIndex)
  }


  //--- object methods ---//
  validate() {
    if (!this.id) { required(`${msg} id`) }
    const msg = `[id: ${this.id}]`
    if (!this.teamId) { required(`${msg} teamId`) }
    if (!this.type) { required(`${msg} type`) }
    if (!this.listId) { required(`${msg} listId`) }
    if (!this.columns) { required(`${msg} columns`) }
    const teamId = this.teamId
    this.columns.forEach(col => {
      if (col.isDeleted) {
        return
      }
      col.validate()
      col.fieldSchema.validate()
      if (col.teamId !== teamId) { required(`${msg} columnSet and column [${col.key}] teamId not match`) }
      if (col.fieldSchema.teamId !== teamId) { required(`${msg} columnSet and fieldSchema [${col.fieldSchema.key}] teamId not match`) }
    })
  }

  #getMyProperties() {
    return {
      type: this.#type,
      listId: this.#listId,
      teamId: this.#teamId,
      sortBy: this.#sortBy,
      isStatic: this.#isStatic,
    }
  }

  cloneAsNew({ teamId, type } = {}) {
    return new ColumnSetEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
      columns: this.#columns.map(col => col.cloneAsNew({ teamId })),
      teamId: teamId || this.#teamId,
      type: type || this.#type
    })
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties(),
      columns: this.#columns,
    }
  }

  save() {
    ColumnSetEntity.upsert(this)
  }

  //--- static methods ---//
  static toEntity(columnSet) {
    if (!columnSet) {
      return
    }
    const columnEntities = []
    for (const column of columnSet.columns) {
      const columnEntity = new ColumnEntity({
        ...column,
        fieldSchema: (columnSet.isStatic && column.fieldSchema)
          ? (new FieldSchemaEntity(column.fieldSchema))
          : undefined
      })
      columnEntities.push(columnEntity)
    }
    return new ColumnSetEntity({
      ...columnSet,
      columns: columnEntities
    })
  }

  static async upsert(columnSetEntity) {
    const data = columnSetEntity.toObject()
    const columns = []
    for (const column of columnSetEntity.columns) {
      columns.push({
        ...column.toObject(),
        fieldSchema: (columnSetEntity.isStatic) ? column.fieldSchema.toObject() : undefined
      })
    }
    return upsertColumnSet({
      ...data,
      columns
    })

  }

  static async fetch(id) {
    const columnSet = await fetchColumnSet(id)
    if (!columnSet) {
      return
    }
    return this.toEntity(columnSet)
  }
}

