<template>
  <main-layout>

    <!-- Grid Loader -->
    <template v-if="isGridLoading">
      <div style="position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%; top:0; left: 0; z-index: 99; background-color: rgba(255, 255, 255, 0.6);"
        :style="isMainMenuOpen ? 'margin-left: 150px;' : ''">
        <div class="is-loading"></div>
        <div v-if="isTakingTime" style="line-height: 3rem;">{{ randomWaitMessage }}</div>
      </div>
    </template>
  
    <!-- Action Menu -->
    <div class="sheet-action-bar" :style="isMainMenuOpen ? '' : 'margin-left: 5rem;'">
      <div style="display: flex; align-items: center; min-width: 200px;">

        <!-- Add Item -->
        <q-btn  @click="addNewItem" icon="add" class="q-mx-sm" round>
          <q-tooltip>Add item</q-tooltip>
        </q-btn>

        <!-- Search Items -->
        <q-btn v-show="canAddItems" @click="onOpenSearch(this)" icon="o_search" class="q-mx-sm" flat round>
          <q-tooltip>Search Library</q-tooltip>
          <q-badge v-if="currentSearch?.length" rounded style="margin-left: -12px; margin-top: -19px;" />
        </q-btn>
        <div
          id="searchInputContainer"
          v-show="isSearchingInputActive"
          style="position: relative; width: 80vw; left: 1.8em; z-index: 9999; padding: 1rem 0; background-color: white;"
        >
          <q-input
            type="search"
            v-model="currentSearch"
            placeholder="Search Library"
            @update:model-value="onSearch"
            @keydown.escape="isSearchingInputActive = false"
            @blur="isSearchingInputActive = false"
            dense outlined clearable
          />
        </div>

        <q-btn icon="more_vert" flat round>
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item v-show="canHideColumns" @click="onColumnActionClick()" dense clickable v-close-popup>
                <q-item-section>Show / Hide Columns</q-item-section>
              </q-item>

              <q-item v-show="canUploadCsv" @click="onUploadCsv()" dense clickable v-close-popup>
                <q-item-section>Upload CSV</q-item-section>
              </q-item>

              <q-item v-show="canExportItems && !isImportMode" @click="onExportItems" dense clickable v-close-popup>
                <q-item-section>Download as CSV</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canCreateView" @click="onCreateView" dense clickable v-close-popup>
                <q-item-section>Create View</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canAddToView" @click="onAddToView" dense clickable v-close-popup>
                <q-item-section>Add {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }} to View</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canCreatePriceList" @click="onCreateList" dense clickable v-close-popup>
                <q-item-section>Create Price List</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canAddToPriceList" @click="onAddToList" dense clickable v-close-popup>
                <q-item-section>Add {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }} to Price List</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canDeleteItems" @click="onDeleteItems" dense clickable v-close-popup>
                <q-item-section style="color: red;">Delete {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }}</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canDuplicateItems" @click="onDuplicateSelection" dense clickable v-close-popup>
                <q-item-section>Duplicate {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }}</q-item-section>
              </q-item>

            </q-list>
          </q-menu>
        </q-btn>

      </div>

      <!-- Title -->
              <div class="sheet-title">
        <span :style="canAddToPriceList ? 'cursor: pointer;' : 'cursor: default;'" @click="canAddToPriceList && $router.push('/team')">
          {{ isGridLoading ? 'Loading Library...' : currentTeam?.name + ' Library' }}
          <q-icon class="show-on-hover" v-if="canAddToPriceList" style="margin-left: .4em;" name="o_edit" />
        </span>
      </div>
  
      <div style="min-width: 240px;"></div>
    </div>

    
    <ag-grid-vue
      id="libraryGrid"
      class="sheet ag-theme-alpine"
      @contextmenu.prevent
      @gridReady          = "onGridReady"
      @selectionChanged   = "onSelectionChanged"
      @columnResized      = "onColumnResized"
      @cellValueChanged   = "onCellValueChange"
      @columnMoved        = "onColumnMoved"

      rowSelection = "multiple"
      suppressRowClickSelection = "true"
      :cellFadeDelay        = "600"
      :cellFlashDelay       = "150"
      :stopEditingWhenCellsLoseFocus = "true"
      :singleClickEdit      = "false"
      :animateRows          = "false"

      :getRowId             = "getRowId"
      :columnHoverHighlight = "true"
      :rowBuffer            = "50"

      :isExternalFilterPresent = "GridItemManager.isExternalFilterPresent"
      :doesExternalFilterPass = "GridItemManager.doesExternalFilterPass"
      excludeHiddenColumnsFromQuickFilter = "true"

      overlayNoRowsTemplate = "&nbsp;"
      overlayLoadingTemplate = "&nbsp;"
      
    >
    </ag-grid-vue>

    <right-hand-menu />
    <field-edit-modal></field-edit-modal>
    <list-modal></list-modal>
    <price-list-add-item-modal></price-list-add-item-modal>
    <view-add-item-modal></view-add-item-modal>
    <selected-cell-value-editor></selected-cell-value-editor>

    <!-- Upload Progress Overlay -->
    <progress-overlay
      v-show="isProgressOpen"
      :heading="progressLabel"
      :body="progressBody"
      :value="progressPercentage"
    />

  </main-layout>

  <!-- File uploader -->
  <input
    type="file"
    id="libraryMenuUploader"
    @change="onUploadFile"
    style="position: absolute; top: -1000px; left: -1000px;"
    accept="text/csv, text/tsv"
  />
</template>

<script setup>

// Styles
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/styles/sheet.css'
import '@/styles/theme.css'

// Dependencies
import { ref, onUnmounted, onBeforeMount, onMounted, onBeforeUpdate } from 'vue'
import { AgGridVue } from 'ag-grid-vue3'
import { useRoute } from 'vue-router'
import { getRandomWaitMessage } from '@/utils'

// Layouts
import MainLayout from '@/layouts/MainLayout'

// Components
import FieldEditModal      from '@/components/sheet/FieldEditModal'
import ListModal           from '@/components/sheet/ListModal'
import PriceListAddItemModal from '@/components/sheet/PriceListAddItemModal.vue' 
import ViewAddItemModal from '@/components/sheet/ViewAddItemModal.vue'
import ProgressOverlay     from '@/components/ProgressOverlay'
import RightHandMenu       from '@/components/sheet/RightHandMenu'
import SelectedCellValueEditor from '@/components/sheet/SelectedCellValueEditor.vue'

// notification
import { raiseConfirmDelete } from '../../notify/raise-confirm-delete.mjs'
import { raiseConfirmAlert } from '../../notify/raise-confirm-alert.mjs'

// Actions
import { isSearchMenuOpen, openSearchMenu, closeSearchMenu } from '@/actions/search-menu.mjs'
import { isMainMenuOpen } from '@/ui/main-menu.mjs'
import { getRowId } from '@/ui/components/sheet.mjs'

// ui action permissions
import {
  canUploadCsv,
  canExportItems,
  canDuplicateItems,
  canCreatePriceList,
  canAddToPriceList,
  canDeleteItems,
  canAddItems,
  canHideColumns,
  canCreateView,
  canAddToView,
  selectedItemsCount,
} from '../../ui/ui-states.mjs'

import { 
  currentTeam, filterTab, 
} from '../../ui/ui-states.mjs'


// list functions
import { 
  openCreateView, 
  openAddToView,
  openCreatePriceList,
  openAddToPriceList
} from '../library-ui.mjs'


// library list function
import { onLibraryStop, onLibraryGridReady } from '../library-ui.mjs'

// progress
import { isProgressOpen, progressLabel, progressBody, progressPercentage } from '../../ui/ui-states.mjs'
import { startProgressEvents, stopProgressEvents } from '../../ui/progress-status.mjs'

// session events
import { dispatch } from '../../events/dispatch.mjs'
import { 
  EVENT_ITEM_NEW_LOCAL, 
  EVENT_ITEM_GRID_UPDATED_LOCAL, 
  EVENT_UPLOAD_CSV_LOCAL,
  EVENT_SELECTED_ITEMS_DELETE_LOCAL,
  EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL,
  EVENT_ITEMS_DOWNLOAD_AS_CSV,
  EVENT_COLUMN_FILTER_QUICK_LOCAL,
} from '../../events/constants/event-types.mjs'

// ui functions
import { 
  onColumnResized, 
  onColumnMoved 
} from '../../list-core/uis/column-ui-actions.mjs'

// filtering
import { GridItemManager } from '../../grid-manager/entities/GridItemManager.mjs'

const TAKING_TIME_DELAY = 1000

// JS: shouldn't keep these globally, only used for search right now
let gridApi   = null
let selectedRows = []
const route = useRoute()

// Refs
const selectedCount          = ref(0)
const isSearchingInputActive = ref(false)
const canSelect = ref(true)
const currentSearch = ref('')
const isImportMode = ref(false)
const isGridLoading = ref(false)
const isTakingTime = ref(false)
const randomWaitMessage = ref(getRandomWaitMessage())

/**
 * Mount handlers
 */
onBeforeMount(() => {  
  isTakingTime.value = false
  setTimeout(() => {
    isTakingTime.value = true
  }, TAKING_TIME_DELAY)
  startProgressEvents()
})

onMounted(async () => {
})

/**
 * Grid ready handler
 */
async function onGridReady(params) {
  isGridLoading.value = true
  await onLibraryGridReady({ api: params.api, columnApi: params.columnApi })
  isGridLoading.value = false

}

/**
 * Before Update handler
 */
onBeforeUpdate(() => {
  //isGridLoading.value = true
})

/**
 * Unmount handler
 */
onUnmounted(() => {
  stopProgressEvents()
  onLibraryStop()
  isGridLoading.value = true
  isTakingTime.value = false

})

/**
 * Export item handler
 */
function onExportItems() {
  return dispatch(EVENT_ITEMS_DOWNLOAD_AS_CSV)
}

/**
 * Column action click handler
 */
function onColumnActionClick() {
  if (isSearchMenuOpen.value) {
    return closeSearchMenu()
  }
  openSearchMenu()
  filterTab.value = 'columns'
}


function onUploadFile(event) {
  if (!canUploadCsv.value) {
    return
  }
  event.preventDefault()
  event.stopPropagation()

  const files  = event.target.files || event.dataTransfer.files
  const file   = files[0]
  dispatch(EVENT_UPLOAD_CSV_LOCAL, {
    file,
    isNew: true
  })
  return
}

/**
 * On search handler
 */
function onSearch() {
  dispatch(EVENT_COLUMN_FILTER_QUICK_LOCAL, {
    searchValue: currentSearch.value,
  })
}

/**
 * Duplicate selected items handler
 */
function onDuplicateSelection() {
  if (selectedItemsCount.value < 2) {
    dispatch(EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL, {
      selectedItemsCount: selectedItemsCount.value,
    })
    return
  }
  raiseConfirmAlert(
    `Are you sure you wish to duplicate ${selectedItemsCount.value} item${selectedItemsCount.value > 1 ? 's' : ''}?`, 
    () => {
      dispatch(EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL, {
        selectedItemsCount: selectedItemsCount.value,
      })
    }, 
    { confirmLabel: 'DUPLICATE'}
  )
}

/**
 * Selection change handler
 */
function onSelectionChanged({ api }) {
  if (!canSelect.value) {
    return
  }
  selectedRows = api.getSelectedRows()
  selectedCount.value = selectedRows.length
  selectedItemsCount.value = selectedRows.length
}

/**
 * Cell value change handler
 */
function onCellValueChange({ data }) {
  dispatch(EVENT_ITEM_GRID_UPDATED_LOCAL , { item: data })
}

/**
 * Open file handler
 */
function onUploadCsv() {
  const fileUploadInput = document.getElementById('libraryMenuUploader')
  fileUploadInput.value = '' // reset the value so change detection works
  fileUploadInput.click()
}

/**
 * Open search handler
 */
function onOpenSearch() {
  isSearchingInputActive.value = true
  requestAnimationFrame(() => {
    document.querySelector('#searchInputContainer input')?.focus()
  })
}

/**
 * Open create view handler
 */
function onCreateView() {
  openCreateView()
}


/**
 * Open Add to view handler
 */
 function onAddToView() {
  openAddToView()
}


/**
 * Open create list handler
 */
function onCreateList() {
  openCreatePriceList()
}

/**
 * Open Add to list handler
 */
function onAddToList() {
  openAddToPriceList()
}



//#region //--- items ---//
const addNewItem = () => {
  dispatch(EVENT_ITEM_NEW_LOCAL)
}

const onDeleteItems = () => {
  raiseConfirmDelete(`Are you sure you wish to delete ${selectedItemsCount.value} item${selectedItemsCount.value > 1 ? 's' : ''} from the Library?`, () => {
    dispatch(EVENT_SELECTED_ITEMS_DELETE_LOCAL, {
      selectedItemsCount: selectedItemsCount.value,
    })
  })
}

//#endregion
</script>
