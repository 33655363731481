// Dependencies
import { ERR_UNKNOWN_ERR } from '../constants/error.mjs'
import { isError } from './is.mjs'

/**
 * Throw an error
 */
function throwError(error = ERR_UNKNOWN_ERR) {
  const err = isError(error) ? error : new Error(error)
  console.error(err)
  throw err
}

// Exports
export { throwError }
