/*!
 * firebase emulators
 */
import { connectAuthEmulator } from 'firebase/auth'
import { connectFirestoreEmulator } from 'firebase/firestore'
import { connectStorageEmulator } from 'firebase/storage'
import { connectFunctionsEmulator } from 'firebase/functions'

// emulator setup
export const EMULATOR_HOSTS = ['localhost', '127.0.0.1']
export const EMULATOR_HOST = 'localhost'
export const EMULATOR_HOST_PORT = '5080'
export const EMULATOR_AUTH_URL = 'http://localhost:9099'
export const EMULATOR_STORAGE_PORT = '9199'
export const EMULATOR_FIRESTORE_PORT = '9080'
export const EMULATOR_FUNCTION_PORT = '5001'

/**
 * Is emulator
 * @returns 
 */
function isEmulator() {
  return EMULATOR_HOSTS.includes(location.hostname) && location.port === EMULATOR_HOST_PORT
}

/**
 * Use auth emulator
 */
function useAuthEmulator(auth) {
  console.log('Using Auth Emulator')
  connectAuthEmulator(auth, EMULATOR_AUTH_URL)
}

/**
 * Use firestore emulator
 */
function useFirestoreEmulator(db) {
  console.log('Using Firestore Emulator')
  connectFirestoreEmulator(db, EMULATOR_HOST, EMULATOR_FIRESTORE_PORT)
}

/**
 * Use storage emulator
 */
function useStorageEmulator(storage) {
  console.log('Using Storage Emulator')
  connectStorageEmulator(storage, EMULATOR_HOST, EMULATOR_STORAGE_PORT)
}

/**
 * Use function emulator
 */
 function useFunctionEmulator(functions) {
  console.log('Using Function Emulator')
  connectFunctionsEmulator(functions, EMULATOR_HOST, EMULATOR_FUNCTION_PORT)
}


export { isEmulator, useAuthEmulator, useFirestoreEmulator, useStorageEmulator, useFunctionEmulator }