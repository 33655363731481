// utils
import { required } from '../../utils/required.mjs'

//events
import { unsubscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

import { addEventsColumnAutosize, addEventsColumnResize } from '../../list-core/events/size-column-events.mjs'
import { addEventsColumnHide, addEventsColumnUnhide } from '../../list-core/events/hide-unhide-column-events.mjs'
import { addEventsColumnMove } from '../../list-core/events/move-column-events.mjs'
import { addEventsColumnPin, addEventsColumnUnpin } from '../../list-core/events/pin-unpin-column-events.mjs'
import { addEventsColumnSortAsc, addEventsColumnSortDesc } from '../../list-core/events/sort-column-events.mjs'

import { addEventsColumnFieldSchemaNew } from '../../list-core/events/new-column-field-schema-events.mjs'
import { addEventsColumnFieldSchemaDelete } from '../../list-core/events/delete-column-field-schema-events.mjs'
import { addEventsFieldSchemaUpdate } from '../../list-core/events/update-field-schema-events.mjs'

import { addEventsColumnQuickFilter } from '../../list-core/events/filter-column-events.mjs'

import {
  addEventsCreateView,
  addEventsAddToView
} from '../../list-core/actions/view-event-listeners.mjs'

import {
  addEventsCreatePriceList,
  addEventsAddToPriceList
} from '../../list-core/actions/price-list-event-listener.mjs'

import {
  addEventsDownloadAsCsv
} from '../../list-core/events/download-as-csv-events.mjs'

import {
  addEventsItemNew,
  addEventsItemUpated,
  addEventsLibraryItemsUpdatedRemote,
  addEventsSeletedItemsUpdate,
  addEventsSeletedItemsDelete,
  addEventsSeletedItemsDuplicate,
} from '../../list-core/item-events/item-event-listeners.mjs'

import {
  addEventsSetUpdatedAtInterval
} from '../../list-core/events/list-update-interval-events.mjs'

let subscriptions = []
let columnEventController = null
let itemEventController = null

export const startLibraryColumnEvents = (
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  columnEventController = new AbortController()
  addEventsSetUpdatedAtInterval(columnEventController)
  
  addEventsColumnFieldSchemaNew(columnEventController, subscriptions, columnManager, itemManager, { scope: SCOPE_TEAM })
  addEventsColumnFieldSchemaDelete(columnEventController, subscriptions, columnManager, itemManager, { scope: SCOPE_TEAM })

  addEventsFieldSchemaUpdate(columnEventController, subscriptions, columnManager, itemManager, { scope: SCOPE_TEAM })

  addEventsColumnHide(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })
  addEventsColumnUnhide(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })

  addEventsColumnPin(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })
  addEventsColumnUnpin(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })

  addEventsColumnSortAsc(columnEventController, columnManager)
  addEventsColumnSortDesc(columnEventController, columnManager)

  addEventsColumnAutosize(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })
  addEventsColumnResize(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })

  addEventsColumnMove(columnEventController, subscriptions, columnManager, { scope: SCOPE_TEAM })

  addEventsColumnQuickFilter(columnEventController, itemManager)

  // view events
  addEventsCreateView(columnEventController, columnManager, itemManager)
  addEventsAddToView(columnEventController, columnManager, itemManager)

  // pricelist events
  addEventsCreatePriceList(columnEventController, columnManager, itemManager)
  addEventsAddToPriceList(columnEventController, columnManager, itemManager)

  // download event
  addEventsDownloadAsCsv(columnEventController, columnManager, itemManager)
  
}

export const stopLibraryColumnEvents = () => {
  columnEventController?.abort()
  columnEventController = undefined

  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })
  }
  subscriptions = []

}


export const startLibraryItemEvents = (
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  itemEventController = new AbortController()
  // single item
  addEventsItemNew(itemEventController, subscriptions, columnManager, itemManager)
  addEventsItemUpated(itemEventController, subscriptions, columnManager, itemManager)

  // selected items
  addEventsLibraryItemsUpdatedRemote(itemEventController, subscriptions, columnManager, itemManager)
  addEventsSeletedItemsUpdate(itemEventController, subscriptions, columnManager, itemManager)
  addEventsSeletedItemsDelete(itemEventController, subscriptions, columnManager, itemManager)
  addEventsSeletedItemsDuplicate(itemEventController, subscriptions, columnManager, itemManager)
}

export const stopLibraryItemEvents = () => {
  itemEventController?.abort()
  itemEventController = null
}


