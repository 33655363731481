/*!
 * List constants
 */

export const LIBRARY_LIST = 'Library'
export const VIEW_LIST = 'View'
export const PRICE_LIST = 'Price List'
export const CSV_PRICE_LIST = 'CSV Price List'
export const DEFAULT_LIBRARY_NAME = 'Library'

export const PERMISSION_TEAM = 'Team'
export const PERMISSION_ANYONE = 'Anyone'
export const PERMISSION_NONE = 'None'
export const DEFAULT_EXPORT_PERMISSION = PERMISSION_TEAM

export const ROLE_EDITOR = 'Editor'
export const ROLE_VIEWER = 'Viewer'
export const ROLE_NONE = 'None'

export const FEATURE_KEY_LIST_EXPORT = 'canExport'

export const DEFAULT_HEADER_HEIGHT = 48
export const IMPORT_HEADER_HEIGHT = 96

export const COLUMN_SORT_ASC = 'asc'
export const COLUMN_SORT_DESC = 'desc'
