// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'
// local events
import {
  EVENT_VIEW_MODIFY_LOCAL,
  EVENT_VIEW_MODIFIED,
  EVENT_VIEW_MODIFIED_LOCAL,
  EVENT_VIEW_MODIFIED_REMOTE,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'


import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

// entities
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

//actions 
import { getListAndColumns } from '../db/get-list-and-columns.mjs'

export const addEventViewModify = (controller) => {
  addEventListener(
    EVENT_VIEW_MODIFY_LOCAL,
    (event) => viewModify(event),
    { signal: controller.signal }
  )
}

export const addEventsViewModified = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_VIEW_MODIFIED_LOCAL,
    (event) => viewModified(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_VIEW_MODIFIED,
      viewModifiedRemoteHandler,
      { scope: SCOPE_TEAM }
    )
  )
  // remote
  addEventListener(
    EVENT_VIEW_MODIFIED_REMOTE,
    (event) => viewModifiedRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return

}

//#region //--- New Column ---//
export const viewModify = async (event, {
  scope = SCOPE_TEAM
} = {}) => {
  const { list, columnSet } = event.detail
  const team = getTeamSession()

  const listEntity = !list.isListEntity
    ? (new ListEntity(list))
    : list

  const columnSetEntity = !columnSet.isColumnSetEntity
    ? (new ColumnSetEntity(columnSet))
    : columnSet

  const externalEvent = createExternalEvent({
    action: EVENT_VIEW_MODIFIED,
    scope,
    teamId: team.id,
    payload: {
      listId: list.id,
      columnSetId: columnSetEntity.id,
    }
  })
  listEntity.updatedByEventId = externalEvent.id
  columnSetEntity.updatedByEventId = externalEvent.id
  await ListEntity.upsert(listEntity)
  await ColumnSetEntity.upsert(columnSetEntity)
  dispatch(EVENT_VIEW_MODIFIED_LOCAL, { list: listEntity, columnSet: columnSetEntity })

  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
}

/**
 * Add new column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const viewModified = async (event, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { list, columnSet } = event.detail
  const team = getTeamSession()

  if (columnManager && columnManager.list.id === list.id) {
    columnManager.list.name = list.name
    columnManager.setColumnSet(columnSet)
    columnManager.refreshColumns()
    itemManager.compileAllItems(columnManager.getFieldSchemas())
  }

}


/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const viewModifiedRemoteEvent = async (event, columnManager, itemManager) => {
  const { list, columnSet } = event.detail
  if (columnManager.columnSet.id !== columnSet.id) {
    return
  }
  columnManager.list.name = list.name
  columnManager.setColumnSet(columnSet)

  columnManager.refreshColumns()
  itemManager.compileAllItems(columnManager.getFieldSchemas())
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const viewModifiedRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { listId } = payload
  const { list, columnSet } = await getListAndColumns(listId)
  dispatch(EVENT_VIEW_MODIFIED_REMOTE, { list, columnSet })

}
