import { createNotification } from './create-notification.mjs'

/**
 * Raise import confirmation with error
 */
export const raiseConfirmImportError = (message, handler) => {
  return createNotification({
    message,
    icon: 'warning',
    color: 'red-8',
    position: 'center',
    timeout: 0,
    textColor: 'white',
    actions: [
      { label: 'Cancel', color: 'white' },
    ]
  })
}

/**
 * Raise import confirmation with warning
 */
export const raiseConfirmImportWarning = (message, handler) => {
  return createNotification({
    message,
    icon: 'warning',
    color: 'red-8',
    position: 'center',
    timeout: 0,
    textColor: 'white',
    actions: [
      { label: 'Continue', color: 'white', handler },
      { label: 'Cancel', color: 'white' },
    ]
  })
}

/**
 * Raise import confirmation
 * @param {*} message 
 * @param {*} handler 
 * @returns 
 */
export const raiseConfirmImport = (message, handler) => {
  return createNotification({
    message,
    position: 'center',
    timeout: 0,
    actions: [
      { label: 'Continue', color: 'white', handler },
      { label: 'Cancel', color: 'white' },
    ]
  })
}


