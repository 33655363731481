/**
 * String utils
 */
// Utils
import { required } from './required.mjs'

// Constants
import { CHAR_AT } from '../constants/characters.mjs' 
import { REGEX_NON_ALPHANUMERIC } from '../constants/regex.mjs' 

/**
 * Uppercase the first character of a string
 */
function ucFirst(
  str = required('string')
) {
  return String(str)[0]
    .toUpperCase() 
    +String(str)
      .substring(1)
      .toLowerCase()
}

/**
 * Get the username of an email address
 */
function getFullNameFromEmail(
  email = required('email address')
) {
  return String(email)
    .split(CHAR_AT)[0]
}

/**
 * Get the first word of a string
 */
function getFirstword(
  fullName = required('full name')
) {
  return String(fullName)
    .split(REGEX_NON_ALPHANUMERIC)[0]
}

export { ucFirst, getFullNameFromEmail, getFirstword }
