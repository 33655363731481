/*!
 * User Entity
 */

import { Entity } from '../entity'

import { TRUE, FALSE } from '../constants'
import { required } from '../utils'
import { CHAR_EMPTY } from '../constants/characters.mjs'


export class UserEntity extends Entity {
  #disabled
  #displayName
  #email
  #emailAliases
  #emailVerified
  #isActive
  #isSuperAdmin
  #phoneNumber 
  #photoURL
  #role
  #teamId
  #teams
  #uid
  constructor({
    disabled = FALSE,
    displayName = null,
    email = required('email'),
    emailAliases = [],
    emailVerified = FALSE,
    isActive = TRUE,
    isSuperAdmin = FALSE,
    phoneNumber = null,
    photoURL = null,
    role = null,
    teamId = null,
    teams = [],
    uid = null,
    ...props
  }) {
    super({ ...props })
    this.disabled = disabled
    this.displayName = displayName
    this.email = email
    this.emailAliases = emailAliases
    this.emailVerified = emailVerified
    this.isActive = isActive
    this.isSuperAdmin = isSuperAdmin
    this.phoneNumber = phoneNumber
    this.photoURL = photoURL
    this.role = role
    this.teamId = teamId
    this.teams = teams
    this.uid = super.id
  }
  
  get isUserEntity() { return TRUE }

  //#region getters
  get disabled() { return this.#disabled }
  get displayName() { return this.#displayName }
  get email() { return this.#email }
  get emailAliases() { return [this.#email, ...this.#emailAliases] }
  get emailVerified() { return this.#emailVerified }
  get isActive() { return this.#isActive }
  get isSuperAdmin() { return this.#isSuperAdmin }
  get phoneNumber() { return this.#phoneNumber }
  get photoURL() { return this.#photoURL }
  get role() { return this.#role }
  get teamId() { return this.#teamId }
  get teams() { return [...this.#teams] }
  get uid() { return this.#uid }
  //#endregion

  //#region setters
  set disabled(bool) { this.#disabled = bool }
  set displayName(str) { this.#displayName = str }
  set emailVerified(bool) { this.#emailVerified = bool }
  set isActive(bool) { this.#isActive = bool }
  set isSuperAdmin(bool) { this.#isSuperAdmin = bool }
  set phoneNumber(str) { this.#phoneNumber = str }
  set photoURL(str) { this.#photoURL = str }
  set role(str) { this.#role = str }
  set teamId(str) { this.#teamId = str }
  set teams(arr) { this.#teams = arr } //TODO: JL - removed set, to test

  set uid(str) { this.#uid = str }
  set email(str) { 
    this.#emailAliases?.delete(this.#email)
    this.#email = str
  }
  set emailAliases(arr) { 
    this.#emailAliases = new Set([...arr]) 
    this.#emailAliases?.delete(this.#email)
  }
  //#endregion

  //#region email aliases methods
  /**
   * add an email alias
   * @param {string} email 
   */
  addEmailAlias(email) {
    if (email !== this.#email) {
      this.#emailAliases.add(email)
    }
  }

  /**
   * remove an email alias
   * @param {string} email 
   */
  removeEmailAlias(email) {
    this.#emailAliases.delete(email)
  }
  //#endregion

  updateEmail(email) {
    this.#email = email
  }

  //#region teams methods

  /**
   * Switch team and role
   * @param {string} teamId 
   * @param {string} role 
   */
  switchTeam(teamId, role) { //TODO: JL - removed set, to test
    if (!this.#teams) this.addTeam(teamId) //add to teams if it is first team
    if (!this.#teams.includes(teamId)) return //not allow to switch if not in teams
    this.#teamId = teamId
    this.#role = role
  }

  /**
   * add a team membership
   * @param {string} teamId 
   */
  addTeam(teamId) { //TODO: JL - removed set, to test
    if (!this.#teams.includes(teamId)) {
      return this.#teams.push(teamId)
    }
  }

  /**
   * remove a team membership and remove user from current team
   * @param {string} teamId 
   */
  removeTeam(teamId) { //TODO: JL - removed set, to test
    const index = this.#teams.findIndex(team => team === teamId)
    if (index != -1) {
      this.#teams.splice(index, 1)
    }
    if (this.#teamId === teamId) { //remove user from current team
      this.#teamId = CHAR_EMPTY
      this.#role = CHAR_EMPTY
    } 
  }
  //#endregion

  //#region object methods
  validate() {
    const msg = `[email: "${this.#email}" id: ${this.id}]`
    if (!this.id) { required(`${msg} id`) }
    if (!this.email) { required(`${msg} email`) }
    if (!this.uid) { required(`${msg} uid`) }
  }

  #getMyProperties() {
    return {
      disabled: this.#disabled,
      displayName: this.#displayName,
      email: this.#email,
      emailAliases: [...this.#emailAliases],
      emailVerified: this.#emailVerified,
      isActive: this.#isActive,
      isSuperAdmin: this.#isSuperAdmin,
      phoneNumber: this.#phoneNumber,
      photoURL: this.#photoURL,
      role: this.#role,
      teamId: this.#teamId,
      teams: this.#teams,
      uid: this.#uid,
    }
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties()
    }
  }
  //#endregion
}
