import { getUserSession } from '@/session/user-session.mjs'
import { PRICE_LIST, CSV_PRICE_LIST } from '../../list-core/constants/list-constants.mjs'

function getSharedWithMePriceListQuery(user) {
  const email = [user.email, ...user.emailAliases]
  if (!user?.teamId) {
    return [
      ['sharedWith', 'array-contains-any', email],
      ['isDeleted', '==', false],
      ['type', '==', PRICE_LIST]
    ]
  }
  return [
    ['teamId', '!=', user.teamId],
    ['sharedWith', 'array-contains-any', email],
    ['isDeleted', '==', false],
    ['type', '==', PRICE_LIST]
  ]
}

function getTeamPriceListsQuery(teamId) {
  return [
    ['teamId', '==', teamId],
    ['isDeleted', '==', false],
    ['type', 'in', [PRICE_LIST, CSV_PRICE_LIST] ]
  ]
}

export { 
  getSharedWithMePriceListQuery,
  getTeamPriceListsQuery,
}
