/*!
 * Item listener
 */

import { DbListener } from "@/db"
import { COLLECTION_LISTS } from '@/db/db-collections.mjs'
import { required } from "../../utils/required.mjs"
import { getSharedWithMePriceListQuery } from '../db/price-list-db-utils.mjs'

export class SharedWithMeListener extends DbListener {
  constructor({
    user = required('user'),
    collectionName = COLLECTION_LISTS,
    queryConditions = getSharedWithMePriceListQuery(user),
    addedFunction,
    modifiedFunction,
    removedFunction,
    handlerParams,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      handlerParams,
      ...props
    })
  }
}
