/*!
 * Invites
 */

// Utils
import {
  required, getTimestamp,
  queryListener, getAllByConditions, stopListener,
  createOne, updateOne, markAsDeleted, 
} from '../../utils'

// Session
import { getUserSession } from '../../session/user-session.mjs'

// Constants
import {
  DEFAULT_INVITE_EXPIRY_DURATION,
  TEAM_INVITE_TYPE, LIST_INVITE_TYPE, SIGNUP_INVITE_TYPE,
  INVITES_COLLECTION_NAME as COLLECTION_NAME
} from '../../constants'

const SIGNUP_DEFAULT_NAME = 'No Name'

/**
 * Make invite
 */
function makeInvite({
  id,
  role,
  name          = SIGNUP_DEFAULT_NAME,
  type          = SIGNUP_INVITE_TYPE, 
  senderId      = getUserSession().uid,
  senderEmail   = getUserSession().email,
  receiverEmail = required('receiverEmail'),
  message,
  isActive      = true,
  isDeleted     = false,
  expiresIn     = DEFAULT_INVITE_EXPIRY_DURATION, 
  createdAt     = getTimestamp(),
  expiresAt     = createdAt + (expiresIn * 1000),
  createdBy,
  updatedAt,
  updatedBy,
}) {
  return {
    id,
    name,
    type,
    senderId,
    senderEmail : senderEmail.toLowerCase(),
    receiverEmail : receiverEmail.toLowerCase(),
    role, 
    message,
    isActive,
    isDeleted,
    expiresAt,
    createdAt,
    createdBy,
  }
}

/**
 * Make signup invite
 */
 function makeSignupInvite({
  ...props
} = required('signup invite data')) {
  return makeInvite({
    ...props,
    type: SIGNUP_INVITE_TYPE  
  })
}

/**
 * Make team invite
 */
 function makeTeamInvite({
  teamId,
  ...props
} = required('signup invite data')) {
  return { ...makeInvite({
      ...props,
      type: TEAM_INVITE_TYPE  
    }),
    teamId
  }
}

/**
 * Make list invite
 */
 function makeListInvite({
  listId,
  ...props
} = required('signup invite data')) {
  return{ ...makeInvite({
      ...props,
      type: LIST_INVITE_TYPE  
    }),
    listId
  }
}

/**
 * IsExpired
 */
function isExpired(invite) {
  return invite.expiresAt < Date.now()
}

/**
 * Create signup invite
 */
function createOneSignupInvite({
  receiverEmail = required('receiverEmail'),
  type,
  ...props
} = required('invite object is required')) {
  const newInvite = makeSignupInvite({ ...props, receiverEmail })
  const id = createOne(COLLECTION_NAME, newInvite)
  return { ...newInvite, id }

}

/**
 * Create team invite
 */
function createOneTeamInvite({
  receiverEmail = required('receiverEmail'),
  teamId = required('teamId'),
  type,
  ...props
} = required('invite object is required')) {
  const newInvite = makeTeamInvite({ ...props, teamId, receiverEmail })
  const id = createOne(COLLECTION_NAME, newInvite)
  return { ...newInvite, id }
}

/**
 * Create list invite
 */
function createOneListInvite({
  receiverEmail = required('receiverEmail'),
  listId = required('listId'),
  type,
  ...props
} = required('invite object is required')) {
  const newInvite = makeListInvite({ ...props, listId, receiverEmail })
  const id = createOne(COLLECTION_NAME, newInvite)
  return { ...newInvite, id }
}

/**
 * Get active sign-up invites
 */
function getSignupInvites({
  receiverEmail = required('receiverEmail')
}) {
  const condArr = [
    [ 'type',          '==', SIGNUP_INVITE_TYPE ],
    [ 'receiverEmail', '==', receiverEmail ],
    [ 'isActive',      '==', true ],
    [ 'isDeleted',     '==', false ]
  ]
  return getAllByConditions(COLLECTION_NAME, condArr)
}

/**
 * Get active invites
 */
function getTeamInvites({
  teamId = required('teamId')
}) {
  const condArr = [
    [ 'type',      '==', TEAM_INVITE_TYPE ],
    [ 'teamId',    '==', teamId ],
    [ 'isActive',  '==', true ],
    [ 'isDeleted', '==', false ]
  ]
  return getAllByConditions(COLLECTION_NAME, condArr)
}

/**
 * Update invite
 */
function deactivateInvite({
  id = required('invite id'),
  ...props
} = required('invite object is required')) {
  return updateOne(COLLECTION_NAME, { ...props, isActive : false,  id })
}

/**
 * Mark invite as deleted
 */
function markInviteAsDeleted(inviteId) {
  return markAsDeleted(COLLECTION_NAME, inviteId)
}

/**
 * Start invite listener
 */
 function startInvitesListener(
  localAddedFunction,
  remoteAddedFunction,
  localModifiedFunction,
  remoteModifiedFunction,
  localRemovedFunction,
  remoteRemovedFunction,
  queryConditionsArray = [],
) {
  const queryConditions = [
    [ 'isDeleted', '==', false ],
    ...queryConditionsArray
  ]  
  return queryListener(
    COLLECTION_NAME,
    queryConditions,
    localAddedFunction,
    remoteAddedFunction,
    localModifiedFunction,
    remoteModifiedFunction,
    localRemovedFunction,
    remoteRemovedFunction,
  )
}

/**
 * Start invite or invite items inviteener by handle
 */
function stopInvitesListener(handle) {
  stopListener(handle)
}

// Exports
export {
  createOneSignupInvite, createOneTeamInvite, createOneListInvite, deactivateInvite,
  getTeamInvites, getSignupInvites,
  markInviteAsDeleted, isExpired,
  startInvitesListener, stopInvitesListener,
}
