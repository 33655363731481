/**
 * Active integrations
 */

// utils
import { required } from '../../utils/required.mjs'
import { generateId } from '../../utils/uuid.mjs'

// session
import { setSessionEvent, generateEventId } from '../../events/session-events.mjs'

// salesforce integration rpc
import { getIntegrationsRpc } from '../rpcs/integrations-rpcs.mjs'
import { ACTION_GET_INTEGRATION, ACTION_GET_TEAM_INTEGRATIONS } from '../constants/action-types.mjs'

// states
import { activeIntegrationsUi, publishableIntegrations } from '../states/integrations-ui-states.mjs'

const SESSION_KEY = 'activeIntegration'
const activeIntegrationsMap = {}

const setEventId = (integration) => {
  const eventId = generateEventId()
  integration.updatedByEventId = eventId
  setSessionEvent({ 
    id: eventId, 
    payload : {
      integrationId: integration.id 
    }
  })
  return integration
}


const fetchTeamIntegrations = async (teamId) => {
  const integrationRpc = getIntegrationsRpc()
  const response = await integrationRpc({
    action: ACTION_GET_TEAM_INTEGRATIONS,
    payload: {
      teamId
    }
  })
  return response?.data?.result
}

const createUiData = (integration) => {
  return {
    id: integration.id,
    name: integration.name,
    teamId: integration.teamId,
    type: integration.type,
    subtype: integration.subtype,
    path: `${integration.subtype}/${integration.id}`,
    isImport: integration.isImport,
    description: integration.description || integration.subtype
  }
}

const setActiveIntegrationsUi = (activeIntegrationsMap) => {
  if (activeIntegrationsMap.size === 0) {
    activeIntegrationsUi.value = []
    return
  }
  activeIntegrationsUi.value = Object.values(activeIntegrationsMap)
    .map(integration => createUiData(integration))
    .sort((a, b) => a.name.localeCompare(b.name))

  publishableIntegrations.value = activeIntegrationsUi.value
    .filter(i => !i.isImport)
}

export const upsertActiveIntegrations = (
  integration = required('integration'),
  local = true
) => {
  integration.id = integration.id || generateId()
  if (local) {
    integration = setEventId(integration)
  }
  activeIntegrationsMap[integration.id] = integration
  setActiveIntegrationsUi(activeIntegrationsMap)
  return integration
}


export const removeActiveIntegrations = (
  id = required('id'),
) => {
  delete activeIntegrationsMap[id]
  setActiveIntegrationsUi(activeIntegrationsMap)
}

export const clearActiveIntegrations = () => {
  activeIntegrationsUi.value = []
}

export const getActiveIntegration = async (
  id = required('id')
) => {
  const integrationRpc = getIntegrationsRpc()
  const response = await integrationRpc({
    action: ACTION_GET_INTEGRATION,
    payload: {
      id
    }
  }) 
  activeIntegrationsMap[id] = response?.data?.result
  return activeIntegrationsMap[id]
}

export const getAllActiveIntegrations = () => {
  return Object.values(activeIntegrationsMap)
}

export const loadActiveIntegrations = async (
  teamId = required('teamId')
) => {
  //TODO: set this based on team plan
  const activeIntegrationsMap = {}
  const integrations = await fetchTeamIntegrations(teamId)
  if (integrations) {
    integrations.forEach(integration => {
      activeIntegrationsMap[integration.id] = integration
    })
  }
  setActiveIntegrationsUi(activeIntegrationsMap)
}


