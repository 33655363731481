
import { required } from '@/utils'
import { getCurrentUser } from '../actions/current-user.mjs'
import { getCurrentTeam } from '../actions/current-team.mjs'

import {
  LIBRARY_LIST, DEFAULT_LIBRARY_NAME,
  createList,
} from '../list-core/constants/list-constants.mjs'

import { DEFAULT_FIELD_SCHEMAS } from '../field-schema/field-schema-default-fields.mjs'

import { ColumnEntity } from '../entity'

/***NEW***/
import { throwError } from '@/utils/errors.mjs'
import { LibraryColumnManager } from '../grid-manager/entities/LibraryColumnManager.mjs'
import { GridItemManager } from '../grid-manager/entities/GridItemManager.mjs'

// events
import {
  EVENT_USER_SIGNOUT,
  EVENT_OPEN_CREATE_VIEW,
  EVENT_OPEN_ADD_TO_VIEW,
  EVENT_OPEN_CREATE_PRICE_LIST,
  EVENT_OPEN_ADD_TO_PRICE_LIST,
  EVENT_SET_SESSION_UPDATED_AT_INTERVAL,
} from '../events/constants/event-types.mjs'
import { dispatch } from '../events/dispatch.mjs'

// list
import { setListUiActionPermissionStates } from '../list-core/permissions/list-ui-action-permissions.mjs'

import {
  startLibraryColumnEvents, stopLibraryColumnEvents,
  startLibraryItemEvents, stopLibraryItemEvents,
} from './events/library-column-event-handlers.mjs'
import { startRightHandMenuEvents, stopRightHandMenuEvents } from '../list-core/right-hand-menu-actions/right-hand-menu-event-actions.mjs'

// test
import { getListAndColumns } from '../list-core/db/get-list-and-columns.mjs'
import { setListSessionStates } from '../list-core/actions/set-list-session-states.mjs'

// local db 
import { startLocalDb } from '../db-local/local-db.mjs'
import { loadDeltaLibraryItems, getLocalItems } from '../item/data/local-db-items.mjs'


let gridItemsManager = null
let gridColumnManager = null


const onLibraryGridReady = async (grid) => {
  gridItemsManager = null
  gridColumnManager = null
  await startLocalDb()
  addEventListener(EVENT_USER_SIGNOUT, onLibraryStop)
  const startTime = Date.now()

  const currentUser = getCurrentUser()
  if (!currentUser.teamId) {
    throwError('User is missing teamId')
  }
  const currentTeam = getCurrentTeam()
  if (!currentTeam.isActive) {
    throwError('Team is inactive')
  }
  if (!currentTeam.libraryListId) {
    throwError('No library list found in currentTeam')
  }

  const { list, columnSet, fieldSchemas } = await getListAndColumns(currentTeam.libraryListId)
  await setListSessionStates(list.id)

  if (!list) {
    throwError('Cannot not load library', currentTeam.libraryListId)
  }
  setListUiActionPermissionStates(list)

  gridColumnManager = new LibraryColumnManager({
    grid,
    list,
    columnSet: columnSet,
    fieldSchemaSet: { fieldSchemas },
  })
  gridColumnManager.start()


  gridItemsManager = new GridItemManager({
    grid,
    list
  })
  gridItemsManager.start()

  await loadDeltaLibraryItems(list.id)
  const getLocalStartTime = Date.now()

  let itemCnt = 0
  await getLocalItems({
    libraryListId: list.id,
    batchCallBack: (batch) => {
      itemCnt += batch.length
      gridItemsManager?.upsertItemsGridOnly(batch, gridColumnManager.getFieldSchemas() )
    }
  })
  console.log('Get local', itemCnt, 'items. Time:', Date.now() - getLocalStartTime, 'ms')

  startLibraryColumnEvents(gridColumnManager, gridItemsManager)
  startLibraryItemEvents(gridColumnManager, gridItemsManager)
  startRightHandMenuEvents(gridColumnManager, gridItemsManager)

  // The startup will use last session info to get delta
  // so always set the latest updatedAtInterval as the last action

  dispatch(EVENT_SET_SESSION_UPDATED_AT_INTERVAL, { id: list.id })
  console.log('Library load Time:', Date.now() - startTime, 'ms')
}



function onLibraryStop() {
  console.log('stop library')
  stopRightHandMenuEvents()
  stopLibraryItemEvents()
  stopLibraryColumnEvents()
  gridItemsManager?.terminate()
  gridColumnManager?.terminate()
  gridItemsManager = null
  gridColumnManager = null
  return
}

export const openCreateView = () => {
  dispatch(EVENT_OPEN_CREATE_VIEW, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas()
  })
}

export const openAddToView = () => {
  dispatch(EVENT_OPEN_ADD_TO_VIEW, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas(),
    selectedItems: gridItemsManager.getSelectedItems()
  })
}


export const openCreatePriceList = () => {
  dispatch(EVENT_OPEN_CREATE_PRICE_LIST, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas()
  })
}

export const openAddToPriceList = () => {
  dispatch(EVENT_OPEN_ADD_TO_PRICE_LIST, {
    columnSet: gridColumnManager.columnSet,
    fieldSchemas: gridColumnManager.getFieldSchemas(),
    selectedItems: gridItemsManager.getSelectedItems()
  })
}

export {
  onLibraryGridReady,
  onLibraryStop, 
}
