// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

// events
import {
  EVENT_COLUMN_FILTER_SAVE_GROUP,
  EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP,
  EVENT_COLUMN_FILTER_QUICK_LOCAL,
  EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE
}
  from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'
import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { SCOPE_TEAM } from '../../events/external-events.mjs'


// session event
import { getSessionEvent } from '../../events/session-events.mjs'
import { getObjectSession, setObjectSession } from '../../session/object-session.mjs'


//#region //--- Filter ---//
export const addEventsColumnQuickFilter = (controller, itemManager) => {
  addEventListener(
    EVENT_COLUMN_FILTER_QUICK_LOCAL,
    (event) => applyColumnFilterQuickEvent(event, itemManager),
    { signal: controller.signal }
  )
}


/**
 * Update the column filter query
 * @param {*} event 
 * @param {*} columnManager 
 */
export const updateFilterColumnsQueryEvent = (event, columnManager) => {
  const { filterValue } = event.detail
  columnManager.setFilteredColumns(filterValue)
}

/**
 * Refresh the filtered columns properties e.g 
 * @param {*} event 
 * @param {*} columnManager 
 */
export const refreshFilteredColumnsPropsEvent = (event, columnManager) => {
  columnManager.refreshFilteredColumns()
}

/**
 * Toggle the apply filters switch on right hand menu
 * @param {*} event 
 * @param {*} itemManager 
 */
export const applyFilterToggleEvent = (event, itemManager) => {
  const { isFilteringActive } = event.detail
  itemManager.toggleFiltering(isFilteringActive)
  const listId = itemManager.list?.id
  if (!listId) {
    return
  }
  getObjectSession(listId).then((listSession) => {
    listSession.data = {
      ...listSession.data,
      isFilteringActive
    }
    setObjectSession(listSession)
  })
}

/**
 * Add a column filter
 * @param {*} event 
 * @param {*} itemManager 
 */
export const addColumnFilterEvent = (event, columnManager, itemManager) => {
  const { column } = event.detail
  const fieldSchema = columnManager.getColumnFieldSchema(column.id) //TODO: JL - to review if still needed because column may already have fieldSchema available instead of just the id. 
  column.fieldSchema = fieldSchema.toObject()
  itemManager.addFilter(column)
}

/**
 * Remove a column filter
 * @param {*} event 
 * @param {*} itemManager 
 */
export const removeColumnFilterEvent = (event, itemManager) => {
  const { filterIdx } = event.detail
  itemManager.removeFilter(filterIdx)
}
//#endregion

//#region //--- Filter rule ---//
/**
 * Set a column filter rule condition
 * @param {*} event 
 * @param {*} itemManager 
 */
export const setColumnFilterRuleConditionEvent = (event, itemManager) => {
  const { filterIdx, ruleIdx, condition } = event.detail
  itemManager.setFilterRuleCondition(filterIdx, ruleIdx, condition)
}

/**
 * Set a column filter rule value
 * @param {*} event 
 * @param {*} itemManager 
 */
export const setColumnFilterRuleValueEvent = (event, itemManager) => {
  const { filterIdx, ruleIdx, value } = event.detail
  itemManager.setFilterRuleValue(filterIdx, ruleIdx, value)
}

/**
 * Add a column filter rule
 * @param {*} event 
 * @param {*} itemManager 
 */
export const addColumnFilterRuleEvent = (event, itemManager) => {
  const { filterIdx } = event.detail
  itemManager.addFilterRule(filterIdx)
}

/**
 * Remove a column filter rule
 * @param {*} event 
 * @param {*} itemManager 
 */
export const removeColumnFilterRuleEvent = (event, itemManager) => {
  const { filterIdx, ruleIdx } = event.detail
  itemManager.removeFilterRule(filterIdx, ruleIdx)
}
//#endregion

//#region //--- Filter group ---//
/**
 * Save a column filter group
 * @param {*} event 
 * @param {*} itemManager 
 */
export const saveColumnCurrentFilterGroupEvent = (event, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { name, isExistingSavedFilterGroup } = event.detail
  const team = getTeamSession()

  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_FILTER_SAVE_GROUP,
    scope,
    teamId: team.id,
    payload: event.detail
  })
  itemManager.saveCurrentFilterGroup(name, isExistingSavedFilterGroup, { event: externalEvent })
  setTimeout(() => emitEvent(externalEvent), 500)

}

/**
 * Save a column filter group external event handler
 * @param {*} event 
 * @returns 
 */
export const saveColumnCurrentFilterGroupRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { name, isExistingSavedFilterGroup } = payload
  dispatch(EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE, { name, isExistingSavedFilterGroup })
}

/**
 * Save a column filter group event remote
 * @param {*} event 
 * @param {*} itemManager 
 * @returns {Object[]} Saved filter groups
 */
export const saveColumnCurrentFilterGroupRemoteEvent = (event, itemManager) => {
  const { name, isExistingSavedFilterGroup } = event.detail
  itemManager.setSavedFilterGroups()
}

/**
 * Delete a column filter group
 * @param {*} event 
 * @param {*} itemManager 
 */
export const deleteColumnFilterGroupEvent = (event, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { filterId } = event.detail
  const team = getTeamSession()

  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP,
    scope,
    teamId: team.id,
    payload: event.detail
  })
  itemManager.deleteFilterGroup(filterId, { event: externalEvent })
  setTimeout(() => emitEvent(externalEvent), 500)

}

/**
 * Delete a column filter group external event handler
 * @param {*} event 
 * @returns 
 */
export const deleteColumnFilterGroupRemoteHandler = (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { filterId } = payload
  dispatch(EVENT_COLUMN_FILTER_SAVE_GROUP_REMOTE, { filterId })
}

/**
 * Delete a column filter group event remote
 * @param {*} event 
 * @param {*} itemManager 
 * @returns {Object[]} Saved filter groups
 */
export const deleteColumnFilterGroupRemoteEvent = (event, itemManager) => {
  const { filterId } = event.detail
  itemManager.deleteFilterGroup(filterId, { event, gridOnly: true })
}

/**
 * Apply a column filter group
 * @param {*} event 
 * @param {*} itemManager 
 * @returns {Object[]} Saved filter groups
 */
export const applyColumnFilterGroupEvent = (event, itemManager) => {
  const { filterId } = event.detail
  itemManager.applyFilterGroup(filterId)
}

/**
 * Cancel a column filter group
 * @param {*} event 
 * @param {*} itemManager 
 * @returns {Object[]} Saved filter groups
 */
export const cancelColumnFilterGroupEvent = (event, itemManager) => {
  const { filterGroupName } = event.detail
  itemManager.clearCurrentFilterGroup()
}

/**
 * Apply quick filter
 * @param {*} event 
 * @param {*} itemManager 
 * @returns {Object[]} Saved filter groups
 */
export const applyColumnFilterQuickEvent = (event, itemManager) => {
  const { searchValue } = event.detail
  itemManager.setQuickFilter(searchValue)
}
//#endregion
