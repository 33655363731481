/**
 * Dataabse collections
 */

export const COLLECTION_FIELD_SCHEMAS   = 'field-schemas'
export const COLLECTION_FILTER_GROUPS   = 'filter-groups'
export const COLLECTION_INVITES         = 'invites'
export const COLLECTION_ITEMS           = 'items'
export const COLLECTION_LISTS           = 'lists'
export const COLLECTION_TEAMS           = 'teams'
export const COLLECTION_USERS           = 'users'
export const COLLECTION_VIEWS           = 'views'
export const COLLECTION_EMAIL           = 'email'
export const COLLECTION_EMAIL_TEMPLATES = 'email-templates'
export const COLLECTION_COLUMN_SETS     = 'column-sets'
export const COLLECTION_COLUMNS         = 'columns'
export const COLLECTION_EVENTS          = 'events'
export const COLLECTION_INTEGRATIONS    = 'integrations'

export const COLLECTION_SESSIONS        = 'sessions'
