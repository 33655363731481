import {
  onTeamPriceListUpsert, onTeamPriceListRemoved,
  onSharedWithUpsert, onSharedWithRemoved
} from '../list-price-list/events/price-list-ui-event-handlers.mjs'

import {
  SharedWithMeListener,
} from '../list-price-list/events/SharedWithMeListener.mjs'

import {
  TeamPriceListListener,
} from '../list-price-list/events/TeamPriceListListener.mjs'

import { startUserListener, stopUserListener } from '../user/actions/user-ui.mjs' 

import { FilterGroupDbListener } from '../grid-manager/events/FilterGroupDbListener.mjs'
import { onLibraryFiltersUpsert, onLibraryFiltersRemoved } from '../list-library/events/library-filter-group-events.mjs'
import { TeamFieldScheamaListener } from '../field-schema/events/TeamFieldSchemaListener.mjs'

import { startListActionEvents, stopListActionEvents } from '../list-core/events/list-action-event-handlers.mjs'

import {startGlobalEventsHandler, stopGlobalEventsHandler, startTeamEventsHandler, stopTeamEventsHandler  } from '../events/external-events.mjs'


let sharedByMeListener = null
let sharedWithMeListener = null
let librarySavedFilters = null
let teamFieldSchemaListener = null
let teamPriceListListener = null

function startViewerEventsAndListeners(user){
  startGlobalEventsHandler()
  sharedWithMeListener = new SharedWithMeListener({
    user: user,
    addedFunction: onSharedWithUpsert,
    modifiedFunction: onSharedWithUpsert,
    removedFunction: onSharedWithRemoved
  })
  sharedWithMeListener.start()

  startUserListener()
}

function startEditorEventsAndListeners(user, team){
  startTeamEventsHandler()
  startListActionEvents()
  startViewerEventsAndListeners(user)

  librarySavedFilters = new FilterGroupDbListener({
    listId: team.libraryListId,
    addedFunction: onLibraryFiltersUpsert,
    modifiedFunction: onLibraryFiltersUpsert,
    removedFunction: onLibraryFiltersRemoved
  })
  librarySavedFilters.start()

  teamPriceListListener = new TeamPriceListListener({
    teamId: team.id,
    addedFunction: onTeamPriceListUpsert,
    modifiedFunction: onTeamPriceListUpsert,
    removedFunction: onTeamPriceListRemoved
  })
  teamPriceListListener.start()
  teamFieldSchemaListener = new TeamFieldScheamaListener({ teamId: team.id })
  teamFieldSchemaListener.start()
}

function stopMainMenuListeners() {
  console.log('stopMainMenuListeners')
  stopGlobalEventsHandler()
  stopTeamEventsHandler()
  stopListActionEvents()
  sharedByMeListener?.stop()
  sharedWithMeListener?.stop()
  librarySavedFilters?.stop()
  teamFieldSchemaListener?.stop()
  teamPriceListListener?.stop()
  stopUserListener()
}

export {
  stopMainMenuListeners, startEditorEventsAndListeners, startViewerEventsAndListeners
}
