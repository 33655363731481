/*!
 * List grid manager entity
 */
import { ref, triggerRef } from 'vue'
// Utils
import { throwError } from '../../utils/errors.mjs'
import { TRUE, FALSE } from '../../constants/types.mjs'
import { required } from '../../utils/required.mjs'
import { isEmptyNullOrUndefined } from '../../utils/is.mjs'

// constants
import { ITEM_COMPILE_BATCH_SIZE, SAVE_ITEMS_BATCH_SIZE } from '../../constants/batch-sizes.mjs'
import { ITEM_TYPE_CSV } from '../../item/constants/item-types.mjs'
// ui
import { selectedItemsCount } from '../../ui/ui-states.mjs'

// sessions
import { getTeamSession } from '../../session/team-session.mjs'

import { FilterGroupEntity } from '../entities/FilterGroupEntity.mjs'
import { saveFilterGroupEntity, getListFilterGroups } from '../db/filter-group-db.mjs'

import { matchFilters } from '../utils/filter-utils.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import { ItemEntity } from '../../entity/ItemEntity.mjs'

import { compileItem } from '../../item/utils/compile-item.mjs'
import { saveItemsEntities } from '../../item/db/item-db.mjs'

import { createStaticItem } from '../../item/utils/create-static-item.mjs'
import { formatItemValues } from '../../item/utils/format-item-values.mjs'

export const currentListFilterGroups = ref([])

// UI states
import { savedFilterGroups, currentFilters, filterGroupName, isFilteringActive } from '../../ui/ui-states.mjs'

import { getLocalItemsBySourceId, getLocalItems } from '../../item/data/local-db-items.mjs'

import { stopProgressEvents } from '../../ui/progress-status.mjs'

export class GridItemManager {
  #grid
  #gridApi
  #columnApi
  #list
  #filterGroups = []
  #currentFilterGroup

  constructor({
    grid,
    currentFilterGroup,
    list
  } = {}) {
    grid && this.setGrid(grid)
    this.#currentFilterGroup = currentFilterGroup || new FilterGroupEntity()
    this.#list = list
    //this.#currentFilterGroup = new FilterGroupEntity()

  }

  get list() { return this.#list }

  //#region //--- Grid methods ---//
  setGrid(grid) {
    this.#grid = grid
    this.#gridApi = this.#grid.api
    this.#columnApi = this.#grid.columnApi
  }

  start() {
    selectedItemsCount.value = 0
    this.#gridApi.setRowData()
    this.setSavedFilterGroups()
  }

  terminate() {
    this.#gridApi.setRowData()
    this.#grid = undefined
    this.#gridApi = undefined
    this.#columnApi = undefined
    this.#list = undefined
  }

  //#endregion

  //#region //--- grid functions ---//
  #getRowNode(id) {
    return this.#gridApi?.getRowNode(id)
  }

  //#endregion


  //#region //--- grid items ---//

  #createTransactions(items, atIndex) {
    let transactions = {}
    const add = []
    const update = []
    const remove = []
    for (const item of items) {
      const existingItem = this.#getRowNode(item.id)
      if (item.isDeleted && !existingItem) {
        continue
      }
      if (item.isDeleted) {
        remove.push(item)
        continue
      }
      if (!existingItem) {
        add.push(item)
        continue
      }
      if (existingItem) {
        update.push(item)
        continue
      }
    }
    if (!isEmptyNullOrUndefined(atIndex)) { transactions.addIndex = atIndex }
    if (add.length > 0) { transactions.add = add }
    if (update.length > 0) { transactions.update = update }
    if (remove.length > 0) { transactions.remove = remove }
    return transactions
  }

  #createRemoveTransactions(items) {
    let transactions = { remove: [] }
    for (const item of items) {
      const existingItem = this.#getRowNode(item.id)
      if (existingItem) {
        transactions.remove.push(item)
        continue
      }
    }
    if (transactions.remove.length === 0) { delete transactions.remove }
    return transactions
  }

  upsertGridItems(items) {
    const transactions = this.#createTransactions(items)
    this.#gridApi?.applyTransaction(transactions)
  }


  deleteGridItems(items) {
    const transactions = this.#createRemoveTransactions(items)
    this.#gridApi?.applyTransaction(transactions)

  }

  removeGridItems(items) {
    const transactions = this.#createRemoveTransactions(items)
    this.#gridApi?.applyTransaction(transactions)

  }

  selectAll() {
    this.#gridApi?.selectAll()
  }

  autoSizeAllColumns() {
    this.#columnApi.autoSizeAllColumns()
  }

  //#endregion

  //#region //--- Item local methods ---//
  clearItems() {
    this.#gridApi.setRowData()
  }

  async addItem(
    fieldSchemas = required('fieldSchemas'),
    {
      gridOnly = false
    } = {}
  ) {
    const team = getTeamSession()
    if (!team?.id) {
      return
    }
    let item = new ItemEntity({
      teamId: team.id,
      libraryListId: team.libraryListId,
    })
    if (this.#list.id !== team.libraryListId) {
      item.addListId(this.#list.id)
    }
    if (!gridOnly) {
      item.setCreated().setUpdated()
    }
    compileItem({ item, fieldSchemas })
    this.upsertGridItems([item])
    if (!gridOnly) {
      await ItemEntity.upsert(item)
    }
    return item
  }

  async updateItem(
    item = required('item'),
    fieldSchemas = required('fieldSchemas'),
    {
      keys,
      gridOnly = false
    } = {}
  ) {
    const startTime = Date.now()
    if (!gridOnly) {
      item.setCreated().setUpdated()
    }
    compileItem({ item, keys, fieldSchemas })
    this.upsertGridItems([item])
    console.log('updateItem time', Date.now() - startTime, 'ms')
    if (!gridOnly) {
      await ItemEntity.upsert(item)
    }
  }

  getSelectedItems() {
    return this.#gridApi.getSelectedRows()
  }

  getLastSelctedIndex() {
    const selected = this.#gridApi.getSelectedNodes()
    if (!selected || selected.length === 0) {
      return -1
    }
    return selected[selected.length - 1].rowIndex
  }

  /**
   * update selected items
   * @param {*} key 
   * @param {*} inputValue 
   * @returns 
   */

  updateItems(
    items = required('items'),
    key = required('fieldKey'),
    inputValue = CHAR_EMPTY,
    {
      event
    } = {}
  ) {
    if (items.length === 0) {
      return
    }
    for (const item of items) {
      item.fields[key] = {
        ...item.fields[key],
        inputValue
      }
      item.setUpdated()
      if (event) {
        item.updatedByEventId = event.id
      }
    }

    return items
  }
  /**
   * update selected items
   * @param {*} key 
   * @param {*} inputValue 
   * @returns 
   */
  updateSelectedItems(
    key = required('fieldKey'),
    inputValue = CHAR_EMPTY,
    {
      event
    } = {}
  ) {
    const selected = this.#gridApi.getSelectedNodes()
    if (selected.length === 0) {
      return
    }
    for (const node of selected) {
      const item = node.data
      item.fields[key] = {
        ...item.fields[key],
        inputValue
      }
      item.setUpdated()
      if (event) {
        item.updatedByEventId = event.id
      }
    }

    return selected
  }

  deleteSelectedItems({ event }) {
    const removedItems = []
    const selected = this.#gridApi.getSelectedNodes()
    if (selected.length === 0) {
      return
    }
    for (const node of selected) {
      const item = node.data
      item.markAsDeleted()
      item.setUpdated().setDeleted()
      if (event) {
        item.updatedByEventId = event.id
      }
      removedItems.push(item)
    }
    this.deleteGridItems(selected)
    return removedItems
  }


  removeSelectedItems(listId, { event }) {
    const removedItems = []
    const selected = this.#gridApi.getSelectedNodes()
    if (selected.length === 0) {
      return
    }
    for (const node of selected) {
      const item = node.data
      item.removeListId(listId)
      item.setUpdated()
      if (event) {
        item.updatedByEventId = event.id
      }
      removedItems.push(item)
    }
    this.deleteGridItems(selected)
    return removedItems
  }

  removeItemsNotInIds(itemIds) {
    const removeItems = []
    this.#gridApi.forEachNode((node) => {
      const item = node.data
      if (itemIds.includes(item.id)) {
        return
      }
      removeItems.push(item)
    })
    this.removeItemsGridOnly(removeItems)
  }

  /**
   * Compile selected items
   * @param {*} fieldSchemas 
   * @param {*} keys 
   * @returns 
   */
  compileSelectedItems(
    fieldSchemas = required('fieldSchemas'),
    keys
  ) {
    const selected = this.#gridApi.getSelectedNodes()
    if (selected.length === 0) {
      return
    }
    let cnt = 0
    let batch = []
    selected.forEach((node) => {
      const item = node.data
      compileItem({ item, keys, fieldSchemas })
      batch.push(item)
      cnt++
      if (cnt >= ITEM_COMPILE_BATCH_SIZE) {
        this.#gridApi.refreshCells({ rowNodes: batch })
        cnt = 0
        batch = []
      }
    })

    if (batch.length > 0) {
      this.#gridApi.refreshCells({ rowNodes: batch })
    }
    return selected
  }


  compileAllItems(
    fieldSchemas = required('fieldSchemas'),
    keys
  ) {
    const startTime = Date.now()
    let cnt = 0
    let batch = []

    this.#gridApi.forEachNode((node) => {
      const item = node.data
      if (item.type !== ITEM_TYPE_CSV) {
        compileItem({ item, keys, fieldSchemas })
      }
      batch.push(item)
      cnt++
      if (cnt >= ITEM_COMPILE_BATCH_SIZE) {
        this.#gridApi.refreshCells({ rowNodes: batch })
        cnt = 0
        batch = []
      }
    })

    if (batch.length > 0) {
      this.#gridApi.refreshCells({ rowNodes: batch })
    }
    console.log('compile and refresh grid time', Date.now() - startTime, 'ms')
  }

  formatAllItems(
    fieldSchemas = required('fieldSchemas')
  ) {
    const startTime = Date.now()
    let cnt = 0
    let batch = []
    const fieldSchemasMap = new Map(fieldSchemas.map(fs => [fs.key, fs]))
    this.#gridApi.forEachNode((node) => {
      const item = node.data
      if (item.type === ITEM_TYPE_CSV) {
        return
      }
      batch.push(formatItemValues(item, fieldSchemasMap))
      cnt++
      if (cnt >= ITEM_COMPILE_BATCH_SIZE) {
        this.#gridApi.refreshCells({ rowNodes: batch })
        cnt = 0
        batch = []
      }
    })
    if (batch.length > 0) {
      this.#gridApi.refreshCells({ rowNodes: batch })
    }
    console.log('format all and refresh grid time', Date.now() - startTime, 'ms')
  }

  clearAllItemsErrors() {
    this.#gridApi.forEachNode((node) => {
      const item = node.data
      item.clearErrorsAndWarnings()
    })
  }

  clearSelectedItemsErrors(items) {
    for (const item of items) {
      item.clearErrorsAndWarnings()
    }
  }

  //#endregion

  //#region //--- ui items only ---//
  async upsertItemsGridOnly(
    items = required('items'),
    fieldSchemas = required('fieldSchemas'),
    keys = [],
  ) {
    let cnt = 0
    let batch = []
    for (const item of items) {
      const displayItem = (item.type === ITEM_TYPE_CSV || item.isDeleted) ? item
        : compileItem({ item, keys, fieldSchemas })
        batch.push(displayItem)

      if (cnt >= ITEM_COMPILE_BATCH_SIZE) {
        this.upsertGridItems([...batch])
        cnt = 0
        batch = []
      }
    }
    if (batch.length > 0) {
      this.upsertGridItems(batch)
    }
  }
  async removeItemsGridOnly(
    items = required('items'),
  ) {
    let cnt = 0
    let batch = []
    for (const item of items) {
      batch.push(item)
      if (cnt >= ITEM_COMPILE_BATCH_SIZE) {
        this.removeGridItems([...batch])
        cnt = 0
        batch = []
      }
    }
    if (batch.length > 0) {
      this.removeGridItems(batch)
    }
    return
  }


  async addSelectedToView(viewListId) {
    const startTime = Date.now()
    const selected = this.#gridApi.getSelectedRows()
    if (selected.length === 0) {
      return
    }
    let cnt = 0
    let batch = []
    for (const item of selected) {
      item.addListId(viewListId)
      item.setUpdated()
      batch.push(item)
      cnt++
      if (cnt >= SAVE_ITEMS_BATCH_SIZE) {
        await saveItemsEntities(batch)
        cnt = 0
        batch = []
      }

    }

    if (batch.length > 0) {
      await saveItemsEntities(batch)
    }
  }
  //endregion

  //#region //--- static filter methods for AG-grid ---//
  static doesExternalFilterPass(node) {
    if (!isFilteringActive.value) return TRUE
    const item = node.data
    return matchFilters(item, currentFilters.value)
  }

  static isExternalFilterPresent(bool = isFilteringActive.value) {
    return bool
  }
  //#endregion

  //#region //--- Filters ---//

  #onFilterChanged(filters) {
    currentFilters.value = filters
    triggerRef(currentFilters)
    this.#gridApi.onFilterChanged()
  }

  setFilteringOn() {
    isFilteringActive.value = TRUE
    this.#gridApi.onFilterChanged()
  }

  setFilteringOff() {
    isFilteringActive.value = FALSE
    this.#gridApi.onFilterChanged()
  }

  toggleFiltering(bool) {
    isFilteringActive.value = bool
    this.#gridApi.onFilterChanged()
  }

  get currentFilters() {
    return this.#currentFilterGroup.filters
  }

  setQuickFilter(value) {
    this.#gridApi.setQuickFilter(value)
  }

  addFilter(column) {
    this.#currentFilterGroup.addFilter(column)
    this.#onFilterChanged(this.currentFilters)
  }

  removeFilter(filterIdx) {
    this.#currentFilterGroup.removeFilter(filterIdx)
    this.#onFilterChanged(this.currentFilters)
  }

  addFilterRule(filterIdx, data = {}) {
    this.#currentFilterGroup.addRule(filterIdx, data)
    this.#onFilterChanged(this.currentFilters)
  }

  removeFilterRule(filterIdx, ruleIdx) {
    this.#currentFilterGroup.removeRule(filterIdx, ruleIdx)
    this.#onFilterChanged(this.currentFilters)
  }

  async addSelectedToPriceList(listId, columnSet, {
    event
  } = {}) {
    const selected = this.#gridApi.getSelectedNodes()
    if (selected.length === 0) {
      return
    }
    let cnt = 0
    let batch = []
   
    const sourceItemsMap = new Map()
    await getLocalItems({
      listId: listId,
      batchCallBack: (batch) => {
        for (const item of batch) {
          if (item.isDeleted) {
            continue
          }
          sourceItemsMap.set(item.sourceId, item)
        }
      }
    })
    let getLocalStartTime = Date.now()
    for (const node of selected) {
      let item = createStaticItem(node.data, listId, columnSet)
      item.sourceId = node.data.id

      const sourceItem = sourceItemsMap.get(item.sourceId)
      if (sourceItem) {
        // use existing item details
        item = new ItemEntity({
          ...item.toObject(),
          id: sourceItem.id,
          sourceId: node.data.id,
          createdAt: sourceItem.createdAt,
          createdBy: sourceItem.createdBy
        })
      }

      if (event) {
        item.updatedByEventId = event.id
      }
      batch.push(item)
      cnt++
      if (cnt >= SAVE_ITEMS_BATCH_SIZE) {
        console.log('get local items batch size', cnt, 'duration ', Date.now() - getLocalStartTime, 'ms', Date.now())
        await saveItemsEntities(batch)

        cnt = 0
        batch = []
        getLocalStartTime = Date.now()

      }
    }

    if (batch.length > 0) {
      await saveItemsEntities(batch)
    }
    stopProgressEvents()
  }
  //#endregion


  async saveSelectedItems(items, { event } = {}) {
    const selected = items || this.#gridApi.getSelectedRows()
    if (selected.length === 0) {
      return
    }
    let cnt = 0
    let batch = []
    for (const data of selected) {
      const item = data
      if (event) {
        item.updatedByEventId = event.id
      }
      batch.push(item)
      cnt++
      if (cnt >= SAVE_ITEMS_BATCH_SIZE) {
        await saveItemsEntities(batch)
        cnt = 0
        batch = []
      }
    }

    if (batch.length > 0) {
      await saveItemsEntities(batch)
    }
  }

  //#region //--- Filter rule ---//

  setFilterRuleCondition(filterIdx, ruleIdx, condition) {
    this.#currentFilterGroup.setRuleCondition(filterIdx, ruleIdx, condition)
    this.#onFilterChanged(this.currentFilters)
  }

  setFilterRuleValue(filterIdx, ruleIdx, value) {
    this.#currentFilterGroup.setRuleValue(filterIdx, ruleIdx, value)
    this.#onFilterChanged(this.currentFilters)
  }

  //#endregion

  //#region //--- Filter group ---//
  get filterGroups() {
    return this.#filterGroups
  }

  /**
   * Set Saved filter groups and update savedFilterGroups ref for right side menu
   * @param {*} filterGroups 
   * @returns {Object} saved filter groups
   */
  async setSavedFilterGroups(filterGroups) {
    this.#filterGroups = filterGroups || await getListFilterGroups(this.#list.id)
    savedFilterGroups.value = this.#filterGroups
    triggerRef(savedFilterGroups)
    return this.#filterGroups
  }

  /**
   * Add/update/remove a filter group in SavedFilterGroups
   * @param {Object} filterGroup 
   * @returns {Object} saved filter groups
   */
  updateSavedFilterGroups(filterGroup) {
    const index = this.#filterGroups.findIndex(fg => fg.id === filterGroup.id)
    if (index === -1) {
      this.addFilterGroup(filterGroup)
    } else {
      const removeOrUpdate = filterGroup.isDeleted
        ? this.removeFilterGroup(filterGroup)
        : this.updateFilterGroup(filterGroup)
    }
    return this.setSavedFilterGroups(this.#filterGroups)
  }



  /**
   * Save and set current Filter Group
   * @param {string} name - name of the filter group
   * @param {bool} isExistingSavedFilterGroup 
   * @param {Object} options: { event, gridOnly }  
   * @returns {Object} current filter group
   */
  saveCurrentFilterGroup(
    name,
    isExistingSavedFilterGroup = false,
    {
      event
    } = {}) {
    const filterGroup = isExistingSavedFilterGroup
      ? this.saveFilterGroup(name, event)
      : this.saveFilterGroupAsNew(name, event)
    this.updateSavedFilterGroups(filterGroup)
    const copiedFilterGroup = filterGroup.clone()
    this.setCurrentFilterGroup(copiedFilterGroup)

    if (event) {
      event.payload = {
        name: name,
        isExistingSavedFilterGroup: isExistingSavedFilterGroup
      }
    }
    return this.#currentFilterGroup
  }

  saveFilterGroup(name, event) {
    const existingFilterGroup = this.#filterGroups.find(filterGroup => filterGroup.name.toLowerCase() === name.toLowerCase())
    existingFilterGroup.name = name
    existingFilterGroup.listId = this.#list.id
    existingFilterGroup.filters = this.#currentFilterGroup.filters
    event && this.#setFilterGroupEvent(existingFilterGroup, event)
    saveFilterGroupEntity(existingFilterGroup)
    return existingFilterGroup
  }

  saveFilterGroupAsNew(name, event) {
    const newFilterGroup = this.#currentFilterGroup.cloneAsNew()
    newFilterGroup.name = name
    newFilterGroup.listId = this.#list.id
    event && this.#setFilterGroupEvent(newFilterGroup, event)
    saveFilterGroupEntity(newFilterGroup)
    return newFilterGroup
  }


  setCurrentFilterGroup(filterGroup) {
    this.#currentFilterGroup = filterGroup
    filterGroupName.value = this.#currentFilterGroup?.name
    triggerRef(filterGroupName)
    this.#onFilterChanged(this.currentFilters)
  }

  clearCurrentFilterGroup() {
    this.setCurrentFilterGroup(new FilterGroupEntity({ id: this.#list.id }))
    this.setFilteringOff()
  }

  /**
   * Set filter group as deleted and remove it from filter groups 
   * @param {number} filterId 
   * @param {Object} options: { event, gridOnly } 
   * @returns {Object[]} filter groups
   */
  deleteFilterGroup(filterId,
    {
      event,
      gridOnly = false
    } = {}) {
    const filterGroup = this.#filterGroups.find(fg => fg.id === filterId)
    filterGroup.markAsDeleted()
    event && this.#setFilterGroupEvent(filterGroup, event)
    if (filterGroup.id === this.#currentFilterGroup?.id) {
      this.setFilteringOff()
    }
    if (!gridOnly) saveFilterGroupEntity(filterGroup)
    this.updateSavedFilterGroups(filterGroup)
    if (event) {
      event.payload = {
        filterId: filterId,
      }
    }
    return this.#filterGroups
  }

  addFilterGroup(filterGroup) {
    this.#filterGroups.push(filterGroup)
    return this.#filterGroups
  }

  updateFilterGroup(filterGroup) {
    const idx = this.#filterGroups.findIndex(fg => fg.id === filterGroup.id)
    if (idx >= 0) {
      this.#filterGroups[idx] = filterGroup
    }
    return this.#filterGroups
  }

  removeFilterGroup(filterGroup) {
    const idx = this.#filterGroups.findIndex(fg => fg.id === filterGroup.id)
    if (idx >= 0) {
      this.#filterGroups.splice(idx, 1)
    }
    return this.#filterGroups
  }

  /**
   * Apply a filter group from saved filter groups
   * @param {*} filterId
   */
  applyFilterGroup(filterId) {
    const filterGroup = this.#filterGroups.find(fg => fg.id === filterId)
    if (!filterGroup) {
      return
    }
    //make a copy to prevent modification of the existing filter in saved filter groups
    const copiedFilterGroup = filterGroup.clone()
    this.setCurrentFilterGroup(copiedFilterGroup)
    if (!isFilteringActive.value) this.setFilteringOn()
  }
  //#endregion

  //#region //--- Private filter group methods ---//
  #setFilterGroupEvent(filterGroupEntity, event) {
    if (!event) {
      return
    }
    filterGroupEntity.updatedByEventId = event.id
    event.payload = {
      ...event.payload,
      filterGroupId: filterGroupEntity.id
    }
  }
  //#endregion

  //#region //--- batch errors ---//
  consoleLogBatchErrors() {
    this.#gridApi.forEachNode(node => {
      const item = node.data
      if (item.error === 'Batch Errors') {
        console.error('Batch Errors', item.id, item.batchResults)
      }

    })
    return
  }

  setItemsLogStatus(logStatuses) {
    let batch = []
    if (!logStatuses) {
      return
    }

    this.#gridApi.forEachNode(node => {
      const item = node.data
      if (!logStatuses[item.id]) {
        return
      } 
      
      item.logStatus = logStatuses[item.id].status
      batch.push(node)
    })
    this.#gridApi.refreshCells({ rowNodes: batch })
    return
  }

  setSelectedItemsLogStatus(status) {
    const selected = this.#gridApi.getSelectedNodes()
    for (const node of selected) {
      node.data.logStatus = status
    }
    this.#gridApi.refreshCells({ rowNodes: selected })
  }

  clearItemsLogStatus() {
    let batch = []
    this.#gridApi.forEachNode(node => {
      const item = node.data
      if (!item.logStatus) {
        return
      }
      item.logStatus = CHAR_EMPTY
      batch.push(node)
    })
    this.#gridApi.refreshCells({ rowNodes: batch })
    return
  }

  //#endregion
}
