<template>
  <main-layout>
    <q-toolbar class="row items-center q-px-md q-pt-lg q-pb-xs">
      <q-toolbar-title class="text-dark text-weight-bold text-center">{{ $route.meta.title }}</q-toolbar-title>
    </q-toolbar>

    <template v-if="isLoading">
      <div style="position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%; top:0; left: 0; z-index: 99; background-color: rgba(255, 255, 255, 0.6);"
        :style="isMainMenuOpen ? 'margin-left: 150px;' : ''">
        <div class="is-loading"></div>
      </div>
    </template>

    <!-- Main Container -->
    <div class="container">

      <h6>Overview</h6>

      <!-- Integration Name -->
      <div class="input-group">
        <label>Integration Name</label>
        <q-input v-model="integrationName" :rules="[validateIntegrationName]" debounce="200" color="grey-7" bg-color="accent" outlined dense style="width: 60%;" />
      </div>

      <!-- Connection Header -->
      <h6>Connection</h6>
      <div style="color: #8e8e8e; background-color: #efefef; padding: .5rem 1rem; border-radius: .4rem; font-size: .8rem; margin-bottom: 1rem;">
        <div><strong>Read</strong> permission for objects: Product2, Pricebook2, PricebookEntry</div>
        <div><strong>Read/Edit</strong> Access to import/mapped fields: Product2, PricebookEntry</div>            
      </div>

      <!-- Username -->
      <div class="input-group">
        <label>Username</label>
        <q-input v-model="username" color="grey-7" bg-color="accent" outlined dense  style="width: 100%;" autocomplete="off" />
      </div>

      <!-- Password -->
      <div class="input-group">
        <label>Password</label>
        <q-input :type="showPassword ? 'text' : 'password'" v-model="userPassword" color="grey-7" bg-color="accent" autocomplete="off" outlined dense style="width: 100%;">
          <template v-slot:append>
            <q-icon
              :name="showPassword ? 'o_visibility' : 'o_visibility_off'"
              class="cursor-pointer"
              size="xs"
              @click="showPassword = !showPassword"
              clearable="true"
            />
          </template>
        </q-input>
      </div>

      <!-- Security Token -->
      <div class="input-group">
        <label>Security Token</label>
        <q-input v-model="securityToken" color="grey-7" bg-color="accent" outlined dense  style="width: 100%;" />
      </div>

      <!-- Instance URL -->
      <div class="input-group">
        <label>Instance URL</label>
        <q-input v-model="instanceUrl" color="grey-7" bg-color="accent" outlined dense  style="width: 100%;" />
      </div>

      <!-- Response Message -->
      <div v-if="testResponse?.length" style="color: #888; margin-top: 1rem; background-color: #efefef; border-radius: .4rem; padding: 1rem; font-size: 0.7rem; font-family: monaco, monospace, 'Courier New', Courier; max-height: 200px overflow: auto;">
        <strong>Test Result:</strong>
        <br><br>
        {{ testResponse }}
      </div>

      <div style="display: flex; justify-content: flex-end; margin-top: 2rem;">
        <!-- Test Connection Button -->
        <q-btn
          @click="onTestConnection"
          no-caps
          unelevated
          color="secondary"
          text-color="#aaa"
          type="submit"
          style="margin-right: 1rem;"
          label="Test Connection"
          :loading="isTestingConnection"
          v-show="integrationName
            && username 
            && userPassword 
            && securityToken 
            && instanceUrl"
        />

        <!-- Connection Save Button -->
        <q-btn
          @click="onSaveConnection"
          no-caps
          unelevated
          style="margin-right: 1rem;"
          type="submit"
          color="primary"
          text-color="white"
          :loading="isSavingConnection"
          label="Save Connection"
        />

        <!-- Import Button -->
        <q-btn
          @click="onImport"
          no-caps
          unelevated
          type="submit"
          color="primary"
          text-color="white"
          label="Import from Salesforce"
          v-show="integrationName
            && username 
            && userPassword 
            && securityToken 
            && instanceUrl
            && integrationId"
        />

      </div>

      <template v-if="integrationName
        && username
        && userPassword
        && securityToken
        && instanceUrl
        && integrationId"
      >
        <!-- Standard Pricebook -->
        <h6>
          Standard Pricebook
          <q-btn 
            @click="onRefreshCache()" 
            :loading="isRefreshCache"
            icon="refresh" 
            label="Reload from Salesforce" 
            tyle="float: right;" 
            unelevated 
            text-color="primary" 
            no-caps />
        </h6>

        <table style="width: 800px;">
          <thead>
            <tr>
              <th style="width: 45%; text-align: left; padding-bottom: 1rem;">Salesforce Pricebook</th>
              <th style="width: 55%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <q-select
                  color="grey-7" bg-color="accent" outlined dense
                  v-model="selectedStdPricebook"
                  @update:model-value="onSaveMapping"
                  :options="stdPricebooks"
                  style="width: 100%; margin-right: 1rem;"
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <!-- Custom Pricebooks -->
        <h6>Custom Pricebooks</h6>

        <table style="width: 800px; margin-top: 1rem;">
          <thead>
            <tr>
              <th style="width: 45%; text-align: left; padding-bottom: 1rem;">Salesforce Pricebook</th>
              <th style="width: 55%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pricebook, index) in selectedCustomPricebooks" :key="index">
              <td>
                <q-select
                  color="grey-7" bg-color="accent" outlined dense
                  v-model="selectedCustomPricebooks[index]"
                  @update:model-value="onSaveMapping"
                  :options="customPricebooks"
                  style="width: 100%; margin-right: 1rem;"
                />
              </td>
              <td>
                <q-btn @click="onRemoveCustomPricebook(index)" unelevated icon="close" />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="input-group">
          <q-btn icon="add" no-caps unelevated text-color="primary" @click="onAddCustomPricebook">Add Pricebook</q-btn>
        </div>

        <!-- Field Mapping Header -->
        <h6>Product Fields</h6>

        <table style="width: 800px; margin-top: 1rem;">
          <thead>
            <tr>
              <th style="width: 45%; text-align: left; padding-bottom: 1rem;">Salesforce Field</th>
              <th style="width: 55%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mapping, index) in selectedSfProductFields" :key="index">
              <td>
                <q-select
                  v-model="selectedSfProductFields[index]"
                  @update:model-value="onSaveMapping"
                  :options="sfProductFields"
                  style="width: 100%; margin-right: 1rem;"
                  color="grey-7" bg-color="accent" outlined dense
                />
              </td>
              <td>
                <q-btn @click="onRemoveFieldMap(index)" unelevated icon="close" />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="input-group">
          <q-btn icon="add" no-caps unelevated text-color="primary" @click="onAddFieldMap">Add import field</q-btn>
        </div>
      </template>
    </div>
    <!-- End: Main Container -->

  </main-layout>
</template>

<style scoped>
.container {
  width: 100%;
  margin: auto;
  max-width: 800px;
  padding-bottom: 3rem;
}

.container label {
  min-width: 18rem;
  color: #6e6e6e;
  font-weight: 500;
}

.container h6 {
  border-bottom: 1px solid #efefef;
  margin: 1rem 0;
  margin-top: 2rem;
}

.container .input-group {
  display: flex;
  align-items: center;
  padding-top: .4rem;
}

</style>

<script setup>

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Styles
import '../../../styles/theme.css'

// Layouts
import MainLayout from '../../../layouts/MainLayout.vue'

// Components

// Refs
import {
  integrationName,
  validateIntegrationName,
  parseIntegrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import { 
  // connection UI
  testResponse, 
  username,
  userPassword,
  securityToken,
  instanceUrl,

  //configuration UI
  selectedSfProductFields,
  sfProductFields,
  stdPricebooks,
  customPricebooks,
  selectedStdPricebook,
  selectedCustomPricebooks,
} from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'

import { isMainMenuOpen } from '../../../ui/ui-states.mjs'

// events
import { EVENT_INTEGRATION_DELETED_REMOTE } from '../../../events/constants/event-types.mjs'
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_IMPORT_BASIC } from '../../registered-apps.mjs'

// Actions

import { createIntegratonFromUi, saveIntegration } from '../../../integration-modules/salesforce/actions/save-integration.mjs'
import { startSalesforceUi, stopSalesforceUi } from '../../../integration-modules/salesforce/actions/salesforce-connection-ui.mjs'

import { startSalesforceImportUi, stopSalesforceImportUi, reloadSalesforceImportUi } from '../actions/ui-actions.mjs'

import { testConnection } from '../../../integration-modules/salesforce/actions/test-connection.mjs'
import { refreshCache } from '../../../integration-modules/salesforce/actions/refresh-cache.mjs'
import { createImportCsv } from '../../../integration-modules/salesforce/actions/create-import-csv.mjs'

// Setup
const INTEGRATION_PATH = '/integrations'
const router = useRouter()
const showPassword = ref(false)
const isTestingConnection = ref(false)
const isSavingConnection = ref(false)
const isRefreshCache = ref(false)
const isLoading = ref(true)

let integrationId = useRoute().params.id

const EMPTY_FIELD = { key: null, label: null }

// Mount hook
onMounted(async () => {
  addEventListener(EVENT_INTEGRATION_DELETED_REMOTE, onIntegrationDeleted )
  await startSalesforceUi(integrationId)
  await startSalesforceImportUi(integrationId)
  isLoading.value = false
})

onUnmounted(() => {
  stopSalesforceUi()
  stopSalesforceImportUi()
})

/**
 * reset path when integration is deleted remote
 */
function onIntegrationDeleted(event) {
  if (event.detail === integrationId ) {
    router.push({ path: INTEGRATION_PATH })
  }
}

/**
 * Save Connection
 */
async function onSaveConnection() {
  isSavingConnection.value = true
  integrationName.value = parseIntegrationName(integrationName.value)
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  const response = await saveIntegration(integration)
  //TODO: PL - error handling on save
  if (!response.success) {
    console.log(response.error)
    return
  }
  
  const routeChanged = integrationId !== response.data.id
  integrationId = response.data.id
  isSavingConnection.value = false
  if (routeChanged) {
    location.href = response.data.subtype + '/' + response.data.id
  }
}

async function onTestConnection() {
  isTestingConnection.value = true
  const result = await testConnection({
    username: username.value,
    password: userPassword.value,
    securityToken: securityToken.value,
    instanceUrl: instanceUrl.value
  })
  isTestingConnection.value = false
  return result
}

async function onRefreshCache() {
  if (!integrationId) {
    return
  }
  isRefreshCache.value = true
  await refreshCache(integrationId)
  reloadSalesforceImportUi(integrationId)
  isRefreshCache.value = false
}

async function onSaveMapping() {
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return saveIntegration(integration)
}

async function onImport() {
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return createImportCsv(integration)
}

async function onAddCustomPricebook() {
  selectedCustomPricebooks.value.push(EMPTY_FIELD)
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return saveIntegration(integration)
}

async function onAddFieldMap() {
  selectedSfProductFields.value.push(EMPTY_FIELD)
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return saveIntegration(integration)
}

async function onRemoveCustomPricebook(index) {
  selectedCustomPricebooks.value.splice(index, 1)
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return saveIntegration(integration)
}

async function onRemoveFieldMap(index) {
  selectedSfProductFields.value.splice(index, 1)
  const integration = await createIntegratonFromUi(APP_SFDC_IMPORT_BASIC)
  return saveIntegration(integration)
}

</script>
