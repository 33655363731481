// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// Dependencies
import { unparse as parseToCsv } from 'papaparse'

// events
import {
  EVENT_ITEMS_DOWNLOAD_AS_CSV
} from '../../events/constants/event-types.mjs'

//utils
import { isEmpty, isArray } from '../../utils/is.mjs'
import { downloadStringAsFile, downloadFile } from '../../utils/file.mjs'

import {
  CHAR_COMMA, CHAR_EMPTY,
} from '../../constants/characters.mjs'

import {
  FIELD_TYPE_DATE, FIELD_TYPE_CHECKBOX, FIELD_TYPE_CURRENCY, FIELD_TYPE_NUMBER
} from '../../constants/field.mjs'

import { getListCsv, getListCsvMeta } from '../../storage/list-file-storage.mjs'

export const addEventsDownloadAsCsv = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_ITEMS_DOWNLOAD_AS_CSV,
    (event) => downloadAsCsvEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

export const downloadAsCsvEvent = async (event, columnManager, itemManager) => {

  if (columnManager.list.csvFilePath) {
    const file = await getListCsv({filePath: columnManager.list.csvFilePath })
    return downloadFile(file,columnManager.list.name )
  }
  let fieldSchemas = []
  for (const col of columnManager.columnSet.columns) {
    if (col.isDeleted || col.isHidden) {
      continue
    }
    const fs = columnManager.getColumnFieldSchema(col.id)
    if (!fs || fs.isDeleted) {
      console.error('no field schema found', col) 
      continue
    }
    fieldSchemas.push(fs)
  }
  const items = itemManager.getSelectedItems()
  const arr = makeArrayFromItems(fieldSchemas, items)
  const contents = parseToCsv(arr)
  const fileName = 'ProductEngine Export ' + Date.now() + '.csv'
  return downloadStringAsFile({ fileName, contents })
}

/**
 * Make an array from fields and items.
 * @param {Object[]} fields - Fields with label and key.
 * @param {Object[]} items - Items with fields.
 * @returns {Object[]} Items(row), fields(column).
 */
const makeArrayFromItems = (fieldSchemas, items) => {
  const arrItems = []
  const arrHeaders = []

  //output first row as field headers
  for (const fieldSchema of fieldSchemas) {
    arrHeaders.push(fieldSchema.label)
  }
  arrItems.push(arrHeaders)
  //output subsequent rows of item with fields
  for (const item of items) {
    const itemfields = item.fields
    const arrFields = []
    for (const fieldSchema of fieldSchemas) {
      const fieldKey = fieldSchema.key
      const itemField = itemfields[fieldKey]
      itemField ? arrFields.push(getFieldValueByFieldType(itemField, fieldSchema)) : arrFields.push(CHAR_EMPTY)
    }
    arrItems.push(arrFields)
  }
  return arrItems
}

/**
 * Get the value of the field based on the field type.
 * @param {Object} field - The field object.
 * @returns {(number|string|boolean)} Value in number or string or boolean.
 */
const getFieldValueByFieldType = (field, fieldSchema) => {
  return isEmpty(field.value) ? CHAR_EMPTY
    : isArray(field.value) ? field.value.join(CHAR_COMMA)
      : [FIELD_TYPE_DATE, FIELD_TYPE_CHECKBOX].includes(fieldSchema.type) ? field.value
        : [FIELD_TYPE_CURRENCY, FIELD_TYPE_NUMBER].includes(fieldSchema.type)
          ? Number(field.value).toFixed(fieldSchema.format.decimalPlaces)
          : field.value
}