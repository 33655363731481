// DB FIle - Needs refactor...
import {
  doc, collection, getDocs, setDoc, updateDoc, getDoc,
  query, where, onSnapshot, writeBatch,
  disableNetwork, enableNetwork
} from 'firebase/firestore'

// Utils
import { required, generateId, getTimestamp, sleep, throwError, getUpdateInterval } from '../utils'

// Session
import { getUserSession } from '../session'

// Constants
import { IS_DEBUG_MODE } from '../constants'
import { COMMIT_BATCH_SIZE, COMMIT_BATCH_WAIT } from '../constants'

import { DbInstance } from '../db/DbInstance.mjs'

const dbInstance = new DbInstance({ poolSize: 1 })
const db = dbInstance.getDb()

/*
TODO: need to fix this up, was having issues with offline mode. 
Snapshot query not loading data on 1st browser open
*/
/*
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
})

// enable persistence with multi Tab Support
enableMultiTabIndexedDbPersistence(db)
  .catch(console.error)
*/

/**
 * get collection
 */
function getCollection(collectionName) {
  return collection(db, collectionName)
}

/**
 * Make a collection document
 */
function makeDoc({
  id        = generateId(),
  user      = getUserSession(),
  isDeleted = false,
  createdAt = getTimestamp(),
  createdBy = user?.uid,
  ...props
} = {}) {
  const newDoc = Object.fromEntries(
    Object.entries(props)
    .filter(p => p[1] !== undefined)
    .map( p => {
      return [ p[0], p[1]
      ]
    })
  )
  return  {
    id,
    ...newDoc,
    isDeleted,
    createdAt,
    createdBy,
    updatedAt: getTimestamp(),
    updatedBy: user?.uid,
    updatedAtInterval: getUpdateInterval()
  }
}

/**
 * Get doc by id
 */
async function getById(collectionName, id) {
  const docRef  = doc(db, collectionName, id)
  const docSnap = await getDoc(docRef)
  return docSnap.exists()
    && { id, ...docSnap.data() }
}

/**
 * Create one document in collection
 */
function createOne(
  collectionName = required('collectionName'),
  {
    id,
    ...data
  } = required('data'),
) {
  const newDoc = makeDoc({ ...data, id })
  const docRef = doc(db, collectionName, newDoc.id)
  setDoc(docRef, newDoc)
  return newDoc.id
}

/**
 * Update one document
 */
function updateOne(
  collectionName = required('collectionName'),
  {
    id = required('id'),
    ...props
  } = {},
) {
  const newDoc = makeDoc({
    id,
    ...props,
  })
  const docRef = doc(db, collectionName, newDoc.id)
  return updateDoc(docRef, newDoc)
}

/*
 * Delete one docunment
 */
function markAsDeleted(
  collectionName = required('collectionName'),
  id,
) {
  const user = getUserSession()
  const docRef = doc(db, collectionName, id )
  return updateDoc(docRef, { 
    isDeleted : true,
    deletedAt : getTimestamp(),
    deletedBy : user?.uid,
  })
}

//----------//
// Special notes: if batch write is still not quick enough
//use parallel write with Cloud functions (admin SDK)
// 
//

/**
 * CommitBatch with progress status
 * @param {*} batches 
 * @param {*} param1 
 */
async function commitBatch(batches) {
  for (const batch of batches) {
    await (batch.handle).commit()
    await sleep(COMMIT_BATCH_WAIT)
  }
}

/**
 * Create documents in batches
 */
async function createBatch(collectionName, docList, user) {
  if (docList.length === 0) return
  let idx = 0
  const batches = []
  for (const data of docList) {
    if (!data.id) continue
    if (!batches[idx]) {
      batches[idx] = { count : 0, handle : writeBatch(db) }  
    }
    const newDoc = makeDoc({ ...data, id : data.id, user })
    const docRef = doc(db, collectionName, newDoc.id)
    batches[idx].handle.set(docRef, newDoc)
    batches[idx].count++
    if (batches[idx].count >= COMMIT_BATCH_SIZE) {
      idx++
    }
  }
  commitBatch(batches)
}

/**
 * Update documents in batches
 */
async function updateBatch(collectionName, docList, user) {
  if (docList.length === 0) return
  let idx = 0
  const batches = []
  for (const data of docList) {
    if (!data.id) continue
    if (!batches[idx]) {
      batches[idx] = { count : 0, handle : writeBatch(db) }  
    }
    const newDoc = makeDoc({ ...data, id : data.id, user })
    const docRef = doc(db, collectionName, newDoc.id)
    batches[idx].handle.update(docRef, newDoc)
    batches[idx].count++
    if (batches[idx].count >= COMMIT_BATCH_SIZE) {
      idx++
    }
  }
  commitBatch(batches)
}

/**
 * Mark documents as deleted in batches
 */
async function markAsDeletedBatch(collectionName, docList, user = getUserSession()) {
  if (docList.length === 0) return
  let idx = 0
  const batches = []
  for (const data of docList) {
    if (!data.id) continue
    if (!batches[idx]) {
      batches[idx] = { count : 0, handle : writeBatch(db) }  
    }
    const ref = doc(db, collectionName, data.id)
    batches[idx].handle.update(ref, {
      isDeleted   : true,
      deletedAt   : getTimestamp(),
      deletedBy   : user?.uid,
    })
    batches[idx].count++
    if (batches[idx].count >= COMMIT_BATCH_SIZE) {
      idx++
    }
  }
  commitBatch(batches)
}

/**
 * Remove documents from collection (delete permenantly)
 */
async function removeBatch(collectionName, ids) {
  if (ids.length === 0) return
  let idx = 0
  const batches = []
  for (const id of ids) {
    if (!id) continue
    if (!batches[idx]) {
      batches[idx] = { count : 0, handle : writeBatch(db) }  
    }
    const ref = doc(db, collectionName, id)
    batches[idx].handle.delete(ref)
    batches[idx].count++
    if (batches[idx].count >= COMMIT_BATCH_SIZE) {
      idx++
    }
  }
  commitBatch(batches)
}


//-----------//
/**
 * Get all documents 
 */
async function getAll(collectionName, teamId) {
  const c = getCollection(collectionName)
  const q = (teamId) 
    ? query(c, where ('teamId', '==', teamId)) 
    : c
  const snap = await getDocs(q)
  const docs = []
  snap.forEach(doc => {
    docs.push({ id: doc.id, ...doc.data() })
  })
  return docs
}

/**
 * Get all documents by query conditions
 */
async function getAllByConditions(collectionName, conditionsArr) {
  const c = getCollection(collectionName)
  const conditions = (conditionsArr) 
    ? conditionsArr.map(i => where(i[0], i[1], i[2])) 
    : []
  const q = query(c, ...conditions)
  const snap = await getDocs(q)
  const docs = []
  snap.forEach(doc => {
    docs.push({ id: doc.id, ...doc.data() })
  })
  return docs
}

//-----------//
async function disableDbNetwork() { 
  await disableNetwork(db) 
  IS_DEBUG_MODE && console.log('DB Network Disabled!')
}

//-----------//
async function enableDbNetwork() { 
  await enableNetwork(db) 
  IS_DEBUG_MODE && console.log('DB Network Enabled!')
}

//-----------//
function queryListener(
  collectionName,
  queryConditionsArray,
  localAddedFunction,
  serverAddedFunction,
  localModifiedFunction,
  serverModifiedFunction,
  localRemovedFunction,
  serverRemovedFunction,
) {
  const condArr = queryConditionsArray
    ? queryConditionsArray.map(i => where(i[0], i[1], i[2]))
    : []
  const listenerQuery = query(
    collection(db, collectionName),
    ...condArr
  )

  const listener = onSnapshot(listenerQuery, { includeMetadataChanges: true }, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const metadata = snapshot.metadata
      if (!metadata.hasPendingWrites && metadata.fromCache) {
        // prevent echo changes from browser multi-tab
        return
      }
      if (change.type === 'added') {
        if (metadata.hasPendingWrites) {
          if (!localAddedFunction) return
          localAddedFunction({ id : change.doc.id, data : change.doc.data() })
        } else {
          if (!serverAddedFunction) return
          serverAddedFunction({ id : change.doc.id, data : change.doc.data() })
        }
      }
      if (change.type === 'modified') {
        if (metadata.hasPendingWrites) {
          if (!localModifiedFunction) return
          localModifiedFunction({ id : change.doc.id, data : change.doc.data() })
        } else {
          if (!serverModifiedFunction) return
          serverModifiedFunction({ id : change.doc.id, data : change.doc.data() })
        }
      }
      if (change.type === 'removed') {
        if (metadata.hasPendingWrites) {
          if (!localRemovedFunction) return
          localRemovedFunction({ id : change.doc.id, data : change.doc.data() })
        } else {
          if (!serverRemovedFunction) return
          serverRemovedFunction({ id : change.doc.id, data : change.doc.data() })
        }
      }
    })
  },
  (error) => {
    return throwError(`Listener Error: ${error}`)
  })

  return listener
}

//-----------//
function docListener(
  collectionName,
  docId,
  localModifiedFunction,
  serverModifiedFunction,
  localRemovedFunction,
  serverRemovedFunction,
) {
  const listener = onSnapshot(doc(db, collectionName, docId), 
    { includeMetadataChanges: true }, 
    (snapshot) => {
    const metadata = snapshot.metadata
    if (!metadata.hasPendingWrites && metadata.fromCache) {
      // prevent echo changes from browser multi-tab
      return
    }
    if (snapshot.exists()) { 
      if (metadata.hasPendingWrites) {
        if (!localModifiedFunction) return
        localModifiedFunction({ id : snapshot.id, data : snapshot.data() })
      } else {
        if (!serverModifiedFunction) return
        serverModifiedFunction({ id : snapshot.id, data : snapshot.data() })
      }
    } else {
      if (metadata.hasPendingWrites) {
        if (!localRemovedFunction) return
        localRemovedFunction({ id : snapshot.id, data : snapshot.data() })
      } else {
        if (!serverRemovedFunction) return
        serverRemovedFunction({ id : snapshot.id, data : snapshot.data() })
      }
    }
  },
  (error) => {
    return throwError(`Listener Error: ${error}`)
  })

  return listener
}

function stopListener(handle) {
  if (!handle) {
    return
  }
  return handle()
}

export { 
  db, getAll, getAllByConditions, getById,
  createOne, updateOne, markAsDeleted,
  createBatch, updateBatch, markAsDeletedBatch, removeBatch,
}

export { disableDbNetwork, enableDbNetwork, queryListener, docListener, stopListener }
