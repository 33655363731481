
//utils
import { throwError } from '../../../utils/errors.mjs'

// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../rpcs/salesforce-rpcs.mjs'
import { ACTION_PUBLISH_PRODUCTS } from '../constants/action-types.mjs'

// list
import { ListEntity } from '../../../entity/ListEntity.mjs'

import { setLogFilesUi, getLogFile, setLatestLogFile, clearLogFilesUi } from '../../../list-core/log-files/log-files-ui.mjs'

//notification
import { raiseNotification } from '../../../notify/raise-notification.mjs'
import { getListAndColumns } from '../../../list-core/db/get-list-and-columns.mjs'

const resetListName = (list) => {
  list.name = list.name.replace(/^Publishing\.\.\./, '')
  list.name = list.name.replace(/^Failed\.\.\./, '')
  return list
}

const publishListName = (list) => {
  list.name = 'Publishing...' + list.name
  return list
}

const failedPublishListName = (list) => {
  list.name = 'Failed...' + list.name
  return list
}

const publishProducts = async ({
  integrationId,
  teamId, 
  listId, 
  itemIds
}) => {
  const { list } = await getListAndColumns(listId)
  if (!list) {
    throwError('No list found in DB', listId)
    return
  }
  clearLogFilesUi()
  resetListName(list)
  publishListName(list)
  await ListEntity.upsert(list)
  const sfdcIntegration = getSalesforceIntegrationRpc()
  try {
    const response = await sfdcIntegration({
      action: ACTION_PUBLISH_PRODUCTS,
      payload: {
        integrationId,
        teamId,
        listId,
        itemIds
      }
    })

    // publish v2
    // TODO: PL - retro fit latest publish with salesforce basic
    if (response.data.logfileMeta) {
      list.addLogfileMeta(response.data.logfileMeta)
    }

    resetListName(list)
    await ListEntity.upsert(list)

    // publish v2
    // TODO: PL - retro fit latest publish with salesforce basic
    if (response.data.logfileMeta) {
      await setLogFilesUi(list)
      await getLogFile(response.data.logfileMeta)
      setLatestLogFile(response.data.logfileMeta)
    }

    const notifyMsg = !response.data ? 'Salesforce - Nothing to publish' : response.data.success
      ? !response.data.hasErrors ? 'Salesforce Publish Completed'
        : 'Salesforce Publish Completed With Errors'
      : (response.data.error || 'Salesforce Publish Failed')
    raiseNotification(notifyMsg)
    if (!response.data || (!response.data.hasErrors && !response.data.error)) {
      return
    }
  } catch (err) {
    resetListName(list)
    failedPublishListName(list)
    await ListEntity.upsert(list)
    console.error('Salesforce Publish error =>', err)
  }
}



export { publishProducts }

