/**
 * Dispatch a local event
 */

import { IS_DEBUG_EVENT_MODE } from '../constants/debug.mjs'

function dispatch(name, payload) {
  IS_DEBUG_EVENT_MODE && console.log('DISPATCH EVENT:', name)
  IS_DEBUG_EVENT_MODE && console.log('EVENT PAYLOAD:', payload)
  return dispatchEvent(
    new CustomEvent(name, { detail: payload, bubbles: false })
  )
}

// Exports
export { dispatch }
