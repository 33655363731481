/**
 * Field schema constants
 */

// Dependencies
import { CHAR_EMPTY }  from '@/constants'
// Utils
import { getTimestamp } from '@/utils/timestamp.mjs'

import { 
  FIELD_TYPE_TEXT,
  FIELD_TYPE_DATE,
  DEFAULT_LABEL,
} from './field-schema-constants.mjs'


// Field Keys
export const FIELD_KEY_ID            = 'id'
export const FIELD_KEY_PRODUCT_CODE  = 'productCode'
export const FIELD_KEY_CREATED_AT    = 'createdAt'
export const FIELD_KEY_UPDATED_AT    = 'updatedAt'

// Field Labels
export const FIELD_LABEL_ID            = 'ID'
export const FIELD_LABEL_PRODUCT_CODE  = 'Product Code'
export const FIELD_LABEL_CREATED_AT    = 'Created At'
export const FIELD_LABEL_UPDATED_AT    = 'Updated At'

// Special
export const FIELD_KEY_FIRST_COLUMN = '__FIRST_COLUMN__'
export const FIELD_KEY_NEW_COLUMN   = '__NEW_COLUMN__'
export const FIELD_LABEL_NEW_COLUMN    = '+ New Column'

// Default field values
export const DEFAULT_LIST_PRICE_VALUE = '=PRICEMARGIN($costPrice, $margin)'



// Default field schemas
export const DEFAULT_FIELD_SCHEMAS = [
  {
    key:          FIELD_KEY_ID,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_ID,
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }, {
    key:          FIELD_KEY_PRODUCT_CODE,
    type:         FIELD_TYPE_TEXT,
    label:        FIELD_LABEL_PRODUCT_CODE,
    defaultValue: CHAR_EMPTY,
    isHidden:     false,
    isDeletable:  false,
    isEditable :  true
  }, {
    key:          FIELD_KEY_CREATED_AT,
    type:         FIELD_TYPE_DATE,
    label:        FIELD_LABEL_CREATED_AT,
    defaultValue: getTimestamp(),
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }, {
    key:          FIELD_KEY_UPDATED_AT,
    type:         FIELD_TYPE_DATE,
    label:        FIELD_LABEL_UPDATED_AT,
    defaultValue: getTimestamp(),
    isHidden:     true,
    isDeletable:  false,
    isEditable :  false
  }
]

/*
export const NON_EDITABLE_FIELDS = DEFAULT_FIELD_SCHEMAS
  .filter(field => !field.isEditable)
  .map(field => field.key)

export const NON_DELETABLE_FIELDS = DEFAULT_FIELD_SCHEMAS
  .filter(field => !field.isDeletable)
  .map(field => field.key)
  */
