/**
 * Stripe Constants
 */
export const STRIPE_SECRET_KEY = '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'
export const STRIPE_SECRET_KEY_TEST = 'sk_test_51MhPf0Il26eX4e43p8jAB8rC3N90k3kIzRHiy0KdyVQIYhsdUAvyJC8kkXzjHu16kJ6zUHrBoxpMIJyBXY3bEcVv00uMbF42gG'
export const STRIPE_BILLING_PORTAL_RESTRICTED_KEY_TEST = 'rk_test_51MhPf0Il26eX4e43JlRVEsSl8PMUrSLUezBoKjhtK2pIfan6N1AFhh9LkdNKkMKsWaGgUrLLSojrKtEoBdLoKlzF00FbOoDTrE'
export const STRIPE_BILLING_PORTAL_RESTRICTED_KEY = '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'
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51MhPf0Il26eX4e43EEVQ5lkBMd8cNiFKIxd59JgppnDZIwGciLkPqBKb5VVHbUwpNf7R5v10J4ma3plBJ2Dgswhp009XlrmexC'
export const STRIPE_PUBLISHABLE_KEY_HOSTED_TEST = 'pk_test_51MhPf0Il26eX4e43pfXonjnO0eUvsYMBXg0KF2NGLIfQr3wYum7wGeVelX3LmzhJmmw1TeVwKJodOBPGc8cqlhBU00pFnkLkCW'
export const STRIPE_PUBLISHABLE_KEY_LOCAL_TEST = 'pk_test_51MhPf0Il26eX4e43pfXonjnO0eUvsYMBXg0KF2NGLIfQr3wYum7wGeVelX3LmzhJmmw1TeVwKJodOBPGc8cqlhBU00pFnkLkCW'
export const STRIPE_WEBHOOK_SIGNING_SECRET_PRODUCTION = 'whsec_i1pK0mVwGjgtUKTsSYdORGuJxmQwbi6L'
export const STRIPE_WEBHOOK_SIGNING_SECRET_HOSTED_TEST = 'whsec_sSze8EeEl19q2lgaK6c9O0vW3msAvywf'
export const STRIPE_WEBHOOK_SIGNING_SECRET_LOCAL_TEST = 'whsec_b9568eb20909e4c7eedcf0419778a080f3a640ddf97f0f56e39185d2c57254b2'
export const STRIPE_PRICING_TABLE_LOCAL_TEST = 'prctbl_1N91TeIl26eX4e43p0pGZJk5'
export const STRIPE_PRICING_TABLE_HOSTED_TEST = 'prctbl_1NJ3EDIl26eX4e435YDotJWi'
export const STRIPE_PRICING_TABLE = 'prctbl_1MhSLTIl26eX4e43nZ1jhKkL'
export const CHECKOUT_SESSION_CUSTOM_FIELD_KEY_COMPANY_NAME = 'companyname'
export const SUBSCRIPTION_STATUS_TRIALING = 'trialing'
export const SUBSCRIPTION_STATUS_ACTIVE = 'active'
export const SUBSCRIPTION_STATUS_PAST_DUE = 'past_due'
export const SUBSCRIPTION_STATUS_UNPAID = 'unpaid'
export const SUBSCRIPTION_STATUS_CANCELLED = 'canceled'
export const SUBSCRIPTION_STATUS_PAUSED = 'paused'
export const STRIPE_PRODUCT_NAME_STANDARD = 'ProductEngine Standard'
export const STRIPE_PRODUCT_NAME_PRO = 'ProductEngine Pro'
export const STRIPE_PRODUCT_NAME_ENTERPRISE = 'ProductEngine Enterprise'

// billing rpc action constants
export const ACTION_GET_BILLING_PORTAL_API_KEY = 'get-BillingPortalApiKey'