/*!
* List import constants
*/

import { 
  FIELD_LABEL_NEW_COLUMN, FIELD_KEY_NEW_COLUMN,
  FIELD_LABEL_NO_MAP_COLUMN, FIELD_KEY_NO_MAP_COLUMN,
} from '../../constants'

export const MAP_NEW = { label: FIELD_LABEL_NEW_COLUMN, key: FIELD_KEY_NEW_COLUMN }
export const MAP_NULL = { label: FIELD_LABEL_NO_MAP_COLUMN, key: FIELD_KEY_NO_MAP_COLUMN }
