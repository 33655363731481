/*!
 *
 */
import { required } from '../../utils/required.mjs'
import { getUpdateInterval } from '../../utils/update-interval.mjs'

import { ItemEntity } from '../../entity/ItemEntity.mjs'
import { ItemDb } from './ItemDb.mjs'

// db rpcs
import { getSaveItemsRpc } from '../../db/rpcs/db-rpcs.mjs'

// local db
import { COLLECTION_ITEMS } from '../../db/db-collections.mjs'
import { getLocalDb } from '../../db-local/local-db.mjs'
import { getLocalItems, bulkPutItems } from '../../item/data/local-db-items.mjs'

// debug
import { logSizeInBytes } from '../../utils/get-size-in-bytes.mjs'

// sessions
import { getObjectSession, setObjectSession } from '../../session/object-session.mjs'

const itemDb = new ItemDb()
// event
import { dispatch } from '../../events/dispatch.mjs'
import { EVENT_PROGRESS_SET_STATUS } from '../../events/constants/event-types.mjs'

import { COMMIT_BATCH_SIZE } from '../../constants/batch-sizes.mjs'
/**
 * Get items by query conditions
 * @param {*} queryConditions 
 * @returns {Object[]} Array of ItemEntity
 */
async function getItemsByConditions(queryConditions) {
  const items = await itemDb.getByConditions(queryConditions)
  return items.map(item => new ItemEntity(item))
}

/**
 * Get item entity
 * @param {*} id 
 * @returns {Object} ItemEntity
 */
async function getItemEntity(id) {
  if (!id) {
    return
  }
  return new ItemEntity(await itemDb.getById(id))
}

const BATCH_SIZE = 65 * 4

/**
* Save items entities
* @param {*} itemEntities 
* @param {*} param1 
* @returns 
*/
async function saveItemsEntities(
 itemEntities,
 {
   event,
   onCompleted,
 } = {}) {
 const startTime = Date.now()
 if (!itemEntities || itemEntities.length === 0) {
   return
 }
 const len = itemEntities.length
 const saveItemsRpc = getSaveItemsRpc()

 let saveObjects = []
 let cnt = 0
 for (const item of itemEntities) {
  item.updatedByEventId = (event) ? event.id : ''
  saveObjects.push(itemDb.createDocObject(item))
  cnt++
  if (cnt === COMMIT_BATCH_SIZE) {
    await bulkPutItems(COLLECTION_ITEMS, saveObjects)
    await saveItemsRpc(saveObjects)
    dispatch(EVENT_PROGRESS_SET_STATUS, { addValue: saveObjects.length })
    saveObjects = []
    cnt = 0
  }
 }
 if (saveObjects.length > 0) {
  await bulkPutItems(COLLECTION_ITEMS, saveObjects)
  await saveItemsRpc(saveObjects)
  dispatch(EVENT_PROGRESS_SET_STATUS, { addValue: saveObjects.length })
}
 // onCompleted callback
 onCompleted && onCompleted()
 console.log('saved', itemEntities.length, 'items duration: ', Date.now() - startTime, 'ms')
}





// TODO: PL - obsolete
const getLibraryItemsFromInterval = async (libararyId, updatedAtInterval) => {
  return await getItemsByConditions([
    ['libraryListId', '==', libararyId],
    ['updatedAtInterval', '>=', updatedAtInterval],
  ])
}

// TODO: PL - obsolete
const getLibraryItems = async (libraryId) => {
  const listSession = await getObjectSession(libraryId)
  const localItems = (await getLocalItems({ libraryId })) || []
  console.log('Got local items:', localItems.length)

  const totalItems = {}
  for (const item of localItems) {
    if (totalItems[item.id]) {
      continue
    }
    totalItems[item.id] = item
  }
  console.log('get delta items from', listSession.data.updatedAtInterval)
  const deltaItems = (await getLibraryItemsFromInterval(libraryId, listSession.data.updatedAtInterval)) || []
  console.log('Got delta items:', deltaItems.length)

  if (deltaItems.length === 0) {
    return getValidItems(totalItems)
  }
  const itemLocalDb = getLocalDb(COLLECTION_ITEMS)
  for (const item of deltaItems) {
    await itemLocalDb.put(item.toObject())
    totalItems[item.id] = item
  }

  return getValidItems(totalItems)
}

// TODO: PL - obsolete

const getValidItems = (totalItems) => {
  const results = []
  for (const key in totalItems) {
    if (totalItems[key].isDeleted) {
      continue
    }
    results.push(totalItems[key])
  }
  return results
}


export {
  getItemsByConditions, saveItemsEntities, getItemEntity,
  getLocalItems,
  getLibraryItems,
}
