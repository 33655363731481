

import { isEmpty, required, normalizeString } from '../utils'
//Constants
import {
  KEY_IS_EMPTY, KEY_EQUAL_TO, KEY_NOT_EQUAL_TO,
  KEY_STARTS_WITH, KEY_ENDS_WITH, KEY_CONTAINS, KEY_DOES_NOT_CONTAIN,
  CHAR_EMPTY
} from '../constants'

export class FieldType {
  
  static compare( 
    aValue    = CHAR_EMPTY,
    condition = required('filter condition'),
    bValue    = CHAR_EMPTY
  ) {
    const a = normalizeString(aValue)
    const b = normalizeString(bValue)
    return condition === KEY_IS_EMPTY         ? isEmpty(aValue)
         : condition === KEY_EQUAL_TO         ? !b?.length || a === b
         : condition === KEY_NOT_EQUAL_TO     ? a !== b
         : condition === KEY_STARTS_WITH      ? a.startsWith(b)
         : condition === KEY_ENDS_WITH        ? a.endsWith(b)
         : condition === KEY_CONTAINS         ? a === b || a.includes(b)
         : condition === KEY_DOES_NOT_CONTAIN ? !a.includes(b)
         : null
  }
}