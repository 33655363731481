import { createApp } from 'vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options.mjs'
import P11eCheckbox from './components/sheet/P11eCheckbox.vue'
import P11eTooltip from './components/P11eTooltip.vue'
import P11eEmailList from './components/P11eEmailList.vue'
import JsonEditorVue from 'json-editor-vue'

import App from './App.vue'

const app = createApp(App)
  .use(router)
  .use(Quasar, quasarUserOptions)
  .use(JsonEditorVue)
  .component('P11eCheckbox', P11eCheckbox)
  .component('P11eTooltip', P11eTooltip)
  .component('P11eEmailList', P11eEmailList)
  .mount('#app')

export default app
