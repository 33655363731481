import { required, throwError } from '../../utils'
import { ColumnSetEntity } from '../../entity'
import { ColumnSetDb } from './ColumnSetDb.mjs'
import { getFieldSchemaEntitiesByTeamId } from '../../field-schema'
import { getImportColumnSetQuery, getDefaultColumnSetQuery } from './column-set-db-utils.mjs'


const db = new ColumnSetDb()

async function createColumnSetEntityFromDb(columnSetData) {
  const newColumnSet = new ColumnSetEntity(columnSetData)
  const columns = newColumnSet.columns

  const fieldSchemas = newColumnSet.isStatic
    ? columnSetData.columns.map(col => col.fieldSchema)
    : await getFieldSchemaEntitiesByTeamId(newColumnSet.teamId)

  const fieldSchemaMap = new Map(fieldSchemas
    .map(fs => { return [fs.id, fs] })
  )
  const teamId = newColumnSet.teamId

  newColumnSet.columns.forEach(column => {
    column.fieldSchema = fieldSchemaMap.get(column.fieldSchemaId)
    column.teamId = teamId
  })

  return newColumnSet
}

/**
 * fetch column set
 * @param {*} id 
 * @returns ColumnSetEntity
 */
async function fetchColumnSet(id) {
  if (!id) {
    return
  }
  // load column set
  const columnSetData = await db.getById(id)
  if (!columnSetData) {
    return
  }

  return createColumnSetEntityFromDb(columnSetData)
}

// TODO: - PL rename getColumnSet to fetchColumnSet
async function getColumnSet(id) {
  return fetchColumnSet(id)
}

function saveColumnSetEntity(columnSetEntity) {
  return saveColumnSetEntities([columnSetEntity])
}


async function fetchImportColumnSetEntity(
  listId = required('listId'),
  teamId = required('teamId')
) {
  const [ columnSetData ] = await db.getByConditions(getImportColumnSetQuery(listId, teamId))
  if (!columnSetData) {
    return
  }
  return createColumnSetEntityFromDb(columnSetData)
}

async function fetchDefaultColumnSetEntity(
  listId = required('listId'),
) {
  const [ columnSetData ] = await db.getByConditions(getDefaultColumnSetQuery(listId))
  if (!columnSetData) {
    return
  }
  return createColumnSetEntityFromDb(columnSetData)
}



function saveColumnSetEntities(columnSetEntities) {
  if (!Array.isArray(columnSetEntities)) {
    throwError('columnSetEntities must be an array')
  }
  if (!columnSetEntities || columnSetEntities.length === 0) {
    return
  }
  const saveObjects = columnSetEntities
    .map(columnSetEntity => db.createDocObject(columnSetEntity))

  return db.upsertBatch(saveObjects)
}

export {
  fetchColumnSet, getColumnSet, createColumnSetEntityFromDb,
  saveColumnSetEntity, saveColumnSetEntities,
  fetchImportColumnSetEntity, fetchDefaultColumnSetEntity,
}
