<template>
  <q-btn v-show="canFilterColumns || canSaveFilteredColumns || canHideColumns || canPinColumns" @click="openSearchMenu()"
    round size="md" icon="o_filter_list" color="white" text-color="grey-10"
    style="position: absolute; top: 1.4rem; right: 1.2rem;">
    <q-badge v-show="currentFilters.length" :color="isFilteringActive ? 'primary' : 'grey-5'" rounded floating />
    <p11e-tooltip>{{ currentFilters.length ? `${currentFilters.length} ${isFilteringActive ? '' : 'in'}active
          filter${currentFilters.length === 1 ? '' : 's'}` : 'Filters' }}</p11e-tooltip>
  </q-btn>

  <div class="right-menu" :style="isSearchMenuOpen ? `
          min-width: 460px;
          margin-right: 0px;
      ` : `
        margin-right: -460px;
      `">
    <div style="height: 100%; overflow-x: hidden; overflow-y: auto; padding: 2rem;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div style="font-size: 1rem;">Filters</div>
        <q-btn size="md" @click="closeSearchMenu()" flat round dense icon="o_close" />
      </div>
      <div class="bg-accent q-pa-xs q-mt-md rounded-borders">
        <q-tabs v-model="filterTab" dense class="text-dark text-weight-medium" active-bg-color="white"
          indicator-color="transparent" content-class="bg-accent" align="justify">
          <q-tab v-show="canFilterColumns" style="border-radius: 6px; margin: 0.1rem" name="filter" no-caps
            label="Filter" />
          <q-tab style="border-radius: 6px; margin: 0.1rem" name="saved" no-caps
            :label="'Saved (' + savedFilterGroups.length + ')'" />
          <q-tab v-show="canHideColumns || canPinColumns" style="border-radius: 6px; margin: 0.1rem" name="columns"
            no-caps label="Columns" />
            <q-tab v-show="canPublishToIntegration" style="border-radius: 6px; margin: 0.1rem" name="publishLogs" no-caps
            :label="'Logs'" />
        </q-tabs>

      </div>

      <q-tab-panels v-model="filterTab" animated>
        <q-tab-panel name="filter" class="q-px-none q-pt-md column justify-center">
          <div class="q-px-md full-width">
            <div class="row justify-between items-center q-pl-xs">
              <div class="text-subtitle2 text-weight-medium">Apply Filters</div>
              <q-toggle v-model="isFilteringActive" @click="onFilterToggled()" color="primary" />
            </div>
            <div v-if="currentFilters.length && filterGroupName?.length" class="row justify-between items-center q-pl-xs"
              style="border-top: 1px solid #efefef; padding-top: 1em; text-overflow: ellipsis; overflow: hidden; width: 100%;"
              @click="isEditingFilterGroupName = true">
              <div class="text-subtitle" style="font-weight: bold;">{{ filterGroupName }}</div>
              <q-btn size="md" @click="onClearFilters(filterGroupName)" color="red" flat dense no-caps round>&cross;</q-btn>
            </div>

            <div v-show="!currentFilters.length"
              class="row justify-center text-center items-center text-grey-8 text-caption q-px-md q-pt-xl">
              Add and filter columns to refine the number of products displayed
            </div>

            <q-scroll-area :style="currentFilters.length ? 'height: calc(100vh - 380px);' : 'margin-bottom: 2rem;'">

              <div v-for="(filter, filterIdx) in currentFilters" :key="filter">
                <div class="row justify-between items-center q-px-xs q-mt-md">
                  <div class="text-subtitle2 text-weight-medium">{{ getCurrentColumnById(filter.fieldId)?.label }}</div>
                  <q-btn @click="onRemoveFilter(filterIdx)" flat color="grey-6" no-caps>Remove</q-btn>
                </div>

                <div v-for="(rule, ruleIdx) in currentFilters[filterIdx].rules" :key="rule" class="q-mb-sm">
                  <div v-if="ruleIdx" style="font-style: italic; color: #cecece; margin-left: .8rem;">Or &mdash;</div>
                  <div class="q-pt-xs q-px-xs">
                    <div class="row justify-between items-center no-wrap">
                      <q-select @update:model-value="onUpdateFilterCondition(filterIdx, ruleIdx, $event)"
                        :model-value="currentFilters[filterIdx].rules[ruleIdx].condition" style="width: 88%;" dense
                        color="black" bg-color="accent" outlined map-options class="rounded-borders"
                        :options="getFilterConditionsForType(filter.type)" placeholder="Rule condition" />
                      <q-btn @click="onRemoveFilterRule(filterIdx, ruleIdx)" flat round color="grey-6" size="sm" no-caps>
                        <svg width="12" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.98594 16.6348L2.31026 6.63483C2.23222 5.47982 3.14807 4.5 4.30571 4.5H11.693C12.8506 4.5 13.7665 5.47982 13.6884 6.63483L13.0128 16.6348C12.9418 17.6847 12.0695 18.5 11.0173 18.5H4.98139C3.92915 18.5 3.05687 17.6847 2.98594 16.6348Z"
                            stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                          <path d="M15 4.5L1 4.5" stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                          <path d="M4.5 4.5V2C4.5 1.44772 4.94772 1 5.5 1H10.5C11.0523 1 11.5 1.44772 11.5 2V4.5"
                            stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                          <path d="M5.66602 9.16797L6.24935 15.0013" stroke="#9A9FB4" stroke-width="2"
                            stroke-linecap="round" />
                          <path d="M10.334 9.16797L9.75065 15.0013" stroke="#9A9FB4" stroke-width="2"
                            stroke-linecap="round" />
                        </svg>
                        <p11e-tooltip>Remove rule</p11e-tooltip>
                      </q-btn>
                    </div>
                    <q-input v-if="rule.condition && !CONDITIONS_WITH_NO_FILTER_VALUE.includes(rule.condition)" :type="filter.type"
                      @update:model-value="onUpdateFilterValue(filterIdx, ruleIdx, $event)"
                      :model-value="currentFilters[filterIdx].rules[ruleIdx].value" style="width: 88%;" color="grey-10"
                      bg-color="accent" outlined class="rounded-borders q-mt-md" dense placeholder=""></q-input>
                  </div>
                </div>
                <q-btn @click="onAddRuleToFilter(filterIdx)" flat color="primary" no-caps class="q-pa-sm">Add Rule</q-btn>
              </div>

            </q-scroll-area>
          </div>

          <q-separator v-if="currentFilters.length" class="q-my-sm" />

          <q-btn no-caps unelevated color="primary" label="Add a Filter" class="rounded-borders full-width">
            <q-menu max-width="400px" anchor="bottom left" self="top left" class="shadow-brand2" style="height: 15.5rem;">
              <q-list>
                <div class="q-pa-sm">
                  <q-input :autofocus=true color="grey-10" bg-color="accent" outlined class="full-width rounded-borders"
                    dense v-model="columnFilterValue" placeholder="Find Columns" @keyup="setFilteredColumns()"
                    @clear="setFilteredColumns()" clearable>
                    <template v-slot:prepend>
                      <q-icon color="dark" name="search" />
                    </template>
                  </q-input>
                </div>
                <template v-for="column in filteredColumns" :key="column.id">
                  <q-item clickable v-close-popup @click="onAddFilter(column)" class="q-mx-sm rounded-borders">
                    <q-item-section>
                      <q-item-label lines="1" >{{ column?.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-list>
            </q-menu>
          </q-btn>

          <template v-if="currentFilters.length">
            <div class="row justify-between no-wrap q-pt-md">
              <q-btn v-show="canSaveFilteredColumns && !saveBtnPressed" @click="saveBtnPressed = true" no-caps unelevated
                class="fit rounded-borders" color="primary" text-color="white" label="Save As" />
              <template v-if="saveBtnPressed">
                <q-input outlined dense v-model="filterGroupName" type="text" color="grey-10" bg-color="accent"
                  class="full-width rounded-borders" placeholder="Name filter" :autofocus="true"
                  @keydown.enter="saveFilterGroup" />
                <q-btn @click="saveFilterGroup" no-caps unelevated class="q-ml-sm rounded-borders" style="width: 5rem;"
                  color="primary" text-color="white" label="Save" />
              </template>
            </div>
          </template>
        </q-tab-panel>

        <q-tab-panel name="saved" class="q-px-md full-width">
          <div v-if="!savedFilterGroups.length"
            class="row justify-center text-center items-center text-grey-8 text-caption q-px-lg q-mt-md">
            Your saved filters will show up here.
          </div>
          <q-item v-else v-for="savedFilterGroup in savedFilterGroups"
            @click="onApplySavedFilterGroup(savedFilterGroup.id)" :key="savedFilterGroup.id" clickable dense
            class="row justify-between items-center no-wrap q-py-sm rounded-borders">
            <div class="text-subtitle2 text-grey-8">{{ savedFilterGroup.name }}</div>
            <q-btn v-show="canSaveFilteredColumns" @click.stop="onRemoveSavedFilterGroup(savedFilterGroup.id)" flat round color="grey-6" size="sm"
              no-caps>
              <svg width="12" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.98594 16.6348L2.31026 6.63483C2.23222 5.47982 3.14807 4.5 4.30571 4.5H11.693C12.8506 4.5 13.7665 5.47982 13.6884 6.63483L13.0128 16.6348C12.9418 17.6847 12.0695 18.5 11.0173 18.5H4.98139C3.92915 18.5 3.05687 17.6847 2.98594 16.6348Z"
                  stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                <path d="M15 4.5L1 4.5" stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                <path d="M4.5 4.5V2C4.5 1.44772 4.94772 1 5.5 1H10.5C11.0523 1 11.5 1.44772 11.5 2V4.5" stroke="#9A9FB4"
                  stroke-width="2" stroke-linecap="round" />
                <path d="M5.66602 9.16797L6.24935 15.0013" stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                <path d="M10.334 9.16797L9.75065 15.0013" stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
              </svg>
              <p11e-tooltip>Remove saved filter group</p11e-tooltip>
            </q-btn>
          </q-item>
        </q-tab-panel>

        <q-tab-panel name="columns" class="full-width">
          <q-input :autofocus=true color="grey-10" bg-color="accent" outlined class="full-width rounded-borders" dense
            v-model="columnFilterValue" placeholder="Find Columns" @keyup="setFilteredColumns()"
            @clear="setFilteredColumns()" clearable>
            <template v-slot:prepend>
              <q-icon color="dark" name="search" />
            </template>
          </q-input>

          <q-item dense class="q-px-xs q-mt-sm">
            <q-checkbox v-show="canHideColumns" v-model="toggleAll" @click="toggleColumnSelection()" color="primary" />
          </q-item>

          <q-scroll-area style="height: calc(100vh - 260px);">
            <div style="max-width: 364px;">
              <q-item v-for="column in filteredColumns" :key="column.id" dense
                class="row justify-between items-center q-px-xs">
                <template v-if="canHideColumns">
                  <q-checkbox :model-value="!column.isHidden" 
                    @click="setFieldVisibility([column.id], !column.isHidden)" :val="column?.id" color="primary" />
                  <q-item-section >
                    <q-item-label lines="1" >{{ column?.label }}</q-item-label>
                  </q-item-section>
                </template>
                <div v-else style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ column?.label }}</div>
                <q-btn v-show="canPinColumns" flat round @click="onUpdatePin([column.id], !column.isPinned)">
                  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.72165 3.31579V7.13684C2.81443 7.6386 1 9.31368 1 12H12C12 9.12842 10.0344 7.5614 9.16495 7.13684V3.31579C9.89072 2.29684 9.99656 1.34737 9.95876 1H6.3299H2.81443C2.81443 2.01895 3.41924 2.96842 3.72165 3.31579Z"
                      :stroke="column.isPinned ? '#0A0A29' : '#B6BBCE'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M6.5 12V17" :stroke="column.isPinned ? '#0A0A29' : '#B6BBCE'" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </q-btn>
              </q-item>
            </div>
          </q-scroll-area>
        </q-tab-panel>

        // Publish Logs
        <q-tab-panel name="publishLogs" class="q-px-md full-width">
          <div class="row justify-between items-center q-px-xs q-mt-md">
            <div class="text-subtitle2 text-weight-medium">Log File</div>
            <q-btn @click="onClearSelectLogFile" flat color="grey-6" no-caps>Clear</q-btn>
          </div>
          <div class="q-pt-xs q-px-xs">
            <div class="row justify-between items-center no-wrap">
              <q-select v-model="selectedLogFile" :options="logFileList" @update:model-value="onSelectLogFile"
                style="width: 88%;" dense
                color="black" bg-color="accent" outlined map-options class="rounded-borders">
              </q-select>
              <q-btn @click="onDeleteLogFile" flat round color="grey-6" size="sm" no-caps>
                <svg width="12" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M2.98594 16.6348L2.31026 6.63483C2.23222 5.47982 3.14807 4.5 4.30571 4.5H11.693C12.8506 4.5 13.7665 5.47982 13.6884 6.63483L13.0128 16.6348C12.9418 17.6847 12.0695 18.5 11.0173 18.5H4.98139C3.92915 18.5 3.05687 17.6847 2.98594 16.6348Z"
                      stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                    <path d="M15 4.5L1 4.5" stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                    <path d="M4.5 4.5V2C4.5 1.44772 4.94772 1 5.5 1H10.5C11.0523 1 11.5 1.44772 11.5 2V4.5"
                      stroke="#9A9FB4" stroke-width="2" stroke-linecap="round" />
                    <path d="M5.66602 9.16797L6.24935 15.0013" stroke="#9A9FB4" stroke-width="2"
                      stroke-linecap="round" />
                    <path d="M10.334 9.16797L9.75065 15.0013" stroke="#9A9FB4" stroke-width="2"
                      stroke-linecap="round" />
                </svg>
                <p11e-tooltip>Delete log file</p11e-tooltip>
              </q-btn>
            </div>
            <div v-if="selectedLogFile" class="text q-mt-sm">
              Local time: {{ 
                selectedLogFile?.dateIsoString ? new Date(selectedLogFile.dateIsoString).toLocaleString() : ''
              }}
            </div>

            <div v-show="selectedLogFile" class="row justify-between items-center q-px-xs q-mt-md">
              <div class="text-subtitle2 text-weight-medium">Log Data</div>
            </div>
            <q-input v-show="selectedLogFile" :autofocus=false v-model="logFileDisplay" color="grey" bg-color="accent"
              outlined dense class="rounded-borders q-mt-sm" type="textarea" 
              input-style="font-size:0.68rem; height:500px"
              style="overflow:auto;" 
              />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<style>
.right-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: white;
  transition: margin .3s ease-in-out;
  overflow-x: hidden;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  user-select: none;
}

</style>

<script setup>
import { ref, triggerRef } from 'vue'
// Actions
import { isSearchMenuOpen, openSearchMenu, closeSearchMenu } from '@/actions/search-menu.mjs'


/***NEW***/
import {
  columnFilterValue, filteredColumns,
  isFilteringActive, currentFilters, filterTab, savedFilterGroups,
  filterGroupName,
  canFilterColumns, canSaveFilteredColumns, canHideColumns, canPinColumns,
  canPublishToIntegration,
  logFileList,
  logFileDisplay,
  selectedLogFile
} from '../../ui/ui-states.mjs'
import { getCurrentColumnById } from '../../ui/ui-utils.mjs'
import { onColumnAddFilter } from '../../list-core/uis/column-ui-actions.mjs'

import { CONDITIONS_WITH_NO_FILTER_VALUE } from '../../constants/filter.mjs'

// grid manager
import { getFilterConditionsForType } from '../../grid-manager/utils/filter-utils.mjs'

// events
import { dispatch } from '../../events/dispatch.mjs'
import { 
  EVENT_COLUMN_FILTER_UPDATED_LOCAL, 
  EVENT_COLUMN_HIDE_LOCAL, EVENT_COLUMN_UNHIDE_LOCAL,
  EVENT_COLUMN_PIN_LOCAL, EVENT_COLUMN_UNPIN_LOCAL,
  EVENT_COLUMN_FILTER_APPLY_TOGGLE_LOCAL,
  EVENT_COLUMN_FILTER_ADD_LOCAL,
  EVENT_COLUMN_FILTER_REMOVE_LOCAL,
  EVENT_COLUMN_FILTER_ADD_RULE_LOCAL,
  EVENT_COLUMN_FILTER_REMOVE_RULE_LOCAL,
  EVENT_COLUMN_FILTER_SET_RULE_CONDITION_LOCAL,
  EVENT_COLUMN_FILTER_SET_RULE_VALUE_LOCAL,
  EVENT_COLUMN_FILTER_SAVE_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_APPLY_SAVED_GROUP_LOCAL,
  EVENT_COLUMN_FILTER_CANCEL_SAVED_GROUP_LOCAL
} from '../../events/constants/event-types.mjs'

// logfiles
import { getLogFile, clearLogFilesUi, removeLogFile } from '../../list-core/log-files/log-files-ui.mjs'

// Refs
const toggleAll = ref(null)
const saveBtnPressed = ref(false)

/**
 * Filter toggled handler
 */
const onFilterToggled = () => {
  dispatch(EVENT_COLUMN_FILTER_APPLY_TOGGLE_LOCAL, {
    isFilteringActive: isFilteringActive.value
  }) 
}



/**
 * Add filter handler
 */
const onAddFilter = (column) => {
  onColumnAddFilter(column)
}

/**
 * Add rule to filter handler
 */
function onAddRuleToFilter(filterIdx) {
  dispatch(EVENT_COLUMN_FILTER_ADD_RULE_LOCAL, {
    filterIdx: filterIdx
  })
}

/**
 * Remove filter rule handler
 */
function onRemoveFilterRule(filterIdx, ruleIdx) {
  dispatch(EVENT_COLUMN_FILTER_REMOVE_RULE_LOCAL, {
    filterIdx: filterIdx,
    ruleIdx: ruleIdx
  })
}

/**
 * Remove filter handler
 */
function onRemoveFilter(filterIdx) {
  dispatch(EVENT_COLUMN_FILTER_REMOVE_LOCAL, {
    filterIdx: filterIdx,
  })
}

/**
 * Update filter condition handler
 */
function onUpdateFilterCondition(filterIdx, ruleIdx, { value }) {
  dispatch(EVENT_COLUMN_FILTER_SET_RULE_CONDITION_LOCAL, {
    filterIdx: filterIdx,
    ruleIdx: ruleIdx,
    condition: value
  })
}

/**
 * Update filter value handler
 */
function onUpdateFilterValue(filterIdx, ruleIdx, value) {
  dispatch(EVENT_COLUMN_FILTER_SET_RULE_VALUE_LOCAL, {
    filterIdx: filterIdx,
    ruleIdx: ruleIdx,
    value: value
  })
}

/**
 * Save filter group handler
 */
function saveFilterGroup() {
  saveBtnPressed.value = false
  const isExisting = savedFilterGroups.value.some(filterGroup => filterGroup.name === filterGroupName.value)
  dispatch(EVENT_COLUMN_FILTER_SAVE_GROUP_LOCAL, { 
    name: filterGroupName.value,
    isExistingSavedFilterGroup: isExisting
  })
}




/**
 * Apply saved filter group handler
 */
async function onApplySavedFilterGroup(id) {
  filterTab.value = 'filter'
  saveBtnPressed.value = false
  dispatch(EVENT_COLUMN_FILTER_APPLY_SAVED_GROUP_LOCAL, {
    filterId: id
  })
}

/**
 * Remove saved filter group handler
 */
function onRemoveSavedFilterGroup(id) {
  dispatch(EVENT_COLUMN_FILTER_DELETE_SAVED_GROUP_LOCAL, {
    filterId: id
  })

}

/**
 * Clear filters handler
 */
function onClearFilters(filterGroupName) {
  dispatch(EVENT_COLUMN_FILTER_CANCEL_SAVED_GROUP_LOCAL, {
    filterGroupName: filterGroupName
  })
}

//#region //--- columns menu ---//

/**
 * Toggle column selection
 */
 function toggleColumnSelection() {
  const colIds = filteredColumns.value.map(column => column.id)
  setFieldVisibility(colIds, !toggleAll.value)
}

/**
 * Update pin handler
 */
function onUpdatePin(params, pinField) {
  const colIds = {
    columnIds: params
  }
  if (pinField) {
    return dispatch(EVENT_COLUMN_PIN_LOCAL, colIds)
  }
  return dispatch(EVENT_COLUMN_UNPIN_LOCAL, colIds)
}


const setFilteredColumns = () => {
  dispatch(EVENT_COLUMN_FILTER_UPDATED_LOCAL, {
    filterValue: columnFilterValue.value
  })
}

const setFieldVisibility = (params, hideField) => {
  const fields = {
    columnIds: params
  }
  if (hideField) {
    return dispatch(EVENT_COLUMN_HIDE_LOCAL, fields)
  }
  return dispatch(EVENT_COLUMN_UNHIDE_LOCAL, fields)
}
//#endregion

//#region //--- log menu ---//

const onSelectLogFile = async () => {
  await getLogFile(selectedLogFile.value)
}

const onDeleteLogFile = async () => {
  if (!selectedLogFile.value) {
    return
  }
  await removeLogFile(selectedLogFile.value)
  clearLogFilesUi()
}

const onClearSelectLogFile = () => {
  clearLogFilesUi()
}
//#endregion

</script>