/**
 * Dataabse constants
 */

/*
  Firebase best practices
  Maxiumn batch size is 500
  Limit the COLLECTION write rate - less than 1,000 operations/sec
  Limit the COLLECTION write rate with sequential values - less than 500 operations/sec
  Implicit exclude of sequential value from index if possible
*/

export const MIN_CONNECTION_POOL_SIZE = 1
export const MAX_CONNECTION_POOL_SIZE = 1


// settings here is per session.
export const DB_INSTANCE_POOL_SIZE = 1
export const COMMIT_BATCH_SIZE = 65
export const COMMIT_BATCH_WAIT = 300 // in millseconds

// Batch types
export const BATCH_SET = 'set'
export const BATCH_UPDATE = 'update'
export const BATCH_DELETE = 'delete' // delete document (permenant) 

// Regions
export const AU_REGION = 'australia-southeast1'