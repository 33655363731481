/*!
 *Team db
 */

 // Utils
import { required } from '../../utils'

// Db
import { Db } from '@/db/Db.mjs'
import { COLLECTION_TEAMS } from '@/db/db-collections.mjs'

export class TeamDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_TEAMS
    })
  }

  validateDocObject({
    ownerId = required ('ownerId')
  }) {
    return
  }

  createDocObject(team) {
    this.validateDocObject(team.toObject())
    return {
      defaultCurrencyIsoCode: team.defaultCurrencyIsoCode,
      defaultLocale: team.defaultLocale,
      environment: team.environment,
      expiresAt: team.expiresAt,
      isActive: team.isActive,
      libraryListId: team.libraryListId,
      members: team.members,
      name: team.name,
      ownerId: team.ownerId,
      plan: team.plan,
      planId: team.planId,
      planQuantity: team.planQuantity,
      planStatus: team.planStatus,
      ...super.createDocObject(team)
    }
  }
}
