import { getUserSession } from '@/session/user-session.mjs'
import { getTeamSession } from '@/session/team-session.mjs'

import { getItemsByConditions } from '../../item'
import { PRICE_LIST } from '../../list-core/constants/list-constants.mjs'

function getActiveListItemsQuery(listId) {
  return [
    ['lists', 'array-contains', listId],
    ['isDeleted', '==', false]
  ]
}

function getLibraryItemsQuery(listId) {
  return [
    ['libraryListId', '==', listId],
    ['isDeleted', '==', false]
  ]
}

function getItemsFromIntervalQuery(listId, updatedAtInterval = 0) {
  return [
    ['lists', 'array-contains', listId],
    ['updatedAtInterval', '>=', updatedAtInterval],
    ['isDeleted', '==', false]
  ]
}

function getDbListItems(listId) {
  const query = getActiveListItemsQuery(listId)
  return getItemsByConditions(query)
}

function getCreatedByMePriceListQuery() {
  const user = getUserSession()
  const team = getTeamSession()
  return [
    ['teamId', '==', team.id],
    ['createdBy', '==', user.uid],
    ['isDeleted', '==', false],
    ['type', '==', PRICE_LIST]
  ]
}

function getSharedWithMePriceListQuery() {
  const user = getUserSession()
  return [
    ['sharedWith', 'array-contains-any', user.emailAliases],
    ['isDeleted', '==', false],
    ['type', '==', PRICE_LIST]
  ]
}

export { 
  getDbListItems, getActiveListItemsQuery, getLibraryItemsQuery, getItemsFromIntervalQuery, 
  getCreatedByMePriceListQuery, getSharedWithMePriceListQuery,
}
