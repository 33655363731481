/*!
 * Database listener
 */


import { collection, query, onSnapshot, doc, where } from 'firebase/firestore'
import { required, throwError } from '@/utils'
import { DbInstance } from '@/db/DbInstance.mjs'

export class DbListener {
  #handle
  #db
  #collectionName
  #docId
  #queryConditions
  #addedFunction
  #modifiedFunction
  #removedFunction
  #handlerParams
  constructor({
    db,  // optional
    collectionName,
    docId,
    queryConditions,
    addedFunction,
    modifiedFunction,
    removedFunction,
    handlerParams
  } = {}) {
    this.#handle = null
    this.#db = db 
      || this.#db 
      || new DbInstance({ size: 1 }) // dedicated connection
    this.#collectionName = collectionName
    this.docId = docId
    this.queryConditions = queryConditions
    this.addedFunction = addedFunction
    this.modifiedFunction = modifiedFunction
    this.removedFunction = removedFunction
    this.handlerParams = handlerParams
  }

  get db() {
    return this.#db
  }

  get handlerParams() {
    return this.#handlerParams
  }

  set docId(docId) {
    this.#docId = docId  
  }

  set queryConditions(queryConditions) {
    this.#queryConditions = queryConditions  
  }

  set addedFunction(addedFunction) {
    this.#addedFunction = addedFunction  
  }

  set modifiedFunction(modifiedFunction) {
    this.#modifiedFunction = modifiedFunction  
  }

  set removedFunction(removedFunction) {
    this.#removedFunction = removedFunction  
  }

  set handlerParams(handlerParams) {
    this.#handlerParams = handlerParams
  }

  start({
    collectionName = this.#collectionName,
    docId = this.#docId,
    queryConditions = this.#queryConditions,
    addedFunction = this.#addedFunction,
    modifiedFunction = this.#modifiedFunction,
    removedFunction = this.#removedFunction,
    handlerParams = this.#handlerParams
  } = {} ) {
    this.#collectionName = collectionName
    this.#docId = docId
    this.#queryConditions = queryConditions
    this.#addedFunction = addedFunction
    this.#modifiedFunction = modifiedFunction
    this.#removedFunction = removedFunction   
    this.#handlerParams = handlerParams
    if (docId) {
      this.startDoc({
        collectionName: this.#collectionName,
        docId: this.#docId,
        localModifiedFunction: this.#modifiedFunction,
        serverModifiedFunction: this.#modifiedFunction,
        localRemovedFunction: this.#removedFunction,
        serverRemovedFunction: this.#removedFunction,
      })
      return this
    }
    if (!docId && queryConditions) {
      this.startQuery({
        collectionName: this.#collectionName,
        queryConditions: this.#queryConditions,
        localAddedFunction: this.#addedFunction,
        serverAddedFunction: this.#addedFunction,
        localModifiedFunction: this.#modifiedFunction,
        serverModifiedFunction: this.#modifiedFunction,
        localRemovedFunction: this.#removedFunction,
        serverRemovedFunction: this.#removedFunction,
      })
      return this
    }
    return
  }


  startDoc({
    collectionName,
    docId,
    localModifiedFunction,
    serverModifiedFunction,
    localRemovedFunction,
    serverRemovedFunction,
  }={}) {
    const db = this.#db.getDb()
    this.#handle = onSnapshot(doc(db, collectionName, docId),
      { includeMetadataChanges: true },
      (snapshot) => {
        const metadata = snapshot.metadata
        if (!metadata.hasPendingWrites && metadata.fromCache) {
          // prevent echo changes from browser multi-tab
          return
        }
        if (snapshot.exists()) {
          if (metadata.hasPendingWrites) {
            if (!localModifiedFunction) return
            localModifiedFunction({ id: snapshot.id, data: snapshot.data(), handlerParams: this.#handlerParams})
          } else {
            if (!serverModifiedFunction) return
            serverModifiedFunction({ id: snapshot.id, data: snapshot.data(), handlerParams: this.#handlerParams })
          }
        } else {
          if (metadata.hasPendingWrites) {
            if (!localRemovedFunction) return
            localRemovedFunction({ id: snapshot.id, data: snapshot.data(), handlerParams: this.#handlerParams })
          } else {
            if (!serverRemovedFunction) return
            serverRemovedFunction({ id: snapshot.id, data: snapshot.data(), handlerParams: this.#handlerParams })
          }
        }
      },
      (error) => {
        return throwError(`Listener Error: ${error}`)
      })
    return this
  }

  startQuery({
    collectionName,
    queryConditions,
    localAddedFunction,
    serverAddedFunction,
    localModifiedFunction,
    serverModifiedFunction,
    localRemovedFunction,
    serverRemovedFunction,
  }) {
    const db = this.#db.getDb()
    const condArr = queryConditions
      ? queryConditions.map(i => where(i[0], i[1], i[2]))
      : []
    const listenerQuery = query(
      collection(db, collectionName),
      ...condArr
    )

    this.#handle = onSnapshot(listenerQuery, { includeMetadataChanges: true }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const metadata = snapshot.metadata
        if (!metadata.hasPendingWrites && metadata.fromCache) {
          // prevent echo changes from browser multi-tab
          return
        }
        if (change.type === "added") {
          if (metadata.hasPendingWrites) {
            if (!localAddedFunction) return
            localAddedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams })
          } else {
            if (!serverAddedFunction) return
            serverAddedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams })
          }
        }
        if (change.type === "modified") {
          if (metadata.hasPendingWrites) {
            if (!localModifiedFunction) return
            localModifiedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams })
          } else {
            if (!serverModifiedFunction) return
            serverModifiedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams })
          }
        }
        if (change.type === "removed") {
          if (metadata.hasPendingWrites) {
            if (!localRemovedFunction) return
            localRemovedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams  })
          } else {
            if (!serverRemovedFunction) return
            serverRemovedFunction({ id: change.doc.id, data: change.doc.data(), handlerParams: this.#handlerParams  })
          }
        }
      })
    },
      (error) => {
        return throwError(`Listener Error: ${error}`)
      })

    return this
  }


  stop() {
    if (this.#handle) {
      this.#handle()
    }
    return this
  }
}