/*!
 * Current user actions
 */

// Dependencies
import { ref } from 'vue'
import {
  signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, OAuthProvider, onAuthStateChanged,
  signOut, sendEmailVerification, updateEmail, updateProfile, updatePassword, sendPasswordResetEmail, sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth'

import { raiseNotification } from '../notify/index.mjs'

import { getFirebaseAuth } from '../db/firebase.mjs'
import router from '../router'

// Utils
import { throwError, isNull } from '../utils'

// Session
import { getUserSession, setUserSession } from '../session'

// Events
import { dispatch } from '../events/dispatch.mjs'
import { EVENT_USER_AUTHENTICATED, EVENT_USER_SIGNOUT } from '../events/constants/event-types.mjs'

// Collections
import { getUserById } from '../user/actions/user-actions.mjs'
import { getTeamById } from '../team/actions/team-actions.mjs'

// Actions
import { clearCurrentTeam, setCurrentTeam } from '../actions/current-team.mjs'

// Constants
import { IS_DEBUG_MODE, CHAR_EMPTY } from '../constants'
import { setLoginLoaderLoading } from './login.mjs'


import { startEditorEventsAndListeners, startViewerEventsAndListeners, stopMainMenuListeners } from '../ui/main-menu-listeners.mjs'

// start event handlers
import { startIntegrationsEventListeners } from '../integrations/events/integrations-event-listeners.mjs'

// Permissions
import { setMainLayoutUiActionPermissionStates } from '../layouts/permissions/mainLayout-ui-action-permissions.mjs'

// start localDb
import { startLocalDb } from '../db-local/local-db.mjs'

// Refs
const email = ref(CHAR_EMPTY)
const password = ref(CHAR_EMPTY)
const showPassword = ref(true)
const currentUser = ref({})
const signInError = ref(null)
const firstName = ref(CHAR_EMPTY)
const lastName = ref(CHAR_EMPTY)
const confirmedPassword = ref(CHAR_EMPTY)
const currentUsers = ref([])

// Setup
const auth = getFirebaseAuth()

/**
 * Auth change handler
 */
setLoginLoaderLoading(true)
onAuthStateChanged(auth, user => {
  console.log('onAuthStateChanged called', auth, user)
  if (!user?.uid) {
    dispatch(EVENT_USER_SIGNOUT)
    return onUserAuthenticateFailure(user)
  }
  return onUserAuthenticateSuccess(user)
})

/**
 * Integration handlers
 */
startIntegrationsEventListeners()

/**
 * On authentication failure
 */
function onUserAuthenticateFailure() {
  clearCurrentUser()
  clearCurrentTeam()
  setLoginLoaderLoading(false)
  return router.replace('/signin')
}

// We will store the list to log into if required
if (location.href.includes('/list/')) {
  localStorage.setItem('list-url', location.pathname)
}

/**
 * Need email verification handler
 */
function needsEmailVerification(user) {
  setLoginLoaderLoading(false)
  sendEmailVerification(user)
  return router.replace('/register/verify')
}

/**
 * On authentication success
 */
function onUserAuthenticateSuccess(authedUser) {
  const listUrl = localStorage.getItem('list-url')
  if (listUrl?.length) {
    localStorage.removeItem('list-url')
  }
  if (!authedUser?.emailVerified) {
    setLoginLoaderLoading(false)
    setCurrentUser(authedUser)
    return verifyEmailAddress(authedUser)
  }
  setLoginLoaderLoading(true)
  getUserById(authedUser.uid)
    .then(userEntity => {
      const user = userEntity?.toObject()
      setCurrentUser({
        ...authedUser,
        ...user
      })
      setMainLayoutUiActionPermissionStates(user)
      if (!user.teamId) {
        IS_DEBUG_MODE && console.log('user is not in any teams')
        startViewerEventsAndListeners(user)
        setLoginLoaderLoading(false)
        if (listUrl?.length) {
          return router.replace(listUrl)
        }
        // TODO: should this happen?
        return router.replace('/')
      }
      return getTeamById(user.teamId).then(teamEntity => {
        const team = teamEntity.toObject()
        setCurrentTeam(team)
        // start main menu listeners 
        // TODO: move to event driven
        dispatch(EVENT_USER_AUTHENTICATED, { user, team })
        startEditorEventsAndListeners(user, team)
        if (listUrl?.length) {
          return router.replace(listUrl)
        }
        if (location.href.includes('/signin')) {
          router.replace('/')
        }
        setLoginLoaderLoading(false)
      })
    })
}

/**
 * Clear current user
 */
function clearCurrentUser() {
  setCurrentUser(null)
}

/**
 * Set the current user
 */
function setCurrentUser(user = {}) {
  currentUser.value = user
  setUserSession(user)
}

/**
 * Get current user
 */
function getCurrentUser() {
  return getUserSession()
}

/**
 * Clear sign in error
 */
function clearSignInError() {
  // TODO: check for the error code / type and set the message appropriately
  signInError.value = CHAR_EMPTY
}

/**
 * Set sign in error
 */
function setSignInError(err) {
  // TODO: check for the error code / type and set the message appropriately
  console.error('TODO:', err.code, err.message, err.customData)
  signInError.value = isNull(err) ? CHAR_EMPTY
    : 'Ooops... invalid credentials'
  setLoginLoaderLoading(false)
}

function onUserAlreadySignedUp(email) {
  return sendResetPasswordEmail(email)
    .then(() => raiseNotification(`We've sent an email to "${email}" for verification.`, 10000))
}

/**
 * Sign up a user with email and password
 */
function signUpWithUsernameAndPassword() {
  // TODO: check for invite here.
  createUserWithEmailAndPassword(auth, email.value, password.value)
    .then(async ({ user }) => {
      IS_DEBUG_MODE && console.log('sign up success', user)
      const displayName = firstName.value + ' ' + lastName.value
      if (!user.emailVerified) {
        return needsEmailVerification(user)
      }
      await updateProfile(user, {
        displayName
      })
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/email-already-in-use':
          return onUserAlreadySignedUp(email.value)
      }
      throw error
    })
}

/**
 * Sign in with username & password
 */
function signInWithPassword(email, password) {
  setLoginLoaderLoading(true)
  return signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if (!auth.currentUser.emailVerified) {
        return needsEmailVerification()
      }
      router.replace('/')
      clearSignInError()
    })
    .catch(setSignInError)
}

/**
 * Sign in with google
 */
function signInWithGoogle() {
  setLoginLoaderLoading(true)
  const provider = new GoogleAuthProvider()
  return signInWithPopup(auth, provider)
    .then(() => {
      router.replace('/')
      clearSignInError()
    })
    .catch(setSignInError)
}

/**
 * Sign in with microsoft
 */
function signInWithMicrosoft() {
  setLoginLoaderLoading(true)
  const provider = new OAuthProvider('microsoft.com')
  return signInWithPopup(auth, provider)
    .then(() => {
      // TODO: Get the user image if it's a microsoft account
      // https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
      router.replace('/')
      clearSignInError()
    })
    .catch(setSignInError)
}

/**
 * Sign in with Apple
 */
function signInWithApple() {
  // TODO
}

/**
 * Sign out
 */
function signOutUser() {
  // TODO: move to event driven
  dispatch(EVENT_USER_SIGNOUT, auth)
  stopMainMenuListeners()
  signOut(auth)
  window.location.reload()
}

/**
 * Verify email address
 */
function verifyEmailAddress(user = auth.currentUser) {
  sendEmailVerification(user)
    .then(() => raiseNotification(`We've sent an email to "${user.email}" for verification.`, 10000))
}

/**
 * Update user email
 */
function updateUserEmail(newEmail) {
  return updateEmail(auth.currentUser, newEmail)
}

/**
 * Update user password
 */
function updateUserPassword(newPassword) {
  updatePassword(auth.currentUser, newPassword)
}

/**
 * Send sign in link
 */
function sendSignInLink() {
  const actionCodeSettings = {
    url: location.href,
    handleCodeInApp: true
  }
  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .catch(setSignInError)
}

/**
 * Send reset password email
 */
function sendResetPasswordEmail(email) {
  return sendPasswordResetEmail(auth, email)
    .catch(console.error)
}

/**
 * Update user profile
 */
function updateUserProfile(updates = {}) {
  return updateProfile(auth.currentUser, updates)
}

// Exports
export {
  currentUser, showPassword, email, password, signInError, firstName, lastName, confirmedPassword,
  currentUsers,
  signInWithPassword, signInWithGoogle, signInWithApple, signInWithMicrosoft, getCurrentUser, setCurrentUser,
  signOutUser, verifyEmailAddress, updateUserEmail, updateUserPassword,
  sendResetPasswordEmail, updateUserProfile, sendSignInLink,
  signUpWithUsernameAndPassword,
}
