/*!
 * Team event handlers
 */

import { setCurrentTeam } from '../../actions/current-team.mjs'

function onTeamChanged({ id, data }) {
  setCurrentTeam(data)
}

function onTeamRemoved({ id, data }) {
}

function onTeamAdded({ id, data }) {
}


export {
  onTeamAdded, onTeamRemoved,onTeamChanged,
}
