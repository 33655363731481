
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../db/db-constants.mjs'

const app = getApp()
const CALLABLE_INTEGRATION_APPS = 'integrationApps'

let inegrationAppsRpc = null

/**
 * get the salesforce rpc function
 * @returns 
 */
export const getIntegrationAppsRpc = () => {
  console.log('=== getIntegrationAppsRpc called ===')
  if (inegrationAppsRpc) {
    return inegrationAppsRpc
  }
  const functions = getFunctions(app, AU_REGION)
  inegrationAppsRpc = httpsCallable(functions, CALLABLE_INTEGRATION_APPS, {timeout: 540000 } )
  return inegrationAppsRpc
}

