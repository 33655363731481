// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'
// local events
import {
  EVENT_PRICE_LIST_MODIFY_LOCAL,
  EVENT_PRICE_LIST_MODIFIED,
  EVENT_PRICE_LIST_MODIFIED_LOCAL,
  EVENT_PRICE_LIST_MODIFIED_REMOTE,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'


import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { SCOPE_GLOBAL } from '../../events/external-events.mjs'
import { subscribe, } from '../../events/external-events.mjs'

// entities
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

//actions 
import { getListAndColumns } from '../db/get-list-and-columns.mjs'

export const addEventPriceListModify = (controller) => {
  addEventListener(
    EVENT_PRICE_LIST_MODIFY_LOCAL,
    (event) => priceListModify(event),
    { signal: controller.signal }
  )
}

export const addEventsPriceListModified = (controller, subscriptions, columnManager, itemManager, {
  scope = SCOPE_GLOBAL
} = {}) => {
  console.log('start addEventsPriceListModified')
  addEventListener(
    EVENT_PRICE_LIST_MODIFIED_LOCAL,
    (event) => priceListModified(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_PRICE_LIST_MODIFIED,
      priceListModifiedRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_PRICE_LIST_MODIFIED_REMOTE,
    (event) => priceListModifiedRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return

}

//#region //--- New Column ---//
export const priceListModify = async (event, {
  scope = SCOPE_GLOBAL
} = {}) => {
  const { list } = event.detail
  const team = getTeamSession()

  const listEntity = !list.isListEntity
    ? (new ListEntity(list))
    : list

  const externalEvent = createExternalEvent({
    action: EVENT_PRICE_LIST_MODIFIED,
    scope,
    teamId: team.id,
    payload: {
      listId: list.id,
    }
  })
  listEntity.updatedByEventId = externalEvent.id
  await ListEntity.upsert(listEntity)
  dispatch(EVENT_PRICE_LIST_MODIFIED_LOCAL, { list: listEntity })

  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
}

/**
 * Add new column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const priceListModified = async (event, columnManager, itemManager, {
  scope = SCOPE_GLOBAL
} = {}) => {
  const { list } = event.detail
  const team = getTeamSession()

  if (columnManager && columnManager.list.id === list.id) {
    columnManager.list = list
    columnManager.refreshColumns()
  }

}


/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const priceListModifiedRemoteEvent = async (event, columnManager, itemManager) => {
  const { listId } = event.detail
  if (listId !== columnManager.list?.id) {
    IS_DEBUG_EVENT_MODE && console.log('Not for this list. skip.')
    return
  }
  const { list } = await getListAndColumns(listId)
  columnManager.list = list
  columnManager.refreshColumns()
  columnManager.setListUIActionPermissions()
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const priceListModifiedRemoteHandler = async (event) => {
  const { eventId, payload } = event
  console.log('got global event priceListModifiedRemoteHandler called')
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { listId } = payload
  dispatch(EVENT_PRICE_LIST_MODIFIED_REMOTE, {
    listId
  })

}
