/**
 * Keyboard constants
 */

export const KEY_BACKSPACE = 'Backspace'
export const KEY_DELETE    = 'Delete'
export const KEY_ENTER     = 'Enter'
export const KEY_DOWN      = 'ArrowDown'
export const KEY_UP        = 'ArrowUp'
export const KEY_LEFT      = 'ArrowLeft'
export const KEY_RIGHT     = 'ArrowRight'
