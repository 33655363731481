/*!
 * Team listener
 */

import { DbListener } from "@/db"
import { COLLECTION_TEAMS } from '@/db/db-collections.mjs'
import { required } from '@/utils/required.mjs'
import { fetchTeamQuery } from '../db/team-db.mjs'

export class TeamDbListener extends DbListener {
  constructor({
    collectionName = COLLECTION_TEAMS,
    docId = required('docId'),
    queryConditions = fetchTeamQuery(docId),
    addedFunction,
    modifiedFunction,
    removedFunction,
    ...props
  } = {}) {
    super({
      collectionName,
      queryConditions,
      addedFunction,
      modifiedFunction,
      removedFunction,
      ...props
    })
  }
}
