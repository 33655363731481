/*!
 *User db
 */

 // Utils
 import { required } from '../../utils'

 // Db
 import { Db } from '@/db/Db.mjs'
 import { COLLECTION_USERS } from '@/db/db-collections.mjs'

 
 export class UserDb extends Db {
   constructor() {
     super({
       collectionName: COLLECTION_USERS
     })
   }

   validateDocObject({
    email = required('email'),
  }) {
    return
  }

  createDocObject(user) {
    this.validateDocObject(user.toObject())
    return {
      disabled: user.disabled,
      displayName: user.displayName,
      email: user.email,
      emailAliases: [...user.emailAliases],
      emailVerified: user.emailVerified,
      isActive: user.isActive,
      isSuperAdmin: user.isSuperAdmin,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      role: user.role,
      teamId: user.teamId,
      teams: user.teams,
      uid: user.uid,
      ...super.createDocObject(user)
    }
  }
}
