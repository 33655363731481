<template>
  <q-dialog v-model="isSelectedCellValueEditorOpen">
    <q-card style="border-radius: 20px;  width: 25rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Edit {{ selectedItemsCount }} item{{ selectedItemsCount !== 1 ? 's' : '' }}</div>
      </q-card-section>
      <q-form @submit.prevent="onEditSelectedItems">
        <q-card-section class="q-pt-none">
          <!-- New Value input -->
          <div class="text-weight-medium q-mt-sm">New Value</div>
          <template v-if="!isFormulaMode">
            <!-- Text, multi-line, number, currency, percentage, date field -->
            <q-input 
              v-if="![FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST].includes(fieldSchema.type)" 
              v-model="newValue" 
              :type="inputType" 
              color="black" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              placeholder="New value" 
              dense
            />
            <!-- Taglist field -->
            <q-select 
              v-if="[FIELD_TYPE_TAGLIST].includes(fieldSchema.type)" 
              v-model="newValue" 
              color="primary" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              use-input 
              use-chips 
              multiple 
              hide-dropdown-icon 
              input-debounce="0" 
              new-value-mode="add-unique" 
              dense
            />
            <!-- Checkbox Field -->
            <p11e-checkbox 
              v-if="[FIELD_TYPE_CHECKBOX].includes(fieldSchema.type)"
              v-model="newValue"
              class="q-mt-sm"
              :value="newValue"
              :label="newValue === true ? DEFAULT_TRUE_VALUE
                    : newValue === false ? DEFAULT_FALSE_VALUE
                    : EMPTY_VALUE_TEXT" 
            />
          </template>
          <template v-if="isFormulaMode">
            <!-- Formula mode -->
            <q-input 
              v-if="isFormulaMode"
              v-model="newValue" 
              color="black" 
              bg-color="accent" 
              outlined 
              class="rounded-borders q-mt-sm" 
              placeholder="[Enter Formula]" 
              dense
            />
          </template>
          <q-checkbox @update:model-value="onFormulaModeChecked" v-model="isFormulaMode" class="q-mt-sm" label="Formula Mode" color="primary" />
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <q-btn
            no-caps
            unelevated
            class="rounded-borders"
            color="accent"
            text-color="dark"
            label="Cancel"
            style="width: 6.3rem;"
            v-close-popup
          />
          <q-btn
            no-caps
            unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Apply"
            style="width: 6.3rem;"
            v-close-popup
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>

</template>

<script setup>

// Dependencies
import { ref, shallowRef } from 'vue'

// Actions
import { isSelectedCellValueEditorOpen, selectedItemsCount, currentColumn, fieldEditorInputType } from '../../ui/ui-states.mjs'

//Constants
import {
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  EMPTY_VALUE_TEXT,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
} from '../../field-schema/field-schema-constants.mjs'

import {
  EVENT_OPEN_CELL_VALUE_EDITOR,
  EVENT_SELECTED_ITEMS_UPDATE_LOCAL
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'


// Refs
const newValue = ref()
const inputType = fieldEditorInputType
const isFormulaMode = ref(false)
let fieldSchema = shallowRef()

addEventListener(EVENT_OPEN_CELL_VALUE_EDITOR, (event) => {
  fieldSchema.value = event.detail.fieldSchema
  isSelectedCellValueEditorOpen.value = true
})


function onSelectedCellValueEditorOpen () {
}

/**
 * On edit selected items
 */
function onEditSelectedItems(params) {
  dispatch(EVENT_SELECTED_ITEMS_UPDATE_LOCAL, {
    key: fieldSchema.value.key,
    newValue: newValue.value,
    selectedItemsCount: selectedItemsCount.value,
  })
  
}

function onFormulaModeChecked () {
  return isFormulaMode.value = true
}

</script>