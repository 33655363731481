/**
 * List file management
 */
// Dependencies
import { required } from '../utils/required.mjs'
import { getBlob, uploadBytesResumable, getMetadata } from 'firebase/storage'
import { generateId } from '../utils/uuid.mjs'
import { storage, ref, } from './storage.mjs'
import { LIST_IMPORT_PATH, HEADER_ROW_INDEX, LABEL_UPLOAD } from './constants/storage-constants.mjs'
import { parse as parseCSV, unparse as unparseCSV } from 'papaparse'
import { convertStringToFile } from '../utils/file.mjs'
import { FIELD_LABEL_ID, FIELD_LABEL_CREATED_AT, FIELD_LABEL_UPDATED_AT } from '../field-schema/field-schema-default-fields.mjs'

// events
import { dispatch } from '../events/dispatch.mjs'
import { EVENT_PROGRESS_SET_STATUS } from '../events/constants/event-types.mjs'
async function uploadListCsv({
  id = generateId(),
  file = required('file or blob'),
  teamId = required('teamId'),
  listId = required('listId'),
  fileName,
  filePath,
} = required('upload data')) {
  filePath = filePath || `${teamId}/${LIST_IMPORT_PATH}/${id}`
  const storageRef = ref(storage, filePath)
  const metadata = {
    contentType: 'text/csv',
    customMetadata: {
      fileName: fileName || file.name,
      filePath: filePath,
      listId: listId,
      // TODO: PL - #465 - Commented out because of issue. will need to find a work around
      //fieldTypes: await getFieldSchemaTypes(file)
    }
  }
  const uploadTask = uploadBytesResumable(storageRef, file, metadata)
  uploadTask.on('state_changed',
  (snapshot) => {
    dispatch( EVENT_PROGRESS_SET_STATUS, {
      value:  snapshot.totalBytes,
      maxValue: snapshot.bytesTransferred
    })
  })
  return uploadTask
}

async function getListCsv({
  filePath
}) {
  const storageRef = ref(storage, filePath)
  return getBlob(storageRef)
}

/**
 * Get File Metadata Properties
 * @param {string} filePath 
 * @returns {Object} file metadata
 */
async function getListCsvMeta(filePath) {
  const storageRef = ref(storage, filePath)
  return await getMetadata(storageRef)
}

async function getListCsvData(filePath) {
  const storageRef = ref(storage, filePath)
  const file = await getBlob(storageRef)
  const csv = await new Promise(resolve => {
    parseCSV(file, {
      complete: results => {
        resolve(results)
      },
      delimiter: ','
    })
  })
  const labels = HEADER_ROW_INDEX
  csv.data[labels].forEach((label, index) => {
    if (!label) {
      label = `MISSING_HEADER_COL_${(index+1)}`
    }
    const systemLabels = [FIELD_LABEL_ID.toLowerCase(), 
                          FIELD_LABEL_CREATED_AT.toLowerCase(),
                          FIELD_LABEL_UPDATED_AT.toLowerCase()
                         ]
    systemLabels.includes(label.toLowerCase()) ? csv.data[labels][index] = LABEL_UPLOAD + label 
                                               : csv.data[labels][index] = label 
  })
  return csv
}

// save list item to csv file 
async function saveListCsvItems(listEntity) {
  const fieldKeys = listEntity.columnSet.columns
    .filter(column => !column.isHidden || !column.isDeleted)
    .map(column => column.fieldSchema.key)
  const fieldLabels = listEntity.columnSet.columns
    .filter(column => !column.isHidden || !column.isDeleted)
    .map(column => column.fieldSchema.label)
  const rows = listEntity.items.map(item => {
    return fieldKeys.map(key => item.fields[key].value)
  })

  const blob = unparseCSV({
    fields: fieldLabels,
    data: [...rows]
  }, {
    quotes: true, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    newline: "\r\n",
    skipEmptyLines: true,
  })
  const result = await uploadListCsv({
    file: convertStringToFile({ contents: blob, fileName: listEntity.name }),
    teamId: listEntity.teamId,
    listId: listEntity.id,
    fileName: listEntity.name,
    filePath: listEntity.csvFilePath || undefined
  })
  listEntity.csvFilePath = result.metadata.customMetadata.filePath
}

// Exports
export { uploadListCsv, getListCsv, getListCsvData, saveListCsvItems, getListCsvMeta }
