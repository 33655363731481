import { 
  FEATURE_KEY_LIST_EXPORT,
  PERMISSION_ANYONE, PERMISSION_NONE, PERMISSION_TEAM,
  ROLE_EDITOR, ROLE_VIEWER, ROLE_NONE,
} from '../../list-core/constants/list-constants.mjs'

import { LIBRARY_LIST, PRICE_LIST, CSV_PRICE_LIST }  from '../../list-core/constants/list-constants.mjs'
import { FALSE } from '../../constants/types.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'
//#region //-- list permission --//

/**
 * Determine if user is an editor of the list. 
 * @param {Object} user user entity
 * @param {Object} list list entity
 * @returns {boolean} boolean
 */
function isListEditor(user, list) {
  return user.teamId === list.teamId 
}

/**
 * Determine if user is a viewer of the list. 
 * @param {Object} user user entity
 * @param {Object} list list entity
 * @returns {boolean} boolean
 */
function isListViewer(user, list) {
  return list.sharedWith?.includes(user.email)
}

/**
 * Determine the user role of the list. 
 * @param {Object} user user entity
 * @param {Object} list list entity
 * @returns {string} role
 */
function getUserListRole(user, list) {
  if (isListEditor(user, list)) return ROLE_EDITOR
  if (isListViewer(user, list)) return ROLE_VIEWER
  return ROLE_NONE
}

/**
 * Determine if user is allowed to use the feature
 * @param {Ojbect} userEntity
 * @param {Object} listEntity
 * @returns {boolean} boolean
 */
function isExportActionAllowed(user, list) {
  const userRole = getUserListRole(user, list)
  const allowRoles = list[FEATURE_KEY_LIST_EXPORT] === PERMISSION_NONE ? [] 
    : list[FEATURE_KEY_LIST_EXPORT] === PERMISSION_ANYONE ? [ROLE_EDITOR, ROLE_VIEWER] 
    : list[FEATURE_KEY_LIST_EXPORT] === PERMISSION_TEAM ? [ROLE_EDITOR]
    : [ROLE_EDITOR]
  return allowRoles.includes(userRole)
}

/**
 * Determine if user is allowed to use the feature
 * @param {Ojbect} userEntity
 * @param {Object} listEntity
 * @returns {boolean} boolean
 */
function isColumnEditActionAllowed(user, list) {
  const userRole = getUserListRole(user, list)
  const allowRoles = list.type === LIBRARY_LIST ? [ROLE_EDITOR]
    : [PRICE_LIST].includes(list.type) ? [ROLE_EDITOR]
    : [CSV_PRICE_LIST].includes(list.type) ? [null]
    : [ROLE_EDITOR]
  return allowRoles.includes(userRole)
}

/**
 * Determine if user is allowed to use the feature
 * @param {Ojbect} userEntity
 * @param {Object} listEntity
 * @returns {boolean} boolean
 */
function isListRenameActionAllowed(user, list) {
  const userRole = getUserListRole(user, list)
  const allowRoles = list.type === 'Library' ? [ROLE_EDITOR]
    : list.type === 'Price List' ? [ROLE_EDITOR]
    : [ROLE_EDITOR]
  return allowRoles.includes(userRole)
}

/**
 * Determine if user is allowed to view the list
 * @param {Ojbect} userEntity
 * @param {Object} listEntity
 * @returns {boolean} boolean
 */
function isListViewActionAllowed(user, list) {
  const userRole = getUserListRole(user, list)
  const allowRoles = [LIBRARY_LIST].includes(list.type) ? [ROLE_EDITOR]
                   : [PRICE_LIST].includes(list.type) ? [ROLE_EDITOR, ROLE_VIEWER]
                   : [CSV_PRICE_LIST].includes(list.type) ? [ROLE_EDITOR]
                   : [ROLE_EDITOR]
  return allowRoles.includes(userRole)
}

/**
 * Determine if user is allowed to view the list
 * @param {Ojbect} userEntity
 * @param {string} listId
 * @returns {Promise} boolean
 */
async function isListViewAllowed(user, listId) {
  if (!listId) return FALSE
  const listEntity = await ListEntity.fetch(listId)
  if (!listEntity) {
    return FALSE
  }
  return isListViewActionAllowed(user, listEntity)
}
//#endregion

export {
  //roles  
  getUserListRole, isListEditor, isListViewer, 

  //actions
  isExportActionAllowed, isColumnEditActionAllowed, isListRenameActionAllowed, isListViewAllowed, isListViewActionAllowed,
}
