/*!
 * Price list event handlers
 */
import { triggerRef } from 'vue'
import { 
  teamPriceLists, teamPriceListsMap, supplierPriceLists, supplierPriceListsMap,
  currentList
} from '../../ui/ui-states.mjs'
import { getUserEmail } from '../../user/actions/user-actions.mjs'
import { PRICE_LIST, CSV_PRICE_LIST } from '../../list-core/constants/list-constants.mjs'
import { getUserSession } from '../../session/index.mjs'
import { getTeamById } from '../../team/actions/team-actions.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'

async function setUserEmails(data) {
  if (data.createdBy) {
    data.createdByUser = { email: await getUserEmail(data.createdBy) }
  }
  if (data.updatedBy) {
    data.updatedByUser = { email: await getUserEmail(data.updatedBy) }
  }
  return data
}

/**
 * update supplier price lists, filter out price list where its team is not active, sort price lists in ascending order
 * @param {Object[]} priceListsMap 
 * @returns {Object[]} filteredPriceLists
 */
async function filterSupplierPriceLists(priceListsMap) {
  const filteredPriceLists = await Promise.all(priceListsMap
    .map(async (pricelist) => {
      const teamIsActive = (await getTeamById(pricelist.teamId)).isActive
      return { ...pricelist, teamIsActive }
    }))
    .then((pricelists) => { 
      return pricelists
      .filter((pricelist) => pricelist.teamIsActive) //filter out price list where its team is not active
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    })
  return filteredPriceLists
}


async function setSuppliers(id, data) {
  supplierPriceListsMap.value.set(id, data)
  supplierPriceLists.value = await filterSupplierPriceLists([...supplierPriceListsMap.value.values()])
  triggerRef(supplierPriceLists)
}

async function removeSuppliers(id) {
  supplierPriceListsMap.value.delete(id)
  supplierPriceLists.value = await filterSupplierPriceLists([...supplierPriceListsMap.value.values()])
  triggerRef(supplierPriceLists)
}

async function refreshSuppliers() {
  supplierPriceLists.value = await filterSupplierPriceLists([...supplierPriceListsMap.value.values()])
  triggerRef(supplierPriceLists)
}

function setTeamPriceLists(id, data) {
  teamPriceListsMap.value.set(id, data)
  teamPriceLists.value = [ ...teamPriceListsMap.value.values() ].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
  triggerRef(teamPriceLists)
}

function removeTeamPriceLists(id) {
  teamPriceListsMap.value.delete(id)
  teamPriceLists.value = [ ...teamPriceListsMap.value.values() ].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
  triggerRef(teamPriceListsMap)
}

async function onTeamPriceListUpsert({ id, data }) {
  const user = await getUserSession()
  data = await setUserEmails(data)
  if (data.type === CSV_PRICE_LIST) {
    setTeamPriceLists(id, data)
  } 
  if (data.type === PRICE_LIST) {
    setTeamPriceLists(id, data)
  }
  const isSharedWithCurrentUser = (data.type === PRICE_LIST && data.sharedWith.some(email => [user.email, ...user.emailAliases].includes(email))) 
                                ? await setSuppliers(id, data) 
                                : await removeSuppliers(id, data)
  if (currentList.value.id === id) {
    currentList.value = new ListEntity(data)
  }
}

async function onTeamPriceListRemoved({ id, data }) {
  const user = await getUserSession()
  if (data.type === CSV_PRICE_LIST) {
    removeTeamPriceLists(id, data)
  } 
  if (data.type === PRICE_LIST && data.sharedWith.some(email => [user.email, ...user.emailAliases].includes(email))) {
    removeSuppliers(id, data)
  }
  if (data.type === PRICE_LIST) {
    removeTeamPriceLists(id, data)
  }
  if (currentList.value.id === id) {
    currentList.value = null
  }
}

async function onSharedWithUpsert({ id, data }) {
  data = await setUserEmails(data)
  await setSuppliers(id, data)
}

function onSharedWithRemoved({ id, data }) {
  removeSuppliers(id, data)
}

export {
  onTeamPriceListUpsert, onTeamPriceListRemoved,
  onSharedWithUpsert, onSharedWithRemoved, refreshSuppliers,
}
