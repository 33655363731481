// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

// events
import {
  EVENT_COLUMN_FIELD_SCHEMA_NEW,
  EVENT_COLUMN_FIELD_SCHEMA_NEW_LOCAL,
  EVENT_COLUMN_FIELD_SCHEMA_NEW_REMOTE,
  EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED,
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

// entities
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'
import { getTeamFieldSchemas } from '../../field-schema/actions/field-schema-actions.mjs'



//#region //--- New Column ---//

export const addEventsColumnFieldSchemaNew = (controller, subscriptions, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_FIELD_SCHEMA_NEW_LOCAL,
    (event) => newColumnFieldSchemaEvent(event, columnManager, itemManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_FIELD_SCHEMA_NEW,
      newColumnFieldSchemaRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_FIELD_SCHEMA_NEW_REMOTE,
    (event) => newColumnFieldSchemaRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}


/**
 * Add new column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const newColumnFieldSchemaEvent = async (event, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { column, fieldSchema, afterId } = event.detail
  const team = getTeamSession()

  const columnEntity = !column.isColumnEntity
    ? (new ColumnEntity(column))
    : column
  const fieldSchemaEntity = !fieldSchema.isFieldSchemaEntity
    ? (new FieldSchemaEntity(fieldSchema))
    : fieldSchema

  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_FIELD_SCHEMA_NEW,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnSetId: columnManager.columnSet.id,
      fieldSchemaId: fieldSchemaEntity.id
    }
  })

  columnEntity.key = fieldSchemaEntity.key
  await columnManager.addColumn(columnEntity, afterId, externalEvent)
  await columnManager.addFieldSchema(fieldSchemaEntity, externalEvent)
  columnManager.refreshColumns()
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })
  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
  itemManager.compileAllItems(columnManager.getFieldSchemas(), [fieldSchema.key])

}


/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const newColumnFieldSchemaRemoteEvent = async (event, columnManager, itemManager) => {
  const { columnSet, fieldSchema, fieldSchemas } = event.detail
  if (columnManager.columnSet.id !== columnSet.id) {
    return
  }
  columnManager.setColumnSet(columnSet)
  if (fieldSchemas) {
    columnManager.setFieldSchemas(fieldSchemas)
  } else {
    if (fieldSchema) {
      columnManager.setFieldSchema(fieldSchema)
    }
  }
  columnManager.refreshColumns()
  dispatch(EVENT_COLUMN_FIELD_SCHEMA_UPDATE_LOCAL_COMPLETED, { payload: columnManager })
  const keys = fieldSchemas ? fieldSchemas.map(fs => fs.key) : [fieldSchema.key]
  itemManager.compileAllItems(columnManager.getFieldSchemas(), keys)
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const newColumnFieldSchemaRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { columnSetId, fieldSchemaId, teamId } = payload
  let promises = []
  promises[0] = ColumnSetEntity.fetch(columnSetId)
  promises[1] = fieldSchemaId ? FieldSchemaEntity.fetch(fieldSchemaId): getTeamFieldSchemas(teamId)

  Promise.all(promises).then(values => {
    if (fieldSchemaId) {
      const [columnSet, fieldSchema] = values
      dispatch(EVENT_COLUMN_FIELD_SCHEMA_NEW_REMOTE, { columnSet, fieldSchema })
      return
    }
    if (teamId) {
      const [columnSet, teamFieldSchemas] = values
      const columnSchemaIds = columnSet.columns.map(col => col.fieldSchemaId)
      const fieldSchemas = fieldSchemaId ? undefined
        : teamFieldSchemas.filter(fs => columnSchemaIds.includes(fs.id))
      dispatch(EVENT_COLUMN_FIELD_SCHEMA_NEW_REMOTE, { columnSet, fieldSchemas })

    }
  })
}
