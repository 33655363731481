
// Dependencies
import { ref } from 'vue'
import Stripe from 'stripe'

// Utils
import { required } from '@/utils'
import { throwError } from '@/utils/errors.mjs'

// Collections
import { isExpired, deactivateInvite } from '../../email-invites/db/invites.mjs'

// Events
import { TeamListener } from '../events/TeamListener.mjs'

// Actions
import { 
  getTeamById, updateTeam, setTeamMember,
  getTeamMembers, removeTeamMember, createTeam_cloudfn
} from '../actions/team-actions.mjs'
import { getExistingTeamInvites, teamInvites, startTeamInvitesListeners, stopTeamInvitesListeners } from '../../email-invites/actions/invites.mjs'

import { currentUser } from '../../actions/current-user.mjs'
import { getCurrentTeam } from '../../actions/current-team.mjs'
import { removeUserTeam, getUserById, setUserTeam } from '../../user/actions/user-actions.mjs'

import { isRemoveMemberConfirmationOpen, currentTeam, selectedMember } from '../../ui/ui-states.mjs' 

import { getBillingPortalApiKey_cloudfn } from '../../billing/actions/billing-actions.mjs'

// Permissions
import { setTeamUiActionPermissionStates } from '../permissions/team-ui-action-permissions.mjs'
import { isTeamMember } from '../../permissions/utils/is-team-member-user.mjs'

// Refs
const members = ref([])
const isTeamLoaderLoading = ref(false)

//event handlers
let teamMembersEventListener = null
let teamEventListener = null

//#region team
/**
 * Update team and save to DB
 */
function updateCurrentTeam({
  id = required('team id'),
  name,
  ...props
}) {
  if (currentUser.value.teamId !== id) return
  getTeamById(id).then(teamEntity => {
    teamEntity.name = name
    return updateTeam(teamEntity)
  })
}

function createDefaultTeam({ name, ...props }){
  createTeam_cloudfn(name)
}

async function onTeamStart() {
  if (currentTeam.value.id) {
    teamInvites.value = await getExistingTeamInvites()
    startTeamInvitesListeners()
    startTeamListener()
  }
  const permissions = isTeamMember(currentUser.value, currentTeam.value) 
                    ? setTeamUiActionPermissionStates(currentUser.value?.role) 
                    : setTeamUiActionPermissionStates()
}

function onTeamStop() {
  stopTeamInvitesListeners()
  stopTeamListener()
}

//#endregion

//#region team member

function onRemoveTeamMember(selected) {
  isRemoveMemberConfirmationOpen.value = true
  selectedMember.value = selected
}

/**
 * Remove a team member
 */
async function removeOneTeamMember(
  userId = required('userId'),
) {
  const team = getCurrentTeam()
  const user = await getUserById(userId)
  // Dont allow a user to remove themselves
  if (userId === currentUser.value.id) {
    return throwError(`You can't remove yourself from a team`)
  }
  // Remove team membership from user record
  removeUserTeam(userId, team.id)
  //remove team membership from team record
  return await removeTeamMember(userId, team.id)
}

/**
 * Get Team Members
 */
async function getAllTeamMembers(
  teamId
) {
  const team = getCurrentTeam()
  teamId = teamId || team.id || required('teamId')
  const members = await getTeamMembers(teamId)
  return members
}

/**
 * Change team member role
 * @param {Object} member
 * @returns {Object} TeamEntity
 */
async function onMemberRoleChange(member) {
  const team = getCurrentTeam()
  const teamEntity = await setTeamMember({ teamId: team.id, ...member })

  // Change role in user record if user is using the current team
  const user = await getUserById(member.userId)
  if (team.id === user.teamId) {
    setUserTeam(user.id, user.teamId, member.role) 
  }
  return teamEntity
} 

//#endregion

//#region team listener
function startTeamListener() {
  const teamEntity = getCurrentTeam()
  if (teamEventListener) {
    return console.warn('Team listener has already started')
  } else {
    const teamEventListener = new TeamListener({ teamEntity })
    teamEventListener.start()
  }
}

function stopTeamListener(){
  if(!teamEventListener) return
  teamEventListener.stop()
  teamEventListener = null
}
//#endregion

async function openBillingPortal() {
   const stripeApiSecret = await getBillingPortalApiKey_cloudfn(location).then(result => result.data)
   const stripe = new Stripe(stripeApiSecret)
    const portalSession = await stripe.billingPortal.sessions.create({
      customer: currentTeam.value.billingCustomerId, 
      return_url: location.href
    })
  location.href = portalSession.url
}

export {
  onTeamStart, onTeamStop, getAllTeamMembers, startTeamListener, stopTeamListener,

  // team functions
  updateCurrentTeam, createDefaultTeam,

  // team member functions
  onRemoveTeamMember, removeOneTeamMember, onMemberRoleChange,

  // team states
  members, isTeamLoaderLoading,

  // Stripe integration
  openBillingPortal
}
