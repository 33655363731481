
import { required } from '../../utils/required.mjs'
import { canPublishToIntegration, logFileList, selectedLogFile, logFileDisplay } from '../../ui/ui-states.mjs'

// storage
import { storage, ref, getBlob } from '../../storage/storage.mjs'
import { deleteFileById } from '../../storage/delete-file.mjs'

// events
import { EVENT_LOG_FILE_ITEMS_ENABLE_LOCAL, EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL } from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

// list
import { getListAndColumns } from '../db/get-list-and-columns.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'

let currentLog = null

export const setLogFilesUi = (list) => {
  if (!list.logfiles) {
    clearLogFilesUi()
    return
  }
  if (!canPublishToIntegration.value) {
    logFileList.value = []
    return
  }
  logFileList.value = list.logfiles
    .map(meta => {
      const localTimeStr = new Date(meta.dateIsoString).toLocaleString()
      return {
        ...meta,
        label: `${meta.filename}`,
        listId: list.id
      }
    })
  return
}

export const clearLogFilesUi = () => {
  selectedLogFile.value = null
  logFileDisplay.value = null
  currentLog = null
  dispatch(EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL)
}

// TODO: PL - add scheduler to expire file regularly (cloud function)
export const getLogFile = async ({
  path
}) => {
  if (!path) {
    logFileDisplay.value = null
    dispatch(EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL)
    return
  }
  try {
    const fileRef = ref(storage, path)
    const ablob = await getBlob(fileRef)
    currentLog = JSON.parse(await ablob.text())
    logFileDisplay.value = JSON.stringify(currentLog, null, 2)
    if (currentLog) {
      dispatch(EVENT_LOG_FILE_ITEMS_ENABLE_LOCAL, { logdata: currentLog })
    } else {
      dispatch(EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL)
    }
  } catch (e) {
    logFileDisplay.value = 'Unable to load file'
  }
}

export const removeLogFile = async ({
  path,
  listId
}) => {
  if (!path || !listId) {
    return
  }

  const { list } = await getListAndColumns(listId)
  logFileDisplay.value = null
  selectedLogFile.value = null
  if (list) {
    list.removeLogfileMeta(path)
    await ListEntity.upsert(list)
    setLogFilesUi(list)
  }
  try {
    await deleteFileById(path)
  } catch (e) {
    console.error(e)
  }
  return
}

export const setLatestLogFile = ({
  path
}) => {
  if (!path || !logFileList.value || logFileList.value.length === 0) {
    selectedLogFile.value = null
    return
  }
  selectedLogFile.value = logFileList.value.find(f => f.path === path)
  return
}

export const setLogDisplayItem = (itemId) => {
  if (!itemId || !currentLog) {
    return
  }
  const displayData = currentLog.find(log => log.id === itemId)
  logFileDisplay.value = JSON.stringify(displayData?.publish, null, 2)
  return
}