// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../rpcs/salesforce-rpcs.mjs'
import { ACTION_REFRESH_CACHE } from '../constants/action-types.mjs'
import { testResponse} from '../states/salesforce-ui-states.mjs'

async function refreshCache(id) {
  console.log('refresh Cache(id) =====>', id)
  if (!id) {
    console.error('No integration Id to refresh')
    return
  }
  const sfdcIntegration = getSalesforceIntegrationRpc()
  const response = await sfdcIntegration({
    action: ACTION_REFRESH_CACHE,
    payload: {
      id,
    }
  })
  if (response.data.success) {
    testResponse.value = response.data?.message
  } else {
    testResponse.value = response.data?.errors
  }
}

export { refreshCache }