// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../../../integration-modules/salesforce/rpcs/salesforce-rpcs.mjs'
import { ACTION_IMPORT_BY_QUERY } from '../../../integration-modules/salesforce/constants/action-types.mjs'



export const importByQuery = async(id) => {
  if (!id) {
    console.error('Missing integration Id')
    return
  }
  const sfdcIntegration = getSalesforceIntegrationRpc()
  return await sfdcIntegration({
    action: ACTION_IMPORT_BY_QUERY,
    payload: {
      id: id,
    }
  })
}