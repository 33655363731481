// Dependencies
import { getDownloadUrl, storage, ref } from './storage.mjs'

/**
 * Get file url by id
 */
function getFileUrlById(fileId) {
  const fileRef = ref(storage, fileId)
  return getDownloadUrl(fileRef)
}

/**
 * Get file url by ref
 */
function getFileUrlByRef(fileRef) {
  return getDownloadUrl(fileRef)
}

// Exports
export { getFileUrlById, getFileUrlByRef }
