/**
 * Filter constants
 */

// Filter types
export const FILTER_TYPE_NUMBER  = 'number'
export const FILTER_TYPE_BOOLEAN = 'boolean'
export const FILTER_TYPE_DATE    = 'date'
export const FILTER_TYPE_FILE    = 'file'
export const FILTER_TYPE_ARRAY   = 'array'
export const FILTER_TYPE_TEXT    = 'text'

// Filter condition keys
export const KEY_EQUAL_TO              = 'equalTo'
export const KEY_NOT_EQUAL_TO          = 'notEqualTo'
export const KEY_LESS_THAN             = 'lessThan'
export const KEY_GREATER_THAN          = 'greaterThan'
export const KEY_LESS_THAN_EQUAL_TO    = 'lessThanEqualTo'
export const KEY_GREATER_THAN_EQUAL_TO = 'greaterThanEqualTo'
export const KEY_STARTS_WITH           = 'startsWith'
export const KEY_ENDS_WITH             = 'endsWith'
export const KEY_CONTAINS              = 'contains'
export const KEY_DOES_NOT_CONTAIN      = 'doesNotContain'
export const KEY_IS_TRUE               = 'isTrue'
export const KEY_IS_FALSE              = 'isFalse'
export const KEY_IS_EMPTY              = 'isEmpty'
export const KEY_HAS_WARNING           = 'hasWarning'
export const KEY_HAS_ERROR             = 'hasError'
export const KEY_HAS_NO_WARNING_OR_ERROR = 'hasNoWarningOrErrors'

// Filter condition labels
export const LABEL_EQUAL_TO              = 'Is equal to...'
export const LABEL_NOT_EQUAL_TO          = 'Is not equal to...'
export const LABEL_LESS_THAN             = 'Is less than...'
export const LABEL_GREATER_THAN          = 'Is greater than...'
export const LABEL_LESS_THAN_EQUAL_TO    = 'Is less than or equal to...'
export const LABEL_GREATER_THAN_EQUAL_TO = 'Is greater than or equal to...'
export const LABEL_IS                    = 'Is equal to...'
export const LABEL_IS_NOT                = 'Is not equal to...'
export const LABEL_STARTS_WITH           = 'Starts with...'
export const LABEL_ENDS_WITH             = 'Ends with...'
export const LABEL_CONTAINS              = 'Contains...'
export const LABEL_DOES_NOT_CONTAIN      = 'Does not contain...'
export const LABEL_IS_TRUE               = 'Is true...'
export const LABEL_IS_FALSE              = 'Is false...'
export const LABEL_IS_EMPTY              = 'Is empty'
export const LABEL_HAS_WARNING           = 'Has warning'
export const LABEL_HAS_ERROR             = 'Has error'
export const LABEL_HAS_NO_WARNING_OR_ERROR = 'Has no warnings or errors'

export const ALL_CONDITIONS = [
  { value: KEY_IS_EMPTY,              label: LABEL_IS_EMPTY },
  { value: KEY_HAS_WARNING,           label: LABEL_HAS_WARNING },
  { value: KEY_HAS_ERROR,             label: LABEL_HAS_ERROR },
  { value: KEY_HAS_NO_WARNING_OR_ERROR, label: LABEL_HAS_NO_WARNING_OR_ERROR }
]

// Filter conditions
export const NUMBER_RULE_CONDITIONS = [
  { value: KEY_EQUAL_TO,              label: LABEL_EQUAL_TO },
  { value: KEY_NOT_EQUAL_TO,          label: LABEL_NOT_EQUAL_TO },
  { value: KEY_LESS_THAN,             label: LABEL_LESS_THAN },
  { value: KEY_GREATER_THAN,          label: LABEL_GREATER_THAN },
  { value: KEY_LESS_THAN_EQUAL_TO,    label: LABEL_LESS_THAN_EQUAL_TO },
  { value: KEY_GREATER_THAN_EQUAL_TO, label: LABEL_GREATER_THAN_EQUAL_TO },
  ...ALL_CONDITIONS
]

export const STRING_RULE_CONDITIONS = [
  { value: KEY_EQUAL_TO,              label: LABEL_IS },
  { value: KEY_NOT_EQUAL_TO,          label: LABEL_IS_NOT },
  { value: KEY_STARTS_WITH,           label: LABEL_STARTS_WITH },
  { value: KEY_ENDS_WITH,             label: LABEL_ENDS_WITH },
  { value: KEY_CONTAINS,              label: LABEL_CONTAINS },
  { value: KEY_DOES_NOT_CONTAIN,      label: LABEL_DOES_NOT_CONTAIN },
  ...ALL_CONDITIONS
]

export const BOOLEAN_RULE_CONDITIONS = [
  { value: KEY_IS_TRUE,               label: LABEL_IS_TRUE },
  { value: KEY_IS_FALSE,              label: LABEL_IS_FALSE },
  ...ALL_CONDITIONS
]

export const DATE_RULE_CONDITIONS = [
  { value: KEY_EQUAL_TO,              label: LABEL_EQUAL_TO },
  { value: KEY_NOT_EQUAL_TO,          label: LABEL_NOT_EQUAL_TO },
  { value: KEY_LESS_THAN,             label: LABEL_LESS_THAN },
  { value: KEY_GREATER_THAN,          label: LABEL_GREATER_THAN },
  { value: KEY_LESS_THAN_EQUAL_TO,    label: LABEL_LESS_THAN_EQUAL_TO },
  { value: KEY_GREATER_THAN_EQUAL_TO, label: LABEL_GREATER_THAN_EQUAL_TO },
  ...ALL_CONDITIONS
]

export const ARRAY_RULE_CONDITIONS = [
  { value: KEY_CONTAINS,              label: LABEL_CONTAINS },
  { value: KEY_DOES_NOT_CONTAIN,      label: LABEL_DOES_NOT_CONTAIN },
  ...ALL_CONDITIONS
]

// Defaults
export const DEFAULT_FILTERGROUP_NAME = 'No Name'
export const NS_FILTERGROUP = 'filtergroup'

// Conditions with no filter value input
export const CONDITIONS_WITH_NO_FILTER_VALUE = [
  KEY_IS_EMPTY,
  KEY_HAS_WARNING,
  KEY_HAS_ERROR,
  KEY_HAS_NO_WARNING_OR_ERROR
]
