/*!
 * Current team actions
 */

// Dependencies
import { ref } from 'vue'

// Session
import { getTeamSession, setTeamSession } from '../session'

// Constants
import { IS_DEBUG_MODE } from '../constants'

// Refs
import { currentTeam } from '../ui/ui-states.mjs'
const teamTab       = ref('overview')
const memberColumns = ref([
  'Name', 'Email', 'Role', 'Accepted', ' '
])

import { members } from '../team/actions/team-ui.mjs'
import { getUserById } from '../user/actions/user-actions.mjs'
import { currentUser } from './current-user.mjs'
import { isSuperAdminUser } from '../permissions/utils/is-super-admin-user.mjs'

/**
 * Get current team
 */
function getCurrentTeam() {
  return getTeamSession()
}

/**
 * Set current team
 */
function setCurrentTeam(team = {}) {
  IS_DEBUG_MODE && console.log('set current team to => ', team)
  currentTeam.value = team

  if (Object.keys(team).length) {
    const memberList = Promise.all(team.members.map(async (member) => {
      const userEntity = await getUserById(member.userId)
      return { isSuperAdmin: userEntity.isSuperAdmin, ...member }
  
    }))
    memberList.then(result => {
      members.value = isSuperAdminUser(currentUser.value)
                    ? result
                    : result.filter(member => !member.isSuperAdmin)
    })
  }

  return setTeamSession(team)
}

/**
 * Clear current team
 */
function clearCurrentTeam() {
  return setCurrentTeam()
}

// Exports
export {
  teamTab, memberColumns,
  setCurrentTeam, getCurrentTeam, clearCurrentTeam
}
