import { isEmptyNullOrUndefined, isFormula } from '../../utils/is.mjs'
import { CHAR_EMPTY, CHAR_PERCENT } from '../../constants/characters.mjs'
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  DEFAULT_NUMBER_DP,
  DEFAULT_PERCENTAGE_DP,
  DEFAULT_TEXT_TYPE,
  DEFAULT_TRUE_VALUE,
  DEFAULT_FALSE_VALUE,
  EMPTY_VALUE_TEXT,
  DEFAULT_IS_TEXT_MULTI_LINE
} from '../field-schema-constants.mjs'

export function makeFormatCompileInput(type) {
  switch (type) {
    case FIELD_TYPE_PERCENTAGE:
      return formatPercentageCompileInput

    case FIELD_TYPE_NUMBER:
      return formatNumberCompileInput

    case FIELD_TYPE_CURRENCY:
      return formatCurrencyCompileInput

    case FIELD_TYPE_TAGLIST:
      return formatTagListCompileInput

    case FIELD_TYPE_CHECKBOX:
      return formatCheckboxCompileInput

    case FIELD_TYPE_DATE:
      return formatDateCompileInput

    default:
      return formatTextCompileInput
  }
}

const countDecimalPaces = (input) => {
  if (Number.isInteger(input)) {
    return 0
  }
  return input.toString().split('.')[1].length
}
/**
 * Make number format
 */
function formatNumberCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {
    return CHAR_EMPTY
  }
  return Number(String(input).trim())
}

/**
 * Make currency format
 */
function formatCurrencyCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {
    return CHAR_EMPTY
  }
  return Number(String(input).trim())
}

/**
 * Make percentge format
 */
function formatPercentageCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  if (isFormula(input)) {
    return input
  }
  const regex = /%$/i
  let inputValue = String(input).trim()
  if (!inputValue.match(regex)) {
    return Number(inputValue)
  }
  let cleanValue = Number(inputValue.replace(regex,''))
  return Number((cleanValue / 100).toFixed(countDecimalPaces(cleanValue) + 2))
}

/**
 * Make date time format
 */
function formatDateCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make taglist format
 */
function formatTagListCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return input
}

/**
 * Make text format
 */
function formatTextCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return String(input).trim()
}

/**
 * Make checkbox format
 */
function formatCheckboxCompileInput(input) {
  if (isEmptyNullOrUndefined(input)) {   
    return CHAR_EMPTY
  }
  return input
}

