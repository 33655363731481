/*!
 * list actions
 */

// sessions
import { getTeamSession } from '../../session/team-session.mjs'

import { saveItemsEntities } from '../../item/db/item-db.mjs'

import { required } from '@/utils/required.mjs'



// constants
import { LIBRARY_LIST } from '../../list-core/constants/list-constants.mjs'



// database adapters
import { getListById, getDbListItems } from '../db'
import { getColumnSet } from '../../column-set'
import { throwError } from '@/utils/errors.mjs'

import { setListCsvItems } from '../../list-core/actions/list-csv-actions.mjs'


// items
import { getLibraryItems } from '../../item/db/item-db.mjs'


//#region //--- list  actions ---//
async function getList(listId) {
  const listEntity = await getListById(listId)
  if (!listEntity) {
    return
  }

  if (!listEntity.columnSetId) {
    return listEntity
  }

  // load column set
  listEntity.columnSet = await getColumnSet(listEntity.columnSetId)

  return listEntity
}

// TODO: PL - migration salesforce import first before removing this
async function getListItems(listEntity) {
  const team = getTeamSession()
  if (listEntity.csvFilePath) {
    await setListCsvItems(listEntity)
    return listEntity
  }
  if (listEntity.type === LIBRARY_LIST) {
    listEntity.items = await getLibraryItems(listEntity.id)
  } else {
    if (!listEntity.isStatic) {
      const items =  await getLibraryItems(team.libraryListId)
      const newItems = []
      for (const item of items) {
        if (!item.lists.includes(listEntity.id)) {
          continue
        }
        newItems.push(item)
      }
      listEntity.items = newItems
    } else {
      listEntity.items = await getDbListItems(listEntity.id)
    }
  }
  //sort list items
  const columnKey = Object.keys(listEntity.columnSet.sortBy)
  const dir = Object.values(listEntity.columnSet.sortBy)
  if (columnKey.length) listEntity = sortListItems(listEntity, columnKey[0], dir[0])
  return listEntity
}

async function getListAndItems(listId) {
  const newList = await getList(listId)
  if (!newList) {
    return
  }
  return await getListItems(newList)
}


/**
 * Sort list items by column key
 * @param {Object} listEntity - the list entity with items to sort.
 * @param {string} columnKey - the column key to sort by.
 * @param {number} dir - the sort direction 1 for ascending, -1 for descending.
 * @returns {Object} list entity with items sorted.
 */
function sortListItems(
  listEntity = required('list entity'),
  columnKey = required('column key'),
  dir = required('sort direction')
) {
  const itemEntities = listEntity.items
  itemEntities.sort((a, b) => {
    const aFieldValue = a.fields?.[columnKey]?.value
    const bFieldValue = b.fields?.[columnKey]?.value
    const aValue = isNaN(aFieldValue) ? String(aFieldValue).toLowerCase() : aFieldValue
    const bValue = isNaN(bFieldValue) ? String(bFieldValue).toLowerCase() : bFieldValue

    if (dir === 0) {
      return 0
    }

    // A < B?
    if (aValue < bValue) {
      return -dir
    }

    // A > B?
    if (aValue > bValue) {
      return dir
    }

    // They are equal
    return 0
  })
  listEntity.columnSet.setSortBy(columnKey, dir)
  /* Notes: PL - make sort local only until we work out the better way to array items
  if (saveListToDb) {
    //saveListAndItems(listEntity)
    saveList(listEntity)
    //saveItemsEntities([itemEntity])
  }
  */
  return listEntity
}

//#endregion

export {

  // list
  getList, getListItems, getListAndItems,


  // items
  sortListItems,

}
