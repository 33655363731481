<template>
  <q-list @vnode-updated="scrollToBottom(this)" v-if="props.inviteList.length" style="max-height: 100px; border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;" class="scroll q-mb-md">
    <q-item v-for="invitee in props.inviteList" :key="invitee" clickable style="cursor: default !important; border-top: 1px solid #e0e0e0;">
      <q-item-section>
        <div class="row items-center w-full no-wrap">
          <div class="fit row items-center">{{invitee.email}}</div>
          <div class="q-ml-sm">{{invitee.role}}</div>
          <q-btn @click="$emit('removeEmail', invitee)" icon="close" size=".6rem" flat round />
        </div>
      </q-item-section>
    </q-item>
  </q-list>
  <div style="display: flex;">
    <q-input
      v-model="newInviteeName"
      type="text"
      :autofocus=true
      color="black"
      bg-color="accent"
      outlined
      class="rounded-borders fit"
      placeholder="Name"
      dense />

    <q-select 
      v-model="newInviteeRole" 
      :options="userRoleOptions" 
      style="width: 150px; margin-bottom: 1.2rem;"  
      class="q-ml-sm rounded-borders" 
      color="black" 
      bg-color="accent"
      outlined
      label="Role"
      dense
      >
    </q-select>
  </div>

  <div class="row items-center no-wrap">
    <q-input
      @keydown.capture="onEmailKeyDown"
      v-model="newInviteeEmail"
      type="email" 
      :rules="[ isEmailRule ]"
      color="black"
      bg-color="accent"
      outlined
      class="rounded-borders fit"
      placeholder="Email address"
      dense />

    <q-btn 
      @click="onAddEmailEvent"
      no-caps
      unelevated
      square
      class="q-ml-sm rounded-borders"
      style="margin-bottom: 1.2rem"
      color="primary"
      text-color="white"
      label="Add"
      :disable="!newInviteeEmail.length || !newInviteeName || !newInviteeRole.length || !isEmail(newInviteeEmail)" />
  </div>
</template>

<script setup>

// Dependenceis
import { defineProps, ref, defineEmits } from 'vue'

// Constants
import { KEY_ENTER } from '../constants/keyboard.mjs'
import { CHAR_EMPTY } from '../constants/characters.mjs'
import { USER_ROLES, DEFAULT_USER_ROLE } from '../permissions/constants/roles.mjs'

// Utils
import { isEmail } from '../utils/is.mjs'
import { getFullNameFromEmail } from '../utils/strings.mjs'

// Props
const props = defineProps(['inviteList'])

// Emits
const emit = defineEmits([ 'removeEmail', 'addEmail' ])

// Constants
const newInviteeEmail = ref('')
const newInviteeRole  = ref('')
const newInviteeName  = ref('')
const userRoleOptions = ref(USER_ROLES)

// Email validation
function isEmailRule(val) {
  return (isEmail(val) || val === CHAR_EMPTY) || 'Invalid email'
}

// Add new email event
function onAddEmailEvent() {
  const newInvitee = {
    name: newInviteeName.value || getFullNameFromEmail(newInviteeEmail.value),
    role: newInviteeRole.value,
    email: newInviteeEmail.value,
  }
  emit('addEmail', newInvitee)
  newInviteeName.value = CHAR_EMPTY
  newInviteeRole.value = CHAR_EMPTY
  newInviteeEmail.value = CHAR_EMPTY
}

// Keydown event
function onEmailKeyDown(event) {
  newInviteeRole.value = DEFAULT_USER_ROLE
  if (event.key === KEY_ENTER) {
    onAddEmailEvent()
    event.preventDefault()
  }
}

// Scroll to the bottom
function scrollToBottom(element) {
  const el = element.$el.nextSibling
  el.scrollTop = el.scrollHeight
}
</script>
