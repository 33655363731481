import { getListCoreRpc } from '../../db/rpcs/db-rpcs.mjs'
import { ACTION_GET_LIST_AND_COLUMNS } from '../../list-core/constants/action-types.mjs'

import { ListEntity } from '../../entity/ListEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'

export const getListAndColumns = async (listId) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_LIST_AND_COLUMNS,
    payload: {
      id: listId
    }
  })
  if (!result.data.success) {
    return
  }

  const { list, columnSet, fieldSchemas, libraryColumnSet, importColumnSet } = result.data.result

  let listEntity = null
  let columnSetEntity = null
  let fieldSchemaEntities = []
  let libraryColumnSetEntity = null
  let importColumnSetEntity = null

  listEntity = new ListEntity(list)
  if (columnSet?.id) {
    columnSetEntity = ColumnSetEntity.toEntity(columnSet)
  }

  if (fieldSchemas) {
    for (const fieldSchema of fieldSchemas) {
      fieldSchemaEntities.push( new FieldSchemaEntity( fieldSchema ))
    }
  }

  if (libraryColumnSet?.id) {
    libraryColumnSetEntity = ColumnSetEntity.toEntity(libraryColumnSet)
  }


  if (importColumnSet?.id) {
    importColumnSetEntity = ColumnSetEntity.toEntity(importColumnSet)
  }

  return {
    list: listEntity,
    columnSet: columnSetEntity,
    fieldSchemas: fieldSchemaEntities,
    libraryColumnSet: libraryColumnSetEntity,
    importColumnSet: importColumnSetEntity,
  }
}