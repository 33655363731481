import {
  EVENT_UPLOAD_CSV_LOCAL_COMPLETED,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'
import { getTeamSession } from '../../session/team-session.mjs'

import { setCsvGridItems } from '../../list-core/actions/csv-item-actions.mjs'

// actions
import { getListAndColumns } from '../../list-core/db/get-list-and-columns.mjs'
import { getImportColumnSet } from '../../list-core/import/list-import-actions.mjs'
//#region //--- New Column ---//
/**
 * Update column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const reloadCsvListEvent = async (event, columnManager, itemManager) => {
  if (!event.detail?.list) {
    return
  }
  const team = getTeamSession()
  const { list } = event.detail

  //const  = await getListAndColumns(list.id)
  const importColumnSet = await getImportColumnSet(list.id, team.id)

  // refresh columns
  columnManager.list = list
  columnManager.columnSet = list.columnSet
  columnManager.importColumnSet = importColumnSet
  columnManager.setFieldSchemas(list.columnSet.columns.map(col => col.fieldSchema ))
  columnManager.refreshColumns()
  setCsvGridItems(list, columnManager, itemManager)
  return
}



export const addEventsUploadCsvCompleted = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_UPLOAD_CSV_LOCAL_COMPLETED,
    (event) => reloadCsvListEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}