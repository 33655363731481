/**
 * Regex constants
 */

export const REGEX_ALPHA_AND_WHITESPACE   = /[a-zA-Z0-9\s]+/g
export const REGEX_URL                    = /(http|ftp)s?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
export const REGEX_NON_ALPHANUMERIC       = /[^a-zA-Z0-9]/
export const REGEX_NUMBER_CSV_FORMAT      = /^(?:\d+(?:,\d{3})*(?:\.\d+)?)$/
export const REGEX_PERCENTAGE_CSV_FORMAT  = /^(?:\d+(?:,\d{3})*(?:\.\d+)?[%])$/
export const REGEX_CURRENCY_CSV_FORMAT    = /^(?:[$]\d+(?:,\d{3})*(?:\.\d+)?)$/
export const REGEX_TAGLIST_CSV_FORMAT     = /^(?:\S+?,){1,}\S+$/
export const REGEX_CHECKBOX_CSV_FORMAT    = /^TRUE|FALSE|YES|NO$/i
export const REGEX_NUMBER_FROM_CURRENCY   = /[0-9|.]/g
export const REGEX_COMMA_AND_OR_SPACE     = /,\s?/g
export const REGEX_DATE_MM_DD_YYYY        = /^(0?[1-9]|1[0-2])[./](0?[1-9]|[1-2][0-9]|3[0-1])[./](\d{4})$/
export const REGEX_DATE_DD_MM_YYYY        = /^(0?[1-9]|[1-2][0-9]|3[0-1])[./](0?[1-9]|1[0-2])[./](\d{4})$/
export const REGEX_DATE_ISO_FORMAT        = /^(\d{4})-(\d{2})-(\d{2})(T\d{2}:\d{2}:\d{2}(\.\d{1,3})?([+-]\d{2}:\d{2})?Z)?$/
export const REGEX_DATE_TIMESTAMP_SECOND  = /^\d{1,10}$/
export const REGEX_DATE_TIMESTAMP_MILLISECOND  = /^\d{1,13}$/
