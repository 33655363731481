/*!
* Mail Actions
*/

// Utils
import { required } from '../../utils/required.mjs'
import { getFirstword, ucFirst } from '../../utils/strings.mjs'

//Constants
import { TEAM_INVITE_TEMPLATE_NAME, LIST_INVITE_TEMPLATE_NAME} from '../../constants/email.mjs'

// Collections
import { createOneTeamInviteEmail, createEmail, createOneListInviteMail } from '../db/email.mjs'
import { getTeamById } from '../../team/actions/team-actions.mjs'
import { ListEntity } from '../../entity/ListEntity.mjs'
/**
 * Send email
 * @param {*} param0 
 * @returns { mailId }
 */
function sendEmail({
  to = required('to'),
  cc = null,
  bcc = null,
  from,
  replyTo,
  subject,
  text,
  html
}) {
  return createEmail({
    to: to.replace(/\s+/g, '').split(','),
    cc: cc ? cc.replace(/\s+/g, '').split(',') : null,
    bcc: bcc ? bcc.replace(/\s+/g, '').split(',') : null,
    from,
    replyTo,
    subject,
    text,
    html
  })
}

/**
 * Send team invite email
 * @param {*} param0 
 * @returns { mailId }
 */
async function sendTeamInviteEmail({
  templateName = TEAM_INVITE_TEMPLATE_NAME,
  invite
}) {
  const team = await getTeamById(invite.teamId)
  const templateData = {
    receiverEmail: invite.receiverEmail,
    teamName: team.name,
    senderEmail: invite.senderEmail,
    firstName: ucFirst(getFirstword(invite?.name))
  }
  return createOneTeamInviteEmail({
    to: invite.receiverEmail,
    templateName,
    templateData,
    teamId: invite.teamId,
    inviteId: invite.id
  })
}

/**
 * Send pricelist invite email
 * @param {*} param0 
 * @returns { mailId }
 */
 async function sendListInviteMail({
  templateName = LIST_INVITE_TEMPLATE_NAME,
  invite
}) {
  const list = await ListEntity.fetch(invite.listId)
  const team = await getTeamById(list.teamId)
  const templateData = {
    receiverEmail: invite.receiverEmail,
    teamName: team.name,
    senderEmail: invite.senderEmail,
    listId: invite.listId,
    priceListName: list.name,
    message: invite.message,
  }
  return createOneListInviteMail({
    to: invite.receiverEmail,
    templateName,
    templateData,
    teamId: team.id,
    inviteId: invite.id
  })
}

export { sendEmail, sendTeamInviteEmail, sendListInviteMail }
