/**
 * format item field values
 */

//import functions from 'firebase-functions'
// Utils
import {
  isCurrency, isNumber, isEmpty,
  isPercentage, isCheckbox, isTaglist, isEmptyNullOrUndefined,
} from '../../utils/is.mjs'
import {
  parseCurrency, parsePercentage,
} from '../../utils/parse.mjs'
import {
  formatPercentage, formatCurrency, formatDate, formatNumber, formatHtml
} from '../../utils/format.mjs'

// Constants
import { DEFAULT_LOCALE } from '../../constants/locale.mjs'
import {
  FIELD_TYPE_PERCENTAGE, FIELD_TYPE_NUMBER, FIELD_TYPE_TAGLIST,
  FIELD_TYPE_CURRENCY, FIELD_TYPE_CHECKBOX, FIELD_TYPE_DATE,
} from '../../constants/field.mjs'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import { NULL } from '../../constants/types.mjs'

// make formats
import {
  makePercentageFormat, makeCurrencyFormat,
  makeNumberFormat, makeTagListFormat,
  makeCheckboxFormat, makeDateFormat,
  makeTextFormat
} from '../../field-schema/utils/make-field-formats.mjs'


//const logger = functions.logger

// Constants TODO: move out
const USER_LOCAL_ZONE_CODE = DEFAULT_LOCALE // TODO: move this into settings

const WARN_CURRENCY_NOT_NUMBER = 'Currency field is expecting a number'
const WARN_NUMBER_NOT_NUMBER = 'Number field is expecting a number'
const WARN_INVALID_PERCENTAGE = 'Percentage field is expecting a number'
const WARN_CHECKBOX_NOT_BOOLEAN = 'Checkbox is expecting true/false'
const WARN_TAGLIST_NOT_LIST = 'Taglist is expecting a list'
const WARN_INVALID_DATE = 'Date field is expecting YYYY-MM-DD format'
const WARN_CURRENCY_INFINITY = 'Currency field should not be Infinity'

const INFINITY = 'Infinity'

export const formatItemValues = (item, fieldSchemaMap) => {
  for (const key in item.fields) {
    const fieldSchema = fieldSchemaMap.get(key)
    if (!fieldSchema) {
      item.fields[key].formattedValue = item.fields[key].value
      continue
    }
    item.fields[key] = formatItemField(item.fields[key], fieldSchema)
  }
  return item
}


/**
 * Make item field final
 * prepare item field for output
 */
export const formatItemField = ({
  inputValue = null,
  value = null,
  formattedValue = CHAR_EMPTY,
  warning = null,
  error = null,
},
  fieldSchema
) => {
  let fieldType = fieldSchema.type
  let placeholderText = fieldSchema.placeholderText || CHAR_EMPTY
  let format = fieldSchema.format || {}
  let fieldFormat = null

  switch (fieldType) {
    case FIELD_TYPE_PERCENTAGE:
      fieldFormat = makePercentageFormat(format)
      if (isEmptyNullOrUndefined(value)) {
        formattedValue = CHAR_EMPTY
      }
      else if (!isPercentage(value)) {
        warning = WARN_INVALID_PERCENTAGE
      }
      else {
        value = Number(parsePercentage(value)?.toFixed(fieldFormat.decimalPlaces + 2))// shift additional decimal places for correct % decimal place
        formattedValue = formatPercentage(value, fieldFormat.decimalPlaces, USER_LOCAL_ZONE_CODE)
      }
      break

    case FIELD_TYPE_CURRENCY:
      fieldFormat = makeCurrencyFormat(format)
      if (isEmptyNullOrUndefined(value)) {
        formattedValue = CHAR_EMPTY
      }
      else if (!isCurrency(value)) {
        warning = WARN_CURRENCY_NOT_NUMBER
      }
      // currency formatter cannot handle infinity. handler it here instead
      else if (value === INFINITY) {
        warning = WARN_CURRENCY_INFINITY
      }
      else {
        value = Number(parseCurrency(value)?.toFixed(fieldFormat.decimalPlaces)) // Make sure the value is a number
        formattedValue = formatCurrency(value, fieldFormat.decimalPlaces, USER_LOCAL_ZONE_CODE, fieldFormat.isoCode)
      }
      break

    case FIELD_TYPE_NUMBER:
      fieldFormat = makeNumberFormat(format)
      if (isEmptyNullOrUndefined(value)) {
        formattedValue = CHAR_EMPTY
      }
      else if (!isNumber(value)) {
        warning = WARN_NUMBER_NOT_NUMBER
      }
      else {
        value = Number(parseFloat(value)?.toFixed(fieldFormat.decimalPlaces)) // Make sure the value is a number
        formattedValue = formatNumber(value, fieldFormat.decimalPlaces, USER_LOCAL_ZONE_CODE)
      }
      break

    case FIELD_TYPE_TAGLIST:
      if (isEmptyNullOrUndefined(value)) {
        formattedValue = CHAR_EMPTY
        value = []
      }
      else if (!isTaglist(value)) {
        warning = WARN_TAGLIST_NOT_LIST
      }
      else {
        fieldFormat = makeTagListFormat(format)
        formattedValue = value.join(', ')
      }
      break

    case FIELD_TYPE_CHECKBOX:
      fieldFormat = makeCheckboxFormat(format)
      if (isEmptyNullOrUndefined(value)) {
        formattedValue = fieldFormat.emptyValue
        value = null
      }
      else if (!isCheckbox(value)) {
        warning = WARN_CHECKBOX_NOT_BOOLEAN
      }
      else {
        formattedValue = value ? fieldFormat.trueValue
          : fieldFormat.falseValue
      }
      break

    case FIELD_TYPE_DATE:
      if (!isEmptyNullOrUndefined(value)) {
        fieldFormat = makeDateFormat(format)
        formattedValue = formatDate(
          value?.seconds
            ? value.seconds * 1000
            : value)

        if (formattedValue.includes('Invalid')) {
          warning = formattedValue
        }
        if (!formattedValue) {
          formattedValue = value
          warning = WARN_INVALID_DATE
        }
      } else {
        if (inputValue) {
          warning = WARN_INVALID_DATE
        }
        value = CHAR_EMPTY
        formattedValue = CHAR_EMPTY
      }
      break

    default:
      fieldFormat = makeTextFormat(format)
      formattedValue = formatHtml(value)
      break
  }
  formattedValue = (warning || error) ? NULL
    : (formattedValue || placeholderText)
  return {
    inputValue,
    value,
    formattedValue,
    warning,
    error,
  }
}
