// Dependencies
import { required } from '../utils'

// constants
import { IS_DEBUG_EVENT_MODE } from '../constants/debug.mjs'

//sessions
import { getTeamSession } from '../session/team-session.mjs'
import { generateEventId, setSessionEvent } from './session-events.mjs'

// firebase
import { Db } from '../db/Db.mjs'
import { COLLECTION_EVENTS } from '../db/db-collections.mjs'

//
import { SCOPE_GLOBAL, SCOPE_TEAM } from './external-events.mjs'

const db = new Db({ collectionName: COLLECTION_EVENTS })

export const createTeamEvent = (
  action = required('action'),
  payload = {}
) => {
  const team = getTeamSession()
  return {
    id: generateEventId(),
    action,
    scope: SCOPE_TEAM,
    teamId: team.id,
    payload: payload || {}
  }
}

export const createExternalEvent = ({
  action = required('action'),
  scope = SCOPE_GLOBAL,
  teamId,
  payload = {}
}) => {
  const team = getTeamSession()
  return {
    id: generateEventId(),
    action,
    scope,
    teamId,
    payload: payload || {}
  }
}

export const emitEvent = (
  event = required('event'),
) => {
  IS_DEBUG_EVENT_MODE && console.log(`EMIT EVENT: ${event.action}  (SCOPE: ${event.scope})`)
  IS_DEBUG_EVENT_MODE && console.log('EVENT PAYLOAD:', event.payload)

  setSessionEvent(event)
  db.upsert(event)
  return event
}