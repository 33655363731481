/*!
 * Team db functions
 */
import { throwError } from '@/utils/errors.mjs'
import { UserEntity } from '../../entity/UserEntity.mjs'
import { UserDb } from './UserDb.mjs'

const db = new UserDb()

/**
 * Fetch user by id
 * @param {string} id - user id 
 * @returns {Promise} UserEntity
 */
async function fetchUserEntity(id) {
  const user = await db.getById(id)
  if (!user) return
  return new UserEntity(user)
}

/**
 * Save user to database
 * @param {Object} userEntity - user entity object
 * @returns {Promise} document
 */
function saveUserEntity(userEntity) {
  const saveObject = db.createDocObject(userEntity)
  return db.upsert(saveObject)
}

/**
 * Mark user as deleted by userEntity
 * @param {Object} userEntity 
 * @returns {Object} UserEntity
 */
 async function deleteUserEntity(userEntity) {
  if (!userEntity.isDeletable) {
    throwError('User is not deletable')
  }
  userEntity.markAsDeleted()
  saveUserEntity(userEntity)
  return userEntity
}

/**
 * Fetch user by email
 * @param {string} email - user email 
 * @returns {Promise} UserEntity
 */
async function fetchUserByEmail(email) {
  const queryConditions = fetchUserByEmailQuery(email)
  const users = await db.getByConditions(queryConditions)
  if (!users.length) return null
  if (users.length > 1) {
    throwError(`Found ${users.length} users with ${email}`)
  }
  return new UserEntity(users[0])
}

/**
 * Get users by teamId
 * @param {string} teamId - team Id 
 * @returns {Promise} UserEntities
 */
 async function fetchUsersByTeamId(teamId) {
  const queryConditions = fetchUsersByTeamIdQuery(teamId)
  const users = await db.getByConditions(queryConditions)
  if (!users.length) return null
  return users.map(user => new UserEntity(user))
}

function fetchUsersByTeamIdQuery(teamId) {
  return [
    ['teamId', '==', teamId],
    ['isDeleted', '==', false]
  ]
}

function fetchUserQuery(id) {
  return [
    ['id', '==', id],
    ['isDeleted', '==', false]
  ]
}

function fetchUserByEmailQuery(email) {
  return [
    ['emailAliases', 'array-contains', email],
    ['isDeleted', '==', false]
  ]
}

export {
  fetchUserEntity, saveUserEntity, fetchUserByEmail, fetchUsersByTeamId,
  fetchUsersByTeamIdQuery, fetchUserQuery, deleteUserEntity,
}