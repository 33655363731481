<template>
  <main-layout>
    <q-toolbar class="row items-center q-px-md q-pt-lg q-pb-xs">
      <q-toolbar-title class="text-dark text-weight-bold text-center">Super Admin</q-toolbar-title>
    </q-toolbar>
    <q-tabs
      v-model="showSection"
      class="text-grey-5 text-weight-medium"
      indicator-color="primary"
      active-color="dark"
      style="border-bottom: 1px solid #EBEEF5;"
    >
      <q-tab :name="`${DEMO_SECTION}`" label="Demo"  no-caps class="q-px-lg"/>
      <q-tab :name="`${TEAMS_MANAGEMENT_SECTION}`" label="Teams" no-caps class="q-px-lg"/>
      <q-tab :name="`${LIBRARY_MANAGEMENT_SECTION}`" label="Library"  no-caps class="q-px-lg"/>
      <q-tab :name="`${DEVELOPER_SECTION}`" label="Developer Area"  no-caps class="q-px-lg"/>

    </q-tabs>
    <q-tab-panels v-model="showSection" animated>
      <!-- Demo Managment -->
      <q-tab-panel :name="`${DEMO_SECTION}`" 
        class="row justify-center items-center" style="margin-top: 2rem;">
        <div style="width: 80rem;">
          <q-item-section>
            <div class="text-h6 q-mx-xs q-pb-xs">{{ showSection }}</div>
            <div class="text-subtitle2 q-mx-xs q-pt-xs">Create demo team:</div>
            <q-item>

            <div class="text-subtitle2 q-mx-xs q-pt-sm">Name:</div>
            <div class="q-mr-xs">
              <q-input v-model.trim="demoTeamName" class="col q-mb-sm  q-mr-sm" color="grey-7" bg-color="accent" outlined dense style="width: 250px"/>
            </div>
            <div class="q-mr-xs">
              <q-btn class="rounded-borders q-mr-sm" @click="createDemoTeam()" no-caps label="Create Demo Team" color="primary"/>
            </div>
          </q-item>
          </q-item-section>
        </div>
      </q-tab-panel>
      <!-- Teams Managment -->
      <q-tab-panel :name="`${TEAMS_MANAGEMENT_SECTION}`" 
        class="row justify-center items-center" style="margin-top: 2rem;">
      <div style="width: 80rem;">
        <q-item-section>
          <div class="text-h6 q-mx-xs q-pb-xs">{{ showSection }}</div>
          <div class="text-subtitle2 q-mx-xs q-pt-xs">Switch Team for User:</div>
          <q-item>
            <div class="q-mx-sm">User:
              <q-select v-model="selectedUserTm" :options="users" option-label="email" option-value="id" @update:model-value="setSelectedUserTeam()" 
                color="black" bg-color="accent" outlined dense class="rounded-borders q-my-xs" @filter="onUserfilterFn" use-input input-debounce="0" clearable
              />
            </div>
            <div class="q-mx-sm">Team:
              <q-select v-model="selectedUserTeamTm" :options="selectedUserTeamsTm" :option-label="(team) => team.name ? team.name : `No team name (${team.id})`" option-value="id"
                color="black" bg-color="accent" outlined dense class="rounded-borders q-my-xs"
              />
            </div>
          </q-item>
          <q-item>
            <q-btn class="rounded-borders q-mx-sm"  @click="changeUserTeam(selectedUserTm, selectedUserTeamTm)" no-caps label="Switch" color="primary"/>
            <q-btn class="rounded-borders q-mx-sm"  @click="removeUserMembership(selectedUserTm.id, selectedUserTeamTm.id)" no-caps label="Remove team membership" color="red"/>
          </q-item>
          <q-separator class="q-my-sm"/>
          <div class="text-subtitle2 q-mx-xs q-pt-xs">Add User to a Team:</div>
          <q-item>
            <div class="q-mx-sm">Team:
              <q-select v-model="selectedTeamTm" :options="teams" :option-label="(team) => team.name ? team.name : `No team name (${team.id})`" option-value="id" @update:model-value="setSelectedTeamMembers(selectedTeamTm)" 
                color="black" bg-color="accent" outlined dense class="rounded-borders q-my-xs" clearable
              />
            </div>
          </q-item>
          <template v-if="selectedTeamTm">
              <div class="q-ml-lg q-mt-sm items-center" style="display: flex; justify-content: space-between">
              <div style="flex-grow: 1;">Active Members ({{ selectedTeamMembersTm ? selectedTeamMembersTm.length : 0 }})</div>
              <q-btn class="rounded-borders"  style="margin-left: 10px;" @click="isAddTeamMemberOpen=true" no-caps label="Add" color="primary"/>
              </div>
            <q-item>
              <table v-if="selectedTeamMembersTm?.length" cellspacing="0" cellpadding="0" class="q-px-sm fit">
                <thead>
                  <tr>
                    <th
                      v-for="column in memberColumns"
                      :key="column"
                      class="text-left text-caption text-weight-medium text-grey-5 q-px-xs q-pb-sm"
                      style="border-bottom: 1px solid #EBEEF5;"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="member in selectedTeamMembersTm" :key="member">
                    <tr style="height: 3rem;" @mouseenter="member.isActionButtonVisible=true" @mouseleave="member.isActionButtonVisible=false">
                      <td class="text-body2 text-weight-medium" style="border-bottom: 1px solid #EBEEF5;">
                        <div class="row items-center">
                          <q-avatar size="md" class="bg-grey-5" style="color: white; margin-right: 1rem;">
                            <span>{{ member?.displayName?.[0]?.toUpperCase() }}</span>
                          </q-avatar>
                          <div>{{ member.displayName }}</div>
                        </div>
                      </td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ member.email }}</td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">
                        <div style="width: 100px">
                          <q-select v-model="member.role" :options="userRoleOptions" @update:model-value="changeMemberRole(member, selectedTeamTm.id)" borderless dense></q-select>
                        </div>
                      </td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ formatDate(member.acceptedAt) }}</td>
                      <td class="q-pr-sm" style="width: 2.5rem; border-bottom: 1px solid #EBEEF5;">
                        <div class="column justify-center">
                          <q-btn v-if="member.isActionButtonVisible" size="12px" flat dense round icon="more_vert" />
                            <q-menu class="q-pa-sm shadow-brand2" v-model="member.isActionMenuOpen">
                              <q-list style="min-width: 200px" dense>
                                <q-item clickable v-close-popup class="rounded-borders">
                                  <q-item-section class="text-red-12 text-weight-medium" @click="removeUserMembership(member.userId, selectedTeamTm.id)">Remove Member</q-item-section>
                                </q-item>
                              </q-list>
                            </q-menu>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </q-item>
          </template>
          <q-separator class="q-my-sm"/>
          <div class="text-subtitle2 q-mx-xs q-pt-xs">Statistics:</div>
          <q-item>
            <div class="q-mr-xs">
              <q-btn class="rounded-borders q-mr-sm" @click="getTeamStats()" no-caps label="Show Latest Stats" color="cyan" />
            </div>
          </q-item>
          <q-item class="bg-accent text-black q-py-xs q-my-sm">
            <p>Speical Notes: {{ statNotes }}</p>
            Production Teams:
            <div v-for="(stats, id) in usageStats" :key="id">
              {{ stats }}
            </div>
          </q-item>

        </q-item-section>
      </div>
      </q-tab-panel>
      <!-- Library Managment -->
      <q-tab-panel :name="`${LIBRARY_MANAGEMENT_SECTION}`" 
          class="row justify-center items-center" style="margin-top: 2rem;"
      >
        <div style="width: 80rem;">
          <q-item-section>
            <div class="text-h6 q-mx-xs q-pb-xs">{{ showSection }}</div>
            <!-- Team selection -->
            <div class="text-subtitle2 q-mx-xs q-pt-xs">Team:
              <q-item>
                <q-select v-model="selectedTeamLm" :options="teams" :option-label="(team) => team.name ? team.name : `No team name (${team.id})`" option-value="id" @update:model-value="onSelectTeamChanged()"
                  color="black" bg-color="accent" outlined dense class="rounded-borders q-my-xs"
                />
                <div class="text-subtitle1 q-ml-md q-pt-sm">Environment: <b>{{ selectedEnvironment }} (Remove actions are only allow
                    on Sandbox)</b>
                </div>
              </q-item>
            </div>
            <!-- Recreate default fields -->
<!--             <q-item horizontal class="q-mt-md">
              <div class="text-subtitle1 q-pr-md">Recreate Default Fields:</div>
              <q-btn class="rounded-borders q-mx-sm" @click="recreateDefaultFieldSchemas()" no-caps label="Create"
                color="primary" :disabled="selectedEnvironment !== 'Sandbox'" />
            </q-item> -->
            <!-- Remove Items -->
<!--             <q-item horizontal class="q-mt-md">
              <div class="text-subtitle1 q-pr-md">Remove All Items:</div>
              <q-btn class="rounded-borders q-mx-sm" @click="removeAllTeamItems()" no-caps label="Remove Items" color="red"
                :disabled="selectedEnvironment !== 'Sandbox'" />
              <div class="text-subtitle1 text-yellow q-ml-md"><b><i>WARNING: All items will be removed
                    PERMENANTLY!</i></b>
              </div>
            </q-item> -->
            <!-- Remove a field -->
<!--             <q-item horizontal class="q-mt-md">
              <div class="text-subtitle1 q-pr-md">Remove Field on All Items:</div>
              <select v-model="selectedFieldLm" class="select-box q-ml-sm q-mr-lg">
                <option v-for="option in teamFields" v-bind:key="option.label" :value="option.id">
                  {{ option.label }}
                </option>
              </select>
              <q-btn class="rounded-borders q-mx-sm" @click="removeFieldAndData()" no-caps label="Remove Field+Data"
                color="red" :disabled="selectedEnvironment !== 'Sandbox'" />
              <div class=" text-subtitle1 text-yellow q-ml-md"><b><i>WARNING: Item's field data will be removed
                    PERMENTANTLY!</i></b>
              </div>
            </q-item> -->
            <!-- Remove all lists, columns, items and fields -->
            <q-item horizontal class="q-mt-md">
              <div class="text-subtitle1 q-pr-md">Remove all lists by team:</div>
              <q-btn class="rounded-borders q-mx-sm" @click="removeAllDataByTeam(selectedTeamLm)" no-caps label="Remove All Lists"
                color="red" :disabled="selectedEnvironment !== 'Sandbox'" />
              <div class="text-subtitle1 text-red q-ml-md"><b><i>WARNING: All lists (library, views, price lists), columns, items, fields, invites will be removed
                    PERMENTANTLY!</i></b>
              </div>
            </q-item>
            <!-- Create a team library -->
            <q-item horizontal class="q-mt-md">
              <div class="text-subtitle1 q-pr-md">Create a team library:</div>
              <q-btn class="rounded-borders q-mx-sm" @click="createTeamLibrary(selectedTeamLm)" no-caps label="Create a Team Library"
                color="primary" :disabled="selectedEnvironment !== 'Sandbox'" />
            </q-item>
          </q-item-section>
        </div>
      </q-tab-panel>
      <!-- Developer test -->
      <q-tab-panel :name="`${DEVELOPER_SECTION}`" 
        class="row justify-center items-center" style="margin-top: 2rem;"
      >
        <q-item-section>
          <div class="text-h6 q-mx-xs q-pb-xs">{{ showSection }}</div>
          <div class="text-subtitle2 q-mx-xs q-pt-xs">Development Test:</div>
          <q-item>
            <q-btn class="rounded-borders q-mx-xs" @click="test1Button()" no-caps label="Test 1" color="primary"/>
            <q-btn class="rounded-borders q-mx-xs" @click="test2Button()" no-caps label="Test 2" color="primary"/>
            <q-btn class="rounded-borders q-mx-xs" @click="test3Button()" no-caps label="Test 3" color="primary"/>
            <q-btn class="rounded-borders q-mx-xs" @click="test4Button()" no-caps label="Test 4" color="primary"/>
            <q-btn class="rounded-borders q-mx-xs" @click="test5Button()" no-caps label="Test 5" color="primary"/>
          </q-item>
          <q-item>
            <div class="q-pa-lg q-gutter-md">
              <b>result 1</b>
              <br /><br />
              {{ result1 }}
              <br /><br />
              <b>result 2</b>
              <br /><br />
              {{ result2 }}
              <br /><br />
              <b>result 3</b>
              <br /><br />
              {{ result3 }}
              <br /><br />
              <b>result 4</b>
              <br /><br />
              {{ result4 }}
              <br /><br />
              <b>result 5</b>
              <br /><br />
              {{ result5 }}
              <br /><br />
            </div>
          </q-item>
        </q-item-section>
      </q-tab-panel>
    </q-tab-panels>
  </main-layout>
  <q-dialog v-model="isAddTeamMemberOpen">
    <q-card style="border-radius: 20px; width: 200rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Select user to add to Team</div>
      </q-card-section>
      <form @submit.prevent="onAddTeamMemberFormSubmit(selectedUserTm, selectedTeamTm, teamMemberRole)">
        <q-card-section class="q-pt-none">
          <div class="items-center" style="display: flex; justify-content: space-between">
            <q-select v-model="selectedUserTm" :options="filteredUsers" option-label="email" option-value="id" 
              color="black" bg-color="accent" outlined dense class="rounded-borders" style="flex-grow: 1;"
              @filter="onUserfilterFn" use-input input-debounce="0" clearable
            />
            <q-select 
              v-model="teamMemberRole" 
              :options="userRoleOptions" 
              style="width: 100px;"  
              class="q-mx-sm rounded-borders" 
              color="black" 
              bg-color="accent"
              outlined
              label="Role"
              dense
              >
            </q-select>
          </div>
          <q-card-actions align="right" class="q-mb-sm">
            <q-btn
              no-caps
              unelevated
              type="submit"
              class="q-ml-sm q-mt-sm rounded-borders"
              color="primary"
              text-color="white"
              label="Add User"
              style="width: 6.3rem;"
              v-close-popup
            />
          </q-card-actions>
        </q-card-section>


      </form>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.select-box {
  width: 16rem;
  height: 2rem;

}

.select-label {
  width: 14rem;
}

.section-button {
  width: 13rem;
  height: 2rem;
  font-size: 100%;
  background-color: rgb(220, 220, 220);
  color: grey;
}


.action-button {
  width: 9rem;
  height: 2rem;
  font-size: 90%;
}


.my-card {
  background: grey;
  width: 85rem;
}

.input-box {
  width: 16rem;
  height: 2rem;
  font-size: 90%;
}
</style>

<script setup>

// Dependencies
import { onMounted, onBeforeMount, onBeforeUnmount, ref } from 'vue'

//utils
import { formatDate } from '../utils/format.mjs'

// Layouts
import MainLayout from '../layouts/MainLayout.vue'

// Actions
import { getCurrentUser } from '@/actions/current-user.mjs'
import {
  TEAMS_MANAGEMENT_SECTION, LIBRARY_MANAGEMENT_SECTION, DEVELOPER_SECTION, DEMO_SECTION,
  showSection, selectedUserTm, selectedUserTeamTm, selectedTeamTm, selectedUserTeamsTm, selectedTeamLm, selectedEnvironment, selectedFieldLm,
  users, teams, teamFields, loadAdminRefs, setSelectedUserTeam, setTeamEnvironment, changeUserTeam, removeUserMembership,createDemoTeam,
  newTeamName, removeAllTeamItems, removeFieldAndData, recreateDefaultFieldSchemas, setTeamFields, removeAllDataByTeam, createTeamLibrary,
  newUserEmail, setSelectedTeamMembers, selectedTeamMembersTm, addUserToTeam, changeMemberRole, demoTeamName,
} from '@/superadmin/super-admin.mjs'

import { getUsageStats, statNotes, usageStats } from './usage-stats.mjs'

import { TeamListener } from '../team/events/TeamListener.mjs'
import { getCurrentTeam, memberColumns } from '../actions/current-team.mjs'
import { currentUser } from '../actions/current-user.mjs'
import { UserListener } from '../user/events/UserListener.mjs'
import { getTeamsByUser, getCurrentTeamByUser } from '../team/actions/team-actions.mjs'

import { USER_ROLES, DEFAULT_USER_ROLE } from '../permissions/constants/roles.mjs'


//****//
// Developer Area
import {
  testButton1, testButton2, testButton3, testButton4, testButton5,
  result1, result2, result3, result4, result5
} from './developer-area.mjs'

//****//

const userRoleOptions = ref(USER_ROLES)
const isAddTeamMemberOpen = ref(false)
const filteredUsers = ref(users.value)
const teamMemberRole = ref(DEFAULT_USER_ROLE)

// import for fishTest
async function showTeamsManagmenet() {
  await init()
  showSection.value = TEAMS_MANAGEMENT_SECTION
}

async function showLibraryManagmenet() {
  await init()
  showSection.value = LIBRARY_MANAGEMENT_SECTION
}

async function showDeveloperArea() {
  showSection.value = DEVELOPER_SECTION
}


async function onSelectTeamChanged() {
  setTeamEnvironment()
  await setTeamFields()
}

async function getTeamStats() {
  await getUsageStats()

}

async function init() {
  await loadAdminRefs()
/*   const currentUser = await getCurrentUser()
  selectedUserTm.value = currentUser
  selectedUserTeamsTm.value = await getTeamsByUser(currentUser)
                             .then(teamEntities => teamEntities.map(teamEntity => teamEntity.toObject()))
  selectedUserTeamTm.value = await getCurrentTeamByUser(selectedUserTm.value)
                        .then(teamEntity => teamEntity.toObject()) 
  selectedTeamLm.value = selectedUserTeamTm.value
  setTeamEnvironment()
  await setTeamFields() */
}

// Setup
onMounted(async () => {
  console.log('onMounted called')
  const teamEntity = getCurrentTeam()
  const teamListener = new TeamListener({ teamEntity })
  teamListener.start()
/*   const teamMemberListener = new UserListener({ teamEntity })
  teamMemberListener.start() */
  await init()
})


//*** DEVELOPER AREA ***/

onBeforeMount(async () => {
  //await testOnBeforeMount()
})

onBeforeUnmount(async () => {
  //await testOnBeforeUnmount()
})

async function test1Button() {
  console.log('calling Test 1 Button!!!')
  await testButton1()
}

async function test2Button() {
  console.log('calling Test 2 Button!!!')
  await testButton2()
}

async function test3Button() {
  await testButton3()
}

async function test4Button() {
  await testButton4()
}

async function test5Button() {
  await testButton5()
}

function onUserfilterFn(val, update, abort) {
  if (val.length < 2) {
    abort()
    return
  }

  update(() => {
    const needle = val.toLowerCase()
    filteredUsers.value = users.value.filter(user => user.email.toLowerCase().indexOf(needle) > -1)
  })
}

function onAddTeamMemberFormSubmit(user, team, role) {
  const isMember = team.members.some(member => member.email === user.email)
  if (isMember) return
  addUserToTeam({
    userId: user.id,
    teamId: team.id,
    email: user.email,
    displayName: user.displayName,
    role: role || DEFAULT_USER_ROLE
  })
  teamMemberRole.value = null
  selectedUserTm.value = null
}


</script>
