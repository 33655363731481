/*!
 * Sheet actions
 */

// Components
import SheetCellRenderer            from '../../components/sheet/SheetCellRenderer.vue'
import SheetCellEditor              from '../../components/sheet/SheetCellEditor.vue'
import SheetHeaderCell              from '../../components/sheet/SheetHeaderCell.vue'
import SheetHeaderNewColCell        from '../../components/sheet/SheetHeaderNewColCell.vue'
import SheetNewCellRenderer         from '../../components/sheet/SheetNewCellRenderer.vue'
import SheetHeaderStatusColRenderer from '../../components/sheet/SheetHeaderStatusColRenderer.vue'
import SheetStatusCellRenderer      from '../../components/sheet/SheetStatusCellRenderer.vue'

// Utils
import { isEqual, throwError } from '../../utils'

// Constants
import { CHAR_EMPTY } from '../../constants'
import { 
  FIELD_KEY_NEW_COLUMN, FIELD_KEY_FIRST_COLUMN,
 } from '../../constants/field.mjs'
 import { UNDEFINED } from '../../constants/types.mjs'

/***NEW***/
import { LIBRARY_LIST, VIEW_LIST } from '../../list-core/constants/list-constants.mjs'

import { compileItem } from '../../item/utils/compile-item.mjs'


// Setup - add list stuff here
const defaultColDef = {
  flex:                    0,
  minWidth:                80,
  width:                   200,
  maxWidth:                1900,
  resizable:               true,
  sortable:                true,
  editable:                false,
  equals:                  isItemEqual,
  suppressMovable:         false,
  suppressNavigable:       false,
  suppressKeyboardEvent:   false,
  suppressPaste:           false,
  cellEditorPopup:         true,
  cellEditorPopupPosition: 'over',
  enableCellChangeFlash:   true,
  getQuickFilterText:      getQuickFilterText,
  valueGetter:             onGetCellValue,
  valueSetter:             onSetCellValue,
  cellEditor:              SheetCellEditor,
  cellRenderer:            SheetCellRenderer,
}

// Defaults for the first column
const firstColumnColDef = {
  flex:                    0,
  width:                   100,
  resizable:               false,
  sortable:                false,
  field:                   FIELD_KEY_FIRST_COLUMN,
  editable:                false,
  equals:                  () => false, // re-render on change
  lockVisible:             true,
  pinned:                  'left',
  lockPosition:            'left',
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection:       true,
  suppressMovable:         true,
  suppressNavigable:       true,
  suppressKeyboardEvent:   true,
  suppressPaste:           true,
  headerComponent:         SheetHeaderStatusColRenderer,
  cellRenderer:            SheetStatusCellRenderer,
  enableCellChangeFlash:   false
}

// Defaults for the + New Column button
const newColumnColDef = {
  flex:                  0,
  width:                 800,
  resizable:             false,
  sortable:              false,
  field:                 FIELD_KEY_NEW_COLUMN,
  editable:              false,
  lockVisible:           true,
  lockPosition:          'right',
  suppressMovable:       true,
  suppressNavigable:     true,
  suppressKeyboardEvent: true,
  suppressPaste:         true,
  headerComponent:       SheetHeaderNewColCell,
  cellRenderer:          SheetNewCellRenderer,
}

export const libraryFirstColumn = () => {
  return {
    ...firstColumnColDef,
    cellRenderer: SheetStatusCellRenderer
  }
}

export const ViewFirstColumn = () => {
  return {
    ...firstColumnColDef,
    cellRenderer: SheetStatusCellRenderer
  }
}

function createFirstColumnColDef(type, userRole) {
  //TODO: Price list can vary based on userRole
  const showStatus = (type === LIBRARY_LIST || type ===VIEW_LIST)
  return {
    ...firstColumnColDef,
    //checkboxSelection:       type === LIBRARY_LIST,
    cellRenderer:            showStatus ? SheetStatusCellRenderer : null,
  }
}

function createNewColumnColDef(type, userRole) {
  //TODO: Price list can vary based on userRole
  return {
    ...newColumnColDef,
  }
}

/**
 * Check if item is equal
 */
 function isItemEqual(a, b) {
  return isEqual(a?.formattedValue, b?.formattedValue)
    && isEqual(a?.inputValue,       b?.inputValue)
    && isEqual(a?.value,            b?.value)
    && isEqual(a?.error,            b?.error)
    && isEqual(a?.warning,          b?.warning)
}


/**
 * On get cell value
 */
function onGetCellValue(params) {
  if (!params?.colDef?.field) {
    return throwError('No field found')
  }
  return params.data?.fields?.[params.colDef.field]
    || undefined
}

/**
 * On set cell value
 */
function onSetCellValue(params) {
  const formatInput = params.colDef.headerComponentParams.data.formatInput

  const fieldKey = params.colDef.field
  const newValue = formatInput ? formatInput(params.newValue) : params.newValue
  
  // ItemEntityChanges
  const itemEntity =  params.data
  const fields =  itemEntity.fields
  const oldValue = fields[fieldKey]?.inputValue
  const isChanged = !isEqual(newValue, oldValue) 
  const fieldSchemas = params.colDef.headerComponentParams.data.fieldSchemas
  if (!isChanged) {
    return isChanged
  }
  itemEntity.fields[fieldKey].inputValue = newValue
  itemEntity.setCreated().setUpdated()
  compileItem({ item: itemEntity, keys: [fieldKey], fieldSchemas })
  itemEntity.updatedByEventId = UNDEFINED
  return isChanged
}

/**
 * This is used to get the value from the object when doing a search
 */
function getQuickFilterText(params) {
  return params.value?.formattedValue || CHAR_EMPTY
}

/**
 * Get the row id from our structure
 */
function getRowId(row) {
  return row.data.id
}

// Exports
export {
  defaultColDef, createNewColumnColDef, createFirstColumnColDef,
  onGetCellValue, onSetCellValue,
  getQuickFilterText,
  getRowId
}
