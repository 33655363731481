// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { getTeamSession } from '../../session/team-session.mjs'
// local events
import {
  EVENT_LIST_REMOVE_SHARING_USER,
  EVENT_LIST_REMOVE_SHARING_USER_LOCAL,
  EVENT_LIST_REMOVE_SHARING_USER_REMOTE
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'


import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM, SCOPE_GLOBAL } from '../../events/external-events.mjs'

// entities
import { ListEntity } from '../../entity/ListEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

//actions 
import { getListAndColumns } from '../db/get-list-and-columns.mjs'

//
import { currentList } from '../../ui/ui-states.mjs'
import router from '../../router'


export const addEventListRemoveSharingUser = (controller, subscriptions) => {
  addEventListener(
    EVENT_LIST_REMOVE_SHARING_USER_LOCAL,
    (event) => removeListSharingUserEvent(event),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_LIST_REMOVE_SHARING_USER,
      removeListSharingUserRemoteHandler,
      { scope: SCOPE_GLOBAL }
    )
  )
  // remote
  addEventListener(
    EVENT_LIST_REMOVE_SHARING_USER_REMOTE,
    (event) => removeListSharingUserRemoteEvent(event),
    { signal: controller.signal }
  )
  return

}



//#region //--- Dlete list ---//
const removeListSharingUserEvent = async (event, {
  scope = SCOPE_GLOBAL
} = {}) => {
  const { list, userEmail } = event.detail
  const team = getTeamSession()

  const listEntity = !list.isListEntity
    ? (new ListEntity(list))
    : list

  const externalEvent = createExternalEvent({
    action: EVENT_LIST_REMOVE_SHARING_USER,
    scope,
    teamId: team.id,
    payload: {
      listId: list.id,
      userEmail
    }
  })
  listEntity.updatedByEventId = externalEvent.id
  listEntity.removeSharedWith(userEmail)
  listEntity.setUpdated()
  await ListEntity.upsert(listEntity)
  if (currentList.value.id === list.id) {
    router.replace('/')
  }
  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)


}




/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
const removeListSharingUserRemoteEvent = async (event) => {
  const { list, userEmail } = event.detail

  if (currentList.value.id !== list.id) {
    return
  }
  currentList.value.removeSharedWith(userEmail)
  router.replace('/')
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
const removeListSharingUserRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { listId, userEmail } = payload
  const { list } = await getListAndColumns(listId)
  dispatch(EVENT_LIST_REMOVE_SHARING_USER_REMOTE, { list, userEmail })

}
