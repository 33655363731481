// Dependencies
import { required } from '../utils/required.mjs'
import { CHAR_EMPTY } from '../constants/characters.mjs'
import { REGEX_URL } from '../constants/regex.mjs'
import { parseCurrency } from '../utils/parse.mjs'
import { isNumber, isEmpty, isString } from './is.mjs'

/**
 * Format percentage
 */
function formatPercentage(
  value         = required('value'),
  decimalPlaces = required('decimalPlaces'),
  locale        = required('locale')
) { 
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(value)
}

/**
 * Format currency
 */
function formatCurrency(
  value           = required('value'),
  decimalPlaces   = required('decimalPlaces'),
  locale          = required('locale'),
  currencyIsoCode = required('currencyIsoCode')
) {
  const cleanValue = parseCurrency(value)
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyIsoCode,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(cleanValue)
}

/**
 * Format number
 */
function formatNumber(
  value         = required('value'),
  decimalPlaces = required('decimalPlaces'),
  locale        = required('locale'),
) {
  const cleanValue = parseFloat(value)
  return Intl.NumberFormat(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(cleanValue)
}

/**
 * Format date
 */
function formatDate(
  value = required('value')
) {
  if (isEmpty(value)) {
    return CHAR_EMPTY
  }
  return (new Date(value))
    .toLocaleDateString()
}

/**
 * Format HTML
 */
function formatHtml(
  value = required('value')
) {
  if (!isString(value) && !isNumber(value)) {
    return CHAR_EMPTY
  }
  return (isNumber(value)? String(value) : value)
    // this is a fast way to get safe html
    .split('&').join('&amp;')
    .split('<').join('&lt;')
    .split('>').join('&gt;')
    // find links and replace with markup
    .replace(REGEX_URL, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`
    })
}

// Exports
export { formatPercentage, formatCurrency, formatNumber, formatDate, formatHtml }
