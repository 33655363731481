
//utils
import { getUpdateInterval } from '../../utils/update-interval.mjs'

// events
import {
  EVENT_SET_SESSION_UPDATED_AT_INTERVAL
} from '../../events/constants/event-types.mjs'

// sessions
import { getObjectSession, setObjectSession } from '../../session/object-session.mjs'

export const addEventsSetUpdatedAtInterval = (controller) => {
  addEventListener(
    EVENT_SET_SESSION_UPDATED_AT_INTERVAL,
    (event) => setUpdatedAtIntervalEvent(event),
    { signal: controller.signal }
  )
  return
}

const setUpdatedAtIntervalEvent = (event) => {
  const { id } = event.detail
  const session = getObjectSession(id)
  getObjectSession(id).then((session) => {
    session.data.updatedAtInterval = getUpdateInterval()
    setObjectSession(session)
  })
}