// utils
import { required } from '../../utils/required.mjs'
import { getTeamSession } from '../../session/team-session.mjs'
import { isEmptyRow } from '../import/import-utils.mjs'

// constants
import { IMPORT_BATCH_SIZE } from '../../constants/batch-sizes.mjs'

import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'

// actions
import { createCsvItem } from '../import/import-utils.mjs'
import { getListCsvData } from '../../storage/list-file-storage.mjs'

export const setCsvGridItems = async (
  list = required('list'),
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  const team = getTeamSession()

  // set CSV data
  if (!list.csvFilePath) {
    console.log('no csv file path')
    return
  }

  // replace new column 
  const fieldSchemas = columnManager.getImportFieldSchemas()
  itemManager.clearItems()
  const csv = await getListCsvData(list.csvFilePath)
  const [importLabels, ...importRows] = csv.data.filter(row => !isEmptyRow(row))
  let batch = []
  let cnt = 0
  const formatCompileInputMap = columnManager.getFormatCompileInputMap()
  for (const row of importRows) {
    const item = createCsvItem(team.id, row, importLabels, fieldSchemas, formatCompileInputMap)
    batch.push(item)
    cnt++
    if (cnt === IMPORT_BATCH_SIZE) {
      itemManager.upsertItemsGridOnly(batch, fieldSchemas)
      batch = []
      cnt = 0
    }
  }
  if (batch.length > 0) {
    itemManager.upsertItemsGridOnly(batch, fieldSchemas)
  }
}