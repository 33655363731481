

// utils
import { required } from '../../utils/required.mjs'
import { throwError } from '../../utils/errors.mjs'
import { inviteListByEmailList } from '../../email-invites/actions/invites.mjs'

import { ListEntity } from '../../entity/ListEntity.mjs'





//#endregion

//#region //--- list sharing ---//

export const updateSharedWith = async ({
  list = required('list'),
  listId = required('listId'),
  emails = required('eamils'),
  message
}) => {
  const listEntity = list.isListEntity ? list : (new ListEntity(list))//await ListEntity.fetch(listId)

  //const listEntity = await ListEntity.fetch(listId)
  if (!listEntity) {
    throwError('List not found')
  }
  const newEmails = emails.filter(email => !listEntity.sharedWith.includes(email))
  listEntity.sharedWith = [...emails]
  ListEntity.upsert(listEntity)
  if (newEmails.length > 0) {
    inviteListByEmailList(listEntity.id, newEmails, message)
  }
}
