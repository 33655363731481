<template>
  <q-dialog v-model="isModifyPriceListOpen">
    <q-card style="border-radius: 20px; width: 27rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Edit Price List</div>
      </q-card-section>

      <form @submit.prevent="onListSave">
        <q-card-section class="q-pt-none">
          <q-input v-model="name"
            bg-color="accent" outlined dense
            :autofocus=true>
            <template v-slot:append>
            <q-icon name="close" @click="name = ''" class="cursor-pointer"></q-icon>
            </template>
          </q-input>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-weight-medium q-mt-md">Export Permission</div>
          <q-select 
            v-model="listExportPermission" 
            color="black" 
            bg-color="accent" 
            outlined
            class="rounded-borders q-my-sm" 
            menu-anchor="bottom left" 
            label="Who can export this price list"
            :options="listExportPermissions"
            dense
            >
            <template v-slot:prepend>
              <q-icon name="lock_open"/>
            </template>
          </q-select>
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <!-- Cancel Button -->
          <q-btn
            no-caps unelevated
            class="rounded-borders"
            color="accent"
            text-color="dark"
            label="Cancel"
            style="width: 6.3rem;" v-close-popup @click="onCancelList()"
          />

          <!-- Done Button-->
          <q-btn
            :disable="(name === '' || listExportPermission === null)"
            no-caps unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Done"
            style="width: 6.3rem;" v-close-popup
          />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>
</template>

<style scoped>
textarea {
  resize: none !important;
}
</style>

<script setup>

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'


// events
import { dispatch } from '../../events/dispatch.mjs'

import { 
  EVENT_OPEN_MODIFY_PRICE_LIST,
  EVENT_PRICE_LIST_MODIFY_LOCAL
} from '../../events/constants/event-types.mjs'

// actions
import { getListAndColumns } from '../../list-core/db/get-list-and-columns.mjs'

// Refs
const name = ref('')
const isModifyPriceListOpen = ref(false)

const listExportPermissions = [
{
    label: 'Anyone',
    value: 'Anyone'
  },
  {
    label: 'Team',
    value: 'Team'
  }
]
const listExportPermission = ref(null)

let currentList = null
let eventController = null
let currentListId = null

onMounted(() => {
  if (!eventController) {
    eventController = new AbortController()
    addEventListener(EVENT_OPEN_MODIFY_PRICE_LIST, onOpenModifyPriceListEvent, { signal: eventController.signal })
  }
})

onUnmounted(() => {
  isModifyPriceListOpen.value = false
  eventController?.abort()
})

async function onOpenModifyPriceListEvent(event) {
  const { listId } = event.detail
  currentListId = listId
  await initList()
  isModifyPriceListOpen.value = true
}



async function initList() {
  const { list } = await getListAndColumns(currentListId)
  currentList = list
  const arrIndex = listExportPermissions.findIndex(permission => permission.value == currentList.canExport)
  listExportPermission.value = arrIndex !== -1 ? listExportPermissions[arrIndex] : null
  name.value = list.name
  return
}

/**
 * save list name and list canExport property 
 */
async function onListSave() {
  currentList.name = name.value
  currentList.canExport = listExportPermission.value.value
  return dispatch(EVENT_PRICE_LIST_MODIFY_LOCAL, { list: currentList })

}

function onCancelList() {
  initList()
  isModifyPriceListOpen.value = false
  return
}

</script>
