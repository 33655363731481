/*!
 * Number field type
 */

import { FieldType } from './FieldType.mjs'
import { isEmpty } from '../utils'

//Constants
import {
  KEY_IS_EMPTY, KEY_EQUAL_TO, KEY_NOT_EQUAL_TO, KEY_GREATER_THAN, 
  KEY_GREATER_THAN_EQUAL_TO, KEY_LESS_THAN, KEY_LESS_THAN_EQUAL_TO,
} from '../constants'

export class NumberType extends FieldType {
  constructor() {
    super()
  }

  static compare(aValue, condition, bValue) {
    if (condition === KEY_IS_EMPTY) {
      return isEmpty(aValue)
    }
    // Return false if it's not a number
    if (isNaN(aValue) || isNaN(bValue)) {
      return false
    }
    const a = Number(aValue)
    const b = Number(bValue)
    return condition === KEY_EQUAL_TO              ? a === b
         : condition === KEY_NOT_EQUAL_TO          ? a !== b
         : condition === KEY_GREATER_THAN          ? a > b
         : condition === KEY_GREATER_THAN_EQUAL_TO ? a >= b
         : condition === KEY_LESS_THAN             ? a < b
         : condition === KEY_LESS_THAN_EQUAL_TO    ? a <= b
         : null 
  }
}