//utils
import { required } from '../../../utils/required.mjs'

// salesforce integration rpc
import { getSalesforceIntegrationRpc } from '../rpcs/salesforce-rpcs.mjs'
import { ACTION_TEST_CONNECTION } from '../constants/action-types.mjs'
import { testResponse } from '../states/salesforce-ui-states.mjs'

async function testConnection({
  username = required('username'),
  password = required('password'),
  securityToken = required('securityToken'),
  instanceUrl = required('instanceUrl'),
}) {
  testResponse.value = ''
  const sfdcIntegration = getSalesforceIntegrationRpc()
  const response = await sfdcIntegration({
    action: ACTION_TEST_CONNECTION,
    payload: {
      username,
      password,
      securityToken,
      instanceUrl
    }
  })
  if (response.data.success) {
    testResponse.value = response.data.message
  } else {
    testResponse.value = response.data.errors
  }
}

export { testConnection }