// constants
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// events
import {
  EVENT_LOG_FILE_ITEMS_ENABLE_LOCAL,
  EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL,
} from '../../events/constants/event-types.mjs'

import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

// log file states

//#region //--- overlay item log files ---//

export const addEventsLogFileOverlay = (controller, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_LOG_FILE_ITEMS_ENABLE_LOCAL,
    (event) => enableLogFileOverlayEvent(event, itemManager, { scope }),
    { signal: controller.signal }
  )
  
  addEventListener(
    EVENT_LOG_FILE_ITEMS_DISABLE_LOCAL,
    (event) => disableLogFileOverlayEvent(event, itemManager, { scope }),
    { signal: controller.signal }
  )
  return
}

export const enableLogFileOverlayEvent = (event, itemManager) => {
  const { logdata } = event.detail
  const logStatuses = {}
  itemManager.clearItemsLogStatus()

  // handle not per item error
  if (typeof(logdata) === 'string') {
    itemManager.setSelectedItemsLogStatus('error')
    return
  }

  for (const data of logdata) {
    logStatuses[data.id] = {
      status: data.hasErrors ? 'error' : 'ok'
    }
  }
  itemManager.setItemsLogStatus(logStatuses)
  return
}


export const disableLogFileOverlayEvent = (event, itemManager) => {
  itemManager.clearItemsLogStatus()
  return
}