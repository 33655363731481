/*!
 * Field schema db functions
 */
import { throwError } from '@/utils/errors.mjs'
import { FieldSchemaEntity } from '../../entity'
import { FieldSchemaDb } from './FieldSchemaDb.mjs'
import { FIELD_TYPES } from '../field-schema-constants.mjs'
const db = new FieldSchemaDb()


async function getFieldSchemaEntitiesByTeamId(teamId) {
  const fieldSchemas = await db.getAllByTeamId(teamId)
  return fieldSchemas
    .filter(fieldSchema => FIELD_TYPES.includes(fieldSchema.type))
    .map(fieldSchema => new FieldSchemaEntity(fieldSchema))
}

function saveFieldSchemaEntities(fieldSchemaEntities) {
  if (!Array.isArray(fieldSchemaEntities)) {
    throwError('fieldSchemaEntities must be an array')
  }
  if (fieldSchemaEntities.length === 0) {
    return
  }
  const saveObjects = fieldSchemaEntities
    .filter(fieldSchema => FIELD_TYPES.includes(fieldSchema.type))
    .map(fieldSchemaEntity => db.createDocObject(fieldSchemaEntity))

  return db.upsertBatch(saveObjects)
}

export {
  getFieldSchemaEntitiesByTeamId, saveFieldSchemaEntities
}