/*!
 * Grid manager entity
 * 
 * local methods:
 *    the change originate locally. 
 *    update document timestamps, userids and update datastore
 * 
 * Remote methods:
 *    the change originate remotely. 
 *    only update grid data or state.
 *    no changes to document timestamps, userids or datastore
 * 
 */

/*
*

*/
import { triggerRef } from 'vue'

import { getUserSession } from '../../session/user-session.mjs'
// Utils
import { throwError } from '../../utils'
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { makeFieldComparator } from '../../field-schema/utils/make-field-comparator.mjs'


// constants
import { CHAR_EMPTY } from '../../constants/characters.mjs'

// col defs
import { defaultColDef } from '../../ui/components/sheet.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { FIELD_TYPES } from '../../field-schema/field-schema-constants.mjs'
import { createColDefFromColumn } from '../../column/column-ui-utils.mjs'
import { makeFormatInput } from '../../field-schema/utils/make-field-format-input.mjs'
import { makeFormatCompileInput } from '../../field-schema/utils/make-field-format-compile-input.mjs'
// roles
import { getUserListRole } from '../../list-core/permissions/list-permissions.mjs'

import SheetHeaderCell from '../../components/sheet/SheetHeaderCell.vue'


// list constants
import { 
  ROLE_NONE,
  COLUMN_SORT_ASC, COLUMN_SORT_DESC,
 } from '../../list-core/constants/list-constants.mjs'
import { isLibraryList, isViewList } from '@/list-core/utils/is-list-type.mjs'

// ui states
import { filteredColumns, currentList, isStaticList } from '../../ui/ui-states.mjs'
import { setListUiActionPermissionStates } from '../../list-core/permissions/list-ui-action-permissions.mjs'


const DEFAULT_HEADER_HEIGHT = 48
const IMPORT_HEADER_HEIGHT = 96

export class GridColumnManager {
  #grid
  #gridApi
  #columnApi
  #list
  #firstColumnDef
  #lastColumnDef
  #columnDefs
  #columnSet
  #viewColumns  // array of column ids available for view
  #userColumnSettings // a subset of columnSet that contains personal column settings
  #columnFieldSchemaMap
  #fieldSchemaSet
  #headerComponent
  #userRole
  #isImportMode = false
  #importColumnSet
  #libraryColumnSet
  #filterQuery
  #formatInputMap
  #formatCompileInputMap
  constructor({
    grid,
    list,
    columnDefs = null,
    firstColumnDef = null,
    lastColumnDef = null,
    columnSet,
    fieldSchemaSet,
    headerComponent,
    userRole = ROLE_NONE,
    importColumnSet,
    isImportMode = false,
    libraryColumnSet = null,
  } = {}) {
    this.isImportMode = isImportMode
    grid && this.setGrid(grid)
    this.list = list
    this.columnDefs = columnDefs
    this.firstColumnDef = firstColumnDef
    this.lastColumnDef = lastColumnDef
    this.userRole = userRole  
    // do not trigger setters on construct
    this.#columnSet = columnSet
    this.#fieldSchemaSet = fieldSchemaSet
    this.#importColumnSet = importColumnSet
    this.#libraryColumnSet = libraryColumnSet
    this.#filterQuery = CHAR_EMPTY
  }

  //#region //--- Property Getters ---//
  get gridApi() { return this.#gridApi }
  get columnApi() { return this.#columnApi }
  get list() { return this.#list }
  get columnDefs() { return this.#columnDefs }
  get firstColumnDef() { return this.#firstColumnDef }
  get lastColumnDef() { return this.#lastColumnDef }
  get columnSet() { return this.#columnSet }
  get fieldSchemaSet() { return this.#fieldSchemaSet }
  get userRole() { return this.#userRole }
  get importColumnSet() { return this.#importColumnSet }
  get isImportMode() { return this.#isImportMode }
  get libraryColumnSet() { return this.#libraryColumnSet }
  //#endregion

  //#region //--- Property Setters ---//
  set list(obj) { 
    this.#list = obj 
    this.#headerComponent = SheetHeaderCell
    currentList.value = this.#list
    isStaticList.value = this.#list.isStatic
  }
  set columnDefs(obj) { this.#columnDefs = obj }
  set firstColumnDef(obj) { this.#firstColumnDef = obj }
  set lastColumnDef(obj) { this.#lastColumnDef = obj }
  set columnSet(obj) {
    {
      this.#columnSet = obj
      this.#setColumnFieldSchemaMap()
    }
  }
  set fieldSchemaSet(obj) {
    {
      this.#fieldSchemaSet = obj
      this.#setColumnFieldSchemaMap()
    }
  }
  set userRole(str) { this.#userRole = str }
  set importColumnSet(obj) { this.#importColumnSet = obj }
  set isImportMode(bool) { this.#isImportMode = !!bool }
  //#endregion


  //#region //--- Private setter methods ---//
  #setColumnFieldSchemaMap() {
    this.#columnFieldSchemaMap = new Map()
    this.#formatInputMap = new Map()
    this.#formatCompileInputMap = new Map()
    if (!this.#columnSet || !this.#fieldSchemaSet) {
      console.error('No column or fieldSchema found')
      return
    }
    for (const col of this.#columnSet.columns) {
      const fs = this.#fieldSchemaSet.fieldSchemas
        .filter(fs => !fs.isDeleted && FIELD_TYPES.includes(fs.type))
        .find(fs => fs.id === col.fieldSchemaId)
      if (!fs) {
        continue
      }
      this.#columnFieldSchemaMap.set(col.id, fs)
      this.#formatInputMap.set(fs.type, makeFormatInput(fs.type))
      this.#formatCompileInputMap.set(fs.type, makeFormatCompileInput(fs.type))

    }
    this.#createColumnDefs()
  }

  #createColumnDefs() {
    const colDefs = []
    for (const column of this.#columnSet.columns) {
      const fieldSchema = this.#columnFieldSchemaMap.get(column.id)
      if (!fieldSchema || column.isDeleted || fieldSchema.isDeleted || !FIELD_TYPES.includes(fieldSchema.type)) {
        continue
      }
      const importColumn = this.#importColumnSet?.columns?.find(col => col.importColumnId === column.id)
      colDefs.push({
        ...createColDefFromColumn(column),
        headerName: fieldSchema?.label,
        field: fieldSchema?.key,
        comparator: makeFieldComparator(fieldSchema.type),
        editable: !this.#list.isStatic,
        headerComponentParams: {
          data: {
            listId: this.#list.id,
            listType: this.#list.type,
            teamId: this.#list.teamId,
            userRole: this.#userRole,
            column,
            fieldSchema,
            fieldSchemas: this.#fieldSchemaSet.fieldSchemas,
            importColumn: importColumn,
            importColumnSet: this.#importColumnSet,
            isLibraryList: isLibraryList(this.#list),
            isViewList: isViewList(this.#list),
            formatInput: this.#formatInputMap.get(fieldSchema.type),
            formatCompileInput: this.#formatCompileInputMap.get(fieldSchema.type)
          }
        }
      })
    }
    this.#columnDefs = colDefs
  }
  //#endregion

  //#region //--- Grid methods ---//
  setGrid(grid) {
    this.#grid = grid
    this.#gridApi = this.#grid.api
    this.#columnApi = this.#grid.columnApi
  }

  start() {
    const user = getUserSession()
    this.#userRole = getUserListRole(user, this.list)
    const headerHeight = this.#isImportMode ? IMPORT_HEADER_HEIGHT : DEFAULT_HEADER_HEIGHT
    this.#gridApi.setHeaderHeight(headerHeight)
    this.#gridApi.setDefaultColDef({
      ...defaultColDef,
      headerComponent: this.#headerComponent
    })
    this.#setColumnFieldSchemaMap()
    this.#setColumnDefs()
    this.setFilteredColumns()
  }

  terminate() {
    this.#grid = undefined
    this.#gridApi = undefined
    this.#columnApi = undefined
    this.#list = undefined
  }

  refreshColumns() {
    currentList.value = this.#list
    isStaticList.value = this.#list.isStatic
    this.#setColumnFieldSchemaMap()
    this.#setColumnDefs()
    this.refreshFilteredColumns()
    triggerRef(currentList)
    triggerRef(isStaticList)
  }

  //#endregion

  //#region //--- UI states ---//
  setFilteredColumns(query) {
    this.#filterQuery = query
    const columnStateList = []
    for (const col of this.#columnSet.columns) {
      if (col.isDeleted) {
        continue
      }
      const fieldSchema = this.#columnFieldSchemaMap.get(col.id)
      if (!fieldSchema || fieldSchema.isDeleted || !FIELD_TYPES.includes(fieldSchema.type)) {
        continue
      }
      if (query && !fieldSchema?.label?.toLowerCase().includes(query.toLowerCase())) {
        continue
      }
      columnStateList.push({
        ...col.toObject(),
        label: fieldSchema.label
      })
    }

    filteredColumns.value = columnStateList
      .sort((a, b) => a.label.localeCompare(b.label))
    triggerRef(filteredColumns)
  }

  refreshFilteredColumns() {
    return this.setFilteredColumns(this.#filterQuery)
  }

  setListUIActionPermissions() {
    setListUiActionPermissionStates(this.#list)
  } 


  //#endregion

  //#region //--- Private columns methods ---//
  #setColumnDefs() {
    let colDefs = [
      this.#firstColumnDef,
      ...this.#columnDefs,
    ]
    if (this.#lastColumnDef) {
      colDefs = [...colDefs, this.#lastColumnDef]
    }
    this.#gridApi.setColumnDefs(colDefs)
  
  }

  setColumnEvent(columnEntity, event) {
    if (!event) {
      return
    }
    columnEntity.updatedByEventId = event.id
    this.#columnSet.updatedByEventId = event.id
    event.payload = {
      ...event.payload,
      columnSetId: this.#columnSet.id
    }
  }

  //#region //--- Hide / Unhide ---//  
  /**
   * Set columns hide / unhide remote
   * @param {*} colId 
   * @param {*} bool 
   */
  #setHiddenApi(colIds, bool) {
    const columnState = []
    for (const colId of colIds) {
      const index = this.#columnSet.columns.findIndex(column => column.id === colId)
      if (index == -1) continue
      columnState.push({
        colId: colId,
        hide: bool
      })
    }
    this.#columnApi.applyColumnState({
      state: columnState
    })
  }

  /**
   * Set columns hidden
   * @param {*} colId 
   * @param {*} bool 
   * @param {*} options: { event, gridOnly }  
   */
  #setHidden(colIds, bool, { 
    event,
    gridOnly = false
  } = {}) {
    const columnEntities = []
    for (const colId of colIds) {
      const columnEntity = this.getColumn(colId)
      if (!columnEntity) {
        continue
      }
      columnEntity.isHidden = bool
      if (gridOnly) {
        this.#columnSet.updateColumn(columnEntity)
      } else {
        columnEntities.push(columnEntity)
      }
    }
    if (!gridOnly) { 
      this.updateColumns(columnEntities, event)
    }
    this.#setHiddenApi(colIds, bool)
  }

  /**
   * Hide column
   * @param {*} colIds 
   * @param {*} options: { event, gridOnly } 
   */
  hide(colIds, {
    event,
    gridOnly = false
  } = {}) {
    this.#setHidden(colIds, true, { event, gridOnly })
  }

  /**
   * Unhide column
   * @param {*} colIds 
   * @param {*} options: { event, gridOnly } 
   */
  unhide(colIds, {
    event,
    gridOnly = false
  } = {}) {
    this.#setHidden(colIds, false, { event, gridOnly })
  }

  //#endregion

  //#region //--- Remote column Set methods ---//
  setColumnSet(columnSetEntity) {
    this.columnSet = columnSetEntity
  }

  //#endregion

  //#region //--- Local columns methods ---//
  // 
  getColumn(id) {
    return this.#columnSet.columns.find(col => col.id === id)
  }

  /**
   * Add a column
   * @param {Object} columnEntity
   * @param {string} afterId - specify the column id where new column will be inserted after
   * @param {Object} event 
   * @returns {Promise} ColumnSetEntity
   */
  async addColumn(columnEntity, afterId, event) {
    //event && this.setColumnEvent(columnEntity, event)
    columnEntity.setCreated().setUpdated()
    this.#columnSet.addColumnAfterId(columnEntity, afterId)
    this.#columnSet.setCreated().setUpdated()
    await ColumnSetEntity.upsert(this.#columnSet)
  }

  /**
   * Update a column
   * @param {Object} columnEntity 
   * @param {Object} event 
   * @returns {Promise} ColumnSetEntity
   */
  async updateColumn(columnEntity, event) {
   // event && this.setColumnEvent(columnEntity, event)
    columnEntity.setUpdated()
    this.#columnSet.updateColumn(columnEntity)
    this.#columnSet.setCreated().setUpdated()
    await ColumnSetEntity.upsert(this.#columnSet)
  }

  /**
   * Update mulitple columns 
   * @param {Object[]} columnEntities 
   * @param {Object} event 
   * @returns {Promise} ColumnSetEntity
   */
  async updateColumns(columnEntities) {
    for (const columnEntity of columnEntities) {
      columnEntity.setUpdated()
      this.#columnSet.updateColumn(columnEntity)
    }
    this.#columnSet.setCreated().setUpdated()
    await ColumnSetEntity.upsert(this.#columnSet)
  }

  async deleteColumn(columnEntity, event) {
    const fieldSchema = this.#columnFieldSchemaMap.get(columnEntity.id)
    if (!columnEntity.isDeletable || !fieldSchema?.isDeletable) {
      return
    }
    columnEntity.markAsDeleted()
    columnEntity.setDeleted()
    this.updateColumn(columnEntity, event)
    return
  }

  //#endregion

  //#region //--- Pin / Unpin ---//
  /**
    * Set columns pin / unpin with grid apid
    * @param {*} colId 
    * @param {*} bool 
    */
  #setPinnedApi(colIds, bool) {
    const columnState = []
    for (const colId of colIds) {
      const index = this.#columnSet.columns.findIndex(column => column.id === colId)
      if (index == -1) continue
      columnState.push({
        colId: colId,
        pinned: bool
      })
    }
    this.#columnApi.applyColumnState({
      state: columnState
    })
  }

  /**
   * Set pinned
   * @param {*} colIds 
   * @param {*} bool 
   * @param {*} options: { event, gridOnly }  
   * @returns 
   */
  #setPinned(colIds, bool, {
    event,
    gridOnly = false
  } = {}) {
    const columnEntities = []
    for (const colId of colIds) {
      const columnEntity = this.getColumn(colId)
      if (!columnEntity) {
        continue
      }
      columnEntity.isPinned = bool
      if (gridOnly) {
        this.#columnSet.updateColumn(columnEntity)
      } else {
        columnEntities.push(columnEntity)
      }
    }
    if (!gridOnly) {
      this.updateColumns(columnEntities, event)
    }
    this.#setPinnedApi(colIds, bool)

  }

  /**
   * Pin column
   * @param {*} colIds 
   * @param {*} options: { event, gridOnly } 
   */
  pin(colIds, {
    event,
    gridOnly = false
  } = {}) {
    this.#setPinned(colIds, true, { event, gridOnly })
  }

  /**
   * Unpin column
   * @param {*} colIds 
   * @param {*} options: { event, gridOnly } 
   */
  unpin(colIds, {
    event,
    gridOnly = false
  } = {}) {
    this.#setPinned(colIds, false, { event, gridOnly })
  }

  //#endregion


  //#region //--- Sort ---//
  /**
    * Set column sort with grid apid
    * @param {*} colId 
    * @param {*} sortOrder: 'asc' | 'desc'
    */
  #setSortApi(colId, sortOrder) {
    const index = this.#columnSet.columns.findIndex(column => column.id === colId)
    if (index == -1) return
    this.#columnApi.applyColumnState({
      state: [
        {
        colId: colId,
        sort: sortOrder
        }
      ],
      defaultState: { sort: null }
    })
  }

  /**
   * Set sort
   * @param {*} colId
   * @param {*} sortOrder: 'asc' | 'desc'
   * @param {*} options: { event }  
   * @returns 
   */
  #setSort(colId, sortOrder, {
    event,
  } = {}) {
    if (event) {
      event.payload = { columnId: [colId] }
    }
    this.#setSortApi(colId, sortOrder)
  }

  /**
   * Sort applied to the column 
   * @param {*} colIds
   * @param {*} options: { event } 
   */
  sortAsc(colId, {
    event,
  } = {}) {
    this.#setSort(colId, COLUMN_SORT_ASC, { event })
  }

  /**
   * Unpin column
   * @param {*} colId
   * @param {*} options: { event } 
   */
  sortDesc(colId, {
    event,
  } = {}) {
    this.#setSort(colId, COLUMN_SORT_DESC, { event })
  }

  //#endregion

  //#region //--- Size ---//
  /**
    * Autosize columns with grid apid
    * @param {string[]} colIds
    * @param {bool} [skipHeader]
    * @returns {Object[]} column state
    */
  #setAutosizeApi(colIds, skipHeader = true) {
    const validColIds = []
    for (const colId of colIds) {
      const index = this.#columnSet.columns.findIndex(column => column.id === colId)
      if (index == -1) continue
      validColIds.push(colId)
    }
    this.#columnApi.autoSizeColumns(validColIds, skipHeader)
    const colStates = this.#columnApi.getColumnState()
    return colStates.filter(columnState => validColIds.includes(columnState.colId))
  }

  /**
   * Set autosize
   * @param {string[]} colIds
   * @param {bool} [skipHeader]
   * @param {*} [options] { event, gridOnly }  
   * @returns 
   */
  #setAutosize(
    colWidthArr, 
    skipHeader = true, 
    {
      event,
      gridOnly = false
    } = {}
  ) {
    const columnStates = this.#setAutosizeApi(colWidthArr.columnIds, skipHeader)
    const colDefs = columnStates.map(columnState => {
      return {
        colId: columnState.colId,
        width: columnState.width
      }
    })
    const columnEntities = []
    for (const colDef of colDefs) {
      const columnEntity = this.getColumn(colDef.colId)
      columnEntity.width = colDef.width
      if (gridOnly) {
        this.#columnSet.updateColumn(columnEntity)
      } else {
        columnEntities.push(columnEntity)
      }
    }
    if (!gridOnly) {
      this.updateColumns(columnEntities, event)
    }
    const newColWidthArr = {
      listId: colWidthArr.listId,
      userRole: colWidthArr.userRole,
      colDefs: colDefs
    }
    if (event) {
      event.payload = newColWidthArr
    }
  }

  /**
   * Autosize columns
   * @param {string[]} colIds
   * @param {bool} [skipHeader]
   * @param {*} options:  { event, gridOnly } 
   */
  autosize(
    colWidthArr, 
    skipHeader = true,
    {
      event,
      gridOnly = false
    } = {}
  ) {
    this.#setAutosize(colWidthArr, skipHeader, { event, gridOnly })
  }

 /**
    * Set columns width with grid apid
    * @param {Object[]} colWidthArr [{ colId, width }] 
    */
  #setWidthApi(colWidthArr) {
    const columnState = []
    for (const colWidth of colWidthArr) {
      const index = this.#columnSet.columns.findIndex(column => column.id === colWidth.colId)
      if (index == -1) continue
      columnState.push({
        colId: colWidth.colId,
        width: colWidth.width
      })
    }
    this.#columnApi.applyColumnState({
      state: columnState
    })
  }

  /**
   * Set columns width
   * @param {Object[]} colWidthArr [{ colId, width }] 
   * @param {*} [options] { event, gridOnly }  
   * @returns 
   */
  #setWidth(
    colWidthArr,
    {
      event,
      gridOnly = false
    } = {}
  ) {
    const columnEntities = []
    for (const colWidth of colWidthArr) {
      const columnEntity = this.getColumn(colWidth.colId)
      if (!columnEntity) {
        console.error('Missing columnEntity. We should not be here. col',  colWidth)
        continue
      }
      columnEntity.width = colWidth.width
      if (gridOnly) {
        this.#columnSet.updateColumn(columnEntity)
      } else {
        columnEntities.push(columnEntity)
      }
    }
    if (!gridOnly) {
      this.updateColumns(columnEntities, event)
    }

    this.#setWidthApi(colWidthArr)
  }

  /**
   * Set column width
   * @param {Object[]} colWidthArr { listId, colDefs:[{ colId, width }] }
   * @param {Object} [options] { event, gridOnly } 
   */
  resize(
    colWidthArr,
    {
      event,
      gridOnly = false
    } = {}
  ) {
    if (colWidthArr.listId != this.list.id) {
      return
    }
    if (event) {
      event.payload = colWidthArr
    }
    this.#setWidth(colWidthArr.colDefs, { event, gridOnly })
  }

  //#endregion

  //#region //--- Move ---//
  /**
   * Move column with grid apid
   * @param {string} columnId
   * @param {number} toIndex - target position
   */
  #setMoveColumnApi(columnId, toIndex) {
    const index = this.#columnSet.columns.findIndex(column => column.id === columnId)
    if (index == -1) return
    this.#columnApi.moveColumn(columnId, toIndex)
  }

  /**
   * Move column 
   * @param {string} columnId
   * @param {number} toIndex - target position
   * @param {*} [options] { event, gridOnly }  
   */
  #setMove(
    columnId,
    toIndex,
    {
      event,
      gridOnly = false
    } = {}
  ) {
    const fromIndex = this.#columnSet.columns.findIndex(column => column.id === columnId)
    if (fromIndex == -1) return
    const [moveColumn] = this.#columnSet.columns.splice(fromIndex, 1)
    this.#columnSet.columns.splice(toIndex - 1, 0, moveColumn)
    const columnEntities = []
    for (const column of this.#columnSet.columns) {
      const columnEntity = this.getColumn(column.id)
      if (!columnEntity) {
        continue
      }
      if (gridOnly) {
        this.#columnSet.updateColumn(columnEntity)
      } else {
        columnEntities.push(columnEntity)
      }
    }
    if (!gridOnly) {
      this.updateColumns(columnEntities)
      if (event) {
        this.#columnSet.updatedByEventId = event.id
      }
    }

    this.#setMoveColumnApi(columnId, toIndex)
  }

  /**
   * Move column 
   * @param {string} columnId
   * @param {number} toIndex - target position
   * @param {Object} [options] { event, gridOnly } 
   */
  move(
    listId,
    columnId,
    toIndex,
    {
      event,
      gridOnly = false
    } = {}
  ) {
    if (listId != this.list.id) {
      return
    }
    this.#setMove(columnId, toIndex, { event, gridOnly })
  }

  //#region /--- Local and remote field schema methods ---//
  getFieldSchemas() {
    return this.#fieldSchemaSet.fieldSchemas?.filter(fs => !fs.isDeleted && !FIELD_TYPES.includes(fs))
  }

  setFieldSchemas(fieldSchemas) {
    this.#fieldSchemaSet.fieldSchemas = fieldSchemas
  }

  getColumnFieldSchema(colId) {
    return this.#columnFieldSchemaMap.get(colId)
  }

  setFieldSchema(fieldSchemaEntity) {
    const index = this.#fieldSchemaSet.fieldSchemas
      .findIndex(fs => fs.id === fieldSchemaEntity.id)
    if (index < 0) {
      this.#fieldSchemaSet.fieldSchemas.push(fieldSchemaEntity)
    } else {
      this.#fieldSchemaSet.fieldSchemas[index] = fieldSchemaEntity
    }
  }

  getFormatInputMap() {
    return this.#formatInputMap
  }

  getFormatCompileInputMap() {
    return this.#formatCompileInputMap
  }
  //#endregion

  //#region /--- Local field schema methods ---//
  async addFieldSchema(fieldSchemaEntity, event) {
    const index = this.#fieldSchemaSet.fieldSchemas
      .findIndex(fs => fs.id === fieldSchemaEntity.id)
    if (index >= 0) {
      throwError('FieldSchema already exists')
    }
    fieldSchemaEntity.setCreated().setUpdated()
    this.#fieldSchemaSet.fieldSchemas.push(fieldSchemaEntity)
    await FieldSchemaEntity.upsert(fieldSchemaEntity)
  }

  async updateFieldSchema(fieldSchemaEntity, event) {
    if (event) {
      fieldSchemaEntity.updatedByEventId = event.id
    }
    const index = this.#fieldSchemaSet.fieldSchemas
      .findIndex(fs => fs.id === fieldSchemaEntity.id)
    if (index < 0) {
      console.error('fieldSchema index not found! No Update.')
      return
    }
    fieldSchemaEntity.setUpdated()
    this.#fieldSchemaSet.fieldSchemas[index] = fieldSchemaEntity
    await FieldSchemaEntity.upsert(fieldSchemaEntity)
  }

  async deleteFieldSchema(fieldSchemaEntity, event) {
    if (!fieldSchemaEntity.isDeletable) {
      return
    }
    fieldSchemaEntity.markAsDeleted()
    fieldSchemaEntity.setDeleted()
    this.updateFieldSchema(fieldSchemaEntity, event)
  }
  //#endregion
}
