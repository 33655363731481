/*!
 * Column db
 */

import { Db } from '@/db/Db.mjs'
import { required } from '../../utils'
import { COLLECTION_FILTER_GROUPS } from '@/db/db-collections.mjs'

export class FilterGroupDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_FILTER_GROUPS
    })
  }

  validateDocObject({
    id = required('id'),
    name = required('name'),
    listId = required('listId')
  }) {
    return
  }

  createDocObject(filterGroup) {
    this.validateDocObject(filterGroup.toObject())
    return {
      name: filterGroup.name,
      filters: filterGroup.filters,  
      listId: filterGroup.listId,
      ...super.createDocObject(filterGroup)
    }
  }
}
