
// Refs
import {
  filteredColumns,
} from './ui-states.mjs'


function getCurrentColumnById(id) {
  return filteredColumns.value?.find(col => col.id === id)
}


export {
  getCurrentColumnById,
}
