// Dependencies
import { required } from '../utils/required.mjs'
import { uploadBytes } from 'firebase/storage'
import { generateId } from '../utils/uuid.mjs'
import { storage, ref } from './storage.mjs'
import { LIST_IMPORT_PATH } from './constants/storage-constants.mjs'

/**
 * Upload a file / blob
 */
function uploadFile({
  id = generateId(),
  file = required('file or blob')
} = required('upload data')) {
  const storageRef = ref(storage, id)
  return uploadBytes(storageRef, file, /* metadata */)
}

function uploadListFile({
  id = generateId(),
  file = required('file or blob'),
  teamId = required('teamId'),
  listId = required('listId')
} = required('upload data')) {
  const storagePath = `${teamId}/${LIST_IMPORT_PATH}/${id}`
  const storageRef = ref(storage, storagePath)
  const metadata = {
    customMetadata: {
      filename: file.name,
      path: storagePath,
      listId: listId,
    }
  }
  return uploadBytes(storageRef, file, metadata)
}

// Exports
export { uploadFile, uploadListFile }
