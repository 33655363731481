/**
 * Is admin user
 */

import { EDITOR_USER_ROLE } from '../constants/roles.mjs'
import { userHasRole } from './user-has-role.mjs'

const isEditorUser = (user) => {
  return userHasRole(user, EDITOR_USER_ROLE)
}

export { isEditorUser }
