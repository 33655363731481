/**
 * Update interval util functions
 */
// Dependencies
import { required, setSessionItem, getSessionItem } from '../utils'

// Constants
const SESSION_KEY = 'sessionUpdatedAtInterval'
import { UPDATE_INTERVAL_DURATION } from '../constants/update-interval.mjs'

function getUpdateInterval() {
  const now = Date.now()
  return now - ( now % UPDATE_INTERVAL_DURATION )
}


/**
 * Set session updateInterval
 */
function getSessionUpdatedAtInterval() {
  return getSessionItem(SESSION_KEY)
}

/**
 * Get session updateInterval
 */
function setSessionUpdatedAtInterval() {
  setSessionItem(SESSION_KEY, getUpdateInterval())
}

// Exports
export { getSessionUpdatedAtInterval, setSessionUpdatedAtInterval }
export { getUpdateInterval }
