
// session
import { getTeamSession } from '../../session/team-session.mjs'

// 

import { ListEntity } from '../../entity/ListEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
import { PRICE_LIST } from '../constants/list-types.mjs'

import {
  TYPE_DEFAULT
} from '../../column-set/constants/column-set-types.mjs'

import { createStaticColumnSet } from '../../column-set/utils/create-static-column-set.mjs'

export const createPriceList = async ({
  name,
  sourceColumnSet,
  selectedColumns,
  canExport,
}) => {
  const teamId = sourceColumnSet.teamId
  const listEntity = new ListEntity({
    name,
    type: PRICE_LIST,
    teamId,
    isStatic: true,
    canExport
  })

  const columnSet = await createStaticColumnSet(
    listEntity.id,
    sourceColumnSet,
    selectedColumns
  )
  listEntity.columnSetId = columnSet.id
  await ListEntity.upsert(listEntity)
  await ColumnSetEntity.upsert(columnSet)

  return { list: listEntity, columnSet: columnSet }

}
