/*!
 * List-Entity
 */

// Entity
import { Entity } from '../entity/Entity.mjs'

import { required, deepClone, isArray } from '../utils'
import { LIBRARY_LIST, DEFAULT_EXPORT_PERMISSION, CSV_PRICE_LIST } from '@/list-core/constants/list-constants.mjs'
import { TRUE, FALSE, UNDEFINED } from '../constants/types.mjs'
import { createItemWithFields } from '../item/utils/create-item-with-fields.mjs'
import { CHAR_EMPTY } from '../constants/characters.mjs'
import { upsertList, fetchList } from '../list-core/data/list-data.mjs'

export class ListEntity extends Entity {
  #name
  #teamId
  #type
  #columnSetId
  #columnSet
  #items
  #sharedWith
  #deletedListItemIds
  #createdByUser = {}
  #updatedByUser = {}
  #csvFilePath
  csv
  #isStatic
  #canExport
  #sourceIntegrationId
  #logfiles
  constructor({
    name,
    teamId,
    type,
    columnSetId,
    columnSet,
    items = [],
    sharedWith = [],
    deletedListItemIds = [],
    csvFilePath,
    isStatic = FALSE,
    canExport = DEFAULT_EXPORT_PERMISSION,
    sourceIntegrationId,
    logfiles = [],
    ...props
  } = {}) {
    super({ ...props })
    this.name = name
    this.teamId = teamId
    this.type = type
    this.columnSetId = columnSetId
    this.columnSet = columnSet
    this.items = items
    this.sharedWith = sharedWith
    this.deletedListItemIds = deletedListItemIds
    this.csvFilePath = csvFilePath
    this.isStatic = isStatic
    this.canExport = canExport
    this.sourceIntegrationId = sourceIntegrationId
    this.logfiles = logfiles
  }

  //--- getters ---//
  get isListEntity() { return TRUE }
  get name() { return this.#name }
  get teamId() { return this.#teamId }
  get type() { return this.#type }
  get columnSetId() { return this.#columnSetId }
  get columnSet() { return this.#columnSet }
  get items() { return this.#items }
  get sharedWith() { return [...this.#sharedWith] }
  get deletedListItemIds() { return [...this.#deletedListItemIds] }
  get createdByUser() { return this.#createdByUser }
  get updatedByUser() { return this.#updatedByUser }
  get csvFilePath() { return this.#csvFilePath }
  get isStatic() { return this.#isStatic }
  get canExport() { return this.#canExport }
  get sourceIntegrationId() { return this.#sourceIntegrationId }
  get logfiles() { return this.#logfiles }

  //--- setters ---//
  set name(str) { this.#name = str }
  set teamId(id) { this.#teamId = id }
  set type(str) { this.#type = str }
  set columnSetId(id) { this.#columnSetId = id }
  set columnSet(obj) {
    if (!obj) {
      return
    }
    this.#columnSet = obj
    this.#columnSet.isStatic = this.isStatic
    this.#columnSetId = obj.id
  }
  set items(arr) { this.#items = arr }
  set sharedWith(arr) { this.#sharedWith = new Set([...arr]) }
  set deletedListItemIds(arr) { this.#deletedListItemIds = new Set([...arr]) }
  set csvFilePath(str) { this.#csvFilePath = str }
  set isStatic(bool) { this.#isStatic = !!bool }
  set canExport(str) { this.#canExport = str }
  set sourceIntegrationId(id) { this.#sourceIntegrationId = id }
  set logfiles(arr) { this.#logfiles = arr }

  //--- private methods ---//

  //#region //--- column methods ---//
  getColumns() {
    return this.#columnSet?.columns
  }

  getColumnById(id) {
    return this.#columnSet?.getColumnById(id)
  }

  getColumnSet() {
    return this.#columnSet
  }

  addColumn(columnEntity, index) {
    this.#columnSet?.addColumn(columnEntity, index)
  }

  addColumnAfterId(columnEntity, afterId) {
    this.#columnSet?.addColumnAfterId(columnEntity, afterId)
  }
  //#endregion

  //#region //--- import column set methods ---//


  //#endregion

  //#region //--- item functions ---//
  getActiveItems() {
    return this.#items.filter(item => !item.isDeleted)
  }

  #addStaticItems(
    itemEntities = required('itemEntities')
  ) {
    if (!isArray(itemEntities)) {
      return
    }
    const itemSourceIdx = new Map(this.#items.map((item, idx) => [item.sourceId, idx]))
    const newItems = []
    itemEntities.forEach(item => {
      const newItem = item.cloneAsNew()
      newItem.isStatic = true
      newItem.LibraryListId = CHAR_EMPTY
      newItem.lists = [this.id]
      newItem.sourceId = item.id
      const existingIdx = itemSourceIdx.get(item.id)

      if (existingIdx >= 0) {
        this.#items[existingIdx].fields = newItem.fields
        newItems.push(this.#items[existingIdx])
      } else {
        this.#items.push(newItem)
        newItems.push(newItem)
      }
    })
    return newItems
  }

  #addDynamicItems(
    itemEntities = required('itemEntities')
  ) {
    if (!isArray(itemEntities)) {
      return
    }
    itemEntities.forEach(item => {
      item.addListId(this.id)
    })
    this.#items.push(...itemEntities)
    return itemEntities
  }

  addItems(
    itemEntities = required('itemEntities')
  ) {
    return this.#isStatic
      ? this.#addStaticItems(itemEntities)
      : this.#addDynamicItems(itemEntities)
  }

  addItem(itemEntity) {
    return (this.addItems([itemEntity]))[0]
  }

  updateItem(itemEntity) {
    const index = this.#items.findIndex(item => item.id === itemEntity.id)
    if (index >= 0) {
      this.#items[index] = itemEntity
    }
    return this.#items[index] = itemEntity
  }


  #deleteStaticItems(itemEntities) {
    if (!itemEntities?.length) {
      return this
    }
    const itemIds = itemEntities.map(item => item.id)
    this.#items.filter(item => itemIds.includes(item.id))
      .forEach(item => item.markAsDeleted())
    this.#items = this.#items.filter(item => !itemIds.includes(item.id))
  }

  #removeDynamicItems(itemEntities) {
    if (!itemEntities?.length) {
      return this
    }
    const itemIds = itemEntities.map(item => item.id)
    this.#items.filter(item => itemIds.includes(item.id))
      .forEach(item => item.removeListId(this.id))
    this.#items = this.#items.filter(item => !itemIds.includes(item.id))
  }

  removeItems(
    itemEntities = required('itemEntities')
  ) {
    return this.#isStatic
      ? this.#deleteStaticItems(itemEntities)
      : this.#removeDynamicItems(itemEntities)
  }

  removeItem(itemEntity) {
    this.removeItems([itemEntity])
  }

  deleteItems(itemEntities) {
    if (!itemEntities?.length) {
      return
    }
    const deleteItemIds = itemEntities
      .filter(item => item.isDeletable)
      .map(item => item.id)
    this.#items
      .filter(item => deleteItemIds.includes(item.id))
      .forEach(item => item.markAsDeleted())
    this.#items = this.#items.filter(item => !deleteItemIds.includes(item.id))
  }


  deleteItem(itemEntity) {
    this.deleteItems([itemEntity])
  }

  upsertItem(itemEntity) {
    if (!itemEntity) {
      return
    }
    const index = this.#items.findIndex(item => item.id === itemEntity.id)
    if (index < 0) {
      this.addItem(itemEntity)
    } else {
      this.#items[index] = itemEntity
    }
  }

  createItem = () => {
    const newItem = createItemWithFields({
      teamId: this.#teamId,
      libraryListId: this.#type === LIBRARY_LIST ? this.id : UNDEFINED,
      listId: this.id,
      columns: this.getColumns().filter(col => !col.isDeleted && !col.fieldSchema?.isDeleted),
    })
    return this.addItem(newItem)
  }

  //#endregion

  //#region //--- sharing functions ---//
  addSharedWith(emails) {
    if (isArray(emails)) {
      this.sharedWith = new Set([...this.#sharedWith, ...emails])
    } else {
      this.sharedWith = new Set([...this.#sharedWith, emails])
    }
  }

  removeSharedWith(emails) {
    if (isArray(emails)) {
      for (const email of emails) {
        if (this.#sharedWith.includes(email)) {
          continue
        }
        this.#sharedWith.delete(email)
      }
    } else {
      this.#sharedWith.delete(emails)
    }
  }



  //#endregion

  //#region //--- columns and fields functions ---//
  getFieldSchemas() {
    return this.#columnSet.getFieldSchemas()
  }

  updateColumn(columnEntity) {
    this.#columnSet.updateColumn(columnEntity)
  }

  pinColumn(id) {
    this.getColumnById(id).pin()
  }

  unpinColumn(id) {
    this.getColumnById(id).unpin()
  }

  hideColumn(id) {
    this.getColumnById(id).hide()
  }

  unhideColumn(id) {
    this.getColumnById(id).unhide()
  }

  setColumnOrder(ids) {
    this.#columnSet.setColumnOrder(ids)
  }
  //#endregion

  //#region //--- logfiles methods ---//
  addLogfileMeta(filemeta) {
    this.#logfiles = [filemeta, ...this.#logfiles]
  }
  removeLogfileMeta(path) {
    this.#logfiles = this.#logfiles.filter(f => f.path !== path)
  }
  //#endregion
  
  setCreatedByUserEmail(email) {
    this.#createdByUser.email = email
  }

  setUpdatedByUserEmail(email) {
    this.#updatedByUser.email = email
  }



  //#region //--- object methods ---//
  validate() {
    const msg = `[name: "${this.#name}"  id: ${this.id}]`
    if (!this.id) { required(`${msg} id`) }
    if (!this.teamId) { required(`${msg} teamId`) }
    if (!this.name) { required(`${msg} name`) }
    if (!this.type) { required(`${msg} type`) }
    if (!this.columnSetId) { required(`${msg} columnSetId`) }
    if (!this.columnSet || this.columnSet.length === 0) { required(`${msg} columnSet`) }
    if (this.columnSetId !== this.#columnSet.id) {
      required(`${msg} columnSetId and columnSet.id not match`)
    }
    this.columnSet.validate()
    const isDeleted = this.isDeleted
    const deletedListItemIds = this.deletedListItemIds
    const listId = this.id
    const teamId = this.teamId
    this.#items.forEach(item => {
      if (!item.isItemEntity) {
        { required(`${msg} list item must be an entity: ${item.id}`) }
      }
      item.validate()
      switch (isDeleted) {
        case true:
          if (item.lists.includes(listId)) { required(`${msg} deleted. list id still exists in itemId: ${item.id}`) }
          if (!deletedListItemIds.includes(item.id)) { required(`${msg} deleted. itemId: ${item.id} is missing in deleteListItemIds`) }
          break
        case false:
          if (!item.lists.includes(listId)) { required(`${msg} missing list id in itemId: ${item.id}`) }
          if (item.teamId !== teamId) { required(`${msg} item and list team id not match itemId: ${item.id}`) }
          break
      }
    })
  }


  #getMyProperties() {
    return {
      name: this.#name,
      teamId: this.#teamId,
      type: this.#type,
      columnSetId: this.#columnSetId,
      sharedWith: [...this.#sharedWith],
      isStatic: this.#isStatic,
      canExport: this.#canExport,
      sourceIntegrationId: this.#sourceIntegrationId,
      logfiles: this.#logfiles
    }
  }
  cloneAsNew({ name, type }) {
    const newList = new ListEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
      name,
      type,
      columnSet: this.#columnSet?.cloneAsNew(),
      columnSetId: UNDEFINED,
      items: [],
      isStatic: this.#isStatic,
      sourceIntegrationId: UNDEFINED,
      logfiles: [],
    })
    newList.columnSet.listId = newList.id
    return newList
  }


  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties(),
      columnSet: this.#columnSet,
      deletedListItemIds: [...this.#deletedListItemIds],
      items: this.#items,
      csvFilePath: this.#csvFilePath,
      isStatic: this.#isStatic,
      logfiles: this.#logfiles
    }
  }

  //#endregion

  //--- static methods ---//
  static async upsert(listEntity) {
    return upsertList(listEntity.toObject())
  }

  static async fetch(id) {
    const list = await fetchList(id)
    return list ? new ListEntity(list) : undefined
  }
}

