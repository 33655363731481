// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { getTeamSession } from '../../session/team-session.mjs'

// events
import {
  EVENT_FIELD_SCHEMA_UPDATED,
  EVENT_FIELD_SCHEMA_UPDATED_LOCAL,
  EVENT_FIELD_SCHEMA_UPDATED_REMOTE,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

// entities
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

import { isLibraryList, isViewList } from '../../list-core/utils/is-list-type.mjs'


//#region //--- Update field schema ---//

export const addEventsFieldSchemaUpdate = (controller, subscriptions, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_FIELD_SCHEMA_UPDATED_LOCAL,
    (event) => updateFieldSchemaEvent(event, columnManager, itemManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_FIELD_SCHEMA_UPDATED,
      updateFieldSchemaRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_FIELD_SCHEMA_UPDATED_REMOTE,
    (event) => updateFieldSchemaRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}


/**
 * Update column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const updateFieldSchemaEvent = async (event, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  let startTime = Date.now()
  const { fieldSchema } = event.detail
  const team = getTeamSession()

  const fieldSchemaEntity = !fieldSchema.isFieldSchemaEntity
    ? (new FieldSchemaEntity(fieldSchema))
    : fieldSchema

  const externalEvent = createExternalEvent({
    action: EVENT_FIELD_SCHEMA_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnSetId: columnManager.columnSet.id,
      fieldSchemaId: fieldSchemaEntity.id
    }
  })
  await columnManager.updateFieldSchema(fieldSchemaEntity, externalEvent)
  columnManager.refreshColumns()
  itemManager.compileAllItems(columnManager.getFieldSchemas(), [fieldSchema.key])

  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
}


/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const updateFieldSchemaRemoteEvent = async (event, columnManager, itemManager) => {
  const { fieldSchema } = event.detail
  if (!isLibraryList(columnManager.list) && !isViewList(columnManager.list)) {
    return
  }
  columnManager.setFieldSchema(fieldSchema)
  columnManager.refreshColumns()
  itemManager.compileAllItems(columnManager.getFieldSchemas(), [fieldSchema.key])
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const updateFieldSchemaRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('Incoming event originated here. skip', eventId)
    return
  }
  const { fieldSchemaId } = payload
  if (!fieldSchemaId) {
    console.error('Missing fieldSchemaId in payload')
    return
  }
  let promises = []
  promises[0] = FieldSchemaEntity.fetch(fieldSchemaId)
  Promise.all(promises).then(values => {
    const [fieldSchema] = values
    if (!fieldSchema) {
      return
    }
    dispatch(EVENT_FIELD_SCHEMA_UPDATED_REMOTE, { fieldSchema })
  })
}
