<template>
  <main-layout>
    Import screen
  </main-layout>
</template>

<style scoped>
</style>

<script setup>

// Layouts
import MainLayout from '../layouts/MainLayout.vue'

</script>
