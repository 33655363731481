/**
 * Salesforce import configuration UI states
 */
import { triggerRef } from 'vue'

import {
  integrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import {
  currentIntegrationId,
  selectedSfProductFields,
  sfProductFields,
  stdPricebooks,
  customPricebooks,
  selectedStdPricebook,
  selectedCustomPricebooks,
  integrationDescription,
} from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'

// sesssion events
import { isSessionEvent } from '../../../events/session-events.mjs'

// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_IMPORT_BASIC } from '../../../integration-apps/registered-apps.mjs'

import { SOBJECT_PRODUCT, SOBJECT_PRICEBOOK } from '../../../integration-modules/salesforce/constants/sobject-names.mjs'

import { getIntegrationApp } from '../../../integration-modules/utils/integration-apps-utils.mjs'

export const clearImportConfigurationUiStates = async () => {
  const integrationDetails = await getIntegrationApp(INTEGRATION_SALESFORCE, APP_SFDC_IMPORT_BASIC)
  selectedSfProductFields.value = []
  sfProductFields.value = []
  stdPricebooks.value = []
  customPricebooks.value = []
  selectedStdPricebook.value = {}
  selectedCustomPricebooks.value = []
  integrationName.value = integrationDetails?.defaultName
  integrationDescription.value = integrationDetails?.name
}

const setPricebooksUiStates = (integration) => {
  const { cache, configuration } = integration

  // std pricebooks options
  const stdPb = cache?.pricebooks?.find(pb => pb.isStandard)
  stdPricebooks.value = stdPb ? [{ key: stdPb.salesforceId, label: stdPb.name }] : []

  // std pricebook selection
  selectedStdPricebook.value = selectedStdPricebook.value.selected || {}
  selectedStdPricebook.value = stdPb ? { key: stdPb.salesforceId, label: stdPb.name } : { key: null, label: null }

  // custom pricebooks options
  const custPb = cache?.pricebooks?.filter(pb => !pb.isStandard) || []
  customPricebooks.value = custPb.length === 0 ? []
    : custPb.map(pb => {
      return {
        key: pb.salesforceId, label: pb.name
      }
    }).sort((a, b) => a.label.localeCompare(b.label))

  // custom pricebooks selections
  selectedCustomPricebooks.value = configuration?.customPricebookEntryImport?.selected || []

}

const setProductUiStates = (integration) => {
  const { cache, configuration } = integration

  // product field options
  const product2Fields = (cache?.objectDefinitions?.product2?.fields || [])
  sfProductFields.value = product2Fields
    .map(field => {
      return {
        key: field.key,
        label: `${field.label} (${field.key})`,
        required: field.nillable,
        permissionable: field.permissionable
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  // product field selections
  // Set default of there is nothing in config
  selectedSfProductFields.value = (configuration?.productImport?.selected || [])
  if (selectedSfProductFields.value.length === 0) {
    // set defaults
    const peMandatoryFields = ['Name', 'ProductCode', 'IsActive']
    const recommandedFields = sfProductFields.value
      .filter(field => peMandatoryFields.includes(field.key))
      .map(field => { return { key: field.key, label: field.label } })
    const suggestedFields = sfProductFields.value
      .filter(field => !peMandatoryFields.includes(field.key)
        && field.permissionable
        && field.required)
      .map(field => { return { key: field.key, label: field.label } })

    selectedSfProductFields.value = [
      ...recommandedFields,
      ...suggestedFields
    ]
  }
}

/**
 * Set import configuraiton ui states
 * @param {*} integration 
 */
export const setImportConfigurationUiStates = async (integration) => {
  setPricebooksUiStates(integration)
  setProductUiStates(integration)
  integrationDescription.value = await getIntegrationApp(INTEGRATION_SALESFORCE, APP_SFDC_IMPORT_BASIC)
}


/**
 * Reload import options
 * @param {*} integration 
 */
export const reloadImportOptions = (integration) => {
  setImportConfigurationUiStates(integration)
}

/**
 * On Integration Update
 * @param {*} event 
 * @returns 
 */
export const onLocalSalesforceImportConfigUpdated = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setImportConfigurationUiStates(integration)
}

export const onRemoteSalesforceImportConfigUpdated = (event) => {

  const integration = event.detail
  if (isSessionEvent(integration.updatedByEventId)) {
    return
  }
  if (currentIntegrationId.value !== integration.id) {
    return
  }
  setImportConfigurationUiStates(integration)
}

export const onLocalSalesforceImportConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearImportConfigurationUiStates(integration)
}

export const onRemoteSalesforceImportConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearImportConfigurationUiStates(integration)
}


export const createImportConfigurationFromUi = () => {
  const configuration = {}

  configuration.stdPricebookEntryImport = {
    object: SOBJECT_PRICEBOOK,
    selected: selectedStdPricebook.value
  }

  configuration.customPricebookEntryImport = {
    object: SOBJECT_PRICEBOOK,
    selected: [...selectedCustomPricebooks.value].filter(pb => pb.label)
  }
  configuration.productImport = {
    object: SOBJECT_PRODUCT,
    retrieveActiveOnly: true,
    selected: [...selectedSfProductFields.value].filter(field => field.key)
  }
  return configuration
} 