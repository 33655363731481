/*!
 * Grid manager entity
 */
import { ref, triggerRef } from 'vue'

// Utils
import { throwError } from '../../utils'
import { TRUE, FALSE } from '../../constants'

import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
// col defs
import { ViewFirstColumn, createNewColumnColDef, defaultColDef } from '../../ui/components/sheet.mjs'
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'
const DEFAULT_HEADER_HEIGHT = 48
import { GridColumnManager } from '../../grid-manager/entities/GridColumnManager.mjs'

import SheetHeaderCell from '../../components/sheet/SheetHeaderCell.vue'

export class importColumnManager extends GridColumnManager {
  constructor({
    //columnSet,
    //importColumnSet,
    ...props
  } = {}) {
    super({ ...props })
    this.firstColumnDef = ViewFirstColumn()
    this.lastColumnDef = undefined //createNewColumnColDef()
    //this.columnSet = importColumnSet
  }

  //#region //--- Property Getters ---//
  //#endregion

  //#region //--- Property Setters ---//
  //#endregion


  //#region //--- import columns ---//

  /**
   * get the import field schemas
   * @returns 
   */
  getImportFieldSchemas() {
    const importColumns = this.importColumnSet.columns
    const mappedSchemas = []
    for (const importCol of importColumns ) {
      const sourceFieldSchema =  this.getColumnFieldSchema(importCol.importColumnId)
      if (!sourceFieldSchema) {
        continue
      }
      mappedSchemas.push(new FieldSchemaEntity({
        ...importCol.fieldSchema.toObject(),
        key: sourceFieldSchema.key,
        label: sourceFieldSchema.label
      }))
    }
    return mappedSchemas
  }

  async updateImportColumn(columnEntity, event) {
    event && this.setColumnEvent(columnEntity, event)
    columnEntity.setUpdated()
    this.importColumnSet.updateColumn(columnEntity)
    this.importColumnSet.setCreated().setUpdated()
    await ColumnSetEntity.upsert(this.importColumnSet)
  }
  //#endregion

}
