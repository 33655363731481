/*!
 * Team listener
 */

import { sleep } from '@/utils'
import { onUserAdded, onUserChanged, onUserRemoved } from './user-ui-event-handlers.mjs'
import { UserDbListener } from './UserDbListener.mjs'
import { fetchUserQuery } from '../db/user-db.mjs'

export class UserListener {
  #userEntity
  #userListener

  constructor({
    userEntity,
  } = {}) {
    this.#userEntity = userEntity
  }

  async start() {
    if (this.#userListener) {
      this.stop()
      await sleep(1000) // give it time for all listeners to stop before starting again
    }
    this.#userListener = new UserDbListener({
      queryConditions: fetchUserQuery(this.#userEntity.id),
      addedFunction: onUserAdded,
      modifiedFunction: onUserChanged,
      removedFunction: onUserRemoved,
    })
    this.#userListener.start()

    return this
  }

  stop() {
    if (this.#userListener) {
      this.#userListener?.stop()
      this.#userListener = null
    }
  }
}
