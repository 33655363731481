// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'
import { required } from '../../utils/required.mjs'

import { getTeamSession } from '../../session/team-session.mjs'
// local events
import {
  EVENT_INTEGRATION_PUBLISH_LOCAL
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

// integration actions
import { publishProducts } from '../../integration-modules/salesforce/actions/publish-products.mjs'

import { isTeamCsvPriceList } from '../../list-core/utils/is-list-type.mjs'

export const addEventsIntegrationPublish = (controller, columnManager, itemManager) => {
  addEventListener(
    EVENT_INTEGRATION_PUBLISH_LOCAL,
    (event) => publishToIntegration(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

//#region //--- New Column ---//

/**
 * Add new column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
const publishToIntegration = async (event, columnManager, itemManager) => {
  const { id } = event.detail
  if (!id) {
    required('id')
    return
  }

  const selectedItems = itemManager.getSelectedItems()
  if (!selectedItems || selectedItems.length === 0) {
    return
  }
  const team = getTeamSession()
  if (!team.id) {
    return
  }
  const ids = []
  for (const item of selectedItems) {
    item.clearBatchResults()
    ids.push(item.id)
  }

  return await publishProducts({
    integrationId: id,
    teamId: team.id,
    listId: itemManager.list.id,
    itemIds: ids
  })
}

