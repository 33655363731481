
import { 
  REGEX_NUMBER_CSV_FORMAT, REGEX_PERCENTAGE_CSV_FORMAT, REGEX_CURRENCY_CSV_FORMAT, 
  REGEX_TAGLIST_CSV_FORMAT, REGEX_CHECKBOX_CSV_FORMAT,
  REGEX_DATE_ISO_FORMAT, REGEX_DATE_MM_DD_YYYY,
} from '../../constants/regex.mjs'

import { 
  DEFAULT_FIELD_TYPE, 
  FIELD_TYPE_CHECKBOX, FIELD_TYPE_DATE, 
  FIELD_TYPE_NUMBER, FIELD_TYPE_PERCENTAGE, FIELD_TYPE_CURRENCY,
} from '../field-schema-constants.mjs'

import { parse as parseCSV } from 'papaparse'
import { FIELD_TYPE_TAGLIST } from '../../constants/field.mjs'
import { FIELD_LABEL_PRODUCT_CODE } from '../field-schema-default-fields.mjs'

//constants
const ITEM_SAMPLE_LIMIT = 50

/**
 * Read CSV file and determine the field schema type for each field header
 * @param {Object} file 
 * @returns {Object} field header: schema type
 */
async function getFieldSchemaTypes(file) {
  const fieldTypes = {}
  const [fieldLabels, ...items] = await parseCsvFileToJson(file)
  fieldLabels.forEach((label, index) => {
    const values = items.map(item => item[index])
    fieldTypes[label] = label === FIELD_LABEL_PRODUCT_CODE ? DEFAULT_FIELD_TYPE : getFieldSchemaType(values)
  })
  return fieldTypes
}

/**
 * Pasrse CSV file to JSON
 * @param {*} file 
 * @param {*} [rowsToParse] - default number refer to ITEM_SAMPLE_LIMIT
 * @returns {string[]} csv data in array
 */
async function parseCsvFileToJson (file, rowsToParse = ITEM_SAMPLE_LIMIT) {
  const csv = await new Promise(resolve => {
    parseCSV(file, {
      skipEmptyLines: true,
      header: false,
      delimiter: ',',
      preview: rowsToParse,
      complete: results => {
        resolve(results)
      }
    })
  })
  return csv.data
}

/**
 * Determine the field schema type based on a set of input values
 * @param {string[]} values - an array of values
 * @returns {string} field schema type
 */
function getFieldSchemaType(values) {
  const valueCounts = new Map()
  for (const value of values) {
    const fieldType =  getFieldType(value)
    const fieldTypeCount = valueCounts.has(fieldType) ? valueCounts.get(fieldType) + 1 : 1
    valueCounts.set(fieldType, fieldTypeCount)
  }
  //use this for fieldType with most count
  /* const [maxKey] = Array.from(valueCounts).reduce((a, b) => {
    return a[1] >= b[1] ? a : b
  }) */
  const maxKey = valueCounts.size === 1 ? valueCounts.keys().next().value : DEFAULT_FIELD_TYPE
  return maxKey
}

function getFieldType(input) {
  if (isNumberField(input)) return FIELD_TYPE_NUMBER
  if (isPercentageField(input)) return FIELD_TYPE_PERCENTAGE
  if (isCurrencyField(input)) return FIELD_TYPE_CURRENCY
  if (isCheckboxField(input)) return FIELD_TYPE_CHECKBOX
  if (isTagListField(input)) return FIELD_TYPE_TAGLIST
  if (isDateField(input)) return FIELD_TYPE_DATE
  return DEFAULT_FIELD_TYPE
}

function isNumberField(input) {
  return REGEX_NUMBER_CSV_FORMAT.test(input)
}

function isDateField(input) {
  return !isNaN(Date.parse(input)) 
    || REGEX_DATE_ISO_FORMAT.test(input) 
    || REGEX_DATE_MM_DD_YYYY.test(input)
}

function isCheckboxField(input) {
  return REGEX_CHECKBOX_CSV_FORMAT.test(input)
}

function isPercentageField(input) {
  return REGEX_PERCENTAGE_CSV_FORMAT.test(input)
}

function isCurrencyField(input) {
  return REGEX_CURRENCY_CSV_FORMAT.test(input)
}

function isTagListField(input) {
  return REGEX_TAGLIST_CSV_FORMAT.test(input)
}


export { getFieldType, getFieldSchemaType, getFieldSchemaTypes }