/**
 * Dataabse constants
 */

/*
  Firebase best practices
  Maxiumn batch size is 500
  Limit the COLLECTION write rate - less than 1,000 operations/sec
  Limit the COLLECTION write rate with sequential values - less than 500 operations/sec
  Implicit exclude of sequential value from index if possible
*/

// settings here is per session. make sure is below 
export const COMMIT_BATCH_SIZE                = 75
export const COMMIT_BATCH_WAIT                = 700 // in millseconds

// TODO: rename to COLLECTION_{key}
export const FIELDS_COLLECTION_NAME           = 'field-schemas'
export const FILTER_GROUPS_COLLECTION_NAME    = 'filter-groups'
export const INVITES_COLLECTION_NAME          = 'invites'
export const ITEMS_COLLECTION_NAME            = 'items'
export const LISTS_COLLECTION_NAME            = 'lists'
export const TEAMS_COLLECTION_NAME            = 'teams'
export const USERS_COLLECTION_NAME            = 'users'
export const VIEWS_COLLECTION_NAME            = 'views'
export const EMAIL_COLLECTION_NAME            = 'email'
export const EMAIL_TEMPLATES_COLLECTION_NAME  = 'email-templates'
