<template>

  <!-- Main Layout-->
  <div style="position: relative; width: 100vw; height: 100vh;">

    <!-- Menu Toggle -->
    <q-btn @click="onToggleMenu()" flat style="padding: 1rem; position: absolute; left: .5rem; top: .6rem; background-color: white; z-index: 1000;">
      <q-tooltip style="z-index: 999999;">Toggle menu</q-tooltip>
      <svg width="28px" viewBox="107.4 135.115 26.3 26.259" xmlns="http://www.w3.org/2000/svg">
        <path d="M 120.032 157.315 C 121.214 155.268 120.512 152.65 118.465 151.468 C 116.418 150.286 113.801 150.988 112.619 153.035 C 111.437 155.082 112.138 157.7 114.185 158.881 C 116.232 160.063 118.85 159.362 120.032 157.315 Z M 118.495 151.468 L 130.828 158.588 L 118.495 151.468 Z" id="Combined-Shape" style="fill: none; stroke: rgb(0, 159, 255);" transform="matrix(-1, 0, 0, -1, 242.872696, 310.349258)" stroke-width="2.84"></path>
        <path d="M 116.652 144.073 C 117.834 146.12 120.451 146.822 122.498 145.64 C 124.545 144.458 125.247 141.84 124.065 139.793 C 122.883 137.746 120.265 137.045 118.218 138.227 C 116.171 139.409 115.47 142.026 116.652 144.073 Z M 122.425 145.696 L 110.373 152.626 L 122.425 145.696 Z" id="Combined-Shape" style="fill: none; stroke: rgb(0, 159, 255);" transform="matrix(-1, 0, 0, -1, 235.012054, 290.278702)" stroke-width="2.84"></path>
        <path d="M 122.278 150.871 L 122.254 137.225 L 122.278 150.871 C 122.268 150.988 122.263 151.106 122.263 151.225 C 122.263 153.589 124.179 155.505 126.543 155.505 C 128.907 155.505 130.823 153.589 130.823 151.225 C 130.823 148.861 128.907 146.945 126.543 146.945 C 124.299 146.945 122.457 148.673 122.278 150.871 Z" id="Combined-Shape" style="fill: none; stroke: rgb(0, 159, 255);" transform="matrix(-1, 0, 0, -1, 253.076996, 292.730011)" stroke-width="2.84"></path>
      </svg>
    </q-btn>

    <!-- Main Menu -->
    <div
      id="MainMenu"
      class="main-menu"
      :style="isMainMenuOpen ? `
        width: ${mainMenuWidth}px;
        margin-left: 0px;
      ` : `
        margin-left: -${mainMenuWidth}px;
      `"
    >
      <div style="display: flex; flex-grow: 1; flex-direction: column; height: 100%; overflow: none; padding-right: 10px;">

        <div style="font-size: 1rem; font-weight: 500; text-align: center; padding-top: 1.8rem;">ProductEngine</div>

        <div style="height: 100%; display: flex; flex-direction: column; overflow: auto; margin-top: 1.2rem;">

          <q-expansion-item
            v-show="canViewLibrarySection"
            dense
            @update:model-value="bool => setExpansionItemState('library', bool)"
            :default-opened="getExpansionItemState('library')"
            header-style="font-size: .9em; margin-left: .4rem; padding-top: 1rem;"
            header-class="text-weight-medium"
            expand-icon-class="text-black"
            label="Library"
            @mouseover="isManagePriceListVisible = true"
            @mouseout="isManagePriceListVisible = true"
          >
            <q-item to="/library" active-class="bg-accent text-grey-10" dense>
              <q-item-section avatar style="padding-left: .7rem;">
                <q-icon name="o_folder" style="opacity: .7;"></q-icon>
              </q-item-section>
              <q-item-section class="text-caption text-weight-medium">All Items</q-item-section>
            </q-item>

            <template v-for="list of teamPriceLists" :key="list.id">
              <q-item v-if="isViewList(list)" :to="'/view/' + list.id" active-class="bg-accent text-grey-10" dense>
                <q-item-section avatar style="padding-left: .7rem;">
                  <q-icon name="o_view_list" style="opacity: 0.7;"></q-icon>
                  <q-tooltip v-if="list.sharedWith.length">Shared with {{ list.sharedWith.length }} team{{ list.sharedWith.length !== 1 ? 's' : '' }}</q-tooltip>
                </q-item-section>
                <q-item-section class="text-caption text-weight-medium"><div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%;">{{ list.name }}</div></q-item-section>
                <q-item-section side v-show="isManagePriceListVisible" @click.prevent.stop>
                  <q-btn size="10px" flat dense round icon="more_vert"/>
                  <q-menu class="q-pa-sm shadow-brand2">
                    <q-list dense>
                      <q-item clickable v-close-popup>
                        <q-item-section @click="onEditView(list)">Edit View</q-item-section>
                      </q-item> 
                      <q-item clickable v-close-popup >
                        <q-item-section class="text-red-12" @click="onDeleteList(list)">Delete</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-item-section>
              </q-item>
            </template>

            <!-- TO IMPLEMENT IN FUTURE
            <q-item to="/deleted" active-class="bg-accent text-grey-10" dense>
              <q-item-section avatar style="padding-left: .8rem;">
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 4L2.83464 13.1811C2.92829 14.2112 3.79202 15 4.82643 15H10.1736C11.208 15 12.0717 14.2112 12.1654 13.1811L13 4" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M1 4H14" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M5 4V3C5 1.89543 5.89543 1 7 1H8C9.10457 1 10 1.89543 10 3V3.5" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M6 7V12" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M9 7V12" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </q-item-section>
              <q-item-section class="text-caption text-weight-medium">Recently Deleted</q-item-section>
            </q-item>
            -->

          </q-expansion-item>

          <q-expansion-item
            dense
            v-show="canViewPriceListsSection"
            @update:model-value="bool => setExpansionItemState('price-lists', bool)"
            :default-opened="getExpansionItemState('price-lists')"
            header-style="font-size: .9em; margin-left: .4rem; padding-top: 1rem;"
            header-class="text-weight-medium"
            expand-icon-class="text-black"
            style="cursor: default;"
            @mouseover="isManagePriceListVisible = true"
            @mouseout="isManagePriceListVisible = true"
          >
          <template v-slot:header>
            <q-item-section>
              <a style="text-decoration: none; color: inherit;">Price Lists</a>
            </q-item-section>
          </template>

          <template v-for="list of teamPriceLists" :key="list.id">
            <q-item v-if="isPriceList(list)" :to="'/list/' + list.id" active-class="bg-accent text-grey-10" dense>
              <q-item-section avatar style="padding-left: .95rem;">
                <svg height="12" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 5H5" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M2 5H1" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M14 1H5" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M2 1H1" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M14 9H5" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M2 9H1" stroke="#414864" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <q-tooltip v-if="list.sharedWith.length">Shared with {{ list.sharedWith.length }} team{{ list.sharedWith.length !== 1 ? 's' : '' }}</q-tooltip>
              </q-item-section>
              <q-item-section class="text-caption text-weight-medium"><div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%;">{{ list.name }}</div></q-item-section>
              <q-item-section side v-show="isManagePriceListVisible" @click.prevent.stop>
                <q-btn size="10px" flat dense round icon="more_vert" />
                <q-menu class="q-pa-sm shadow-brand2">
                  <q-list dense>
                    <q-item clickable v-close-popup>
                      <q-item-section @click="onShareList(list)">Share Price List</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup>
                      <q-item-section @click="onEditList(list)">Edit Price List</q-item-section>
                    </q-item> 
                    <q-item clickable v-close-popup >
                      <q-item-section class="text-red-12" @click="onDeleteList(list)">Delete</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item-section>
            </q-item>
          </template>
        </q-expansion-item>

        <q-expansion-item
          v-show="canViewImportsSection"
          dense
          @update:model-value="bool => setExpansionItemState('imports', bool)"
          :default-opened="getExpansionItemState('imports')"
          header-style="font-size: .9em; margin-left: .4rem; padding-top: 1rem;"
          header-class="text-weight-medium"
          expand-icon-class="text-black"
          style="cursor: default;"
          @mouseover="isManagePriceListVisible = true"
          @mouseout="isManagePriceListVisible = true"
        >
          <template v-slot:header>
            <q-item-section>
              <a style="text-decoration: none; color: inherit;">Imports</a>
            </q-item-section>

            <q-item-section class="show-on-menu-hover" side>
              <a @click.prevent.stop="onUploadCsv()" style="cursor: pointer; color: var(--q-primary); font-size: .85rem;">&uparrow; Upload CSV</a>
            </q-item-section>
          </template>

          <template v-for="list of teamPriceLists" :key="list.id">
            <q-item v-if="isImportList(list)" :to="'/list/' + list.id" active-class="bg-accent text-grey-10" dense>
              <q-item-section avatar style="padding-left: .8rem;">
                <q-icon name="o_insert_drive_file"></q-icon>
                <span style="position: absolute; font-size: .4rem; font-weight: bold; background: white; top: .8rem;">CSV</span>
              </q-item-section>
              <q-item-section class="text-caption text-weight-medium"><div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%;">{{ list.name }}</div></q-item-section>
              <q-item-section v-show="isManagePriceListVisible" side @click.prevent.stop>
                <q-btn size="10px" flat dense round icon="more_vert" />
                <q-menu class="q-pa-sm shadow-brand2">
                  <q-list dense>
                    <q-item v-show="isListRenameActionAllowed(currentUser, list)" clickable v-close-popup>
                      <q-item-section @click="onEditList(list)">Edit Import</q-item-section>
                    </q-item> 
                    <q-item clickable v-close-popup >
                      <q-item-section class="text-red-12" @click="onDeleteList(list)">Delete</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item-section>
            </q-item>
          </template>
        </q-expansion-item>

        <q-expansion-item
          v-show="canViewSharedWithMeSection"
          dense
          @update:model-value="bool => setExpansionItemState('suppliers', bool)"
          :default-opened="getExpansionItemState('suppliers')"
          header-style="font-size: .9em; margin-left: .4rem; padding-top: 1rem;"
          header-class="text-weight-medium"
          expand-icon-class="text-black"
          style="cursor: default;"
          @mouseover="isManagePriceListVisible = true"
          @mouseout="isManagePriceListVisible = true"
        >
          <template v-slot:header>
            <q-item-section to="/lists">
              <a style="text-decoration: none; color: inherit;" to="/lists">Shared with me</a>
            </q-item-section>

            <q-item-section class="show-on-menu-hover" @click.stop side>
              <a href="mailto:?subject=I would like to invite you to use ProductEngine&body=Hi,%0D%0A%0D%0AI would like to invite you to share your price lists directly with me through Product Engine, please register at https://my.productengine.app, upload, and share with me!" style="cursor: pointer; color: var(--q-primary); text-decoration: none; font-size: .85rem;">&plus; Invite Supplier</a>
            </q-item-section>
          </template>

          <template v-for="list of supplierPriceLists" :key="list.id">
            <q-item v-if="isPriceList(list)" :to="'/list/' + list.id" active-class="bg-accent text-grey-10" dense>
              <q-item-section avatar style="padding-left: .8rem;">
                <q-icon name="o_cloud"></q-icon>
                <q-tooltip v-if="list.sharedWith.length">Shared with {{ list.sharedWith.length }} team{{ list.sharedWith.length !== 1 ? 's' : '' }}</q-tooltip>
              </q-item-section>
              <q-item-section class="text-caption text-weight-medium"><div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%;">{{ list.name }}</div></q-item-section>
              <q-item-section side v-show="isManagePriceListVisible" @click.prevent.stop>
                <q-btn size="10px" flat dense round icon="more_vert" />
                <q-menu class="q-pa-sm shadow-brand2">
                  <q-list dense>
                    <q-item clickable v-close-popup >
                      <q-item-section class="text-red-12" @click="onRemoveUserFromList(list)">Remove</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item-section>
            </q-item>
          </template>
        </q-expansion-item>
      </div>

      <q-expansion-item
        style="margin-top: auto; border-top: 1px solid #efefef; padding-top: .8rem; padding-bottom: 2.8rem;"
        @update:model-value="bool => setExpansionItemState('profile', bool)"
        :default-opened="getExpansionItemState('profile')"
      >
        <template v-slot:header>
          <q-item-section avatar>
            <q-avatar size="md" class="bg-grey-5" style="color: white;">
              <span>{{ currentUser?.displayName?.[0]?.toUpperCase() }}</span>
            </q-avatar>
          </q-item-section>

          <q-item-section class="text-caption" style="white-space: nowrap;">
            <div class="text-weight-medium text-dark" style="width: 100%; overflow: hidden; text-overflow: ellipsis;">{{ currentUser?.displayName }}</div>
            <div v-if="currentUser?.displayName !== currentUser?.email" class="text-grey-5" style="width: 100%; overflow: hidden; text-overflow: ellipsis;">{{ currentUser?.email }}</div>
          </q-item-section>
        </template>

        <!-- Help & Support -->
        <q-item to="/" active-class="bg-accent text-grey-10">
          <q-item-section avatar style="padding-left: .4rem;">
            <q-icon name="o_contact_support" class="text-weight-bold" size="1.2rem" />
          </q-item-section>
          <q-item-section class="text-caption text-weight-medium">
            Help & Support
          </q-item-section>
        </q-item>

        <!-- My Profile -->
        <q-item v-show="canViewMyProfileSection" dense to="/profile" active-class="bg-accent text-grey-10">
          <q-item-section avatar style="max-width: 17px; padding-left: .4rem;">
            <q-icon name="o_person" class="text-weight-bold" size="1.2rem" />
          </q-item-section>
          <q-item-section class="text-caption text-weight-medium">
            My Profile
          </q-item-section>
        </q-item>

        <!-- My Team -->
        <q-item v-show="canViewMyTeamSection" dense to="/team" active-class="bg-accent text-grey-10">
          <q-item-section avatar style="max-width: 17px; padding-left: .4rem;">
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 11.7143V13H16V11.7143C16 9.57143 14.504 7 10.28 7C6.056 7 5 10.1429 5 11.7143Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="10.5" cy="3.5" r="2.5" stroke="currentColor" stroke-width="2"/>
              <path d="M1 11.7143V13H12V11.7143C12 9.57143 10.504 7 6.28 7C2.056 7 1 10.1429 1 11.7143Z" fill="white" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="6.5" cy="3.5" r="2.5" fill="white" stroke="currentColor" stroke-width="2"/>
            </svg>
          </q-item-section>
          <q-item-section class="text-caption text-weight-medium">My Team</q-item-section>
          <q-item-section class="show-on-menu-hover" to: v-if="canUploadFile" clickable side>
            <a v-if="currentTeam.isActive" @click.prevent.stop="onInviteTeamMember()" style="cursor: pointer; color: var(--q-primary); font-size: .85rem;">&plus; Invite Member</a>
          </q-item-section>
          <q-badge v-if="!currentTeam.id" outline color="primary" @click.prevent.stop="onCreateSubscription()">Start a free trial</q-badge>
          <q-badge v-if="currentTeam.id && !currentTeam.isActive" outline color="red" @click.prevent.stop="openBillingPortal()">Manage Subscription</q-badge>
        </q-item>

        <!-- Integrations -->
        <q-item v-show="canViewIntegrationsSection" dense to="/integrations" active-class="bg-accent text-grey-10">
          <q-item-section avatar style="max-width: 17px; padding-left: .4rem;">
            <q-icon name="o_extension" class="text-weight-bold" size="1.2rem" />
          </q-item-section>
          <q-item-section class="text-caption text-weight-medium">Integrations</q-item-section>
        </q-item>

        <!-- Sign Out -->
        <q-item clickable @click.prevent.stop="signOutUser()" active-class="bg-accent text-grey-10" style="margin-bottom: 2rem;">
          <q-item-section avatar style="padding-left: .4rem;">
            <q-icon name="o_logout" class="text-weight-bold" size="1.2rem" />
          </q-item-section>
          <q-item-section class="text-caption text-weight-medium">
            Sign Out
          </q-item-section>
        </q-item>

        <q-item style="min-height: 1rem;"></q-item>

      </q-expansion-item>
    </div>

    <!-- Main Menu Resize Handle-->
    <div
      id="MainMenuHandle"
      @dblclick="resetMainMenuWidth()"
      style="
        position: absolute;
        top: 0px;
        right: 0px;
        width: 10px;
        height: 100%;
        cursor: ew-resize;
        "
    ></div>
  </div>
  <!-- End Main Menu -->
    <div style="transition: padding .3s ease-in-out; width: 100%; overflow: auto; height: 100%;" :style="isMainMenuOpen ? `padding-left: ${mainMenuWidth}px` : `0px`">
      <slot />
    </div>
  </div>
  <view-modify-modal></view-modify-modal>
  <price-list-modify-modal></price-list-modify-modal>

  <!-- End Main Layout-->

  <!-- File uploader -->
  <input
    type="file"
    id="mainMenuUploader"
    @change="onUploadFile"
    style="position: absolute; top: -1000px; left: -1000px;"
    accept="text/csv, text/tsv"
  />
</template>

<style scoped>
.main-menu {
  z-index: 999;
  transition: margin .3s ease-in-out;
  overflow-x: hidden;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  user-select: none;
  position: relative;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
}

.main-menu .show-on-menu-hover {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.main-menu:hover .show-on-menu-hover {
  opacity: 1;
}

</style>

<script setup>

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'
import router from '../router'

import ViewModifyModal from '../components/sheet/ViewModifyModal.vue'
import PriceListModifyModal from '../components/sheet/PriceListModifyModal.vue'

// Actions
import { currentUser, signOutUser } from '../actions/current-user.mjs'
import { isMainMenuOpen, toggleMainMenu } from '../ui/main-menu.mjs'
import { openShareList, openRemoveUserFromList } from '../list-price-list/uis/price-list-index-ui.mjs'

// notification
import { raiseConfirmDelete } from '../notify/raise-confirm-delete.mjs'
// Permissions
import { isExportActionAllowed, isListRenameActionAllowed } from '../list-core/permissions/list-permissions.mjs'
import { 
  canViewLibrary,
  canViewPriceLists,
  canViewImports,
  canViewSharedWithMe,
  canViewMyProfile,
  canViewMyTeam,
  canViewIntegrations,
  canUploadCsv,
} from '../ui/ui-states.mjs'

import { teamPriceLists, supplierPriceLists, currentTeam } from '../ui/ui-states.mjs'

// session events
import { dispatch } from '../events/dispatch.mjs'
import { 
  EVENT_UPLOAD_CSV_LOCAL,
  EVENT_OPEN_MODIFY_VIEW,
  EVENT_OPEN_MODIFY_PRICE_LIST,
  EVENT_LIST_DELETE_LOCAL,
} from '../events/constants/event-types.mjs'

import {
  startMainMenuEvents,
  stopMainMenuEvents,
} from '../list-core/events/main-menu-event-handlers.mjs'

import { openBillingPortal } from '../team/actions/team-ui.mjs'

const canUploadFile = canUploadCsv
const canInviteSupplier = canUploadCsv
const isManagePriceListVisible = ref(true)
const mainMenuWidth = ref(getMainMenuWidth())
const canViewLibrarySection = canViewLibrary
const canViewPriceListsSection = canViewPriceLists
const canViewImportsSection = canViewImports
const canViewSharedWithMeSection = canViewSharedWithMe
const canViewMyProfileSection = canViewMyProfile
const canViewMyTeamSection = canViewMyTeam
const canViewIntegrationsSection = canViewIntegrations

let menuResizeDebounce = null

onMounted(() => {
  const MainMenu = document.querySelector('#MainMenu')
  const MainMenuHandle = document.querySelector('#MainMenuHandle')
  if (!MainMenu || !MainMenuHandle) {
    return
  }
  let isMouseDown = false
  MainMenuHandle.addEventListener('mousedown', () => {
    isMouseDown = true
  })
  addEventListener('mousemove', event => {
    let newWidth = event.clientX
    if (!isMouseDown) {
      return
    }
    if (newWidth < 300) {
      newWidth = 300
    }
    if (newWidth > 800) {
      newWidth = 800
    }
    MainMenu.style.width = newWidth + 'px'
    if (menuResizeDebounce) {
      clearTimeout(menuResizeDebounce)
    }
    menuResizeDebounce = setTimeout(() => {
      localStorage.setItem('main-menu-width', String(newWidth))
      mainMenuWidth.value = String(newWidth)
    }, 10)
  })
  addEventListener('mouseup', event => {
    isMouseDown = false
  })

  startMainMenuEvents()
})

onUnmounted(() => {
  stopMainMenuEvents()
})

/**
 * Get the main menu width
 */
function getMainMenuWidth() {
  return Number(localStorage.getItem('main-menu-width') || 400)
}

/**
 * Reset the main menu width
 */
function resetMainMenuWidth() {
  const MainMenu = document.getElementById('MainMenu')
  localStorage.setItem('main-menu-width', '400')
  MainMenu.style.width = '400px'
  mainMenuWidth.value = String('400')
}

/**
 * Set expansion item states
 */
function setExpansionItemState(key, bool) {
  localStorage.setItem('expand-' + key, bool.toString())
}

/**
 * Get expansion item states
 */
 function getExpansionItemState(key) {
  return localStorage.getItem('expand-' + key) === 'false'
    ? false
    : true
}

/**
 * Toggle menu handler
 */
function onToggleMenu() {
  toggleMainMenu()
}

/**
 * Check if a price list is a View
 * TODO: move out into utils
 */
function isViewList(list) {
  return list.type === 'Price List' && !list.isStatic
}

/**
 * Check if a price list is a price list
 * TODO: move out into utils
 */
function isPriceList(list) {
  return list?.type === 'Price List' && list.isStatic
}

/**
 * Check if a price list is a price list
 * TODO: move out into utils
 */
 function isImportList(list) {
  return list?.type === 'CSV Price List'
}



/**
 * Invite team member handler
 */
function onInviteTeamMember() {
  router.push('/team#invite')
}

/**
 * Share list handler
 */
function onShareList(list) {
  return openShareList(list)
}

/**
 * Delete list handler
 */
function onDeleteList(list) {
  raiseConfirmDelete(
    `Are you sure you wish to delete "${list.name}"?`,
    () => {
      dispatch(EVENT_LIST_DELETE_LOCAL, { list })
    }
  )
  return
}

/**
 * Remove user from list
 */
function onRemoveUserFromList(list) {
  return openRemoveUserFromList(list)
}

/**
 * Open file handler 
 */
function onUploadCsv() {
  const uploader = document.getElementById('mainMenuUploader')
  uploader.value = '' // this is so change detection works
  uploader.click()
}

/**
 * File upload handler
 */
function onUploadFile(event) {
  event.preventDefault()
  event.stopPropagation()

  const files  = event.target.files || event.dataTransfer.files
  const file   = files[0]
  dispatch(EVENT_UPLOAD_CSV_LOCAL, {
    file,
    isNew: true
  })
  return
}

/**
 * Open edit List handler
 */
function onEditList(list) {
  dispatch(EVENT_OPEN_MODIFY_PRICE_LIST, { listId: list.id })

}

/**
 * Open edit View handler
 */
function onEditView(list) {
  dispatch(EVENT_OPEN_MODIFY_VIEW, { listId: list.id })

  //openModifyView(list)
}

/**
 * Create subscription handler
 */
 function onCreateSubscription() {
  router.push('/team')
}
</script>