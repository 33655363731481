<template>
  <q-dialog v-model="isSendInviteOpen">
    <q-card style="border-radius: 20px; width: 200rem;" class="q-pa-md">
      <q-card-section>
        <div class="text-h6">Invite Team Members</div>
      </q-card-section>

      <form @submit.prevent="onTeamInviteFormSubmit">
        <q-card-section class="q-pt-none">
          <p11e-email-list
            :inviteList="emailList"
            @removeEmail="onRemoveEmail"
            @addEmail="onAddEmail" />
        </q-card-section>

        <q-card-actions align="right" class="q-mb-sm q-mr-sm">
          <q-btn
            :disable="!emailList.length"
            no-caps
            unelevated
            type="submit"
            class="q-ml-sm rounded-borders"
            color="primary"
            text-color="white"
            label="Done"
            style="width: 6.3rem;"
            v-close-popup
          />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isDeleteInviteOpen">
    <q-card style="border-radius: 20px; width: 25rem;">
      <q-card-section>
        <div class="text-h6">Revoke Invite?</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-justify text-body2">
          Are you sure you wish to revoke this invite?
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-mb-sm q-mr-sm">
        <q-btn
          no-caps
          unelevated
          class="rounded-borders"
          color="accent"
          text-color="dark"
          label="Cancel"
          style="width: 6.3rem;"
          v-close-popup
        />
        <q-btn
          @click="deleteInvite(selectedInvite.id)"
          no-caps
          unelevated
          class="q-ml-sm rounded-borders"
          color="red-12"
          text-color="white"
          label="Revoke"
          style="width: 6.3rem;"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isRemoveMemberConfirmationOpen">
    <q-card style="border-radius: 20px; width: 25rem;">
      <q-card-section>
        <div class="text-h6">Remove Member?</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-justify text-body2">
          Are you sure you wish to revoke this member?
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-mb-sm q-mr-sm">
        <q-btn
          no-caps
          unelevated
          class="rounded-borders"
          color="accent"
          text-color="dark"
          label="Cancel"
          style="width: 6.3rem;"
          v-close-popup
        />
        <q-btn
          @click="removeOneTeamMember(selectedMember.userId)"
          no-caps
          unelevated
          class="q-ml-sm rounded-borders"
          color="red-12"
          text-color="white"
          label="Revoke"
          style="width: 6.3rem;"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>

// Dependenceis
import { ref } from 'vue'

// Actions
import { isSendInviteOpen, isDeleteInviteOpen, selectedInvite, deleteInvite, inviteTeamMembersByEmailList, teamInvites } from '../email-invites/actions/invites.mjs'
import { removeOneTeamMember } from '../team/actions/team-ui.mjs'
import { isRemoveMemberConfirmationOpen, selectedMember } from '../ui/ui-states.mjs'


// Utils
import { isEmail } from '../utils/is.mjs'

// Refs
const emailList = ref([])

function onTeamInviteFormSubmit() {
  inviteTeamMembersByEmailList(emailList.value)
  emailList.value = []
}

// Add new email
function onAddEmail(newInvitee) {
  const newEmail = newInvitee.email
  if (!isEmail(newEmail) || emailList.value.includes(newEmail) || teamInvites.value.findIndex(x => x.receiverEmail === newEmail) != -1 || newEmail === '') {
    return
  }
  emailList.value.push(newInvitee)
}

// Remove email
function onRemoveEmail(invitee) {
  const index = emailList.value.findIndex(x => x === invitee.email)
  emailList.value.splice(index, 1)
}

</script>
