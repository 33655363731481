

// utils
import { required } from '../../utils/required.mjs'
import { throwError } from '../../utils/errors.mjs'
import { getUpdateInterval } from '../../utils/update-interval.mjs'
// collections
import { COLLECTION_ITEMS } from '../../db/db-collections.mjs'

// firestore
import { getDb } from '../../db/DbInstance.mjs'
import { collection, getDocs, where, query, orderBy, limit, startAfter } from 'firebase/firestore'

// local db
import { getLocalDb, bulkPut } from '../../db-local/local-db.mjs'

// sessions
import { getObjectSession, setObjectSession } from '../../session/object-session.mjs'

// entities
import { ItemEntity } from '../../entity/ItemEntity.mjs'

import {
  LOCAL_READ_BATCH_SIZE,
  REMOTE_READ_BATCH_SIZE,
  SAVE_ITEMS_BATCH_SIZE,
  COMMIT_BATCH_SIZE,
} from '../../constants/batch-sizes.mjs'

import { formatItemToLocalDbItem, formatLocalDbItemToItem } from '../utils/format-local-db.mjs'

export const bulkPutItems = async (COLLECTION_ITEMS, items) => {
  let localItems = []
  for (const item of items) {
    localItems.push(formatItemToLocalDbItem(item))
  }
  return bulkPut(COLLECTION_ITEMS, localItems)
}

export const loadDeltaLibraryItems = async (libraryId) => {
  const listSession = await getObjectSession(libraryId)
  const updatedAtInterval = listSession.data?.updatedAtInterval || 0
  console.log('load from updatedAtInterval', updatedAtInterval)
  const startTime = Date.now()
  const db = getDb()
  const localDb = getLocalDb(COLLECTION_ITEMS)
  let cnt = 0
  let first = query(collection(db, COLLECTION_ITEMS),
    where('libraryListId', '==', libraryId),
    where('updatedAtInterval', '>=', updatedAtInterval),
    orderBy('updatedAtInterval'),
    limit(REMOTE_READ_BATCH_SIZE)
  )

  let snapshots = await getDocs(first)
  while (snapshots.docs.length > 0) {
    let localItems = []
    const lastItem = snapshots.docs[snapshots.docs.length - 1]
    snapshots.forEach(rec => {
      const data = rec.data()
      localItems.push(formatItemToLocalDbItem(data))
      cnt++
    })
    const startTime = Date.now()
    await bulkPutItems(COLLECTION_ITEMS, localItems)
    const next = query(collection(db, COLLECTION_ITEMS),
      where('libraryListId', '==', libraryId),
      where('updatedAtInterval', '>=', updatedAtInterval),
      orderBy('updatedAtInterval'),
      startAfter(lastItem),
      limit(REMOTE_READ_BATCH_SIZE)
    )
    snapshots = await getDocs(next)
    localItems = []
  }
  listSession.data.updatedAtInterval = getUpdateInterval()
  setObjectSession(listSession)
  console.log('Latest remote items -> local db:', cnt, 'in', Date.now() - startTime, 'ms')
  return
}

/**
 * 
 * @param {*} libraryId 
 * @returns 
 */

export const loadDeltaStaticListItems = async (listId) => {
  const listSession = await getObjectSession(listId)
  const updatedAtInterval = listSession.data.updatedAtInterval || 0
  const startTime = Date.now()
  const db = getDb()
  const localDb = getLocalDb(COLLECTION_ITEMS)
  let cnt = 0
  let first = query(collection(db, COLLECTION_ITEMS),
    where('lists', 'array-contains', listId),
    where('updatedAtInterval', '>=', updatedAtInterval),
    orderBy('updatedAtInterval'),
    limit(REMOTE_READ_BATCH_SIZE)
  )

  let snapshots = await getDocs(first)
  while (snapshots.docs.length > 0) {
    let localItems = []
    const lastItem = snapshots.docs[snapshots.docs.length - 1]
    snapshots.forEach(rec => {
      const data = rec.data()
      localItems.push(formatItemToLocalDbItem(data))
      cnt++
    })
    const startTime = Date.now()
    await bulkPutItems(COLLECTION_ITEMS, localItems)
    const next = query(collection(db, COLLECTION_ITEMS),
      where('lists', 'array-contains', listId),
      where('updatedAtInterval', '>=', updatedAtInterval),
      orderBy('updatedAtInterval'),
      startAfter(lastItem),
      limit(REMOTE_READ_BATCH_SIZE)
    )
    snapshots = await getDocs(next)
    localItems = []
  }
  listSession.data.updatedAtInterval = getUpdateInterval()
  setObjectSession(listSession)
  console.log('Latest remote items -> local db:', cnt, 'in', Date.now() - startTime, 'ms')
  return
}

/**
 * get local items
 * @param {*} param0 
 * @returns 
 */
export const getLocalItems = async ({
  libraryListId,
  listId,
  batchCallBack
}) => {
  if (!libraryListId && !listId) {
    required('libraryId or listId')
  }
  const indexName = libraryListId ? 'libraryListId' : 'lists'
  const lookupId = libraryListId ? libraryListId : listId
  const itemLocalDb = getLocalDb(COLLECTION_ITEMS)
  const dbIndex = await itemLocalDb.index(indexName)
  let range = IDBKeyRange.only(lookupId)
  await new Promise((resolve, reject) => {
    let cnt = 0
    let items = []
    dbIndex.openCursor(range).onerror = (e) => {
      console.error('localdb openCursor failed')
      reject(e)
    }
    dbIndex.openCursor(range).onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        const item = formatLocalDbItemToItem(cursor.value)
        if (
          (
            (libraryListId && item.libraryListId === libraryListId)
            || (listId && item.lists.includes(listId))
          )
        ) {
          items.push(new ItemEntity(item))
          cnt++
        }
        if (items.length >= LOCAL_READ_BATCH_SIZE) {
          batchCallBack([...items])
          items = []
        }
        cursor.continue()
      } else {
        if (items.length > 0) {
          batchCallBack([...items])
          items = undefined
        }
        resolve()
      }
    }
  })
}

