/*!
 * billing actions
 */

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../db/db-constants.mjs'

// constants
import { ACTION_GET_BILLING_PORTAL_API_KEY } from '../../constants/stripe.mjs'

//#region billing actions

const app = getApp()
const CALLABLE_BILLING = 'billingPortal'

let billingRpc = null

/**
 * get the billing rpc function
 * @returns 
 */
function getBillingRpc() {
  if (billingRpc) {
    return billingRpc
  }
  const functions = getFunctions(app, AU_REGION)
  billingRpc = httpsCallable(functions, CALLABLE_BILLING, {timeout: 540000 } )
  return billingRpc
}

/**
 * use cloud function to get billing portal api key based on the location of the application environment
 * @param {Object} location.hostname - the hostname of the location object
 */
async function getBillingPortalApiKey_cloudfn(location) {
  const billingPortalCloudFn = getBillingRpc()
  const result = await billingPortalCloudFn({
    action: ACTION_GET_BILLING_PORTAL_API_KEY,
    payload: {
      location: location,
    }
  })
  return result
}

//#endregion
export { getBillingPortalApiKey_cloudfn,
}
