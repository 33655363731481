<template>
  <main-layout>
    <template v-if="isTeamLoaderLoading">
      <div 
        style="
        position: absolute; 
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        width: 100%; height: 100%; 
        top:0; left: 0; 
        z-index: 99; 
        background-color: rgba(255, 255, 255, 0.6);
        margin-left: 150px;">
        <div class="is-loading"></div>
        <div style="line-height: 3rem;">Thank you for joining. Please wait a few minutes while team is getting ready. </div>
      </div>
    </template>
    <q-toolbar class="row items-center q-px-md q-pt-lg q-pb-xs">
      <q-toolbar-title class="text-dark text-weight-bold text-center">{{ $route.meta.title }}</q-toolbar-title>
    </q-toolbar>
    <q-tabs
      v-model="teamTab"
      class="text-grey-5 text-weight-medium"
      indicator-color="primary"
      active-color="dark"
      style="border-bottom: 1px solid #EBEEF5;"
      align="center"
    >
      <q-route-tab no-caps to="/team" name="overview" label="Overview" class="q-px-lg" />
      <q-route-tab no-caps @click="openBillingPortal" name="manage subscription" class="q-px-lg" v-if="currentTeam.id && canViewBilling">
        <div>Manage Subscription
        <q-badge v-show="!currentTeam.isActive" outline align="middle" color="red" class="q-mb-xs">{{ currentTeam.planStatus }}</q-badge>
      </div>
      </q-route-tab>
    </q-tabs>
    <q-tab-panels v-model="teamTab" animated>
      <q-tab-panel class="row justify-center items-center" name="overview" style="margin-top: 2rem;">
        <div style="width: 60rem;">
          <template v-if="!currentTeam.id">
<!--             <q-btn @click="onCreateTeam"
              class="q-ml-sm rounded-borders" color="primary" text-color="white" style="width: 10rem;"
              no-caps unelevated v-close-popup type="submit" label="Create a Team"
            /> -->
            <stripe-pricing-table
              :pricing-table-id="stripePricingTableId"
              :publishable-key="stripePubishableKey"
              :client-reference-id="currentUser.id"
              :customer-email="currentUser.email"
            >
            </stripe-pricing-table>
          </template>
          <template v-if="currentTeam.id && canViewSettings">
            <div class="text-body1 text-weight-medium">Team Settings</div>
            <!-- <div class="row justify-start items-center q-mt-md">
              <q-avatar rounded class="q-mr-sm" color="grey-1" style="border: 1px solid #EBEEF5;" />
              <q-btn
                no-caps
                flat
                color="primary"
                label="Edit logo"
              />
            </div> -->
            <div style="margin-top: 1.4rem;">
              <label>Team Name</label>
            </div>
            <div style="margin-top: .5rem; display: flex; flex-wrap: nowrap;">
              <q-input v-model="currentTeam.name" color="grey-7" bg-color="accent" outlined class="rounded-borders team-input-wrapper" style="width: 100%;" dense :autofocus=true placeholder="[Company Name]"/>
              <q-btn
                v-if="currentTeam.isActive && canRenameTeam"
                @click="onUpdateTeam"
                no-caps
                unelevated
                type="submit"
                class="q-ml-sm rounded-borders"
                color="primary"
                text-color="white"
                label="Done"
                style="width: 6.3rem;"
                v-close-popup
              />
            </div>
          </template>
          <template v-if="currentTeam.isActive && canViewMemberList">
            <div class="row justify-between items-center full-width q-mb-lg">
              <div class="text-body1 text-weight-medium q-mt-xl q-mb-lg">Active Members ({{ members.length }})</div>
              <table v-if="members.length" cellspacing="0" cellpadding="0" class="fit">
                <thead>
                  <tr>
                    <th
                      v-for="column in memberColumns"
                      :key="column"
                      class="text-left text-caption text-weight-medium text-grey-5 q-px-xs q-pb-sm"
                      style="border-bottom: 1px solid #EBEEF5;"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="member in members" :key="member">
                    <tr style="height: 3rem;" @mouseenter="member.isActionButtonVisible=true && canRemoveMember" @mouseleave="member.isActionButtonVisible=false">
                      <td class="text-body2 text-weight-medium" style="border-bottom: 1px solid #EBEEF5;">
                        <div class="row items-center">
                          <q-avatar size="md" class="bg-grey-5" style="color: white; margin-right: 1rem;">
                            <span>{{ member?.displayName?.[0]?.toUpperCase() }}</span>
                          </q-avatar>
                          <div>{{ member.displayName }}</div>
                        </div>
                      </td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ member.email }}</td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">
                        <div style="width: 100px">
                          <q-select v-if="canChangeMemberPermission && currentUser.id != member.userId" v-model="member.role" :options="userRoleOptions" @update:model-value="onMemberRoleChange(member)" borderless dense></q-select>
                          <span v-else>{{ member.role }}</span>
                        </div>
                      </td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ formatDate(member.acceptedAt) }}</td>
                      <td class="q-pr-sm" style="width: 2.5rem; border-bottom: 1px solid #EBEEF5;">
                        <div class="column justify-center">
                          <q-btn v-if="member.isActionButtonVisible && currentUser.id != member.userId" size="12px" flat dense round icon="more_vert" />
                            <q-menu class="q-pa-sm shadow-brand2" v-model="member.isActionMenuOpen">
                              <q-list style="min-width: 200px" dense>
                                <q-item clickable v-close-popup class="rounded-borders">
                                  <q-item-section class="text-red-12 text-weight-medium" @click="onRemoveTeamMember(member)">Remove Member</q-item-section>
                                </q-item>
                              </q-list>
                            </q-menu>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row justify-between items-center full-width q-mb-lg">
              <div class="text-body1 text-weight-medium q-mt-xl q-mb-lg">Pending Invitations ({{ teamInvites.length }})</div>
              <q-btn
                v-if="canInviteMember"
                @click="onSendInvitation"
                no-caps
                unelevated
                class="q-ml-sm rounded-borders"
                color="primary"
                text-color="white"
                label="Invite Team Member"
              />
              <table v-if="teamInvites.length" cellspacing="0" cellpadding="0" class="fit">
                <thead>
                  <tr>
                    <th
                      v-for="column in inviteColumns"
                      :key="column"
                      class="text-left text-caption text-weight-medium text-grey-5 q-px-xs q-pb-sm"
                      style="border-bottom: 1px solid #EBEEF5;"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="invitation in teamInvites" :key="invitation">
                    <tr style="height: 3rem;" @mouseenter="invitation.isActionButtonVisible=true && canRevokeInvite" @mouseleave="invitation.isActionButtonVisible=false">
                      <td class="text-body2 text-weight-medium" style="border-bottom: 1px solid #EBEEF5;">
                        <div class="row items-center">
                          <q-avatar size="md" class="bg-grey-5" style="color: white; margin-right: 1rem;">
                            <span>{{ invitation?.name?.[0]?.toUpperCase() }}</span>
                          </q-avatar>
                          <div>{{ invitation.name }}</div>
                        </div>
                      </td> 
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ invitation.receiverEmail }}</td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ invitation.role }}
                        <div style="width: 100px">
                        </div>
                      </td>
                      <td class="text-body2" style="border-bottom: 1px solid #EBEEF5;">{{ formatDate(invitation.expiresAt) }}</td>
                      <td class="q-pr-sm" style="width: 2.5rem; border-bottom: 1px solid #EBEEF5;">
                        <div class="column justify-center">
                          <q-btn v-if="invitation.isActionButtonVisible" size="12px" flat dense round icon="more_vert" />
                            <q-menu class="q-pa-sm shadow-brand2" v-model="invitation.isActionMenuOpen">
                              <q-list style="min-width: 200px" dense>
                                <q-item clickable v-close-popup class="rounded-borders">
                                  <q-item-section class="text-red-12 text-weight-medium" @click="onDeleteInvitation(invitation)">Revoke Invitation</q-item-section>
                                </q-item>
                              </q-list>
                            </q-menu>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
        </template>
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <team-modal></team-modal>
  </main-layout>
</template>

<style scoped>
  .table {
    border-left: 0;
    border-right: 0;
  }

  @media only screen and (max-width: 768px) {
    .team-wrapper {
      justify-content: left;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .team-input-wrapper {
    width: 24rem;
  }

  @media only screen and (max-width: 767px) {
    .team-input-wrapper {
      width: 12rem;
    }
  }
</style>

<script setup>

// Styles
import '../styles/theme.css'

// Dependencies
import { ref, onMounted, onUnmounted } from 'vue'

// Layouts
import MainLayout from '../layouts/MainLayout.vue'

// Components
import TeamModal from '../components/TeamModal.vue'

//utils
import { formatDate } from '../utils/format.mjs'

// Actions
import { inviteColumns, onSendInvitation, onDeleteInvitation, teamInvites } from '../email-invites/actions/invites.mjs'
import { 
  onRemoveTeamMember, members, onMemberRoleChange,
  updateCurrentTeam, onTeamStart, onTeamStop, createDefaultTeam, openBillingPortal, isTeamLoaderLoading } from '../team/actions/team-ui.mjs'
import { currentUser } from '../actions/current-user.mjs'
import { memberColumns, teamTab } from '../actions/current-team.mjs'
import { currentTeam } from '../ui/ui-states.mjs'
import { USER_ROLES } from '../permissions/constants/roles.mjs'
import {
  canViewTeamSettings,
  canViewTeamMemberList,
  canChangeTeamName,
  canInviteTeamMember,
  canRemoveTeamInvite,
  canRemoveTeamMember,
  canSetTeamMemberPermission,
  canCreateTeam,
} from '../ui/ui-states.mjs'

//Constants
import { 
  STRIPE_PUBLISHABLE_KEY_HOSTED_TEST, STRIPE_PUBLISHABLE_KEY_LOCAL_TEST, STRIPE_PUBLISHABLE_KEY,
  STRIPE_PRICING_TABLE_HOSTED_TEST, STRIPE_PRICING_TABLE_LOCAL_TEST, STRIPE_PRICING_TABLE,
} from '../constants/stripe.mjs'

// Refs
const canViewSettings = canViewTeamSettings
const canViewBilling = true // todo: make sure only admins can see the billing section
const canViewMemberList = canViewTeamMemberList
const canRenameTeam = canChangeTeamName
const canInviteMember = canInviteTeamMember
const canRevokeInvite = canRemoveTeamInvite
const canRemoveMember = canRemoveTeamMember
const canChangeMemberPermission = canSetTeamMemberPermission
const canCreateDefaultTeam = canCreateTeam
const userRoleOptions = ref(USER_ROLES)
const stripePricingTableId = ref(STRIPE_PRICING_TABLE_LOCAL_TEST)
const stripePubishableKey = ref(STRIPE_PUBLISHABLE_KEY_LOCAL_TEST)


const PRODUCTENGINE_HOST_PROD = 'my.productengine.app'
const PRODUCTENGINE_HOST_DEV_NETWORK = 'localhost:8080'
const PRODUCTENGINE_HOST_DEV_LOCAL = '172.30.1.180:8080'
const PRODUCTENGINE_HOST_DEV_EMULATOR_NETWORK = 'localhost:5080'
const PRODUCTENGINE_HOST_DEV_EMULATOR_LOCAL = '127.0.0.1:5080'
const PRODUCTENGINE_HOST_DEV_EMULATOR_PORT = '5080'

// Setup
onMounted(() => {
  switch (location.host) {
    case PRODUCTENGINE_HOST_PROD: {
      stripePricingTableId.value = STRIPE_PRICING_TABLE
      stripePubishableKey.value = STRIPE_PUBLISHABLE_KEY
      break
    }
    case PRODUCTENGINE_HOST_DEV_NETWORK:
    case PRODUCTENGINE_HOST_DEV_LOCAL: 
    case PRODUCTENGINE_HOST_DEV_EMULATOR_NETWORK:
    case PRODUCTENGINE_HOST_DEV_EMULATOR_LOCAL: {
      if (location.host.includes(PRODUCTENGINE_HOST_DEV_EMULATOR_PORT)) {
        stripePricingTableId.value = STRIPE_PRICING_TABLE_LOCAL_TEST
        stripePubishableKey.value = STRIPE_PUBLISHABLE_KEY_LOCAL_TEST
      } else {
        stripePricingTableId.value = STRIPE_PRICING_TABLE_HOSTED_TEST
        stripePubishableKey.value = STRIPE_PUBLISHABLE_KEY_HOSTED_TEST
      }
      break
    }
    default: {
      stripePricingTableId.value = STRIPE_PRICING_TABLE_LOCAL_TEST
      stripePubishableKey.value = STRIPE_PUBLISHABLE_KEY_LOCAL_TEST
    }
  }
  onTeamStart()
  if (location.hash === '#invite') {
    onSendInvitation()
  }
})

onUnmounted(() => {
  onTeamStop()
})

function onUpdateTeam() {
  updateCurrentTeam({ id: currentTeam.value.id, name: currentTeam.value.name })
}

function onCreateTeam() {
  createDefaultTeam( { name: currentTeam.value.name })
} 

</script>
