// Module imports
import { ref } from 'vue'
/**
 * Refs
 */
export const isMainMenuOpen = ref(
  localStorage.getItem('isMainMenuOpen') === 'false' ? false : true
)

/**
 * Save menu state
 */
export const saveMenuState = () => {
  localStorage.setItem('isMainMenuOpen', isMainMenuOpen.value.toString())
}

/**
 * Open main menu
 */
export const openMainMenu = () => {
  isMainMenuOpen.value = true
  saveMenuState()
}

/**
 * Close main menu
 */
export const closeMainMenu = () => {
  isMainMenuOpen.value = false
  saveMenuState()
}

/**
 * Toggle main menu
 */
export const toggleMainMenu = () => {
  isMainMenuOpen.value
    ? closeMainMenu()
    : openMainMenu()
}
