// Dependencies
import { required } from '../utils/required.mjs'

/**
 * Set session item
 */
function setSessionItem(
  key  = required('key'),
  item = required('item'))
{
  const newSessionItem = JSON.stringify(item)
  sessionStorage.setItem(key, newSessionItem)
}

/**
 * Get session item
 */
function getSessionItem(
  key = required('key')
) {
  const item = sessionStorage.getItem(key)
  return item && JSON.parse(item)
}

function removeSessionItem(
  key = required('key')
) {
  return sessionStorage.removeItem(key)
}
// Exports
export { setSessionItem, getSessionItem, removeSessionItem }
