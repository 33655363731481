

//Constants
import {
  FIELD_TYPE_CHECKBOX, FIELD_TYPE_CURRENCY, FIELD_TYPE_DATE, FIELD_TYPE_NUMBER, FIELD_TYPE_PERCENTAGE, FIELD_TYPE_TAGLIST,
  FILTER_TYPE_ARRAY, FILTER_TYPE_BOOLEAN, FILTER_TYPE_DATE, FILTER_TYPE_NUMBER, FILTER_TYPE_TEXT,
  NUMBER_RULE_CONDITIONS, BOOLEAN_RULE_CONDITIONS, DATE_RULE_CONDITIONS, ARRAY_RULE_CONDITIONS, STRING_RULE_CONDITIONS,
} from '../../constants'
import { KEY_HAS_ERROR, KEY_HAS_WARNING, KEY_HAS_NO_WARNING_OR_ERROR } from '../../constants/filter.mjs'
import { NumberType, TextType, BooleanType, ArrayType, DateType } from '../../field-types'

import { filterTab } from '../../ui/ui-states.mjs'


/**
 * Get data type from field type
 */
function getTypeFromFieldType(fieldType) {
  switch (fieldType) {
    case FIELD_TYPE_NUMBER:
    case FIELD_TYPE_PERCENTAGE:
    case FIELD_TYPE_CURRENCY:
      return FILTER_TYPE_NUMBER
    case FIELD_TYPE_DATE:
      return FILTER_TYPE_DATE
    case FIELD_TYPE_CHECKBOX:
      return FILTER_TYPE_BOOLEAN
    case FIELD_TYPE_TAGLIST:
      return FILTER_TYPE_ARRAY
  }
  return FILTER_TYPE_TEXT
}

/**
 * Get comparison function
 */
function getCompareFunction(filterType) {
  switch (filterType) {
    case FILTER_TYPE_NUMBER:
      return NumberType.compare
    case FILTER_TYPE_BOOLEAN:
      return BooleanType.compare
    case FILTER_TYPE_DATE:
      return DateType.compare
    case FILTER_TYPE_ARRAY:
      return ArrayType.compare
  }
  return TextType.compare
}

function matchFilters(item, filters) {
  for (const filter of filters) {
    const hasMatch = matchFilterRules(filter, item)
    // applies AND between filters. If 1 fails, all fail
    if (!hasMatch) {
      return false
    }
  }
  return true
}

/**
 * Filter a list of items
 */
function matchFilterRules(filter, item) {
  let result = false
  for (const rule of filter.rules) {
    if (
      rule.condition === KEY_HAS_NO_WARNING_OR_ERROR
      && !item.fields[filter.fieldKey].warning
      && !item.fields[filter.fieldKey].error
    ) {
      return true
    }

    if (rule.condition === KEY_HAS_WARNING && item.fields[filter.fieldKey].warning) {
      return true
    }
    if (rule.condition === KEY_HAS_ERROR && item.fields[filter.fieldKey].error) {
      return true
    }
    const compareFunc = getCompareFunction(filter.type)
    // applies OR between rules
    const itemFieldValue = item.fields[filter.fieldKey]?.value?.name ||
      item.fields[filter.fieldKey]?.value
    result = result || compareFunc(itemFieldValue, rule.condition, rule.value)
  }
  return result
}

/**
 * Get filter conditions depending on field type
 */
function getFilterConditionsForType(filterType) {
  switch (filterType) {
    case FILTER_TYPE_NUMBER:
      return NUMBER_RULE_CONDITIONS
    case FILTER_TYPE_BOOLEAN:
      return BOOLEAN_RULE_CONDITIONS
    case FILTER_TYPE_DATE:
      return DATE_RULE_CONDITIONS
    case FILTER_TYPE_ARRAY:
      return ARRAY_RULE_CONDITIONS
    case FILTER_TYPE_TEXT:
    default:
      return STRING_RULE_CONDITIONS
  }
}

/**
 * Set filter tab
 */
function setFilterTab(value) {
  filterTab.value = value
}

export {
  getTypeFromFieldType, getCompareFunction, matchFilters, getFilterConditionsForType, setFilterTab
}