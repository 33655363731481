/**
 * Is admin or editor user role
 */

import { isAdminUser, isEditorUser } from './index.mjs'

const isAdminOrEditorUser = (user) => {
  return isAdminUser(user)
    || isEditorUser(user)
}

export { isAdminOrEditorUser }
