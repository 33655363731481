// Dependencies
import { Notify } from 'quasar'

// Constants
const NOTIFY_DEFAULT_OPTIONS = {
  position: 'bottom',
  icon: 'o_info',
  color: 'primary'
}

/**
 * Create notification
 */
function createNotification(
  options
) {
  return Notify.create({
    ...NOTIFY_DEFAULT_OPTIONS,
    ...options
  })
}

// Module exports
export { createNotification }
