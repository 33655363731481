
import { TRUE, FALSE } from '../../constants/types.mjs'
import { ADMIN_USER_ROLE, EDITOR_USER_ROLE, VIEWER_USER_ROLE } from '../../permissions/constants/roles.mjs'
import { TEAM_PLAN_FREE } from '../../constants/team.mjs'

import {
  canViewLibrary,
  canViewPriceLists,
  canViewImports,
  canViewSharedWithMe,
  canViewMyProfile,
  canViewMyTeam,
  canViewIntegrations,
} from '../../ui/ui-states.mjs'
import { getTeamById } from '@/team/actions/team-actions.mjs'

const MAINLAYOUT_ACTION_SETTINGS = {
  plan : {
    editor : {
      canViewLibrary: TRUE,
      canViewPriceLists: TRUE,
      canViewImports: TRUE,
      canViewSharedWithMe: TRUE,
      canViewMyProfile: TRUE,
      canViewMyTeam: TRUE,
      canViewIntegrations: TRUE,
    },
    viewer : {
      canViewLibrary: FALSE,
      canViewPriceLists: FALSE,
      canViewImports: FALSE,
      canViewSharedWithMe: TRUE,
      canViewMyProfile: TRUE,
      canViewMyTeam: TRUE,
      canViewIntegrations: FALSE,
    }
  },
  default : {
    viewer : {
      canViewLibrary: FALSE,
      canViewPriceLists: FALSE,
      canViewImports: FALSE,
      canViewSharedWithMe: TRUE,
      canViewMyProfile: TRUE,
      canViewMyTeam: TRUE,
      canViewIntegrations: FALSE,
    }
  }
}

/**
 * Get the permission of UI elements by team and user role
 * @param {Object} team - the team
 * @param {string} team.plan - the plan subscribed by the team
 * @param {string} team.isActive - the team active status
 * @param {string} userRole - the role of the user in team
 * @returns {Object} main layout permissions
 */
function getMainLayoutUiActionPermissions(team, userRole) {
  const teamPlan = team?.plan
  // team is inactive or no team
  if (!team?.isActive) {
    return MAINLAYOUT_ACTION_SETTINGS.default.viewer
  }
  // free plan
  if (teamPlan === TEAM_PLAN_FREE) {
    switch (userRole) {
      case ADMIN_USER_ROLE:
        return MAINLAYOUT_ACTION_SETTINGS.plan.editor
      case EDITOR_USER_ROLE:
      case VIEWER_USER_ROLE:
        return MAINLAYOUT_ACTION_SETTINGS.default.viewer
    }
  }
  // other plans including teams without team.plan
  if (teamPlan !== TEAM_PLAN_FREE || !teamPlan) {
    switch (userRole) {
      case ADMIN_USER_ROLE:
      case EDITOR_USER_ROLE:
        return MAINLAYOUT_ACTION_SETTINGS.plan.editor
      case VIEWER_USER_ROLE:
        return MAINLAYOUT_ACTION_SETTINGS.plan.viewer
    }
  }
  // no user role
  return MAINLAYOUT_ACTION_SETTINGS.default.viewer
}

/**
 * Sets the permissions of MainLayout UI elements
 * @param {Object} user - user
 * @param {string} user.teamId - the user's team
 * @param {string} user.role - the role of the user in team
 */
async function setMainLayoutUiActionPermissionStates(user) {
  const team = user.teamId ? await getTeamById(user.teamId) : null
  const permissions = getMainLayoutUiActionPermissions(team, user.role)
  canViewLibrary.value = permissions.canViewLibrary
  canViewPriceLists.value = permissions.canViewPriceLists
  canViewImports.value = permissions.canViewImports
  canViewSharedWithMe.value = permissions.canViewSharedWithMe
  canViewMyProfile.value = permissions.canViewMyProfile
  canViewMyTeam.value = permissions.canViewMyTeam
  canViewIntegrations.value = permissions.canViewIntegrations
}

export { getMainLayoutUiActionPermissions, setMainLayoutUiActionPermissionStates }
