/*!
 * ColumnEntity
 */

// Entities
import { Entity } from './Entity.mjs'
import { FieldSchemaEntity } from './FieldSchemaEntity.mjs'

// constants
import { TRUE, FALSE } from '../constants'

// utils
import { isEmpty, deepClone } from '@/utils'

// field schema utils
import { generateFieldKeyFromLabel } from '../field-schema'
import { required } from '@/utils/required.mjs'


export class ColumnEntity extends Entity {
  #teamId
  #label
  #key
  #isHidden
  #isPinned
  #isResizable
  #isSortable
  #width
  #maxWidth
  #minWidth
  #fieldSchemaId
  #fieldSchema
  #importColumnId
  constructor({
    teamId,
    label,
    key,
    isHidden,
    isPinned,
    isResizable,
    isSortable,
    width,
    maxWidth,
    minWidth,
    fieldSchemaId,
    fieldSchema,
    importColumnId,
    ...props
  } = {}) {
    super({ ...props })
    this.teamId = teamId
    this.key = key
    this.label = label
    this.isHidden = isHidden
    this.isPinned = isPinned
    this.isSortable = isSortable
    this.width = width
    this.maxWidth = maxWidth
    this.minWidth = minWidth
    this.fieldSchemaId = fieldSchemaId
    this.fieldSchema = fieldSchema
    this.importColumnId = importColumnId
  }

  //--- getters ---//
  get isColumnEntity() { return TRUE }
  get label() { return this.#label }
  get teamId() { return this.#teamId }
  get key() { return this.#key }
  get isHidden() { return this.#isHidden }
  get isPinned() { return this.#isPinned }
  get isResizable() { return this.#isResizable }
  get width() { return this.#width }
  get maxWidth() { return this.#maxWidth }
  get minWidth() { return this.#minWidth }
  get fieldSchemaId() { return this.#fieldSchemaId }
  get fieldSchema() { return this.#fieldSchema }
  get importColumnId() { return this.#importColumnId }

  //--- setters ---//
  set teamId(id) { this.#teamId = id }
  set key(str) { this.#key = str }
  set label(str) {
    this.#label = str
    if (this.#label?.length && isEmpty(this.#key)) {
      this.#key = generateFieldKeyFromLabel(this.#label)
    }
  }
  set isHidden(bool) { this.#isHidden = !!bool }
  set isPinned(bool) { this.#isPinned = !!bool }
  set isResizable(bool) { this.#isResizable = !!bool }
  set width(val) { this.#width = val }
  set maxWidth(val) { this.#maxWidth = val }
  set minWidth(val) { this.#minWidth = val }
  set fieldSchemaId(id) { this.#fieldSchemaId = id }
  set fieldSchema(obj) {
    if (!obj) {
      return
    }
    this.#fieldSchema = obj.isFieldSchemaEntity ? obj : new FieldSchemaEntity(obj)
    this.fieldSchemaId = this.#fieldSchema.id
    this.#label = isEmpty(this.#label) ? this.#fieldSchema.label : this.#label
    this.#key = isEmpty(this.#key) ? this.#fieldSchema.key : this.#key
  }
  set importColumnId(id) { this.#importColumnId = id }

  //--- methods ---//
  hide() { this.#isHidden = TRUE }
  unhide() { this.#isHidden = FALSE }
  pin() { this.#isPinned = TRUE }
  unpin() { this.#isPinned = FALSE }

  createFieldSchema(type) {
    this.fieldSchema = new FieldSchemaEntity( {
      teamId: this.teamId, 
      label: this.label,
      type: type
    })
  }

  resetKey() {
    this.#key = generateFieldKeyFromLabel(this.#label)
  }

  //--- object methods ---//
  validate() {
    const msg = `[key: "${this.#key}"  id: ${this.id}]`
    if(!this.id) { required(`${msg} id`)}
    if(!this.teamId) { required(`${msg} teamId`)}
    if(!this.label) { required(`${msg} label`)}
    if(!this.key) { required(`${msg} key`)}
    if(!this.fieldSchemaId) { required(`${msg} fieldSchemaId`)}
    if(!this.fieldSchema) { required(`${msg} fieldSchema`)}
    if (this.fieldSchemaId !== this.#fieldSchema.id) {
      required(`${msg} fieldSchemaId and fieldSchema.id not match`)
    }
  }

  #getMyProperties() {
    return {
      teamId: this.#teamId,
      label: this.#label,
      key: this.#key,
      isHidden: this.#isHidden,
      isPinned: this.#isPinned,
      isResizable: this.#isResizable,
      isSortable: this.#isSortable,
      width: this.#width,
      maxWidth: this.#maxWidth,
      minWidth: this.#minWidth,
    }
  }
  cloneAsNew({teamId} = {}) {
    return new ColumnEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
      fieldSchemaId: this.#fieldSchemaId,
      fieldSchema: this.#fieldSchema, // keep current field schema on clone.
      teamId: teamId || this.#teamId
    })
  }

  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties(),
      fieldSchemaId: this.#fieldSchemaId,
      fieldSchema: this.#fieldSchema,
      importColumnId: this.#importColumnId
    }
  }

}

