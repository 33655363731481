/*!
 * Invite actions
 */

// Dependencies
import { ref } from 'vue'

// Utils
import { required, isArray, throwError, getTimestamp } from '../../utils'

// Collections
import { updateUser } from '../../user/actions/user-actions.mjs'

import { createOneSignupInvite, createOneTeamInvite, createOneListInvite, deactivateInvite, startInvitesListener, getTeamInvites, markInviteAsDeleted, isExpired, getSignupInvites } from '../db/invites.mjs'

// Actions
import { getCurrentUser } from '../../actions/current-user.mjs'
import { getCurrentTeam } from '../../actions/current-team.mjs'
import { sendTeamInviteEmail, sendListInviteMail } from '../actions/email.mjs'

// Constants
import {
  IS_DEBUG_MODE,
  TEAM_INVITE_TYPE, LIST_INVITE_TYPE
} from '../../constants'
import { UserEntity } from '../../entity/UserEntity.mjs'

// Refs
const inviteColumns = ref([
  'Name', 'Email', 'Role', 'Expiry', ' '
])
const isSendInviteOpen = ref(false)
const isDeleteInviteOpen = ref(false)
const isSendInviteError = ref(false)
const sendInviteErrors = ref({})

const teamInvites = ref([])
const selectedInvite = ref([])

// Variables
let inviteEventListener = null
let teamInviteEventListener = null
/**
 * Create signup invite
 */
function createSignupInvite({
  receiverEmail = required('receiverEmail'),
  ...props
}) {
  return createOneSignupInvite({ ...props, receiverEmail })
}

/**
 * Create Team invite
 */
async function createTeamInvite({
  currentTeam = getCurrentTeam(),
  receiverEmail = required('receiverEmail'),
  teamId = currentTeam.id || required('teamId'),
  ...props
} = required('team invite data')) {
  const invite = createOneTeamInvite({ ...props, receiverEmail, teamId })
  return await sendTeamInviteEmail({ invite })
}

/**
 * @param {Object[]} inviteList
 * @param {string} inviteList[].email
 * @param {string} inviteList[].role
 * Invite team members by email list
 */
function inviteTeamMembersByEmailList(
  inviteList = required('team invite list array')
) {
  if (!isArray(inviteList)) {
    throwError(`invite list must be an array`)
  }
  const promises = inviteList
    .map(invitee => createTeamInvite({ receiverEmail: invitee.email, role: invitee.role, name: invitee.name }))
  return Promise.all(promises)
}

/**
 * Set team invite error
 */
function setSendInviteErrors(message) {
  console.error('Send Invite error => ', message)
  isSendInviteError.value = true
  sendInviteErrors.value = { message }
}

/**
 * Clear team invite error
 */
function clearSendInviteErrors() {
  isSendInviteError.value = false
  sendInviteErrors.value = null
}

/**
 * Create list invite
 */
 async function createListInvite({
  receiverEmail = required('receiverEmail'),
  listId = required('listId'),
  message = null,
  ...props
} = required('Price list invite data')) {
  const invite = createOneListInvite({ ...props, receiverEmail, listId, message })
  return await sendListInviteMail({ invite })
}

/**
 * Invite team members by email list
 */
 function inviteListByEmailList(
  listId = required('listId'),
  emailList = required('Price list invite email array'),
  message = null,
 ) {
  if (!isArray(emailList)) {
    throwError(`email list must be an array`)
  }
  const promises = emailList
    .map(receiverEmail => createListInvite({ listId, receiverEmail, message }))
  return Promise.all(promises)
}


/**
 * Delete an invite
 */
function deleteInvite(
  inviteId = required('inviteId')
) {
  markInviteAsDeleted(inviteId)
}

/**
 * Get existing unexpired sign-up invites
 */
async function getExistingSignupInvites({
  receiverEmail = required('receiverEmail')
}) {
  const existingSignupInvites = await getSignupInvites({ receiverEmail })
  const unexpiredSignupInvites = existingSignupInvites.filter(invite => invite.expiresAt > getTimestamp())
  return unexpiredSignupInvites
}

/**
 * Get team current team invites
 */
function getExistingTeamInvites(
  currentTeam = getCurrentTeam()
) {
  return getTeamInvites({ teamId: currentTeam.id })
}

/**
 * On send invite
 */
function onSendInvitation() {
  isSendInviteOpen.value = true
}

/**
 * On delete invite
 */
function onDeleteInvitation(selected) {
  selectedInvite.value = selected
  isDeleteInviteOpen.value = true
}


//#region invite listener - code is not in use
/**
 * Process team invite //TODO: JL - code is not in use
 */
function processTeamInvite(invite) {
  const currentUser = getCurrentUser()
  const userEntity = new UserEntity(currentUser)
  userEntity.teamId = invite.teamId
  updateUser(userEntity)
}

/**
 * Process list invite //TODO: JL - code is not in use
 */
function processListInvite(invite) {
}

/**
 * Added invite triggered //TODO: JL - code is not in use
  */
function addedInviteTrigger({
  id,
  data
}) {
  // check to see if the invite has expired
  if (isExpired(data)) {
    IS_DEBUG_MODE && console.log('Event has expired ', id)
    deactivateInvite(data)
    return
  }
  switch (data.type) {
    case TEAM_INVITE_TYPE:
      processTeamInvite(data)
      break

    case LIST_INVITE_TYPE:
      processListInvite(data)
      break
  }
  deactivateInvite(data)
}

/**
 * Start invite listeners //TODO: JL - code is not in use
 */
function startInviteListeners() {
  const currentUser = getCurrentUser()
  if (inviteEventListener) {
    IS_DEBUG_MODE && console.log('Invite Listener has already started')
  } else {
    IS_DEBUG_MODE && console.log('Start invite listener for ', currentUser.email)
    inviteEventListener = startInvitesListener(
      currentUser.email,
      addedInviteTrigger, addedInviteTrigger,
      addedInviteTrigger, addedInviteTrigger,
      null, null,
      [
        ['receiverEmail', '==', currentUser.email],
        ['isActive', '==', true],
      ]
    )
  }
}

/**
 * Stop invite listeners //TODO: JL - code is not in use
 */
 function stopInviteListeners() {
  if (!inviteEventListener) return
  inviteEventListener()
  inviteEventListener = null
}
//#endregion

//#region team invites listener
function teamInvitesUpsert({
  id,
  data
}) {
  // check to see if the invite has expired
  if (isExpired(data)) {
    IS_DEBUG_MODE && console.log('Event has expired ', id)
    deactivateInvite(data)
    return
  }
  const idx = teamInvites.value.findIndex(i => i.id === id)
  if (idx >= 0) {
    teamInvites.value[idx] = { ...data }
  } else {
    teamInvites.value = [...teamInvites.value, data]
  }
}

function teamInvitesRemoved({
  id,
  data
}) {
  // check to see if the invite has expired
  if (isExpired(data)) {
    IS_DEBUG_MODE && console.log('Event has expired ', id)
    deactivateInvite(data)
    return
  }
  const idx = teamInvites.value.findIndex(i => i.id === id)
  teamInvites.value.splice(idx, 1)
}

/**
 * Start current team invites listeners
 */
function startTeamInvitesListeners() {
  const currentTeam = getCurrentTeam()
  if (teamInviteEventListener) {
    IS_DEBUG_MODE && console.log('Team Invites Listener has already started')
  } else {
    IS_DEBUG_MODE && console.log('Start team invites listener for ', currentTeam.id)
    teamInviteEventListener = startInvitesListener(
      teamInvitesUpsert, teamInvitesUpsert,
      teamInvitesUpsert, teamInvitesUpsert,
      teamInvitesRemoved, teamInvitesRemoved,
      [
        ['teamId', '==', currentTeam.id],
        ['type', '==', TEAM_INVITE_TYPE],
        ['isActive', '==', true],
      ]
    )
  }
}

function stopTeamInvitesListeners() {
  if (!teamInviteEventListener) return
  teamInviteEventListener()
  teamInviteEventListener = null
}
//#endregion

// Module epxorts
export {
  isSendInviteOpen, isDeleteInviteOpen, inviteColumns, isSendInviteError, sendInviteErrors, selectedInvite, clearSendInviteErrors,
  inviteTeamMembersByEmailList,
  onSendInvitation, onDeleteInvitation, deleteInvite,
  createSignupInvite, createTeamInvite, createListInvite, inviteListByEmailList,
  getExistingTeamInvites, getExistingSignupInvites,
  //startInviteListeners, stopInviteListeners, 
  teamInvites, startTeamInvitesListeners, stopTeamInvitesListeners,
}
