
import { ref, shallowRef } from 'vue'
import { CHAR_EMPTY } from '@/constants/characters.mjs'
import { TRUE, FALSE } from '@/constants/types.mjs'

// List
export const currentList = shallowRef({})
export const isImportMode = ref(FALSE)
export const isLibraryList = ref(FALSE)
export const isViewList = ref(FALSE)
export const isStaticList = ref(FALSE)

// Field Schemas
export const teamFieldSchemas = shallowRef({})
export const libraryFieldSchemas = shallowRef({})

export const fieldMapOptions = shallowRef([])

// List UI action permissions
export const canAddItems        = ref(FALSE)
export const canDeleteItems     = ref(FALSE)
export const canExportItems     = ref(FALSE)
export const canDuplicateItems  = ref(FALSE)
export const canCreateView      = ref(FALSE)
export const canAddToView       = ref(FALSE)
export const canModifyView      = ref(FALSE)
export const canCreatePriceList = ref(FALSE)
export const canAddToPriceList = ref(FALSE)
export const canModifyPriceList = ref(FALSE)
export const canSharePriceList = ref(FALSE)
export const canUploadCsv   = ref(TRUE)
export const canImportToLibrary = ref(FALSE)

// Selected items
export const selectedItemsCount = ref(0)
export const isSelectedCellValueEditorOpen = ref(FALSE)

// Field Editor
export const currentColumn = ref(null)
export const isFieldEditorOpen = ref(FALSE)
export const fieldEditorInputType = ref(null)

// Filter
export const filterTab = ref('filter')
export const columnFilterValue = ref(CHAR_EMPTY)
export const filteredColumns = ref([])
export const isFilteringActive = ref(FALSE)
export const currentFilters = shallowRef([])
export const savedFilterGroups = shallowRef([])
export const libraryFilterGroups = ref([])
export const filterGroupName = ref(null)

// List Modal
export const isCreateListOpen = ref(FALSE)
export const isShareListOpen = ref(FALSE)
export const isRemoveUserFromListConfirmModalOpen = ref(FALSE)
export const isMainMenuOpen = ref(TRUE)

// Price List Modal
export const isModifyPriceListOpen = ref(FALSE)
export const isAddToPriceListOpen = ref(FALSE)

// View Modal
export const isModifyViewOpen = ref(FALSE)
export const isAddToViewOpen = ref(FALSE)

// Columns
export const canCreateColumns = ref(FALSE)
export const canEditColumns = ref(FALSE)
export const canDeleteColumns = ref(FALSE)
export const canFilterColumns = ref(FALSE)
export const canSaveFilteredColumns = ref(FALSE)
export const canHideColumns = ref(FALSE)
export const canPinColumns = ref(FALSE)

// List Index
export const selectedList = shallowRef([])
export const teamPriceListsMap = shallowRef(new Map()) 
export const supplierPriceListsMap = shallowRef(new Map()) 
export const teamPriceLists = shallowRef([])
export const supplierPriceLists = shallowRef([])


// progress status refs
export const isProgressOpen = ref(false)
export const progressLabel = ref('label...')
export const progressBody = ref('body...')
export const progressValue = ref(0)
export const progressMaxValue = ref(0)
export const progressShowWhenAboveValue = ref(0)
export const progressPercentage = ref(0)

// Team
export const isRemoveMemberConfirmationOpen = ref(FALSE)
export const currentTeam = shallowRef({})
export const selectedMember = ref([])

//Team UI action permissions
export const canViewTeamSettings = ref(FALSE)
export const canViewTeamMemberList = ref(FALSE)
export const canChangeTeamName = ref(FALSE)
export const canInviteTeamMember = ref(FALSE)
export const canRemoveTeamInvite = ref(FALSE)
export const canRemoveTeamMember = ref(FALSE)
export const canSetTeamMemberPermission = ref(FALSE)
export const canCreateTeam = ref(FALSE)

// Integration
export const canPublishToIntegration = ref(FALSE)

// Main Layout UI action permissions
export const canViewLibrary = ref(FALSE)
export const canViewPriceLists = ref(FALSE)
export const canViewImports = ref(FALSE)
export const canViewSharedWithMe = ref(FALSE)
export const canViewMyProfile = ref(TRUE)
export const canViewMyTeam = ref(TRUE)
export const canViewIntegrations = ref(TRUE)


// list of log files
export const logFileList = ref([])
export const selectedLogFile = ref()
export const logFile = ref(CHAR_EMPTY)
export const logFileDisplay = ref(CHAR_EMPTY)