/*!
 * Duration constants
 */

// Dependencies
import { THREE, FIVE, TEN, TWENTY_FOUR, THIRTY, SIXTY, THREE_HUNDRED, ONE_THOUSAND } from './number.mjs'

// References
export const MILLIS_IN_ONE_SECOND  = ONE_THOUSAND
export const SECONDS_IN_A_MINUTE   = SIXTY
export const MINUTES_IN_AN_HOUR    = SIXTY
export const HOURS_IN_A_DAY        = TWENTY_FOUR
export const DAYS_IN_A_YEAR        = THREE_HUNDRED + SIXTY + FIVE

// Duration (base in millis)
export const ONE_SECOND            = MILLIS_IN_ONE_SECOND
export const TEN_SECONDS           = ONE_SECOND * TEN
export const ONE_MINUTE            = ONE_SECOND * SECONDS_IN_A_MINUTE
export const ONE_HOUR              = ONE_MINUTE * MINUTES_IN_AN_HOUR
export const ONE_DAY               = ONE_HOUR   * HOURS_IN_A_DAY
export const ONE_HOUR_IN_SECONDS   = ONE_HOUR / MILLIS_IN_ONE_SECOND
export const ONE_DAY_IN_SECONDS    = ONE_DAY  / MILLIS_IN_ONE_SECOND
export const THREE_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * THREE
export const ONE_YEAR_IN_SECONDS   = DAYS_IN_A_YEAR * ONE_DAY_IN_SECONDS
export const FIVE_MINUTES          = ONE_MINUTE * FIVE
export const THIRTY_DAYS           = THIRTY * ONE_DAY