import { parse as parseCSV, unparse as unparseCSV } from 'papaparse'
import { generateId } from '../utils/uuid.mjs'
import { convertStringToFile } from '../utils/file.mjs'

const ID_HEADER = 'Row Id'

export const addRowIds = async (file) => {
  const fileJson = await new Promise(resolve => {
    parseCSV(file, {
      skipEmptyLines: true,
      header: true,
      delimiter: ',',
      complete: results => {
        resolve(results)
      }
    })
  })

  for (const row of fileJson.data) {
    row[ID_HEADER] = row[ID_HEADER] || generateId()
  }
  const blob = unparseCSV(fileJson.data, {
    quotes: true, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    newline: "\r\n",
    skipEmptyLines: true,
  })
  return convertStringToFile({ contents: blob, fileName: file.name })
}

