/*!
 * Field input types
 */

// Dependencies
import { 
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_DATE,
  FIELD_TYPE_TAGLIST,
  FIELD_TYPE_CHECKBOX,
}   from '../../field-schema/field-schema-constants.mjs'

// Field input types for FieldEditModal
export const FIELD_INPUT_TYPE_TEXT       = FIELD_TYPE_TEXT
export const FIELD_INPUT_TYPE_MULTI_LINE = 'textarea'
export const FIELD_INPUT_TYPE_CHECKBOX   = FIELD_TYPE_CHECKBOX
export const FIELD_INPUT_TYPE_DATE       = FIELD_TYPE_DATE
export const FIELD_INPUT_TYPE_NUMBER     = FIELD_TYPE_NUMBER
export const FIELD_INPUT_TYPE_TAGLIST    = FIELD_TYPE_TAGLIST