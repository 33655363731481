// utils
import { required } from '../../utils/required.mjs'

// local events
import {
  EVENT_ITEM_NEW,
  EVENT_ITEM_NEW_LOCAL ,
  EVENT_ITEM_NEW_REMOTE,
  EVENT_ITEM_UPDATED,
  EVENT_ITEM_UPDATED_LOCAL,
  EVENT_ITEM_GRID_UPDATED_LOCAL,
  EVENT_ITEM_UPDATED_REMOTE,
  EVENT_SELECTED_ITEMS_UPDATE_LOCAL,
  EVENT_SELECTED_ITEMS_DELETE_LOCAL,
  EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL,
  EVENT_LIBRARY_ITEMS_UPDATED,
  EVENT_LIBRARY_ITEMS_UPDATED_REMOTE,
} from '../../events/constants/event-types.mjs'

// external events
import { SCOPE_TEAM } from '../../events/external-events.mjs'
import { subscribe, unsubscribe } from '../../events/external-events.mjs'

// item event actions
import { newItemEvent, newItemRemoteEvent, newItemRemoteHandler } from './new-item-events.mjs'
import { updateItemEvent, updateItemRemoteEvent, updateItemRemoteHandler, updateItemGridEvent } from './update-item-events.mjs'
import { selectedItemsUpdateEvent, selectedItemUpdateRemoteHandler, selectedItemsUpdateRemoteEvent } from './selected-items-update-events.mjs'
import { selectedItemsDeleteEvent } from './selected-items-delete-events.mjs'
import { selectedItemsDuplicateEvent } from './selected-items-duplicate-events.mjs'

/**
 * Add event listener item new
 * @param {*} controller 
 * @param {*} subscriptions 
 * @param {*} columnManager 
 * @param {*} itemManager 
 * @returns 
 */
export const addEventsItemNew = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_ITEM_NEW_LOCAL ,
    (event) => newItemEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_ITEM_NEW,
      newItemRemoteHandler,
      { scope: SCOPE_TEAM }
    )
  )
  // remote
  addEventListener(
    EVENT_ITEM_NEW_REMOTE,
    (event) => newItemRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

/**
 * Add event listner item updated
 * @param {*} controller 
 * @param {*} subscriptions 
 * @param {*} columnManager 
 * @param {*} itemManager 
 * @returns 
 */
export const addEventsItemUpated = (controller, subscriptions, columnManager, itemManager) => {
  
  addEventListener(
    EVENT_ITEM_GRID_UPDATED_LOCAL ,
    (event) => updateItemGridEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )

  addEventListener(
    EVENT_ITEM_UPDATED_LOCAL ,
    (event) => updateItemEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )

  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_ITEM_UPDATED,
      updateItemRemoteHandler,
      { scope: SCOPE_TEAM }
    )
  )

  // remote
  addEventListener(
    EVENT_ITEM_UPDATED_REMOTE,
    (event) => updateItemRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

export const addEventsLibraryItemsUpdatedRemote = (controller, subscriptions, columnManager, itemManager) => {
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_LIBRARY_ITEMS_UPDATED,
      selectedItemUpdateRemoteHandler,
      { scope: SCOPE_TEAM }
    )
  )

  // remote
  addEventListener(
    EVENT_LIBRARY_ITEMS_UPDATED_REMOTE,
    (event) => selectedItemsUpdateRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

/**
 * Add event listener selected items updated
 * @param {*} controller 
 * @param {*} subscriptions 
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const addEventsSeletedItemsUpdate = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_SELECTED_ITEMS_UPDATE_LOCAL,
    (event) => selectedItemsUpdateEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
}


export const addEventsSeletedItemsDelete = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_SELECTED_ITEMS_DELETE_LOCAL,
    (event) => selectedItemsDeleteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
}


export const addEventsSeletedItemsDuplicate = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_SELECTED_ITEMS_DUPLICATE_LOCAL,
    (event) => selectedItemsDuplicateEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
}

