/*!
 * ItemEntity
 */

import { required } from '../utils/required.mjs'
import { deepClone } from '../utils/deep-clone.mjs'
import { Entity } from './Entity.mjs'
import { CHAR_EMPTY } from '../constants/characters.mjs'
import { UNDEFINED, TRUE, FALSE } from '../constants/types.mjs'
import { isEmptyNullOrUndefined } from '../utils/is.mjs'
import { fetchItem } from '../item/data/item-data.mjs'
import { saveItemsEntities } from '../item/db/item-db.mjs'
import { ITEM_TYPE_DEFAULT } from '../item/constants/item-types.mjs'

export class ItemEntity extends Entity {
  #teamId
  #libraryListId
  #productCode
  #fields
  #deletedFields
  #lists
  #type
  #isStatic
  #sourceId
  #batchResults
  #logStatus
  constructor({
    teamId = required('teamId'),
    libraryListId,
    productCode = UNDEFINED,
    fields = {},
    lists = [],
    deletedFields = {},
    type = ITEM_TYPE_DEFAULT,
    isStatic = FALSE,
    sourceId = UNDEFINED,
    batchResults = UNDEFINED,
    logStatus = CHAR_EMPTY,
    ...props
  } = {}) {
    super({ ...props })
    this.teamId = teamId
    this.libraryListId = libraryListId
    this.productCode = productCode
    this.fields = fields
    this.deletedFields = deletedFields
    this.lists = lists
    this.type = type
    this.isStatic = isStatic
    this.sourceId = sourceId
    this.batchResults = batchResults
    this.setInternalFieldValues()
    this.logStatus = logStatus
  }

  get isItemEntity() { return TRUE }

  //--- getters ---//
  get teamId() { return this.#teamId }
  get libraryListId() { return this.#libraryListId }
  get productCode() { return this.#productCode }
  get fields() { return this.#fields }
  get deletedFields() { return this.#deletedFields }
  get lists() { return [this.#libraryListId, ...this.#lists] }
  get type() { return this.#type }
  get isStatic() { return this.#isStatic }
  get sourceId() { return this.#sourceId }
  get batchResults() { return this.#batchResults }
  get logStatus() { return this.#logStatus }

  //--- setters ---//
  set teamId(id) { this.#teamId = id }
  set libraryListId(id) {
    this.#lists?.delete(this.#libraryListId)
    this.#libraryListId = this.#isStatic ? CHAR_EMPTY : id
  }
  set productCode(str) { this.#productCode = str }
  set fields(obj) {
    this.#fields = obj
    this.setInternalFieldValues()
  }
  set deletedFields(obj) { this.#deletedFields = obj }
  set lists(arr) {
    this.#lists = new Set([...arr].filter(id => !isEmptyNullOrUndefined(id)))
    this.#lists.delete(this.#libraryListId)
  }

  set type(str) { this.#type = str }
  set isStatic(bool) {
    this.#isStatic = !!bool
    this.#libraryListId = this.#isStatic ? CHAR_EMPTY : this.#libraryListId
  }

  set sourceId(id) { this.#sourceId = id }
  set batchResults(obj) { this.#batchResults = obj }
  set logStatus(str) { this.#logStatus = str }

  //--- private methods ---//
  setInternalFieldValues() {
    if (!this.#fields) {
      return
    }
    const fields = this.#fields
    if (fields.id) {
      fields.id.inputValue
        = fields.id.value
        = fields.id.formattedValue
        = this.id
    }
    if (fields.createdAt) {
      fields.createdAt.inputValue
        = fields.createdAt.value
        = fields.createdAt.formattedValue
        = this.createdAt
    }
    if (fields.updatedAt) {
      fields.updatedAt.inputValue
        = fields.updatedAt.value
        = fields.updatedAt.formattedValue
        = this.updatedAt
    }

    if (fields.productCode) {
      this.#productCode = fields.productCode.value || fields.productCode.inputValue
    }
  }

  //--- lists methods ---//
  addListId(id) {
    if (id !== this.#libraryListId) {
      this.#lists.add(id)
    }
  }
  removeListId(id) {
    this.#lists.delete(id)
  }

  clearLists() {
    this.#lists.clear()
  }

  //--- field methods ---//
  setField(field) {
    if (!field.key) {
      console.error('missing field key')
      return
    }
    this.#fields[field.key] = { ...field }
    this.setInternalFieldValues()
  }

  addField(field) {
    this.setField(field)
    this.setInternalFieldValues()
  }

  deleteField(fieldKey) {
    if (!fieldKey || !this.#fields[fieldKey]) {
      return
    }
    this.#deletedFields[fieldKey] = { ...this.#fields[fieldKey] }
    delete this.#fields[fieldKey]
    this.setInternalFieldValues()
  }

  undeleteField(fieldKey) {
    if (!this.#deletedFields || !this.#deletedFields[fieldKey] || !fieldKey) {
      return
    }
    this.#fields[fieldKey] = { ...this.#deletedFields[fieldKey] }
    delete this.#deletedFields[fieldKey]
    this.setInternalFieldValues()
  }

  //--- batchResults methods ---//
  clearBatchResults() {
    this.#batchResults = []
  }
  addBatchResults(str) {
    this.#batchResults = [...this.#batchResults, str]
  }

  //--- object methods ---//
  clearErrorsAndWarnings() {
    this.error = CHAR_EMPTY
    this.warning = CHAR_EMPTY
    for (const key in this.fields) {
      this.fields[key].error = CHAR_EMPTY
      this.fields[key].warning = CHAR_EMPTY    
    }
    return
  }

  validate() {
    if (!this.id) { required(`${msg} id`) }
    const msg = `[id: ${this.id}]`
    if (!this.teamId) { required(`${msg} teamId`) }
    if (!this.fields) { required(`${msg} fields`) }
  }

  #getMyProperties() {
    return {
      teamId: this.#teamId,
      libraryListId: this.#libraryListId,
      productCode: this.#productCode,
      fields: this.#fields,
      deletedFields: this.#deletedFields,
      lists: [...this.#lists],
      type: this.#type,
      isStatic: this.#isStatic,
      batchResults: this.#batchResults,
      logStatus: this.#logStatus
    }
  }

  cloneAsNew({ 
    isStatic = this.#isStatic
  } = {}) {
    return new ItemEntity({
      ...super.cloneAsNew().toObject(),
      ...deepClone(this.#getMyProperties()),
      batchResults: UNDEFINED,
      isStatic,
      logStatus: CHAR_EMPTY
    })
  }


  toObject() {
    return {
      ...super.toObject(),
      ...this.#getMyProperties(),
    }
  }

  //--- static methods ---//
  static async upsert(itemEntity) {
    return saveItemsEntities([itemEntity])
  }

  static async fetch(id) {
    const item = await fetchItem(id)
    return item ? new ItemEntity(item) : undefined
  }
}

