<template>
  <pre-layout>
    <div class="fixed-center text-center">
      <q-card class="verification-card text-dark q-pa-md">
        <q-card-section>
          <div class="text-h5 text-weight-bold">Email Verification Required</div>
          <div class="text-body2 text-weight-medium text-grey-6 q-mt-sm">Please check your inbox (or spam folder) to continue.</div>
          <div style="margin-top: 2rem; display: flex; justify-content: space-evenly;">
            <a style="text-decoration: none; cursor: pointer;" class="text-primary" @click="verifyEmailAddress()">Resend email</a>
            <a style="text-decoration: none;" class="text-primary" href="/">Sign In &rarr;</a>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div class="q-pt-sm text-white" style="text-align: center; font-weight: 500; position: fixed; bottom: 1rem; width: 100%;">
      <a style="text-decoration: none;" class="text-white" href="https://productengine.app/terms">Terms & Conditions</a> &bullet; <a style="text-decoration: none;" class="text-white" href="https://productengine.app/privacy">Privacy Policy</a>
    </div>

  </pre-layout>
</template>

<style scoped>

.verification-card {
  background: white;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  width: 25rem;
}

@media only screen and (max-width: 767px) {
  .verification-card {
    width: 18rem;
  }
}

</style>

<script setup>

// Layouts
import PreLayout from '../layouts/PreLayout.vue'
import { verifyEmailAddress } from '../actions/current-user.mjs'

</script>