import { ref, triggerRef } from 'vue'
import { CHAR_EMPTY } from '../../constants/characters.mjs'
import { TRUE } from '../../constants/types.mjs'

export const activeIntegrations = ref({})
export const activeIntegrationsMap = ref(new Map())

export const activeIntegrationsUi = ref([])
export const availableIntegrations = ref([])
export const currentIntegration = ref({})
export const currentIntegrationId = ref(null)

export const publishableIntegrations = ref([])


export const integrationName = ref(CHAR_EMPTY)



export const validateIntegrationName = (input) => {
  return input.match(/[^a-zA-Z0-9\s-]/) 
    ? `A-Z, a-z, 0-9, or '-' characters only`
    : TRUE
}

export const parseIntegrationName = (input) => {
  return input.replace(/[^a-zA-Z0-9\s-]/g, "").trim()
}