
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { uploadListCsv, getListCsv, getListCsvData, getListCsvMeta } from '../../storage/list-file-storage.mjs'
import { parse as parseCSV } from 'papaparse'
import { saveList, saveListAndItems } from '../../list/db/list-db.mjs'
import { getCurrentLibraryList } from '../../list/db/list-db.mjs'
import { getColumnSet } from '../../column-set/db/column-set-db.mjs'
import { throwError } from '../../utils/errors.mjs'
import { compileItem } from '../../item/utils/compile-item.mjs'
import { getListFieldSchemas } from '../../list/utils/get-list-field-schemas.mjs'

// Utils
import {
  parseCurrency, parseNumber, required,
  parseBoolean, parseDateInternational, parseTaglist, isNull, isEmpty
} from '../../utils'
import { makeFormatInput } from '../../field-schema/utils/make-field-format-input.mjs'
// Constants
import {
  FIELD_TYPE_CHECKBOX, FIELD_TYPE_DATE, FIELD_TYPE_NUMBER,
  FIELD_TYPE_TAGLIST, FIELD_TYPE_CURRENCY, FIELD_TYPE_PERCENTAGE,
  FIELD_TYPE_TEXT
} from '../../constants'
import { ITEM_TYPE_CSV } from '../../item/constants/item-types.mjs'

import { setColumnWidthToLabel } from '../../column/column-ui-utils.mjs'
import { DEFAULT_COLUMN_WIDTH_MAPS_TO } from '../../column-set/column-set-constants.mjs'

function isEmptyRow(row) {
  return !row.some(cell => cell?.length)
}

async function getCsvLabelsAndRows(listEntity) {
  if (!listEntity.csv) {
    listEntity.csv = await getListCsvData(listEntity.csvFilePath)
  }
  const data = listEntity.csv.data
  const errors = listEntity.csv.errors
  if (errors?.length) {
    return console.error('Errors:', errors)
  }

  const [importLabels, ...importRows] = data.filter(row => !isEmptyRow(row))
  return { importLabels, importRows }
}

async function setListCsvColumns(listEntity) {
  const library = await getCurrentLibraryList()
  if (!library) {
    throwError('Library is missing')
  }
  const libraryColumnSet = await getColumnSet(library.columnSetId)
  if (!libraryColumnSet || !libraryColumnSet.columns) {
    throwError('Missing library column set')
  }

  const { importLabels, importRows } = await getCsvLabelsAndRows(listEntity)
  if (!importLabels) {
    // Should not be here.
    throwError('No import label found!')
  }
  const columns = listEntity.columnSet.columns
  // add new columns to list
  const columnLabelMap = new Map(columns.map(col => [col.label.toLowerCase(), col]))
  // map labels with columns
  const importColumns = []
  // get the column field schema types from firebase storage 
  const meta = await getListCsvMeta(listEntity.csvFilePath)

  // TODO: PL - #465 - Commented out because of issue. will need to find a work around
  //const fieldSchemaTypes = JSON.parse(meta.customMetadata.fieldTypes)
  importLabels.forEach((label, idx) => {
    const column = columnLabelMap.get(label.toLowerCase())
    if (!column) {
      const newColumn = new ColumnEntity({ teamId: listEntity.teamId, label })
      if (!newColumn?.width) newColumn.width = setColumnWidthToLabel(newColumn) + DEFAULT_COLUMN_WIDTH_MAPS_TO

     // TODO: PL - #465 - Commented out because of issue. will need to find a work around
     //const fieldSchemaType = fieldSchemaTypes[label]
     // #465 workaround - need to be replace
     const fieldSchemaType = FIELD_TYPE_TEXT


      newColumn.createFieldSchema(fieldSchemaType)
      listEntity.columnSet.addColumn(newColumn)
      importColumns[idx] = newColumn
    }
  })

  saveList(listEntity)
}

async function setListCsvItems(listEntity) {
  let results = null
  if (!listEntity.csv) {
    listEntity.csv = await getListCsvData(listEntity.csvFilePath)
  }
  listEntity.items = []
  const { importLabels, importRows } = await getCsvLabelsAndRows(listEntity)
  const columns = listEntity.columnSet.columns
  const columnLabelMap = new Map(columns.map(col => [col.label.toLowerCase(), col]))

  // create new items with label
  for (const row of importRows) {
    const newItem = listEntity.createItem()
    newItem.type = ITEM_TYPE_CSV
    const columns = listEntity.columnSet.columns
    const itemFields = newItem.fields
    for (const column of columns) {
      const label = column.label.toLowerCase()
      const importIdx = importLabels.findIndex(l => l.toLowerCase() === label)
      if (importIdx < 0) {
        continue
      }
      const fieldSchema = column.fieldSchema
      itemFields[fieldSchema.key].inputValue = row[importIdx]
      formatImportValue(itemFields[fieldSchema.key])
    }

    listEntity.updateItem(compileItem({ item: newItem, fieldSchemas: getListFieldSchemas(listEntity) }))

  }
}

async function setListCsvData(listEntity) {
  listEntity.csv = { data: [], errors: [] }
  const file = await getListCsv({ filePath: listEntity.csvFilePath })

  await parseCSV(file, {
    skipEmptyLines: true,
    header: false,
    //worker: true,
    complete: function () {
      return listEntity
    },
    delimiter: ',',
    step: function (results) {
      listEntity.csv.data.push(results.data)
      listEntity.csv.errors.push(...results.errors)
    }
  })
}

async function setListCsvFile(list, file) {
  list.csv = undefined
  const result = await uploadListCsv({ teamId: list.teamId, listId: list.id, file, filePath: list.csvFilePath })
  list.csvFilePath = result.metadata.customMetadata.filePath
  await setListCsvData(list)
  saveListAndItems(list)
}

/**
 * 
 */
function formatImportValue(field = required('field')) {
  const formatInput = makeFormatInput(field.fieldType)
  switch (field.fieldType) {
    case FIELD_TYPE_CHECKBOX: {
      field.inputValue = parseBoolean(field.inputValue)
      break
    }

    case FIELD_TYPE_NUMBER: {
      const inputValue = parseNumber(field.inputValue)
      field.inputValue = (!isNaN(inputValue) && !isNull(inputValue)) ? inputValue : field.inputValue
      break
    }

    case FIELD_TYPE_CURRENCY: {
      const inputValue = parseCurrency(field.inputValue)
      field.inputValue = (!isNaN(inputValue) && !isNull(inputValue)) ? inputValue : field.inputValue
      break
    }

    case FIELD_TYPE_PERCENTAGE: {
      const inputValue = formatInput(field.inputValue)
      field.inputValue = (!isNaN(inputValue) && !isNull(inputValue)) ? inputValue : field.inputValue
      break
    }

    case FIELD_TYPE_DATE: {
      const inputValue = parseDateInternational(field.inputValue)
      field.inputValue = (!isNull(inputValue)) ? inputValue : field.inputValue
      break
    }

    case FIELD_TYPE_TAGLIST: {
      field.inputValue = parseTaglist(field.inputValue)
      break
    }
  }

  return field
}

export { setListCsvData, setListCsvColumns, setListCsvItems }
export { setListCsvFile }
