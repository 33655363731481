/**
 * Is admin user
 */

import { ADMIN_USER_ROLE } from '../constants/roles.mjs'
import { userHasRole } from './user-has-role.mjs'

const isAdminUser = (user) => {
  return userHasRole(user, ADMIN_USER_ROLE)
}

export { isAdminUser }
