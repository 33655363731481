<template>

  <!-- Error Cell -->
  <div
    v-if="error"
    class="q-px-md bg-red-1 text-red"
  >
    <q-icon name="warning" color="error" /> <span style="position: absolute; top: 0.07rem; margin-left: 0.3rem;">Error</span>
    <p11e-tooltip class="bg-red-8">{{ error }}</p11e-tooltip>
  </div>

  <!-- Warning Cell -->
  <div
    v-else-if="warning"
    class="q-px-md bg-orange-1 text-orange-8"
  >
    <q-icon name="warning" color="yellow-10" class="text-red" /> <span style="position: absolute; top: 0.07rem; margin-left: 0.3rem;">{{ formattedValue || value || 'Warning' }}</span>
    <p11e-tooltip class="bg-orange-8">{{ warning }}</p11e-tooltip>
  </div>

  <!-- Valid Cell -->
  <div
    v-else class="q-px-md"
    style="min-height: 39px;"
    @mouseenter="isMouseOverCell = true"
    @mouseleave="isMouseOverCell = false"
  >
    <!-- Placeholder Text -->
    <div v-if="isPlaceholderText" style="color: #aaa; font-style: italic;">{{ placeholderText }}</div>

    <!-- Taglist -->
    <template v-else-if="fieldType === FIELD_TYPE_TAGLIST && value">
      <span>
        <div class="row no-wrap">
          <div v-for="tag in value" :key="tag">
            <q-chip dense square class="q-pa-sm">{{ tag }}</q-chip>
          </div>
        </div>
      </span>
    </template>

    <!-- Checkbox TODO: use the trueValue/falseValue in the formattedValue if they exist -->
    <div v-else-if="fieldType === FIELD_TYPE_CHECKBOX" style="text-align: center;">
      <q-icon v-if="value === true" name="check" color="green" size="18px" />
      <q-icon v-else-if="value === false" name="close" color="red" size="18px" />
    </div>

    <!-- Number, Currency, Percentage -->
    <div
      v-else-if="[ FIELD_TYPE_NUMBER, FIELD_TYPE_CURRENCY, FIELD_TYPE_PERCENTAGE ].includes(fieldType)"
      style="text-align: right;"
    >
      {{ formattedValue }}
    </div>

    <!-- HTML -->
    <div v-else-if="FIELD_TYPE_TEXT === fieldType && isHtml(formattedValue)" v-html="formattedValue"></div>

    <!-- Date -->
    <!-- TODO: we should be formatting the date in the compiler -->
    <div v-else-if="FIELD_TYPE_DATE === fieldType">
      {{ (!isNaN(formattedValue) && !isEmpty(formattedValue)) ? new Date(formattedValue).toLocaleString() : formattedValue }}
    </div>
    <!-- Default -->
    <div v-else style="width: 100%$; overflow: hidden; text-overflow: ellipsis;">
      {{ formattedValue }}
    </div>

    <!-- Text expander -->
    <q-btn v-if="FIELD_TYPE_TEXT === fieldType && isMouseOverCell" size="sm" flat round icon="open_in_full" color="grey-6" style="background-color: white; position: absolute; right: .4rem; top: .2rem;" @click="onExpandText">
      <p11e-tooltip>Expand</p11e-tooltip>
    </q-btn>

    <!-- Cell Override Icon -->
    <template v-if="isOverridden">
      <q-icon
        name="circle"
        color="grey-4"
        style="position: absolute; left: -6px; top: -6px;"
      >
        <p11e-tooltip>Overridden from default column value</p11e-tooltip>
      </q-icon>
    </template>

  </div><!--/ Valid Cell -->
</template>
<script>

import { ref } from 'vue'

// Utils
import { isHtml, isEmpty } from '../../utils'

// Constants
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_DATE,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_TAGLIST,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_PERCENTAGE
} from '../../constants'

// Actions
import FileIcon from './FileIcon.vue'

let fieldType = FIELD_TYPE_TEXT
/**
 * Setup
 */
function setup(props) {
  const { headerComponentParams } = props.params.colDef || {}
  const { fieldSchema, column } = headerComponentParams?.data || {}
  const { defaultValue, placeholderText, format } = fieldSchema || {}
  fieldType = fieldSchema.type
  const { value, inputValue, formattedValue, error, warning }
    = props.params.value || {}

  const isMouseOverCell = ref(false)
  const isPlaceholderText = placeholderText?.length && placeholderText === formattedValue // TODO: this should just use the placeholderText if the formatted value isn't available

  /**
   * Open file handler
   */
  function onOpenFile() {
    const el = document.createElement('a')
    el.href = value.url
    el.target = '_blank'
    document.body.appendChild(el)
    el.click()
    el.remove()
  }

  function onExpandText() {
    const container = document.createElement('div')
    container.style = `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999999;
      background-color: rgba(255, 255, 255, 0.95);
      padding: 3rem 10vw;
      display: flex;
      flex-direction: column;
    `
    container.innerHTML = `
      <div onclick="this.parentNode.remove()" style="position: fixed; top: 2rem; right: 4rem; font-size: 3rem; color: black; cursor: pointer;">&cross;</div>
      <div style="text-align: center; font-weight: 500; font-size: 2rem; margin-bottom: 2rem;">${props?.params?.colDef?.headerName || fieldType} Field</div>
      <div style="max-width: 100%; height: 100%; background-color: white; padding: 2rem; overflow: auto;">
        ${formattedValue
            .replace(/\n/gm, '<br>')
            .replace(/ /gm, '&nbsp')}
      </div>
    `
    document.querySelector('body').appendChild(container)
  }

  // Is the cell overridden from it's default value (if it has one)
  const isOverridden = !isEmpty(defaultValue) // It has a default value
    && !isEmpty(inputValue)                   // The input value isn't empty
    && inputValue !== defaultValue            // The input isn't the same as the default
    && props?.params?.colDef?.editable        // The input is actually editable

  // Expose to component
  return {
    FIELD_TYPE_TEXT, FIELD_TYPE_DATE, FIELD_TYPE_CHECKBOX, FIELD_TYPE_TAGLIST,
    FIELD_TYPE_CURRENCY, FIELD_TYPE_NUMBER, FIELD_TYPE_PERCENTAGE,
    isHtml, isOverridden, isMouseOverCell, isEmpty,
    onOpenFile, onExpandText,
    value, format, formattedValue, placeholderText, fieldType, error, warning,
    isPlaceholderText // TODO: this should actually just use the placeholder text and not be formatted
  }
}

// Exports
export default {
  setup,
  components: {}
}

</script>
