/*!
 * Column db
 */

// Db
import { Db } from '@/db/Db.mjs'
import { required } from '../utils'

// column is part of column set collection
export class ColumnDb extends Db {
  constructor() {
    super({
    })
  }

  validateDocObject({
    id = required('id'),
    teamId = required('teamId'),
    label = required('label'),
    key = required('key'),
    fieldSchemaId = required('fieldSchemaId')
  }) {
    return
  }

  createDocObject(column, retainSchema = false) {
    return {
      label: column.label,
      key: column.key,  
      teamId: column.teamId,
      isHidden: column.isHidden,
      isPinned: column.isPinned,
      isResizable: column.isResizable,
      isSortable: column.isSortable,
      fieldSchema: retainSchema ? column.fieldSchema.toObject() :undefined,
      fieldSchemaId: column.fieldSchemaId,
      width: column.width,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
      importColumnId: column.importColumnId,
      ...super.createDocObject(column)
    }
  }
}
