// utils
import { required } from '../../utils/required.mjs'


// external events
import { unsubscribe, SCOPE_TEAM, SCOPE_GLOBAL } from '../../events/external-events.mjs'

import { addEventsFieldSchemaUpdate } from './update-import-field-schema-event.mjs'

import { addEventsImportColumnMapUpdated } from './import-column-mapping-events.mjs'

import { addEventsColumnAutosize, addEventsColumnResize } from '../../list-core/events/size-column-events.mjs'
import { addEventsColumnHide, addEventsColumnUnhide } from '../../list-core/events/hide-unhide-column-events.mjs'
import { addEventsColumnMove } from '../../list-core/events/move-column-events.mjs'
import { addEventsColumnPin, addEventsColumnUnpin } from '../../list-core/events/pin-unpin-column-events.mjs'
import { addEventsColumnSortAsc, addEventsColumnSortDesc } from '../../list-core/events/sort-column-events.mjs'

import { addEventsImportItemsLibrary } from './import-items-library-event.mjs'
import { addEventsUploadCsvCompleted } from './price-list-events.mjs'
import { addEventsPriceListModified } from '../../list-core/events/price-list-modify-events.mjs'


import { addEventsDownloadAsCsv } from '../../list-core/events/download-as-csv-events.mjs'

let subscriptions = []
let globalSubscriptions = []

let columnEventController = null
let itemEventController = null


/**
 * Start view column events
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const startImportColumnEvents = (
  columnManager = required('columnManager'),
  itemManager = required('itemManager')
) => {
  columnEventController = new AbortController()

  addEventsImportColumnMapUpdated(columnEventController, subscriptions, columnManager, itemManager)

  addEventsFieldSchemaUpdate(columnEventController, subscriptions, columnManager, itemManager)

  addEventsColumnHide(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnUnhide(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnPin(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnUnpin(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnSortAsc(columnEventController, columnManager)
  addEventsColumnSortDesc(columnEventController, columnManager)

  addEventsColumnAutosize(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })
  addEventsColumnResize(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsColumnMove(columnEventController, globalSubscriptions, columnManager, { scope: SCOPE_GLOBAL })

  addEventsImportItemsLibrary(columnEventController, subscriptions, columnManager)


  // price list modified
  addEventsPriceListModified(columnEventController, subscriptions, columnManager, itemManager, { scope: SCOPE_GLOBAL })

  // menu actions
  // TODO: PL- move this out of here
  addEventsUploadCsvCompleted(columnEventController, columnManager, itemManager)

    // download event
  addEventsDownloadAsCsv(columnEventController, columnManager, itemManager)

}

/**
 * Stop view column events
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const stopImportColumnEvents = () => {
  columnEventController?.abort()
  columnEventController = undefined

  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })
  }
  subscriptions = []

  //external events
  for (const subscription of globalSubscriptions) {
    unsubscribe(subscription, { scope: SCOPE_GLOBAL })
  }
  globalSubscriptions = []
}

