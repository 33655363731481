/*!
 * Team listener
 */

import { sleep } from '@/utils'
import { onTeamAdded, onTeamChanged, onTeamRemoved } from './team-ui-event-handlers.mjs'
import { TeamDbListener } from './TeamDbListener.mjs'

export class TeamListener {
  #teamEntity
  #teamListener

  constructor({
    teamEntity,
  } = {}) {
    this.#teamEntity = teamEntity
  }

  async start() {
    if (this.#teamListener) {
      this.stop()
      await sleep(1000) // give it time for all listeners to stop before starting again
    }
    this.#teamListener = new TeamDbListener({
      docId: this.#teamEntity.id,
      addedFunction: onTeamAdded,
      modifiedFunction: onTeamChanged,
      removedFunction: onTeamRemoved,
    })
    this.#teamListener.start()

    return this
  }

  stop() {
    if (this.#teamListener) {
      this.#teamListener.stop()
      this.#teamListener = null
    }
  }
}
