

import { addEventsUploadCsv } from './upload-csv-events.mjs'
import { SCOPE_TEAM } from '../../events/external-events.mjs'
import { unsubscribe } from '../../events/external-events.mjs'

let subscriptions = []
let actionEventController = null


export const startListActionEvents = () => {
  actionEventController = new AbortController()
  addEventsUploadCsv(actionEventController)
}

/**
 * Stop view column events
 * @param {*} columnManager 
 * @param {*} itemManager 
 */
export const stopListActionEvents = () => {
  actionEventController?.abort()
  actionEventController = undefined

  //external events
  for (const subscription of subscriptions) {
    unsubscribe(subscription, { scope: SCOPE_TEAM })
  }
  subscriptions = []
}

