
// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_PUBLISH_ADVANCED } from '../../../integration-apps/registered-apps.mjs'


// states
import { currentIntegrationId } from '../../../integrations/states/integrations-ui-states.mjs'
import { getActiveIntegration } from '../../../integrations/actions/active-integrations.mjs'

// events
import { 
  EVENT_INTEGRATION_UPDATED_REMOTE
} from '../../../events/constants/event-types.mjs'

import {
  clearPublishAdvancedConfigurationUiStates,
  setPublishAdvancedConfigurationUiStates,
  onRemoteSalesforcePublishAdvancedConfigUpdated,
  createPublishAdvancedConfigurationFromUi,
} from './salesforce-publish-advanced-configuration-ui.mjs'

import { registerAppFunctions } from '../../../integration-modules/utils/integration-apps-utils.mjs' 

let eventController = null


/**
 * 
 * @param {*} id 
 * @returns 
 */
export const startSalesforcePublishAdvancedUi = async (id) => {
  eventController = new AbortController()
  registerAppFunctions(
    INTEGRATION_SALESFORCE,
    APP_SFDC_PUBLISH_ADVANCED,
    {
      createConfigurationFunc: createPublishAdvancedConfigurationFromUi,
      setConfigurationUiStatesFunc: setPublishAdvancedConfigurationUiStates
    }
  )

  if (!id) {
    clearPublishAdvancedConfigurationUiStates()
    return
  }
  const integration = await getActiveIntegration(id)

  // if no id, it's new
  if (integration) {
    setPublishAdvancedConfigurationUiStates(integration)
  }
  currentIntegrationId.value = id
  //init load
  addEventListener(EVENT_INTEGRATION_UPDATED_REMOTE, onRemoteSalesforcePublishAdvancedConfigUpdated, { signal: eventController.signal })
}

export const stopSalesforcePublishAdvancedUi = () => {
  eventController?.abort()
  eventController = null
  clearPublishAdvancedConfigurationUiStates()
}
