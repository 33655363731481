
// constants
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

import { getTeamSession } from '../../session/team-session.mjs'

// events

import { dispatch } from '../../events/dispatch.mjs'

import {
  EVENT_COLUMN_FIELD_SCHEMA_DELETED,
  EVENT_COLUMN_FIELD_SCHEMA_DELETED_LOCAL,
  EVENT_COLUMN_FIELD_SCHEMA_DELETED_REMOTE
} from '../../events/constants/event-types.mjs'

import { createExternalEvent, emitEvent } from '../../events/Emitter.mjs'
import { subscribe, SCOPE_TEAM } from '../../events/external-events.mjs'

// entities
import { ColumnEntity } from '../../entity/ColumnEntity.mjs'
import { FieldSchemaEntity } from '../../entity/FieldSchemaEntity.mjs'
import { ColumnSetEntity } from '../../entity/ColumnSetEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'



//#region //--- Delete Column and field schema ---//
export const addEventsColumnFieldSchemaDelete = (controller, subscriptions, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  addEventListener(
    EVENT_COLUMN_FIELD_SCHEMA_DELETED_LOCAL,
    (event) => deleteColumnFieldSchemaEvent(event, columnManager, itemManager, { scope }),
    { signal: controller.signal }
  )
  // remote handler
  subscriptions.push(
    subscribe(
      EVENT_COLUMN_FIELD_SCHEMA_DELETED,
      deleteColumnFieldSchemaRemoteHandler,
      { scope }
    )
  )
  // remote
  addEventListener(
    EVENT_COLUMN_FIELD_SCHEMA_DELETED_REMOTE,
    (event) => deleteColumnFieldSchemaRemoteEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}

/**
 * Delete column and field schema event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const deleteColumnFieldSchemaEvent = async (event, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { column, fieldSchema } = event.detail
  const team = getTeamSession()
  const columnEntity = !column.isColumnEntity
    ? (new ColumnEntity(column))
    : column
  const fieldSchemaEntity = !fieldSchema.isFieldSchemaEntity
    ? (new FieldSchemaEntity(fieldSchema))
    : fieldSchema

  const externalEvent = createExternalEvent({
    action: EVENT_COLUMN_FIELD_SCHEMA_DELETED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list.id,
      columnSetId: columnManager.columnSet.id,
      fieldSchemaId: fieldSchemaEntity.id
    }
  })
  columnEntity.updatedByEventId = externalEvent.id
  await columnManager.deleteColumn(columnEntity, externalEvent)
  await columnManager.deleteFieldSchema(fieldSchemaEntity, externalEvent)
  columnManager.refreshColumns()
  itemManager.compileAllItems(columnManager.getFieldSchemas(), [fieldSchema.key])
  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
}


/**
 * Delete column and field schema event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const deleteColumnFieldSchemaRemoteEvent = async (event, columnManager, itemManager) => {
  const { columnSet, fieldSchema } = event.detail
  if (columnManager.columnSet.id !== columnSet.id) {
    return
  }
  columnManager.setColumnSet(columnSet)
  columnManager.setFieldSchema(fieldSchema)
  columnManager.refreshColumns()
  itemManager.compileAllItems(columnManager.getFieldSchemas(), [fieldSchema.key])
}


/**
 * Delete column and field schema external event handler
 * @param {*} event 
 * @returns 
 */
export const deleteColumnFieldSchemaRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('incoming event originated here. skip', eventId)
    return
  }
  const { columnSetId, fieldSchemaId } = payload
  let promises = []
  promises[0] = ColumnSetEntity.fetch(columnSetId)
  promises[1] = FieldSchemaEntity.fetch(fieldSchemaId)
  Promise.all(promises).then(values => {
    const [columnSet, fieldSchema] = values
    dispatch(EVENT_COLUMN_FIELD_SCHEMA_DELETED_REMOTE, { columnSet, fieldSchema })
  })
}
