/*!
 * User event handlers
 */

import { setCurrentUser } from '../../actions/current-user.mjs'

function onUserAdded({ id, data }){
}

function onUserRemoved({ id, data }) {
}

function onUserChanged({ id, data }) {
  setCurrentUser(data)
}


export {
  onUserAdded, onUserRemoved, onUserChanged,
}
