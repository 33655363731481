// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

//sessions
import { getTeamSession } from '../../session/team-session.mjs'

import { SCOPE_TEAM } from '../../events/external-events.mjs'
// user roles
import {
  VIEWER_USER_ROLE,
  EDITOR_USER_ROLE,
  ADMIN_USER_ROLE
} from '../../permissions/constants/roles.mjs'

// external events
import {
  EVENT_LIBRARY_ITEMS_UPDATED,
  EVENT_LIBRARY_ITEMS_UPDATED_REMOTE,
  EVENT_PROGRESS_START,
} from '../../events/constants/event-types.mjs'

import { dispatch } from '../../events/dispatch.mjs'

import { createTeamEvent, createExternalEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'

// actions
import { loadDeltaLibraryItems, getLocalItems } from '../../item/data/local-db-items.mjs'

//#region //--- library items updated ---//

/**
 * Selected library items updated event
 * @param {*} event 
 * @param {*} columnManager 
 * @param {*} itemManager 
 * @param {*} options: { scope }
 */
export const selectedItemsUpdateEvent = async (event, columnManager, itemManager, {
  scope = SCOPE_TEAM
} = {}) => {
  const { key, newValue, selectedItemsCount } = event.detail
  const team = getTeamSession()


  const externalEvent = createExternalEvent({
    action: EVENT_LIBRARY_ITEMS_UPDATED,
    scope,
    teamId: team.id,
    payload: {
      listId: columnManager.list?.id
    }
  })

  itemManager.updateSelectedItems(key, newValue, { event: externalEvent })
  itemManager.compileSelectedItems(columnManager.getFieldSchemas(), [key])
  dispatch(EVENT_PROGRESS_START, {
    label: 'Updating items...',
    maxValue: selectedItemsCount,
  })
  await itemManager.saveSelectedItems()

  setTimeout(() => emitEvent(externalEvent), 500)
}


/**
 * Selected library items updated external event handler
 * @param {*} event 
 * @returns 
 */
export const selectedItemUpdateRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (!eventId) {
    IS_DEBUG_EVENT_MODE && console.log('Missing event id. skip')
    return
  }
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('Incoming event originated here. skip', eventId)
    return
  }
  dispatch(EVENT_LIBRARY_ITEMS_UPDATED_REMOTE, { ...payload })
}

/**
 * Selected library items updated event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const selectedItemsUpdateRemoteEvent = async (event, columnManager, itemManager) => {
  const { listId, columnId, toIndex } = event.detail
  const team = getTeamSession()
  // current grid is library or list
  const listOpt = columnManager.list.id === team.libraryListId
    ? { libraryListId: team.libraryListId }
    : { listId: columnManager.list.id }


  // load items to grid
  loadDeltaLibraryItems(team.libraryListId).then(async () => {
    let itemIds = []
    await getLocalItems({
      ...listOpt,
      batchCallBack: (batch) => {
        itemIds = [ ...itemIds, ...batch.map(item => item.id )]
        itemManager.upsertItemsGridOnly(batch, columnManager.getFieldSchemas())
      }
    })
    itemManager.removeItemsNotInIds(itemIds)
  })
}

//#endregion