/*!
 * Number field type
 */

import { FieldType } from './FieldType.mjs'
import { isEmpty } from '../utils'

//Constants
import {
  KEY_IS_EMPTY, KEY_IS_TRUE, KEY_IS_FALSE, 
} from '../constants'


export class BooleanType extends FieldType {
  constructor() {
    super()
  }

  static compare(aValue, condition) {
    if (condition === KEY_IS_EMPTY) {
      return isEmpty(aValue)
    }
    return condition === KEY_IS_TRUE  ? aValue === true
         : condition === KEY_IS_FALSE ? aValue === false
         : null
  }
}