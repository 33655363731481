/*!
 * Filter group db functions
 */
import { throwError } from '@/utils/errors.mjs'
import { FilterGroupEntity } from '../entities/FilterGroupEntity.mjs'
import { FilterGroupDb } from '../db/FilterGroupDb.mjs'

const db = new FilterGroupDb()

async function getFilterGroup(id) {
  const filterGroup = await db.getById(id)
  return new FilterGroupEntity(filterGroup)
}

async function getListFilterGroups(listId) {
  const filterGroups = await db.getByConditions([
    ['listId', '==', listId],
    ['isDeleted', '==', false]
  ])
  return filterGroups.map(filterGroup => new FilterGroupEntity(filterGroup))
}

async function saveFilterGroupEntities(filterGroupEntities) {
  if (!Array.isArray(filterGroupEntities)) {
    throwError('filterGroupEntities must be an array')
  }
  if (filterGroupEntities.length === 0) {
    return
  }
  const saveObjects = filterGroupEntities
    .map(filterGroupEntity => db.createDocObject(filterGroupEntity))

  return db.upsertBatch(saveObjects)
}

async function saveFilterGroupEntity(filterGroupEntity) {
  return saveFilterGroupEntities([filterGroupEntity])
}

export {
  getFilterGroup, getListFilterGroups, saveFilterGroupEntities, saveFilterGroupEntity
}
