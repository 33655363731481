// Utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// external events
import {
  EVENT_COLUMN_SORT_ASC,
  EVENT_COLUMN_SORT_ASC_LOCAL,
  EVENT_COLUMN_SORT_DESC,
  EVENT_COLUMN_SORT_DESC_LOCAL,
} from '../../events/constants/event-types.mjs'

import { createTeamEvent } from '../../events/Emitter.mjs'



export const addEventsColumnSortAsc = (controller, columnManager) => {
  addEventListener(
    EVENT_COLUMN_SORT_ASC_LOCAL,
    (event) => sortAscColumnEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}

export const addEventsColumnSortDesc = (controller, columnManager) => {
  addEventListener(
    EVENT_COLUMN_SORT_DESC_LOCAL,
    (event) => sortDescColumnEvent(event, columnManager),
    { signal: controller.signal }
  )
  return
}



export const sortAscColumnEvent = async (event, columnManager) => {
  const { columnId } = event.detail
  const teamEvent = createTeamEvent(EVENT_COLUMN_SORT_ASC)
  await columnManager.sortAsc(columnId, { event: teamEvent })
}

export const sortDescColumnEvent = async (event, columnManager) => {
  const { columnId } = event.detail
  const teamEvent = createTeamEvent(EVENT_COLUMN_SORT_DESC)
  await columnManager.sortDesc(columnId, { event: teamEvent })
}