<template>
  <main-layout>
    <q-toolbar class="row items-center q-px-md q-pt-lg q-pb-xs">
      <q-toolbar-title class="text-dark text-weight-bold text-center">{{ $route.meta.title }}</q-toolbar-title>
    </q-toolbar>

    <template v-if="isLoading">
      <div
        style="position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%; top:0; left: 0; z-index: 99; background-color: rgba(255, 255, 255, 0.6);"
        :style="isMainMenuOpen ? 'margin-left: 150px;' : ''">
        <div class="is-loading"></div>
      </div>
    </template>

    <!-- Main Container -->
    <div class="container">
      <q-expansion-item header-style="border-bottom: 1px solid #efefef;margin-bottom: 0.5rem" header-class="text-h6"
        label="Overview" :default-opened="true">

        <!-- Integration Name -->
        <div class="row justify-start items-center q-pl-md q-pb-sm">
          <label>Integration Name</label>
          <q-input v-model="integrationName" :rules="[validateIntegrationName]" debounce="200" color="grey-7" bg-color="accent" outlined dense style="width: 60%;" />
        </div>
      </q-expansion-item>

      <!-- Connection -->
      <q-expansion-item header-style="border-bottom: 1px solid #efefef;margin-bottom: 0.5rem" header-class="text-h6"
        label="Connection">

        <!-- Username -->
        <div class="row justify-start items-center q-pl-md q-pb-sm">
          <label>Username</label>
          <q-input v-model="username" color="grey-7" bg-color="accent" outlined dense autocomplete="off"
            style="width: 60%;" />
        </div>

        <!-- Password -->
        <div class="row justify-start items-center q-pl-md q-pb-sm">
          <label>Password</label>
          <q-input :type="showPassword ? 'text' : 'password'" v-model="userPassword" color="grey-7" bg-color="accent"
            autocomplete="off" outlined dense style="width: 60%;">
            <template v-slot:append>
              <q-icon :name="showPassword ? 'o_visibility' : 'o_visibility_off'" class="cursor-pointer" size="xs"
                @click="showPassword = !showPassword" clearable="true" />
            </template>
          </q-input>
        </div>

        <!-- Security Token -->
        <div class="row justify-start items-center q-pl-md q-pb-sm">
          <label>Security Token</label>
          <q-input v-model="securityToken" color="grey-7" bg-color="accent" outlined dense style="width: 60%" />
        </div>

        <!-- Instance URL -->
        <div class="row justify-start items-center q-pl-md q-pb-sm">
          <label>Instance URL</label>
          <q-input v-model="instanceUrl" color="grey-7" bg-color="accent" outlined dense style="width: 60%;" />
        </div>
        <!-- Response Message -->
        <div v-if="testResponse?.length"
          style="color: #888; margin-left: 1rem; margin-right: 1.5rem; margin-top: 1rem; background-color: #efefef; 
          border-radius: .4rem; padding: 1rem; font-size: 0.7rem; font-family: monaco, monospace, 'Courier New', Courier; max-height: 200px overflow: auto;">
          <strong>Test Result:</strong>
          <br><br>
          {{ testResponse }}
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 2rem; margin-bottom: 2rem;">
          <!-- Test Connection Button -->
          <q-btn @click="onTestConnection" no-caps unelevated color="secondary" text-color="#aaa" type="submit"
            style="margin-right: 1rem;" label="Test Connection" :loading="isTestingConnection" v-show="integrationName
              && username
              && userPassword
              && securityToken
              && instanceUrl" />
        </div>
      </q-expansion-item>

      <!-- lookup json config -->
      <q-expansion-item header-style="border-bottom: 1px solid #efefef;margin-bottom: 0.4rem" header-class="text-h6"
        :label="`Lookup Configuration (${lookupJsonConfig ? lookupJsonConfig.length : 0})`">
        <div>
          <q-btn flat color="grey-6" no-caps>Add
            <q-menu>
              <q-list dense style="min-width: 100px">
                <q-item clickable v-close-popup @click="onAddLookupConfig(LOOKUP_JSON_CONFIG_TYPE_QUERY)">
                  <q-item-section>By query</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onAddLookupConfig(LOOKUP_JSON_CONFIG_TYPE_DATA)">
                  <q-item-section>By data</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onAddLookupConfig(LOOKUP_JSON_CONFIG_TYPE_LIST)">
                  <q-item-section>By List</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-btn v-show="lookupJsonConfig.length > 0" @click="onClearLookupConfig" flat color="grey-6"
            no-caps>Clear</q-btn>
          <q-btn v-show="lookupJsonConfig.length > 0" @click="onDeleteLookupConfig" flat color="grey-6"
            no-caps>Delete</q-btn>
        </div>
        <template v-if="lookupJsonConfig[currentLookupCfgIndex]">
          <div class="q-px-md">
            <q-tabs v-model="currentLookupCfgIndex" class="text-grey-5 text-weight-medium" indicator-color="primary"
              active-color="dark" style="border-bottom: 1px solid #EBEEF5;" align="left">
              <template v-for="(config, index) in lookupJsonConfig" :key="index">
                <q-tab :name="index" :label="config.name" :no-caps="true" />
              </template>
            </q-tabs>
            <q-tab-panels v-show="lookupJsonConfig.length > 0" v-model="currentLookupCfgIndex" animated>
              <q-tab-panel :name="currentLookupCfgIndex">
                <div class="row justify-end">
                  <q-toggle v-model="lookupJsonEditor[currentLookupCfgIndex].jsonEditor.isJsonMode"
                    :disable="!lookupJsonEditor[currentLookupCfgIndex].jsonEditor.isActive" color="primary"
                    label="JSON Editor" left-label />
                </div>
                <JsonEditorVue v-if="lookupJsonEditor[currentLookupCfgIndex].jsonEditor.isJsonMode"
                  v-model="lookupJsonConfig[currentLookupCfgIndex]" :mode="lookupJsonEditorMode"
                  v-bind="{/* local props & attrs */ }" @update:model-value="onLookupConfigChanged" />
                <template
                  v-if="!lookupJsonEditor[currentLookupCfgIndex].jsonEditor.isJsonMode && lookupJsonConfig[currentLookupCfgIndex]">
                  <div class="row justify-start">
                    <q-input v-model="lookupJsonConfig[currentLookupCfgIndex].name" class="col q-mb-sm" label="Name"
                      bg-color="accent" outlined dense />
                    <q-select v-model="lookupJsonConfig[currentLookupCfgIndex].type" class="col q-pl-sm q-mb-sm"
                      :options="LOOKUP_JSON_CONFIG_TYPES" label="Type" bg-color="accent" outlined dense disable
                      @update:model-value="onLookupJsonConfigTypeChanged(lookupJsonConfig[currentLookupCfgIndex].type)" />
                    <q-input v-if="lookupJsonConfig[currentLookupCfgIndex].type === LOOKUP_JSON_CONFIG_TYPE_QUERY"
                      v-model="lookupJsonConfig[currentLookupCfgIndex].query" class="col q-mb-sm" label="Query"
                      bg-color="accent" outlined dense />
                      <q-select v-if="lookupJsonConfig[currentLookupCfgIndex].type === LOOKUP_JSON_CONFIG_TYPE_LIST"
                      v-model="lookupJsonConfig[currentLookupCfgIndex].listId" class="col q-mb-sm" label="List"
                      :options="teamPriceLists.filter(list => isImportList(list))" 
                      :option-value="'id'" 
                      :option-label="'name'"
                      map-options 
                      emit-value
                      bg-color="accent" outlined dense />
                  </div>
                  <JsonEditorVue v-if="lookupJsonConfig[currentLookupCfgIndex].type === LOOKUP_JSON_CONFIG_TYPE_DATA"
                    v-model="lookupJsonConfigDataTable[currentLookupCfgIndex]" :mode="JSON_EDITOR_MODES.table"
                    v-bind="{/* local props & attrs */ }" @update:model-value="onLookupConfigTypeDataChanged" />
                </template>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </template>
      </q-expansion-item>

      <!-- mapping json config -->
      <q-expansion-item header-style="border-bottom: 1px solid #efefef;margin-bottom: 0.4rem" header-class="text-h6"
        :label="`Mapping Configuration (${mappingJsonConfig ? mappingJsonConfig.length : 0})`">
        <div>
          <q-btn @click="onAddMappingConfig" flat color="grey-6" no-caps>Add</q-btn>
          <q-btn v-show="mappingJsonConfig.length > 0" @click="onClearMappingConfig" flat color="grey-6"
            no-caps>Clear</q-btn>
          <q-btn v-show="mappingJsonConfig.length > 0" @click="onDeleteMappingConfig" flat color="grey-6"
            no-caps>Delete</q-btn>
          <q-btn v-show="mappingJsonConfig.length > 0" @click="onCloneMappingConfig" flat color="grey-6"
            no-caps>Clone</q-btn>
        </div>
        <template v-if="mappingJsonConfig[currentMappingCfgIndex]">
          <div class="q-px-none">
            <q-tabs v-model="currentMappingCfgIndex" class="text-grey-5 text-weight-medium" indicator-color="primary"
              active-color="dark" style="border-bottom: 1px solid #EBEEF5;" align="left">
              <template v-for="(config, index) in mappingJsonConfig" :key="index">
                <q-tab :name="index" :label="config.name" :no-caps="true" />
              </template>
            </q-tabs>
            <q-tab-panels v-show="mappingJsonConfig.length > 0" v-model="currentMappingCfgIndex" animated>
              <q-tab-panel :name="currentMappingCfgIndex">
                <div class="row justify-end">
                  <q-toggle v-model="mappingJsonEditor[currentMappingCfgIndex].jsonEditor.isJsonMode" color="primary"
                    :disable="!mappingJsonEditor[currentMappingCfgIndex].jsonEditor.isActive" label="JSON Editor"
                    left-label />
                </div>
                <JsonEditorVue v-if="mappingJsonEditor[currentMappingCfgIndex].jsonEditor.isJsonMode"
                  v-model="mappingJsonConfig[currentMappingCfgIndex]" :mode="mappingJsonEditorMode"
                  v-bind="{/* local props & attrs */ }" @update:model-value="onMappingConfigChanged" />
                <template
                  v-if="!mappingJsonEditor[currentMappingCfgIndex].jsonEditor.isJsonMode && mappingJsonConfig[currentMappingCfgIndex]">
                  <div class="row justify-start">
                    <q-input v-model.trim="mappingJsonConfig[currentMappingCfgIndex].name" class="col q-mb-sm"
                      label="Name" bg-color="accent" outlined dense />
                    <q-input v-model.trim="mappingJsonConfig[currentMappingCfgIndex].object" class="col q-pl-sm q-mb-sm"
                      label="Object" bg-color="accent" outlined dense />
                    <q-select v-model="mappingJsonConfig[currentMappingCfgIndex].updateByDestinationKeys"
                      class="col q-mb-sm" label="Update By Destination Keys"
                      :options="mappingJsonConfig[currentMappingCfgIndex].fieldMapping" option-value="destinationKey"
                      option-label="destinationKey" emit-value color="primary" use-chips multiple bg-color="accent"
                      outlined dense />
                  </div>
                  <div v-if="!mappingJsonConfig[currentMappingCfgIndex].updateByDestinationKeys?.length"
                    style="color:red">
                    *Requires 1 or more destination keys
                  </div>
                  <div class="row justify-start">
                    <q-toggle v-model="mappingJsonConfig[currentMappingCfgIndex].skipOnRequiredFailure"
                      class="q-pl-sm q-mb-sm" label="Skip on required failure" left-label />
                    <q-toggle v-model="mappingJsonConfig[currentMappingCfgIndex].updateOnly" class="q-pl-sm q-mb-sm"
                      label="Update only" left-label />
                  </div>
                  <q-separator class="q-mb-sm" size="0.1rem" color="primary" />

                  <!-- Field mapping -->
                  <q-expansion-item class="q-mt-md" header-class="text-subtitle1 text-weight-medium" default-opened
                    :label="`Field Mapping (${mappingJsonConfig[currentMappingCfgIndex].fieldMapping
                      ? mappingJsonConfig[currentMappingCfgIndex].fieldMapping.length
                      : '0'})`">
                    <div>
                      <q-btn no-caps flat color="grey-6">Add
                        <q-menu>
                          <q-list dense style="min-width: 100px">
                            <q-item clickable v-close-popup @click="onAddFieldMap(MAP_TYPE_KEY)">
                              <q-item-section>By key</q-item-section>
                            </q-item>
                            <q-item clickable v-close-popup @click="onAddFieldMap(MAP_TYPE_VALUE)">
                              <q-item-section>By value </q-item-section>
                            </q-item>
                            <q-item clickable v-close-popup @click="onAddFieldMap(MAP_TYPE_COLUMN_NAME)">
                              <q-item-section>By column name</q-item-section>
                            </q-item>
                            <q-item clickable v-close-popup @click="onAddFieldMap(MAP_TYPE_LOOKUP)">
                              <q-item-section>By lookup</q-item-section>
                            </q-item>
                            <q-item clickable v-close-popup @click="onAddFieldMap(MAP_TYPE_PARENT_RESULT)">
                              <q-item-section>By parent result</q-item-section>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                      <q-btn no-caps flat color="grey-6"  @click="sortFieldMapping(mappingJsonConfig[currentMappingCfgIndex].fieldMapping)">Sort</q-btn>
                    </div>
                    <div style="min-height: 0px; overflow: auto; max-height: 500px; height: 100%;">
                      <q-list separator dense>
                        <template v-for="(fieldMapping, index) in mappingJsonConfig[currentMappingCfgIndex].fieldMapping"
                          :key="index">
                          <q-item dense>
                            <q-item-section>
                              <q-expansion-item class="q-mt-xs" 
                                :label="fieldMapping.destinationKey||'*Missing Destination Key'"
                                :caption="getFieldMappingCaption(fieldMapping)"
                                dense
                                expand-separator group="fieldMapExpanded"
                                :default-opened = !fieldMapping.destinationKey
                                :header-style="!fieldMapping.destinationKey ? 'color: red;' : 'color: black;'">
                                <div class="row justify-start">
                                  <div style="max-width: 150px;">
                                    <q-select v-model="fieldMapping.type" :options="FIELD_MAP_TYPE_OPTIONS"
                                      option-value="value" option-label="label" bg-color="accent" outlined dense
                                      label="By Type" @update:model-value="onFieldMapTypeChanged(fieldMapping, index)"
                                      emit-value disable style="min-width: 150px;" />
                                  </div>
                                  <q-toggle v-model="fieldMapping.isRequired" class="col offset-1 q-mb-sm"
                                    label="Required" left-label />
                                </div>
                                <div class="row justify-start">
                                  <q-input v-model.trim="fieldMapping.destinationKey" class="col q-mb-sm"
                                    label="Destination Key" bg-color="accent" outlined dense autofocus />
                                  <template v-if="fieldMapping.type === MAP_TYPE_KEY">
                                    <q-select v-model="fieldMapping.key" emit-value :options="peFields" option-value="key"
                                      option-label="label" class="col q-pl-sm q-mb-sm" label="ProductEngine Key"
                                      bg-color="accent" outlined dense />
                                  </template>
                                  <template v-if="fieldMapping.type === MAP_TYPE_PARENT_RESULT">
                                    <q-input v-model="fieldMapping.key" class="col q-pl-sm q-mb-sm" label="Result Key"
                                      bg-color="accent" outlined dense disable />
                                  </template>
                                  <template v-if="fieldMapping.type === MAP_TYPE_VALUE">
                                    <q-input v-model="fieldMapping.value" class="col q-pl-sm q-mb-sm" label="Value"
                                      bg-color="accent" outlined dense />
                                  </template>
                                  <template v-if="fieldMapping.type === MAP_TYPE_COLUMN_NAME">
                                    <q-input v-model.trim="fieldMapping.columnName" class="col q-pl-sm q-mb-sm"
                                      label="Column Name" bg-color="accent" outlined dense />
                                  </template>
                                </div>
                                <template v-if="fieldMapping.type === MAP_TYPE_LOOKUP">                            
                                  <q-select v-model="fieldMapping.lookupName" class="q-mb-sm" label="Lookup Name"
                                    bg-color="accent" outlined dense 
                                    :options="lookupJsonConfig" option-label="name" option-value="name" emit-value
                                  />
                                  <q-input v-model.trim="fieldMapping.lookupResultKey" class="q-mb-sm"
                                    label="Lookup Result Key" bg-color="accent" outlined dense />
                                    <div class="row items-center">
                                    <div style="width: 200px; text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Lookup Column</div>
                                    <div style="width: 80px;  text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Operator</div>
                                    <div style="width: 100px; text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Match By</div>
                                    </div>
                                  <div class="row items-center" v-for="(condition, index) in fieldMapping.lookupConditions" :key="index">
                                    <div style="max-width: 200px;" class="q-mb-sm q-mr-sm">
                                      <q-input style="min-width: 200px;" v-model="condition.lookupKey"
                                        bg-color="accent" outlined dense />
                                    </div>
                                    <div style="max-width: 80px;" class="q-mb-sm q-mr-sm">
                                      <q-select v-model="condition.matchOp" :options="lookupOperators"
                                        bg-color="accent" outlined dense
                                        style="min-width: 80px;" />
                                    </div>
                                    <div style="max-width: 100px;" class="q-mb-sm q-mr-sm">
                                      <q-select v-model="condition.matchBy" :options="['key','value','column']"
                                        bg-color="accent" outlined dense 
                                         style="min-width: 100px;" />
                                    </div>
                                    <div style="max-width: 200px;" class="q-mb-sm q-mr-sm">
                                      <q-select v-show="condition.matchBy === 'key'" style="min-width: 200px;" v-model="condition.matchValue"  :options="peFields" option-value="key"
                                        :label="condition.matchValue ? void 0 : 'ProductEngine Key'" option-label="label" emit-value
                                        bg-color="accent" outlined dense />
                                      <q-input v-show="condition.matchBy === 'value'" style="min-width: 200px;" v-model.trim="condition.matchValue" bg-color="accent"
                                        :label="condition.matchValue ? void 0 : 'Value'"  outlined dense />
                                      <q-input v-show="condition.matchBy === 'column'" style="min-width: 200px;" v-model.trim="condition.matchValue" bg-color="accent"
                                      :label="condition.matchValue ? void 0 : 'Column Name'" outlined dense />
                                    </div>
                                    <div v-show="fieldMapping.lookupConditions?.length > 1" style="max-width: 10px;" class="q-mb-sm q-mr-sm">
                                      <q-btn class="q-mt-xs" style="min-width: 10px;" unelevated icon="close" dense text-color="grey-8" @click="onRemoveCondition(fieldMapping.lookupConditions, index)"/>
                                    </div>
                                  </div>  
                                  <div>
                                    <q-btn icon="add" @click="onAddCondition(fieldMapping.lookupConditions)" no-caps unelevated text-color="primary" dense >Add Condition</q-btn>
                                  </div>
                                </template>
                              </q-expansion-item>
                            </q-item-section>
                            <q-item-section side top>
                              <q-btn class="q-mt-xs" @click="onRemoveFieldMap(index)" unelevated icon="close" dense />
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-list>
                    </div>
                  </q-expansion-item>
                  <q-separator class="q-mb-sm" size="0.1rem" color="primary" />

                  <!-- children mapping json config -->
                  <q-expansion-item class="q-mt-md" header-class="text-subtitle1 text-weight-medium" default-opened:false
                    :label="`Children Mapping (${mappingJsonConfig[currentMappingCfgIndex]?.children
                      ? mappingJsonConfig[currentMappingCfgIndex].children.length
                      : '0'})`">
                    <div>
                      <q-btn @click="onAddChildMappingConfig" flat color="grey-6" no-caps>Add</q-btn>
                      <q-btn v-show="mappingJsonConfig[currentMappingCfgIndex].children"
                        @click="onClearChildMappingConfig" flat color="grey-6" no-caps>Clear</q-btn>
                      <q-btn v-show="mappingJsonConfig[currentMappingCfgIndex].children"
                        @click="onDeleteChildMappingConfig" flat color="grey-6" no-caps>Delete</q-btn>
                      <q-btn v-show="mappingJsonConfig[currentMappingCfgIndex].children"
                        @click="onCloneChildMappingConfig" flat color="grey-6" no-caps>Clone</q-btn>
                    </div>
                    <template v-if="mappingJsonConfig[currentMappingCfgIndex].children">
                      <div class="q-px-none">
                        <q-tabs v-model="currentChildMappingCfgIndex" class="text-grey-5 text-weight-medium"
                          indicator-color="primary" active-color="dark" style="border-bottom: 1px solid #EBEEF5;"
                          align="left">
                          <template v-for="(config, index) in mappingJsonConfig[currentMappingCfgIndex].children"
                            :key="index">
                            <q-tab :name="index" :label="config.name" :no-caps="true" />
                          </template>
                        </q-tabs>
                        <q-tab-panels v-model="currentChildMappingCfgIndex" animated>
                          <q-tab-panel :name="currentChildMappingCfgIndex">
                            <template
                              v-if="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex]">
                              <div class="row justify-start">
                                <q-input
                                  v-model.trim="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].name"
                                  class="col q-mb-sm" label="Name" bg-color="accent" outlined dense />
                                <q-input
                                  v-model.trim="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].object"
                                  class="col q-pl-sm q-mb-sm" label="Object" bg-color="accent" outlined dense />
                                <q-select
                                  v-model="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].updateByDestinationKeys"
                                  class="col q-mb-sm" label="Update By Destination Keys"
                                  :options="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].fieldMapping"
                                  option-value="destinationKey" option-label="destinationKey" emit-value color="primary"
                                  use-chips multiple bg-color="accent" outlined dense />
                              </div>
                              <div
                                v-if="!mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].updateByDestinationKeys?.length"
                                style="color:red">
                                *Requires 1 or more destination keys
                              </div>
                              <div class="row justify-start">
                                <q-toggle
                                  v-model="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].skipOnRequiredFailure"
                                  class="q-pl-sm q-mb-sm" label="Skip on required failure" left-label />
                                <q-toggle
                                  v-model="mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].updateOnly"
                                  class="q-pl-sm q-mb-sm" label="Update only" left-label />
                              </div>
                              <q-separator class="q-mb-sm" size="0.1rem" color="primary" />

                              <!-- Child field mapping -->
                              <q-expansion-item class="q-mt-md" header-class="text-subtitle1 text-weight-medium"
                                default-opened :label="`Child Field Mapping (${mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].fieldMapping
                                  ? mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].fieldMapping.length
                                  : '0'})`">
                                <q-btn no-caps flat color="grey-6">Add
                                  <q-menu>
                                    <q-list dense style="min-width: 100px">
                                      <q-item clickable v-close-popup @click="onAddChildrenFieldMap(MAP_TYPE_KEY)">
                                        <q-item-section>By key</q-item-section>
                                      </q-item>
                                      <q-item clickable v-close-popup @click="onAddChildrenFieldMap(MAP_TYPE_VALUE)">
                                        <q-item-section>By value </q-item-section>
                                      </q-item>
                                      <q-item clickable v-close-popup
                                        @click="onAddChildrenFieldMap(MAP_TYPE_COLUMN_NAME)">
                                        <q-item-section>By column name</q-item-section>
                                      </q-item>
                                      <q-item clickable v-close-popup @click="onAddChildrenFieldMap(MAP_TYPE_LOOKUP)">
                                        <q-item-section>By lookup</q-item-section>
                                      </q-item>
                                      <q-item clickable v-close-popup
                                        @click="onAddChildrenFieldMap(MAP_TYPE_PARENT_RESULT)">
                                        <q-item-section>By parent result</q-item-section>
                                      </q-item>

                                    </q-list>
                                  </q-menu>
                                </q-btn>
                                <q-btn no-caps flat color="grey-6"  @click="sortFieldMapping(mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].fieldMapping)">Sort</q-btn>

                                <div style="min-height: 0px; overflow: auto; max-height: 500px; height: 100%;">
                                  <q-list separator dense>
                                    <template
                                      v-for="(fieldMapping, index) in mappingJsonConfig[currentMappingCfgIndex].children[currentChildMappingCfgIndex].fieldMapping"
                                      :key="index">
                                      <q-item dense>
                                        <q-item-section>
                                          <q-expansion-item class="q-mt-xs"
                                            :label="fieldMapping.destinationKey||'*Missing Destination Key'"
                                            :caption="getFieldMappingCaption(fieldMapping)"
                                            dense expand-separator group="fieldMapExpanded"
                                            :default-opened = !fieldMapping.destinationKey
                                            :header-style="!fieldMapping.destinationKey ? 'color: red;' : 'color: black;'">
                                            <div class="row justify-start">
                                              <div style="max-width: 150px;">
                                                <q-select v-model="fieldMapping.type" :options="FIELD_MAP_TYPE_OPTIONS"
                                                  option-value="value" option-label="label" bg-color="accent" outlined
                                                  dense label="By Type"
                                                  @update:model-value="onChildrenFieldMapTypeChanged(fieldMapping, index)"
                                                  emit-value disable style="min-width: 150px;" />
                                              </div>
                                              <q-toggle v-model="fieldMapping.isRequired" class="col offset-1 q-mb-sm"
                                                label="Required" left-label />
                                            </div>                                
                                            <div class="row justify-start">
                                              <q-input v-model.trim="fieldMapping.destinationKey" class="col q-mb-sm"
                                                label="Destination Key" bg-color="accent" outlined dense autofocus />
                                              <template v-if="fieldMapping.type === MAP_TYPE_KEY">
                                                <q-select v-model="fieldMapping.key" emit-value :options="peFields" option-value="key"
                                                  option-label="label" class="col q-pl-sm q-mb-sm" label="ProductEngine Key"
                                                  bg-color="accent" outlined dense />
                                              </template>
                                              <template v-if="fieldMapping.type === MAP_TYPE_PARENT_RESULT">
                                                <q-input v-model="fieldMapping.key" class="col q-pl-sm q-mb-sm" label="Result Key"
                                                  bg-color="accent" outlined dense disable />
                                              </template>
                                              <template v-if="fieldMapping.type === MAP_TYPE_VALUE">
                                                <q-input v-model="fieldMapping.value" class="col q-pl-sm q-mb-sm" label="Value"
                                                  bg-color="accent" outlined dense />
                                              </template>
                                              <template v-if="fieldMapping.type === MAP_TYPE_COLUMN_NAME">
                                                <q-input v-model.trim="fieldMapping.columnName" class="col q-pl-sm q-mb-sm"
                                                  label="Column Name" bg-color="accent" outlined dense />
                                              </template>
                                            </div>
                                            <template v-if="fieldMapping.type === MAP_TYPE_LOOKUP">                            
                                              <q-select v-model="fieldMapping.lookupName" class="q-mb-sm" label="Lookup Name"
                                                bg-color="accent" outlined dense 
                                                :options="lookupJsonConfig" option-label="name" option-value="name" emit-value
                                              />
                                              <q-input v-model.trim="fieldMapping.lookupResultKey" class="q-mb-sm"
                                                label="Lookup Result Key" bg-color="accent" outlined dense />
                                                <div class="row items-center">
                                                  <div style="width: 200px; text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Lookup Column</div>
                                                  <div style="width: 80px;  text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Operator</div>
                                                  <div style="width: 100px; text-align: center;" class="q-mt-sm q-mb-xs q-mr-sm">Match By</div>
                                                </div>
                                              <div class="row items-center" v-for="(condition, index) in fieldMapping.lookupConditions" :key="index">
                                                <div style="max-width: 200px;" class="q-mb-sm q-mr-sm">
                                                  <q-input style="min-width: 200px;" v-model="condition.lookupKey"
                                                    bg-color="accent" outlined dense />
                                                </div>
                                                <div style="max-width: 80px;" class="q-mb-sm q-mr-sm">
                                                  <q-select v-model="condition.matchOp" :options="lookupOperators"
                                                    bg-color="accent" outlined dense
                                                    style="min-width: 80px;" />
                                                </div>
                                                <div style="max-width: 100px;" class="q-mb-sm q-mr-sm">
                                                  <q-select v-model="condition.matchBy" :options="['key','value','column']"
                                                    bg-color="accent" outlined dense 
                                                    style="min-width: 100px;" />
                                                </div>
                                                <div style="max-width: 200px;" class="q-mb-sm q-mr-sm">
                                                  <q-select v-show="condition.matchBy === 'key'" style="min-width: 200px;" v-model="condition.matchValue"  :options="peFields" option-value="key"
                                                    :label="condition.matchValue ? void 0 : 'ProductEngine Key'" option-label="label" emit-value
                                                    bg-color="accent" outlined dense />
                                                  <q-input v-show="condition.matchBy === 'value'" style="min-width: 200px;" v-model.trim="condition.matchValue" bg-color="accent"
                                                    :label="condition.matchValue ? void 0 : 'Value'"  outlined dense />
                                                  <q-input v-show="condition.matchBy === 'column'" style="min-width: 200px;" v-model.trim="condition.matchValue" bg-color="accent"
                                                  :label="condition.matchValue ? void 0 : 'Column Name'" outlined dense />
                                                </div>
                                                <div v-show="fieldMapping.lookupConditions?.length > 1" style="max-width: 10px;" class="q-mb-sm q-mr-sm">
                                                  <q-btn style="min-width: 10px;" unelevated icon="close" dense text-color="grey-8" @click="onRemoveCondition(fieldMapping.lookupConditions, index)"/>
                                                </div>
                                              </div>  <!--end v-for-->
                                              <div>
                                                <q-btn icon="add" @click="onAddCondition(fieldMapping.lookupConditions)" no-caps unelevated text-color="primary" dense >Add Condition</q-btn>
                                              </div>
                                            </template>
                                          </q-expansion-item>
                                        </q-item-section>
                                        <q-item-section side top>
                                          <q-btn class="q-mt-xs" @click="onRemoveChildrenFieldMap(index)" unelevated
                                            icon="close" dense />
                                        </q-item-section>
                                      </q-item>
                                    </template>
                                  </q-list>
                                </div>
                              </q-expansion-item>
                              <q-separator class="q-mb-sm" size="0.1rem" color="primary" />
                            </template>
                          </q-tab-panel>
                        </q-tab-panels>
                      </div>
                    </template>
                  </q-expansion-item>
                </template>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </template>
      </q-expansion-item>

      <div style="display: flex; justify-content: flex-end; margin-top: 2rem;">
        <!-- Connection Save Button -->
        <q-btn @click="onSaveConnection" no-caps unelevated style="margin-right: 1rem;" type="submit" color="primary"
          text-color="white" :loading="isSavingConnection" label="Save" />
      </div>
    </div>
    <!-- End: Main Container -->

  </main-layout>
</template>

<style scoped>
.container {
  width: 100%;
  margin: auto;
  max-width: 800px;
  padding-bottom: 3rem;
}

.container label {
  min-width: 18rem;
  color: #6e6e6e;
  font-weight: 500;
}

.container h6 {
  border-bottom: 1px solid #efefef;
  margin: 1rem 0;
  margin-top: 2rem;
}

.container .input-group {
  display: flex;
  align-items: center;
  padding-top: .4rem;
}

</style>

<script setup>

// Dependencies
import { ref, onMounted, onUnmounted, triggerRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Styles
import '../../../styles/theme.css'

// Layouts
import MainLayout from '../../../layouts/MainLayout.vue'

// Components

// Refs
import {
  integrationName,
  validateIntegrationName,
  parseIntegrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import {
  // connection UI
  testResponse,
  username,
  userPassword,
  securityToken,
  instanceUrl,
  mappingJsonConfig,
  lookupJsonConfig,
  lookupJsonConfigDataTable,
  peFields,
  lookupJsonEditor,
  mappingJsonEditor,
} from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'

import { isMainMenuOpen, teamPriceLists } from '../../../ui/ui-states.mjs'
import { newJsonEditorState } from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'

// events
import { EVENT_INTEGRATION_DELETED_REMOTE } from '../../../events/constants/event-types.mjs'
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_PUBLISH_ADVANCED } from '../../registered-apps.mjs'

// Actions
import { createIntegratonFromUi, saveIntegration } from '../../../integration-modules/salesforce/actions/save-integration.mjs'
import { startSalesforceUi, stopSalesforceUi } from '../../../integration-modules/salesforce/actions/salesforce-connection-ui.mjs'
import { startSalesforcePublishAdvancedUi, stopSalesforcePublishAdvancedUi } from '../actions/ui-actions.mjs'

import { testConnection } from '../../../integration-modules/salesforce/actions/test-connection.mjs'

// field mapping
import {
  MAP_TYPE_KEY,
  MAP_TYPE_VALUE,
  MAP_TYPE_LOOKUP,
  MAP_TYPE_PARENT_RESULT,
  MAP_TYPE_COLUMN_NAME,
  FIELD_MAP_TYPE_OPTIONS
} from '../../../integrations/constants/field-map-types.mjs'
import { newAdvancedFieldMap, newCondition } from '../../../integrations/utils/ui-field-mapping-utils.mjs'

// constants
import { CHAR_EMPTY } from '../../../constants/characters.mjs'
import { CSV_PRICE_LIST } from '../../../list-core/constants/list-constants.mjs'

// Setup
const INTEGRATION_PATH = '/integrations'
const router = useRouter()
const showPassword = ref(false)
const isTestingConnection = ref(false)
const isSavingConnection = ref(false)
const mappingJsonErrors = ref('')
const lookupJsonErrors = ref('')
const isLoading = ref(true)
const currentLookupCfgIndex = ref(0)
const currentMappingCfgIndex = ref(0)
const currentChildMappingCfgIndex = ref(0)

const LOOKUP_JSON_CONFIG_TYPE_DATA = 'data'
const LOOKUP_JSON_CONFIG_TYPE_QUERY = 'query'
const LOOKUP_JSON_CONFIG_TYPE_LIST = 'list'
const LOOKUP_JSON_CONFIG_TYPES = [LOOKUP_JSON_CONFIG_TYPE_QUERY, LOOKUP_JSON_CONFIG_TYPE_DATA, LOOKUP_JSON_CONFIG_TYPE_LIST]

const JSON_EDITOR_MODES = {
  text: 'text',
  tree: 'tree',
  table: 'table'
}

const lookupOperators = ref(['=='])
const lookupJsonEditorMode = ref(JSON_EDITOR_MODES.text)
const mappingJsonEditorMode = ref(JSON_EDITOR_MODES.text)

let integrationId = useRoute().params.id
isLoading.value = true


// Mount hook
onMounted(async () => {
  addEventListener(EVENT_INTEGRATION_DELETED_REMOTE, onIntegrationDeleted)
  await startSalesforceUi(integrationId)
  await startSalesforcePublishAdvancedUi(integrationId)
  isLoading.value = false
})

onUnmounted(() => {
  stopSalesforceUi()
  stopSalesforcePublishAdvancedUi()
})

/**
 * reset path when integration is deleted remote
 */
function onIntegrationDeleted(event) {
  if (event.detail === integrationId) {
    router.push({ path: INTEGRATION_PATH })
  }
}



/**
 * Save Connection
 */
async function onSaveConnection() {
  mappingJsonErrors.value = ''
  lookupJsonErrors.value = ''
  isSavingConnection.value = true
  integrationName.value = parseIntegrationName(integrationName.value)
  const integration = await createIntegratonFromUi(APP_SFDC_PUBLISH_ADVANCED)
  const response = await saveIntegration(integration)

  //TODO: PL - error handling on save
  if (!response.success) {
    console.log(response.error)
    return
  }
  const routeChanged = integrationId !== response.data.id
  integrationId = response.data.id
  isSavingConnection.value = false
  if (routeChanged) {
    location.href = response.data.subtype + '/' + response.data.id
  }
}

async function onTestConnection() {
  isTestingConnection.value = true
  const result = await testConnection({
    username: username.value,
    password: userPassword.value,
    securityToken: securityToken.value,
    instanceUrl: instanceUrl.value
  })
  isTestingConnection.value = false
  return result
}


//#region /-- lookup config tab control --/
// lookup config tabs
function onLookupConfigChanged(input) {
  const index = currentLookupCfgIndex.value
  input ||= '{}' // Setting to empty to avoid UI display error 

  try {
    lookupJsonConfig.value[index] = typeof (input) === 'string'
      ? JSON.parse(input)
      : input
    lookupJsonEditor.value[index].jsonEditor.isActive = true
  } catch (error) {
    lookupJsonEditor.value[index].jsonEditor.isActive = false
    console.error('Error parsing JSON:', error)
    return // Exit the function if JSON parsing fails
  }

  const jsonData = lookupJsonConfig.value[index]
  if (!jsonData.rows) {
    lookupJsonConfigDataTable.value[index] = []
    return
  }
  lookupJsonConfigDataTable.value[index] = jsonData?.rows.map(row => {
    const obj = {}
    jsonData.columns.forEach((column, idx) => {
      obj[column] = row[idx]
    })
    return obj
  })
  return
}

function onLookupJsonConfigTypeChanged(type) {
  console.log('start of onLookupJsonConfigTypeChanged ')

  const index = currentLookupCfgIndex.value
  switch (type) {
    case LOOKUP_JSON_CONFIG_TYPE_DATA: {
      if (!Array.isArray(lookupJsonConfigDataTable.value[index]) || !lookupJsonConfigDataTable.value[index].length) {
        lookupJsonConfigDataTable.value[index] = [{ NewColumn: 'NewValue' }]
        onLookupConfigTypeDataChanged(lookupJsonConfigDataTable.value[index])
      }
      break
    }
  }
  console.log('end of onLookupJsonConfigTypeChanged ')
}

function onLookupConfigTypeDataChanged(input) {

  const index = currentLookupCfgIndex.value
  input ||= '[]' // Setting to empty to avoid UI display error
  try {
    lookupJsonConfigDataTable.value[index] = typeof (input) === 'string'
      ? JSON.parse(input)
      : input
    lookupJsonEditor.value[index].jsonEditor.isActive = true
  } catch (error) {
    lookupJsonEditor.value[index].jsonEditor.isActive = false
    console.error('Error parsing JSON:', error)
    return // Exit the function if JSON parsing fails
  }

  const jsonData = lookupJsonConfigDataTable.value[index]
  const uniqueKeys = [...new Set(jsonData.flatMap(obj => Object.keys(obj)))]
  const valuesArray = jsonData.map(obj => {
    return uniqueKeys.map(key => {
      return Object.hasOwn(obj, key) ? obj[key] : CHAR_EMPTY
    })
  })
  lookupJsonConfig.value[index].columns = uniqueKeys
  lookupJsonConfig.value[index].rows = valuesArray
}

function onAddLookupConfig(type) {
  const index = currentLookupCfgIndex.value
  currentLookupCfgIndex.value = lookupJsonConfig.value.length ? (currentLookupCfgIndex.value + 1) : 0
  lookupJsonConfig.value.splice(currentLookupCfgIndex.value, 0, { name: 'New Lookup', type: type })
  lookupJsonEditor.value[currentLookupCfgIndex.value] = { jsonEditor: newJsonEditorState() } //set JSON editor view to false on new config
  onLookupJsonConfigTypeChanged(type)

}

function onClearLookupConfig() {
  const index = currentLookupCfgIndex.value
  lookupJsonConfig.value[index] = {
    name: lookupJsonConfig.value[index].name,
    type: lookupJsonConfig.value[index].type
  }
  if (lookupJsonConfig.value[index].type === LOOKUP_JSON_CONFIG_TYPE_DATA) {
    lookupJsonConfigDataTable.value[index] = []
    onLookupJsonConfigTypeChanged(lookupJsonConfig.value[index].type)
  }

}
function onDeleteLookupConfig() {
  const index = currentLookupCfgIndex.value
  lookupJsonConfig.value.splice(index, 1)
  lookupJsonConfigDataTable.value.splice(index, 1)
  lookupJsonEditor.value.splice(index, 1)
  currentLookupCfgIndex.value = (currentLookupCfgIndex.value)
    ? currentLookupCfgIndex.value - 1
    : currentLookupCfgIndex.value
}
//#endregion


//#region /-- mapping config tab control --/
// mapping config tabs
function onMappingConfigChanged(input) {
  const index = currentMappingCfgIndex.value
  input ||= '{}' // Setting to empty to avoid UI display error

  try {
    mappingJsonConfig.value[index] = typeof (input) === 'string'
      ? JSON.parse(input)
      : input
    mappingJsonEditor.value[index].jsonEditor.isActive = true
  } catch (error) {
    mappingJsonEditor.value[index].jsonEditor.isActive = false
    console.error('Error parsing JSON:', error)
    return // Exit the function if JSON parsing fails
  }
  return
}

function onAddMappingConfig() {
  currentMappingCfgIndex.value = mappingJsonConfig.value.length ? (currentMappingCfgIndex.value + 1) : 0
  mappingJsonConfig.value.splice(currentMappingCfgIndex.value, 0,
    {
      name: "New Map",
      skipOnRequiredFailure: false,
      updateOnly: false
    }
  )
  mappingJsonEditor.value[currentMappingCfgIndex.value] = { jsonEditor: newJsonEditorState() } //set JSON editor view to false on new config
}

function onAddChildMappingConfig() {
  const mapIdx = currentMappingCfgIndex.value

  const arrChildrenMapping = mappingJsonConfig.value[mapIdx]?.children || []
  currentChildMappingCfgIndex.value = arrChildrenMapping.length ? (currentChildMappingCfgIndex.value + 1) : 0
  arrChildrenMapping.splice(currentChildMappingCfgIndex.value, 0, {
    name: "New Child Map",
    skipOnRequiredFailure: false,
    updateOnly: false
  })
  mappingJsonConfig.value[mapIdx].children = arrChildrenMapping
}

function onClearMappingConfig() {
  const index = currentMappingCfgIndex.value
  mappingJsonConfig.value[index] = {
    name: mappingJsonConfig.value[index].name
  }
}

function onClearChildMappingConfig() {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  mappingJsonConfig.value[mapIdx].children[childMapIdx] = {
    name: mappingJsonConfig.value[mapIdx].children[childMapIdx].name
  }
}

function onDeleteMappingConfig() {
  const index = currentMappingCfgIndex.value
  mappingJsonConfig.value.splice(index, 1)
  mappingJsonEditor.value.splice(index, 1)
  currentMappingCfgIndex.value = (currentMappingCfgIndex.value)
    ? currentMappingCfgIndex.value - 1
    : currentMappingCfgIndex.value
}

function onDeleteChildMappingConfig() {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  mappingJsonConfig.value[mapIdx].children.splice(childMapIdx, 1)
  if (!mappingJsonConfig.value[mapIdx].children.length) {
    mappingJsonConfig.value[mapIdx].children = undefined
  }
  currentChildMappingCfgIndex.value = (currentChildMappingCfgIndex.value)
    ? currentChildMappingCfgIndex.value - 1
    : currentChildMappingCfgIndex.value
}

function onCloneMappingConfig() {
  const mapIdx = currentMappingCfgIndex.value
  const clonedMap = JSON.parse(JSON.stringify(mappingJsonConfig.value[mapIdx]))
  clonedMap.name = clonedMap.name + ' - copy'
  const clonedJsonEditor = JSON.parse(JSON.stringify(mappingJsonEditor.value[mapIdx]))
  mappingJsonConfig.value.splice(mapIdx + 1, 0, clonedMap)
  mappingJsonEditor.value.splice(mapIdx + 1, 0, clonedJsonEditor)
  currentMappingCfgIndex.value = currentMappingCfgIndex.value + 1
}

function onCloneChildMappingConfig() {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  const clonedChildMap = JSON.parse(JSON.stringify(mappingJsonConfig.value[mapIdx].children[childMapIdx]))
  clonedChildMap.name = clonedChildMap.name + ' - copy'
  mappingJsonConfig.value[mapIdx].children.splice(childMapIdx + 1, 0, clonedChildMap)
  currentChildMappingCfgIndex.value = currentChildMappingCfgIndex.value + 1
}

async function onAddFieldMap(type) {
  const index = currentMappingCfgIndex.value
  mappingJsonConfig.value[index].fieldMapping ||= []
  mappingJsonConfig.value[index].fieldMapping.push(newAdvancedFieldMap({ type }))
  return
}

async function onAddChildrenFieldMap(type) {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  mappingJsonConfig.value[mapIdx].children[childMapIdx].fieldMapping ||= []
  mappingJsonConfig.value[mapIdx].children[childMapIdx].fieldMapping.push(newAdvancedFieldMap({ type }))
  return
}

async function onRemoveFieldMap(fieldMappingIndex) {
  const index = currentMappingCfgIndex.value
  const destintionKeyToRemove = mappingJsonConfig.value[index].fieldMapping[fieldMappingIndex].destinationKey
  mappingJsonConfig.value[index].fieldMapping.splice(fieldMappingIndex, 1)
  const idx = Array.isArray(mappingJsonConfig.value[index].updateByDestinationKeys)
    ? mappingJsonConfig.value[index].updateByDestinationKeys.indexOf(destintionKeyToRemove)
    : -1
  if (idx !== -1) {
    mappingJsonConfig.value[index].updateByDestinationKeys.splice(idx, 1)
  }
  return
}

async function onRemoveChildrenFieldMap(fieldMappingIndex) {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  const destintionKeyToRemove = mappingJsonConfig.value[mapIdx].children[childMapIdx].fieldMapping[fieldMappingIndex].destinationKey
  mappingJsonConfig.value[mapIdx].children[childMapIdx].fieldMapping.splice(fieldMappingIndex, 1)
  const idx = Array.isArray(mappingJsonConfig.value[mapIdx].children[childMapIdx].updateByDestinationKeys)
    ? mappingJsonConfig.value[mapIdx].children[childMapIdx].updateByDestinationKeys.indexOf(destintionKeyToRemove)
    : -1
  if (idx !== -1) {
    mappingJsonConfig.value[mapIdx].children[childMapIdx].updateByDestinationKeys.splice(idx, 1)
  }
  return
}

function onFieldMapTypeChanged(fieldMapping, fieldMappingIndex) {
  const index = currentMappingCfgIndex.value
  const replaceFieldMapping = newAdvancedFieldMap(fieldMapping)
  mappingJsonConfig.value[index].fieldMapping.splice(fieldMappingIndex, 1, replaceFieldMapping)
  triggerRef(mappingJsonConfig)
}

function onChildrenFieldMapTypeChanged(fieldMapping, fieldMappingIndex) {
  const mapIdx = currentMappingCfgIndex.value
  const childMapIdx = currentChildMappingCfgIndex.value
  const replaceFieldMapping = newAdvancedFieldMap(fieldMapping)
  mappingJsonConfig.value[mapIdx].children[childMapIdx].fieldMapping.splice(fieldMappingIndex, 1, replaceFieldMapping)
  triggerRef(mappingJsonConfig)
}


function getFieldMappingCaption(fieldMapping) {
  if (!fieldMapping.destinationKey) {
    return
  }
  let label = ''
  switch (fieldMapping.type) {
    case MAP_TYPE_KEY:
      label = `${label} ${fieldMapping.key}`
      break
    case MAP_TYPE_VALUE:
      label = `${label} "${fieldMapping.value}"`
      break

    case MAP_TYPE_COLUMN_NAME:
    label = `${label} "${fieldMapping.columnName}"`
    break

    case MAP_TYPE_LOOKUP:
    label = `${label} ${fieldMapping.lookupName} → ${fieldMapping.lookupResultKey}`
      break

      case MAP_TYPE_PARENT_RESULT:
    label = `${label} ${fieldMapping.key}`
      break

    default:
    label = `${label} ${fieldMapping.destinationKey}`

  }
  return `(${fieldMapping.type}) ${label} `

}

function sortFieldMapping(fieldMapping) {
  if (!fieldMapping) {
    return
  }
  fieldMapping = fieldMapping.sort((a,b) => a.destinationKey?.toLowerCase().localeCompare(b.destinationKey?.toLowerCase()))
  return fieldMapping
}


/**
 * Check if a price list is a price list
 * TODO: move out into utils
 */
 function isImportList(list) {
  return list?.type === CSV_PRICE_LIST
}

function onAddCondition(conditions) {
  conditions.push(newCondition())
}

function onRemoveCondition(conditions, index) {
  conditions.splice(index, 1)
}
//#endregion



</script>
