/*!
 * List db
 */

// Utils
import { required } from '../../utils'

// Db
import { Db } from '@/db/Db.mjs'
import { COLLECTION_LISTS } from '@/db/db-collections.mjs'


export class ListDb extends Db {
  constructor() {
    super({
      collectionName: COLLECTION_LISTS
    })
  }

  /**
   * Get list by type
   * @param {*} param0 
   * @returns 
   */
  getByType({
    type = required('type'),
    teamId,
    isDeleted = false
  }) {
    const conditionsArr = [
      ['type', '==', type],
      ['isDeleted', '==', isDeleted]
    ]
    if (teamId) {
      conditionsArr.push(['teamId', '==', teamId])
    }
    return super.getByConditions(conditionsArr)
  }


  validateDocObject({
    id = required('id'),
    name = required('name'),
    type = required('type'),
    teamId = required('teamId'),
    columnSetId = required('columnSetId')
  }) {
    return
  }

  createDocObject(list) {
    this.validateDocObject(list.toObject())
    return {
      name: list.name,
      teamId: list.teamId,
      type: list.type,
      columnSetId: list.columnSetId,
      sharedWith: [...list.sharedWith],
      deletedListItemIds: list.deletedListItemIds,
      csvFilePath: list.csvFilePath,
      isStatic: list.isStatic,
      canExport: list.canExport,
      sourceIntegrationId: list.sourceIntegrationId,
      ...super.createDocObject(list)
    }
  }
}
