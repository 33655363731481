
import { required } from '../../utils/required.mjs'

// entities
import { ListEntity, ColumnSetEntity } from '../../entity'

// csv file action
import { uploadListCsv } from '../../storage/list-file-storage.mjs'

import { setListCsvData, setListCsvColumns, setListCsvItems } from './list-csv-actions.mjs'
import { saveList } from '../../list/db/list-db.mjs'


async function createStaticList({
  name = required('name'),
  teamId = required('teamId'),
  type = required('type'),
  id,
  file,
  filePath,
  sourceIntegrationId,
}) {
  const newList = new ListEntity({ name, teamId, type, id, isStatic: true, sourceIntegrationId })
  newList.columnSet = new ColumnSetEntity({ listId: newList.id, teamId, isStatic: true })
  if (file) {
   await updateStaticListFile(newList, file)
   return newList
  }
  if (filePath) {
    await updateStaticListFilePath(newList, filePath)
    return newList
  }
  saveList(newList)
  return newList
}

async function updateStaticListFile(
  list,
  file,
) {
  list.csv = undefined
  const result = await uploadListCsv({ teamId: list.teamId, listId: list.id, file, filePath: list.csvFilePath })
  list.csvFilePath = result.metadata.customMetadata.filePath
  await setListCsvColumns(list)
  saveList(list)
  file = undefined
  return list
}

async function updateStaticListFilePath(
  list,
  filePath
) {
  list.csvFilePath = filePath
  list.csv = undefined
  await setListCsvData(list)
  await setListCsvColumns(list)
  await setListCsvItems(list)
  await saveList(list)
  return list
}

export {
  createStaticList,
  updateStaticListFile,
  updateStaticListFilePath,
}
