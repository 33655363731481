/**
 * Salesforce publish configuration UI states
 */
import { triggerRef } from 'vue'
import { getTeamSession } from '../../../session/team-session.mjs'

// states
import {
  integrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import {
  currentIntegrationId,
  productFieldsMapping,
  sfProductFields,
  stdPricebooks,
  customPricebooks,
  selectedStdPricebook,
  selectedCustomPricebooks,
  peFields,
  integrationDescription,
} from '../../../integration-modules/salesforce/states/salesforce-ui-states.mjs'


// sesssion events
import { isSessionEvent } from '../../../events/session-events.mjs'

// constants
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'
import { APP_SFDC_PUBLISH_BASIC } from '../../registered-apps.mjs'

import { SOBJECT_PRODUCT, SOBJECT_PRICEBOOK } from '../../../integration-modules/salesforce/constants/sobject-names.mjs'
import { FIELD_TYPES } from '../../../field-schema/field-schema-constants.mjs'
//actions
import { getCurrentTeamFieldSchemas } from '../../../field-schema/actions/field-schema-actions.mjs'

import { MAP_TYPE_KEY, MAP_TYPE_VALUE, MAP_TYPE_REFERENCE } from '../../../integrations/constants/field-map-types.mjs'
import { newFieldMap } from '../../../integrations/utils/ui-field-mapping-utils.mjs'

import { getIntegrationApp } from '../../../integration-modules/utils/integration-apps-utils.mjs'

const EMPTY_SELECTION = { key: null, label: null }

/**
 * 
 */
export const clearPublishConfigurationUiStates = async () => {
  const team = getTeamSession()
  const integrationDetails = await getIntegrationApp(INTEGRATION_SALESFORCE, APP_SFDC_PUBLISH_BASIC)
  productFieldsMapping.value = []
  sfProductFields.value = []
  stdPricebooks.value = []
  customPricebooks.value = []
  selectedStdPricebook.value = {}
  selectedCustomPricebooks.value = []
  integrationName.value = integrationDetails?.defaultName
  integrationDescription.value = integrationDetails?.name
}

/**
 * 
 * @param {*} integration 
 */
const setUiOptions = async (integration, force = false) => {
  const { cache } = integration

  // set pe product fields
  if (force || !peFields.value || peFields.value.length === 0) {
    const teamFields = await getCurrentTeamFieldSchemas()
    peFields.value = teamFields
      .filter(fs => !fs.isDeleted || FIELD_TYPES.includes(fs.type))
      .map(field => { return { key: field.key, label: field.label } })
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  // standard pricebook options
  if (force || !stdPricebooks.value || stdPricebooks.value.length === 0) {
    const stdPb = cache?.pricebooks?.find(pb => pb.isStandard)
    stdPricebooks.value = stdPb ? [{ key: stdPb.salesforceId, label: stdPb.name }] : []
  }

  // custom pricebook options
  if (force || !customPricebooks.value || customPricebooks.value.length === 0) {
    const custPb = cache?.pricebooks?.filter(pb => !pb.isStandard && pb.isActive) || []
    customPricebooks.value = custPb.length === 0 ? []
      : custPb.map(pb => {
        return {
          key: pb.salesforceId, label: pb.name, isActive: pb.isActive
        }
      }).sort((a, b) => a.label.localeCompare(b.label))
  }

  // sf product field options
  if (force || !sfProductFields.value || sfProductFields.value.length === 0) {
    const product2Fields = (cache?.objectDefinitions?.product2?.fields || [])
    sfProductFields.value = product2Fields
      .filter(field => field.updateable)
      .map(field => {
        return {
          key: field.key,
          label: `${field.label} (${field.key})`,
          required: !field.nillable,
          permissionable: field.permissionable && field.updateable,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }
}

/**
 * 
 */
const setUiSelections = (integration) => {
  // std pricebook selection
  const { configuration } = integration
  selectedStdPricebook.value = selectedStdPricebook.value.selected || {}
  if (configuration?.stdPricebookEntryMapping?.selected) {
    selectedStdPricebook.value = configuration.stdPricebookEntryMapping.selected
  } else {
    selectedStdPricebook.value = {
      pricebook: stdPricebooks.value[0],
      ...newFieldMap({
        type: MAP_TYPE_KEY,
        sfField: { key: 'UnitPrice', label: 'ListPrice', required: true }
      })
    }
  }
  // custom pricebooks
  selectedCustomPricebooks.value = configuration?.customPricebookEntryMapping?.selected || []

  // products
  productFieldsMapping.value = configuration?.productFieldsMapping?.selected || []
  if (productFieldsMapping.value.length === 0) {
    // set defaults
    const peMandatoryFields = ['Name', 'ProductCode', 'IsActive']
    const recommandedFields = sfProductFields.value
      .filter(field => peMandatoryFields.includes(field.key))
      .map(field => {
        const peField = findPeField(field.key) || findPeField(field.label)
        return newFieldMap({
          type: MAP_TYPE_KEY,
          sfField: field,
          peField
        })
      })

    const suggestedFields = sfProductFields.value
      .filter(field => !peMandatoryFields.includes(field.key)
        && field.permissionable
        && field.required)
      .map(field => {
        const peField = findPeField(field.key) || findPeField(field.label)
        return newFieldMap({
          type: MAP_TYPE_KEY,
          sfField: field,
          peField
        })
      })
    productFieldsMapping.value = [
      ...recommandedFields,
      ...suggestedFields
    ]
  }
}

const findPeField = (name) => {
  if (name === 'ProductCode') {
    return peFields.value.find(field => field.key === 'productCode')
  }
  return peFields.value.find(field => field.key === name)
    || peFields.value.find(field => field.label === name)
}

/**
 * Set publish configuraiton ui states
 * @param {*} integration 
 */
export const setPublishConfigurationUiStates = async (integration) => {
  await setUiOptions(integration)
  setUiSelections(integration)
  integrationDescription.value = integration.description
}

/**
 * Reload publish options
 * @param {*} integration 
 */
export const reloadPublishOptions = async (integration) => {
  await setUiOptions(integration, true)
  integrationDescription.value = integration.description
}

/**
 * On Integration Update
 * @param {*} event 
 * @returns 
 */
export const onLocalSalesforcePublishConfigUpdated = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setPublishConfigurationUiStates(integration)
}

export const onRemoteSalesforcePublishConfigUpdated = (event) => {
  const integration = event.detail
  if (isSessionEvent(integration.updatedByEventId)) {
    return
  }
  if (currentIntegrationId.value !== integration.id) {
    return
  }
  setPublishConfigurationUiStates(integration)
}

export const onLocalSalesforceImportConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearPublishConfigurationUiStates(integration)
}

export const onRemoteSalesforceImportConfigDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearPublishConfigurationUiStates(integration)
}


export const createPublishConfigurationFromUi = () => {
  return {
    stdPricebookEntryMapping: {
      object: SOBJECT_PRICEBOOK,
      selected: selectedStdPricebook.value
    },
    customPricebookEntryMapping: {
      object: SOBJECT_PRICEBOOK,
      selected: [...selectedCustomPricebooks.value]
    },
    productFieldsMapping: {
      object: SOBJECT_PRODUCT,
      matchBy: ['ProductCode'],
      selected: [...productFieldsMapping.value]
    }
  }
} 