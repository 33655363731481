import { getListCoreRpc } from '../../db/rpcs/db-rpcs.mjs'
import { ACTION_UPSERT_COLUMN_SET, ACTION_GET_COLUMN_SET } from '../../list-core/constants/action-types.mjs'

export const upsertColumnSet = async (columnSet) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_UPSERT_COLUMN_SET,
    payload: columnSet
  })
  return result?.data?.success
}

export const fetchColumnSet = async (id) => {
  const listCoreRpc = getListCoreRpc()
  const result = await listCoreRpc({
    action: ACTION_GET_COLUMN_SET,
    payload: {
      id
    }
  })
  if (result.data?.success) {
    return result.data.result
  }
  console.error ('fetchColumnSet error:', result.data)
  return result?.data?.success
}
