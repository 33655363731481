/*!
 * Database Instance
 */

import { initializeApp, getApp } from 'firebase/app'
import { firebaseConfig } from "./firebase.mjs"
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { MIN_CONNECTION_POOL_SIZE, MAX_CONNECTION_POOL_SIZE, AU_REGION } from './db-constants.mjs'

import { isEmulator, useFirestoreEmulator, useFunctionEmulator } from './emulators.mjs'
/*
function initInstance(app) {
  const db = initializeFirestore(app, {
    ignoreUndefinedProperties: true
  })
  if (isEmulator()) {
    connectFirestoreEmulator(db, EMULATOR_HOST, EMULATOR_FIRESTORE_PORT)
  }
  return db
}
*/
/*
TODO: need to fix this up, was having issues with offline mode. 
Snapshot query not loading data on 1st browser open
*/
/*
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
})

// enable persistence with multi Tab Support
enableMultiTabIndexedDbPersistence(db)
  .catch(console.error)
*/
const automaticDataCollectionEnabled = false
const app = initializeApp(firebaseConfig, { automaticDataCollectionEnabled })
const db = initializeFirestore(app, { ignoreUndefinedProperties: true })
const dbFunctions = getFunctions(app, AU_REGION)

if (isEmulator()) {
  useFirestoreEmulator(db)
  useFunctionEmulator(dbFunctions)
}


export const getDb = () => {
  return db
}

export class DbInstance {
  // best effort round robin pooling
  #app
  #db = []
  #poolSize
  #nextIndex = 0
  #functions
  constructor({
    poolSize = MIN_CONNECTION_POOL_SIZE,
  } = {}) {
    this.#poolSize = poolSize
    this.#app = app || getApp()
    this.#nextIndex = 0
    // initial instances
    for (let i = 0; i < this.#poolSize; i++) {
      this.#db.push(db)
    }
    this.#functions = dbFunctions
  }

  get app() {
    return this.#app
  }

  get functions() {
    return this.#functions
  }

  get poolSize() {
    return this.#poolSize
  }

  get nextIndex() {
    return this.#nextIndex
  }

  /**
   * Get db method
   */
  getDb() {
    const idx = this.#nextIndex
    this.#nextIndex = (idx + 1) === this.#poolSize ? 0 : (idx + 1)
    return this.#db[idx]
  }

  /**
   * Get db by index method
   */
  getDbByIndex(index) {
    return this.#db[index]
  }

  /**
   * Get a server function
   * @param {*} name 
   * @returns 
   */
  getFunction(name) {
    return httpsCallable(this.#functions, name)
  }

  /**
   * To object method
   */
  toObject() {
    return Object.freeze({
      app: this.#app,
      db: this.#db,
      poolSize: this.#poolSize,
      nextIndex: this.#nextIndex
    })
  }
}
