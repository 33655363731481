
import {
  EVENT_IMPORT_COLUMN_MAP_UPDATED_LOCAL_COMPLETED,
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'

import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'



//#region //--- New Column ---//
/**
 * Update column event local
 * @param {*} event 
 * @param {*} columnManager 
 */
export const compileImportItemsEvent = async (event, columnManager, itemManager) => {
  const { key } = event.detail
  console.log('compileImportItemsEvent called', key)
  //itemManager.compileAllItems(columnManager.getImportFieldSchemas(), [key])

}



export const addEventsImportColumnMapUpdated = (controller, subscriptions, columnManager, itemManager) => {
  addEventListener(
    EVENT_IMPORT_COLUMN_MAP_UPDATED_LOCAL_COMPLETED,
    (event) => compileImportItemsEvent(event, columnManager, itemManager),
    { signal: controller.signal }
  )
  return
}