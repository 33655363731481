export const IMPORT_BATCH_SIZE = 500

export const LOCAL_READ_BATCH_SIZE = 2000
export const REMOTE_READ_BATCH_SIZE = 1000

export const ITEM_COMPILE_BATCH_SIZE = 2000

export const COMMIT_BATCH_SIZE = 500

export const SAVE_ITEMS_BATCH_SIZE = 1000
