export const ACTION_PUBLISH_PRODUCTS = 'publish-products'
export const ACTION_CREATE_IMPORT_CSV = 'create-import-csv'
export const ACTION_UPDATE_IMPORT_CSV = 'update-import-csv'
export const ACTION_TEST_CONNECTION = 'test-connection'
export const ACTION_GET_INTEGRATION = 'get-integration'
export const ACTION_UPDATE_INTEGRATION = 'update-integration'
export const ACTION_GET_PRICEBOOKS = 'get-pricebooks'
export const ACTION_REFRESH_CACHE = 'refresh-cache'
export const ACTION_UPDATE_INTEGRATION_CONNECTION = 'update-integration-connection'
export const ACTION_UPDATE_INTEGRATION_CONFIGURATION = 'update-integration-configuration'
export const ACTION_IMPORT_BY_QUERY = 'import-by-query'
export const ACTION_ADV_TEST = 'sf-sdv-test'