<template>
  <main-layout>

    <!-- Grid Loader -->
    <template v-if="isGridLoading">
      <div style="position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%; top:0; left: 0; z-index: 99; background-color: rgba(255, 255, 255, 0.6);"
        :style="isMainMenuOpen ? 'margin-left: 150px;' : ''">
        <div class="is-loading"></div>
        <div v-if="isTakingTime" style="line-height: 3rem;">{{ randomWaitMessage }}</div>
      </div>
    </template>

    <!-- Action Menu -->
    <div class="sheet-action-bar" :style="isMainMenuOpen ? '' : 'margin-left: 5rem;'">
      <div style="display: flex; align-items: center; min-width: 200px;">

        <!-- Search Items -->
        <q-btn @click="onOpenSearch(this)" icon="o_search" class="q-mx-sm" flat round>
          <q-tooltip>Search Price List</q-tooltip>
          <q-badge v-if="currentSearch?.length" rounded style="margin-left: -12px; margin-top: -19px;" />
        </q-btn>
        <div
          id="searchInputContainer"
          v-show="isSearchingInputActive"
          style="position: relative; width: 80vw; left: 1.8rem; z-index: 9999; padding: 1rem 0; background-color: white;"
        >
          <q-input
            type="search"
            v-model="currentSearch"
            placeholder="Search Price List"
            @update:model-value="onSearch"
            @keydown.escape="isSearchingInputActive = false"
            @blur="isSearchingInputActive = false"
            dense outlined clearable
          />
        </div>

        <q-btn icon="more_vert" flat round>
          <q-menu>
            <q-list style="min-width: 100px">

              <q-item v-show="selectedCount > 0 && canImportToLibrary" @click="onImportToLibrary()" dense clickable v-close-popup>
                <q-item-section>{{ `Import ${selectedCount} item${selectedCount !== 1 ? 's' : ''} to Library &rarr;` }}</q-item-section>
              </q-item>

              <q-item v-show="canHideColumns" @click="onColumnActionClick()" dense clickable v-close-popup>
                <q-item-section>Show / Hide Columns</q-item-section>
              </q-item>

              <q-item v-show="canExportItems" @click="onExportItems" dense clickable v-close-popup>
                <q-item-section>Download as CSV</q-item-section>
              </q-item>

              <q-item v-show="canModifyPriceList && isStaticList" @click="onEditPriceList(currentList)" dense clickable v-close-popup>
                <q-item-section>Edit Price List</q-item-section>
              </q-item>

              <q-item v-show="canUploadCsv" @click="onUploadCsv()" dense clickable v-close-popup>
                <q-item-section>Upload CSV</q-item-section>
              </q-item>

              <q-item v-show="selectedCount && canDuplicateItems" @click="onDuplicateSelection" dense clickable v-close-popup>
                <q-item-section>Duplicate {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }}</q-item-section>
              </q-item>

              <q-item v-show="canSharePriceList && isStaticList" @click="onShareList(currentList)" dense clickable v-close-popup>
                <q-item-section>Share Price List</q-item-section>
              </q-item>

              <q-item dense clickable
                v-show="canPublishToIntegration"
              >
                <q-item-section>Publish To...</q-item-section>
                <q-item-section side>
                  <q-icon name="keyboard_arrow_right" />
                </q-item-section>
                <q-menu anchor="top end" self="top start">
                  <q-list style="min-width: 100px">
                    <q-item dense clickable v-close-popup 
                      v-for="integration of publishableIntegrations" 
                      :key="integration" @click="onPublishToIntegration(integration.id)">
                      <q-item-section>{{ integration.name }}</q-item-section>
                    </q-item>
                    <q-item dense clickable v-close-popup to="/integrations">
                      <q-item-section style="color: var(--q-primary);">+ Add Integration</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>

              <q-item v-show="selectedCount && canDeleteItems" @click="onDeleteItems" dense clickable v-close-popup>
                <q-item-section style="color: red;">Delete {{ selectedCount }} item{{ selectedCount !== 1 ? 's' : '' }}</q-item-section>
              </q-item>

            </q-list>
          </q-menu>
        </q-btn>
      </div>

      <!-- Title -->
      <div class="sheet-title">
        <span :style="isStaticList && canModifyPriceList || !isStaticList ? 'cursor: pointer;' : 'cursor: default;'" @click="canModifyPriceList && onEditPriceList(currentList)">
          {{ isGridLoading ? 'Loading...' : currentList?.name }}
          <q-icon class="show-on-hover" v-if="canModifyPriceList" style="margin-left: .4em;" name="o_edit" />
        </span>
      </div>

      <!-- Right Placeholder -->
      <div style="min-width: 200px;"></div>
    </div>
    
    <ag-grid-vue
      id="PriceListGrid"
      class="sheet ag-theme-alpine"
      @contextmenu.prevent
      @gridReady          = "onGridReady"
      @selectionChanged   = "onSelectionChanged"
      @columnResized      = "onColumnResized"
      @columnMoved        = "onColumnMoved"

      rowSelection = "multiple"
      suppressRowClickSelection = "true"
      :cellFadeDelay        = "600"
      :cellFlashDelay       = "150"
      :stopEditingWhenCellsLoseFocus = "true"
      :singleClickEdit      = "false"
      :animateRows          = "false"

      :getRowId             = "getRowId"
      :columnHoverHighlight = "true"
      :rowBuffer            = "50"

      :isExternalFilterPresent = "GridItemManager.isExternalFilterPresent"
      :doesExternalFilterPass = "GridItemManager.doesExternalFilterPass"

      overlayNoRowsTemplate = "&nbsp;"
      overlayLoadingTemplate = "&nbsp;"

      :enableCellTextSelection="true"
    >
    </ag-grid-vue>

    <right-hand-menu />
    <field-edit-modal></field-edit-modal>
    <list-modal></list-modal>
    <price-list-add-item-modal></price-list-add-item-modal>
    <selected-cell-value-editor></selected-cell-value-editor>

    <!-- Update Progress Overlay -->
    <progress-overlay
      v-show="isProgressOpen"
      :heading="progressLabel"
      :body="progressBody"
      :value="progressPercentage"
    />

    <!-- Import Button -->
    <div v-if="isImportMode" style="position: absolute; bottom: 2rem; right: 2rem;">
      <q-btn v-show="selectedCount > 0 && canImportToLibrary" @click="onImportToLibrary" no-caps unelevated rounded color="primary">
        {{ 
          `Import ${selectedCount} item${selectedCount !== 1 ? 's' : ''} to Library &rarr;`
        }}
      </q-btn>
    </div>

  </main-layout>

  <!-- File uploader -->
  <input
    type="file"
    id="priceListUploader"
    @change="onUploadFile"
    style="position: absolute; top: -1000px; left: -1000px;"
    accept="text/csv, text/tsv"
  />
</template>

<style>

.sheet-title .show-on-hover {
  opacity: 0;
  transition: ease-in-out 300ms opacity;
}

.sheet-title:hover .show-on-hover {
  opacity: 1;
}

</style>

<script setup>

// Styles
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/styles/sheet.css'
import '@/styles/theme.css'

// Dependencies
import { ref, onMounted, onUnmounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { AgGridVue } from 'ag-grid-vue3'

// Layouts
import MainLayout from '@/layouts/MainLayout'

// Components
import FieldEditModal from '@/components/sheet/FieldEditModal'
import ListModal from '@/components/sheet/ListModal'
import ProgressOverlay from '@/components/ProgressOverlay'
import RightHandMenu from '@/components/sheet/RightHandMenu'
import PriceListAddItemModal from '@/components/sheet/PriceListAddItemModal.vue'
import SelectedCellValueEditor from '@/components/sheet/SelectedCellValueEditor.vue'

// notification
import { raiseConfirmDelete } from '../../notify/raise-confirm-delete.mjs'
import { raiseNotification } from '../../notify/raise-notification.mjs'

// Actions
import { isSearchMenuOpen, openSearchMenu, closeSearchMenu } from '@/actions/search-menu.mjs'

import { getRowId } from '@/ui/components/sheet.mjs'


// events
import { dispatch } from '../../events/dispatch.mjs'
import { 
  EVENT_UPLOAD_CSV_LOCAL,
  EVENT_ITEMS_IMPORT_LIBRARY_LOCAL,
  EVENT_ITEMS_DOWNLOAD_AS_CSV,
  EVENT_OPEN_MODIFY_PRICE_LIST,
  EVENT_SELECTED_ITEMS_DELETE_LOCAL,
  EVENT_INTEGRATION_PUBLISH_LOCAL
} from '../../events/constants/event-types.mjs'

// ui states and data
import { getRandomWaitMessage } from '@/utils'
import { filterTab, currentList, isImportMode, isStaticList, selectedItemsCount } from '../../ui/ui-states.mjs'

// ui action permissions
import {
  canUploadCsv,
  canImportToLibrary,
  canExportItems,
  canDuplicateItems,
  canCreatePriceList,
  canModifyPriceList,
  canSharePriceList,
  canAddToPriceList,
  canDeleteItems,
  canPublishToIntegration,
  canAddItems,
  canModifyView,
  canHideColumns,
} from '../../ui/ui-states.mjs'

// Price list function
import { openShareList } from '../uis/price-list-index-ui.mjs'

// integration actions
import { publishableIntegrations } from '../../integrations/states/integrations-ui-states.mjs'

// events
import { isProgressOpen, progressLabel, progressBody, progressPercentage } from '../../ui/ui-states.mjs'
import {startProgressEvents, stopProgressEvents } from '../../ui/progress-status.mjs'

import { isMainMenuOpen } from '../../ui/main-menu.mjs'


import { onPriceListGridReady, onPriceListGridStop } from '../price-list-ui.mjs'

// ui functions
import { 
  onColumnResized, 
  onColumnMoved 
} from '../../list-core/uis/column-ui-actions.mjs'

// filtering
import { GridItemManager } from '../../grid-manager/entities/GridItemManager.mjs'


const TAKING_TIME_DELAY = 1000

// JS: shouldn't keep these globally, only used for search right now
let gridApi = null
let gridColumnApi = null
let selectedRows = []

// Refs
const selectedCount = ref(false)
const isSearchingInputActive = ref(false)
const canSelect = ref(true)
const currentSearch = ref('')
const isGridLoading = ref(true)
const isTakingTime = ref(false)
const randomWaitMessage = ref(getRandomWaitMessage())

// temp
let currentId = null
let listId = useRoute().params.id

onBeforeRouteUpdate(async (to, from) => {
  onPriceListGridStop()
  isGridLoading.value = true
  const thisId = to.params.id
  listId = to.params.id
  if (thisId === currentId) {
    return
  }
  setTimeout(() => {
    isTakingTime.value = true
  }, TAKING_TIME_DELAY)
  await onPriceListGridReady({ api: gridApi, columnApi: gridColumnApi } , thisId)
  setTimeout(() => {
    isGridLoading.value = false
  }, 10)

})

// Mount hook
onBeforeMount(async () => {
  startProgressEvents()
  isGridLoading.value = true
  const thisId = useRoute().params.id
  if (thisId === currentId) {
    return
  }
  setTimeout(() => {
    isTakingTime.value = true
  }, TAKING_TIME_DELAY)
})

// Unmounted hook
onBeforeUnmount(() => {
  isGridLoading.value = true
  isTakingTime.value = false
  onPriceListGridStop()
})

onUnmounted(() => {
  isTakingTime.value = false
  isGridLoading.value = true
  stopProgressEvents()
  gridApi = undefined
})

/**
 * Grid ready handler
 */
 async function onGridReady({ api, columnApi }) {
  isGridLoading.value = true
  gridApi = api
  gridColumnApi = columnApi
  await onPriceListGridReady({ api, columnApi} , listId)
  isTakingTime.value = false
  setTimeout(() => {
    isGridLoading.value = false
  }, 10)
}

/**
 * Export item handler
 */
function onExportItems() {
  return dispatch(EVENT_ITEMS_DOWNLOAD_AS_CSV)
}

/**
 * Column action click handler
 */
function onColumnActionClick() {
  if (isSearchMenuOpen.value) {
    return closeSearchMenu()
  }
  openSearchMenu()
  filterTab.value = 'columns'
}


/**
 * Search handler
 */
function onSearch() {
  return gridApi.setQuickFilter(currentSearch.value)
}

/**
 * Duplicate selected items handler
 */
 function onDuplicateSelection() {
}

/**
 * Selection change handler
 */
 function onSelectionChanged({ api }) {
  if (!canSelect.value) {
    return
  }
  selectedRows = api.getSelectedRows()
  selectedCount.value = selectedRows.length
  selectedItemsCount.value = selectedRows.length
}

/**
 * Open file handler
 */
function onUploadCsv() {
  const fileUploadInput = document.getElementById('priceListUploader')
  fileUploadInput.value = '' // reset the value so change detection works
  fileUploadInput.click()
}

/**
 * Open search handler
 */
function onOpenSearch() {
  isSearchingInputActive.value = true
  requestAnimationFrame(() => {
    document.querySelector('#searchInputContainer input')?.focus()
  })
}


/**
 * Open edit List handler
 */
function onEditPriceList(list) {
  dispatch(EVENT_OPEN_MODIFY_PRICE_LIST, { listId: list.id })
}

/**
 * Share list handler
 */
 function onShareList(list) {
  return openShareList(list)
}

/**
 * Import to library handler
 */
function onImportToLibrary() {
  if (selectedRows.length < 1) {
    return
  }
  dispatch(EVENT_ITEMS_IMPORT_LIBRARY_LOCAL, {
    items: selectedRows
  })

}

/**
 * File upload handler
 */
async function onUploadFile(event) {
  if (!canUploadCsv.value) {
    return
  }
  event.preventDefault()
  event.stopPropagation()

  const files  = event.target.files || event.dataTransfer.files
  const file   = files[0]
  dispatch(EVENT_UPLOAD_CSV_LOCAL, {
    listId: listId,
    file,
    isNew: false
  })
  return
}

/**
 * Publish to Salesforce
 */
function onPublishToIntegration(id) {
  if (selectedItemsCount.value < 1) {
    raiseNotification('Select 1 or more items to publish')
    return
  }
  dispatch(EVENT_INTEGRATION_PUBLISH_LOCAL, { id })
}


//#region //--- items ---//
const onDeleteItems = () => {
  raiseConfirmDelete(`Are you sure you wish to delete ${selectedItemsCount.value} item${selectedItemsCount.value > 1 ? 's' : ''} from "${currentList.value.name}"?`, () => {
    dispatch(EVENT_SELECTED_ITEMS_DELETE_LOCAL, {
      selectedItemsCount: selectedItemsCount.value,
    })
  })
}

</script>
