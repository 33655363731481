
import { isArray, isObject } from '../../utils/is'

export const formatItemToLocalDbItem = (item) => {
  for (const key in item.fields) {
    const field = item.fields[key]
    field.inputValue = isArray(field.inputValue) ? { ...field.inputValue } : field.inputValue
    field.value = isArray(field.value) ? { ...field.value } : field.value
  }
  return item
}

export const formatLocalDbItemToItem = (item) => {
  for (const key in item.fields) {
    const field = item.fields[key]
    if (!field) {
      continue
    }
    if (field.inputValue && isObject(field.inputValue)) {
      const arr = Object.values(field.inputValue)
      field.inputValue = isArray(arr) ? arr : field.inputValue
    }
    if (field.value && isObject(field.value)) {
      const arr = Object.values(field.value)
      field.value = isArray(arr) ? arr : field.value
    }
  }
  return item
}