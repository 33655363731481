
//utils
import { IS_DEBUG_EVENT_MODE } from '../../constants/debug.mjs'

// events
import {
  EVENT_ITEM_UPDATED,
  EVENT_ITEM_UPDATED_REMOTE,
  EVENT_SET_SESSION_UPDATED_AT_INTERVAL
} from '../../events/constants/event-types.mjs'
import { dispatch } from '../../events/dispatch.mjs'


import { createTeamEvent, emitEvent } from '../../events/Emitter.mjs'

// entities
import { ItemEntity } from '../../entity/ItemEntity.mjs'

// session event
import { getSessionEvent } from '../../events/session-events.mjs'



//#region //--- New Column ---//
/**
 * Update item
 * @param {*} event 
 * @param {*} columnManager 
 */
export const updateItemEvent = async (event, columnManager, itemManager) => {
 const { item, key } = event.detail
 const teamEvent = createTeamEvent(EVENT_ITEM_UPDATED)
  const fieldSchemas = columnManager.getFieldSchemas()
  itemManager.updateItem(item, fieldSchemas, { keys: [key], gridOnly: false }).then(() =>{
  // send external event
    teamEvent.payload = { itemId: item.id }
    setTimeout(() => emitEvent(teamEvent), 500)
  })
}


/**
 * Update item that has compiled grid
 * only save and signal remote
 * @param {*} event 
 * @param {*} columnManager 
 */
export const updateItemGridEvent = async (event, columnManager, itemManager) => {
  const { item, key } = event.detail
  const teamEvent = createTeamEvent(EVENT_ITEM_UPDATED)
   // send external event
   await ItemEntity.upsert(item)
   teamEvent.payload = { itemId: item.id }
   setTimeout(() => emitEvent(teamEvent), 500)
 }

/**
 * Add new column event remote
 * @param {*} event 
 * @param {*} columnManager 
 * @returns 
 */
export const updateItemRemoteEvent = async (event, columnManager, itemManager) => {
  const { item } = event.detail
  const currentList = columnManager.list
  if (item.libraryListId !== currentList.id && !item.lists.includes(currentList.id)) {
    return
  }
  await itemManager.updateItem(item, columnManager.getFieldSchemas(), { gridOnly: true } )  
}


/**
 * Add new column external event handler
 * @param {*} event 
 * @returns 
 */
export const updateItemRemoteHandler = async (event) => {
  const { eventId, payload } = event
  if (getSessionEvent(eventId)) {
    IS_DEBUG_EVENT_MODE && console.log('Incoming event originated here. skip', eventId)
    return
  }
  const { itemId } = payload
  const item = await ItemEntity.fetch(itemId)
  if (!item) {
    console.error('Unable to find item', itemId)
  } 
  dispatch(EVENT_ITEM_UPDATED_REMOTE, { item })

}
