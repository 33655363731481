/**
 * Start salesforce UI states
 */
// utils
import { generateId } from '../../../utils/uuid.mjs'

//constants
import { CHAR_EMPTY } from '../../../constants/characters.mjs'
import { DEFAULT_INSTANCE_URL } from '../constants/setting-defaults.mjs'

// session
import { getTeamSession } from '../../../session/team-session.mjs'

// states
import {
  integrationName
} from '../../../integrations/states/integrations-ui-states.mjs'

import {
  currentIntegrationId,
  username,
  userPassword,
  securityToken,
  instanceUrl,
  testResponse,
  integrationDescription
} from '../states/salesforce-ui-states.mjs'

// events
import { 
  EVENT_INTEGRATION_UPDATED_LOCAL ,
  EVENT_INTEGRATION_DELETED_LOCAL ,
  EVENT_INTEGRATION_UPDATED_REMOTE,
  EVENT_INTEGRATION_DELETED_REMOTE,
} from '../../../events/constants/event-types.mjs'

// sesssion events
import { isSessionEvent } from '../../../events/session-events.mjs'

// integrations
import { INTEGRATION_SALESFORCE } from '../../../integrations/constants/integration-types.mjs'

// activeIntegrations
import { getActiveIntegration } from '../../../integrations/actions/active-integrations.mjs'

import { useRouter } from 'vue-router'

const router = useRouter()
let eventController = null

const clearConnectionUiStates = () => {
  currentIntegrationId.value = CHAR_EMPTY
  integrationName.value = CHAR_EMPTY
  username.value = CHAR_EMPTY
  userPassword.value = CHAR_EMPTY
  securityToken.value = CHAR_EMPTY
  instanceUrl.value = DEFAULT_INSTANCE_URL
  testResponse.value = CHAR_EMPTY
  integrationDescription.value = CHAR_EMPTY
}

const setConnectionUiStates = (integration) => {
  const { connectionSettings } = integration
  if (!connectionSettings) {
    clearConnectionUiStates()
    currentIntegrationId.value = integration.id
    return
  }
  currentIntegrationId.value = integration.id
  integrationName.value = integration.name
  username.value = connectionSettings.username
  userPassword.value = connectionSettings.password
  securityToken.value = connectionSettings.securityToken
  instanceUrl.value = connectionSettings.instanceUrl
  integrationDescription.value = integration.description
}


/**
 * On Integration Update
 * @param {*} event 
 * @returns 
 */
const onIntegrationUpdated = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setConnectionUiStates(integration)
}

const onIntegrationUpdatedRemote = (event) => {
  const integration = event.detail
  // skip if event originate locally
  if (isSessionEvent(integration.updatedByEventId)) {
    return
  }
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  setConnectionUiStates(integration)
}


const onIntegrationDeleted = (event) => {
  const integration = event.detail
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearConnectionUiStates(integration)
}

const onIntegrationDeletedRemote = (event) => {
  const integration = event.detail
  // skip if event originate locally
  if (isSessionEvent(integration.updatedByEventId)) {
    return
  }
  if (currentIntegrationId.value !== integration.id) {
    console.log('not current integration. skip')
    return
  }
  clearConnectionUiStates(integration)
}

/**
 * Start Salesforce UI
 * @param {*} id 
 * @returns 
 */
export const startSalesforceUi = async (id) => {
  // if no id, it's new
  if (!id) {
    clearConnectionUiStates()
    return
  }
  currentIntegrationId.value = id
  //init load
  const integration = await getActiveIntegration(id)
  if (integration) {
    setConnectionUiStates(integration)
  }
  eventController = new AbortController()
  addEventListener(EVENT_INTEGRATION_UPDATED_LOCAL , onIntegrationUpdated, { signal: eventController.signal })
  addEventListener(EVENT_INTEGRATION_UPDATED_REMOTE, onIntegrationUpdatedRemote, { signal: eventController.signal })
  addEventListener(EVENT_INTEGRATION_DELETED_LOCAL , onIntegrationDeleted, { signal: eventController.signal })
  addEventListener(EVENT_INTEGRATION_DELETED_REMOTE, onIntegrationDeletedRemote, { signal: eventController.signal })
}

export const stopSalesforceUi = () => {
  eventController?.abort()
  eventController = null
  clearConnectionUiStates()
}

export const createIntegrationSettingsFromUi = (subtype) => {
  const team = getTeamSession()
  currentIntegrationId.value = currentIntegrationId.value || generateId()
  return {
    id: currentIntegrationId.value,
    name: integrationName.value,
    teamId: team.id,
    type: INTEGRATION_SALESFORCE,
    subtype,
    description: integrationDescription.value,
    connectionSettings: {
      username: username.value,
      password: userPassword.value,
      securityToken: securityToken.value,
      instanceUrl: instanceUrl.value
    }
  }
}