
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AU_REGION } from '../../db/db-constants.mjs'

const app = getApp()
const CALLABLE_TEAM = 'team'

let teamRpc = null

/**
 * get the team rpc function
 * @returns 
 */
function getTeamRpc() {
  if (teamRpc) {
    return teamRpc
  }
  const functions = getFunctions(app, AU_REGION)
  teamRpc = httpsCallable(functions, CALLABLE_TEAM, {timeout: 540000 } )
  return teamRpc
}

export { getTeamRpc }