import { ref } from 'vue'
import { DbInstance } from '@/db/DbInstance.mjs'



import { isDate, isPercentage, isBoolean } from '@/utils/is.mjs'
import { parseBoolean, parseNumber, parseTaglist, parseDateInternational } from '@/utils/parse.mjs'
import { formatDate } from '@/utils/format.mjs'
import { getFieldSchemaType } from '../field-schema/utils/field-schema-upload-csv-utils.mjs'
import { setMainLayoutUiActionPermissionStates } from '../layouts/permissions/mainLayout-ui-action-permissions.mjs'
import { getCurrentUser } from '@/actions/current-user.mjs'
import { getTeamsByUser, migrateTeamsUsersMembership_cloudfn } from '@/team/actions/team-actions.mjs'


import { startTaskTest } from './firebase-cloud-task-test.mjs'

import { lookupTest } from './salesforce-cloud-functions-tests.mjs'

import { createExternalEvent, emitEvent } from '../events/Emitter.mjs'

// shopify test
import { testShopifyIntegration } from './shopify-cloud-functions-tests.mjs'

export const AU_REGION = 'australia-southeast1'
export const result1 = ref(null)
export const result2 = ref(null)
export const result3 = ref(null)
export const result4 = ref(null)
export const result5 = ref(null)

const dbInstance = new DbInstance()

async function clearFishTestData() {
  /*
  clearAllUserTeamData({
    userEmail: 'fish@productengine.app',
    teamName: "Paul's Team"
  })
  */
}

export async function testOnBeforeMount() {
}

export async function testOnBeforeUnmount() {
}

export async function testButton1() {
  startTaskTest()
}

export async function testButton2() {
  console.log('runngin testButton2 ')
  testShopifyIntegration()
}

export async function testButton3() {
  const values = ['0','0.1','10.35','10.100','1000','1,000.35','1,000,000','abc','ab3'] //number
  //const values = ['$10.35','$1,000.35','25.00','25%','asdf'] //currency
  //const values = ['10.35%','0.35%','1,000.35%','$10.35'] //percentage
  //const values = ['2023-03-16','2023-03-16T18:00:00.00Z','1677735347000','1677735347','16/03/2032','16.03.2023','12/12/2012','13/12/2012','12/13/2012','01/12/2012','03/12','12/03','2023/12','14/16']
  //const values = [true,false,'true','false','yes','no','1','0','abc'] //checkbox 0 & 1 are numbers
  //const values = ['samsung','same,same,different','same','same,same','12,140','123,340,000,1','125.4'] //taglist signle word is text/number, digits with thousand separator is number, digit with non-matching thousand separator is taglist
  //const values = ['abc','$%.','p-code',] //string

  console.log('testButton3 :', getFieldSchemaType(values))
  values.forEach(value => {
    const parsedDate = parseDateInternational(value)
    console.log('parseDate : ', value, isDate(value), parsedDate)
    console.log('formatDate: ', value, formatDate(parsedDate))
  })
  //await testColumnEntity()
}

export async function testButton4() {
  const externalEvent = createExternalEvent({
    action: 'team-inactive',
    scope: 'global',
    teamId: '1686258510336-c0cb3ef7-5c22-478f-9098-57eb82c6e55d',
    payload: {
      teamId: '1686258510336-c0cb3ef7-5c22-478f-9098-57eb82c6e55d',
    }
  })
  // send external event
  setTimeout(() => emitEvent(externalEvent), 500)
}


export async function testButton5() {
  //front end button to migrate user and team records to new user and team structure
  const user = {
    id: 'SirscG25mYUUdA1wizjXAM6ncUMe',
    teams: ['1680689611980-bba9fa20-ad88-4e48-ae08-3b9d64ef3232',
            '1680764795813-6a67400f-cf38-4d81-b38e-a38d2dc5c44a'
           ],
  }
  const teams = await getTeamsByUser({ user })
  console.log('teams :', teams)
}

