/**
 * User session
 */

// Dependencies
import { required, setSessionItem, getSessionItem } from '../utils'

// Constants
const SESSION_KEY = 'user'

/**
 * Set user session
 */
function getUserSession() {
  return getSessionItem(SESSION_KEY)
}

/**
 * Get user session
 */
function setUserSession(user = required(SESSION_KEY)) {
  setSessionItem(SESSION_KEY, user)
}

// Exports
export { getUserSession, setUserSession }
