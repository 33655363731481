<template>
  <pre-layout>
    <div class="fixed-center">
      <q-card class="login-card text-dark q-pa-md text-center">
        <q-card-section>
          <div class="text-h5 text-weight-bold">Welcome</div>
        </q-card-section>

        <q-card-section class="q-pt-md text-center">
          <q-form>
            <q-input v-model="email" color="grey-7" bg-color="accent" outlined class="rounded-borders fit q-mb-sm" dense type="email" placeholder="Sign in with email" autocomplete="username" />
              <q-input v-model="password" color="grey-7" bg-color="accent" outlined class="rounded-borders fit"  dense :type="showPassword ? 'password' : 'text'" autocomplete="current-password" placeholder="Password">
              <template v-slot:append>
                <q-icon
                  :name="showPassword ? 'o_visibility_off' : 'o_visibility'"
                  class="cursor-pointer"
                  size="xs"
                  @click="showPassword = !showPassword"
                  clearable="true"
                />
              </template>
            </q-input>
            <router-link class="q-mt-md text-grey-6" to="/forgot" style="text-decoration: none;">Forgot Password?</router-link>
            <q-btn
              unelevated
              no-caps
              size="md"
              :loading="isLoginLoaderLoading"
              @click="signInWithPassword(email, password)"
              class="bg-primary full-width q-mt-sm q-pa-sm"
              text-color="white"
              style="border-radius: 5px;"
              label="Sign in &rarr;"
            />
          </q-form>
          <p class="text-body2 text-weight-medium q-pt-sm">or</p>
          <q-btn
            no-caps
            unelevated
            size="md"
            @click="signInWithGoogle"
            class="bg-white text-grey-8 full-width q-pa-sm"
            style="border: 1px solid #E1E5F5; border-radius: 5px;"
          >
            <img class="q-pr-sm" side src="/img/google-logo.png" />
            <div>Continue with Google</div>
          </q-btn>
          <div style="margin-top: 1.2em;">
            <router-link to="/register" class="text-weight-medium q-pt-md text-grey-7" style="text-decoration: none;">
              No account? <span class="text-primary">Get started! &rarr;</span>
            </router-link>            
          </div>          
        </q-card-section>
        <q-card-section class="row justify-center text-red-7 text-weight-medium q-pt-none" v-if="signInError">
          {{ signInError }}
        </q-card-section>
      </q-card>
    </div>
    <div class="q-pt-sm text-white" style="text-align: center; font-weight: 500; position: fixed; bottom: 1rem; width: 100%;">
      <a style="text-decoration: none;" class="text-white" href="https://productengine.app/terms">Terms & Conditions</a> &bullet; <a style="text-decoration: none;" class="text-white" href="https://productengine.app/privacy">Privacy Policy</a>
    </div>

  </pre-layout>
</template>

<style scoped>

.login-card {
  background: white;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  width: 25rem;
}

</style>

<script setup>

// Layouts
import PreLayout from '../layouts/PreLayout.vue'
import { isLoginLoaderLoading } from '../actions/login.mjs'

// Actions
import {
  email, password, showPassword, signInError,
  signInWithPassword, signInWithGoogle
} from '../actions/current-user.mjs'

</script>
