/*!
 * Number field type
 */

import { FieldType } from './FieldType.mjs'
import { isEmpty, isObject, isArray } from '../utils'

//Constants
import {
  KEY_IS_EMPTY, KEY_CONTAINS, KEY_DOES_NOT_CONTAIN
} from '../constants'

export class ArrayType extends FieldType {
  constructor() {
    super()
  }

  static compare(aValue, condition, bValue) {
    if (condition === KEY_IS_EMPTY) {
      return isEmpty(aValue)
    }
    if (isObject(aValue)) {
      aValue = Object.values(aValue)
    }
    if (!isArray(aValue)) {
      return null
    }
    const a = aValue.map(v => String(v).toLowerCase().trim())
    const b = String(bValue).toLowerCase().trim()
    return condition === KEY_CONTAINS         ? a.includes(b)
         : condition === KEY_DOES_NOT_CONTAIN ? !a.includes(b)
         : null
  }
}